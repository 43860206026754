<template>
	<div class="app">
		<van-swipe :autoplay="3000" :height="160">
			<van-swipe-item v-for="(item,i) in bannerList" :key="i">
				<img :src="item.ImgUrl" alt="" />
			</van-swipe-item>
		</van-swipe>
		<van-grid class="type-grid" :column-num="2" :border="false">
			<van-grid-item v-for="(item,i) in goodsType" :key="i">
				<div class="grid-item" @click="changeTypeEvent(item.code,i)">
					<img class="grid-item-img" v-if="item.Icon" :src="item.Icon" alt="" />
					<span>{{item.text}}</span>
				</div>
			</van-grid-item>
		</van-grid>
		<van-sticky :offset-top="pageTop" @scroll="scrollEvent">
			<div class="search">
				<div class="search-box">
					<input class="search-ipt" type="text" v-model="keyword" placeholder="药品名称" />
					<img class="search-btn" src="@/assets/images/tab/icon_search.png" alt="" @click="searchEvent" />
				</div>
			</div>
			<van-tabs class="tabs" v-model="tabCurrent" line-height="2px" title-active-color="#00A2EA" color="#00A2EA" @click="onClick">
				<van-tab v-for="(item,i) in goodsType" :key="i" :title="item.text">
					<!-- <div class="tit" slot="title"  >{{item.text}}</div> -->
				</van-tab>
			</van-tabs>
		</van-sticky>
		
		<div class="m-list" ref="glist">
			<div class="m-list-item" v-for="(item,i) in goodsList" :key="i" @click="goodsDetails(item)">
				<img v-if="item.ImgSrc&&item.ImgSrc.length>0" class="item-img" :src="item.ImgSrc[0]"  alt="">
				<img v-else class="item-img" src="@/assets/images/no_good.png" alt="" />
				<div class="item-goods">
					<span class="name">{{item.spmch}}</span>
					<span class="company">{{item.shengccj}}</span>
					<span class="spec">规格：{{item.shpgg}}</span>
					<span class="price">￥{{item.zdshj}}/{{item.dw}}</span>
					<!-- <span class="ms">月销量99</span> -->
				</div>
			</div>
			<div v-if="!isLoad" class="more-btn" @click="loadEvent">点击加载更多</div>
			<div v-if="goodsList.length==0" class="empty">暂无数据</div>
		</div>
	</div>
</template>


<script>
	import {Toast, Dialog} from "vant"
	export default {
		name: 'app',
		components: {
			Toast, [Dialog.Component.name]: Dialog.Component
		},
		data(){
		  return{
			  pageTop: 0,
			  scrollTop: 0,
			  typeId: "",
			  bannerList: [],
			  
			  tabCurrent: -1,
			  goodsType: [], //商品分类
			  keyword: "",
			  goodsTypeId: "", //商品分类id
			  goodsList: [], //商品列表
			  pageIndex: 1,
			  isLoad: true
		  }
		},
		
		created() {
			this.initView();
			// document.title = this.$route.query.title;
			this.$store.commit('setHeadTitle',this.$route.query.title);
			this.typeId = this.$route.query.goodstype;
			this.queryBanners();
			this.queryGoodsType();
		},
		methods: {
			initView(){
				let appType = localStorage.getItem("clientType");
				if(appType == "applets" || appType == "weixin"){
					this.pageTop = 45;
				}else{
					this.pageTop = 85;
				}
			},
			/**
			 * 轮播图
			 */
			queryBanners(){
				let that = this;
				this.public.request("post","api/advertItem/queryAdvertitem",{
					orgId: localStorage.getItem("orgId"),  //this.public.getConfig.defaultOrgId
					AdvertplatformTvpe: 20,
					Code: 20017,
				},res=>{
					if(res.data.errorCode == "00"){
						that.bannerList = res.data.rows;
					}else{
						that.$toast(res.data.errorMsg)
					}
				})
			},
			/**
			 * 商品分类
			 */
			queryGoodsType(){
				let that = this;
				that.$toast.loading({
					message: "加载中...",
					duration: 0, //0 不会自动消失
				});
				this.public.request("post","api/OrgGoodsType/QueryChildTypeList",{
					Id: this.typeId
				},res=>{
					that.$toast.clear();
					if(res.data.errorCode == "00"){
						that.goodsType = res.data.rows;
						if(that.goodsType.length>0){
							that.changeTypeEvent(that.goodsType[0].code, 0);
						}
					}else{
						that.$toast(res.data.errorMsg);
					}
				})
			},
			
			/**
			 * 根据分类查询商品
			 */
			changeTypeEvent(code,i){
				this.goodsTypeId = code;
				this.tabCurrent = i;
				this.pageIndex = 1;
				// this.goodsList = [];
				this.queryGoodsList();
			},
			
			/**
			 * 搜索
			 */
			searchEvent(){
				if(!this.goodsTypeId){
					return;
				}
				this.pageIndex = 1;
				this.queryGoodsList();
			},
			
			/** 
			 * 加载更多
			 */
			loadEvent(){
				this.pageIndex = this.pageIndex+1;
				this.queryGoodsList();
			},
			
			/**
			 * 商品列表
			 */
			queryGoodsList(){
				let that = this;
				that.$toast.loading({
					message: "加载中...",
					duration: 0, //0 不会自动消失
				});
				this.public.request("post","api/Store/QueryStoreGoods",{
					OnlyPackageSold: "",
					SortField: "",
					SortType: "",
					TypeId: this.goodsTypeId,
					keyword: this.keyword,
					page: this.pageIndex,
					rows: 10
				},res=>{
					that.$toast.clear();
					if(res.data.errorCode == "00"){
						that.isLoad = res.data.rows.length<10?true:false;
						if(that.pageIndex == 1){
							that.goodsList = res.data.rows;
							return;
						}
						that.goodsList = that.goodsList.concat(res.data.rows);
						// for (var i = 0; i < 10; i++) {
						// 	that.goodsList = that.goodsList.concat(res.data.rows)
						// }
					}else{
						that.$toast(res.data.errorMsg)
					}
				})
			},
			
			/**
			 * 界面滚动事件
			 */
			scrollEvent(e){
				// 搜索框取消悬浮时，将scrollTop重置为0
				if(!e.isFixed){
					this.scrollTop = 0;
				}
				//记录搜索框第一次悬浮时滚动的位置
				if(e.isFixed && !this.scrollTop){
					this.scrollTop = e.scrollTop;
				}
			},
			
			/**
			 * tabs 点击切换事件
			 */
			onClick(index, title) {
				//搜索框悬浮时，点击tab分类时，将商品列表滚动到起始位置
				if(this.scrollTop>0){
					document.documentElement.scrollTop = this.scrollTop;
				}
				this.changeTypeEvent(this.goodsType[index].code,index);
			},
			
			/**
			 * 商品详情
			 */
			goodsDetails(goods){
				this.$router.push({
				    path: '/goodsDetail',
				    query:{
				        spid: goods.spid,
				        headTit: '商品详情'
				    }
				})
			},
		},
		
		destroyed(){
			this.$store.commit("setHeadTitle", "");
		},
		// activated(){
		// },
		// deactivated(){
		// },
	}
</script>

<style scoped>
	.type-grid{
		margin-top: 10px;
	}
	.grid-item{
		height: 50px;
		display: flex;
		align-items: center;
		font-size: 16px;
	}
	.grid-item .grid-item-img{
		width: 50px;
		height: 50px;
		margin-right: 10px;
	}
	/deep/.van-grid-item .van-grid-item__content{
		padding: 5px 10px;
	}
	
	.search{
		background: #fff;
		padding: 10px 20px;
	}
	.search-box{
		display: flex;
		align-items: center;
		background: #f5f5f5;
		padding: 0 10px;
		border-radius: 50px;
	}
	.search-box .search-ipt{
		flex: 1;
		height: 40px;
		margin-right: 10px;
		font-size: 15px;
	}
	.search-box .search-btn{
		width: 30px;
		height: 30px;
	}

	.tabs .tit{
		font-size: 15px;
	}
	
	.m-list{
		padding: 10px;
	}
	.m-list .m-list-item{
		margin: 5px;
		display: flex;
	}
	.m-list .m-list-item .item-img{
		width: 120px;
		max-height: 90px;
		margin-right: 10px;
	}
	.m-list .m-list-item .item-goods{
		display: flex;
		flex-direction: column;
		font-size: 15px;
	}
	.item-goods .spec, .ms{
		color: #999;
		font-size: 12px;
	}
	.item-goods .name{
		font-size: 16px;
		font-weight: bold;
	}
	.item-goods .company{
		font-size: 14px;
	}
	.item-goods .price{
		font-size: 18px;
		color: red;
		font-weight: bold;
	}
	
	.more-btn{
		width: 150px; 
		margin: 30px auto; 
		padding: 5px;
		text-align: center; 
		color: #999;
		font-size: 14px; 
		border: 1px #eee solid;
	}
	.empty{
		text-align: center;
		font-size: 15px;
		color: #666;
		margin-top: 40%;
	}
</style>
