<template>
  <div class="app">
    
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  }
};
</script>

<style scoped>

</style>