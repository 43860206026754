<template>
  <div class="container-box">
    <!-- 图片轮播 -->
    <div class="seck-swiper">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <div v-for="(item, index) in bannerList" :key="index">
          <van-swipe-item v-if="bannerList.length > 0">
            <div class="item-img">
              <img :src="item.ImgUrl" alt="" />
            </div>
          </van-swipe-item>
        </div>
        <van-swipe-item v-if="bannerList.length <= 0 && bannerShow">
          <div class="item-img">
            <img src="@/assets/images/banner_reduction.jpg" alt="" />
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="favour-list" v-if="fullList.length > 0">
      <div class="floor-title flex-sp">
        <span class="title">满赠优惠</span>
      </div>
      <div class="brand-item bor-bot2">
        <div class="pack-list flex-wrap">
          <div
            class="pack-item"
            v-for="(item, index) in fullList"
            :key="index"
            @click="toGoodDetail(item.spid)"
          >
            <div class="item-img">
              <img class="shop-img" :src="item.ImgPic?item.ImgPic:item.SpPicExtend" />
            </div>
            <div class="item-info">
              <span class="info-name">{{ item.spmch }}</span>
              <div class="info-wrap flex-sp">
                <span class="info-specifi">{{ item.shpgg }}</span>
              </div>
              <!-- <div class="info-wrap flex-sp">
                                <span class="info-num">库存:<span class="font-red">100</span>盒</span>
                            </div> -->
              <!-- <span class="info-company">医药有限公司</span> -->
              <!-- <div class="info-time">有效期：2021-10-12</div> -->
              <div class="flex-sp">
                <span class="info-price font-red"
                  >￥ {{ item.zdshj }}/{{ item.dw }}</span
                >
                <img
                  class="shop-cart"
                  v-if="item.kcshl > 0"
                  @click.stop="onCartShow(item.spid)"
                  src="@/assets/images/index/main-gwc.png"
                  alt=""
                />
                <img
                  class="shop-cart"
                  v-if="item.kcshl <= 0"
                  src="@/assets/images/index/main-gwc_.png"
                  alt=""
                />
              </div>
            </div>
            <div class="info-active flex-wrap">
              <div class="active-box">{{ item.ActivityCacheData.Description }}</div>
            </div>
          </div>
          <!-- <div  class="but-more" @click="toList(10)">更多</div> -->
        </div>
      </div>
    </div>
    <!-- 减免 -->
    <div class="favour-list" v-if="reductionList.length > 0">
      <div class="floor-title flex-sp">
        <span class="title">减免优惠</span>
      </div>
      <div class="brand-item bor-bot2">
        <div class="pack-list flex-wrap">
          <div
            class="pack-item"
            v-for="(item, index) in reductionList"
            :key="index"
            @click="toGoodDetail(item.spid)"
          >
            <div class="item-img">
              <img class="shop-img" :src="item.ImgPic?item.ImgPic:item.SpPicExtend" />
            </div>
            <div class="item-info">
              <span class="info-name">{{ item.spmch }}</span>
              <div class="info-wrap flex-sp">
                <span class="info-specifi">{{ item.shpgg }}</span>
              </div>
              <!-- <div class="info-wrap flex-sp">
                                <span class="info-num">库存:<span class="font-red">100</span>盒</span>
                            </div> -->
              <!-- <span class="info-company">医药有限公司</span> -->
              <!-- <div class="info-time">有效期：2021-10-12</div> -->
              <div class="flex-sp">
                <span class="info-price font-red"
                  >￥ {{ item.zdshj }}/{{ item.dw }}</span
                >
                <img
                  class="shop-cart"
                  v-if="item.kcshl > 0"
                  @click.stop="onCartShow(item.spid)"
                  src="@/assets/images/index/main-gwc.png"
                  alt=""
                />
                <img
                  class="shop-cart"
                  v-if="item.kcshl <= 0"
                  src="@/assets/images/index/main-gwc_.png"
                  alt=""
                />
              </div>
            </div>
            <div class="info-active flex-wrap">
				<div class="active-box">{{ item.ActivityCacheData.Description }}</div>
            </div>
          </div>
          <!-- <div  class="but-more" @click="toList(20)">更多</div> -->
        </div>
      </div>
    </div>
    <!-- 价格折扣 -->
    <div class="favour-list" v-if="discountList.length > 0">
      <div class="floor-title flex-sp">
        <span class="title">价格折扣</span>
      </div>
      <div class="brand-item bor-bot2">
        <div class="pack-list flex-wrap">
          <div
            class="pack-item"
            v-for="(item, index) in discountList"
            :key="index"
            @click="toGoodDetail(item.spid)"
          >
            <div class="item-img">
              <img class="shop-img" :src="item.ImgPic?item.ImgPic:item.SpPicExtend" />
            </div>
            <div class="item-info">
              <span class="info-name">{{ item.spmch }}</span>
              <div class="info-wrap flex-sp">
                <span class="info-specifi">{{ item.shpgg }}</span>
              </div>
              <!-- <div class="info-wrap flex-sp">
                                <span class="info-num">库存:<span class="font-red">100</span>盒</span>
                            </div> -->
              <!-- <span class="info-company">医药有限公司</span> -->
              <!-- <div class="info-time">有效期：2021-10-12</div> -->
              <div class="flex-sp">
                <span class="info-price font-red"
                  >￥ {{ item.zdshj }}/{{ item.dw }}</span
                >
                <img
                  class="shop-cart"
                  v-if="item.kcshl > 0"
                  @click.stop="onCartShow(item.spid)"
                  src="@/assets/images/index/main-gwc.png"
                  alt=""
                />
                <img
                  class="shop-cart"
                  v-if="item.kcshl <= 0"
                  src="@/assets/images/index/main-gwc_.png"
                  alt=""
                />
              </div>
            </div>
            <div class="info-active flex-wrap">
				<div class="active-box">{{ item.ActivityCacheData.Description }}</div>
            </div>
          </div>
          <!-- <div  class="but-more" @click="toList(30)">更多</div> -->
        </div>
      </div>
    </div>
    <div
      class="empty-box"
      v-if="fullList.length <= 0 && reductionList <= 0 && discountList <= 0"
    >
      <div class="no-data">
        <img src="@/assets/images/cart/noActiveDrug.png" alt="" />
        <span>暂无数据</span>
      </div>
      <!-- <span class="load-txt">暂无数据！</span> -->
    </div>

    <!-- 购物车弹窗 -->
    <cart-pop
      :cartShowPop="cartShowPop"
      :goodInfo="goodInfo"
      @diffCartShow="closeCart"
    ></cart-pop>
  </div>
</template>

<script>
// import favourRequest from '../../../public/api/favourable';
import favourRequest from "@/assets/js/api/favourable";
import cartPop from "../../components/cartPop.vue";
export default {
  components: {
    cartPop,
  },
  data() {
    return {
      offerTypeArr: [
        { offerType: 10 }, // 满赠
        { offerType: 20 }, // 减免
        { offerType: 30 }, // 折扣
      ],
      fullList: [], // 满赠
      reductionList: [], // 减免
      discountList: [], // 折扣
      bannerList: [], // 顶部图片轮播
      bannerShow: false, // 防止进入页面展示本地图片  false隐藏  true展示

      cartShowPop: false, // 组件弹窗显示隐藏
      goodInfo: {}, // 组件商品信息
    };
  },
  created() {
	  let that = this;
	  var url = that.$route.query.url;
	  if (!url) {
	    var urlObj = that.$route.query;
	  } else {
	    var urlObj = that.public.urlObj(url);
	  }
	  // 顶部标题
	  that.$store.commit("setHeadTitle", urlObj.name);
	  // 请求优惠活动数据
	  that.offerTypeArr.forEach((item, i) => {
	    that.getfavourList(item);
	  });
	  // that.getfavourList({ offerType: 10 });
	  that.getBannerImg();
	  
  },
  activated() {
    // var that = this;
    // // 满赠
    // that.getFullList();
    // // 减免
    // that.getReductionList();
    // // 折扣
    // that.getDiscountList();
  },

  methods: {
    // 顶部图片轮播
    /*
            公众号-首页轮播图	20001
            公众号-秒杀列表页banner	20002
            公众号-团购列表页banner	20003
            公众号-集采列表页banner	20004
            公众号-品牌列表页banner	20005
            公众号-今日特价列表页banner	20006
            公众号-套餐包专区banner   20007
            公众号-近效期列表页banner	20008
            公众号-优惠活动列表页banner  20010
             */
    getBannerImg() {
      var that = this;
      var data = {
        Code: "20010",
      };

      that.public.request(
        "post",
        "/api/advertItem/queryAdvertItem",
        data,
        (res) => {
          if (res.data.errorCode == "00") {
            that.bannerList = res.data.rows;
          }
          that.bannerShow = true;
        }
      );
    },

    // 组件购物车弹窗
    onCartShow(spid) {
      var that = this;
      var orgId = localStorage.orgId;
      var data = {
        SpId: spid,
        OrgId: orgId,
      };
      that.public.request("post", "/api/Store/QueryGoodsInfo", data, (res) => {
        // console.log(res);
        if (res.data.errorCode == "00") {
          that.goodInfo = res.data.rows[0];
          that.cartShowPop = true;
        }
      });
    },
    // 关闭组件弹窗
    closeCart() {
      var that = this;
      that.cartShowPop = false;
    },

    // 去详情页面
    toGoodDetail(spid) {
      var that = this;
      that.$router.push({
        path: "/goodsDetail",
        query: {
          spid: spid,
        },
      });
    },

    // 跳转列表页面
    toList(type) {
      var that = this;
      that.$router.push({
        path: "/favourList",
        query: {
          type: type,
        },
      });
    },
    // // 满赠
    // getFullList(){
    //     var that = this;
    //     var data = {
    //         offerType: 10,
    //     };
    //     favourRequest.activeList(data,res => {
    //         if(res.data.errorCode == '00'){
    //             var list = res.data.rows;
    //             var allArr = new Array;
    //             list.forEach((item,i) => {
    //                 item.ListGoodsOfferExtend.forEach((item1,j) => {
    //                     allArr.push(item1);
    //                 })
    //             })
    //             allArr.splice(6);
    //             that.fullList = allArr;
    //         }
    //     })
    // },
    // // 减免
    // getReductionList(){
    //     var that = this;
    //     var data = {
    //         offerType: 20,
    //     };
    //     favourRequest.activeList(data,res => {
    //         if(res.data.errorCode == '00'){
    //             console.log(res);
    //             var list = res.data.rows;
    //             var allArr = new Array;
    //             console.log(list);
    //             list.forEach((item,i) => {
    //                 item.ListGoodsOfferExtend.forEach((item1,j) => {
    //                     allArr.push(item1);
    //                 })
    //             })
    //             allArr.splice(6);
    //             console.log(allArr);
    //             that.reductionList = allArr;
    //             console.log(that.reductionList);
    //         }
    //     })
    // },
    // // 折扣
    // getDiscountList(){
    //     var that = this;
    //     var data = {
    //         offerType: 30,
    //     };
    //     favourRequest.activeList(data,res => {
    //         if(res.data.errorCode == '00'){
    //             var list = res.data.rows;
    //             var allArr = new Array;
    //             list.forEach((item,i) => {
    //                 item.ListGoodsOfferExtend.forEach((item1,j) => {
    //                     allArr.push(item1);
    //                 })
    //             })
    //             allArr.splice(6);
    //             console.log(allArr);
    //             that.discountList = allArr;
    //             console.log(that.discountList);
    //         }
    //     })
    // },
    // 请求优惠活动数据
    getfavourList(model) {
      var that = this;
      that.$toast.loading({
        message: "加载中...",
        duration: 0,
      });
      favourRequest.activeList(model, (res) => {
        // console.log(res);
        // console.log(model);
        if (res.data.errorCode == "00") {
          this.$toast.clear();
          // 满赠
          if (model.offerType == "10") {
            var list = res.data.rows;
            var allArr = new Array();
            list.forEach((item, i) => {
              item.ListGoodsOfferExtend.forEach((item1, j) => {
                allArr.push(item1);
              });
            });
            // allArr.splice(6);
            that.fullList = allArr;
          } else if (model.offerType == "20") {
            // 减免

            var list = res.data.rows;
            var allArr = new Array();
            list.forEach((item, i) => {
              item.ListGoodsOfferExtend.forEach((item1, j) => {
                allArr.push(item1);
              });
            });
            // allArr.splice(6);
            that.reductionList = allArr;
            // console.log(that.reductionList);
          } else if (model.offerType == "30") {
            // 价格折扣
            var list = res.data.rows;
            var allArr = new Array();
            list.forEach((item, i) => {
              item.ListGoodsOfferExtend.forEach((item1, j) => {
                allArr.push(item1);
              });
            });
            // allArr.splice(6);
            that.discountList = allArr;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
/* 图片轮播 */
.seck-swiper {
  width: 100%;
  height: 3.2rem;
}
.seck-swiper .my-swipe {
  width: 100%;
  height: 100%;
}
.seck-swiper .my-swipe .item-img {
  width: 100%;
  height: 100%;
}
.seck-swiper .my-swipe .item-img img {
  width: 100%;
  height: 100%;
}

/* 列表 */
.favour-list .floor-title {
  width: 100%;
  height: 0.8rem;
  padding: 0 0.3rem;
  box-sizing: border-box;
  border-bottom: 0.02rem solid #eee;
}
.favour-list .floor-title .title {
  font-size: 0.3rem;
  color: #e94040;
  font-weight: 600;
  font-weight: bold;
}

.pack-list {
  width: 100%;
  padding: 0.18rem 0.2rem;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: inherit;
}
.pack-list .pack-item {
  width: 48.3%;
  box-shadow: 0 0 0.1rem #d1d0d0;
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
  background: #fff;
  padding: 0.18rem;
  box-sizing: border-box;
}
.pack-list .pack-item:nth-child(2n) {
  margin-right: 0;
}
.pack-list .pack-item .item-info .info-wrap .info-num {
  font-size: 0.24rem;
  display: inline;
}
.pack-list .pack-item .item-info .info-wrap .info-num span {
  display: inline;
}
.pack-list .pack-item .item-info .info-time {
  font-size: 0.22rem;
}

.pack-item .item-img {
  width: 100%;
  height: 2.7rem;
}
.pack-item .item-img .shop-img {
  width: 100%;
 height: 2.7rem;
  object-fit: contain;
}
.pack-item .item-img .item-setime {
  position: absolute;
  left: 3%;
  bottom: 0;
  right: 0;
  padding: 0.02rem 0.2rem;
  background: #199ed8;
  border-radius: 0.28rem;
  width: 80%;
}
.pack-item .item-img .item-setime img {
  width: 0.22rem;
  height: 0.22rem;
  margin-right: 0.18rem;
}
.pack-item .item-img .item-setime .settime-tit {
  color: #fff;
  font-size: 0.24rem;
}
.pack-item .item-info {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}
.pack-item .item-info span {
  display: block;
  font-size: 0.26rem;
}
.pack-item .item-info span.info-name {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
}
.pack-item .item-info span.info-specifi {
  font-size: 0.24rem;
}
.pack-item .item-info span.info-company {
  font-size: 0.26rem;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
}
.pack-item .item-info span.info-price {
  font-size: 0.32rem;
}
.pack-item .item-info span.info-old-price {
  font-size: 0.22rem;
  text-decoration: line-through;
}
.pack-item .item-info .shop-cart {
  width: 0.36rem;
}
.pack-item .info-active {
  width: 100%;
  margin-top: 0.1rem;
  flex-wrap: wrap;
}
.pack-item .info-active .active-box {
  display: inline-block;
  padding: 0.02rem 0.08rem;
  font-size: 0.24rem;
  color: #ff1515;
  border: 0.02rem solid #ff1515;
  border-radius: 0.12rem;
  margin-right: 0.1rem;
  margin-bottom: 0.1rem;
}
.pack-item .info-active .active-box:nth-child() {
  margin-bottom: 0;
}

/* 更多 */
.but-more {
  width: 80%;
  margin: 0.2rem auto;
  height: 0.7rem;
  line-height: 0.7rem;
  font-size: 0.3rem;
  color: #fff;
  text-align: center;
  background: #dddbdb;
  border-radius: 0.12rem;
}
.empty-box{margin: 0 auto;}
</style>
