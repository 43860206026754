<template>
  <div id="container">
	<!-- v-if="videoType==1" -->
	<div class="video-list">
		<div class="video-cell" v-for="item in videoList" @click="handlePlay(item)">
			<img class="video-img" :src="item.ImgPic" >
			<!-- <img class="bg" :src="item.VideoPath + '?x-oss-process=video/snapshot,t_1000,m_fast'"> -->
			<div class="video-tit">
				<span>{{item.Title}}</span>
				<p class="time">{{item.CreateDate}}</p>
			</div>
		</div>
	</div>
	<div class="empty" v-if="videoList.length==0">
		<img src="@/assets/images/cart/empty_img.png" alt="">
		<span>暂无视频</span>
	</div>
  </div>
</template>

<script>
	// import HelloWorld from '@/components/HelloWorld.vue'
	import {Toast} from "vant"
	export default {
		name: 'app',
		components: {
			Toast
			// HelloWorld
		},
		data(){
		  return{
			  videoList: [], //视频列表
		  }
		},
		// beforeRouteEnter(to, from, next){
		// 	if(to && to.query && to.query.title){
		// 		document.title = to.query.title;
		// 	}
		// 	next();
		// },
		
		created() {
			this.queryVideos();
		},
		methods: {
			/**
			 * 视频列表
			 */
			queryVideos(){
				let that = this;
				this.public.request("post","api/ExpertHall/QueryList",{
					title: "",
					Enable: true,
					row: 20,
					page: 1
				},res=>{
					if(res.data.errorCode == "00"){
						that.videoList = res.data.rows;
					}
				})
			},
			
			//视频播放
			handlePlay(video){
				this.$router.push({
					path: "/expertDetails",
					query: {
						vid: video.Id
					}
				})
			},
		  
		}
	}
</script>

<style scoped>
	.video-list{
		position: relative;
		padding: 13px;
	}
	.video-list .video-cell{
		position: relative;
		display: flex;
		margin-bottom: 10px;
	}
	.video-list .video-cell .video-img{
		width: 120px;
		height: 80px;
		margin-right: 10px;
		border-radius: 3px;
	}
	.video-list .video-cell .video-tit{
		flex: 1;
		font-size: 16px;
		padding: 5px 0;
	}
	.video-tit .time{
		text-align: right;
		font-size: 13px;
		color: #999;
	}
	
	.empty{
		position: relative;
		margin-top: 40%;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 15px;
		color: #999;
	}
	.empty img{
		width: 120px;
		height: 120px;
		margin-bottom: 15px;
	}
</style>
