<template>
  <div class="contain">
    <!-- <div class="stepRegister">
      <div class="first">
        <div class="number">1</div>
        <div class="retail star">信息填写</div>
        <div class="right-line"></div>
      </div>

      <div class="second">
        <div class="left-line" :class="step < 2 ? 'grey-back' : 'starline'"></div>
        <div class="number" :class="step < 2 ? 'grey-back' : 'starnumber'">2</div>
        <div class="retail" :class="step < 2 ? 'grey-back' : 'star'">
          资质上传
        </div>
        <div class="right-line" :class="step < 2 ? 'grey-back' : 'starline'"></div>

      </div>

      <div class="third">
        <div class="left-line"  ></div>
        <div class="number">3</div>
        <div class="retail">开始采购</div>
      </div>
    </div> -->
    <!-- 第一步 -->
    <div class="formmember" v-show="step == 1"> 
	
		<div class="mui-input-row"  @click="isCustomer = true " v-if="orgid!='fe0024bb88ab4f259f9941f8c1825fa2' && orgid!='efd712fe5a714c1e9db1d0d1e9875917'">
		  <span class="mui-icon" style="position: absolute; margin-top: 4.2%">
			<img src="@/assets/images/reg/icon_province.png" style="width: 45%" />
		  </span>
		  <input
			type="text"
			disabled
			value=""
			name="customerType"
			id="customerType"
			v-model="customerType"
			placeholder="请选择客户类型"
		  />
		</div>
	
		<div class="mui-input-row">
		  <span class="mui-icon" style="position: absolute; margin-top: 4.2%">
			<img src="@/assets/images/reg/jg.png" style="width: 40%" />
		  </span>
		  <input
			type="text"
			value=""
			name="OrgName"
			id="OrgName"
			v-model="OrgName"
				  autocomplete="off"
			placeholder="请输入机构名称"
		  />
		</div>
	
      <div class="mui-input-row">
        <span class="mui-icon" style="position: absolute; margin-top: 4.2%">
          <img src="@/assets/images/reg/setPhone.png" style="width: 50%" />
        </span>
        <input
          type="number"
          value=""
          name="Mobile"
          v-model="Mobile"
          id="Mobile"
		  autocomplete="off"
          placeholder="请输入您的手机号"
        />
      </div>
      <div class="mui-input-row">
        <span
          class="mui-icon"
          style="position: absolute; margin-left: 1%; margin-top: 4.2%"
        >
          <img
            src="@/assets/images/reg/yzm.png"
            style="width: 40%; margin-left: 1%"
          />
        </span>
        <input
          type="text"
          value=""
          name="Code"
          id="Code"
          v-model="Code"
          placeholder="短信验证码"
          style="width: 65%;"
		  autocomplete="off"
        />
        <button
          :disabled="disabled"
          type="button"
          class="mui-btn mui-btn-warning sendCode"
          @click="getCode"
          style="color:#fff;padding:3% 4%; width:33%;float:right;"
        >
          {{ sendTime }}
        </button>
      </div>
	  
	  <div class="mui-input-row invite-box">
	    <span class="mui-icon" style="position: absolute; margin-top: 4.2%">
	      <img src="@/assets/images/reg/icon-invite.png" style="width: 40%" />
	    </span>
	    <input
	      type="text"
	      value=""
	      name="inviteCode"
	      id="inviteCode"
	      v-model="inviteCode"
	  		  autocomplete="off"
	      placeholder="请输入邀请码"
	    />
	  </div>
	  
	  <div class="mui-input-row register-btn">
	    <button
	      type="button"
	      id="BtnSubmitUser"
	      @click="go"
	      style="
	        margin-top: 4%;
	        border-radius: 3%;
	        width: 100%;
	        width: 100%;
	        background-color: rgb(0, 182, 204);
	        color: #fff;
	        padding: 3% 4%;
	        float: right;
	      "
	    >
	      {{loginOrder?"立即注册":"下一步"}}
	    </button>
	  </div>
	  

      <!-- <div class="mui-input-row">
        <span class="mui-icon" style="position: absolute; margin-top: 4.2%">
          <img src="@/assets/images/reg/pwd.png" style="width: 40%" />
        </span>
        <input
          type="password"
          name="password"
          v-model="password"
          id="password"
		  autocomplete="off"
          placeholder="请输入密码"
        />
      </div>

      <div class="mui-input-row">
        <span class="mui-icon" style="position: absolute; margin-top: 4.2%">
          <img src="@/assets/images/reg/pwd.png" style="width: 40%" />
        </span>
        <input
          type="password"
          name="repassword"
          id="repassword"
          v-model="repassword"
		  autocomplete="off"
          placeholder="再次输入密码"
        />
      </div>
    <div class="mui-input-row">
        <span class="mui-icon" style="position: absolute; margin-top: 4.2%">
          <img src="@/assets/images/reg/icon-name.png" style="width: 40%" />
        </span>
        <input
          type="text"
          value=""
          name="Name"
          id="Name"
          v-model="Name"
		  autocomplete="off"
          placeholder="请输入用户姓名"
        />
      </div>
      
      <div class="mui-input-row"  @click="addArea = true " v-if="orgid!='fe0024bb88ab4f259f9941f8c1825fa2' && orgid!='efd712fe5a714c1e9db1d0d1e9875917'">
        <span class="mui-icon" style="position: absolute; margin-top: 4.2%">
          <img src="@/assets/images/reg/icon_province.png" style="width: 45%" />
        </span>
        <input
          type="text"
          disabled
          value=""
          name="areaName"
          id="areaName"
          v-model="areaName"
          placeholder="请选择所属区域"
        />
      </div>
   
        <div class="mui-input-row">
        <span class="mui-icon" style="position: absolute; margin-top: 4.2%">
          <img src="@/assets/images/reg/icon-address.png" style="width: 40%" />
        </span>
        <input
          type="text"
          value=""
          name="BizAddress"
          id="BizAddress"
          v-model="BizAddress"
          placeholder="请输入机构地址"
		  autocomplete="off"
        />
      </div>

      <div class="mui-input-row">
        <button
          type="button"
          id="BtnSubmitUser"
          class="mui-button"
          @click="go"
          style="
            margin-top: 4%;
            border-radius: 3%;
            width: 100%;
            width: 100%;
            background-color: rgb(0, 182, 204);
            color: #fff;
            padding: 3% 4%;
            float: right;
          "
        >
          下一步
        </button>
      </div> -->
	  
    </div>
    <!-- 第二步 -->
    <div class="upload-info" v-show="step == 2">  
      <!-- <div class="upload-info"> -->
      <div class="info-title flex-wrap">资质信息</div>
      <div class="info-list flex-wrap1">

          <div class="info-item" v-for="(item,index) in showPapersList" :key="index">
            
            <div class="upload-img" >
              <img
                v-if="!qualifiObj_jh[item.type]"
                src="@/assets/images/icon_uptp.png"
                @click="upload(item.type,item.name)"
              />
              <img
                v-if="qualifiObj_jh[item.type]"
                :src="qualifiObj_jh[item.type].src?hostUrl + qualifiObj_jh[item.type].src:hostUrl + qualifiObj_jh[item.type]"
                @click="upload(item.type,item.name)"
              />
            </div>
            <div class="item-title">
              <span class="title-name blue" @click="seeExample(item.type)" v-if="item.name != '其他证件' && (orgid!='efd712fe5a714c1e9db1d0d1e9875917' || orgid!='fe0024bb88ab4f259f9941f8c1825fa2')">示例图片</span>
              <span class="title-name sfzVerso">{{item.name}}</span>
              <div class="title-tip" v-if="item.name != '其他证件'">
                <span class="font-red">必填</span>
                <span>(复印件盖红章)</span>
              </div>
            </div>
          </div>
          <!--防止循环的证件无法上传图片-->
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />

        <!-- <div class="info-item" v-if="orgid!='efd712fe5a714c1e9db1d0d1e9875917'">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.yyzz"
              src="@/assets/images/icon_uptp.png"
              @click="upload('yyzz','营业执照')"
            />
            <img
              v-if="qualifiObj.yyzz"
              :src="qualifiObj.yyzz.src?hostUrl + qualifiObj.yyzz.src:hostUrl + qualifiObj.yyzz"
              @click="upload('yyzz','营业执照')"
            />
          </div>
          <div class="item-title">
            <span class="title-name sfzVerso">营业执照</span>
            <div class="title-tip">
              <span class="font-red">必填</span>
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>

        <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.xkzLicence"
              src="@/assets/images/icon_uptp.png"
              @click="upload('xkzLicence','许可证')"
            />
            <img
              v-if="qualifiObj.xkzLicence"
              :src="qualifiObj.xkzLicence.src?hostUrl + qualifiObj.xkzLicence.src:hostUrl + qualifiObj.xkzLicence"
              @click="upload('xkzLicence','许可证')"
            />
          </div>
          <div class="item-title">
            <span class="title-name xkzLicence">许可证</span>
            <div class="title-tip">
              <span class="font-red">必填</span>
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>

       <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.sfzFront"
              src="@/assets/images/icon_uptp.png"
              @click="upload('sfzFront','身份证正面')"
            />
            <img
              v-if="qualifiObj.sfzFront"
              :src="qualifiObj.sfzFront.src?hostUrl + qualifiObj.sfzFront.src:hostUrl + qualifiObj.sfzFront"
              @click="upload('sfzFront','身份证正面')"
            />
          </div>
          <div class="item-title">
            <span class="title-name sfzFront">身份证正面</span>
            <div class="title-tip">
              <span class="font-red">必填</span>
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>

      <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.sfzVerso"
              src="@/assets/images/icon_uptp.png"
              @click="upload('sfzVerso','身份证反面')"
            />
            <img
              v-if="qualifiObj.sfzVerso"
              :src="qualifiObj.sfzVerso.src?hostUrl + qualifiObj.sfzVerso.src:hostUrl + qualifiObj.sfzVerso"
              @click="upload('sfzVerso','身份证反面')"
            />
          </div>
          <div class="item-title">
            <span class="title-name sfzVerso">身份证反面</span>
            <div class="title-tip">
              <span class="font-red">必填</span>
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>


        <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.yzym"
              src="@/assets/images/icon_uptp.png"
              @click="upload('yzym','印章印模')"
            />
            <img
              v-if="qualifiObj.yzym"
              :src="qualifiObj.yzym.src?hostUrl + qualifiObj.yzym.src:hostUrl + qualifiObj.yzym"
              @click="upload('yzym','印章印模')"
            />
          </div>
          <div class="item-title">
            <span class="title-name sfzVerso">印章印模</span>
            <div class="title-tip">
              <span class="font-red">必填</span>
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div> -->

      </div>
    <div class="btn">
        <div class="page-btn" @click="uponPageBtn">上一页</div>
        <div class="submintBtn" v-if="!onceClick" @click="sumbitRegister">提交</div>
        <div class="submintBtn" v-if="onceClick">提交</div>
    </div>
    </div>


    <!-- 省市区弹窗 -->
    <van-popup v-model="addArea" round position="bottom">
        <van-picker show-toolbar title="请选择地区" value-key="Name" :columns="areaList" @change="onAreaChange" @cancel="onCancel" @confirm="onAreaConfirm"/>  
    </van-popup>
    <van-popup v-model="isCustomer" round position="bottom">
			<van-picker item-height="40" show-toolbar :columns="customeList"
				@cancel="isCustomer = false" @confirm="customerBtn" />
		</van-popup>
    <!-- 示例图片 -->
    <div v-if="isShowPreview">
      <van-image-preview v-model="isShowPreview"
            :showIndex=false
            :closeable=true
            :images="exampleList">
            </van-image-preview>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      Mobile: "",
      Code: "",
      password: "",
      repassword: "",
      OrgName: "",
      inviteCode:'',
      BizAddress:'',
      Name:'',
      isInviteCode:false,

      disabled: false,
      sendTime: "获取验证码",
      step: 1, // 第几步

      // 资质信息
      qualifiObj: {},
      qualifiObj_jh:{},

      token: "",
      customId: "",

      hostUrl: "",

      addArea: false,  //地址
      areaName: '',   // 选择的省市区 名称总和
      areaList: [{values: []}, {values: []}, {values: []}],        //自定义数据三级结构
      checkCity: '',     // picker拿到的值
      sureAdd: [],    // 点击确定传过来的值  选择地址
      orgid:'',
	  isCustomer:false,//是否展示客户类型
      customerType:'医疗机构',//客户类型
      customeList:['医疗机构','单体药店'],   // '单体药店','连锁药店','商业公司'
      listImg: [   // 医疗机构
        {name:'医疗机构执业许可证',type:'yljgzyxkz'},
        {name:'采购委托书',type:'cgwts'},
        {name:'采购人员身份证扫描件',type:'cgryCard'},
        {name:'收货委托书',type:'shwts'},
        {name:'收货人身份证扫描件',type:'shryCard'},
        {name:'其他证件',type:'qtzj'},
      ],
      listImg1: [
        {name:'营业执照',type:'yyzz'},
        {name:'年度报告（新年检）',type:'ndbg'},
        {name:'药品经营许可证',type:'ypyyxkz'},
        {name:'采购委托书',type:'cgwts'},
        {name:'采购人员身份证扫描件',type:'cgryCard'},
        {name:'收货委托书',type:'shwts'},
        {name:'收货人身份证扫描件',type:'shryCard'},
        {name:'其他证件',type:'qtzj'},
      ],
      listImg2: [
        {name:'营业执照',type:'yyzz'},
        {name:'年度报告（新年检）',type:'ndbg'},
        {name:'药品经营许可证',type:'ypyyxkz'},
        {name:'采购委托书',type:'cgwts'},
        {name:'采购人员身份证扫描件',type:'cgryCard'},
        {name:'收货委托书',type:'shwts'},
        {name:'收货人身份证扫描件',type:'shryCard'},
        {name:'开票信息',type:'xkzLicence'},
        {name:'其他证件',type:'qtzj'},
      ],
      listImg3: [
        {name:'营业执照',type:'yyzz'},
        {name:'年度报告（新年检）',type:'ndbg'},
        {name:'药品经营许可证',type:'ypyyxkz'},
        {name:'采购委托书',type:'cgwts'},
        {name:'采购人员身份证扫描件',type:'cgryCard'},
        {name:'收货委托书',type:'shwts'},
        {name:'收货人身份证扫描件',type:'shryCard'},
        {name:'开票信息',type:'xkzLicence'},
        {name:'体系调查表',type:'txdcb'},
        {name:'其他证件',type:'qtzj'},
      ],
      showPapersList: [], // 展示证件
      otherNum: 0,  // 其他证件上传次数
      otherList: [],    // 其他照片
      isShowPreview: false,   // 示例图片显示隐藏
      exampleList: [],  // 示例图片
      exampleArr:[  // 示例图片存放处
        {type:'yljgzyxkz',imgUrl: 'content/custom/example_yljgzyxkz.jpg'},
        {type:'cgwts',imgUrl: 'content/custom/example_cg_sh.png'},
        {type:'cgryCard',imgUrl: 'content/custom/example_idCard.jpg'},
        {type:'shwts',imgUrl: 'content/custom/example_cg_sh.png'},
        {type:'shryCard',imgUrl: 'content/custom/example_idCard.jpg'},
        {type:'yyzz',imgUrl: 'content/custom/example_yyzz.jpg'},
        {type:'ndbg',imgUrl: 'content/custom/example_ndbg.jpg'},
        {type:'ypyyxkz',imgUrl: 'content/custom/example_ypjyxkz.jpg'},
        {type:'xkzLicence',imgUrl: 'content/custom/example_khxkz.jpg'},
        {type:'txdcb',imgUrl: 'content/custom/example_txdcb.jpg'},
      ],
      onceClick: false,     // 接口成功之后才能点击按钮
      // 林创、太新龙展示的证件
      listImgOther:[
        {name:'许可证',type:'xkzLicence'},
        {name:'身份证正面',type:'sfzFront'},
        {name:'身份证反面',type:'sfzVerso'},
        {name:'印章印模',type:'yzym'},
        
      ],
	  loginOrder: false
    };
  },
  created() {
    let that=this;
    var params=that.public.urlObj(location.href);
	if(params.invite){
		this.inviteCode = params.invite;
	}
    if(params.orgId){
      localStorage.setItem('orgId',params.orgId);
    }
    this.hostUrlhostUrl = this.requestUrl.url;
    that.orgid=localStorage.getItem('orgId');
    // 如果是林创的则展示林创的，否则展示其他公司的
    if(that.orgid == 'fe0024bb88ab4f259f9941f8c1825fa2' || that.orgid=='efd712fe5a714c1e9db1d0d1e9875917'){
      that.showPapersList = that.listImgOther;
    }else{
      that.showPapersList = that.listImg;
      if(that.orgid != '2679591e77624a6489912571f449832c'){
        that.customeList = ['医疗机构','单体药店','连锁药店','商业公司'];
      }
    }
	
	that.showPapersList = that.listImg; //默认医疗机构
	that.getWebconfig();
  },
  mounted(){
    var that = this;
    var orgid = localStorage.getItem('orgId');
    if(orgid!='fe0024bb88ab4f259f9941f8c1825fa2' || orgid!='efd712fe5a714c1e9db1d0d1e9875917'){
      that.getArea(1,0);      //渲染页面时请求第一层省级数据
    }
    
  },
  methods: {
    // 获取配置信息  防止小程序端goOrder获取为null,不能执行提交
    getWebconfig(){
      var that = this;
      that.public.request("get","/api/SysOrgInfo/QueryOrgWebConfig",{
        orgId: localStorage.getItem("orgId"),
      },(res) => {
          if (res.data.errorCode == "00") {
            if (res.data && res.data.rows) {
				that.loginOrder = res.data.rows.LoginOrder;
				localStorage.goOrder = res.data.rows.LoginOrder;
            }
           
          }
        }
      );
    },
    // 查看示例图片
    seeExample(type){
      var that = this;
      var exampleArr = that.exampleArr;
      var hostImgUrl = '';
      exampleArr.forEach((item,index) => {
        if(type == item.type){
          that.exampleList = [];
          hostImgUrl = that.requestUrl.url + item.imgUrl;
          that.exampleList.push(hostImgUrl);
        }
      })
      that.isShowPreview = true;
    },
    // 请求地区数据，拼接三级联动
    getArea(parentId,index){
        var that = this;
        
        var data = {
            AreaCode: parentId,
        }
        that.public.request('post','/api/sysOrganize/getChild',data,res => {
            // console.log(res);
            if(res.data.errorCode == '00'){
                const regionList = res.data.rows;
                that.areaList[index].values = [
                    {Name:'请选择'},
                    ...regionList
                ];
                if(index < 2){
                    that.areaList[index + 1].values = [];
                }
                that.areaList = [...that.areaList];
                // console.log(that.areaList);
            }
            
        })
    },
    // 当地区选择变化时
    onAreaChange(picker,values,index){
        // console.log(values);
        if (index < 2) {
            this.getArea(values[index].Code, index + 1);                        //传参 参数为上层选择的地区的code
        }
    },

    //点击取消
    onCancel() {
        this.addArea = false;
    },

    //点击确定
    onAreaConfirm(value) {
        console.log(value);
        if (value[2] && value[2].Code) {
            this.checkCity = value[2];
            this.areaName = value[0].Name + value[1].Name + value[2].Name;
        } else if (value[1] && value[1].Code) {
            this.checkCity = value[1];
            this.areaName = value[0].Name + value[1].Name;
        } else if (value[0] && value[0].Code) {
            this.checkCity = value[0];
            this.$toast('请选择省市区');
        } else {
            this.checkCity = null;
        }
        this.sureAdd = value;
        console.log(this.sureAdd);
        this.addArea = false;
    },

    // 选择客户类型
    customerBtn(value){
      var that=this;
      console.log(value);
      if(value == '医疗机构'){
        that.showPapersList = that.listImg;
      }else if(value == '单体药店'){
        that.showPapersList = that.listImg1;
      }else if(value == '连锁药店'){
        that.showPapersList = that.listImg2;
      }else if(value == '商业公司'){
        that.showPapersList = that.listImg3;
      }
      that.customerType=value;
      that.isCustomer=false;
      that.qualifiObj_jh = {};
      that.qualifiObj = {};
    },

    // 上传图片
    upload(obj,name) {
		// 点击触发按钮
		this.$refs.input.value = ""; //先清除上一次文件，避免上传同一张无效
		this.$refs.input.dataset.type = obj;
		this.$refs.input.dataset.name=name
		this.$refs.input.click();
    },
    /**
     *  上传图片
     */
    changeImg(e,name) {
     var that = this;
     var file = that.$refs.input.files;

      var isLt = file[0].size / 1024 / 1024 < 10;
      if(!isLt){
        that.$toast("上传图片大小不能超过10MB!");
        return;
      }

     var formData = new FormData();
     formData.append("file", file[0]);
     that.$toast.loading({ message: "上传中.." });
     var key = event.currentTarget.dataset.type;
     var name=event.currentTarget.dataset.name;
     if (!key) {
       that.$toast("参数无效");
       return;
     }

      var  goOrder = localStorage.getItem("goOrder");
      if(goOrder=='true'){//新版
          that.public.request(
           "post",
            "/api/CompanyInfo/UploadCompanyImg?key=" + key,
             formData,
            (res) => {
              that.$toast.clear();
              if (res.data.errorCode == "00") {
                var imgSrc = res.data.rows;
                // that.$set(that.qualifiObj, key,{'src':imgSrc,'tag':name});
                if(imgSrc.indexOf("http") != -1){
                  that.hostUrl = '';
                }
                if(name == '其他证件'){
                  that.otherNum ++;
                  var obj = {name: '其他证件',type: 'qtzj'+ that.otherNum}
                  
                  // 其他证件展示图片
                  var jh_key = obj.type;
                  var otherObj = that.showPapersList.pop();
                  that.showPapersList.push(obj);
                  that.$set(that.qualifiObj_jh,jh_key,imgSrc);

                  // 其他证件按钮
                  otherObj.type = obj.type+'Other';
                  that.showPapersList.push(otherObj);
                  that.$set(that.qualifiObj_jh,otherObj.type,'');
                  
                  // 其他证件是一个数组
                  // that.$set(that.qualifiObj, key,imgSrc);
                  that.otherList.push(imgSrc);
                }else{
                  that.$set(that.qualifiObj_jh,key,imgSrc);
                  that.$set(that.qualifiObj, key,imgSrc); 
                }
                
              } else {
                that.$toast(res.data.errorMsg);
              }
            },
            "upImg"
          );

      }else{//老版
            that.public.request('post',"/api/sysUserRegister/UploadBizFile",formData,res => {        
                that.$toast.clear();
                if(res.data.errorCode == '00'){
                    that.hostUrl=''
                    var imgSrc = res.data.rows;
                    if(name == '其他证件'){
                      that.otherNum ++;
                      var obj = {name: '其他证件',type: 'qtzj'+ that.otherNum}
                      
                      // 其他证件展示图片
                      var jh_key = obj.type;
                      var otherObj = that.showPapersList.pop();
                      that.showPapersList.push(obj);
                      that.$set(that.qualifiObj_jh,jh_key,imgSrc);

                      // 其他证件按钮
                      otherObj.type = obj.type+'Other';
                      that.showPapersList.push(otherObj);
                      that.$set(that.qualifiObj_jh,otherObj.type,'');
                      
                      // 其他证件是一个数组
                      // that.$set(that.qualifiObj, key,imgSrc);
                      that.otherList.push(imgSrc);
                    }else{
                      that.$set(that.qualifiObj_jh,key,imgSrc);
                      that.$set(that.qualifiObj, key,imgSrc); 
                    }
                  //  that.$set(that.qualifiObj, key, {'src':imgSrc,'tag':name});
                    //  that.$set(that.qualifiObj, key,imgSrc); 
                }else{
                    that.$toast(res.data.errorMsg);
                }
            },'upImg')
      }   
    },

    // 提交注册
    sumbitRegister() {
      var that = this;
      var customId = that.customId;
      var ContentJson = that.qualifiObj;
      that.qualifiObj.qtzj = that.otherList;
      if(that.orgid!='fe0024bb88ab4f259f9941f8c1825fa2' && that.orgid!='efd712fe5a714c1e9db1d0d1e9875917'){ // 不是林创
            
        var checkBool = that.checkMust(ContentJson);
        if(!checkBool){
          return false;
        }
      }else{
        if (!ContentJson.xkzLicence) {
          that.$toast("请添加许可证");
          return false;
        }
        if (!ContentJson.sfzFront) {
          that.$toast("请添加身份证正面");
          return false;
        }
        if (!ContentJson.sfzVerso) {
          that.$toast("请添加身份证反面");
          return false;
        }
        if (!ContentJson.yzym) {
          that.$toast("请添加印章印模");
          return false;
        }
      }
   
      var json = JSON.stringify(ContentJson);
        // console.log("=============josn===============",json);
      var data = {
        CustomerId: customId,
        ContentJson: json,

      };
      
      var data2={
        Mobile: that.Mobile,
        Code: that.Code,
        Password: "123456", //that.password
        RePwd: "123456", //that.repassword
        OrgName: that.OrgName,
        // AreaProvince: that.sureAdd[0].Code,
        // AreaCode: that.sureAdd[1].Code,
        // ChainAreasCode: that.sureAdd[2].Code,
        Name: that.Mobile, //that.Name,
        BizAddress: "", //that.BizAddress
        inviteCode:that.inviteCode,
        ContentJson: json,

      }
      if(that.orgid!="fe0024bb88ab4f259f9941f8c1825fa2" && that.orgid!='efd712fe5a714c1e9db1d0d1e9875917'){ // 不是林创
        // data2.ChainAreasCode = that.sureAdd[2].Code;
		let customerType = that.customerType;
        if(customerType == '医疗机构'){
          data2.ExtendType = 30;
        }else if(customerType == '单体药店'){
          data2.ExtendType = 50;
        }else if(customerType == '连锁药店'){
          data2.ExtendType = 40;
        }else if(customerType == '商业公司'){
          data2.ExtendType = 10;
        }
      }
      var goOrder = localStorage.getItem("goOrder");
      that.onceClick = true;
      if(goOrder=='true'){//注册新版，注册即下单
        that.public.request("post","/api/CompanyInfo/SaveCompanyImg",data,(res) => {
          that.onceClick = false;
          if (res.data.errorCode == "00") {
            that.$toast("注册成功");
            setTimeout(function() {  
				if(that.IsMiniProgram()){
					that.wx.miniProgram.reLaunch({
					    url: window.baseUrl.xcxLogin?'/pages/login/index':'/pages/login/oldLogin/index',
					})
				}else{
					//返回登录界面
					that.$router.go(-1);
					// 跳转首页
					 // that.$router.push({
					 //    path: "/",
					 //    query: {
					 //      refresh: "refresh",
					 //    },
					 //  });
				}
            }, 2000);
          } else {
            that.$toast(res.data.errorMsg);
          }
        }
       );
      }else if(goOrder=='false') {//老版注册
		data2.IsChainAreasEmpty = true;
		that.public.request('post','/api/sysUserRegister/bindAndRegisterMobile',data2,res => {
          that.onceClick = false;
                if(res.data.errorCode == '00'){
                    that.$toast('注册成功，请登录');
                    setTimeout(function(){
						if(that.IsMiniProgram()){
							that.wx.miniProgram.reLaunch({
							    url: window.baseUrl.xcxLogin?'/pages/login/index':'/pages/login/oldLogin/index',
							})
						}else{
							that.$router.go(-1);
						}
                    },2000)
                }else{
                    that.$toast(res.data.errorMsg);
                }
            })

      }
    
    },
	
	//判断是否小程序浏览器加载
	IsMiniProgram(){
		let mini = false;
		if (/MicroMessenger/i.test(navigator.userAgent)) {
			//ios的ua中无miniProgram，很坑爹,但都有MicroMessenger（表示是微信浏览器）
			this.wx.miniProgram.getEnv((res) => {
				if (res.miniprogram) {
					mini = true;
				}
			});
		}
		return mini;
	},
	
    // 上一页
    uponPageBtn(){
      var that=this;
     that.step=1;

    },

    // 获取验证码
    getCode() {
      var that = this;
      if (!that.disabled) {
        that.disabled = true;
      }
      setTimeout(function() {
        that.disabled = false;
      }, 2000);
      var reg = /^1[0-9]{10}$/;
      if (!that.Mobile) {
        that.$toast("手机号不能为空");
        return;
      }
      if (!reg.test(that.Mobile)) {
        that.$toast("请输入正确的手机号");
        return;
      }
      var data = {
        Mobile: that.Mobile,
      };
      that.public.request(
        "post",
        "/api/AppCommon/IsMobileValid",
        data,
        (res) => {
          if (res.data.errorCode == "00") {
            // 倒计时
            var num = 60;
            var time = setInterval(function() {
              if (num == 0) {
                clearInterval(time);
                that.disabled = false;
                that.sendTime = "获取验证码";
              } else {
                num--;
                that.sendTime = num + "秒后重试";
                that.disabled = true;
              }
            }, 1000);
            data.Id = localStorage.getItem("userId");         
               
                that.public.request(
                "post",
                "/api/AppCommon/SendMobileCode",
                data,
                (res) => {
                if (res.data.errorCode == "00") {
                  that.$toast("验证码发送成功");
                } else if (res.data.errorCode == "99") {
                  that.$toast(res.data.errorMsg);
                }
              }
             );    
          } else if (res.data.errorCode == "99") {
            that.$toast(res.data.errorMsg);
          }
        }
      );
    },

    /**
     *  注册下单 
     */
    go() {
		var that = this;
		if(that.customerType == ''){
			that.$toast("请选择客户类型");
			return false;
		}
		if (!that.OrgName) {
			that.$toast("请输入机构名称");
			return false;
		}
		if (!that.Mobile) {
			that.$toast("请输入手机号");
			return false;
		}
		if (!that.Code) {
			that.$toast("请输入验证码");
			return false;
		}
		if (!that.inviteCode) {
		  that.$toast("请输入邀请码");
		  return false;
		} 
		
		
		// if(orgId != 'fe0024bb88ab4f259f9941f8c1825fa2' && orgId != 'efd712fe5a714c1e9db1d0d1e9875917'){
		// 	if(that.sureAdd.length<=0){
		// 		that.$toast("请选择所在区域");
		// 		return false;
		// 	}
		// }
		// if (!that.password) {
		// 	that.$toast("请输入密码");
		// 	return false;
		// }
		// if (!that.repassword) {
		// 	that.$toast("请再次输入密码");
		// 	return false;
		// }
		// if (that.password != that.repassword) {
		// 	that.$toast("密码不一致，请重新输入");
		// 	return false;
		// }
		// if (!that.Name) {
		// 	that.$toast("请输入用户姓名");
		// 	return false;
		// }
		// if (!that.BizAddress) {
		// 	that.$toast("请输入单位地址");
		// 	return false;
		// }
		// if(orgId != 'fe0024bb88ab4f259f9941f8c1825fa2' && orgId != 'efd712fe5a714c1e9db1d0d1e9875917'){
		// 	if(that.customerType == ''){
		// 		that.$toast("请选择客户类型");
		// 		return false;
		// 	}
		// }
		

      // if(!data.AreaProvince || !data.AreaCode || !data.ChainAreasCode){
      //   that.$toast("请选择所在区域");
      //   return false;
      // }

     
	  //判断手机验证码是否有效
		that.public.request("post","/api/sysUserRegister/IsValidMobileCode",{
			Mobile: that.Mobile,
			Code: that.Code
		},(res) => {
			if (res.data.errorCode == "00") {
				that.nextStep();
			}else{
				that.$toast(res.data.errorMsg);
			}
		  }
		);
      
    },
	
	/**
	 * 下一步
	 */
	nextStep(){
		let that = this;
		// var data = JSON.stringify(that._data);
		let data = {
		  Mobile: that.Mobile,
		  Code: that.Code,
		  Pwd: "123456", //that.password
		  RePwd: "123456", //that.repassword
		  OrgName: that.OrgName,
		  // AreaProvince: that.sureAdd[0].Code ? that.sureAdd[0].Code : '',
		  // AreaCode: that.sureAdd[1].Code ? that.sureAdd[1].Code : '',
		  // ChainAreasCode: that.sureAdd[2].Code ? that.sureAdd[2].Code : '',
		  BizAddress: "", //that.BizAddress
		  inviteCode: that.inviteCode,
		  Name: that.Mobile  //that.Name
		};
		//判断邀请码是否正确
		that.public.request('get','api/InviteCode/GetInviteCode?orgId='+localStorage.orgId,'',(res)=>{
			res.data.rows.forEach(item=>{
				if(that.inviteCode===item.Code){
					that.isInviteCode=true
				}
		    })
		    if(that.inviteCode===""){
		        that.isInviteCode=true;
		    }
		    if(that.isInviteCode===false) {
		        that.$toast("邀请码无效！");
		        return false
		      }
		    //下一步
		    that.$toast.loading({
		        message: "操作中...",
		        duration: 1200,
		    });
		    var goOrder = localStorage.getItem("goOrder");
		    if(goOrder=='true'){//goOrder为true 新版
				let customerType = that.customerType;
				if(customerType == '医疗机构'){
				  data.ExtendType = 30;
				}else if(customerType == '单体药店'){
				  data.ExtendType = 50;
				}else if(customerType == '连锁药店'){
				  data.ExtendType = 40;
				}else if(customerType == '商业公司'){
				  data.ExtendType = 10;
				}
		        that.public.request("post", "/api/SysUserRegister/SaveRegUser",data,(res) => {
		            // console.log("下一步，第一次上传数据", res);
		            if (res.data.errorCode == "00") {
		              
		              sessionStorage.setItem("member_token", res.data.rows);
		              sessionStorage.setItem("customer", res.data.extend.OrgId);
		              that.customId = res.data.extend.OrgId;
					  // that.step = 2;  新版注册即下单，不用上传资质
					  that.$toast("注册成功");
					  setTimeout(function() {  
					  	if(that.IsMiniProgram()){
					  		that.wx.miniProgram.reLaunch({
					  		    url: window.baseUrl.xcxLogin?'/pages/login/index':'/pages/login/oldLogin/index',
					  		})
					  	}else{
					  		//返回登录界面
					  		that.$router.go(-1);
					  		// 跳转首页
					  		 // that.$router.push({
					  		 //    path: "/",
					  		 //    query: {
					  		 //      refresh: "refresh",
					  		 //    },
					  		 //  });
					  	}
					  }, 2000);
		            } else {
						 that.$toast(res.data.errorMsg);
		            }
		          }
		        );
		    }else{//goOrder为false  老流程，接口在下一步中
		      that.step = 2;
		    }
		})
	},
    
    // 验证必填项图片
    checkMust(ContentJson){
      console.log(ContentJson);
      var that = this;
      var customerType = that.customerType;   // 药店类型
      
      if(customerType == '医疗机构'){
        if (!ContentJson.yljgzyxkz) {
          that.$toast("请添加医疗机构执业许可证");
          return false;
        }
        if (!ContentJson.cgwts) {
          that.$toast("请添加采购委托书");
          return false;
        }
        if (!ContentJson.cgryCard) {
          that.$toast("请添加采购人员身份证扫描件");
          return false;
        }
        if (!ContentJson.shwts) {
          that.$toast("请添加收货委托书");
          return false;
        }
        if (!ContentJson.shryCard) {
          that.$toast("请添加收货人身份证扫描件");
          return false;
        }
        
      }else if(customerType == '单体药店'){
        if (!ContentJson.yyzz) {
          that.$toast("请添加营业执照");
          return false;
        }
        if (!ContentJson.ndbg) {
          that.$toast("请添加年度报告");
          return false;
        }
        if (!ContentJson.ypyyxkz) {
          that.$toast("请添加药品经营许可证");
          return false;
        }
        if (!ContentJson.cgwts) {
          that.$toast("请添加采购委托书");
          return false;
        }
        if (!ContentJson.cgryCard) {
          that.$toast("请添加采购人员身份证扫描件");
          return false;
        }
        if (!ContentJson.shwts) {
          that.$toast("请添加收货委托书");
          return false;
        }
        if (!ContentJson.shryCard) {
          that.$toast("请添加收货人身份证扫描件");
          return false;
        }
      }else if(customerType == '连锁药店'){
        if (!ContentJson.yyzz) {
          that.$toast("请添加营业执照");
          return false;
        }
        if (!ContentJson.ndbg) {
          that.$toast("请添加年度报告");
          return false;
        }
        if (!ContentJson.ypyyxkz) {
          that.$toast("请添加药品经营许可证");
          return false;
        }
        if (!ContentJson.cgwts) {
          that.$toast("请添加采购委托书");
          return false;
        }
        if (!ContentJson.cgryCard) {
          that.$toast("请添加采购人员身份证扫描件");
          return false;
        }
        if (!ContentJson.shwts) {
          that.$toast("请添加收货委托书");
          return false;
        }
        if (!ContentJson.shryCard) {
          that.$toast("请添加收货人身份证扫描件");
          return false;
        }
        if (!ContentJson.xkzLicence) {
          that.$toast("请添加开票信息");
          return false;
        }
        
      }else if(customerType == '商业公司'){
        if (!ContentJson.yyzz) {
          that.$toast("请添加营业执照");
          return false;
        }
        if (!ContentJson.ndbg) {
          that.$toast("请添加年度报告");
          return false;
        }
        if (!ContentJson.ypyyxkz) {
          that.$toast("请添加药品经营许可证");
          return false;
        }
        if (!ContentJson.cgwts) {
          that.$toast("请添加采购委托书");
          return false;
        }
        if (!ContentJson.cgryCard) {
          that.$toast("请添加采购人员身份证扫描件");
          return false;
        }
        if (!ContentJson.shwts) {
          that.$toast("请添加收货委托书");
          return false;
        }
        if (!ContentJson.shryCard) {
          that.$toast("请添加收货人身份证扫描件");
          return false;
        }
        if (!ContentJson.xkzLicence) {
          that.$toast("请添加开票信息");
          return false;
        }
        if (!ContentJson.txdcb) {
          that.$toast("请添加体系调查表");
          return false;
        }
      }
      return true;
      
    },
  
  },
};
</script>
<style scoped>
span.mui-icon {
  margin-left: 2%;
}
.stepRegister {
  display: flex;
  overflow: hidden;
  margin-top: 4%;
  margin-bottom: 4%;
}
.stepRegister .first,
.stepRegister .third {
  width: 33%;
  float: left;
  text-align: center;
  position: relative;
}
.stepRegister .first .number,
.stepRegister .second .number,
.stepRegister .third .number {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  margin: 0 auto;
  margin-bottom: 10px;
}
/* .stepRegister .first .number,
.stepRegister .second .number {
  background: rgb(0, 182, 204);
} */

.stepRegister .first .number {
  background: rgb(0, 182, 204);
}
.stepRegister .second .number {
  background: #9b9b9b;;
} 

.stepRegister .second .starline,.stepRegister .second .starnumber{
   background: rgb(0, 182, 204)!important;;
}



.star {
  color: rgb(0, 182, 204);
}
.stepRegister .first .retail,
.stepRegister .second .retail {
  font-size: 14px;
}
.stepRegister .first .right-line {
  width: 46%;
  height: 2px;
  position: absolute;
  top: 18%;
  right: 0;
   background: rgb(0, 182, 204); 
}
.stepRegister .second .left-line,
.right-line {
  background: #9b9b9b;
}
.stepRegister .second {
  width: 34%;
  float: left;
  text-align: center;
  position: relative;
}
.stepRegister .second .left-line {
  width: 46%;
  height: 2px;
  position: absolute;
  top: 18%;
  left: 0;
}

.stepRegister .second .right-line {
  width: 46%;
  height: 2px;
  position: absolute;
  top: 18%;
  right: 0;
}

.stepRegister .first,
.stepRegister .third {
  width: 33%;
  float: left;
  text-align: center;
  position: relative;
}
.stepRegister .third .left-line {
  width: 46%;
  height: 2px;
  background-color: #9b9b9b;
  position: absolute;
  top: 18%;
  left: 0;
}
.stepRegister .third .number {
  background-color: #9b9b9b;
}
.stepRegister .third .retail {
  font-size: 14px;
  color: #9b9b9b;
}

.mui-input-row {
  position: relative;
}
.mui-input-row {
  clear: left;
  overflow: hidden;
  border-color: rgb(0, 182, 204);
  border-bottom: 0.01rem solid rgb(0, 182, 204);
}
.mui-icon {
  font-family: Muiicons;
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  display: inline-block;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}
img {
  border: 0;
}
.formmember {
  width: 85%;
  padding: 30px 0;
  margin: 0 auto;
}
.formmember input {
  padding: 17px 15px;
  border-radius: 1px;
  padding-left: 12%;
  height: 1rem;
}
.formmember input {
  border: 0 solid #9b9b9b;
}
.sendCode {
  background: rgb(0, 182, 204);
  margin-top: 0.12rem;
}

.grey-back {
  /* background: #9b9b9b !important; */
      color: #9b9b9b;
}

/* 上传企业信息照片 */
.upload-info {
  margin-bottom: 1rem;
}
.upload-info .info-title {
  width: 100%;
  height: 0.8rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
  font-size: 0.32rem;
  border-bottom: 0.02rem solid #eee;
}
.upload-info .info-list {
  flex-wrap: wrap;
}
.upload-info .info-item {
  width: 50%;
  padding: 0.2rem;
  box-sizing: border-box;
}
.upload-info .info-item .upload-img {
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.08rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.upload-info .info-item .upload-img img {
  width: 100%;
  max-height: 2.4rem;
}
.upload-info .info-item .item-title .title-name {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 0.28rem;
}
.upload-info .info-item .item-title .title-name.blue{
  color:#0a54f3;
}
.upload-info .info-item .item-title .title-tip {
  text-align: center;
}
.upload-info .info-item .item-title .title-tip span {
  font-size: 0.28rem;
}

/* 提交 */
.btn{
  display: flex;
  justify-content: space-around;

  position: fixed;
   left: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
}
.page-btn,.submintBtn{
   background: #33cccc;
   width: 45%;
   height: 0.9rem;
    line-height: 0.9rem;
  text-align: center;
    color: #fff;
    font-size: 0.3rem;

}

.invite-box{
	
}
.register-btn{
	margin-top: 150px;
}
</style>
