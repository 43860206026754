<template>
    <div class="container">
        <!-- 商品列表 -->
        <div class="floor-box">
            <div class="floor-list" v-for="(item,index) in bookList" :key="index">
                <div class="floor-item flex-wrap"  @click="toGoodsDetail">
					<div class="img-box">
						<img v-if="item.ImgUrl" class="floor-good" :src="item.ImgUrl" alt="商品图片">
						<img v-else class="floor-good" src="@/assets/images/no_good.png" alt="商品图片">
					</div>
                    <div class="floor-specifi">
                        <span class="specifi-name">名称：{{item.SpName}}</span>
                        <span class="specifi-num">规格：{{item.Style}}</span>
                        <span class="specifi-company">厂家：{{item.Factory}}</span>
                    </div>
					<div class="del" @click="deleteBook(item.Id,index)">删除</div>
                </div>
            </div>
        </div>

        <div class="loading-box flex-center" v-if="noMore && bookList.length>0">
            <span class="load-txt">已经到底了！</span>
        </div>
        <!-- 加载样式 -->
        <div class="loading-box flex-center" v-if="showLoading && bookList.length>0">
            <img class="load-img" src="@/assets/images/index/loading.gif" alt="加载图片">
            <span class="load-txt">正在加载</span>
        </div>
        
        <!-- 操作 -->
       <!-- <div class="operate-height" v-if="bookList.length > 0">
            <div class="operate-box flex-wrap1">
                <div class="but-item red" @click="deleteWhole">清空记录</div>
                <div class="but-item blue" @click="deleteSelectGood">删除</div>
            </div>
        </div> -->

        <!-- 暂无记录 -->
        <div class="no-data" v-if="bookList.length == 0">
            <img src="@/assets/images/nore.png" alt="暂无数据">
            <span>暂无登记记录</span>
        </div>
		
		<div class="add-box">
			<button class="add-btn" @click="toEditBook('')">新增求购商品</button>
		</div>

    </div>
</template>

<script>
export default {
    data(){
        return{
            browseShow: true,
            page: 1,
            noMore: false,
            showLoading: true,
            bookList: [],
            ids: [],    // 选中ID的集合
        }
    },
    created(){
        var that = this;
        that.getbookList();
    },
    mounted(){
        window.addEventListener('scroll', this.onReachBottom);
    },
    destroyed(){
        window.removeEventListener('scroll', this.onReachBottom);
    },
    methods:{
		
		// 求购登记列表
		getbookList(){
		    var that = this;
		    that.$toast.loading({
		        message: '加载中',
		        duration: 0
		    })
		    that.public.request('get','/api/sysUserInfo/GetMyList',{
		        OrgId: localStorage.getItem('orgId'),
				page: that.page,
				rows: 10,
		    },res => {
		        that.$toast.clear();
		        if(res.data.errorCode == '00'){
		            var curArr = res.data.rows ? res.data.rows : [];
		            that.bookList = that.page == 1?res.data.rows:that.bookList.concat(curArr);;
					that.noMore = curArr.length < 10?true:false;
					that.showLoading = curArr.length < 10?false:true;
		        }else{
		           that.$toast(res.data.errorMsg)
		        }
		    })
		},
		
        // 删除
        deleteBook(id,i){
			let that = this;
            that.$dialog.confirm({
                message: '确定删除吗',
            }).then(() => {
                that.public.request('post','/api/sysUserInfo/DeleteMyListInfo',{
                    id: id,
                },res => {
                    if(res.data.errorCode == '00'){
                        that.bookList.splice(i,1);
                    }else{
						that.$toast(res.data.errorMsg)
					}
                })
            }).catch(() => {
                // console.log('取消');
            })
            
        },

        //到达底部
        onReachBottom(){
            var that = this;
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
            //变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
            //滚动条到底部的条件
            // console.log(scrollTop);
            if(scrollTop>0){
                if(scrollTop + windowHeight + 17>=scrollHeight){
                    that.page = that.page + 1;
                    if(!that.noMore){
						that.getbookList();
                    }else{
                        console.log('已经到底了');
                    }
                }
            }

        },

        // 跳转详情
        toGoodsDetail(){

        },
		/**
		 * 新增
		 */
		toEditBook(id){
			this.$router.push({
			  path: "/editBuyBook",
			  query: {
			    id: id,
			  },
			});
		}

    }
}
</script>


<style scoped>
    /* 商品列表页面 */
    .floor-box{ width:100%;padding:0.2rem;box-sizing:border-box; }
    .floor-list{ width:100%;padding:0.2rem;box-sizing:border-box;background:#fff;box-shadow:0 0 0.1rem #eee;border-radius:0.2rem;margin-bottom:0.28rem; }
    /* .floor-list .floor-item{ display: flex;box-sizing:border-box; } */
	.img-box{width: 1.6rem;height:1.6rem; margin-right: 10px; display: block;}
    .floor-list .floor-item .floor-good{ width: 1.6rem;height:100%;}
    .floor-list .floor-item .floor-specifi{ position:relative; width:100%;}
    .floor-list .floor-item .floor-specifi span{ display:block; }
    .floor-list .floor-item .floor-specifi span.specifi-name{ font-size:0.3rem;width:4rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-weight:600; }
    .floor-list .floor-item .floor-specifi span.specifi-num{ font-size:0.24rem; }
    .floor-list .floor-item .floor-specifi span.specifi-num span{ display:inline-block; }
    .floor-list .floor-item .floor-specifi span.specifi-company{ font-size:0.28rem;width:4rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis; }
    .floor-list .floor-item .floor-specifi .specifi-price-box span{ display:inline-block; }
    .floor-list .floor-item .floor-specifi span.specifi-price{ font-size:0.32rem;margin-right:0.18rem; }
    .floor-list .floor-item .floor-specifi span.specifi-old-price{ font-size:0.22rem;text-decoration:line-through; }
    .floor-list .floor-item .floor-specifi .shop-cart{ width:0.36rem; }
    

    /* 操作 */
    .operate-height{ width:100%;height:1rem; }
    .operate-box{ position:fixed;left:0;bottom:0;z-index:1;width:100%;height:1rem;background:#fff; }
    .operate-box .but-item{ width:50%;height:100%;text-align:center;line-height:1rem;font-size:0.3rem;color:#fff; }
    .operate-box .but-item.red{ background:#f75c5c; }
    .operate-box .but-item.blue{ background:#2da5eb; }
	.add-box{
		position: fixed;
		width: 100%;
		height: 50px;
		bottom: 0;
		left: 0;
		background: #fff;
		z-index: 1;
	}
	.add-box .add-btn{
		width: 90%;
		height: 40px;
		line-height: 40px;
		margin: 5px 5%;
		text-align: center;
		background: #2da5eb;
		color: #fff;
		font-size: 15px;
		border-radius: 5px;
	}
	.del{
		position: absolute;
		right: 20px;
		font-size: 14px;
		color: red;
		padding: 2px 10px;
	}
</style>