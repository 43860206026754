<template>
    <div class="container">
        <!-- 品牌列表 -->
        <div class="brand-list">
            <div class="brand-item">
                <!-- <div class="item-img" v-if="brandInfo.Pic">
                    <img class="img-top" :src="brandInfo.Pic" alt="">
                </div> -->
                <div class="pack-list flex-wrap">
                    <div class="pack-item" v-for="(item,index) in brandList" :key="index" @click="toDetail(item.Spid)">
                        <div class="item-img">
                            <img class="shop-img" v-if="!item.SpPic" src="@/assets/images/loseImg.png" alt="商品图片">
                            <img class="shop-img" v-if="item.SpPic" :src="item.SpPic" alt="商品图片">
                        </div>
                        <div class="noShopImg" v-if="item.GoodsInfo.kcshl<=0" >
                        <!-- <div class="noShopImg"  > -->
                             售罄
                        </div>
                        <div class="item-info">
                            <span class="info-name">{{item.SpName}}</span>
                            <div class="info-wrap flex-sp">
                                <span class="info-specifi">{{item.Style}}</span>
                                <span class="info-num">数量：<span class="font-red">{{item.GoodsInfo.kcshl}}</span></span>
                            </div>
                            <span class="info-company">{{item.Factory}}</span>
                            <!-- <div class="info-time">远近效期：2020-12/2020-12</div> -->
                            <span class="info-price font-red">￥ {{item.Price}}</span>
                            <img class="shop-cart" v-if="item.GoodsInfo.kcshl>0" @click.stop="onCartShow(item.Spid)" src="@/assets/images/index/main-gwc.png" alt="">
                            <img class="shop-cart" v-if="item.GoodsInfo.kcshl<=0" src="@/assets/images/index/main-gwc_.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <!-- 加载样式 -->
			<div class="loading-box flex-center" v-if="showLoading">
				<img class="load-img" src="@/assets/images/index/loading.gif" alt="加载图片">
				<span class="load-txt">正在加载</span>
			</div>
            <div class="loading-box flex-center" v-if="noMoretip && brandList.length>6">
                <span class="load-txt">暂无数据！</span>
            </div>
            <!-- 暂无数据 -->
            <div class="empty-box" v-if="brandList.length<=0 && !showLoading">
                <div class="no-data">
                    <img src="@/assets/images/cart/noActiveDrug.png" alt="">
                    <span>暂无数据</span>
                </div>
            </div>
        </div>

        <!-- 购物车弹窗 -->
        <cart-pop :cartShowPop="cartShowPop" :goodInfo="goodInfo" @diffCartShow="closeCart"></cart-pop>
    </div>
</template>

<script>
// import brand from '../../../public/api/brand';
import brand from '@/assets/js/api/brand'
import cartPop from '../../components/cartPop.vue';
export default {
    components:{
        cartPop,
    },
    data(){
        return{
            page: 1,
            brandList: [],
            showLoading: true,  // 加载更多
            noMoretip: false,   // 没有更多数据了
            brandId: '',        // 品牌Id
            brandInfo: {},      // 品牌信息

            cartShowPop: false,     // 组件弹窗显示隐藏
            goodInfo: {},           // 组件商品信息
        }
    },
    created(){
        var that = this;
        var brandId = that.$route.query.brandId;
        that.brandId = brandId;
        that.getList(brandId);
        that.getBrandDetail(brandId);
    },
    mounted(){
        window.addEventListener('scroll', this.onReachBottom);
    },
    destroyed(){
        
        window.removeEventListener('scroll', this.onReachBottom);
    },
    methods:{
        // 组件购物车弹窗
        onCartShow(spid){
            var that = this;
            var orgId = localStorage.orgId;
            var data = {
                SpId: spid,
                OrgId: orgId,
            }
            that.public.request('post','/api/Store/QueryGoodsInfo',data,res => {
                // console.log(res);
                if(res.data.errorCode == '00'){
                    that.goodInfo = res.data.rows[0];
                    that.cartShowPop = true;
                }
            })
        },
        // 关闭组件弹窗
        closeCart(){
            var that = this;
            that.cartShowPop = false;
        },

        // 跳转详情页面
        toDetail(spid){
            this.$router.push({
                path: '/goodsDetail',
                query: {
                    spid: spid,
                }
            })
        },
        // 请求品牌详情
        getBrandDetail(brandId){
            var that = this;
            var data = {
                Id: brandId,
            };
            brand.queryDetail(data,res => {
                if(res.data.errorCode == '00'){
                    that.brandInfo = res.data.rows[0];
                }
            })
        },
        // 品牌列表
        getList(brandId){
            var that = this;
            var page = that.page;
            var data = {
                brandId: brandId,
                page: page,
                rows: 10
            };
            brand.GoodsPageList(data,res => {
                // console.log(res);
                if(res.data.errorCode == '00'){
                    var  allArr = [];
                    var initArr = that.brandList;
                    var curArr = res.data.rows ? res.data.rows : [];
                    var pageLength = curArr.length;
                    if(page>1){
                        allArr = initArr.concat(curArr);
                    }else{
                        allArr = res.data.rows;
                    }

                    that.brandList = allArr;
                    if(pageLength<6){
                        that.noMoretip = true;
                        that.showLoading = false;
                    }
                }else if(res.data.errorCode == '99'){
                    if(page<=1){
                        that.brandList = [];
                        that.noMoretip = false;
                        that.showLoading = false;
                    }else{
                        that.noMoretip = true;
                        that.showLoading = false;
                    }
                }
            })

        },
        // 到达底部
        onReachBottom(){
            var that = this;
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
            //变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
            
            if(scrollTop > 0){
                if(scrollTop + windowHeight + 5 > scrollHeight){
                    var brandList = that.brandList;
                    if(brandList.length > 0){
                        var page = that.page;
                        var noMoretip = that.noMoretip;
                        page++;
                        that.page = page;
                        if(noMoretip){
                            console.log("已经到底了");
                        }else{
                            console.log("加载更多");
                            var brandId = that.brandId;
                            that.getList(brandId);
                        }
                    }
                    
                }
            }
        },
    }
}
</script>


<style scoped>
    /* 品牌列表 */
    .brand-list .brand-item .item-img{ width:100%; }
    .brand-list .brand-item .item-img .img-top{ width:100%;max-height:1.1rem; }

    .pack-list{ width:100%;padding:0.18rem 0.25rem;box-sizing:border-box;flex-wrap:wrap; }
    .pack-list .pack-item{ width:46.3%;box-shadow:0 0 0.1rem #b9b9b9;margin-bottom:0.2rem;position: relative; }
    .pack-list .pack-item:nth-child(2n-1){margin-right: .3rem;}
    .pack-list .pack-item .item-info .info-wrap .info-num{ font-size:0.24rem;display:inline; }
    .pack-list .pack-item .item-info .info-wrap .info-num span{ display:inline; }
    .pack-list .pack-item .item-info .info-time{ font-size:0.22rem; }

    .pack-item .item-img{ width:100%; height:2.5rem;    padding: 0.2rem; box-sizing: border-box; }
    .pack-item .item-img  .shop-img{ width:2.2rem;height: 2.5rem;object-fit: contain; margin: 0 auto;}
    .pack-item .item-img .item-setime{ position:absolute; left:3%; bottom:0; right:0; padding:0.02rem 0.2rem; background:#199ed8; border-radius:0.28rem; width:80%; }
    .pack-item .item-img .item-setime img{ width:0.22rem; height:0.22rem; margin-right:0.18rem; }
    .pack-item .item-img .item-setime .settime-tit{ color:#fff; font-size:0.24rem;}
    .pack-item .item-info { width:100%; padding:0.1rem 0.12rem; box-sizing:border-box;position:relative; }
    .pack-item .item-info span{ display:block; font-size:0.3rem; }
    .pack-item .item-info span.info-name{ width:100%; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;font-weight:600; }
    .pack-item .item-info span.info-specifi{ font-size:0.24rem; }
    .pack-item .item-info span.info-company{ font-size:0.26rem; width:100%; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;font-weight:600; }
    .pack-item .item-info span.info-price{ font-size:0.32rem; }
    .pack-item .item-info span.info-old-price{ font-size:0.22rem; text-decoration:line-through; }
    .pack-item .item-info .shop-cart{ position:absolute; right:0.2rem; bottom:0.18rem; width:0.36rem; }
    .noShopImg{position:absolute;top: 0.6rem;right: 0.8rem; width: 1.6rem; height: 1.6rem;color: white; background-color: rgba(128, 127, 127, 0.8);border-radius: 50%;text-align: center;line-height: 1.6rem;font-size: .5rem; }
</style>