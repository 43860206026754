<template>
  <div class="container">
    <!-- 公司信息 -->
    <div class="company-box bor-bot2">
      <div class="company-name flex-wrap">
        <span class="name-tit">企业信息：</span>
        <span class="name-t">{{ companyInfo.dwmch }}</span>
      </div>
      <div class="company-name flex-wrap1">
        <span class="name-tit name-start">注册地址：</span>
        <span class="name-t">{{ companyInfo.dzhdh }}</span>
      </div>
      <div class="business-scope">
        <div class="business-tit">经营范围：</div>
        <div class="business-text">
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            disabled="disabled"
            v-model="companyInfo.scope"
          ></textarea>
        </div>
      </div>
    </div>
    <!-- 上传企业信息照片 -->
    <div class="upload-info">
      <div class="info-title flex-wrap">资质信息</div>
      <div class="info-list flex-wrap1 info-img">

       <div class="info-item" v-if="orgid!='efd712fe5a714c1e9db1d0d1e9875917'">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.yyzz"
              src="@/assets/images/icon_uptp.png"
              @click="upload('yyzz','营业执照')"
            />
            <img
              v-if="qualifiObj.yyzz"
              :src="qualifiObj.yyzz.src?hostUrl + qualifiObj.yyzz.src:hostUrl + qualifiObj.yyzz"
              @click="upload('yyzz','营业执照')"
            />
          </div>
          <div class="item-title">
            <span class="title-name yyzz">营业执照</span>
            <div class="title-tip">
              <span class="font-red">必填</span>
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>  

     <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.xkzLicence"
              src="@/assets/images/icon_uptp.png"
              @click="upload('xkzLicence','许可证')"
            />
            <img
              v-if="qualifiObj.xkzLicence"
              :src="qualifiObj.xkzLicence.src?hostUrl + qualifiObj.xkzLicence.src:hostUrl + qualifiObj.xkzLicence"
              @click="upload('xkzLicence','许可证')"
            />
          </div>
          <div class="item-title">
            <span class="title-name xkzLicence">许可证</span>
            <div class="title-tip">
              <span class="font-red">必填</span>
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>

       <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.sfzFront"
              src="@/assets/images/icon_uptp.png"
              @click="upload('sfzFront','身份证正面')"
            />
            <img
              v-if="qualifiObj.sfzFront"
              :src="qualifiObj.sfzFront.src?hostUrl + qualifiObj.sfzFront.src:hostUrl + qualifiObj.sfzFront"
              @click="upload('sfzFront','身份证正面')"
            />
          </div>
          <div class="item-title">
            <span class="title-name sfzFront">身份证正面</span>
            <div class="title-tip">
              <span class="font-red">必填</span>
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>

        <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.sfzVerso"
              src="@/assets/images/icon_uptp.png"
              @click="upload('sfzVerso','身份证反面')"
            />
            <img
              v-if="qualifiObj.sfzVerso"
              :src="qualifiObj.sfzVerso.src?hostUrl + qualifiObj.sfzVerso.src:hostUrl + qualifiObj.sfzVerso"
              @click="upload('sfzVerso','身份证反面')"
            />
          </div>
          <div class="item-title">
            <span class="title-name sfzVerso">身份证反面</span>
            <div class="title-tip">
              <span class="font-red">必填</span>
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>

         <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.yzym"
              src="@/assets/images/icon_uptp.png"
              @click="upload('yzym','印章印模')"
            />
            <img
              v-if="qualifiObj.yzym"
              :src="qualifiObj.yzym.src?hostUrl + qualifiObj.yzym.src:hostUrl + qualifiObj.yzym"
              @click="upload('yzym','印章印模')"
            />
          </div>
          <div class="item-title">
            <span class="title-name yzym">印章印模</span>
            <div class="title-tip">
              <span class="font-red">必填</span>
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>

        


        <!-- <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg($event)"
            name="upload_file"
            ref="yljgzyxkz"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.yljgzyxkz"
              src="@/assets/images/icon_uptp.png"
              @click="upload('yljgzyxkz','医疗机构执业许可证')"
            />
            <img
              v-if="qualifiObj.yljgzyxkz"
              :src="qualifiObj.yljgzyxkz.src?hostUrl + qualifiObj.yljgzyxkz.src:hostUrl + qualifiObj.yljgzyxkz"
              @click="upload('yljgzyxkz','医疗机构执业许可证')"
            />
          </div>
          <div class="item-title">
            <span class="title-name">医疗机构执业许可证</span>
            <div class="title-tip">
              <span class="font-red">必填</span>
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>

        <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg($event)"
            name="upload_file"
            ref="cgryCard"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.cgryCard"
              src="@/assets/images/icon_uptp.png"
              @click="upload('cgryCard','采购人员身份证扫描件')"
            />
            <img
              v-if="qualifiObj.cgryCard"
              :src=" qualifiObj.cgryCard.src?hostUrl + qualifiObj.cgryCard.src:hostUrl + qualifiObj.cgryCard"
              @click="upload('cgryCard','采购人员身份证扫描件')"
            />
          </div>
          <div class="item-title">
            <span class="title-name">采购人员身份证扫描件</span>
            <div class="title-tip">
              <span class="font-red">必填</span>
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>

        <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg($event)"
            name="upload_file"
            ref="cgwts"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.cgwts"
              src="@/assets/images/icon_uptp.png"
              @click="upload('cgwts','采购委托书扫描件')"
            />
            <img
              v-if="qualifiObj.cgwts"
              :src=" qualifiObj.cgwts.src?hostUrl + qualifiObj.cgwts.src:hostUrl + qualifiObj.cgwts"
              @click="upload('cgwts','采购委托书扫描件')"
            />
          </div>
          <div class="item-title">
            <span class="title-name">采购委托书扫描件</span>
            <div class="title-tip">
              <span class="font-red">必填</span>
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>

        <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg($event)"
            name="upload_file"
            ref="yyzz"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.yyzz"
              src="@/assets/images/icon_uptp.png"
              @click="upload('yyzz','营业执照')"
            />
            <img
              v-if="qualifiObj.yyzz"
              :src=" qualifiObj.yyzz.src?hostUrl + qualifiObj.yyzz.src:hostUrl + qualifiObj.yyzz"
              @click="upload('yyzz','营业执照')"
            />
          </div>
          <div class="item-title">
            <span class="title-name">营业执照</span>
            <div class="title-tip">
        
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>

        <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.gsp"
              src="@/assets/images/icon_uptp.png"
              @click="upload('gsp','GSP证')"
            />
            <img
              v-if="qualifiObj.gsp"
              :src="qualifiObj.gsp.src?hostUrl + qualifiObj.gsp.src:hostUrl + qualifiObj.gsp"
              @click="upload('gsp','GSP证')"
            />
          </div>
          <div class="item-title">
            <span class="title-name">GSP证</span>
            <div class="title-tip">
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>

        <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.ypyyxkz"
              src="@/assets/images/icon_uptp.png"
              @click="upload('ypyyxkz','药品经营许可证')"
            />
            <img
              v-if="qualifiObj.ypyyxkz"
              :src="qualifiObj.ypyyxkz.src?hostUrl + qualifiObj.ypyyxkz.src:hostUrl + qualifiObj.ypyyxkz"
              @click="upload('ypyyxkz','药品经营许可证')"
            />
          </div>
          <div class="item-title">
            <span class="title-name">药品经营许可证</span>
            <div class="title-tip">
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>
      
        <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.elylqx"
              src="@/assets/images/icon_uptp.png"
              @click="upload('elylqx','二类医疗器械备案凭证')"
            />
            <img
              v-if="qualifiObj.elylqx"
              :src=" qualifiObj.elylqx.src?hostUrl + qualifiObj.elylqx.src:hostUrl + qualifiObj.elylqx"
              @click="upload('elylqx','二类医疗器械备案凭证')"
            />
          </div>
          <div class="item-title">
            <span class="title-name">二类医疗器械备案凭证</span>
            <div class="title-tip">
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>

        <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.kpxx"
              src="@/assets/images/icon_uptp.png"
              @click="upload('kpxx','开票信息图片')"
            />
            <img
              v-if="qualifiObj.kpxx"
              :src="hostUrl + qualifiObj.kpxx.src?hostUrl + qualifiObj.kpxx.src:hostUrl + qualifiObj.kpxx"
              @click="upload('kpxx','开票信息图片')"
            />
          </div>
          <div class="item-title">
            <span class="title-name">开票信息图片</span>
            <div class="title-tip">
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>

        <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.zzjg"
              src="@/assets/images/icon_uptp.png"
              @click="upload('zzjg','组织机构代码证')"
            />
            <img
              v-if="qualifiObj.zzjg"
              :src="hostUrl + qualifiObj.zzjg.src?hostUrl + qualifiObj.zzjg.src:hostUrl + qualifiObj.zzjg"
              @click="upload('zzjg','组织机构代码证')"
            />
          </div>
          <div class="item-title">
            <span class="title-name">组织机构代码证</span>
            <div class="title-tip">
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>

        <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.ypjyxkz"
              src="@/assets/images/icon_uptp.png"
              @click="upload('ypjyxkz','药品经营许可证副本变更')"
            />
            <img
              v-if="qualifiObj.ypjyxkz"
              :src="hostUrl + qualifiObj.ypjyxkz.src?hostUrl + qualifiObj.ypjyxkz.src:hostUrl + qualifiObj.ypjyxkz"
              @click="upload('ypjyxkz','药品经营许可证副本变更')"
            />
          </div>
          <div class="item-title">
            <span class="title-name">药品经营许可证副本变更</span>
            <div class="title-tip">
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>

        <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.shryCard"
              src="@/assets/images/icon_uptp.png"
              @click="upload('shryCard','收货人员身份证复印件')"
            />
            <img
              v-if="qualifiObj.shryCard"
              :src="hostUrl + qualifiObj.shryCard.src?hostUrl + qualifiObj.shryCard.src:hostUrl + qualifiObj.shryCard"
              @click="upload('shryCard','收货人员身份证复印件')"
            />
          </div>
          <div class="item-title">
            <span class="title-name">收货人员身份证复印件</span>
            <div class="title-tip">
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>

        <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.shwts"
              src="@/assets/images/icon_uptp.png"
              @click="upload('shwts','收货委托书扫描件')"
            />
            <img
              v-if="qualifiObj.shwts"
              :src="hostUrl + qualifiObj.shwts.src?hostUrl + qualifiObj.shwts.src:hostUrl + qualifiObj.shwts"
              @click="upload('shwts','收货委托书扫描件')"
            />
          </div>
          <div class="item-title">
            <span class="title-name">收货委托书扫描件</span>
            <div class="title-tip">
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>

        <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.yljgzl"
              src="@/assets/images/icon_uptp.png"
              @click="upload('yljgzl','医疗机构诊疗科目核定表')"
            />
            <img
              v-if="qualifiObj.yljgzl"
              :src="hostUrl + qualifiObj.yljgzl.src?hostUrl + qualifiObj.yljgzl.src:hostUrl + qualifiObj.yljgzl"
              @click="upload('yljgzl','医疗机构诊疗科目核定表')"
            />
          </div>
          <div class="item-title">
            <span class="title-name">医疗机构诊疗科目核定表</span>
            <div class="title-tip">
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div>

        <div class="info-item">
          <input
            type="file"
            accept="image/*"
            style="display: none"
            @change="changeImg()"
            name="upload_file"
            ref="input"
          />
          <div class="upload-img">
            <img
              v-if="!qualifiObj.other"
              src="@/assets/images/icon_uptp.png" 
              @click="upload('other','其他')"
            />
            <img
              v-if="qualifiObj.other"
              :src="hostUrl + qualifiObj.other.src?hostUrl + qualifiObj.other.src:hostUrl + qualifiObj.other"
              @click="upload('other','其他')"
            />
          </div>
          <div class="item-title">
            <span class="title-name">其他</span>
            <div class="title-tip">
              <span>(复印件盖红章)</span>
            </div>
          </div>
        </div> -->

      </div>
      <div class="submitBtn" @click="submitBtn">
        保存并提交
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companyInfo: {
        dwmch: "--",
        dzhdh: "--",
        scope: "--",
      },
      qualifiObj: {}, //资历信息
      hostUrl: "",
      orgid:'',
    };
  },
  created() {
    var that = this;
    that.getCompanyInfo();
    this.hostUrl = this.requestUrl.url;
    that.orgid=localStorage.getItem('orgId');
   
  },
  methods: {
    /**
     *  获取机构信息
     */
    getCompanyInfo() {
      this.$toast.loading({
        message: "加载中",
      });
      var that = this;
      var customerId = localStorage.orgId||sessionStorage.getItem('customer');
      if (!customerId) {
        return;
      }
      that.public.request(
        "post",
        "api/CompanyInfo/GetCompanyInfo",
        { orgId: customerId },
        (res) => {

          that.$toast.clear();
          if (res.data.errorCode != "00" && !res.data.rows) {
            if(res.data.errorCode == "99"){
               that.$toast('该机构正在审核中');
            }else{
              that.$toast(res.data.errorMsg);
            }
           
            return;
          }
          var rows = res.data.rows;
          that.companyInfo = rows;         
          if (res.data.rows.imgs == null) {
              that.qualifiObj = {};
            } else {
            var obj=res.data.rows.imgs
            var j=false
            for(var i in obj){     
          
                 if(obj[i].src==undefined){
                   j=false
                   }else{
                     j=true
                     that.hostUrl=''
                   }
                  
            }
            if(res.data.rows.imgs.sfzVerso.indexOf('http')!=-1){
               that.hostUrl='';
            }
            that.qualifiObj = res.data.rows.imgs;
          }

          // console.log(that.companyInfo.imgs);
        }
      );
    },
    // 上传图片
    upload(obj,name) {
      var that = this;
      // 点击触发按钮
      // that.$refs.input.dispatchEvent(new MouseEvent("click"));
      that.$refs.input.dataset.type = obj;
      that.$refs.input.dataset.name = name;
      that.$refs.input.click();
      
    },
    /**
     *  上传图片
     */
    changeImg(obj) {
      var that = this;
      var key = event.currentTarget.dataset.type;
      var name=event.currentTarget.dataset.name;
      if (!key) {
        that.$toast("参数无效");
        return;
      }
      var file = that.$refs.input.files;
      var formData = new FormData();
      formData.append("file", file[0]);
	  that.$refs.input.value = ""; //将input value置空， 放置选择同一个文件第二次onchange无效
     
      that.$toast.loading({ message: "上传中.." });
      that.public.request(
        "post",
        "api/CompanyInfo/UploadCompanyImg?key=" + key,
        formData,
        (res) => {
      
          that.$toast.clear();

          if (res.data.errorCode == "00") {
            var imgSrc = res.data.rows;
            if(imgSrc.indexOf('http')!=-1){
              that.hostUrl='';
            }
            // that.$set(that.qualifiObj, key, {'src':imgSrc,'tag':name});
            that.$set(that.qualifiObj, key,imgSrc);
            console.log('that.qualifiObj',that.qualifiObj);
          } else {
            that.$toast(res.data.errorMsg);
          }
        }
      );
    },
    // 提交
    submitBtn() {
      var that = this
      var customId = sessionStorage.getItem('customer');
     
      var ContentJson = that.qualifiObj;
      if(that.orgid!='efd712fe5a714c1e9db1d0d1e9875917'){
        if (!ContentJson.yyzz) {
          that.$toast("请添加营业执照");
          return false;
        }
      }  
     
     if (!ContentJson.xkzLicence) {
        that.$toast("请添加许可证");
        return false;
      }
      if (!ContentJson.sfzFront) {
        that.$toast("请添加身份证正面");
        return false;
      }
      if (!ContentJson.sfzVerso) {
        that.$toast("请添加身份证反面");
        return false;
      }
      if (!ContentJson.yzym) {
        that.$toast("请输入印章印模");
        return false;
      }
    
       var json = JSON.stringify(ContentJson);
     
      // SaveCompanyImg 老的接口
      that.public.request("post", "/api/CompanyInfo/SaveCompanyImgLogin", {
			CustomerId: customId,
			ContentJson: json,
		}, (res) => {
          // console.log(res);
          if(res.data.errorCode == "00"){
            that.$toast('上传成功')
          }else if(res.data.errorCode == "96"){
              that.$toast('机构正在审核中，请重新登录')
          }else{
              that.$toast('上传失败')
          }
          
        }
      );

      
    },
  },
};
</script>

<style scoped>
/* 公司信息 */
.company-box {
  width: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
}
.company-box .company-name {
  width: 100%;
}
.company-box .company-name .name-tit {
  font-size: 0.32rem;
  width: 1.8rem;
}
.company-box .company-name .name-tit.name-start {
  align-items: flex-start;
}
.company-box .company-name .name-t {
  flex: 1;
  font-size: 0.3rem;
  color: #666;
}

.business-scope .business-tit {
  font-size: 0.3rem;
}
.business-scope .business-text {
  width: 100%;
  height: 2.3rem;
  border: 0.02rem solid #eee;
  padding: 0.2rem;
  box-sizing: border-box;
}
.business-scope .business-text textarea {
  font-size: 0.28rem;
  width: 100%;
  height: 100%;
}

/* 上传企业信息照片 */
.upload-info .info-title {
  width: 100%;
  height: 0.8rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
  font-size: 0.32rem;
  border-bottom: 0.02rem solid #eee;
}
.upload-info .info-list {
  flex-wrap: wrap;
}
.upload-info .info-item {
  width: 50%;
  padding: 0.2rem;
  box-sizing: border-box;
}
.upload-info .info-item .upload-img {
  width: 100%;
  height: 2.4rem;
  margin-bottom: 0.08rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.upload-info .info-item .upload-img img {
  width: 100%;
  max-height: 2.4rem;
}
.upload-info .info-item .item-title .title-name {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 0.28rem;
}
.upload-info .info-item .item-title .title-tip {
  text-align: center;
}
.upload-info .info-item .item-title .title-tip span {
  font-size: 0.28rem;
}
.info-img {
  margin-bottom: 1rem;
}
/* 提交 */
.submitBtn {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  background: var(--theme-color);
  height: 0.9rem;
  line-height: 0.9rem;
  text-align: center;
  color: #fff;
  font-size: 0.3rem;
}
</style>
