<template>
  <div class="container-box">
    <!-- 列表 -->
    <div class="brand-list">
      
        <shopList class="shopLists"  :list="floorList" @toDetail='toGoodDetail' @cartShow='cartShow' @onCartShow='cartActivityShow' @showSupplyDialog='showSupplyDialog'></shopList>
      <!-- 加载样式 -->
      <div class="loading-box flex-center" v-if="showLoading">
        <img class="load-img" src="@/assets/images/index/loading.gif" alt="加载图片"/>
        <span class="load-txt">正在加载</span>
      </div>
      <div class="loading-box flex-center" v-if="noMoretip && floorList.length > 4">
        <span class="load-txt">已经到底了！</span>
      </div>
      <!-- 暂无数据 -->
      <div class="empty-box" v-if="floorList.length <= 0 && !showLoading">
        <div class="no-data">
          <img src="@/assets/images/cart/noActiveDrug.png" alt="" />
          <span>暂无数据</span>
        </div>
      </div>
    </div>

    <!-- 活动商品购物车弹窗 -->
    <active-cart :cartNums='cartNum' :cartShowPop="activeCartShowPop"  :goodSpid="goodSpid" :goodDetail="goodDetail" :typePage="typePage" @diffCartShow="closeCartActive"></active-cart>
    <!-- 普通购物车弹窗 -->
    <cart-pop :cartShowPop="cartShowPop" :goodInfo="goodInfo" @diffCartShow="closeCart"></cart-pop>
	
	<!-- 缺货登记弹窗 -->
	<div class="register-popup" v-if="showSupplyStock"></div>
	<div class="register-cont" v-if="showSupplyStock">
	  <div class="regis-tit">缺货登记</div>
	  <div class="regis-item flex-wrap">
	    <span class="item-tit">商品名称：</span>
	    <div class="input-s"><input type="text" disabled v-model="supplyInfo.spmch" /></div>
	  </div>
	  <div class="regis-item flex-wrap">
	    <span class="item-tit">商品规格：</span>
	    <div class="input-s"><input type="text" disabled v-model="supplyInfo.shpgg" /></div>
	  </div>
	  <div class="regis-item flex-wrap">
	    <span class="item-tit">生产厂商：</span>
	    <div class="input-s">
	      <input type="text" disabled v-model="supplyInfo.shengccj" />
	    </div>
	  </div>
	  <div class="regis-item flex-wrap">
	    <span class="item-tit">需求数量：</span>
	    <div class="input-s">
	      <input type="number" v-model="supplyNum" placeholder="请输入需求数量"/>
	    </div>
	  </div>
	  <div class="regis-item flex-wrap">
	    <span class="item-tit">最长等待时间：</span>
	    <div class="input-s flex-wrap">
	      <input type="number" v-model="waitDay" placeholder="请输入等待天数" />
	      <span class="input-tit">天</span>
	    </div>
	  </div>
	  <div class="regis-but flex-wrap1">
	    <div class="but-around grey" @click="showSupplyStock=false">取消</div>
	    <div class="but-around blue" @click="onSupplyStock">确定</div>
	  </div>
	</div>

    <!-- 商品弹窗  加入购物车 -->
    <!-- <div class="good-popup" v-if="cartShowPop" @click="closePop()"></div>
    <div class="good-pop" v-if="cartShowPop">
      <div class="good-top flex-wrap">
        <img v-if="goodsInfo.SpPicExtend" :src="goodsInfo.SpPicExtend" alt="" />
        <img
          v-else-if="!goodsInfo.SpPicExtend"
          src="@/assets/images/no_good.png"
          alt=""
        />
        <div class="good-right flex-column">
          <div class="right-name">{{ goodsInfo.spmch }}</div>
          <div class="right-price flex-sp">
            <span class="price-tit font-red">￥{{ goodsInfo.zdshj }}</span>
            <span
              >库存：<span class="font-red">{{ goodsInfo.kcshl }}</span></span
            >
          </div>
        </div>
      </div>
      <div class="good-specifi">
        <div class="specifi-item flex-wrap">
          <div class="item-title flex-wrap">
            <span class="tit">规格：</span>
            <span class="tit-parameter"
              >{{ goodsInfo.shpgg }}/{{ goodsInfo.dw }}</span
            >
          </div>
          <span class="last-span"
            >大包装：{{ goodsInfo.jlgg }}{{ goodsInfo.dw }}</span
          >
          <span class="last-span"
            >中包装：{{ goodsInfo.zbz }}{{ goodsInfo.dw }}</span
          >
        </div>
        <div class="specifi-item">
          <div class="item-title flex-wrap">
            <span class="tit">单位：</span>
            <span class="tit-parameter">{{ goodsInfo.dw }}</span>
          </div>
        </div>
        <div class="specifi-item">
          <div class="item-title flex-wrap">
            <span class="tit">生产厂商：</span>
            <span class="tit-parameter">{{ goodsInfo.shengccj }}</span>
          </div>
        </div>
        <div class="specifi-item">
          <div class="item-title flex-wrap">
            <span class="tit">是否可拆零：</span>
            <span class="tit-parameter" v-if="goodsInfo.OnlyPackageSold"
              >不可拆零</span
            >
            <span class="tit-parameter" v-if="!goodsInfo.OnlyPackageSold"
              >可拆零</span
            >
          </div>
        </div>
        <div class="specifi-item">
          <div
            class="active-item flex-wrap"
            v-for="(item, ind) in goodsInfo.ActivityCacheDataList"
            :key="ind"
          >
            <input
              v-if="ind == 0"
              type="radio"
              v-model="selectId"
              :value="item.ActivityId"
              name="activity"
            />
            <input
              v-else
              v-model="selectId"
              :value="item.ActivityId"
              type="radio"
              name="activity"
            />
            <label>{{ item.Description }}</label>
          </div>
        </div>
        <div class="specifi-item">
          <div class="item-title flex-sp">
            <span class="tit">数量</span>
            <div class="add-subtract flex-wrap">
              <span class="add" @click="reduceNum()">-</span>
              <input
                class="num"
                type="number"
                v-model="cartNum"
                @click="editInput()"
              />
              
              <span class="cut" @click="addNum()">+</span>
            </div>
          </div>
        </div>
      </div>
      <div class="good-sure" @click="addCart()">确定</div>
    </div> -->
    <!-- 编辑商品数量弹窗 -->
    <!-- <div class="popup-box" v-if="editNumShow"></div>
    <div class="popup-cont" v-if="editNumShow">
      <div class="good-tit">编辑商品数量</div>
      <div class="good-num-box">
        <span class="reduce-g" @click="reduceNum1()">-</span>
        <input type="number" ref="editNum" v-model="cartEditNum" />
        <span class="add-g" @click="addNum1()">+</span>
      </div>
      <div class="good-btn">
        <div class="btn-g" id="cancel" @click="cancelEdit()">取消</div>
        <div class="btn-g blue" id="sure" @click="sureEdit()">确认</div>
      </div>
    </div> -->
  </div>
</template>

<script>
	import registerRequest from "@/assets/js/api/register.js";
import cartPop from '../../components/cartPop.vue';
import activeCart from '../../components/activeCartPop.vue';
import shopList from "../../components/shopList.vue"
export default {
  components:{
    cartPop,
    activeCart,shopList
  },
  data() {
    return {
      page: 1,
      showLoading: true, // 加载更多
      noMoretip: false, // 没有更多数据了
      floorList: [], // 列表数据
      floorId: "", // 楼层页面的id
      goodsInfo: {},   // 商品信息
      cartNum: 1, // 购物车数量
      selectId: "", // 选中活动的id
      noMore: false,  // 控制page快速增加

      editNumShow: false, // 编辑数字弹窗显示隐藏
      cartEditNum: 1, // 编辑弹窗输入框中的值
      token: sessionStorage.getItem("member_token"),

      cartShowPop: false,     // 普通组件弹窗显示隐藏
      goodInfo: {},           // 组件商品信息

      activeCartShowPop: false,     // 组件显示隐藏
      goodSpid: '',         // 向组件传递的spid
      goodDetail: {},     // 向组件传递商品详情
      typePage:'',    // 向组件传递页面类型

		showSupplyStock: false, //缺货登记弹窗  false隐藏  true显示
		supplyInfo: null,
		supplyNum: "", // 缺货登记需求数量
		waitDay: "", // 缺货登记等待天数
    };
  },
  created() {
    // var that = this;
    // var url = that.$route.query.url;
    // var urlObj = url ? that.public.urlObj(url) : that.$route.query;
    // that.$store.commit("setHeadTitle", urlObj.name);
    // that.floorId = urlObj.id;
    // if (that.floorId) {
    //   that.getfloorList(that.floorId);
    // }
  },
 beforeRouteEnter(to, from, next) {
   if (from.path == "/activeDetail") {
     to.meta.isRefresh = false; //活动商品详情返回不刷新
   } else{
     to.meta.isRefresh = true;
   }
   next();
  },
  mounted() {
    window.addEventListener("scroll", this.onReachBottom);
  },
  activated() {
	  if(this.$route.meta.isRefresh){
		  this.page = 1;
		  var url = this.$route.query.url;
		  var urlObj = url ? this.public.urlObj(url) : this.$route.query;
		  this.$store.commit("setHeadTitle", urlObj.name);
		  this.floorId = urlObj.id;
		  if (this.floorId) {
		    this.getfloorList(this.floorId);
		  }
	  }
  },
  deactivated(){
	  this.$store.commit("setHeadTitle", "");
  },
  destroyed() {
    window.removeEventListener("scroll", this.onReachBottom);
  },
  methods: {
    // 活动商品弹窗显示
    cartActivityShow(id,url,goodDetail){
      var that = this;
      if(goodDetail.CurActivityData.ActivityType == 0){ // 团购
        that.typePage = 'group';  
      }else if(goodDetail.CurActivityData.ActivityType == 1){  // 秒杀
        that.typePage = 'seckill'; 
      }else if(goodDetail.CurActivityData.ActivityType == 2){   // 今日特价
        that.typePage = 'bargain';
      }else if(goodDetail.CurActivityData.ActivityType == 3){ // 集采
        that.typePage = 'purch';
      }
      goodDetail.SoldPrice = goodDetail.CurActivityData.ActivityPrice;
      that.goodSpid = goodDetail.spid;
      that.goodDetail = goodDetail;
      that.activeCartShowPop = true;
    },
    // 活动商品弹窗隐藏
    closeCartActive(){
        var that = this;
        that.activeCartShowPop = false;
    },


    // 关闭普通商品组件弹窗
    closeCart() {
        var that = this;
        that.cartShowPop = false;
    },

	//缺货登记补充弹窗
	showSupplyDialog(goodInfo){
		this.supplyInfo = goodInfo;
		this.showSupplyStock = true;
	},
	
	// 缺货登记弹窗确定
	onSupplyStock() {
	  var that = this;
	  if (!that.supplyNum) {
	    that.$toast("请输入需求数量");
	    return;
	  }
	  if (!that.waitDay) {
	    that.$toast("请输入等待天数");
	    return;
	  }
	  var reg = /(^[1-9]\d*$)/;
	  if (!reg.test(that.supplyNum)) {
	    that.$toast("请输入大于0的整数");
	    return;
	  }
	  if (!reg.test(that.waitDay)) {
	    that.$toast("请输入大于0的整数");
	    return;
	  }
	
	  registerRequest.saveGoodsStockOutByDay({
	    spid: that.supplyInfo.spid,
	    goodsCount: that.supplyNum,
	    waitDays: that.waitDay,
		ReceiveId: localStorage.getItem('orgId')
	  }, (res) => {
	    if (res.data.errorCode == "00") {
	      that.$toast("登记成功");
	      that.showSupplyStock = false;
	    } else {
	      that.$toast(res.data.errorMsg);
	    }
	  });
	},

    // 跳转商品详情
    toGoodDetail(goodInfo) {
      var that = this;
      var spid=goodInfo.spid;
      var CurActivityData=goodInfo.CurActivityData
      // 普通商品详情
      if(CurActivityData == null){
        that.$router.push({
          path: "/goodsDetail",
          query: {
            spid: spid,
          },
        });
      }else{  // 活动商品详情
        if(CurActivityData.ActivityType == 0){ // 团购
          that.$router.push({
              path: '/activeDetail',
              query:{
                  type: 'group',
                  spid: spid,
                  id: CurActivityData.ActivityId,
              }
          })
        }else if(CurActivityData.ActivityType == 1){  // 秒杀
            that.$router.push({
                path: '/activeDetail',
                query:{
                  type: 'seckill',
                  spid: spid,
                  id: CurActivityData.ActivityId,
                }
            })
        }else if(CurActivityData.ActivityType == 2){  // 今日特价
          that.$router.push({
              path: '/activeDetail',
              query:{
                  spid: spid,
                  id: CurActivityData.ActivityId,
                  type:'bargain',
              }
          })
        }else if(CurActivityData.ActivityType == 3){  // 集采
          that.$router.push({
              path: '/activeDetail',
              query:{
                  type: 'purch',
                  spid: spid,
                  id: CurActivityData.ActivityId,
              }
          })
        }
      }
      
    },

    // 购物车弹窗显示
    cartShow(id,goodInfo) {
      var that = this; // 查看是否登录
      if (that.token == null) {
        var orgId = localStorage.getItem("orgId");
        that.$dialog
          .confirm({
            // 获取商品信息
            message: "你还未登录，是否登录!",
          })
          .then(() => {
            that.$router.push({
              path: "/login",
              query: {
                orgId: orgId,
                auto: 0,
              },
            });
          })
          .catch(() => {
            // console.log('取消');
          });
        return false;
      }

      that.cartShowPop = true;
      console.log(goodInfo);
      that.goodInfo = goodInfo;
    },
    
    // 获取商品信息
    getGoodsInfo(spid, orgId) {
      var that = this;
      var data = {
        SpId: spid,
        OrgId: orgId,
      };
      that.public.request("post", "/api/Store/QueryGoodsInfo", data, (res) => {
        // console.log(res);
        if (res.data.errorCode == "00") {
          that.goodsInfo = res.data.rows[0];
          if (res.data.rows[0].ActivityCacheDataList.length) {
            that.selectId =
              res.data.rows[0].ActivityCacheDataList[0].ActivityId;
          }
        } else {
          that.$toast(res.data.errorMsg);
        }
      });
    },
    

    // 获取列表数据
    getfloorList(floorId) {
      var that = this;
      that.noMore = true;   // 控制page页数快速增加(请求结果没有出来，则为true,不让page再次增加了)
      that.public.request("get","/api/indexModule/queryFloorGoodsPageList",{
          id: floorId,
		  page: that.page,
		  rows: 6,
        },(res) => {
          if (res.data.errorCode == "00") {
            var allArr = [];
            // 有效期处理
            res.data.rows.forEach((item, index) => {
              if (item.sxrq) {
                var jxq = item.sxrq.split("/");
                item.jxq = jxq[0];
              }
            });
			if (that.page == 1) {
				allArr = res.data.rows;
			} else {
				allArr = that.floorList.concat(res.data.rows);
			}
            that.floorList = allArr;
			that.noMore = res.data.rows.length < 6?true:false;
			that.noMoretip = res.data.rows.length < 6?true:false;
			that.showLoading = res.data.rows.length < 6?false:true;
          } else{
			that.$toast(res.data.errorMsg)
          }
        }
      );
    },
    // 到达底部
    onReachBottom() {
      var that = this;
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop > 0) {
        if (scrollTop + windowHeight + 5 > scrollHeight) {
          var floorList = that.floorList;
          if (floorList.length > 0) {
            if (that.noMoretip && that.noMore) {
              // console.log("已经到底了");
            } else if(!that.noMoretip && !that.noMore){
              // console.log("加载更多");
              var floorId = that.floorId;
			  that.page = that.page + 1;
              that.getfloorList(floorId);
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped>
/*列表 */

.pack-list {
  width: 100%;
  padding: 0.18rem 0.2rem;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: inherit;
}
.pack-list .pack-item {
  width: 48.3%;
  box-shadow: 0 0 0.1rem #d1d0d0;
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
  background: #fff;
  padding: 0.18rem;
  box-sizing: border-box;
}
.pack-list .pack-item:nth-child(2n) {
  margin-right: 0;
}
.pack-list .pack-item .item-info .info-wrap .info-num {
  font-size: 0.24rem;
  display: inline;
}
.pack-list .pack-item .item-info .info-wrap .info-num span {
  display: inline;
}
.pack-list .pack-item .item-info .info-time {
  font-size: 0.22rem;
}

.pack-item .item-img {
  width: 100%;
  height: 2.7rem;
}
.pack-item .item-img .shop-img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.pack-item .item-img .item-setime {
  position: absolute;
  left: 3%;
  bottom: 0;
  right: 0;
  padding: 0.02rem 0.2rem;
  background: #199ed8;
  border-radius: 0.28rem;
  width: 80%;
}
.pack-item .item-img .item-setime img {
  width: 0.22rem;
  height: 0.22rem;
  margin-right: 0.18rem;
}
.pack-item .item-img .item-setime .settime-tit {
  color: #fff;
  font-size: 0.24rem;
}
.pack-item .item-info {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}
.pack-item .item-info span {
  display: block;
  font-size: 0.26rem;
}
.pack-item .item-info span.info-name {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
}
.pack-item .item-info span.info-specifi {
  font-size: 0.24rem;
}
.pack-item .item-info span.info-company {
  font-size: 0.26rem;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
}
.pack-item .item-info span.info-price {
  font-size: 0.3rem;
}
.pack-item .item-info span.info-old-price {
  font-size: 0.22rem;
  text-decoration: line-through;
}
.pack-item .item-info .shop-cart {
  width: 0.36rem;
}
.pack-item .info-active {
  width: 100%;
  margin-top: 0.1rem;
  flex-wrap: wrap;
}
.pack-item .info-active .active-box {
  display: inline-block;
  padding: 0.02rem 0.08rem;
  font-size: 0.24rem;
  color: #ff1515;
  border: 0.02rem solid #ff1515;
  border-radius: 0.12rem;
  margin-right: 0.1rem;
  margin-bottom: 0.1rem;
}
.pack-item .info-active .active-box:nth-child() {
  margin-bottom: 0;
}

/* 商品弹窗 */
.good-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.good-pop {
  position: fixed;
  left: 0;
  bottom: 1.1rem;
  z-index: 2;
  background: #fff;
  width: 100%;
}
.good-pop .good-top {
  width: 100%;
  padding: 0.18rem 0.2rem;
  box-sizing: border-box;
  border-bottom: 0.02rem solid #eee;
}
.good-pop .good-top img {
  width: 1.8rem;
  max-height: 1.8rem;
  margin-right: 0.2rem;
}
.good-pop .good-top .good-right {
  flex: 1;
  height: 1.8rem;
  justify-content: space-between;
}
.good-pop .good-top .good-right .right-name {
  font-size: 0.3rem;
  font-weight: 600;
}
.good-pop .good-top .good-right .right-price span.price-tit {
  font-size: 0.5rem;
}
.good-pop .good-top .good-right .right-price span {
  font-size: 0.28rem;
}

.good-pop .good-specifi {
  width: 100%;
  padding: 0.18rem 0.2rem;
  box-sizing: border-box;
}
.good-pop .good-specifi .specifi-item {
  margin-bottom: 0.12rem;
}
.good-pop .good-specifi .specifi-item .item-title .tit {
  font-size: 0.28rem;
  color: #333;
  font-weight: 600;
  display: block;
  margin-right: 0.2rem;
}
.good-pop .good-specifi .specifi-item .item-title .tit-parameter {
  font-size: 0.28rem;
  color: #999;
}
.good-pop .good-specifi .specifi-item .last-span {
  margin-left: 0.28rem;
  color: #999;
}
/* 商品加减 */
.add-subtract {
  flex: 1;
  justify-content: flex-end;
}
.add-subtract .add {
  font-size: 0.36rem;
  border: 0.05rem solid #ff1515;
  color: #ff1515;
  border-radius: 50%;
  display: inline-block;
  width: 0.38rem;
  height: 0.38rem;
  text-align: center;
  line-height: 0.3rem;
  box-sizing: border-box;
}
.add-subtract .num {
  font-size: 0.3rem;
  margin: 0 0.2rem;
  width: 1rem;
  text-align: center;
  border: 0.02rem solid #999;
  border-radius: 0.12rem;
  line-height: inherit;
}
.add-subtract .cut {
  font-size: 0.36rem;
  background: #ff1515;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  width: 0.38rem;
  height: 0.38rem;
  text-align: center;
  line-height: 0.3rem;
}

.good-pop .good-sure {
  width: 100%;
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  color: #fff;
  background: #199ed8;
  margin: 0.2rem auto 0;
  font-size: 0.3rem;
}

/* 购物车弹窗活动 */
.good-pop .good-specifi .specifi-item .active-item {
  width: 100%;
  font-size: 0.3rem;
  color: #ff1515;
  padding: 0.06rem 0;
  box-sizing: border-box;
}
.good-pop .good-specifi .specifi-item .active-item label {
  margin-left: 0.2rem;
}

/* 编辑商品数量弹窗 */
.popup-box {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 11000;
}
.popup-cont {
  position: fixed;
  width: 70%;
  padding: 0.2rem 0 0;
  box-sizing: border-box;
  background: #fff;
  border-radius: 0.12rem;
  left: 15%;
  top: 25%;
  z-index: 11001;
}
.popup-cont .good-tit {
  width: 100%;
  text-align: center;
  font-size: 0.3rem;
  margin-bottom: 0.2rem;
}
.popup-cont .good-num-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.1rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
}
.popup-cont .good-num-box .reduce-g {
  font-size: 0.39rem;
}
.popup-cont .good-num-box input {
  width: 2rem;
  height: 0.6rem;
  padding: 0.1rem;
  margin: 0 0.12rem;
  text-align: center;
  border: 0.02rem solid #c9c4c4;
  border-radius: 0.12rem;
}
.popup-cont .good-num-box .add-g {
  font-size: 0.39rem;
}
.popup-cont .good-btn {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

.popup-cont .good-btn .btn-g {
  width: 50%;
  font-size: 0.3rem;
  text-align: center;
  color: #999;
}
.popup-cont .good-btn .btn-g.blue {
  color: #58a0f2;
}
.noActive{
  height: 0.5rem;
}
    .icon-bj{  width: 100%;  position: absolute;top: 0rem;}
    .guiGe{ width: 60%; position: absolute; top: 2.61rem;text-align: center;  color: white; font-size: .25rem;    font-weight: 700;}
    .details{position: absolute;width: 36%; text-align: center; top: 2.12rem;  right: 0.02rem;display: flex; flex-direction: column; color: rgb(255, 250, 240); font-size: .24rem; align-items: flex-end;}
    .details b{width: 100%;}
    .details b:first-child{font-size: .3rem;}
	
	
/* 缺货登记弹窗 */
	.register-popup {
	  position: fixed;
	  width: 100%;
	  height: 100%;
	  top: 0;
	  left: 0;
	  background: rgba(0, 0, 0, 0.6);
	  z-index: 99;
	}
	.register-cont {
	  position: fixed;
	  width: 86%;
	  top: 4.5rem;
	  left: 7%;
	  background: #fff;
	  z-index: 7;
	  padding: 0.2rem;
	  box-sizing: border-box;
	  border-radius: 0.2rem;
	  z-index: 999;
	}
	.register-cont .regis-tit {
	  width: 100%;
	  text-align: center;
	  font-size: 0.39rem;
	}
	.register-cont .regis-item {
	  margin-bottom: 0.2rem;
	  border-bottom: 0.02rem solid #eee;
	  height: 0.8rem;
	}
	.register-cont .regis-item .item-tit {
	  width: 2.3rem;
	  font-size: 0.3rem;
	}
	.register-cont .regis-item .input-s {
	  flex: 1;
	}
	.register-cont .regis-item .input-s input {
	  font-size: 0.3rem;
	  flex: 1;
	}
	.register-cont .regis-item .input-s img {
	  width: 0.16rem;
	  margin-left: 0.2rem;
	}
	.register-cont .regis-item .input-s .input-tit {
	  font-size: 0.32rem;
	}
	
	.register-cont .regis-but {
	  width: 100%;
	  justify-content: space-around;
	}
	.register-cont .regis-but .but-around {
	  width: 1.8rem;
	  height: 0.6rem;
	  font-size: 0.3rem;
	  text-align: center;
	  line-height: 0.6rem;
	  border-radius: 0.2rem;
	  color: #fff;
	}
	.register-cont .regis-but .but-around.grey {
	  background: #c0bfbf;
	}
	.register-cont .regis-but .but-around.blue {
	  background: #0cb4e7;
	}
</style>
