<template>
    <div class="container">
        <!-- 图片轮播 -->
        <div class="seck-swiper">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                <div v-for="(item,index) in bannerList" :key="index">
                    <van-swipe-item  v-if="bannerList.length>0" >
                        <div class="item-img">
                            <img :src="item.ImgUrl" alt="">
                        </div>
                    </van-swipe-item>
                </div>
                
                <van-swipe-item v-if="bannerList.length<=0 && bannerShow">
                    <div class="item-img">
                        <img src="@/assets/images/index/v2_banner.jpg" alt="">
                    </div>
                </van-swipe-item>
                <van-swipe-item v-if="bannerList.length<=0 && bannerShow">
                    <div class="item-img">
                        <img src="@/assets/images/index/v2_banner2.jpg" alt="">
                    </div>
                </van-swipe-item>
                <van-swipe-item v-if="bannerList.length<=0 && bannerShow">
                    <div class="item-img">
                        <img src="@/assets/images/index/v2_banner3.jpg" alt="">
                    </div>
                </van-swipe-item>
            </van-swipe>
        </div>
        <!-- 今日特价列表 -->
        <div class="seck-list">
            <!-- <div class="seck-item" v-for="(item,index) in bargainList" :key="index" @click="togoodDetail(item.GoodsId,item.Id,item)">
                <div class="item-left">
                    <img class="left-img" v-if="item.ImgUrl" :src="item.ImgUrl" alt="商品图片">
                    <img class="left-img" v-else src="@/assets/images/loseImg.png" alt="商品图片">
                </div>
                 <div class="noShopImg" v-if="item.kcshl<=0">
                   售罄
                </div>
                <img class="bjt" src="../../assets/images/aa.png" alt="">
                <div class="item-info flex-column">
                    <div class="info-name">{{item.GoodsName}}</div>
                    <div class="info-specifi guige">{{item.Style}}</div>
                    
                    <div class="info-company">{{item.Factory}}</div>
                    <div class="info-stock flex-sp">
                        <span class="stock-tit">特价</span>
                       
                    </div>
                    <div>
                         <span class="info-tit font-red jiage" v-if="token">{{item.SoldPrice}}/{{item.Unit}}</span>
                         <span class="info-tit font-red jiage" v-if="!token">资质通过后可见</span>
                    </div>
                    <div class="info-seck flex-sp">
                         <span class="stock-quota">仅剩<span class="font-red">{{item.kcshl}}</span></span>
                        <div class="info-price">
                             <span class="info-old-price font-grey yuanjia" v-if="token">￥ {{item.MarketPrice}}</span>
                        </div>
                        <div class="seck-btn" v-if="item.kcshl > 0" @click.stop="onCartShow(item.GoodsId)" >
                             <img  src="@/assets/images/index/main-gwc.png" alt="">
                        </div>
                        <div class="seck-btn grey" v-else > <img  src="@/assets/images/index/main-gwc_.png" alt=""></div>
                    </div>
                    
                    
                </div>
            </div> -->
               <seckillNew :list="bargainList" :active='3' @toDetail='togoodDetail' @cartShow='onCartShow' @onCartShow='onCartShow'></seckillNew>
            <!-- 加载样式 -->
			<div class="loading-box flex-center" v-if="showLoading">
				<img class="load-img" src="@/assets/images/index/loading.gif" alt="加载图片">
				<span class="load-txt">正在加载</span>
			</div>
            <div class="loading-box flex-center" v-if="noMoretip && bargainList.length>5">
                <span class="load-txt">已经到底了！</span>
            </div>
            <!-- 暂无数据 -->
            <div class="empty-box" v-if="bargainList.length<=0 && !showLoading">
                <div class="no-data">
                    <img src="@/assets/images/cart/noActiveDrug.png" alt="">
                    <span>暂无数据</span>
                </div>
            </div>
        </div>

        <!-- 活动商品购物车弹窗 -->
        <active-cart :cartShowPop="cartShowPop" :goodSpid="goodSpid" :goodDetail="goodDetail" :typePage="typePage" @diffCartShow="closeCart"></active-cart>

        
    </div>
</template>

<script>
// import activeRequest from '../../../public/api/active';
import activeRequest from '@/assets/js/api/active';
import seckillNew from "../../components/seckillNew.vue"
// 引入活动弹窗组件
import activeCart from '../../components/activeCartPop.vue';
export default {
    components: { activeCart,seckillNew },
    data(){
        return{
            page: 1,
            showLoading: true,  // 加载更多
            noMoretip: false,   // 没有更多数据了
            bargainList: [],    // 今日特价列表
            show:false,
            bannerList: [],     // 顶部图片轮播
            bannerShow: false,  // 防止进入页面展示本地图片  false隐藏  true展示

            cartShowPop: false,     // 组件显示隐藏
            goodSpid: '',         // 向组件传递的spid
            goodDetail: {},     // 向组件传递商品详情
            typePage:'',    // 向组件传递页面类型
            token : sessionStorage.getItem('member_token')
        }
    },
    created(){
        var that = this;
  //       var headTit = that.$route.query.name;
		// that.$store.commit('setHeadTitle',headTit);
		// that.getDailySpecialList();
		that.getBannerImg();
    },
	
	beforeRouteEnter(to, from, next) {
	  if (from.path == "/activeDetail") {
	    to.meta.isRefresh = false; //活动商品详情返回不刷新
	  } else{
	    to.meta.isRefresh = true;
	  }
	  next();
	 },
	activated() {
		if(this.$route.meta.isRefresh){
			this.page = 1;
			// var headTit = this.$route.query.headTit;
			this.$store.commit('setHeadTitle',"新人特价");
			this.getDailySpecialList();
		}
	},
	deactivated(){
		  this.$store.commit("setHeadTitle", "");
	},
    mounted(){
        window.addEventListener('scroll', this.onReachBottom);
    },
    destroyed(){
        this.$store.commit('setHeadTitle','');
        window.removeEventListener('scroll', this.onReachBottom);
    },
    methods:{
        // 顶部图片轮播
        /*
            公众号-首页轮播图	20001
            公众号-秒杀列表页banner	20002
            公众号-团购列表页banner	20003
            公众号-集采列表页banner	20004
            公众号-品牌列表页banner	20005
            公众号-今日特价列表页banner	20006
            公众号-套餐包专区banner   20007
            公众号-近效期列表页banner	20008
            公众号-优惠活动列表页banner  20010
             */
        getBannerImg(){
            var that = this;
            that.public.request('post',"/api/advertItem/queryAdvertItem",{
                Code: "20018",
            }, res => {
                if(res.data.errorCode == "00"){
                    that.bannerList = res.data.rows;
                }
                that.bannerShow = true;
            })
        },
        // 商品弹窗显示
        onCartShow(spid){
            var that = this;

             // 查看是否登录     
             if(that.token == null){
                 var orgId = localStorage.getItem("orgId");
                 that.$dialog
                     .confirm({
                         message: "你还未登录，是否登录!",
                     })
                 .then(() => {
                     that.$router.push({
                         path: '/login',
                         query: {
                             orgId: orgId,
                             auto: 0,
                         }
                         });

                     })
                     .catch(() => {
                     // console.log('取消');

                 });
                 return false;
            }
            that.cartShowPop = true;
            var bargainList = that.bargainList;
            bargainList.forEach((item,i)=>{
                
                if(spid == item.GoodsId){
                    that.goodDetail = item;
                }
            })
            // that.goodDetail = item;
            that.typePage = 'new_bargain';
            that.goodSpid = spid;
        },
        // 商品弹窗隐藏
        closeCart(){
            var that = this;
            that.cartShowPop = false;
        },


        // 获取特价列表
        getDailySpecialList(){
            var that = this;
           that.noMore = true;   // 控制page页数快速增加(请求结果没有出来，则为true,不让page再次增加了)
           that.public.request("get","/api/NewComerPrice/QueryList",{
                page: that.page,
                rows:6,
            },(res) => {
               if (res.data.errorCode == "00") {
                 var allArr = [];
                 // 有效期处理
                 res.data.rows.forEach((item, index) => {
                   if (item.sxrq) {
                     var jxq = item.sxrq.split("/");
                     item.jxq = jxq[0];
                   }
					item.percentage=(item.SecKillNum/item.GoodsNum)*100;
                 });
				if (that.page == 1) {
					allArr = res.data.rows;
				} else {
					allArr = that.bargainList.concat(res.data.rows);
				}
                that.bargainList = allArr;
				that.noMore = res.data.rows.length < 6?true:false;
				that.noMoretip = res.data.rows.length < 6?true:false;
				that.showLoading = res.data.rows.length < 6?false:true;
               } else{
           			that.$toast(res.data.errorMsg)
               }
             }
           );

        },
        togoodDetail(item){
            console.log(item);
            var type = 'new_bargain';
            this.$router.push({
                path: '/activeDetail',
                query:{
                    spid: item.GoodsId,
                    id: item.Id,
                    type:type,
                }
            })
        },
        // 到达底部
        onReachBottom(){
            var that = this;
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
            //变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
            
            if(scrollTop > 0){
                if(scrollTop + windowHeight + 5 > scrollHeight){
                    var bargainList = that.bargainList;
                    if(bargainList.length > 0){
                        var page = that.page;
                        var noMoretip = that.noMoretip;
                        page++;
                        that.page = page;
                        if(noMoretip){
                            console.log("已经到底了");
                        }else{
                            console.log("加载更多");
                            
                            that.getDailySpecialList();
                        }
                    }
                    
                }
            }
        }
    }
}
</script>

<style scoped>
    /* 图片轮播 */
    .seck-swiper{ width:100%;height:3.2rem; }
    .seck-swiper .my-swipe{ width:100%;height:100%; }
    .seck-swiper .my-swipe .item-img{ width:100%;height:100%; }
    .seck-swiper .my-swipe .item-img img{ width:100%;height:100%; }

    /* 秒杀列表 */
.seck-list{display: flex; align-items: center;  flex-wrap: wrap;}
.seck-list .seck-item {position: relative; width: 46%;padding: 0.1rem;margin-bottom: .25rem;box-sizing: border-box;border:0.02rem solid #eee; box-shadow:0 0 0.1rem #b9b9b9;}
.seck-list .seck-item:nth-child(2n-1) {margin-right: 0.35rem;}
.seck-item .item-left {position: relative;}
.seck-item .item-left .left-img {width: 100%;height: 2.65rem;margin: 0 auto;object-fit: contain;}
.seck-item .item-left .item-setime {width: 90%; margin: 0.12rem auto 0; padding: 0.02rem 0.16rem; background: #199ed8; border-radius: 0.28rem;}
.seck-item .item-left .item-setime img {width: 0.22rem;height: 0.22rem;margin-right: 0.12rem;}
.seck-item .item-left .item-setime .settime-tit {color: #fff; font-size: 0.22rem;}
.seck-item .item-info {flex: 1; box-sizing: border-box; justify-content: space-between;}
.seck-item .item-info .info-name {width: 100%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;font-weight: 600;font-size: 0.3rem;}
.seck-item .item-info .info-stock .stock-tit {font-size: 0.24rem;}
.seck-item .item-info .info-stock .stock-quota {font-size: 0.24rem;}
.seck-item .item-info .info-specifi { font-size: 0.24rem;}
.seck-item .item-info .info-company { width: 3rem !important; font-size: 0.28rem; width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
.info-tit { font-size: 0.27rem;}
.info-old-price { font-size: 0.24rem; text-decoration: line-through;}
.seck-item .item-info .info-seck .seck-btn { width: .36rem;}
.seck-item .item-info .info-seck .seck-btn.grey { background: #c5c5c5;}
.noShopImg{position:absolute;top: 0.3rem;right: 0.7rem; width: 1.6rem; height: 1.6rem;color: white; background-color: rgba(128, 127, 127, 0.8);border-radius: 50%;text-align: center;line-height: 1.6rem;font-size: .5rem; }
.empty-box{margin: 0 auto;}
    /* 商品弹窗 */
    .good-popup{ position:fixed;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,0.5);z-index:1 }
    .good-pop{ position:fixed;left:0;bottom:0;z-index:2;background:#fff;width:100%; }
    .good-pop .good-top{ width:100%;padding:0.18rem 0.2rem;box-sizing:border-box;border-bottom:0.02rem solid #eee; }
    .good-pop .good-top img{ width:1.8rem;max-height:1.8rem;margin-right:0.2rem; }
    .good-pop .good-top .good-right{ flex:1;height:1.8rem;justify-content:space-between; }
    .good-pop .good-top .good-right .right-name{ font-size:0.3rem;font-weight:600; }
    .good-pop .good-top .good-right .right-price span.price-tit{ font-size:0.5rem; }
    .good-pop .good-top .good-right .right-price span{ font-size:0.28rem; }

    .good-pop .good-specifi{ width:100%;padding:0.18rem 0.2rem;box-sizing:border-box; }
    .good-pop .good-specifi .specifi-item .item-title .tit{ font-size:0.3rem;color:#333;font-weight:600; }
    .good-pop .good-specifi .specifi-item .item-title .tit-parameter{ font-size:0.28rem;color:#999; }
    .good-pop .good-specifi .specifi-item .last-span{ margin-left:0.28rem;color:#999;font-size:0.26rem; }
    /* 商品加减 */
    .add-subtract{ flex:1;justify-content:flex-end }
    .add-subtract .add{ font-size:0.36rem;background:#ff1515;color:#fff;border-radius:50%;display:inline-block;width:0.36rem;height:0.36rem;text-align:center;line-height:0.32rem; }
    .add-subtract .num{ font-size:0.3rem;margin:0 0.2rem; }
    .add-subtract .cut{ font-size:0.36rem;background:#ff1515;color:#fff;border-radius:50%;display:inline-block;width:0.36rem;height:0.36rem;text-align:center;line-height:0.32rem; }

    .good-pop .good-sure{ width:100%;height:0.8rem;text-align:center;line-height:0.8rem;color:#fff;background:#199ed8;margin:0.2rem auto 0; font-size:0.3rem;}

    .bjt{position: absolute;top: 0; left: 0;}
  .guige{position: absolute;top: 2.42rem;left: 0; color: white;font-size: .25rem;font-weight: 700;   width: 55%;text-align: center; height: 0.4rem; overflow: hidden; white-space: nowrap;text-overflow: ellipsis;margin-left: 0.1rem}
  .jiage{position: absolute; top: 2rem; color: white; right: 0.01rem; width: 35%;font-weight: 700;text-align: center;white-space: nowrap;  overflow: hidden;}
  .yuanjia{position: absolute;top:2.45rem;right: 0.01rem;  color: white;  width: 35%; text-align: center;font-size: .25rem; font-weight: 700;   white-space: nowrap; overflow: hidden;}


</style>