<template>
  <div class=" pointsMall">
    <div class="pointsHead p5">
      <div class="h40 flex-sb">
        <div class="fs-20">现有积分：<b>{{points}}</b>积分</div>
        <div class="fs-20 "><span class="btn" @click="earnPointsBtn">去赚积分</span></div>
      </div>
      <div class="flex-sb h40 color-blue">
        <div class="flex-ac" @click="pointsDetailsBtn">
          <img src="@/assets/images/icon-points.png" alt="" class="icon" />积分明细</div>
        <div class="flex-ac" @click="exchangeRecordBtn">
          <img src="@/assets/images/icon-download.png" alt="" class="icon" />兑换记录
        </div>
        <div class="flex-ac" @click="pointOrderBtn">
          <img src="@/assets/images/icon-change.png" alt="" class="icon" />我的订单
        </div>
      </div>
    </div>

  <div >  
  <van-tabs   @click="onClick" color='#007AFF' title-active-color='#007AFF' line-width='3rem' background='#F2F2F2'>  
    <van-tab title="优惠卡卷">
        <van-list  class="p5" v-model="loading"  :finished="finished"   finished-text="没有更多了"  @load="onLoad('20')" :offset="10"  >
   
         <div v-for="(item,id) in rollList"  :key="id"  :title="item" class="coupon-item flex-wrap "  >      
          <div class="coupon-left flex-column;">
            <div class="item-price font-red">{{item.Amount}}元</div>
            <div class="item-explain">
              订单金额满￥{{item.RequirePoints}}可用
            </div>
            <div class="item-time">
              <span>有效期</span>
              <span class="font-red">{{item.UseDay}}天</span>
            </div>
          </div>
          <div class="coupon-right flex-center">
            <div class="recevie-but grey" @click="exchangeBtn(item.Id,item.SourceId,'20')">
              立即兑换
            </div>
          </div>
        </div>
        <div v-if="noRollData" class="no-data">
           <img src="@/assets/images/cart/noActiveDrug.png" alt="" />
        </div>
      </van-list>
      
    </van-tab>

    <van-tab title="积分兑换">
      <van-list class="p5" v-model="loading"    :finished="finished"    finished-text="没有更多了"   @load="onLoad('10')" :offset="10"  >
         <div v-for="(item,id) in shopList"  :key="id"  :title="item" class="shopItem" @click="exchangeBtn(item.Id,item.SourceId,'10')">      
            <div >
              <img :src=item.SpPic alt="" class="img">          
            </div>
            <div  style="flex: 1;">
              <span class="itenHead"> {{item.SpName}}({{item.Style}}/{{item.Unit}})</span> 
              <div class="flex-sb ">
                <div >
                    <div>所需积分：<span class="blue">{{item.RequirePoints}}</span>分</div>
                    <div>每人限购：<span class="blue">{{item.EachLimit}}</span></div>
                    
                </div>
                <div style="width: 0.28rem;  padding-right: 0.2rem" ><img src="@/assets/images/icon-right.png" alt=""></div>
              </div>
              <div class="flex-sb">
                  <div>{{item.Factory}}</div>
                  <div style=" padding-right: 0.3rem">剩余可兑换：<span class="blue">{{item.Residue}}</span></div> 
              </div>
          </div>
         </div>
         <div v-if="noShopData" class="no-data">
           <img src="@/assets/images/cart/noActiveDrug.png" alt="" />
        </div>
      </van-list>
    </van-tab>
  </van-tabs>
 
 </div>
  
  </div>
</template>

<script>
import Vue from "vue";
import { Tab, Tabs, List} from "vant";
import { Cell } from 'vant';

Vue.use(Cell);

Vue.use(List, Tab, Tabs);
export default {
  data() {
    return {
      points:0,
      rollList: [],
      loading: false,
      finished: false,
      tabType: "roll",
      shopList:[],
      page: 1,
      rows: 5,
      total:1 ,
      type:'',
      token : sessionStorage.getItem('member_token'),
      noShopData:false,
      noRollData:false,
    };
  },
  created() {
    this.getPoints()
  },
  methods: {
    tabChange(item) {
      var that = this;
      that.tabType = item;
    },
    // 获取积分
    getPoints(){
      var that=this
       if(!that.token){        
            return that.$total('请先登录');
           }
        that.$toast.loading({
          message:'加载中'
        });
        that.public.request(
          'post',
          'api/PointsMain/QueryItem','',
          (res)=>{
            that.$toast.clear();
            if(res.data.errorCode=='00'){
              that.points=res.data.rows.ValidPoints;
            }else{
               that.$toast(res.data.errorMsg);
            }
          }
          )

    },
    //跳转去赚积分
    earnPointsBtn(){
      this.$router.push({
           path: "/",
        });

    },
    //跳转积分
    pointsDetailsBtn() {
      var that=this
       if(!that.token){
          // 加载状态结束
          return  that.$toast("请先登录，再查看");
       }
        that.$router.push({
           path: "/pointsDetails",
        });
    },
    // 跳转兑换记录
    exchangeRecordBtn(){
      var that=this
       if(!that.token){
          // 加载状态结束
          return  that.$toast("请先登录，再查看");
       }
        that.$router.push({
           path: "/exchangeRecord",
        });

    },// 跳转我的订单
    pointOrderBtn(){
       var that=this
       if(!that.token){
          // 加载状态结束
          return  that.$toast("请先登录，再查看");
       }
        that.$router.push({
           path: "/pointOrder",
        });
    },
    //兑换
    exchangeBtn(mid,id,type){
      var that=this;
       that.$router.push({
           path: "/details",
          query: {mid,id,type}
        });
     

    },
    //tab切换
  onClick(name, title){
      var that=this ;
      that.loading= false,
      that.finished=false,
      that.page= 1,
      that.rows=10,
      that.total= 1,
      that.rollList=[];
      that.shopList=[];
      that.noShopData=false;
      that.noRollData=false;
    },
//获取数据
    onLoad(type){
      var that=this
         if(!that.token){
          // 加载状态结束
           that.loading = false;
            return false;
           }
           var data={
             page:that.page,
             rows:that.rows,
             type:type,
             total: 1,
           }
        setTimeout(()=>{
           that.public.request(
          'post',
          'api/PointChangeSetting/GetGoodsPage',
           data,
           (res)=>{                          
             if(res.data.errorCode=='00'){
                this.loading = false;
                if(type=='20'){
                  that.rollList.push(...res.data.rows);         
                }else if(type=='10'){
                   that.shopList.push(...res.data.rows);
                }
                 if(that.page*that.rows>res.data.records){
                    this.finished = true;
                    if(type=='20' &&  that.rollList.length==0){
                       that.noRollData=true;

                    }else if(type=='10'&&  that.shopList.length==0){
                       that.noShopData=true;
                    }
                    
                    }else{
                     that.page++;
                   }     
             }else{
                that.$toast(res.data.errorMsg);
             }
           }
        );
        },500);
    },
  
  },
};
</script>

<style scoped>
.pointsMall {
  font-size: 0.3rem;
}
.pointsHead {
  background-color: #f2f2f2;
}
.btn {
  background-color: #007aff;
  padding: 0.14rem;
  border-radius: 0.14rem;
  color: white;
}
.icon {
  width: 0.4rem;
  padding-right: 0.1rem;
}
.h40 {
  height: 1rem;
  line-height: 1rem;
  border-bottom: 0.02rem solid #c7c7c7;
}
.flex-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-ac {
  display: flex;
  align-items: center;
}
.roll {
  display: flex;
  height: 1.1rem;
  line-height: 1.1rem;
}
.roll div {
  width: 50%;
  text-align: center;
}
.active {
  color: #007aff;
  border-bottom: 0.04rem solid #007aff;
}
.color-blue {
  color: #007aff;
}

.p5 {
  padding: 0 0.3rem;
}

/* 优惠券 */
.coupon{
  padding: 0.2rem;
}
.coupon-list {
  width: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
  margin-top: 1rem;
}
 .coupon-item {
  width: 100%;
  height: 2rem;
  padding: 0.25rem 0.4rem;
  box-sizing: border-box;
  background: url("../../../assets/images/bg_cupon_mycoupon.png") no-repeat;
  background-size: 100% 100%;
  margin-bottom: 0.2rem;
}
 .coupon-item .coupon-left {
  width: 75%;
  justify-content: space-between;
 
}
 .coupon-item .coupon-left .item-price {
  width: 75%;
  font-size: 0.5rem;
  text-align: center;
  font-weight: 600;
}
 .coupon-item .coupon-left .item-explain {
  color: #666;
  font-size: 0.3rem;
}
 .coupon-item .coupon-left .item-time span {
   margin-right: 0.1rem;
  font-size: 0.3rem;
}

 .coupon-item .coupon-right {
  flex: 1;
}
 .coupon-item .coupon-right .recevie-but {
  width: 100%;
  height: 0.6rem;
  line-height: 0.6rem;
  border-radius: 0.28rem;
  color: #fff;
  text-align: center;
  font-size: 0.3rem;
}
 .coupon-item .coupon-right .recevie-but.grey {
  background: rgb(233, 73, 73);
}

/* 积分好物 */

.shopItem{
  display: flex;
  margin-top: 0.2rem;
  border-bottom: .02rem  solid rgb(216, 216, 216);
  padding-bottom: .3rem ;
  color:#999999;
  font-size: 0.26rem;

}
.img{
  width: 80px;
  height: 80px;
  border: .02rem  solid rgb(196, 195, 195);
  margin-right: .2rem ;
  border-radius: 0.2rem;
  
}
.blue{
  color: #007aff !important;
}
.itenHead{
  color: black;
  font-size: 0.3rem;
}
.tabs{
  margin-top: 3rem;

}
.van-tabs__nav{
  background-color: #666 !important;
}
.van-tabs__wrap{
    background-color: #666;
  position: fixed;
    top:2.94rem !important ;
    width: 100%;
    z-index: 3;
}
.van-list{
  margin-top: .2rem /* 5/50 */;  
}

</style>
