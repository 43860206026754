import { render, staticRenderFns } from "./goodsDetail.vue?vue&type=template&id=1f7d4ba8&scoped=true"
import script from "./goodsDetail.vue?vue&type=script&lang=js"
export * from "./goodsDetail.vue?vue&type=script&lang=js"
import style0 from "./goodsDetail.vue?vue&type=style&index=0&id=1f7d4ba8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f7d4ba8",
  null
  
)

export default component.exports