<template>
  <div>
      <div class="p-20 address flex-sp">
         
          <div v-if="idShowAddress">
                <div>收货人：{{addressInfo.Consignee}}</div>
                <div>收货电话：{{addressInfo.Mobile}}</div>
                <div>收货地址：{{addressInfo.Address}}</div>
          </div>
          <div class="noAderss" v-else>
              <img  src="@/assets/images/icon-adress.png" alt="">
              <div>
              没有收货地址，请选择收货地址
              </div>
            </div>
         <div @click="toAddress(addressInfo.Id)">
             <img class="img-r" src="@/assets/images/mine/arrow-r.png" alt="右箭头">
         </div>
      </div>
      <div class="p-20 shopList">
          <div class="shopImg"><img :src=shopDetaiList.SpPic alt=""></div>
          <div class="shopItem">
              <div class="flex-sp">
                  <div>{{shopDetaiList.SpName}}</div>
                  <div>x{{Num}}</div>
              </div>
              <div>{{shopDetaiList.Style}}/{{shopDetaiList.Unit}}</div>
              <div>{{shopDetaiList.Factory}}</div>
              <div class="flex-sp">
                  <div>所需积分</div>
                  <div>{{shopDetaiList.RequirePoints}}</div>
              </div>
          </div>       
      </div>
      <div class="flex-sp  duihuan">
          <div>合计：{{sunNum}} 积分</div>
          <button class="goDuihuan " @click="exchangeBtn">去兑换</button>
      </div>
  </div>
</template>

<script>
export default {
    data () {
        return {
            Mid:this.$route.query.mid,
            Id:this.$route.query.id,
            Num:this.$route.query.num,
            shopDetaiList:{},
            sunNum:'',
            addressInfo:{},//地址
            adressId:localStorage.getItem('addressId'),
            idShowAddress:false,
        }
    },
    created () {
        this.getShopDetail()
        this.getAddressInfo()
    },
    methods: {
         getShopDetail(){
            var that=this;
            var  data={
                Mid:that.Mid,
                Id:that.Id,
                Num:that.Num,
            }
            this.$toast.loading({
               message: "加载中...",
               duration: 0,
             });
            that.public.request(
                'post',
                'api/PointChangeSetting/GetItemInfoById',
                data,
                (res)=>{
                    that.$toast.clear();
                    if(res.data.errorCode==='00'){
                        that.shopDetaiList=res.data.rows                
                        that.sunNum = that.shopDetaiList.RequirePoints*that.Num;                                     
                    }else{
                         that.$toast(res.data.errorMsg);
                    }
                }
            )
        },
         // 获取地址
        getAddressInfo(){    
            var that=this;
             this.$toast.loading({
               message: "加载中...",
               duration: 0,
             });
            
            that.public.request(
                'get',
                'api/receiveAddress/GetReceiveAddress?id='+that.adressId,
                '',
                (res)=>{
                     that.$toast.clear();
                     if(res.data.errorCode==='00'){
                        that.addressInfo=res.data.rows;
                        that.idShowAddress=true;                
                    }else{
                         that.$toast(res.data.errorMsg);
                    }
                }
            );

        },
        // 切换地址
        toAddress(id){
           var that = this;
				that.$router.push({
					path: '/receiveAddress',
					query: {
						addressId: id,
					}
				})
        },
        exchangeBtn(){
            var that=this;
            var address=that.addressInfo.AreaProvinceName+that.addressInfo.AreaName+that.addressInfo.RegionName;

            if(!that.addressInfo.Consignee){
               return that.$toast('请选择收货地址');
            }
           
            var form = {
                Mid: that.Mid,
                ItemId: that.Id,
                Num: that.Num,
                ContactMobile: that.addressInfo.Mobile,
                ContactName: that.addressInfo.Consignee,
                Address: address,
                XqAddress: that.addressInfo.Address,
            }
             that.$toast.loading({
                message:'兑换中'
            })  
             that.public.request(
                'post',
                'api/PointsOrders/CreateOrder',
                form,
                (res)=>{
                    that.$toast.clear();
                    // console.log(res);
                    if(res.data.errorCode==='00'){                      
                         that.$toast('兑换成功');
                         setTimeout(() => {
                             that.$router.push({
                                 path: "/pointsMall",
                                  }); 
                         }, 1000)
                       
                    }else{
                        that.$toast(res.data.errorMsg);
                    }
                 
                });
        }
    }

}
</script>

<style scoped>
.p-20{
    padding: 0 .4rem ;
}
.address{
     font-size: .28rem;
     margin: .2rem /* 10/50 */;
  
}
.shopList{
    display: flex;
    align-items: center;
    border-top: .02rem /* 1/50 */ solid #e7e7e7;
    border-bottom: .02rem /* 1/50 */ solid #e7e7e7;
    padding: .4rem ;
}
.shopImg{
    width: 1.5rem ;
    height: 1.5rem;
    border: .02rem  solid rgb(196, 195, 195);
    box-shadow: 0rem 0rem .2rem rgb(196, 195, 195);
    border-radius: 0.2rem;
    margin: 0 .4rem 0 0 ;
    overflow: hidden;
    
}
.shopImg img{
    width: 100%;
    height: 100%;
object-fit: fill;
}
.shopItem{
    flex: 1 ;
    font-size: .28rem;
}
.flex-sp{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.duihuan{
    width: 100%;
    font-size: .30rem;
    position: absolute;
    bottom: .2rem ;
    padding: .3rem  0 0 .3rem;
    border-top: .02rem  solid #e7e7e7;
     
}

.goDuihuan{
    color: white;
    background-color:#1ABC9C ;
    padding: .16rem  .5rem;
    margin-right: .8rem ;
}
.img-r{
    width: .2rem /* 10/50 */;
}
.noAderss{
    display: flex;
    align-items: center;
    height: 1.26rem /* 63/50 */;
}
.noAderss img{ 
    width: .5rem

}

</style>