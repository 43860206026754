<template>
  <div class="companyZiZhi">
      <div class="company_info">
          <div class="info_title flex-wrap">企业资质(点击即可查看大图和保存)</div>
          <div class="info-list flex-wp ">
                <div class="info-item">
                    <div class="info-img">
                        <div v-if="photoList&&photoList.BussinessLic"  @click="showPhoto(photoList.BussinessLic)">                
                             <img :src=photoList.BussinessLic alt=""  />
                        </div>
                        <img v-else src="@/assets/images/icon_uptp.png" alt="" />
                    </div>
                    <div class="item-title">营业执照</div>
               </div>
                <div class="info-item">
                    <div class="info-img">
                        <div v-if="photoList&&photoList.Licence"  @click="showPhoto(photoList.Licence)">                
                             <img :src=photoList.Licence alt=""  />
                        </div>
                        <img v-else src="@/assets/images/icon_uptp.png" alt="" />
                    </div>
                    <div class="item-title">许可证</div>
                </div>
               <div class="info-item">
                    <div class="info-img" >
                       <div v-if="photoList&&photoList.BillingUrl"  @click="showPhoto(photoList.BillingUrl)">                
                             <img :src=photoList.BillingUrl alt=""  />
                        </div>
                        <img v-else src="@/assets/images/icon_uptp.png" alt="" />
                    </div>
                    <div class="item-title">开票信息</div>  
               </div>

                <div class="info-item">
                   <div class="info-img">
                        <div v-if="photoList&&photoList.Attorney"  @click="showPhoto(photoList.Attorney)">                
                             <img :src=photoList.Attorney alt=""  />
                        </div>
                        <img v-else src="@/assets/images/icon_uptp.png" alt="" />
                    </div>
                    <div class="item-title">委托书</div>       
               </div>

                <div class="info-item">
                    <div class="info-img">
                        <div v-if="photoList&&photoList.Warranty"  @click="showPhoto(photoList.Warranty)">                
                             <img :src=photoList.Warranty alt=""  />
                        </div>
                        <img v-else src="@/assets/images/icon_uptp.png" alt="" />
                    </div>
                    <div class="item-title">质保协议</div>        
               </div>

               <div class="info-item">
                    <div class="info-img">
                        <div v-if="photoList&&photoList.QualifiedUnit"  @click="showPhoto(photoList.QualifiedUnit)">                
                             <img :src=photoList.QualifiedUnit alt=""  />
                        </div>
                        <img v-else src="@/assets/images/icon_uptp.png" alt="" />
                    </div>
                    <div class="item-title">合格供货单位档案表</div>        
               </div>

               <div class="info-item">
                    <div class="info-img">
                        <div v-if="photoList&&photoList.YearReport"  @click="showPhoto(photoList.YearReport)">                
                             <img :src=photoList.YearReport alt=""  />
                        </div>
                        <img v-else src="@/assets/images/icon_uptp.png" alt="" />
                    </div>
                    <div class="item-title">年报</div>        
               </div>

               <div class="info-item">
                    <div class="info-img">
                        <div v-if="photoList&&photoList.Outbound"  @click="showPhoto(photoList.Outbound)">                
                             <img :src=photoList.Outbound alt=""  />
                        </div>
                        <img v-else src="@/assets/images/icon_uptp.png" alt="" />
                    </div>
                    <div class="item-title">空白票样</div>        
               </div>

               <div class="info-item">
                    <div class="info-img">
                        <div v-if="photoList&&photoList.Seal"  @click="showPhoto(photoList.Seal)">                
                             <img :src=photoList.Seal alt=""  />
                        </div>
                        <img v-else src="@/assets/images/icon_uptp.png" alt="" />
                    </div>
                    <div class="item-title">印章印模</div>        
               </div>
				<div class="info-item">
                    <div class="info-img">
                        <div v-if="photoList&&photoList.HLWYMXXFWZGZ"  @click="showPhoto(photoList.HLWYMXXFWZGZ)">                
                             <img :src=photoList.HLWYMXXFWZGZ alt=""  />
                        </div>
                        <img v-else src="@/assets/images/icon_uptp.png" alt="" />
                    </div>
                    <div class="item-title">互联网药品信息服务资格证书</div>        
               </div>
                
          </div>
      </div>
      <!-- <div class="product_info">
          <div class="info_title">产品资质(点击即可查看大图和保存)</div>
           <div class="info-list flex-wp ">
                <div class="info-item">
                   <div class="info-img">
                        <div v-if="photoList.ProductQuali1"  @click="showPhoto(photoList.ProductQuali1)">                
                             <img :src=photoList.ProductQuali1 alt=""  />
                        </div>
                        <img v-else src="@/assets/images/icon_uptp.png" alt="" />
                    </div>
               </div>

               <div class="info-item">
                    <div class="info-img">
                        <div v-if="photoList.ProductQuali2"  @click="showPhoto(photoList.ProductQuali2)">                
                             <img :src=photoList.ProductQuali2 alt=""  />
                        </div>
                        <img v-else src="@/assets/images/icon_uptp.png" alt="" />
                    </div>     
               </div>

                <div class="info-item">
                    <div class="info-img">
                       <div v-if="photoList.ProductQuali3"  @click="showPhoto(photoList.ProductQuali3)">                
                             <img :src=photoList.ProductQuali3 alt=""  />
                        </div>
                        <img v-else src="@/assets/images/icon_uptp.png" alt="" />
                    </div>            
               </div>

                <div class="info-item">
                    <div class="info-img">
                       <div v-if="photoList.ProductQuali4"  @click="showPhoto(photoList.ProductQuali4)">                
                             <img :src=photoList.ProductQuali4 alt=""  />
                        </div>
                        <img v-else src="@/assets/images/icon_uptp.png" alt="" />
                    </div>
               </div>   
          </div>
      </div> -->
	  
       <div class="image-preview" v-if="isShowPreview">
            <van-image-preview v-model="isShowPreview"
            :showIndex=false
            :closeable=true
            :images="zizhiImages">
            </van-image-preview>
        </div>
        <!-- <div class="image-preview" v-if="isShowPreview" @touchstart="touchStart" @touchend="touchEnd">
            <van-image-preview v-model="isShowPreview"
            :images="zizhiImages"
            :closeable=true
            :showIndex=false>
                <template v-slot:index>第{{ index }}页</template>
            </van-image-preview>
        </div>
         
       <form action="" method="post" style="display: none;" id="submits">
          <input name="SufixUrl" value="" id="SufixUrl"/>
        <button type="submit">提交</button>
        </form> -->
        
  </div>
</template>

<script>
import Vue from 'vue';
import { ImagePreview } from 'vant';
import { Dialog } from 'vant';
export default {
    data () {
        return {
            photo:'',
            isLongClick:false,//是否是长按事件
            // isShowPhoto:false,//图片是否放大
            touchStartX:'',
            touchStartY :'',
            touchEndX :'',
            touchEndY :'',
            isShowPreview: false,   // 图片预览弹窗
            index: 0,
            clientType: '',     // 类型  app/applet/weixin
            zizhiImages:[],
            hostUrl:'',//基准地址
            token:sessionStorage.getItem('member_token'),
            photoList:[],
        
          

        }
    },
    components: {
         [ImagePreview.Component.name]: ImagePreview.Component,
         [Dialog.Component.name]: Dialog.Component,
    },
    created(){
        var that = this;
        that.clientType = localStorage.getItem("clientType");
        that.hostUrl = that.requestUrl.url;
        that.getPhotoList()
    },
    methods: {
        // 点击预览图片
        showPhoto(pohoto){
            var that=this;   
   
            that.zizhiImages[0] = pohoto;
            that.isShowPreview = true;  // 显示图片

        },

        //长按
        touchStart() {
            this.touchStartX = event.changedTouches[0].clientX // this.touchStartX按下时的横轴坐标
            this.touchStartY = event.changedTouches[0].clientY // this.touchStartY按下时的纵轴坐标
            
            var that = this;
            that.timeOutEvent = setTimeout(() => {
                if (that.isShowPreview) {
                    that.isLongClick = true; // this.isLongClick用于标识是否是长按事件
                    var imgUrl = that.zizhiImages[that.index];
                    document.getElementById("submits").action=that.requestUrl.url+'Home/DownFile';
                    document.getElementById("SufixUrl").value=imgUrl;
                    document.getElementById("submits").submit();

                    // that.$dialog
                    //   .confirm({
                    //   message: "确定保存到相册吗?",
                    // })
                    // .then(() => {
                    //     var imgUrl = that.zizhiImages[that.index];
                    //     // var downLoader = plus.downloader.createDownload(imgUrl, {  
                    //     //     method: 'GET',  
                    //     //     filename: '_downloads/image', 
                    //     // }, function(download, status) {  
                    //     //     var fileName = download.filename; 
                    //     //     if(status == 200){
                    //     //         plus.gallery.save(imgUrl,function() {//保存到相册方法
                    //     //             that.$toast("保存成功");
                    //     //         }, function() {
                    //     //             that.$toast("保存失败，请重试");
                    //     //         });
                    //     //     }
                    //     // }); 
                    //     // downLoader.start();  
                    //     that.public.downloadIamge(imgUrl);
                    // })
                    // .catch(() => {
                    //   // console.log('取消');
                    // });
                }
                
               
            }, 500)
        },
        touchEnd(event){
            this.touchEndX = event.changedTouches[0].clientX // this.touchEndX释放时的横轴坐标
            this.touchEndY = event.changedTouches[0].clientY // this.touchEndY释放时的纵轴坐标
            
            var that = this;
            if (Math.abs(that.touchEndX - that.touchStartX) < 10 && Math.abs(that.touchEndY - that.touchStartY) < 10 && that.isLongClick) {
                that.isLongClick = false;
            }
        },
        // 获取图片数据
        getPhotoList(){
            var that=this;
            if(!that.token){
                return false;
            }
            that.$toast.loading({
                message:'加载中'
            });
            that.public.request(
                'post',
                '/api/Qualification/GetCompanyQualification',
                '',
                (res)=>{
                    console.log(res);
                    that.$toast.clear();
                    if(res.data.errorCode=='00'){
                        that.photoList=res.data.rows[0];

                    }else{
                      that.$toast(res.data.errorMsg);
                    }
                }
            );

        }
     
    }

}
</script>

<style scoped>
.companyZiZhi{
    font-size: 16px;

}
.info_title{
   border-bottom: .02rem  solid rgb(228, 226, 226);
   padding-left: .4rem ;
   padding: .2rem /* 10/50 */ 0 .2rem .4rem;
}

 .info-item{
  width: 50%;
  padding: 0.2rem;
  box-sizing: border-box;
 }
.flex-wp{
    display: flex;
    flex-wrap: wrap  
}
.item-title{
    text-align: center;
    margin-top: .15rem;
}
.info-img {
width: 100%;
  height: 3rem;
  object-fit: contain;
}
 .info-img img{
  width: 100%;
  height: 3rem;
  object-fit: contain;
  
 }
 .up-photo{
     display: none;
 }
</style>