<template>
  <div class="writeInvoice">
    <form>
      <div class="xiangqiang invoice">
        <div class="left">发票详情:</div>
        <div class="right">
          <span
            ><input
              type="radio"
              name="detail"
              id=""
              value="10"
              v-model="SaveBillData.BillType"
            />增值税普通发票</span
          >
          <span
            ><input
              type="radio"
              name="detail"
              id=""
              value="20"
              v-model="SaveBillData.BillType"
            />增值税专业发票</span
          >
          <span
            ><input
              type="radio"
              name="detail"
              id=""
              value="30"
              v-model="SaveBillData.BillType"
            />电子发票</span
          >
        </div>
      </div>

      <div class="xiangqiang">
        <div class="left ">
          <span class="checked" v-if="isChecked"
            ><img src="@/assets/images/icon_checked.png" alt=""
          /></span>

          公司名称:
        </div>
        <div class="right">
          <input
            type="text"
            placeholder="请输入公司名称"
            v-model="SaveBillData.SendOrgName"
          />
        </div>
      </div>

      <div class="xiangqiang">
        <div class="left"> <span class="checked" v-if="isChecked"
            ><img src="@/assets/images/icon_checked.png" alt=""
          /></span>公司税号:</div>
        <div class="right">
          <input
            type="text"
            placeholder="填写纳税人识别号"
            v-model="SaveBillData.TaxNo"
          />
        </div>
      </div>

      <div class="xiangqiang">
        <div class="left"> <span class="checked" v-if="isChecked1"
            ><img src="@/assets/images/icon_checked.png" alt=""
          /></span>地址:</div>
        <div class="right">
          <input
            type="text"
            placeholder="请填写公司地址"
            v-model="SaveBillData.Address"
          />
        </div>
      </div>

      <div class="xiangqiang">
        <div class="left"> <span class="checked" v-if="isChecked1"
            ><img src="@/assets/images/icon_checked.png" alt=""
          /></span>电话:</div>
        <div class="right">
          <input
            type="text"
            placeholder="请填写联系方式"
            v-model="SaveBillData.Mobile"
          />
        </div>
      </div>

      <div class="xiangqiang">
        <div class="left"> <span class="checked" v-if="isChecked1"
            ><img src="@/assets/images/icon_checked.png" alt=""
          /></span>开户行:</div>
        <div class="right">
          <input
            type="text"
            placeholder="请填写开户行"
            v-model="SaveBillData.Bank"
          />
        </div>
      </div>

      <div class="xiangqiang">
        <div class="left"> <span class="checked" v-if="isChecked1"
            ><img src="@/assets/images/icon_checked.png" alt=""
          /></span>账号:</div>
        <div class="right">
          <input
            type="text"
            placeholder="请填写开户账号"
            v-model="SaveBillData.BankAccount"
          />
        </div>
      </div>

      <div class="xiangqiang">
        <div class="left">备注:</div>
        <div class="right">
          <input
            type="text"
            placeholder="填写备注等（非必填）"
            v-model="SaveBillData.Remark"
          />
        </div>
      </div>

      <div class="xiangqiang sum">
        <div class="left">总金额</div>
        <div class="right">
          <span>
            <s>{{ orderCost }}</s> 元</span
          >
          <span>{{ orderNum }} 订单</span>
        </div>
      </div>

      <div class="btn">
        <div class="submitBtn" @click="submitbtn">立即提交</div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orderNum: this.$route.query.OrderNum,
      orderCost: this.$route.query.OrderCost,
      isChecked1: false,
      isChecked: false,


      SaveBillData: {
        OrderNo: "",
        SendOrgName: "", //客户名称
        BillType: 10, //发票类型
        TaxNo: "", //税号
        Address: "", //地址
        Mobile: "", //电话
        Bank: "", //开户行
        BankAccount: "", //银行账号
        Remark: "",
      },
    };
  },
  updated() {
    // console.log(this.SaveBillData.BillType);
    if (this.SaveBillData.BillType == "10" ) {
      this.isChecked = true
      this.isChecked1 = false
    }else if(this.SaveBillData.BillType == "20"){

      this.isChecked1 = true
    }else if(this.SaveBillData.BillType == "30"){
      this.isChecked = true
       this.isChecked1 = false
    }
  },
  created() {
    var OrderNos = JSON.parse(sessionStorage.getItem("OrderNo"));
    this.SaveBillData.OrderNo = OrderNos.join(",");

    this.GetCompanyName();
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f2f2f2;");
  },
  methods: {
    GetCompanyName() {
      this.$toast.loading({
        message: "加载中...",
        duration: 0,
      });
      this.public.request("post", "/api/Bill/GetCompanyName", {}, (result) => {
        this.$toast.clear();
        this.SaveBillData.SendOrgName = result.data.rows.OrgName;
        console.log(result);
        if (!result.data.extend==null) {
          this.SaveBillData.BillType = result.data.extend.BillType;
          this.SaveBillData.Address = result.data.extend.Address;
          this.SaveBillData.TaxNo = result.data.extend.TaxNo;
          this.SaveBillData.Mobile = result.data.extend.Mobile;
          this.SaveBillData.Bank = result.data.extend.Bank;
          this.SaveBillData.BankAccount = result.data.extend.BankAccount;
          this.SaveBillData.Remark = result.data.extend.Remark;
        }

        this.SaveBillData.BillType = 10;
      });
    },
    submitbtn() {
      if (
        this.SaveBillData.BillType == 10 ||
        this.SaveBillData.BillType == 30
      ) {
        if (!this.SaveBillData.SendOrgName || !this.SaveBillData.TaxNo) {
          this.$toast("公司名称和公司税号不能为空");
          return;
        }
      } else if (this.SaveBillData.BillType == 20) {
        if (
          !this.SaveBillData.SendOrgName ||
          !this.SaveBillData.TaxNo ||
          !this.SaveBillData.Address ||
          !this.SaveBillData.Mobile ||
          !this.SaveBillData.Bank ||
          !this.SaveBillData.BankAccount
        ) {
          this.$toast("除了备注字段，都为必填项  ");
          return;
        }
      }

      var that = this;
      var billData = {
        OrderNo: that.SaveBillData.OrderNo,
        SendOrgName: that.SaveBillData.SendOrgName,
        BillType: that.SaveBillData.BillType * 1,
        TaxNo: that.SaveBillData.TaxNo,
        Address: that.SaveBillData.Address,
        Mobile: that.SaveBillData.Mobile,
        Bank: that.SaveBillData.Bank,
        BankAccount: that.SaveBillData.BankAccount,
        Remark: that.SaveBillData.Remark,
      };
      that.public.request(
        "post",
        "/api/Bill/SaveBillData",
        billData,
        (result) => {
          if (result.status == 200) {
            that.$router.push({
              path: "/billManage",
            });
          }
        }
      );
    },
  },
 
};
</script>

<style scoped>
html {
  background-color: #f2f2f2 !important;
}
.writeInvoice {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  /* position: fixed;
  top: 0.9rem;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto; */
}

.xiangqiang {
  display: flex;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.24rem;
  margin-top: 0.04rem;
  background-color: white;
  box-sizing: border-box;
}

.xiangqiang .left {
  flex: 1;
  padding-left: 0.3rem;
  position: relative;
}
.xiangqiang .right {
  flex: 5;
}
.invoice .right {
  display: flex;
  justify-content: space-around;
}
.sum {
  margin: 0.3rem 0;
}
.sum .right {
  display: flex;
  justify-content: space-between;
  padding-right: 0.8rem;
}
.sum .right s {
  color: #ff0000;
  text-decoration: none;
}
.checked img {
  width: 0.3rem;
  display: inline;
  position: absolute;
  left: 0;
  top: 0.3rem;
}
.submitBtn {
  text-align: center;
  width: 93%;
  line-height: 0.8rem;
  background-color: #33cccc;
  margin: 1rem auto;
  margin-left: 0.3rem;
  border-radius: 0.15rem;
  font-size: 0.27rem;
  color: white;
}
</style>
