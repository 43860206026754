<template>
    <div class="container">
		<!--  -->
		<div class="bank-list">
			<div class="bank-item" v-for="(item,i) in bankList" :key="i">
				<img class="bank-img" :src="item.BankPic" alt="" />
				<div class="bank-info">
					<span class="name">{{item.BankName}}</span>
					<span class="type">{{item.AccTypeName}}</span>
					<span class="num">{{item.Acc}}</span>
				</div>
				<span class="unbind-btn" @click="showDialogTap(item,i)">解绑</span>
			</div>
		</div>

       <div class="bind-btn" @click="toBindTap"> + 添加银行卡</div>
	   
	   <van-dialog 
	   v-model="showDialog" 
	   title="解绑银行卡" 
	   show-cancel-button 
	   confirm-button-text="立即解绑"
	   :lazy-render="false" 
	   :before-close="dialogClose"
	   @confirm="unbindCard"
	  
	   >
	     <div class="code-box" v-if="bankItem">
	     	<p>{{bankItem.Phone}}</p>
			<div class="input-box">
				<input type="number" placeholder="请输入验证码" v-model="smsCode" />
				<span :class="{'disabled':sendCodeBtn!='发送验证码'}" @click="unbindCode">{{sendCodeBtn}}</span>
			</div>
	     </div>
	   </van-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return{
			bankList: [],
			
			showDialog: false,
			bankItem: null,
			current: 0,
			smsCode: "",
			sendCodeBtn: "发送验证码"
        }
    },
    created(){
        var that = this;
        that.queryBankList();
    },
    methods:{
		// 银行卡列表
		queryBankList(){
		    var that = this;
		    that.$toast.loading({
		        message: '加载中',
		        duration: 0
		    })
		    that.public.request('post','/api/SysUserBank/QueryPage',{
		        // OrgId: localStorage.getItem('orgId'),
				rows: 50,
				page: 1,
		    },res => {
		        that.$toast.clear();
		        if(res.data.errorCode == '00'){
		            that.bankList = res.data.rows
		        }else{
		           that.$toast(res.data.errorMsg)
		        }
		    })
		},
		
		// 解绑弹窗
		showDialogTap(item,index){
			this.bankItem = item;
			this.current = index;
			this.smsCode = "";
			this.showDialog = true;
		},
		
		// 解绑获取验证码
		unbindCode(){
			let that = this;
			if(this.sendCodeBtn != "发送验证码"){
				return;
			}
		    that.public.request('post','/api/SysUserBank/sendUnsignCode',{
		        Id: this.bankItem.Id,
		    },res => {
		        if(res.data.errorCode == '00'){
					that.$toast("发送成功");
					// 倒计时
					let num = 60;
					let time = setInterval(function() {
					  if (num == 0) {
					    clearInterval(time);
					    that.sendCodeBtn = "发送验证码";
					  } else {
					    num--;
					    that.sendCodeBtn = num + "秒后重试";
					  }
					}, 1000);
		        }else{
					that.$toast(res.data.errorMsg)
		    	}
		    })
		},
		
		//阻止弹窗关闭
		dialogClose(action,done){
			if(!this.smsCode && action == "confirm"){
				done(false);
			}else{
				done();
			}
		},
		
        // 立即解绑
        unbindCard(){
			let that = this;
			if(!this.smsCode){
				return this.$toast("请输入验证码");
			}
			that.public.request('post','/api/SysUserBank/verifyUnsigncode',{
			    Id: this.bankItem.Id,
				Code: this.smsCode
			},res => {
			    if(res.data.errorCode == '00'){
			        that.bankList.splice(that.current,1);
			    }else{
					that.$toast(res.data.errorMsg)
				}
			})
        },
		
		/**
		 * 添加
		 */
		toBindTap(id){
			this.$router.push({
			  path: "/bankCardBind",
			  query: {
			    // id: id,
			  },
			});
		}

    }
}
</script>


<style scoped>
	.container{
		width: 100%;
		height: 100%;
		background: #f7f7f7;
	}
	.bank-list{
		padding: 5px 10px;
	}
	.bank-item{
		position: relative;
		background: #fff;
		margin: 10px 0;
		padding: 10px;
		display: flex;
		align-items: center;
	}
	.bank-item .bank-img{
		width: 50px;
		height: 50px;
		margin-right: 10px;
		border-radius: 50%;
	}
	.bank-item .bank-info{
		display: flex;
		flex-direction: column;
		font-size: 16px;
	}
	.bank-item .bank-info .type{
		font-size: 14px;
		color: #999;
		margin: 2px 0;
	}
	.bank-item .unbind-btn{
		position: absolute;
		right: 30px;
		font-size: 16px;
		color: #00a2ea;
	}
	
	.bind-btn{
		border: #ddd 1px dashed;
		margin: 10px;
		padding: 30px 0;
		font-size: 18px;
		color: #999;
		text-align: center;
		background: #fff;
	}
	
	.code-box{
		padding: 0 30px 30px;
	}
	.input-box{
		display: flex;
		color: #00a2ea;
	}
	.input-box input{
		flex: 1;
	}
	.input-box .disabled{
		color: #999;
	}
   
</style>