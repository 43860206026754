<template>
	<div class="app">
		<div class="content">
			<div class="cell">
				<div class="label">
					<p>个性化推荐</p>
					<span>关闭后仅使用基本功能，无法看到个性化推荐内容</span>
				</div>
				
				<div class="switch">
					<van-switch v-model="isCheck" active-color="var(--theme-color)" @change="changeSwitch"/>
				</div>
			</div>
			
			<div class="cell about" @click="toMySecure">
				<div class="label">
					<p>安全中心</p>
				</div>
				<img class="arrow" src="@/assets/images/icon-right.png" alt="">  
			</div>
			
			<div class="cell about" @click="logoutTap">
				<div class="label">
					<p>注销账号</p>
				</div>
				<img class="arrow" src="@/assets/images/icon-right.png" alt="">  
			</div>
			  
			<div class="cell about" @click="toAbout">
				<div class="label">
					<p>关于我们</p>
				</div>
				<img class="arrow" src="@/assets/images/icon-right.png" alt="">  
			</div>
		</div>
		
		<div class="logout">
			<button @click="handleOut">退出登录</button>
		</div>
	</div>
</template>


<script>
	// import HelloWorld from '@/components/HelloWorld.vue'
	import {Toast, Dialog} from "vant"
	export default {
		name: 'app',
		components: {
			Toast, [Dialog.Component.name]: Dialog.Component
			// HelloWorld
		},
		data(){
		  return{
			isCheck: true,
			appType: "", //当前app类型
		  }
		},
		
		created() {
			let isPush = localStorage.getItem("isPush");
			this.isCheck = isPush=="true"?true:false
			switch (localStorage.clientType) {
			  case "app":
			    this.appType = "APP";
			    break;
				case "ios":
				 this.appType = "APP";
				  // this.appType = "IOS";
				  break;
			  case "applets":
			    this.appType = "AppLet";
			    break;
			  case "weixin":
			    this.appType = "WeChat";
			    break;
			  default:
			    this.appType = "";
			}
			
		},
		methods: {
			changeSwitch(){
				let that = this;
				this.public.request("post","api/SysUserInfo/SaveUserIsPush",{
					IsPush: (this.isCheck?1:0),
				},res=>{
					if(res.data.errorCode == "00"){
						localStorage.setItem("isPush",that.isCheck);
					}else{
						that.$toast(res.data.errorMsg)
					}
				})
				
			},
			
			// 安全中心
			toMySecure() {
			  this.$router.push({
			    path: "/mySecure",
			  });
			},
			
			// 注销账号
			logoutTap(){
				let tel = JSON.parse(sessionStorage.getItem("orgWebconfig"));
				Dialog.confirm({
				  message: '账号注销功能请联系相关客服人员进行处理，联系电话：'+tel.Mobile,
				  showCancelButton: false,
				}).then(() => {
				}).catch(() => {
				});
			},
			
			// 关于我们
			toAbout() {
			  this.$router.push({
			      path: '/aboutUs'
			  })
			},
			
			/**
			 * 退出弹窗提示
			 */
			handleOut(){
				let that = this;
				Dialog.confirm({
				  message: '确定要退出吗？',
				}).then(() => {
					that.logOut();
				}).catch(() => {
				    // on cancel
				});
			},
			
			//退出登录
			logOut() {
			  var that = this;
			  that.public.request("post", "/api/Online/Logout",{
				  LoginType: that.appType ,
				},(res) => {
				  localStorage.user = "";
				  let token = sessionStorage.getItem("member_token");
				  let queryObj = {
			        orgId: localStorage.orgId,
			        openid: localStorage.openid,
			        clientType: localStorage.clientType,
			        auto: (token?1:0),
			        _: new Date().valueOf(),
			      };
				  if (localStorage.appv) {
				    queryObj.appv = localStorage.appv;
				  }
				  
			      var cipherText = localStorage.cipherText;
			      var cipherPsw = localStorage.cipherPsw;
				   // 清除localStorage所有缓存
			      localStorage.clear();
			      sessionStorage.clear();
				  
				  localStorage.setItem("appFirst",false); //设置非第一次启动
				  sessionStorage.setItem("junp", true);
				  localStorage.setItem("agreement", true);
				  if (cipherText) { //设置记住账号密码
				    localStorage.setItem("cipherText", cipherText);
				    localStorage.setItem("cipherPsw", cipherPsw);
				  }
				  // sessionStorage.setItem("junp", true);
				  if(that.isMini()){
				  	that.wx.miniProgram.reLaunch({
				  	    url: window.baseUrl.xcxLogin?'/pages/login/index?logout=true':'/pages/login/oldLogin/index?logout=true',
				  	})
				  	return;
				  }
			      that.$router.push({
			        path: "/login",
					query: queryObj,
			      });
			    }
			  );
			},
			
			//判断是否小程序浏览器加载
			isMini: function(){
				let mini = false;
				if (/MicroMessenger/i.test(navigator.userAgent)) {
					//ios的ua中无miniProgram，很坑爹,但都有MicroMessenger（表示是微信浏览器）
					this.wx.miniProgram.getEnv((res) => {
						if (res.miniprogram) {
							mini = true;
						}
					});
				}
				return mini;
			},
		  
		}
	}
</script>

<style scoped>
	.content{
		position: relative;
		padding: 10px 0;
		background: #f7f7f7;
	}
	.content .cell{
		position: relative;
		padding: 10px;
		background: #fff;
		font-size: 14px;
		color: #999;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.content .cell p{
		font-size: 16px;
		font-weight: bold;
		color: #111;
		margin: 5px 0;
	}
	
	.switch{
		position: relative;
		border-radius: 50px;
	}
	
	.cell.about{
		border-top: 10px solid #f7f7f7;
	}
	.cell .arrow{
		width: 15px;
		height: 15px;
	}
	
	.logout{
		padding: 20px;
	}
	.logout button{
		width: 100%;
		height: 50px;
		margin-top: 100px;
		background: var(--theme-color);
		border-radius: 6px;
		font-weight: bold;
		font-size: 18px;
		color: #fff;
	}
</style>
