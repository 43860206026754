<template>
    <div class="container">
        <!-- 备案收货人信息 -->
        <div class="record-info bor-bot2">
            <div class="info-tit font-red">医药信息监管要求，收货地址需是ERP备案的收货地址</div>
            <div class="info-t">备案信息：</div>
            <div class="info-name flex-wrap">
                <span>备案收货人：</span>
                <span class="info-r">{{recordConsignee}}</span>
            </div>
            <div class="info-name info-start flex-wrap1">
                <span>地址：</span>
                <span class="info-r">{{recordAddress}}</span>
            </div>
        </div>
        <!-- 收货人 -->
        <div class="use-info">
            <div class="recevier flex-wrap">
                <span>收货人</span>
                <input type="text" placeholder="请输入收货人姓名" v-model="consignee">
            </div>
            <div class="recevier flex-wrap">
                <span>联系电话</span>
                <input type="text" placeholder="请输入收货人电话" v-model="mobile">
            </div>
        </div>
        <!-- 收货人地址 -->
        <div class="add-box">
            <div class="add-picker flex-wrap" @click="addArea = true ">
                <span class="add-tit">地址</span>
                <div class="picker-box flex-wrap">
                    <span v-if="!areaName" class="picker-tit font-grey">请选择地址</span>
                    <span v-else class="picker-tit">{{areaName}}</span>
                    <img src="@/assets/images/mine/arrow-r.png" alt="图标">
                </div>
            </div>
            <div class="add-picker flex-wrap">
                <span class="add-tit">详细地址</span>
                <input type="text" placeholder="请输入详细地址" v-model="address">
            </div>
        </div>


        <!-- 确定 -->
        <div class="sure-but" @click="confirm">确定</div>

        <van-popup v-model="addArea" round position="bottom">
            <van-picker show-toolbar title="请选择地区" value-key="Name" :columns="areaList" @change="onAreaChange" @cancel="onCancel" @confirm="onAreaConfirm"/>  
        </van-popup>
             

    </div>
</template>

<script>
export default {
    data(){
        return{
            addArea: false,  //地址
            areaName: '',   // 选择的省市区 名称总和
            editaddress: '',      // 编辑地址信息
			consignee: "", //收货人姓名
            mobile: '',     // 收货人联系电话
            address: '',    // 详细地址
            areaList: [{values: []}, {values: []}, {values: []}],        //自定义数据三级结构
            checkCity: '',     // picker拿到的值
            sureAdd: [],    // 点击确定传过来的值  选择地址
            recordConsignee:'',
            recordAddress:'',
        }
    },
    created(){
        var that = this;
        

        // 接收编辑地址的信息
        that.editaddress = JSON.parse(localStorage.getItem('addressDetail'));
		console.log("============",that.editaddress)
        if(that.editaddress){
            var areaAddress = that.editaddress.AreaProvinceName + that.editaddress.AreaName + that.editaddress.RegionName;
            that.areaName = areaAddress;
            that.mobile = that.editaddress.Mobile;
            that.address = that.editaddress.Address;
			that.consignee = that.editaddress.Consignee;
        }

        // that.getProvince();
        that.getCustomerInfo();
    },
    mounted(){
        var that = this;
        that.getArea(1,0);      //渲染页面时请求第一层省级数据
    },
    destroyed(){
        localStorage.removeItem('addressDetail');
    },
    methods:{
        // 请求地区数据，拼接三级联动
        getArea(parentId,index){
            var that = this;
            // console.log(parentId);
            var data = {
                AreaCode: parentId,
            }
            that.public.request('post','api/SysOrganize/GetChild',data,res => {
                // console.log(res);
                if(res.data.errorCode == '00'){
                    const regionList = res.data.rows;
                    that.areaList[index].values = [
                        {Name:'请选择'},
                        ...regionList
                    ];
                    if(index < 2){
                        that.areaList[index + 1].values = [];
                    }
                    that.areaList = [...that.areaList];
                    // console.log(that.areaList);
                }
                
            })
        },
        // 当地区选择变化时
        onAreaChange(picker,values,index){
            // console.log(values);
            if (index < 2) {
                this.getArea(values[index].Code, index + 1);                        //传参 参数为上层选择的地区的code
            }
        },

        //点击取消
        onCancel() {
            this.addArea = false;
        },

        //点击确定
        onAreaConfirm(value) {
            console.log(value);
            if (value[2] && value[2].Code) {
                this.checkCity = value[2];
                this.areaName = value[0].Name + value[1].Name + value[2].Name;
            } else if (value[1] && value[1].Code) {
                this.checkCity = value[1];
                this.areaName = value[0].Name + value[1].Name;
            } else if (value[0] && value[0].Code) {
                this.checkCity = value[0];
                this.$toast('请选择省市区');
            } else {
                this.checkCity = null;
            }
            this.sureAdd = value;
            this.addArea = false;
        },
        
        // 确定  保存
        confirm(){
            var that = this;
            var data = {
				Consignee: that.consignee ? that.consignee : '',
                Mobile: that.mobile ? that.mobile : '',
                Address: that.address ? that.address : '',
            }
            // 如果从地址列表页面进入，没有点击选择地址
            if(that.sureAdd.length <= 0 && that.editaddress){
                data.AreaProvince = that.editaddress.AreaProvince;
                data.AreaCode = that.editaddress.AreaCode;
                data.Regions = that.editaddress.Regions;
                data.addressId = that.editaddress.Id;
                data.Id = that.editaddress.Id;
                
            }else if(that.sureAdd.length > 0 && that.editaddress){   // 从地址列表页面进入，点击选择地址
                
                console.log(that.sureAdd);
                data.AreaProvince = that.sureAdd[0].Code;
                data.AreaCode = that.sureAdd[1].Code;
                data.Regions = that.sureAdd[2].Code ? that.sureAdd[2].Code : '';
                data.addressId = that.editaddress.Id;
                data.Id = that.editaddress.Id;
            }else if(that.sureAdd.length > 0 && !that.editaddress ){   // 新增地址
                data.AreaProvince = that.sureAdd[0].Code;
                data.AreaCode = that.sureAdd[1].Code;
                data.Regions = that.sureAdd[2].Code  ? that.sureAdd[2].Code : '';
            }
            // data.Consignee = that.recordConsignee;
			if(!data.Consignee){
				return  that.$toast('请输入收货人姓名');
			}
            if(!data.Mobile){
                that.$toast('请输入手机号');
                return;
            }
            var reg = /^1[0-9]{10}$/;
            if(!reg.test(data.Mobile)){
                that.$toast('请输入正确的手机号')
                return;
            }
            if(!that.areaName){
                that.$toast('请选择地址');
                return;
            }
            if(!data.Address){
                that.$toast('请输入详细地址');
                return;
            }
            that.public.request('post','/api/ReceiveAddress/SaveData',data,res => {
                console.log(res);
                if(res.data.errorCode == '00'){
                    that.$toast('成功');
                    setTimeout(function(){
                        that.$router.go(-1);
                    },2000)
                }else if(res.data.errorCode == '99'){
                    that.$toast(res.data.errorMsg);
                }
            })
        },

        //读取企业备案信息
        getCustomerInfo(){
            var that=this;
            var customerId = sessionStorage.getItem("customer")
            if(!customerId)
            {
                return;
            }
             that.public.request('post','/api/SysOrgInfo/QueryOrgInfo',{Id:customerId},res => {
                if(res.data.errorCode == '00' && !!res.data.rows){
                  that.recordConsignee=res.data.rows.OrgName;
                  that.recordAddress=res.data.rows.Address;
                }
            })
        },
        

    }
}
</script>

<style scoped>
    /* 备案收货信息 */
    .record-info{ width:100%;padding:0.2rem 0.2rem;box-sizing:border-box; }
    .record-info .info-tit{ font-size:0.3rem; }
    .record-info .info-t{ font-size:0.3rem; }
    .record-info .info-name{ width:100%; }
    .record-info .info-name.info-start{ align-items:flex-start; }
    .record-info .info-name span:first-child{ font-size:0.28rem; }
    .record-info .info-name .info-r{ flex:1;font-size:0.28rem;color:#666; }


    /* 收货人 */
    .use-info{ width:100%; }
    .use-info .recevier{ width:100%;height:0.8rem;padding:0 0.2rem;box-sizing:border-box;border-bottom:0.02rem solid #eee; }
    .use-info .recevier span{ font-size:0.3rem; width:1.6rem; }
    .use-info .recevier input{ flex:1;font-size:0.3rem; }

    /* 收货人地址 */
    .add-box .add-picker{ width:100%;height:0.8rem;padding:0 0.2rem;box-sizing:border-box;border-bottom:0.02rem solid #eee; }
    .add-box .add-picker .add-tit{ font-size:0.32rem;width:1.6rem; }
    .add-box .add-picker .picker-box{ flex:1; }
    .add-box .add-picker .picker-box .picker-tit{ font-size:0.28rem;color:#666;flex:1;text-align:right; }
    .add-box .add-picker .picker-box img{ width:0.15rem;margin-left:0.18rem; }

    .add-box .add-picker input{ flex:1;font-size:0.28rem;text-align:right; }


    /* 确定 */
    .sure-but{ width:80%;height:0.8rem;text-align:center;line-height:0.8rem;font-size:0.3rem;color:#fff;background:#16a2f3;border-radius:0.2rem;margin:0.8rem auto ; }
</style>