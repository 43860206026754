<template>
	<div class="container">
		<div style="width: 100%; height: 0.9rem">
			<div class="fixed-top flex-wrap" :style="{ top: topHeight + 'rem' }">
				<div class="top-tit">购物车</div>
				<div class="top-right" v-if="tabType == 'edit'" @click="onChange('edit')">
					编辑
				</div>
				<div class="top-right" v-if="tabType == 'finish'" @click="onChange('finish')">
					完成
				</div>
			</div>
		</div>

		<div class="deliery-box" v-if="ListShop.length > 0">
			<div class="deliery-name">{{ cartMessage.ReceiveName }}</div>
			<div class="deliery-tit flex-wrap" v-if="cartMessage.IsSetFreight == true">
				<img src="@/assets/images/main-lb.png">
				<span>满{{ cartMessage.IncludePostage }}元包邮；未满则加{{ cartMessage.Postage }}元运费；低于{{ cartMessage.MinPurchase
					}}元禁止下单</span>
			</div>
			<div class="deliery-tit flex-wrap"
				v-else-if="cartMessage.MinDeliveryAmount == null || cartMessage.MinDeliveryAmount === ''">
				<img src="@/assets/images/main-lb.png">
				<span>最低起送金额 :500</span>
			</div>
			<div class="deliery-tit flex-wrap" v-else>
				<img src="@/assets/images/main-lb.png">
				<span>最低起送金额 :{{ cartMessage.MinDeliveryAmount }}</span>
			</div>
		</div>

		<div style="padding-bottom: 1.5rem;">
			<div class="floor-list" v-for="(item1, ind) in ListShop" :key="ind">
				<!-- 公司 -->
				<div class="flex-wrap">
					<img class="item-img" v-if="!item1.IsAllSelected" src="@/assets/images/cart/icon_nochecked.png"
						@click.stop="selectCompany(ind)" />
					<img class="item-img" v-if="item1.IsAllSelected" :src="themeImgObj.selectImg"
						@click.stop="selectCompany(ind)" />
					<div class="flex-wrap company">
						<!-- <img src="@/assets/images/cart/comp1.png"/>  -->
						<div class="company-item">{{ item1.ShopName }}<span v-if="item1.allPrice < item1.MinPurchase"
								@click="makeUpBtn(item1.ShopNo)"> 总价低于<b>{{ item1.MinPurchase }}</b>元,<span
									class="fontRed">去凑单></span></span></div>
					</div>
				</div>
				<div class="subtotal">小计: <span v-if="item1.Subtota" style="color: red">
						￥{{ (item1.Subtota).toFixed(pointNum) }}</span> <span v-else style="color: red"> ￥0.00</span>
				</div>
				<div class="floor-item" v-for="(item, index) in item1.ListGoods" :key="index"
					@click="item.Status == null ? toDetail(item.CartSource, item.Spid, item.CartSourceId) : ''">

					<!-- 商品 -->
					<div class="flex-wrap">
						<div>
							<!-- <img class="item-img" v-if="tabType =='edit'&&item.CartSourceEndRemark!=null " src="@/assets/images/cart/icon_nochecked.png" alt=""/> -->
							<img class="item-img" v-if="!item.Selected" src="@/assets/images/cart/icon_nochecked.png"
								alt="" @click.stop="selectCur(ind, index)" />
							<img class="item-img" v-if="item.Selected" :src="themeImgObj.selectImg" alt=""
								@click.stop="selectCur(ind, index)" />
						</div>
						<div style="flex: 1" v-if="item.PackageDetail.length <= 0">
							<div class="item-right flex-wrap">
								<img class="floor-good" :src="item.SpPic"
									onerror="javascript:this.src='https://goodssuffix.oss-cn-qingdao.aliyuncs.com/upload/c5c2ae08dbfb45898d64061a0d82e83e/9cc5bc0235044c54916fd18243082d10.png'" />
								<div class="floor-specifi flex-column">
									<div>
										<span class="specifi-name">{{ item.SpName }} {{ item.Style }}</span>
										<span class="specifi-num"></span>
										<span class="specifi-company">{{ item.Factory }}</span>
										<div class="flex-wrap">
											<span class="specifi-specs">中包装：{{ item.zbz }}</span>
											<span class="specifi-specs" v-if="item.OnlyPackageSold">不可拆零</span>
											<span class="specifi-specs" v-if="!item.OnlyPackageSold">可拆零</span>
											<span style="font-size: 0.27rem; margin-left: 10px; color: #09A200;">库存：{{
												item.kcshl > 0 ? (!ShowStock ? item.kcshl : "有货") : "无货" }}</span>
										</div>
										<span class="specifi-company" v-if="item.ReferBatchNo">参考批号：{{ item.ReferBatchNo
											}}</span>
									</div>
									<div class="cart-operat flex-wrap">
										<div>
											<div class="specifi-price fontRed">￥ {{ item.BuyPrice }}</div>
											<div class="specifi-total">合计:￥ {{ (item.total).toFixed(pointNum) }} </div>
										</div>
										<!-- <div class="add-subtract flex-wrap">
										<span class="add" @click.stop="item.Status==null ? cutNum(ind,index) : ''">-</span>
										<input class="num" type="number" :disabled="item.Status==null?false: true" v-model="item.BuyCount"  @click.stop="editInput(item.BuyCount,index,ind)">
										<span class="cut" @click.stop="item.Status==null ? addNum(ind,index) :'' ">+</span>
									</div> -->
										<!-- && item.CartSource != 90 今日特价 && item.CartSource != 121 新人特价 -->
										<div class="add-subtract flex-wrap"
											v-if="item.CartSource != 20 && item.CartSource != 30 && item.CartSource != 35 && item.CartSource != 80">
											<span class="add"
												@click.stop="item.Status == null ? cutNum(ind, index) : ''">-</span>
											<input class="num" type="number"
												:disabled="item.Status == null ? false : true" v-model="item.BuyCount"
												@click.stop="editInput(item.BuyCount, index, ind)">
											<span class="cut"
												@click.stop="item.Status == null ? addNum(ind, index) : ''">+</span>
										</div>
										<div class="add-subtract flex-wrap" v-else>
											<input class="num grey" disabled type="number" v-model="item.BuyCount">
										</div>
									</div>
								</div>
							</div>

						</div>
						<!-- 套餐包 -->
						<div style="flex: 1" v-if="item.PackageDetail.length > 0">
							<div class="item-right flex-wrap" v-for="(packItem, j) in item.PackageDetail" :key="j">
								<img class="floor-good" v-if="packItem.SpPic" :src="packItem.SpPic"
									 onerror="javascript:this.src='https://goodssuffix.oss-cn-qingdao.aliyuncs.com/upload/c5c2ae08dbfb45898d64061a0d82e83e/9cc5bc0235044c54916fd18243082d10.png'" />
								<img class="floor-good" v-if="!packItem.SpPic" src="@/assets/images/loseImg.png"
									 onerror="javascript:this.src='https://goodssuffix.oss-cn-qingdao.aliyuncs.com/upload/c5c2ae08dbfb45898d64061a0d82e83e/9cc5bc0235044c54916fd18243082d10.png'" />
								<div class="floor-specifi flex-column">
									<div>
										<span class="specifi-name">{{ packItem.SpName }}</span>
										<span class="specifi-num">{{ packItem.Style }}</span>
										<span class="specifi-company">{{ packItem.Factory }}</span>
										<span style="font-size: 0.27rem; margin-left: 10px; color: #09A200;">库存：{{
											packItem.kcshl > 0 ? (!ShowStock ? packItem.kcshl : "有货") : "无货" }}</span>
									</div>
								</div>
							</div>
							<div class="cart-price-num flex-sp">

								<div class="cart-price flex-wrap">
									<div class="specifi-price fontRed">￥ {{ item.BuyPrice }}</div>
									<div class="specifi-total">合计：￥{{ (item.total).toFixed(pointNum) }} </div>
								</div>
								<!-- <div class="add-pack flex-wrap">
								<span class="add" @click.stop="cutNum(ind,index)">-</span>
								<input class="num" type="number" v-model="item.BuyCount"  @click.stop="editInput(item.BuyCount,index)" >
								<span class="cut" @click.stop="addNum(ind,index)">+</span>
							</div> -->
								<div class="add-pack flex-wrap"
									v-if="item.CartSource != 20 && item.CartSource != 30 && item.CartSource != 35 && item.CartSource != 80">
									<span class="add" @click.stop="cutNum(ind, index)">-</span>
									<input class="num" type="number" v-model="item.BuyCount"
										@click.stop="editInput(item.BuyCount, index, ind)">
									<span class="cut" @click.stop="addNum(ind, index)">+</span>
								</div>
								<div class="add-subtract flex-wrap" v-else>
									<input class="num grey" disabled type="number" v-model="item.BuyCount">
								</div>
							</div>

						</div>
					</div>
					<!-- 满赠优惠 -->
					<div class="preferential flex-wrap">
						<div class="per-select flex-wrap">
							<div v-if="item.Status == null">
								<!-- <div class="select-item fontRed" v-if="item.CartSource == '20'"></div> -->
								<div class="select-item fontRed" v-for="(activeItem, i) in item.ActivityCacheDataList"
									:key="i">
									<span
										v-if="activeItem.ActivityType < 4 && item.CartSourceEndTime == null && activeItem.ActivityType != 1">{{
											activeItem.Description }}</span>
									<span v-if="activeItem.ActivityType >= 4">{{ activeItem.Description }}</span>
								</div>
								<div class="select-item fontRed" v-if="item.CartSource == 20 && item.CartSourceEndTime">
									距离结束还剩余：{{ item.timeStamp | timeFormat }}</div>
								<div class="select-item font-blue" v-if="item.BuyCount > item.kcshl">库存不足，将以最大库存量下单
								</div>
								<div class="per-tip" v-if="item.OfferTips != null">{{ item.OfferTips }}</div>
								<!-- <div class="select-item flex-wrap">
								<img src="@/assets/images/cart/icon_nochecked.png" alt="">
								<span class="fontRed">满十赠一打发大发送到发送到发</span>
								
							</div> -->
							</div>
							<div v-if="item.Status == '99'">
								<div class="select-item fontRed">{{ item.StatusRemark }}</div>
							</div>
						</div>
						<!-- <div class="per-tip" v-if="item.OfferTips != null">{{item.OfferTips}}</div> -->
					</div>
				</div>
			</div>
		</div>

		<!-- 编辑商品数量弹窗 -->
		<div class="popup-box" v-if="editNumShow"></div>
		<div class="popup-cont" v-if="editNumShow">
			<div class="good-tit">编辑商品数量</div>
			<div class="good-num-box">
				<span class="reduce-g" @click="reduceNum1()">-</span>
				<input type="number" ref="editNum" v-model="cartEditNum" @keyup.enter="sureEdit()">
				<span class="add-g" @click="addNum1()">+</span>
			</div>
			<div class="good-btn">
				<div class="btn-g" id="cancel" @click="cancelEdit()">取消</div>
				<div class="btn-g blue" id="sure" @click="sureEdit()">确认</div>
			</div>
		</div>

		<!-- 底部全选 -->
		<!-- <div style="height: 1rem"></div> -->
		<div v-if="couponInfo" class="coupon-box">
			<span style="margin: 0 15px;">满{{ couponInfo.MixOrderAmount }}减{{ couponInfo.Amount }}</span>
			<span>{{ cartTotalMoney >= couponInfo.MixOrderAmount ? "当前条件已满足，快去下单吧~" : "再买" + (couponInfo.MixOrderAmount
				- cartTotalMoney).toFixed(2) + "元," + "可减" + couponInfo.Amount + "元" }}</span>
		</div>
		<div class="screen-bot flex-wrap">
			<div class="screen-left flex-sp">
				<div class="screen-b flex-wrap" @click="onAllSelect">
					<img v-if="!ListShop.IsAllSelected" src="@/assets/images/cart/icon_nochecked.png" alt="" />
					<img v-if="ListShop.IsAllSelected" :src="themeImgObj.selectImg" alt="" />
					<span>全选</span>
				</div>
				<div class="total-price flex-wrap">
					<span class="total-tit">合计：</span>
					<span class="total-pri">￥{{ cartTotalPrice.toFixed(pointNum) }}</span>
				</div>
			</div>
			<div class="screen-right flex-center" @click="toWaitBuyPay" v-if="tabType == 'edit'">
				去下单
			</div>
			<div class="screen-right delete-red flex-center" v-if="tabType == 'finish'" @click="deleteGood">
				删除
			</div>
		</div>
		<!-- 暂无数据 -->
		<div class="empty-box" v-if="ListShop.length <= 0">
			<div class="no-data">
				<img src="@/assets/images/cart/noActiveDrug.png" alt="暂无数据" />
				<span>您的购物车空空如也</span>
			</div>
			<div class="cart-but" @click="toSearch()">去逛逛</div>
		</div>

		<!-- 底部提示 -->
		<div style="height:0.3rem;" v-if="tipShow">
			<div class="bot-tip">当前购物车有商品库存不足,下单即生成采购计划</div>
		</div>

	</div>
</template>



<script>
export default {
	data() {
		return {
			cartInfo: [],		// 购物车列表数据
			cartMessage: {},		// 购物车信息
			allSelect: false,
			tabType: "edit", // edit编辑 finish完成
			tipShow: false,		// 商品库存不足时的总提示

			editNumShow: false,	// 编辑商品数量弹窗
			cartNum: 1,		// 购物车数量
			cartEditNum: 1,	//  编辑购物车弹窗数量
			cartIndex: 0,	// 修改数量商品的下标

			isBack: true,		// 为true可以返回上一页
			pointNum: 2,//小数点
			ListShop: [],//
			cartTotalPrice: 0,//总价格
			companyIndex: 0,    // 修改数量商品公司的下标

			ticker: null,
			topHeight: 0,	// 顶部距离
			ShowStock: JSON.parse(localStorage.getItem("ShowStock")),
			keyId: "", //埋点 主键
			couponInfo: null, //优惠券信息
			couponExtent: null,
			cartTotalMoney: 0, //可使用优惠券的总金额
			// noActiveList: [], //不可使用活动类型的优惠券
			isActive: false, //选中的商品是否包含特价商品
		};
	},
	created() {
		var that = this;
		//统计分析 埋点进来
		// this.keyId = ""; //每次进入置空
		// this.public.setBuryPoint(5,1,this.keyId).then(res=>{
		// 	if(res.errorCode=="00"){
		// 		that.keyId = res.rows;
		// 	}
		// });
		let clientType = localStorage.getItem("clientType");
		switch (localStorage.clientType) {
			case "app":
				that.topHeight = 0.8;
				break;
			case "ios":
				that.topHeight = 0.8;
				break;
			case "applets":
				that.topHeight = 0;
				break;
			case "weixin":
				that.topHeight = 0;
				break;
			default:
				that.topHeight = 0;
				break;
		}
		// 主题图片
		that.themeImgObj = that.themeColorImg.imgObj;
		var shuju = JSON.parse(localStorage.getItem('webconfig'));
		if (shuju && shuju.GoodPriceFixed) {
			that.pointNum = shuju.GoodPriceFixed;
		}

		// 请求购物车数量
		that.public.getCartNum();
		// that.getCoupon();
	},
	mounted() {
		var that = this;
		that.getCartList(1);
		//这一段是防止进入页面出去后再进来计时器重复启动
		if (this.ticker) {
			clearInterval(this.ticker);
		}
		that.chronography();   //  倒计时处理
		that.isBack = that.$route.meta.isBack;
		if (window.history && window.history.pushState) {
			history.pushState(null, null, document.URL);
			window.addEventListener('popstate', that.popstateBack, true);
		}
	},
	destroyed() {
		clearInterval(this.ticker);
		window.removeEventListener('popstate', this.popstateBack, true);

		//埋点离开
		// this.public.setBuryPoint(5,2,this.keyId).then(res=>{
		// 	if(res.errorCode=="00"){}
		// });
	},
	methods: {
		// 获取优惠券
		getCoupon() {
			var that = this;
			that.public.request('post', '/api/coupon/queryUserAvailableCoupon', {
				isShowGet: false,
			}, res => {
				if (res.data.errorCode === '00') {
					that.couponExtent = res.data.extend;
					if (res.data.rows && res.data.rows.length > 0) {
						//可使用优惠券的总金额
						if (that.couponInfo) {
							that.cartTotalMoney = that.getCurCoupMoney(that.cartTotalPrice);
							if (that.cartTotalMoney >= that.couponInfo.MixOrderAmount) {
								that.couponInfo = null;
							}
						}
					}
				}
			})
		},

		// 获取当前优惠券的有效金额
		getCurCoupMoney(cartTotalPrice) {
			var that = this;
			var totalUnCoupidMoney = cartTotalPrice;
			if (!that.couponInfo) {
				return 0;
			}
			var shopList = that.ListShop;
			if (!that.couponExtent || !shopList || shopList.length === 0) {
				return totalUnCoupidMoney;
			}
			shopList.forEach(item => {
				item.ListGoods.filter(car => {
					if (car.Selected && that.couponInfo.NoActiveList && that.couponInfo.NoActiveList.length > 0) {
						let noActive = false;
						switch (parseInt(car.CartSource)) {
							case 20: //秒杀
								noActive = that.couponInfo.NoActiveList.some(i => (i === 0));
								break;
							case 35: //集采
								noActive = that.couponInfo.NoActiveList.some(i => (i === 1));
								break;
							case 30: //团购
								noActive = that.couponInfo.NoActiveList.some(i => (i === 2));
								break;
							case 90: //今日特价
								noActive = that.couponInfo.NoActiveList.some(i => (i === 3));
								break;
							case 121: //新人特价
								noActive = that.couponInfo.NoActiveList.some(i => (i === 4));
								break;
							case 122: //优惠方案
								noActive = that.couponInfo.NoActiveList.some(i => (i === 5));
								break;
							case 80: //近效期
								noActive = that.couponInfo.NoActiveList.some(i => (i === 6));
								break;
							default:
								break;
						}
						if (noActive) {
							var moneys = (car.BuyCount * car.BuyPrice - car.DiscountAmount);
							totalUnCoupidMoney = totalUnCoupidMoney - moneys;
							if (totalUnCoupidMoney <= 0) {
								totalUnCoupidMoney = 0;
							}
						}
					}

					//后台设置了商品不能使用该券，减去对应的商品金额
					let _extentSome = that.couponExtent.some(n => {
						if (that.couponInfo.CouponId === n.CouponId && n.Spid === car.Spid) {
							if (car.Selected) {
								var moneys = (car.BuyCount * car.BuyPrice - car.DiscountAmount);
								totalUnCoupidMoney = totalUnCoupidMoney - moneys;
								if (totalUnCoupidMoney <= 0) {
									totalUnCoupidMoney = 0;
								}
								return true;
							}
						}
					});

					return _extentSome;
				})
			})

			return totalUnCoupidMoney;
		},

		//这个计时器是每秒减去数组中指定字段的时间
		chronography() {
			var that = this;
			that.ticker = setInterval(() => {
				for (var i = 0; i < that.ListShop.length; i++) {
					if (that.ListShop[i].ListGoods) {
						for (var j = 0; j < that.ListShop[i].ListGoods.length; j++) {
							if (that.ListShop[i].ListGoods[j].timeStamp > 0) {
								that.ListShop[i].ListGoods[j].timeStamp = that.ListShop[i].ListGoods[j].timeStamp - 1;
								that.$forceUpdate();
							}
						}
					}

				}

			}, 1000);

		},

		// 去逛逛
		toSearch() {
			this.$router.push({
				path: '/search',
			})
		},
		// 跳转详情
		toDetail(cartSource, spid, id) {
			var that = this;
			if (cartSource == '20') {		// 秒杀
				var type = 'seckill';
				that.$router.push({
					path: '/activeDetail',
					query: {
						spid: spid,
						id: id,
						type: type,
					}
				})
			} else if (cartSource == '30') {	// 团购
				var type = 'group';
				that.$router.push({
					path: '/activeDetail',
					query: {
						spid: spid,
						id: id,
						type: type,
					}
				})
			} else if (cartSource == '35') {	//集采
				var type = 'purch';
				that.$router.push({
					path: '/activeDetail',
					query: {
						spid: spid,
						id: id,
						type: type,
					}
				})
			} else if (cartSource == '80') {   // 近效期
				var type = 'nearExpiry';
				that.$router.push({
					path: '/activeDetail',
					query: {
						spid: spid,
						id: id,
						type: type,
					}
				})
			} else if (cartSource == '90') {	// 今日特价
				var type = 'bargain';
				that.$router.push({
					path: '/activeDetail',
					query: {
						spid: spid,
						id: id,
						type: type,
					}
				})
			} else if (cartSource == '121') {	// 新人特价
				var type = 'new_bargain';
				that.$router.push({
					path: '/activeDetail',
					query: {
						spid: spid,
						id: id,
						type: type,
					}
				})
			} else {			// 普通商品
				that.$router.push({
					path: '/goodsDetail',
					query: {
						spid: spid,

					}
				})

			}

		},
		//去凑单
		makeUpBtn(id) {
			var that = this;
			that.$router.push({
				path: '/shopAllProducts',
				query: {
					ShopNo: id,
				}
			})


		},

		// 编辑input数值
		editInput(countNum, index, ind) {
			var that = this;
			that.editNumShow = true;
			that.$nextTick(() => {
				that.$refs.editNum.focus();
				that.$refs.editNum.select();
			})
			that.cartEditNum = countNum;
			that.companyIndex = ind;
			that.cartIndex = index;

		},
		// 取消编辑弹窗
		cancelEdit() {
			var that = this;
			that.editNumShow = false;
		},
		// 购物车数量减
		reduceNum1() {
			var that = this;
			var ind = that.companyIndex;
			var index = that.cartIndex;
			var cartItem = that.ListShop[ind].ListGoods[index];
			var MinBuyCount = cartItem.MinBuyCount;
			var OnlyPackageSold = cartItem.OnlyPackageSold;
			var OrderNumSetp = cartItem.OrderNumSetp;
			if (OnlyPackageSold && OrderNumSetp > 0) {
				var cartNum = OrderNumSetp;
			} else {
				var cartNum = 1;
			}

			if (Number(that.cartEditNum) > Number(cartNum)) {
				that.cartEditNum = Number(that.cartEditNum) - Number(cartNum);
				// 最低下单量
				// if(MinBuyCount > 0){
				// 	if(Number(that.cartEditNum) > Number(MinBuyCount)){
				// 		that.cartEditNum--;
				// 	}else{
				// 		that.$toast("小于最低下单量："+ MinBuyCount)
				// 	}

				// }else{
				// 	that.cartEditNum = Number(that.cartEditNum) - Number(cartNum);
				// }
			}
			if (MinBuyCount && MinBuyCount > 0) {
				if (Number(that.cartEditNum) < Number(MinBuyCount)) {
					that.cartEditNum = MinBuyCount;
					that.$toast("小于最低下单量：" + MinBuyCount)
				}
			}
		},
		// 购物车数量加
		addNum1() {
			var that = this;
			var ind = that.companyIndex;
			var index = that.cartIndex;
			var cartItem = that.ListShop[ind].ListGoods[index];
			var OnlyPackageSold = cartItem.OnlyPackageSold;
			var OrderNumSetp = cartItem.OrderNumSetp;
			if (OnlyPackageSold && OrderNumSetp > 0) {
				var cartNum = OrderNumSetp;
			} else {
				var cartNum = 1;
			}
			if (that.cartEditNum >= cartItem.kcshl) {
				return that.$toast("库存已不足");
			}
			that.cartEditNum = Number(that.cartEditNum) + Number(cartNum);
		},
		// 编辑弹窗确认
		sureEdit() {
			var that = this;
			var orgId = localStorage.orgId;
			// 如果orgId 不是瑞龙的，则只能输入整数
			var regNum = /^\+?[1-9][0-9]*$/;
			if (!regNum.test(that.cartEditNum)) {
				that.$toast('请输入大于0的整数')
				return;
			}
			var ind = that.companyIndex;
			var index = that.cartIndex;
			var cartItem = that.ListShop[ind].ListGoods[index];
			if (that.cartEditNum > cartItem.kcshl) {
				return that.$toast("库存已不足");
			}

			that.editNumShow = false;
			var MinBuyCount = cartItem.MinBuyCount;
			// 中包装
			var OnlyPackageSold = cartItem.OnlyPackageSold;
			var OrderNumSetp = cartItem.OrderNumSetp;
			if (OnlyPackageSold && OrderNumSetp > 0) {
				var cartNum = OrderNumSetp;
				// 取余
				if (that.cartEditNum % cartNum == 0) {
					// cartItem.BuyCount = that.cartEditNum;
				} else {
					return that.$toast("购买数量" + that.cartEditNum + "，必须为" + cartNum + "的倍数");
					// cartItem.BuyCount = cartItem.BuyCount;
				}
			} else {
				// 最低下单量
				if (MinBuyCount > 0) {
					if (Number(that.cartEditNum) >= Number(MinBuyCount)) {
						// cartItem.BuyCount = that.cartEditNum;
					} else {
						return that.$toast("小于最低下单量：" + MinBuyCount);
						// cartItem.BuyCount = cartItem.BuyCount;
					}
				} else {
					// cartItem.BuyCount = that.cartEditNum;
				}
			}

			// that.cartEditNum = 1;
			// var id = cartItem.Id;
			// var num = cartItem.BuyCount;
			// that.getCartNum(id, num);
			// var old=cartItem.total;
			// 	cartItem.total=cartItem.BuyPrice*num;
			// 	if(cartItem.Selected){
			// 		that.ListShop[that.companyIndex].Subtota+=(cartItem.total-old);
			// 		that.cartTotalPrice+=(cartItem.total-old);
			// 	}

			that.getCartNum(cartItem, that.cartEditNum);

		},

		// 编辑
		onChange(type) {
			var that = this;
			if (type == "edit") {
				that.tabType = "finish";
			} else {
				that.tabType = "edit";
			}
		},
		// 删除
		deleteGood() {
			var that = this;
			var allArr = [];
			that.ListShop.forEach((item1, index) => {
				item1.ListGoods.forEach(item => {
					if (item.Selected == true) {
						allArr.concat(allArr.push({ Id: item.Id }));
					}
				})

			});
			if (allArr.length <= 0) {
				that.$toast("请选择需要删除的商品");
				return false;
			}
			that.$dialog.confirm({
				message: "确认删除吗",
			}).then(() => {
				that.public.request("post", "/api/SellWellCar/DeleteSellWellCar", {
					ListSellWellCarId: allArr,
				}, (res) => {
					if (res.data.errorCode == "00") {
						that.$toast("删除成功");
						that.getCartList(1);
						that.public.getCartNum();
						that.$forceUpdate();
					} else {
						that.$toast(res.data.errorMsg);
					}
				});
			}).catch(() => {
				that.$toast(res.data.errorMsg);
			});
		},

		// 增加商品数量
		addNum(ind, index) {
			var that = this;
			// 中包装
			var cartItem = that.ListShop[ind].ListGoods[index]
			var OnlyPackageSold = cartItem.OnlyPackageSold;
			var OrderNumSetp = cartItem.OrderNumSetp;
			if (OnlyPackageSold && OrderNumSetp > 0) {
				var cartNum = OrderNumSetp;
			} else {
				var cartNum = 1;
			}
			if (cartItem.BuyCount >= cartItem.kcshl) {
				return that.$toast("库存已不足");
			}

			// cartItem.BuyCount = Number(cartItem.BuyCount) + Number(cartNum);
			let num = Number(cartItem.BuyCount) + Number(cartNum);
			that.getCartNum(cartItem, num);
			// var old=cartItem.total;
			// cartItem.total=cartItem.BuyPrice*cartItem.BuyCount;
			// if(cartItem.Selected){
			// 	that.ListShop[ind].Subtota+=(cartItem.total-old);
			// 	that.cartTotalPrice+=(cartItem.total-old);
			// }
		},
		// 减少商品数量
		cutNum(ind, index) {
			var that = this;
			var MinBuyCount = that.ListShop[ind].ListGoods[index].MinBuyCount;
			var cartItem = that.ListShop[ind].ListGoods[index]
			// 中包装
			var OnlyPackageSold = cartItem.OnlyPackageSold;
			var OrderNumSetp = cartItem.OrderNumSetp;
			if (OnlyPackageSold && OrderNumSetp > 0) {
				var cartNum = OrderNumSetp;
			} else {
				var cartNum = 1;
			}
			var num = cartItem.BuyCount;
			if (Number(num) > Number(cartNum)) {
				num = Number(num) - Number(cartNum);
				// 最低下单量减1
				// if(MinBuyCount>0){
				// 	if(Number(num) > Number(MinBuyCount)){
				// 		num--;
				// 	}else{
				// 		that.$toast("小于最低下单量："+ MinBuyCount);
				// 		return false;
				// 	}
				// }else{
				// 	num = Number(num) - Number(cartNum);
				// }
			}
			if (MinBuyCount && MinBuyCount > 0) {
				if (Number(num) < Number(MinBuyCount)) {
					num = MinBuyCount;
					that.$toast("小于最低下单量：" + MinBuyCount);
					return
				}
			}

			// var id = cartItem.Id;
			// cartItem.BuyCount = num;
			that.getCartNum(cartItem, num);
			// var old=cartItem.total;
			// cartItem.total=cartItem.BuyPrice*num;
			// if(cartItem.Selected){
			// 	that.ListShop[ind].Subtota-=(old-cartItem.total);
			// 	that.cartTotalPrice-=(old-cartItem.total);
			// }

		},

		//  商品数量请求上传
		getCartNum(cartItem, num) {
			var that = this;
			that.public.request("post", "/api/sellWellCar/updateSellWellCarCount", {
				Id: cartItem.Id,
				GoodsCount: num,
			}, (res) => {
				if (res.data.errorCode == "00") {

					let old = cartItem.total;
					cartItem.BuyCount = num;
					cartItem.total = cartItem.BuyPrice * num;
					if (cartItem.Selected) {
						that.ListShop[that.companyIndex].Subtota += (cartItem.total - old);
						that.cartTotalPrice += (cartItem.total - old);
					}

					// that.getCartList();
				} else if (res.data.errorCode == '99') {
					that.$toast(res.data.errorMsg);
				}
			});
		},
		// 全选  未选
		onAllSelect() {
			var that = this;
			var cartInfo = that.ListShop;
			var price = Number(that.totalPrice);
			var data = {};
			var allArr = [];
			if (cartInfo.IsAllSelected) {
				cartInfo.IsAllSelected = !cartInfo.IsAllSelected;
				that.cartTotalPrice = 0;
				cartInfo.forEach(i => {
					i.Subtota = 0;
					i.IsAllSelected = false;
					i.ListGoods.forEach(i2 => {
						i2.Selected = false;
						allArr.push(i2.Id);
					});
				});
				that.isActive = false;
			} else {
				cartInfo.IsAllSelected = !cartInfo.IsAllSelected;
				// console.log(cartInfo.IsAllSelected);
				that.cartTotalPrice = 0;
				cartInfo.forEach(i => {
					i.Subtota = 0;
				});
				// console.log('清空后',cartInfo);

				cartInfo.forEach(item => {
					item.IsAllSelected = cartInfo.IsAllSelected;
					item.ListGoods.forEach((item2, ind2) => {
						item2.Selected = cartInfo.IsAllSelected;
						allArr.push(item2.Id);
						item.Subtota += item2.total;
						//处理过期商品
						if (that.tabType == 'edit') {
							if (item2.Status == '99') {
								allArr.forEach((i, j) => {//删除过期数据
									if (i == item2.Id) {
										allArr.splice(j, 1);
									}
								});
								item.Subtota -= item2.total;
								item2.Selected = false;
							}
						}
						if (item2.ActivityCacheData) {//有参与活动
							that.isActive = true;
						}
					});
					that.cartTotalPrice += item.Subtota;
				});
			}
			// console.log('最后',cartInfo);
			data.Selected = cartInfo.IsAllSelected;
			// console.log(cartInfo);
			data.ids = allArr;
			if (that.couponInfo) {
				that.cartTotalMoney = that.getCurCoupMoney(that.cartTotalPrice);
			}
			that.getSelect(data); // 请求选择接口
			that.totalPrice = price;
			that.ListShop = cartInfo;
		},
		// 选择接口
		getSelect(data) {
			var that = this;
			that.public.request("post", "/api/SellWellCar/SetSelected", data, (res) => {
				if (res.data.errorCode == "00") {

				}
			});
		},

		// 选择商品
		selectCur(ind, index) {
			var that = this;
			var allArr = [];
			var cartList = that.ListShop[ind].ListGoods[index];
			if (that.tabType == 'edit')
				if (cartList.Status == '99') {
					return false
				}
			if (!cartList.Selected) {//选中
				that.ListShop[ind].Subtota += cartList.total;
				that.cartTotalPrice += cartList.total;
				cartList.Selected = true;
				allArr.push(cartList.Id);
				var data = {
					ids: allArr,
					Selected: true,
				}
				that.getSelect(data);
				var aa = that.ListShop[ind].ListGoods.some(item2 => {
					return !item2.Selected
				})
				that.ListShop[ind].IsAllSelected = !aa;
				//全选
				that.ListShop.IsAllSelected = true;
				that.ListShop.forEach(item => {
					if (!item.IsAllSelected) {
						that.ListShop.IsAllSelected = false;
					}
				});
			} else {
				// 不选
				cartList.Selected = false;
				allArr.push(cartList.Id);
				var data = {
					ids: allArr,
					Selected: false,
				}
				that.ListShop[ind].Subtota -= cartList.total;
				that.cartTotalPrice -= cartList.total;
				that.getSelect(data);
				that.ListShop[ind].IsAllSelected = false;
				that.ListShop.IsAllSelected = false;
			}

			that.isActive = false;
			that.ListShop[ind].ListGoods.forEach(res => {
				if (res.ActivityCacheData && res.Selected) {
					that.isActive = true;
				}
			})
			if (that.couponInfo) {
				that.cartTotalMoney = that.getCurCoupMoney(that.cartTotalPrice);
			}
		},
		// 选择公司(1)
		selectCompany(index) {
			var that = this;
			var allArr = [];
			var cartInfo = that.ListShop[index];

			//点击公司
			if (!cartInfo.IsAllSelected) {
				cartInfo.Subtota = 0;
				var allTotal = that.cartTotalPrice;
				cartInfo.ListGoods.forEach(item => {
					if (item.Selected) {
						allTotal -= item.total;
					}
				})
				that.cartTotalPrice = allTotal;
				cartInfo.IsAllSelected = !cartInfo.IsAllSelected;
				that.ListShop.IsAllSelected = true;
				cartInfo.ListGoods.forEach(item => {
					item.Selected = cartInfo.IsAllSelected;
					cartInfo.Subtota += item.total;
					allArr.push(item.Id);
				});
				//处理过期商品，
				cartInfo.ListGoods.forEach((item1, ind1) => {
					if (that.tabType == 'edit') {
						if (item1.Status == '99') {
							allArr.splice(ind1, 1);
							cartInfo.Subtota -= item1.total;
							item1.Selected = false;
						}
					}
					if (item1.ActivityCacheData) {//有参与活动
						that.isActive = true;
					}
				})
				that.cartTotalPrice += cartInfo.Subtota;
				that.ListShop.forEach(item => {
					if (!item.IsAllSelected) {
						that.ListShop.IsAllSelected = false;
					}
				})
			} else if (cartInfo.IsAllSelected) {
				cartInfo.IsAllSelected = !cartInfo.IsAllSelected;
				that.cartTotalPrice -= cartInfo.Subtota;
				// console.log(cartInfo);
				cartInfo.Subtota = 0;
				cartInfo.ListGoods.forEach(item => {
					item.Selected = cartInfo.IsAllSelected;
					allArr.push(item.Id);
				})
				that.ListShop.IsAllSelected = false;
				that.isActive = false;
			}

			var data = {
				ids: allArr,
				Selected: cartInfo.IsAllSelected,
			}
			if (that.couponInfo) {
				that.cartTotalMoney = that.getCurCoupMoney(that.cartTotalPrice);
			}
			that.getSelect(data); // 请求选择接口
		},
		// 请求购物车列表(1)
		getCartList(first) {
			var that = this;
			that.tipShow = false;
			that.isActive = false;
			if (first == '1') {
				that.$toast.loading({
					message: "加载中...",
					duration: 0,
				});
			}
			var token = sessionStorage.getItem('member_token');
			if (!token) {
				that.$toast.clear();
				return
			}
			that.public.request("post", "/api/SellWellCar/QuerySellWellCarList", {}, (res) => {
				if (first == '1') {
					that.$toast.clear();
					that.cartTotalPrice = 0;
				}
				if (res.data.errorCode != "00") {
					that.$toast(res.data.errorMsg);
					return;
				}
				var allArr = [];
				if (res.data.rows.length == 0) {
					that.ListShop = [];
					return

				} else {

					that.ListShop = res.data.rows[0].ListShop;
					that.ListShop.IsAllSelected = true;
					that.ListShop.forEach(item => {
						item.Subtota = 0;
						item.allPrice = 0;
						item.ListGoods.forEach(item2 => {
							if (item2.Status == '99') {//过期商品
								item2.Selected = false;
							}
							if (item2.CartSource > 0 && item2.Status != '99') {  // 活动商品
								// item2.total= item2.BuyCount*(item2.BuyPrice).toFixed(that.pointNum);	
								item2.total = item2.BuyCount * (item2.BuyPrice);
							} else {		// 普通商品
								// item2.total= item2.BuyCount*(item2.BuyPrice).toFixed(that.pointNum);
								item2.total = item2.BuyCount * (item2.BuyPrice);
							}
							if (item2.CartSource == 20 && item2.CartSourceEndRemark == null) {  // 秒杀商品
								// 转化为时间戳
								item2.timeStamp = that.public.timeStamp(item2.CartSourceEndTime);
							}
							if (item2.Status == '99') {//过期商品
								item2.Selected = false;
							}
							if (item2.Status == '99') {//过期商品
								item2.Selected = false;
							}

							item.allPrice += Number(item2.total);

							if (item2.Selected) {
								item.Subtota += item2.total;
								allArr.push(item2.id);
								if (first == '1') {
									that.cartTotalPrice += item2.total;
								}

							}
							if (item2.ActivityCacheData && item2.Selected) {//有参与活动
								that.isActive = true;
							}
						})
						if (!item.IsAllSelected) {
							that.ListShop.IsAllSelected = false;
						}
					})



					that.cartMessage = res.data.rows[0];
					that.cartInfo = that.ListShop;
					that.getCoupon();
				}

			}
			);
		},
		toFixed(num, s) {
			var times = Math.pow(10, s);
			var des = num * times + 0.5;
			des = parseInt(des, 10) / times;
			return des;
		},


		//判断是否小程序浏览器加载
		isMini: function () {
			let mini = false;
			if (/MicroMessenger/i.test(navigator.userAgent)) {
				//ios的ua中无miniProgram，很坑爹,但都有MicroMessenger（表示是微信浏览器）
				this.wx.miniProgram.getEnv((res) => {
					if (res.miniprogram) {
						mini = true;
					}
				});
			}
			return mini;
		},

		// 去下单
		toWaitBuyPay() {
			var that = this;
			// 查看是否登录
			var token = sessionStorage.getItem('member_token');
			if (token == null) {
				var orgId = localStorage.getItem("orgId");
				that.$dialog.confirm({
					message: "你还未登录，是否登录!",
				}).then(() => {
					if (that.isMini()) {
						that.wx.miniProgram.reLaunch({
							url: window.baseUrl.xcxLogin ? '/pages/login/index?logout=true' : '/pages/login/oldLogin/index?logout=true',
						})
						return;
					}
					that.$router.push({
						path: "/login",
						query: {
							orgId: orgId,
							auto: 0,
						},
					});
				}).catch(() => { });
				return false;
			}

			var selected = false; //是否已选择了购买商品
			let selectFree = true; //选中的商品是否全部包邮
			var freePic = 0; //选中的免邮商品的总价格
			var falseArr = [];	// 最低下单量
			that.ListShop.forEach(item1 => {
				if (item1.Subtota > 0) {
					selected = true;
					if (item1.MinPurchase > 0 && item1.Subtota < item1.MinPurchase) {
						falseArr.push({
							ShopName: item1.ShopName,
							MinPurchase: item1.MinPurchase,
						});
					}
				}
				item1.ListGoods.forEach(item2 => {
					if (item2.Selected && item2.Status == '99') {
						that.$toast(item2.SpName + '请重新加入购物车');
						return;
					}
					//计算选中商品的包邮总价格
					if (item2.Selected && item2.ActivityCacheData && item2.ActivityCacheData.IsFree) {
						freePic = freePic + item2.total;
					}
					//选中的商品，没参加任何活动，不包邮
					if (item2.Selected && !item2.ActivityCacheData) {
						selectFree = false;
					}
					//选中的商品，参加了活动，且不包邮
					if (item2.Selected && item2.ActivityCacheData && !item2.ActivityCacheData.IsFree) {
						selectFree = false;
					}
				})
			});

			//未选择购买商品
			if (selected == false) {
				that.$toast("请选择商品");
				return;
			}
			//如果选择的商品全是包邮的， 则直接下单
			if (selectFree) {
				that.$router.push({
					path: "/waitBuyPay",
					query: {
						refresh: 'refresh'
					}
				});
				return;
			}

			// 判断商店最小购买总价格
			if (falseArr.length) {
				that.$toast(falseArr[0].ShopName + '低于' + falseArr[0].MinPurchase + '元,无法下单');
				return false;
			}
			// 判断低于多少元禁止下单
			var MinDeliveryAmount = that.cartMessage.MinDeliveryAmount;
			var cartTotalPrice = that.cartTotalPrice;
			if (MinDeliveryAmount > 0 && MinDeliveryAmount > cartTotalPrice) {
				that.$toast("最低起配送金额" + MinDeliveryAmount + "元，望继续下单完成订单！")
				return;
			}
			// else if(!MinDeliveryAmount){
			// 	that.$toast("最低起配送金额" + 300 + "元，望继续下单完成订单！")
			// }
			// 判断是否需要运费
			var cartMessage = that.cartMessage;
			let totalPic = Number(cartTotalPrice) - Number(freePic);
			if (cartMessage.IsSetFreight) {
				//如果 合计价格-包邮商品总价格 小于 满包邮的价格  则收取运费
				if (cartMessage.IncludePostage > 0 && cartMessage.IncludePostage > totalPic) {
					that.$dialog.confirm({
						message: "需要加" + cartMessage.Postage + "元运费，确定提交吗?",
					}).then(() => {
						that.$router.push({
							path: "/waitBuyPay",
							query: {
								refresh: 'refresh'
							}
						});
					}).catch(() => { });
					return;
				};

			}

			that.$router.push({
				path: "/waitBuyPay",
				query: {
					refresh: 'refresh'
				}
			});

		},
		// 监听返回
		popstateBack() {
			var that = this;
			var isBack = that.isBack;
			if (!isBack) {
				var userAgent = navigator.userAgent;
				var isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1; //android终端
				var isIos = (userAgent.indexOf('iphone') > -1 ||
					userAgent.indexOf('ipad') > -1 ||
					userAgent.indexOf('ipod') > -1) &&
					(platform.indexOf('iphone') > -1 ||
						platform.indexOf('ipad') > -1 ||
						platform.indexOf('ipod') > -1);

				if (window.WeixinJSBridge) {
					window.WeixinJSBridge.call('closeWindow') // 微信
				} else if (window.AlipayJSBridge) {
					window.AlipayJSBridge.call('closeWebview') // 支付宝
				} else if (isAndroid) {
					window.obj.closePageLppzRequest('') // 安卓app
				} else if (isIos) {
					window.webkit.messageHandlers.closePageLppzRequest.postMessage('') //ios app
				} else {

					location.replace("about:blank", "_top");

				}
			}
		}
	},
	computed: {
		// cartTotalPrice:function(){
		// 	var that = this;
		// 	var totalPrice = Number(0);
		// 	that.ListShop.forEach((item, index) => {
		// 		item.ListGoods.forEach(item2=>{
		// 			if(item2.selected){
		// 				totalPrice+=item2.total;

		// 			}

		// 		})

		// 	});
		// 	totalPrice = parseFloat(totalPrice).toFixed(this.pointNum);
		// 	return totalPrice;
		// },
		subtotal: function () {


		}
	},

	filters: {
		// 局部过滤器
		timeFormat(timeDate) {

			// 天时分秒  倒计时
			var time = timeDate;
			var day = parseInt(time / 60 / 60 / 24);
			var hourse = parseInt(time / 60 / 60) % 24;
			var minute = parseInt(time / 60) % 60;
			var second = parseInt(time % 60);
			hourse = hourse < 10 ? (`0${hourse}`) : hourse;
			minute = minute < 10 ? (`0${minute}`) : minute;
			second = second < 10 ? (`0${second}`) : second;
			var timeDown = day + "天" + hourse + ":" + minute + ":" + second;
			return timeDown;

		}
	},

};
</script>

<style scoped>
.font-blue {
	color: #007aff;
}

/* 顶部导航 编辑 */
.fixed-top {
	position: fixed;
	left: 0;
	background: #fff;
	z-index: 1;
	border-bottom: 0.02rem solid #eee;
	width: 100%;
	height: 0.9rem;
}

.fixed-top .top-tit {
	flex: 1;
	text-align: center;
	font-size: 0.3rem;
	padding-left: 1.5rem;
}

.fixed-top .top-right {
	width: 1.5rem;
	text-align: center;
	font-size: 0.3rem;
}

/* 顶部最低配送金额 */
.deliery-box {
	width: 100%;
	padding: 0.1rem 0.3rem;
	box-sizing: border-box;
	border-bottom: 0.16rem solid #f7f7f7;
}

.deliery-box .deliery-name {
	font-size: 0.36rem;

}

.deliery-box .deliery-tit {
	font-size: 0.24rem;
	color: #999;
}

.deliery-box .deliery-tit img {
	width: 0.3rem;
	margin-right: 0.2rem;
}

/* 购物车列表 */
.floor-list {
	width: 100%;
	padding: 0.2rem 0.2rem;
	box-sizing: border-box;

}

.floor-list .floor-item {
	width: 100%;

	box-sizing: border-box;
	border-top: 0.02rem solid #eee;
	margin-top: 0.1rem;
}

/* .floor-list .floor-item:last-child {
		border-bottom: none;
	} */

.floor-list .floor-item .floor-good {
	width: 1.6rem;
	max-height: 2rem;
	margin-right: 0.2rem;
	object-fit: contain;
}

.floor-list .floor-item .floor-specifi {
	/* width: 100%; */
	/* height: 2.1rem; */
	flex: 1;
	justify-content: space-between;
}

.floor-list .floor-item .floor-specifi span {
	display: block;
}

.floor-list .floor-item .floor-specifi span.specifi-name {
	font-size: 0.3rem;
	width: 4rem;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: 600;
}

.floor-list .floor-item .floor-specifi span.specifi-num {
	font-size: 0.24rem;
}

.floor-list .floor-item .floor-specifi span.specifi-company {
	font-size: 0.28rem;
	width: 4rem;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.floor-list .floor-item .floor-specifi span.specifi-specs {
	font-size: 0.28rem;
	margin-right: 0.2rem;
}

.floor-list .floor-item .floor-specifi .specifi-price {
	font-size: 0.32rem;
}

.floor-list .floor-item .floor-specifi .specifi-total {
	font-size: 0.24rem;

}

.floor-list .floor-item .floor-specifi span.specifi-old-price {
	font-size: 0.22rem;
	text-decoration: line-through;
}

.floor-list .floor-item .floor-specifi .shop-cart {
	position: absolute;
	right: 0rem;
	bottom: 0.3rem;
	width: 0.36rem;
}

/* radio框 */
.item-img {
	width: 0.36rem;
	height: 0.36rem;
	margin: 0 0.2rem 0 0;
}

.floor-list .floor-item .item-right {
	margin-top: 0.2rem;
	flex: 1;
}

/* 商品加减 */
.item-right .floor-specifi .cart-operat {
	position: relative;
}

.item-right .floor-specifi .cart-operat .add-subtract {
	flex: 1;
	justify-content: flex-end;
	position: absolute;
	bottom: 0;
	right: 0;
}

.item-right .floor-specifi .cart-operat .add-subtract .add {
	font-size: 0.36rem;
	background: var(--theme-color);
	color: #fff;
	border-radius: 50%;
	display: inline-block;
	width: 0.36rem;
	height: 0.36rem;
	text-align: center;
	line-height: 0.36rem;
}

.item-right .floor-specifi .cart-operat .add-subtract .num {
	font-size: 0.3rem;
	margin: 0 0.2rem;
	width: 1rem;
	text-align: center;
	border: 0.02rem solid #999;
	border-radius: 0.12rem;
	line-height: inherit;
}

.item-right .floor-specifi .cart-operat .add-subtract .num.grey {
	background: #dbd9d9;
}

.item-right .floor-specifi .cart-operat .add-subtract .cut {
	font-size: 0.36rem;
	background: var(--theme-color);
	color: #fff;
	border-radius: 50%;
	display: inline-block;
	width: 0.36rem;
	height: 0.36rem;
	text-align: center;
	line-height: 0.36rem;
}

/* 倒计时 */
.item-right .floor-specifi .cart-time {
	width: 80%;
	font-size: 0.26rem;
	color: #fff;
	background: #199ed8;
	justify-content: center;
	border-radius: 0.28rem;
}

.item-right .floor-specifi .cart-time img {
	width: 0.22rem;
	height: 0.22rem;
	margin-right: 0.2rem;
}

/* 标签 */
.floor-list .floor-item .floor-specifi .specifi-label {
	background: #ffb515;
	color: #fff;
	display: inline-block;
	padding: 0 0.2rem;
	border-radius: 0.18rem;
}

/* 满赠优惠 */
.preferential {
	width: 100%;
	margin-top: 0.12rem;
	padding-left: 0.5rem;
	box-sizing: border-box;
}

.preferential .per-select {
	flex: 1;

}

.preferential .per-select .select-item img {
	width: 0.32rem;
	height: 0.32rem;
	margin-right: 0.1rem;
}

.preferential .per-select .select-item {
	font-size: 0.24rem;
}

.preferential .per-tip {
	font-size: 0.24rem;
	color: #ffb515;
}

/* 底部结算、全选 */
.screen-bot {
	position: fixed;
	left: 0;
	bottom: 1.2rem;
	z-index: 2;
	background: #fff;
	width: 100%;
	height: 1rem;
	border-top: 0.02rem solid #eee;
	padding: 0 0.2rem;
	box-sizing: border-box;
}

.screen-bot .screen-left {
	flex: 1;
	padding: 0 0.2rem 0 0;
	box-sizing: border-box;
}

.screen-bot .screen-left .screen-b img {
	width: 0.36rem;
	height: 0.36rem;
	margin-right: 0.12rem;
}

.screen-bot .screen-left .screen-b span {
	font-size: 0.3rem;
}

.screen-bot .screen-left .total-price .total-tit {
	font-size: 0.3rem;
}

.screen-bot .screen-left .total-price .total-pri {
	font-size: 0.3rem;
	color: #f71616;
}

.screen-bot .screen-right {
	width: 2.2rem;
	height: 0.7rem;
	font-size: 0.3rem;
	color: #fff;
	background: var(--theme-color);
	border-radius: 0.2rem;
}

.screen-bot .screen-right.delete-red {
	background: var(--theme-color);
	color: #fff;
}

.coupon-box {
	position: fixed;
	width: 100%;
	height: 40px;
	line-height: 35px;
	left: 0;
	bottom: 2.1rem;
	background: #F6B8B8;
	color: red;
	font-size: 15px;
}

/* 去逛逛 */
.no-data {
	padding-bottom: 0;
}

.cart-but {
	width: 2rem;
	height: 0.6rem;
	text-align: center;
	line-height: 0.6rem;
	font-size: 0.32rem;
	color: var(--theme-color);
	border: 0.02rem solid var(--theme-color);
	border-radius: 0.28rem;
	margin: 0.3rem auto 0.1rem;
}



/* 编辑商品数量弹窗 */
.popup-box {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.4);
	z-index: 10;
}

.popup-cont {
	position: fixed;
	width: 70%;
	padding: 0.2rem 0 0;
	box-sizing: border-box;
	background: #fff;
	border-radius: 0.12rem;
	left: 15%;
	top: 40%;
	z-index: 11;
}

.popup-cont .good-tit {
	width: 100%;
	text-align: center;
	font-size: 0.3rem;
	margin-bottom: 0.2rem;
}

.popup-cont .good-num-box {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 0.1rem;
	padding: 0 0.2rem;
	box-sizing: border-box;
}

.popup-cont .good-num-box .reduce-g {
	font-size: 0.39rem;
}

.popup-cont .good-num-box input {
	width: 2rem;
	height: 0.6rem;
	padding: 0.1rem;
	margin: 0 0.12rem;
	text-align: center;
	border: 0.02rem solid #c9c4c4;
	border-radius: 0.12rem;
	font-size: 0.3rem;
}

.popup-cont .good-num-box .add-g {
	font-size: 0.39rem;
}

.popup-cont .good-btn {
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
}

.popup-cont .good-btn .btn-g {
	width: 50%;
	font-size: 0.3rem;
	text-align: center;
	color: #999;
}

.popup-cont .good-btn .btn-g.blue {
	color: #58A0F2;
}



/* 底部提示 */
.bot-tip {
	width: 100%;
	position: fixed;
	left: 0;
	bottom: 2.1rem;
	background: #fff;
	font-size: 0.28rem;
	color: #f59648;
	z-index: 12;
	border-top: 0.02rem solid #ddd9d9;
	padding: 0 0.2rem;
	box-sizing: border-box;
}


/* 套餐包价格 */
.cart-price-num {
	margin-top: 0.12rem;
}

.cart-price {
	display: flex;
	justify-content: flex-end;
}

.cart-price .specifi-price {
	font-size: 0.32rem;
	color: var(--theme-color);
}

.cart-price .specifi-total {
	font-size: 0.24rem;
	margin-left: 0.2rem;
}


/* 套餐包商品加减 */
.floor-item .add-pack {
	flex: 1;
	justify-content: flex-end;
}

.floor-item .add-pack .add {
	font-size: 0.36rem;
	background: var(--theme-color);
	color: #fff;
	border-radius: 50%;
	display: inline-block;
	width: 0.36rem;
	height: 0.36rem;
	text-align: center;
	line-height: 0.36rem;
}

.floor-item .add-pack .num {
	font-size: 0.3rem;
	margin: 0 0.2rem;
	width: 1rem;
	text-align: center;
	border: 0.02rem solid #999;
	border-radius: 0.12rem;
	line-height: inherit;
}

.floor-item .add-pack .num.grey {
	background: #dbd9d9;
}

.floor-item .add-pack .cut {
	font-size: 0.36rem;
	background: var(--theme-color);
	;
	color: #fff;
	border-radius: 50%;
	display: inline-block;
	width: 0.36rem;
	height: 0.36rem;
	text-align: center;
	line-height: 0.36rem;
}


.company img {
	width: 0.5rem;

}

.company-item {
	margin-left: 0.1rem;
	font-size: 14px;
}

.floor-list {
	border-bottom: .3rem #F7F7F7 solid;
}

.subtotal {
	margin: 0.2rem 0 0.2rem .12rem;
	font-size: .25rem;
}

b {
	color: var(--theme-color);
}

.fontRed {
	color: #f71616;
}
</style>
