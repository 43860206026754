<template>
    <div class="container">
        <div class="tab-height">
            <div class="tab-box flex-wrap" :style="{top:topHeight+'rem'}">
                <div class="tab-item" :class="tabType == 'upload'? 'active' : '' " @click="tabChange('upload')">上传计划</div>
                <div class="tab-item" :class="tabType == 'see'? 'active' : '' " @click="tabChange('see')">计划查看</div>
            </div>
        </div>
        <!-- 上传计划 -->
        <div class="plan-upload" v-if="tabType == 'upload'">
            <div class="img-box flex-wrap1">
                <!--  -->
                <div class="imgs-up" v-for="(imgItem,index) in upImgList" :key="index">
                    <img class="img-item" :src="imgItem.SufixUrl" alt="">
                    <img class="img-delete" src="@/assets/images/icon_close.png" @click.stop="deleteImg(index)" alt="删除">
                </div>
				
				<!-- <van-uploader class="upload-img" v-on:oversize="oversize" :max-size="5*1024*1024" :accept="'image/*'" :after-read="selectPhoto">
					<img class="each-img" src="@/assets/images/mine/icon_camera.png"/>
				</van-uploader> -->
				
				<input type="file" accept="image/*" style="display:none;" @change="changeImg()" name="upload_file" ref="input">
                <div class="upload-img flex-center" @click="upload">
                    <img src="@/assets/images/mine/icon_camera.png" alt="">
                </div>
            </div>

            <!-- 上传 -->
            <div class="upload-but" @click="uploadImgAll">上传</div>
        </div>
        <!-- 查看计划 -->
        <div class="see-plan" v-if="tabType == 'see' ">
            <div class="photo-item" v-for="(item,index) in PlanList" :key="index">
                <div class="item-time flex-sp" @click="toSeePlan(item.Id)">
                    <span>上传时间：{{item.UploadDate}}</span>
                    <img src="@/assets/images/mine/arrow-r.png">
                </div>
                <div class="photo-box flex-wrap">                    
                    <img v-for="(suffixitem,suffixindex) in item.ListSuffix" :key="suffixindex" :src="suffixitem.SufixUrl">
                    <!-- <img src="@/assets/images/index/xd3.png"> -->
                </div>
            </div>

            <div class="loading-box flex-center" v-if="noMore && PlanList.length>0">
                <span class="load-txt">已经到底了！</span>
            </div>
            <!-- 加载样式 -->
            <div class="loading-box flex-center" v-if="showLoading && PlanList.length>0">
                <img class="load-img" src="@/assets/images/index/loading.gif" alt="加载图片">
                <span class="load-txt">正在加载</span>
            </div>
            <!-- 暂无记录 -->
            <div class="no-data" v-if="PlanList.length == 0">
                <img src="@/assets/images/nore.png" alt="暂无数据">
                <span>暂无计划</span>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            tabType: 'upload',
            imgList: [],     // 图片数组  base64格式
            upImgList: [],      // 后台返回的图片数组 路由路径
            page: 1,
            noMore: false,
            showLoading: true,
            PlanList: [],   // 查看计划列表
            topHeight: 0.9,     // 顶部距离
        }
    },
    mounted(){
        var that = this;
        switch (localStorage.clientType) {
            case "app":
                that.appType = "app";
                that.topHeight = 1.7;
                break;
			case "ios":
			    that.appType = "ios";
			    that.topHeight = 1.7;
			    break;
            case "applets":
                that.appType = "applets";
                that.topHeight = 0.9;
                break;
            case "weixin":
                that.appType = "weixin";
                that.topHeight = 0.9;
                break;
            default:
                that.topHeight = 0.9;
				break;
        }
        window.addEventListener('scroll', this.onReachBottom);
    },
    destroyed(){
        window.removeEventListener('scroll', this.onReachBottom);
    },
    methods:{
        // tab导航切换
        tabChange(type){
            var that = this;
            that.tabType = type;
            
            if(type == 'see'){
                that.getPlanList();
            }
        },
        //  查看计划列表
        getPlanList(){
            var that = this;
            var page = that.page;
            var data = {
                page: page,
                rows: 10,
                Extend:true //获取图片
            }
            that.public.request('post','/api/PaperPlan/QueryPaperPlanPage',data,res => {
                // console.log(res);
                if(res.data.errorCode == '00'){
                    var allArr = [];
                    var initArr = that.PlanList;
                    var curArr = res.data.rows ? res.data.rows : [];
                    var lastPageLength = curArr.length;
                    if(page > 1){
                        allArr = initArr.concat(curArr);
                    }else{
                        curArr = res.data.rows;
                    }
                    that.PlanList = curArr;
                    if(lastPageLength < 10){
                        that.noMore = true;
                        that.showLoading = false;
                    }

                }else if(res.data.errorCode == '99'){
                    if(page <= 1){
                        that.PlanList = [];
                        that.noMore = false;
                        that.showLoading = false;
                    }else{
                        that.noMore = true;
                        that.showLoading = false;
                    }
                }
            })
        },

        // 查看计划图片
        toSeePlan(id){
            this.$router.push({
                path: '/seePlanDetail',
                query: {
                    id: id,
                }
            })
        },

        // 上传计划  全部图片
        uploadImgAll(){
            var that = this;
            var upImgList = that.upImgList;
            console.log()
            if(upImgList.length > 0){
                var data = {
                    ListSuffix: upImgList,
                }
                that.public.request('post','/api/PaperPlan/UploadPaperPlanStep2',data,res => {
                    // console.log(res);
                     that.$toast('上传成功');
                     that.upImgList=[];
                })
            }else{
                that.$toast('请先上传计划')
            }
            
        },

        // 删除图片
        deleteImg(index){
            var that = this;
            that.upImgList.splice(index,1);
            that.imgList.splice(index,1);
        },

        // 上传图片
        upload(){
            // 点击触发按钮
            this.$refs.input.dispatchEvent(new MouseEvent('click'));
			// this.showActionSheet();
        },
        // 点击上传图片，触发change时间
        changeImg(){
            var that = this;
            var files = that.$refs.input.files;
			if (files.length === 0) {
			    return;
			}
            that.$toast.loading({
                message: '上传中...',
            })
			let file = files[0];
			if(/\.(jpe?g|png|gif)$/i.test(file.name)){
			    // 实例化一个reader文件
			    var reader = new FileReader();
			    // 添加二进制文件
			    reader.readAsDataURL(file);
			    reader.onload = function(e){
			        // 避免重复上传同个图片
			        if(that.imgList.indexOf(e.target.result) === -1 ){  //如果没有找到，
			            that.imgList.push(e.target.result);
			            var base64Img = e.target.result;
			            var scale = 0.8;
			            that.public.compressImg(base64Img,scale,files,that.uploadImg);// 压缩图片
						// console.log(base64Img);
						// that.uploadPhoto(base64Img)
			        }else{
			            that.$toast('已有该图片')
			        }  
			    }
			}
            // console.log(that.imgList)
        },
		
        // 上传图片
        uploadImg (blob, base64,files) {
            var that = this;
            // 接收到回调函数  获取到压缩后  图片文件，然后上传就可以了
            let formData = new FormData();
            // 需要给文件名添加后缀扩展名，否则传到后端的是一个没有扩展名的文件
            formData.append('Files', blob,files[0].name);
            that.public.request('post','/api/PaperPlan/UploadPaperPlanStep1',formData,res => {
                // console.log(res);
                if(res.data.errorCode == '00'){
                    that.$toast.clear();
                    var imgUrl = res.data.rows
                    that.upImgList.push(imgUrl);
                    // console.log(that.upImgList);
                }
            },'upImg')
        },
		
		/**
		 * 选择图片
		 */
		selectPhoto(file){
			// let max = 9 - this.upImgList.length;
			// if (max <= 0) {
			// 	return Toast('相册最多只能上传9张图片，您已超出');
			// }
			console.log(file.content)
			this.uploadPhoto(file.content);
		},
		uploadPhoto(imgBase64){
			let that = this;
			if(!imgBase64){
				return;
			}
			let bytes = window.atob(imgBase64.split(',')[1])
			let array = [];
			for(let i = 0; i < bytes.length; i++){
			    array.push(bytes.charCodeAt(i));
			}
			let blob = new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
			let formData = new FormData();
			formData.append('file',blob, Date.now() + '.jpg')
			that.public.request('post','/api/PaperPlan/UploadPaperPlanStep1',formData,res => {
			    if(res.data.errorCode == '00'){
			        // that.$toast.clear();
			        let imgUrl = res.data.rows
			        that.upImgList.push(imgUrl);
			    }
			},'upImg')
		},
		
        //到达底部
        onReachBottom(){
            var that = this;
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
            //变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
            //滚动条到底部的条件
            // console.log(scrollTop);
            if(scrollTop>0){
                if(scrollTop+windowHeight+2>=scrollHeight){
                    //写后台加载数据的函数
                    console.log("----------------");
                    // console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
                    
                    var page = that.page;
                    var noMore = that.noMore;
                    page++;
                    that.page = page;
                    if(noMore){
                        console.log('已经到底了');
                    }else{
                        that.getPlanList();
                    }
                }
            }

        },
		
		// 
		oversize(){
			Toast("请选择5M以内的图片");
		},

    }
}
</script>


<style scoped>
    /* tab导航 */
    .tab-height{ width:100%;height:0.9rem; }
    .tab-box{ position:fixed;top:0.9rem;left:0;z-index:6;width:100%;height:0.9rem;border-bottom:0.02rem solid #eee; }
    .tab-box .tab-item{ width:50%;height:100%;text-align:center;line-height:0.9rem;font-size:0.3rem; }
    .tab-box .tab-item.active{ color:var(--theme-color);border-bottom:0.02rem solid var(--theme-color); }


    /* 上传计划 */
    .plan-upload{ width:100%;padding:0.2rem;box-sizing:border-box; }
    .plan-upload .img-box{ width:100%;background:#fff;box-shadow:0 0 0.1rem #eee;padding:0.2rem;box-sizing:border-box;border-radius:0.18rem;flex-wrap:wrap; }
    .plan-upload .img-box .imgs-up{ width:1.8rem;height:1.8rem;margin-right:0.6rem;margin-bottom:0.28rem;position:relative; }
    .plan-upload .img-box .imgs-up:nth-child(4n){ margin-right:0; }
    .plan-upload .img-box .imgs-up .img-item{ width:100%;height:100%; }
    .plan-upload .img-box .imgs-up .img-delete{ width:0.36rem;position:absolute;top:-0.12rem;right:-0.12rem; }
    .plan-upload .img-box .upload-img{ width:1.8rem;height:1.8rem;border:0.02rem dashed #999;box-sizing:border-box; }
    .plan-upload .img-box .upload-img img{ width:0.6rem;vertical-align: middle;}
	/deep/.van-uploader__wrapper{
		height: 100%;
		/* display: flex;
		align-items: center;
		justify-content: center; */
	}
	/deep/.van-uploader__input-wrapper{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
    .plan-upload .upload-but{ width:80%;height:0.8rem;text-align:center;line-height:0.8rem;color:#fff;font-size:0.3rem;background:var(--theme-color);border-radius:0.2rem;margin:1.5rem auto; }

    /* 查看计划 */
    .see-plan .photo-item{ width:100%; }
    .see-plan .photo-item .item-time{ width:100%;height:0.8rem;padding:0 0.2rem;box-sizing:border-box; }
    .see-plan .photo-item .item-time span{ font-size:0.32rem; }
    .see-plan .photo-item .item-time img{ width:0.16rem; }
    .see-plan .photo-item .photo-box{ width:100%;padding:0.2rem;box-sizing:border-box;background:#f7f7f7; }
    .see-plan .photo-item .photo-box img{ display:inline-block;width:2rem;max-height:2rem;margin-right:0.5rem; }
    .see-plan .photo-item .photo-box img:last-child{ margin-right:0; }

    .loading-box{ background:#fff; }
</style>