<template>
  <div>
    <div class="articleBOX" v-if="!noData">
        <div class="articleTop">{{ArticleDetail.Title}}</div>
        <div>
            <span class="color-blue">药盟主</span>
            <span class="color-hui">{{ArticleDetail.CreateDate||''}}</span>
            <span class="color-hui">发表于{{ArticleDetail.CreateUser||'药盟主'}}</span>
        </div>
        <div class="articleContent" v-html='ArticleDetail.Content'></div>
    </div>
   
    <div class="no-data" v-if="noData">
        <img src="@/assets/images/nore.png" alt="暂无内容">
        <span>暂无内容</span>
    </div>
  </div>
</template>

<script>
export default {
       data () {
        return {
            Id:'',
            ArticleDetail:'',
            noData:true,
        }
       },
       created(){
            this.Id= this.$route.query.Id;
            if(this.Id){
                 this.getDetail();
            }
       },
       methods: {
        getDetail(){
             let that=this;
             that.$toast.loading({
                    message: "加载中...",
                    duration: 0,
                });
              that.public.request('post','/api/Article/Detail/'+this.Id,{},res=>{
                that.$toast.clear();
                 if (res.data.errorCode == "00"){ 
                     this.noData=false;
                    this.ArticleDetail=res.data.rows;
                    if(!this.ArticleDetail.Enable || !this.ArticleDetail.Content){
                        this.noData=true;
                    }
                 }else{
                     that.$toast(res.data.errorMsg);
                 }
                })
              }
       },
}
</script>

<style scoped>
.articleBOX{ margin: 0 0.2rem;  font-size: 0.28rem;}
.articleTop{  font-weight: 700;  text-indent:.8em; font-size: 0.4rem;}
.color-hui{ color: rgb(157, 157, 157);  font-size: 0.28rem;   margin-left: .08rem;}
.color-blue{color:#00a2ea;}
.articleContent{  margin-top: 0.2rem;}
</style>