<template>
    <div class="container" v-if="historyShow">
        <!-- 搜索 -->
        <div class="search-box flex-wrap">
            <img class="back-img" src="@/assets/images/tab/icon_left.png" alt="" @click="backPage">
            <div class="search-input flex-wrap">
                <input type="text" ref="getSelect" placeholder="请输入搜索内容" v-model="keyword" @keyup.enter="onSearch()" @keyup="seachInput">
                <img src="@/assets/images/index/main-sousuo.png" alt="搜索按钮" @click="onSearch()">
            </div>
            <div class="search-tit" @click="onSearch()">搜索</div>
        </div>
        <!-- 搜索联想建议 -->
        <div class="suggest-box" v-if="idSeachInput">
            <div class="suggest-item" v-for="(i,id) in lenovoList" :key="id" @click="seclectLenvo(i.value)">
                <img src="@/assets/images/index/main-sousuo.png" alt="搜索按钮" >
                <div class="suggest-content">  <span class="resultTip" slot="title" v-html="highlight(i.value)"></span></div>
            </div>
        </div>

        <!-- 历史搜索 -->
        <div class="sear-box" v-if="!idSeachInput">
            <div class="sear-item" v-if="historyList.length>0">
                <div class="item-tit flex-sp">
                    <span>历史搜索</span>
                    <img v-if="!deleteShow" src="@/assets/images/search/icon_delete.png" @click="onDelete()" alt="">
                    <span v-if="deleteShow" class="finish-font" @click="onMore('up')">完成</span>
                </div>
                <div class="label-list catch-more flex-wrap" >
                    <div class="label-item flex-wrap" v-for="(item,index) in historyList" :key="index">
                        <span @click="onSearch(item)">{{item}}</span>
                        <img class="img-close" v-if="deleteShow" src="@/assets/images/search/icon_deleteClose.png" alt="">
                    </div>
                    <img class="img-down" v-if="hasMoreBtn" @click="onMore('down')" src="@/assets/images/index/icon_down.png" alt="">
                    <img class="img-down" v-if="hasUpShow" @click="onMore('up')" src="@/assets/images/index/icon_up.png" alt="">
                    
                </div>
            </div>
            <!-- 常购清单 -->
            <div class="oftenShop flex-sb">
               <div class="flex-ac"> 
                   <img class="shopImg" src="../assets/images/search/shop.png" alt="">
                    <b>常购清单</b>
                </div>
                <div class="flex-ac often" @click="oftenShopBtn">
                    <span>点击查看常购物品</span>
                    <img  src="../assets/images/search/More.png" alt="">
                </div>

            </div>
            <!-- 搜索发现 -->
            <!-- <div class="searchFind ">
                <div class="flex-sb">
                    <div class="flex-ac">
                        <img class="findImg" src="../assets/images/search/searchItem.png" alt="">
                        <b>搜索发现</b>
                    </div>
                    <img class="seachImg" src="../assets/images/search/lookIten.png" alt="">
                </div>
                <div class="searchFindList flex-ac">
                    <div class="searchFindItem flex-ac">
                        <div class="line"></div>
                        <div>哈哈哈哈哈</div>
                    </div>
                     <div class="searchFindItem flex-ac">
                        <div class="line"></div>
                        <div>哈哈哈哈哈</div>
                    </div> <div class="searchFindItem flex-ac">
                        <div class="line"></div>
                        <div>哈哈哈哈哈</div>
                    </div>
                     <div class="searchFindItem flex-ac">
                        <div class="line"></div>
                        <div>哈哈哈哈哈</div>
                    </div>

                </div>
            </div> -->


            
           
        </div>

    </div>
</template>

<script>
export default {
    props: {
        historyShow: Boolean,
        backShow: Boolean,
        
    },
    data(){
        return{
            hasMoreBtn: false,   // 下拉隐藏
            hasUpShow: false,   // 上拉隐藏
            deleteShow: false,    // 删除关闭
            historyList:[],
            // historyArr: [],
            hotSearchList:[],   // 热门搜索
            recommendList:[],   // 推荐标签
            keyword: '',        // 输入框输入的内容
            idSeachInput:false,//搜索联想建议
            lenovoList:[],
        }
    },
    created(){
        var that = this;
        // that.historyArr = that.$store.state.searchHistory;
        // that.historyList = that.historyArr;
        //   var historyBool = sessionStorage.getItem("historyBool");
        //     if(!historyBool){   // 首次进入执行一次
        //     that.getHistory();
        //     }else{
        //         var historyData = localStorage.getItem("historyData");
        //         that.historyList = JSON.parse(historyData);
        //     }
        // console.log("执行creat");
        // // 计算历史搜索有几行
        // that.toggleHistoryData();
        // // 热门搜索、推荐标签
        // that.getHotRecom();
        
    },
    watch:{
        historyShow:function(){
            var that = this;
            
            // console.log(that.historyShow);
            if(that.historyShow){
                console.log("执行watch");
                // that.historyArr = that.$store.state.searchHistory;
                // that.historyList = that.historyArr;
                // 默认选中input焦点
                that.$nextTick(() => {
                    that.$refs.getSelect.focus();
                })
                var historyBool = sessionStorage.getItem("historyBool");
                if(!historyBool){   // 首次进入执行一次
                    that.getHistory();
                }else{
                    var historyData = localStorage.getItem("historyData");
                    that.historyList = JSON.parse(historyData);
                    // 计算历史搜索有几行
                    that.toggleHistoryData();
                }
                that.hasUpShow = false;
                
                
                // 热门搜索、推荐标签
                that.getHotRecom();
            }
        }
    },
    
    methods:{
        // 返回上一页
        backPage(){
            var that = this;
            console.log(that.backShow);
            if(that.backShow){
                that.$emit('onHistoryShow','');
            }else{
                that.$router.go(-1);
            }
             that.idSeachInput=false,
                that.keyword='';
                that.lenovoList=[];
        },
        // 搜索
        // onSearch(value){
        //     var that = this;
        //     that.idSeachInput=false;
        //     that.lenovoList=[];
        //     var deleteShow = that.deleteShow;
        //     if(deleteShow){     // 删除
        //         var historyArr = that.$store.state.searchHistory;
        //         historyArr.forEach((item,index) => {
        //             if(value === item){
        //                 historyArr.splice(index,1);
        //             }
        //         })
        //         that.historyList = historyArr;
        //         that.$store.commit('setHistory',historyArr)
        //     }else{      // 搜索
        //         if(value){
        //             var keyword = value;
        //         }else{
        //             var keyword = that.keyword;
        //         }
        //         that.$emit('onHistoryShow',keyword);
        //     }
        //     that.keyword = ''; 
        // },


          onSearch(value){
            var that = this;
            that.idSeachInput=false;
            that.lenovoList=[];
            var deleteShow = that.deleteShow;
            // console.log(that.deleteShow);
            if(deleteShow){     // 删除
                // var historyArr = that.$store.state.searchHistory;
                var historyArr = localStorage.getItem("historyData")
                historyArr = JSON.parse(historyArr);
                historyArr.forEach((item,index) => {
                    if(value === item){
                        historyArr.splice(index,1);
                    }
                })
                that.historyList = historyArr;
                localStorage.setItem("historyData",JSON.stringify(that.historyList));
                // that.$store.commit('setHistory',historyArr)
            }else{      // 搜索
                if(value){
                    var keyword = value;
                }else{
                    var keyword = that.keyword;
                }
                var historyArr = localStorage.getItem("historyData")
                that.historyList = JSON.parse(historyArr);
                if(that.historyList.indexOf(keyword) == -1){
                    that.historyList.unshift(keyword);
                }
                
                localStorage.setItem("historyData",JSON.stringify(that.historyList));
                that.saveHistory();
                
                that.$emit('onHistoryShow',keyword);

                // that.$router.push({
                //     path: '/search',
                //     query: {
                //         keyword: keyword,
                //     }
                // })
            }
            that.keyword = ''; 
        },

        // 历史搜索数据
        getHistory(){
            var that = this;
            var page = that.page;
            var data = {
                page: page,
                rows:10,
            }
            that.public.request('post','/api/GoodsSearchHis/QueryPage',data,(res) => {
                if(res.data.errorCode == '00'){
                    var arr = res.data.rows;
                    var arrList = [];
                    arr.forEach((item)=> {
                        arrList.unshift(item.KeyWord);
                    })
                    that.historyList = arrList;
                    localStorage.setItem("historyData",JSON.stringify(arrList));
                    // 判断执行一次
                    sessionStorage.setItem("historyBool",true);
                    // 计算历史搜索有几行
                    that.toggleHistoryData();
                }
            });

        },
        // 保存历史搜索
        saveHistory(){
            var that = this;
            var data = {
                Keyword: that.keyword,
            }
            that.public.request('post','api/GoodsSearchHis/SaveData',data,(res) => {
                if(res.data.errorCode == '00'){
                    // console.log(res.data);
                    
                }
            })
        },










        // 搜索联想建议
        seachInput(){
              var that=this;
           if(this.keyword.trim()){
              that.getLenovo(that.keyword);
             }
           if(this.keyword==''){
                that.idSeachInput=false;
                that.lenovoList=[];
           }
        },
        // 热门搜索、推荐标签
        getHotRecom(){
            var that = this;
            that.public.getOrgWebInfo(res => {
                var arr1 = [];
                var arr2 = [];
                var keyWord = res.keyWord;
                keyWord.forEach((item,index) => {
                    if(item.m_Item1 === 'keyword'){
                        arr1.push(item);
                    }else if(item.m_Item1 === 'tag'){
                        arr2.push(item);
                    }
                })
                that.hotSearchList = arr1;
                that.recommendList = arr2;
            })
        },
        // 常购清单
        oftenShopBtn(){
              this.$router.push({
                path: "/oftenBreed"
            });

        },
        // 计算历史搜索有几行
        toggleHistoryData(){
            var that = this;
            var idx = 0;
            var count = 0;
            var iIndex = 0;
            // console.log(that.historyList);
            if(that.historyList.length>0){
                that.$nextTick(() => {
                    if(document.querySelector(".catch-more")){
                        // 获取父容器所有子节点
                        var child = document.querySelector(".catch-more").childNodes;
                        // console.log("child",child);
                        
                        child.forEach((i,index) => {
                            
                            if(index === 0){
                                iIndex = i.offsetLeft;
                            }
                            if(i.offsetLeft == iIndex){
                                count++;
                                if(count == 3 ){
                                    idx = index-1;
                                    that.hasMoreBtn = true;
                                }
                            }
                        })
                        // 超过两行截断数据
                        if(idx > 0){
                            that.historyList = [...that.historyList].slice(0,idx);
                            // console.log(that.historyList);
                        }
                    }
                })
            }
            
        },
        // 更多历史搜索
        onMore(type){
            var that = this;
            if(type == 'down'){
                that.hasMoreBtn = false;
                that.hasUpShow = true;
                // that.historyList = that.historyArr;
                var historyArr = localStorage.getItem("historyData")
                that.historyList = JSON.parse(historyArr);
            }else{
                that.hasUpShow = false;
                that.deleteShow = false;
                that.toggleHistoryData();
            }
        },
        // 删除历史
        onDelete(){
           var that = this;
            that.deleteShow = true;
            that.hasMoreBtn = false;
            that.hasUpShow = false;
            // that.historyList = that.historyArr;
            var historyArr = localStorage.getItem("historyData")
            that.historyList = JSON.parse(historyArr);
        },
        // 获取联想建议
        getLenovo(key){
            var that=this;
            if(!key){
                key=that.keyword
            }
            that.lenovoList=[];
            that.public.request( 
            "get",
            "/api/Store/GetKeyword?key="+key,'',res=>{
             that.lenovoList=res.data;
             that.idSeachInput=true;
            });
        },
        // 联想建议  搜索
        seclectLenvo(val){
            var that=this;
            that.keyword=val;
            that.onSearch(val);
        },
        // 高亮效果
        highlight(text){
            let keyword = this.keyword;
            if (text.indexOf(keyword) !== -1){
                let replaceReg = new RegExp(this.keyword, 'g');
                let newText=`<font color='#027ada'>${keyword}</font>`
               return   text = text.replace(replaceReg, newText);
            }else{
                 return text
            }
              
        },
    }
}
</script>


<style scoped>
    /* 搜索 */
    .search-box{ width:100%;height:1rem;padding:0 0.2rem;box-sizing:border-box;border-bottom:0.02rem solid #eee; }
    .search-box .back-img{ width:0.42rem;margin-right:0.2rem; }
    .search-box .search-input{ flex:1;padding:0.15rem 0.2rem;border:0.02rem solid #eee;border-radius:0.28rem; }
    .search-box .search-input input{ font-size:0.28rem; }
    .search-box .search-input img{ width:0.29rem;height:0.29rem; }
    .search-box .search-tit{ font-size:0.3rem;margin-left:0.12rem; }

    /* 历史搜索 */
     .sear-box{ width:100%;padding:0.2rem;box-sizing:border-box; }
    .sear-box .sear-item .item-tit{ width:100%;height:0.5rem; }
    .sear-item .item-tit span{ font-size:0.3rem; }
    .sear-item .item-tit img{ width:0.3rem;height:0.3rem; }
    .sear-item .item-tit .finish-font{ font-size:0.24rem;color:#fa4949; }

    .sear-item .label-list{ width:100%;flex-wrap:wrap;margin-top:0.16rem;border-bottom:0.02rem solid #eee;min-height:1.2rem; }
    .sear-item .label-list .img-down{ width:0.32rem;margin: 0 0 0.2rem 0; }
    .sear-item:last-child .label-list{ border-bottom:0; }
    .sear-item .label-list .label-item{ padding:0.06rem 0.2rem;font-size:0.3rem;background:#f7f7f7;border-radius:0.2rem;margin:0 0.4rem 0.2rem 0; }
    .sear-item .label-list .label-item .img-close{ width:0.26rem;height:0.26rem;margin-left:0.08rem; }

    .suggest-item{display: flex;align-items:center;border-bottom: 0.02rem solid #eee;padding: 0.2rem 0.3rem;}
    .suggest-item img{width: 0.3rem;margin-right: 0.2rem;}
    .suggest-content{font-size: 0.32rem;}
    .active{font-weight: 700;color: red;}

    .oftenShop{justify-content: space-between;  padding: .35rem 0;font-size: 0.28rem; background: url("../assets/images/search/icon-bd1.jpg");border-radius: 0.2rem;background-size: cover;}
    .flex-ac{display: flex;align-items: center;}
    .shopImg{width: 16%; margin: 0 0.2rem }
     /* .often{margin-left: 1.25rem;} */
    .often img{ width: 10%;}
    .flex-sb{display: flex;align-items: center;justify-content: space-between;}

    .searchFind{font-size: 0.28rem;padding: 0.5rem 0.15rem;margin-top: 0.5rem;border-radius: 0.2rem;;background: url("../assets/images/search/icon-bg2.jpg"); background-size: cover;}
    .findImg{width: 18%;margin: 0 0.2rem ;}
    .seachImg{width: 5%;margin-right: 0.35rem;}
    .searchFindList{flex-wrap:wrap; justify-content: space-between;margin-left: 0.2rem;}
    .searchFindItem{margin: 0.2rem 0;width: 40%; background-color:#fff0da ; padding: 0.1rem 0.2rem;border-radius: 0.2rem;}
    .line{background-color: red; height: 0.28rem;width: 0.05rem;margin-right: 0.05rem;}
    


</style>