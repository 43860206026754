import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentTab:0,    //底部导航存储状态
    cartNum:0,  // 购物车数量
    headTitle:'',
    extend: [],     // 优惠券的extend
    couponItem: {},   // 选中的优惠券
    couponList: [],   // 优惠券
    redPackList:[],   // 红包列表
    historyShow: false,   // 历史搜索显示隐藏
    searchHistory: [],    // 搜索历史数据
  },
  mutations: {
    setCurrentTab(state,e){
      state.currentTab = e;
    },
    setCartNum(state,e){
      state.cartNum = e;
    },
    setHeadTitle(state,e){
      state.headTitle = e;
    },
    setCouponExtend(state,e){
      
      state.extend = e;
    },
    setCouponItem(state,e){
      state.couponItem = e;
    },
    setCouponList(state,e){
      
      state.couponList = e;
    },
    setRedPackList(state,e){
      state.redPackList = e;
    },
    setHistory(state,e){
      state.searchHistory = e;
    },
    sethistoryShow(state,e){
      state.historyShow = e;
    },
  },
  actions: {
  },
  modules: {
  }
})
