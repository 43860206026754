<template>
<div>
    <div class="notice">
        <img src="@/assets/images/icon-Notice.png" alt="" class="imgNotice">
        <p>暂无权限</p>
    </div>
    <div class="login" @click="goLogin">去登录</div>
</div>
</template>

<script>

export default {
    data () {
        return {
        }
    },
    methods: {
        goLogin(){
            var that=this;
            var params=that.public.urlObj(location.href);
             that.$router.push({
              path: "/login",
              query:{
                  orgId:params.OrgId,
              }
              
            });
            sessionStorage.setItem('junp',true);
        }
    }
    
    

}
</script>

<style scoped>

.imgNotice{
    margin: 0 auto;
    margin-top: 3rem;
    width: 2.3rem;

}
p{
    text-align: center;
    font-size: 0.5rem;
}
.login{
     font-size: 0.32rem;
    background-color: #ff8800;
    color: white;
    position: fixed;
    bottom: 2.34rem ;
    border-bottom-right-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    padding: 0.1rem 0.22rem 0.1rem 0.15rem;
}
</style>