<template>
    <div class="container">
        <!-- 商品列表 -->
        <div class="floor-box">
            <div class="floor-list flex-wrap" v-for="(item,index) in breedList" :key="index">
                <img class="item-img" v-if="!item.selected" src="@/assets/images/cart/icon_nochecked.png" alt="选择图标" @click="selectCur(index)">
                <img class="item-img" v-if="item.selected" src="@/assets/images/cart/icon_checked.png" alt="选择图标" @click="selectCur(index)">
                <div class="floor-item flex-wrap"  @click="toGoodsDetail">
                    <img class="floor-good" :src="item.SpPicExtend"  alt="商品图片">
                    <div class="floor-specifi">
                        <span class="specifi-name">{{item.spmch}}</span>
                        <span class="specifi-num">{{item.shpgg}}/{{item.dw}}</span>
                        <span class="specifi-company">{{item.shengccj}}</span>
                        <div class="flex-sp">
                            <div class="cart-operat flex-wrap">
                                <span class="specifi-price fontRed" v-if="item.CurActivityData == null">￥ {{item.zdshj}}</span>
                                <span class="specifi-price fontRed" v-if="item.CurActivityData != null">￥ {{item.CurActivityData.ActivityPrice}}</span>
                                <div class="add-subtract flex-wrap">
                                    <span class="add" @click="cutNum(index)">-</span>
                                    <input class="num" type="number" v-model="item.BuyCount" @click.stop="editInput(item.BuyCount,index)">
                                    <!-- <span class="num">2</span> -->
                                    <span class="cut" @click="addNum(index)">+</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="loading-box flex-center" v-if="noMore && breedList.length>4">
            <span class="load-txt">已经到底了！</span>
        </div>
        <!-- 加载样式 -->
        <div class="loading-box flex-center" v-if="showLoading && breedList.length>0">
            <img class="load-img" src="@/assets/images/index/loading.gif" alt="加载图片">
            <span class="load-txt">正在加载</span>
        </div>
        <!-- 暂无记录 -->
        <div class="no-data" v-if="breedList.length == 0">
            <img src="@/assets/images/nore.png" alt="暂无数据">
            <span>暂无浏览记录</span>
        </div>


        <!-- 编辑商品数量弹窗 -->
        <div class="popup-box" v-if="editNumShow"></div>
        <div class="popup-cont" v-if="editNumShow">
            <div class="good-tit">编辑商品数量</div>
            <div class="good-num-box">
                <span class="reduce-g" @click="reduceNum1()">-</span>
                <input type="number"  ref="editNum" v-model="cartEditNum" @keyup.enter="sureEdit()">
                <span class="add-g" @click="addNum1()">+</span>
            </div>
            <div class="good-btn">
                <div class="btn-g" id="cancel" @click="cancelEdit()">取消</div>
                <div class="btn-g blue" id="sure" @click="sureEdit()">确认</div>
            </div>
        </div>

        <!-- 底部全选 -->
        <div style="height:1rem;" v-if="breedList.length > 0"></div>
        <div class="screen-bot flex-wrap" v-if="breedList.length > 0">
            <div class="screen-left flex-sp">
                <div class="screen-b flex-wrap">
                    <img v-if="isSelectAll" src="@/assets/images/cart/icon_checked.png" alt="" @click="selectAll()">
                    <img v-if="!isSelectAll" src="@/assets/images/cart/icon_nochecked.png" alt="" @click="selectAll()">
                    <span>全选</span>
                </div>
                <!-- <div class="total-price flex-wrap">
                    <span class="total-tit">合计：</span>
                    <span class="total-pri">18:00</span>
                </div> -->
            </div>
            <div class="screen-right flex-center" @click="onCart">加入购物车</div>
            <!-- <div class="screen-right delete-gray flex-center" >删除</div> -->
        </div>

    </div>
</template>

<script>
export default {
    data(){
        return{
            noMore: false, 
            showLoading: true,
            isSelectAll:false,
            totalPage:1,
            page: 1,
            breedList: [],
            ids: [],        // 选中ID的集合

            editNumShow: false,	// 编辑商品数量弹窗
            cartNum: 1,		// 购物车数量
            cartEditNum: 1,	//  编辑购物车弹窗数量
            cartIndex: 0,	// 修改数量商品的下标

        }
    },
    created(){
        var that = this;
        //  请求常购物种数据列表
        that.getBreed();
    },
    mounted(){
        window.addEventListener('scroll', this.onReachBottom);
    },
    destroyed(){
        window.removeEventListener('scroll', this.onReachBottom);
    },
    methods:{
        // 跳转商品详情
        toGoodsDetail(){

        },
        // 加入购物车
        onCart(){
            var that = this;
            var ids = that.ids;
            if(ids.length <= 0){
                that.$toast("请选择商品");
                return;
            }
            var breedList = that.breedList;
            // console.log(ids);
            var carts = [];
            breedList.forEach((item,i) => {
                ids.forEach((item1,j) => {
                    if(item1 == item.spid){
                        carts.push({GoodsCount: item.BuyCount,Spid: item.spid,SpName: item.spmch})
                    }
                })
            })
            // console.log(carts);
            var data = {
                ListSellWellCarId: carts,
            };
            that.public.request('post','/api/SellWellCar/AddToCartList',data,res => {
                // console.log(res);
                if(res.data.errorCode == '00'){
                    that.$toast("加入购物车成功");
                }else{
                    that.$toast(res.data.errorMsg);
                }

            })
            

        },
        // 编辑弹窗显示
        editInput(countNum,index){
            var that = this;
            that.editNumShow = true;
            that.$nextTick(() => {
                that.$refs.editNum.focus();
                that.$refs.editNum.select();
            })
            that.cartEditNum = countNum;
            that.cartIndex = index;
        },
        // 取消编辑弹窗
        cancelEdit(){
            var that = this;
            that.editNumShow = false;
        },
        // 购物车数量减
        reduceNum1(){
            var that = this;
            var index = that.cartIndex;
            // 中包装
            var OnlyPackageSold = that.breedList[index].OnlyPackageSold;
            var OrderNumSetp = that.breedList[index].OrderNumSetp;
            if(OnlyPackageSold && OrderNumSetp>0){
                var step = OrderNumSetp;
            }else{
                var step = 1;
            }
            
            if(Number(that.cartEditNum) > Number(step)){
                // 最低下单量
                var MinBuyCount = that.breedList[index].MinBuyCount;
                if(MinBuyCount>0){
                    if(Number(that.cartEditNum)>Number(MinBuyCount)){
                        that.cartEditNum--
                    }
                }else{
                    that.cartEditNum = Number(that.cartEditNum) - Number(step);
                }
                
            }
        },
        // 购物车数量加
        addNum1(){
            var that = this;
            var index = that.cartIndex;
            // 中包装
            var OnlyPackageSold = that.breedList[index].OnlyPackageSold;
            var OrderNumSetp = that.breedList[index].OrderNumSetp;
            if(OnlyPackageSold && OrderNumSetp>0){
                var step = OrderNumSetp;
            }else{
                var step = 1;
            }
            // that.cartEditNum++;
            that.cartEditNum = Number(that.cartEditNum) + Number(step);
        },
        // 编辑弹窗确认
        sureEdit(){
            var that = this;
            that.editNumShow = false;
            var index = that.cartIndex;

            var orgId = localStorage.orgId;
            // 如果orgId 不是瑞龙的，则只能输入整数
            if(orgId != 'd3d3041db35d42f39443e9e1e9d74410'){
                var regNum =  /^\+?[1-9][0-9]*$/;
                if(!regNum.test(that.cartEditNum)){
                    that.$toast('请输入大于0的整数')
                    return;
                }
            }
            
            
            // 中包装步长
            var OnlyPackageSold = that.breedList[index].OnlyPackageSold;
            var OrderNumSetp = that.breedList[index].OrderNumSetp;
            var BuyCount = that.breedList[index].BuyCount;
            if(OnlyPackageSold && OrderNumSetp>0){
                // 取余
                if(that.cartEditNum%OrderNumSetp ==0){
                    BuyCount = that.cartEditNum;
                }else{
                    that.$toast("购买数量"+that.cartEditNum+"，必须为"+OrderNumSetp+"的倍数");
                    BuyCount = BuyCount;
                }
            }else{
                // 最低下单量
                var MinBuyCount = that.breedList[index].MinBuyCount;
                if(MinBuyCount>0){
                    if(that.cartEditNum >= MinBuyCount){
                        BuyCount = that.cartEditNum;
                    }else{
                        that.$toast("小于最低下单量："+ MinBuyCount);
                        BuyCount = BuyCount;
                    }
                }else{
                    BuyCount = that.cartEditNum;
                }
            }

            that.breedList[index].BuyCount = BuyCount;
            that.cartEditNum = 1;
            
            
        },


        // 购物车数量减
        cutNum(index){
            var that = this;
            // 中包装
            var OnlyPackageSold = that.breedList[index].OnlyPackageSold;
            var OrderNumSetp = that.breedList[index].OrderNumSetp;
            var BuyCount = that.breedList[index].BuyCount;
            if(that.breedList[index].BuyCount>1){
                if(OnlyPackageSold && OrderNumSetp>0){ // 中包装
                    if(BuyCount > OrderNumSetp){
                        BuyCount = Number(BuyCount) - Number(OrderNumSetp);
                    }
                }else{
                    BuyCount--;
                }
                that.breedList[index].BuyCount = BuyCount;
            }
        },
        // 购物车数量加
        addNum(index){
            var that = this;
            // 中包装
            var OnlyPackageSold = that.breedList[index].OnlyPackageSold;
            var OrderNumSetp = that.breedList[index].OrderNumSetp;
            var BuyCount = that.breedList[index].BuyCount;
            if(OnlyPackageSold && OrderNumSetp>0){
                BuyCount = Number(BuyCount) + Number(OrderNumSetp);
            }else{
                BuyCount++;
            }
            that.breedList[index].BuyCount = BuyCount;
            // that.breedList[index].BuyCount++;
        },

        // 选中(单选)
        selectCur(index){
            var that = this;
            // 还未选中
            if(!that.breedList[index].selected){
                that.breedList[index].selected = true;
                console.log(that.breedList[index].spid);
                that.ids.push(that.breedList[index].spid)
            }else{      // 已经选中
                that.breedList[index].selected = false;
                for(var i=0; i< that.ids.length; i++){
                    if(that.ids[i] == that.breedList[index].spid){
                        that.ids.splice(i,1);
                    }
                }
            }
            that.isSelectAll = true;
            that.breedList.forEach((item,i)=>{
                if(!item.selected){
                    that.isSelectAll = false;
                }
            })
            
        },

        // 全选
        selectAll(){
            var that = this;
            that.isSelectAll=!that.isSelectAll;
            //全部不选中
            if(!that.isSelectAll)
            {
                that.ids = [];
                that.breedList.forEach((item, index) => {
                    item.selected=false;
                });
                return;
            }
            //全部选中
            var cartIds = [];
            for(var i=0; i< that.breedList.length; i++){
                that.breedList[i].selected = true;
                var id = that.breedList[i].spid;
                cartIds.concat(cartIds.push(id));
            }
            that.ids = [];
            that.ids = cartIds;
        },


        // 请求常购物种数据列表
        getBreed(){
            var that = this;
            var page = that.page;
            var data = {
                page: page,
                rows: 10,
            }
             that.$toast.loading({
                message: '加载中',
                duration: 0
            })
            that.public.request('post','/api/Store/QueryOftenBuyGoods',data,res => {
                that.showLoading = false;
                that.$toast.clear();
                if(res.data.errorCode == '00'){
                    var allArr = [];
                    var initArr = that.breedList;
                    var curArr = res.data.rows ? res.data.rows : [];
                    //var lastPageLength = curArr.length;
                    that.totalPage=res.data.total;
                    if(page > 1){
                        allArr = initArr.concat(curArr);
                    }else{
                        allArr = res.data.rows;
                    }
                    // 选中状态
                    for(var i=0; i< allArr.length; i++){
                        allArr[i].selected = false;
                        // 普通商品
                        if(allArr[i].CurActivityData == null){
                            if(allArr[i].OnlyPackageSold && allArr[i].OrderNumSetp > 0){    // 中包装开启
                                allArr[i].BuyCount = allArr[i].OrderNumSetp;
                            }else{
                                if(allArr[i].MinBuyCount>0){    // 最低下单量
                                    allArr[i].BuyCount = allArr[i].MinBuyCount;
                                }else{
                                    allArr[i].BuyCount = 1;
                                }
                            }
                        }else if(allArr[i].CurActivityData != null){ // 活动商品
                            allArr[i].BuyCount = 1;
                        }
                        
                    }
                    that.isSelectAll = false;
                    that.breedList = allArr;
                    if(that.totalPage <=that.page){
                        that.noMore = true;
                    }       
                }
                else if(res.data.errorCode == '99'){
                    if(page <= 1){
                        that.breedList = [];
                        that.noMore = false;
                    }else{
                        that.noMore = true;
                    }
                }
            })
        },

        //到达底部
        onReachBottom(){
            var that = this;
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
            //变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
            //滚动条到底部的条件
            // console.log(scrollTop);
            if(scrollTop>0){
                if(scrollTop+windowHeight+2>=scrollHeight){
                    //写后台加载数据的函数
                    console.log("----------------");
                    // console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
                    
                    var page = that.page;
                    var noMore = that.noMore;
                    page++;
                    that.page = page;
                    if(noMore){
                        console.log('已经到底了');
                    }else{
                        that.getBreed();
                    }
                }
            }

        },

    }
}
</script>


<style scoped>
    /* 商品列表页面 */
    .floor-box{ width:100%;padding:0.2rem;box-sizing:border-box; }
    .floor-list{ width:100%;padding:0.2rem;box-sizing:border-box;background:#fff;box-shadow:0 0 0.1rem #eee;border-radius:0.2rem;margin-bottom:0.28rem; }
    .floor-list .item-img{ width:0.36rem;height:0.36rem;margin-right:0.2rem; }
    .floor-list .floor-item{ flex:1;box-sizing:border-box; }
    .floor-list .floor-item .floor-good{ width:1.6rem;max-height:1.8rem;margin-right:0.2rem;object-fit: contain; }
    .floor-list .floor-item .floor-specifi{ position:relative; width:4.5rem}
    .floor-list .floor-item .floor-specifi span{ display:block; }
    .floor-list .floor-item .floor-specifi span.specifi-name{ font-size:0.3rem;width:4rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-weight:600; }
    .floor-list .floor-item .floor-specifi span.specifi-num{ font-size:0.24rem; }
    .floor-list .floor-item .floor-specifi span.specifi-company{ font-size:0.28rem;width:4rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis; }
    .floor-list .floor-item .floor-specifi .specifi-price-box span{ display:inline-block; }
    .floor-list .floor-item .floor-specifi span.specifi-price{ font-size:0.32rem;margin-right:0.18rem; }
    .floor-list .floor-item .floor-specifi span.specifi-old-price{ font-size:0.22rem;text-decoration:line-through; }
    .floor-list .floor-item .floor-specifi .shop-cart{ width:0.36rem; }

    /* 商品加减 */
    .floor-item .floor-specifi .cart-operat{ width:100%; }
    .floor-item .floor-specifi .cart-operat .add-subtract{ flex:1;justify-content:flex-end }
    .floor-item .floor-specifi .cart-operat .add-subtract .add{ font-size:0.36rem;background:var(--theme-color);color:#fff;border-radius:50%;display:inline-block;width:0.36rem;height:0.36rem;text-align:center;line-height:0.32rem; }
    .floor-item .floor-specifi .cart-operat .add-subtract .num{ font-size:0.3rem;margin:0 0.2rem;width: 1rem;text-align: center;border: 0.02rem solid #999;border-radius: 0.12rem;line-height: inherit; }
    .floor-item .floor-specifi .cart-operat .add-subtract .cut{ font-size:0.36rem;background:var(--theme-color);color:#fff;border-radius:50%;display:inline-block;width:0.36rem;height:0.36rem;text-align:center;line-height:0.32rem; }


    /* 底部结算、全选 */
    .screen-bot{ position:fixed;left:0;bottom:0;z-index:2;background:#fff;width:100%;height:1rem;border-top:0.02rem solid #eee;padding:0 0.2rem;box-sizing:border-box; }
    .screen-bot .screen-left{ flex:1;padding:0 0.2rem 0 0;box-sizing:border-box; }
    .screen-bot .screen-left .screen-b img{ width:0.36rem;height:0.36rem;margin-right:0.12rem; }
    .screen-bot .screen-left .screen-b span{ font-size:0.3rem; }
    .screen-bot .screen-left .total-price .total-tit{ font-size:0.3rem; }
    .screen-bot .screen-left .total-price .total-pri{ font-size:0.3rem;color:var(--theme-color); }
    .screen-bot .screen-right{ width:2.2rem;height:0.7rem;font-size:0.3rem;color:#fff;background:var(--theme-color);border-radius:0.2rem; }
    .screen-bot .screen-right.delete-gray{ background:rgb(211, 206, 206);color:#fff; }



    /* 编辑商品数量弹窗 */
    .popup-box{
        position:fixed;
        left:0;
        top:0;
        right:0;
        bottom:0;
        background:rgba(0,0,0,0.4);
        z-index:11000;
    }
    .popup-cont{
        position:fixed;
        width:70%;
        padding:0.2rem 0 0;
        box-sizing:border-box;
        background:#fff;
        border-radius:0.12rem;
        left:15%;
        top:25%;
        z-index:11001;
    }
    .popup-cont .good-tit{
        width:100%;
        text-align:center;
        font-size:0.3rem;
        margin-bottom:0.2rem;
    }
    .popup-cont .good-num-box{
        width:100%;
        display:flex;
        align-items:center;
        justify-content:center;
        margin-bottom:0.1rem;
        padding: 0 0.2rem;
        box-sizing:border-box;
    }
    .popup-cont .good-num-box .reduce-g{
        font-size:0.39rem;
    }
    .popup-cont .good-num-box input{
        width:2rem;
        height:0.6rem;
        padding:0.1rem;
        margin:0 0.12rem;
        text-align:center;
        border: 0.02rem solid #c9c4c4;
        border-radius:0.12rem;
        font-size:0.3rem;
    }
    .popup-cont .good-num-box .add-g{
        font-size:0.39rem;
    }
    .popup-cont .good-btn{
        width:100%;
        height:50px;
        display:flex;
        align-items:center;
    }
    
    .popup-cont .good-btn .btn-g{
        width:50%;
        font-size:0.3rem;
        text-align:center;
        color:#999;
    }
    .popup-cont .good-btn .btn-g.blue{
        color:#58A0F2;
    }
    .fontRed{color: var(--theme-color);}
</style>