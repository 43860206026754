<template>
  <div class="invoiceDetails">
    <table>
      <tr>
        <td>
          发票类型: <span v-if="billDetails.BillType == 0">未知</span>
          <span v-if="billDetails.BillType == 10">增值税普通发票</span>
          <span v-if="billDetails.BillType == 20">增值税专业发票</span>
          <span v-if="billDetails.BillType == 30">电子发票</span>
        </td>
        <td class="audit">{{ billDetails.StatusName }}</td>
        <td>
          <button
            v-if="billDetails.StatusName == '已审核'"
            @click="previewPicture"
          >
            查看发票
          </button>
        </td>
      </tr>
      <tr>
        <td>
          公司名称:<span>{{ billDetails.SendOrgName }}</span>
        </td>
      </tr>
      <tr>
        <td>
          公司税号:<span>{{ billDetails.TaxNo }}</span>
        </td>
      </tr>
      <tr>
        <td>
          地址:<span>{{ billDetails.Address }}</span>
        </td>
      </tr>
      <tr>
        <td>
          电话:<span>{{ billDetails.Mobile }}</span>
        </td>
      </tr>
      <tr>
        <td>
          开户行:<span>{{ billDetails.Bank }}</span>
        </td>
      </tr>
      <tr>
        <td>
          账号:<span>{{ billDetails.BankAccount }}</span>
        </td>
      </tr>
      <tr>
        <td>
          备注:<span>{{ billDetails.Remark }}</span>
        </td>
      </tr>
      <tr>
        <td>
          发票代码:<span>{{ billDetails.BillCode }}</span>
        </td>
      </tr>
      <tr>
        <td>
          发票号码:<span>{{ billDetails.BillNumber }}</span>
        </td>
      </tr>
      <tr>
        <td>
          附件地址：

          <span
            v-if="billDetails.BillPicUrl"
            class="copy  tag"
            @click="getAddress(billDetails.BillPicUrl)"
          >
            复制</span
          >
          <span v-if="!billDetails.BillPicUrl"> 暂无</span>
        </td>
      </tr>
    </table>

    <!-- 商品详情 -->
    <div class="floor-box">
      <div class="floor-list" v-for="(item, ind) in billOrderList" :key="ind">
        <div class="floor-item">
          <div class="drugTop">
            <div>
              <span class="drug-title">
                {{ item.orderInfo.ReceiveOrgName }}</span
              >
              <span class="drug-number">{{ item.orderInfo.OrderNo }}</span>
            </div>
            <span class="cancel">{{ item.Status == 99 ? "已取消" : "" }}</span>
          </div>
          <div class="drugMiddle">
            <span class="drug-img">
              <div class="itemimg">
                <div
                  class="imgs"
                  v-for="(item2, ind2) in item.orderDetails"
                  :key="ind2"
                >
                  <img :src="item2.SpPic" alt="" />
                </div>
              </div>
              <img class="iconImg" src="@/assets/images/icon_omit.png" alt="" />
            </span>
            <span
              >共{{ item.orderDetails.length }}个品种{{ item.totals }}件
            </span>
          </div>
          <div class="drugBottom">
            <span>{{ item.orderInfo.CreateDate }}</span>
            <span class="font"
              >优惠：<i> {{ item.orderInfo.CouponAmount }}元</i> 付款金额：<i
                >{{ item.orderInfo.OrderCost }}元</i
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Clipboard from "clipboard";
import { ImagePreview } from "vant";
Vue.use(ImagePreview);
export default {
  data() {
    return {
      Id: this.$route.query.Id,
      billDetails: [],
      photo: [],
      billOrderList: [],
      page: 1,
      rows: 100000,
    };
  },
  created() {
    this.GetMobileBillItemById();
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f2f2f2;");
  },
  methods: {
    GetMobileBillItemById() {
      this.$toast.loading({
        message: "加载中...",
        duration: 0,
      });
      this.public.request(
        "post",
        "/api/Bill/GetMobileBillItemById",
        { Id: this.Id },
        (result) => {
          this.$toast.clear();
          // console.log('result',result);
          this.billDetails = result.data.rows.info;
          if (result.data.rows.length != 0) {
            this.photo.push(JSON.parse(result.data.rows.info.BillPics));
          }

          var billList = [];
          result.data.rows.order.forEach((item) => {
            billList.push(item);
          });
          var allArr = billList;

          allArr.forEach((item) => {
            item.totals = 0;
          });

          allArr.forEach((item2) => {
            item2.orderDetails.forEach((item3) => {
              item2.totals += item3.Total;
            });
          });
          this.billOrderList = allArr;
        }
      );
    },
    // 浏览图片
    previewPicture() {
      ImagePreview({
        images: this.photo[0],
        startPosition: 0,
        showIndex: true,
        closeable: true,
      });
    },

    // 复制地址

    // 复制附件地址
    getAddress(val) {
      // console.log(val);
      var that = this;
      let clipboard = new Clipboard(".tag", {
        text: function() {
          return val;
        },
      });
      console.log(1111111);
      clipboard.on("success", (e) => {
        that.$toast("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        that.$toast("复制失败");
        clipboard.destroy();
      });
    },
  },
};
</script>

<style scoped>
html {
  background-color: #f2f2f2 !important;
}
.invoiceDetails {
  background-color: #f2f2f2;
  padding: 0.13rem;
  font-size: 0.28rem;
  /* position: fixed;
  top: 0.9rem;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto; */
}

.audit {
  /* width: 1rem; */
  padding: 0 0.4rem;
}

button {
  width: 2rem;
  height: 0.5rem;
  background-color: #169bd5;
  color: white;
  border-radius: 0.16rem;
}

table {
  margin-bottom: 0.4rem;
}
table tr:nth-child(1) {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
table tr:nth-child(1) button {
  position: absolute;
  right: 0.2rem;
}
table span {
  margin-left: 0.1rem;
}

.floor-item {
  /* margin: 0.12rem; */
  padding: 0.12rem 0.32rem;
  background-color: rgb(255, 254, 254);
  border-radius: 0.24rem;
}
.drugTop,
.drugMiddle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drug-title,
.drug-number,
.cancel,
.font {
  font-weight: 700;
  font-size: 0.25rem;
}
.drug-title {
  margin-right: 0.14rem;
  color: #d81112;
}
.drugMiddle {
  margin: 0.4rem 0;
}

.drugBottom {
  display: flex;
  justify-content: space-between;
}

i {
  color: #c84c4c;
  font-style: normal;
}

/* 商品 */
.noData {
  margin: 2rem 3rem;
}
/* .floor-shop {
  display: flex;
  align-items: center;
  margin-bottom: 0.1rem;
} */
.floor-item {
  /* flex: 24;
  width: 100%; */
  margin: 0.12rem;
  padding: 0.12rem 0.32rem;
  background-color: rgb(255, 254, 254);
  border-radius: 0.24rem;
}
.icon_radio {
  width: 0.4rem;
  margin-left: 0.2rem;
}
.drug-img {
  display: flex;
}
.itemimg {
  display: flex;
  width: 1.8rem;
  overflow: hidden;
}
.drug-img .imgs img {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.1rem;
}
.drug-img .iconImg {
  margin-top: 0.5rem;
}
.drugTop,
.drugMiddle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.drugMiddle span img {
  display: inline;
  width: 0.3rem;
  height: 0.3rem;
  vertical-align: middle;
}

.drug-title,
.drug-number,
.cancel,
.font {
  font-weight: 700;
  font-size: 0.25rem;
}
.drug-title {
  margin-right: 0.14rem;

  color: #f3040a;
}
.drugMiddle {
  margin: 0.28rem 0;
}

.drugBottom {
  display: flex;
  justify-content: space-between;
}

i {
  color: #c84c4c;
  font-style: normal;
}

/* 复制 */
.copy {
  background-color: #169bd5;
  color: white;
  padding: 0.08rem 0.2rem;
  border-radius: 0.2rem;
}
</style>
