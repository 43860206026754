<template>
   <div class="timeShop-list">
              <div class="timeShop-item flex" v-for="(item,index) in list" :key="index" @click="goToDetails(item)">
                <div class="timeShop-img"><img :src="item.ImgUrl" alt=""></div>
                <div class="timeShop-detail">
                  <div style="display: flex; justify-content: space-between; align-items: center;">
					  <span class="font-w"> {{item.GoodsName}}</span> 
					  <span style="color: red;">投放数量：{{item.GoodsNum}}</span> 
					</div>
                  <div class="SecKill-schedule flex" v-if="active==1||active==3">
                     <div class="SecKillNum" v-if="item.percentage<10">已售出10%</div>
                      <div class="SecKillNum" v-else>已售出{{(item.percentage).toFixed(0)}}%</div>
                    <div class="noShopImg"  v-if="item.SecKillNum>=item.GoodsNum">
                        <span>售罄</span>
                    </div>
                     <img class="SecKillImg" src="../assets/images/spike.png" alt=""> 
                  </div>
                  <div>{{item.Style}}</div>
                  <div>{{item.Factory}}</div>
                  <div>效期:{{item.sxrq}}</div>
                  <div class="flex2">
                    <div class="flex" v-if="token">
                      <div class="SoldPrice">￥{{item.SoldPrice}}</div>
                      <s>￥{{item.MarketPrice}}</s>
                    </div>
                    <div class="flex" v-if="!token">
                      <div class="SoldPrice">￥资质通过后可见</div>
                      
                    </div>
                   <div v-if="item.SecKillNum<item.GoodsNum">
                      <div class="snapUp " v-if="active==1||active==3" @click.stop='onCartShow1(item)'>抢购</div>
                      <div class="snapUp yure" v-if="active==0" @click.stop="warmUpBtn">预热中</div>
                      <div class="snapUp yure" v-if='item.SecKillNum==item.GoodsNum' @click.stop="noShop">已售空</div>
                   </div>
                   <div v-if='item.SecKillNum>=item.GoodsNum'>
                       <div class="snapUp yure"  @click.stop="noShop">已售空</div>
                   </div>
                  </div>
                </div>
              </div>
        </div>
</template>

<script>
export default {
     props: {
        list:{
            type:Array
        },
        active:{
            type:Number
        }
    },
    data () {
        return {
               token : sessionStorage.getItem('member_token'),
            
        }
    },
    methods: {
        warmUpBtn(){
          var that=this;
          return that.$toast('活动还未开始');
        },
         goToDetails(goodInfo){
            this.$emit( 'toDetail',goodInfo,this.active);

        },
        onCartShow(goodInfo){
             this.$emit( 'toDetail',goodInfo);
        },
         onCartShow1(goodInfo){
             if(this.active==3){
                 return  this.$emit( 'onCartShow',goodInfo.GoodsId,'',goodInfo,3);
             }
            this.$emit( 'onCartShow',goodInfo.spid,'',goodInfo,this.active);
        },
        noShop(){
              var that=this;
              return that.$toast('活动商品已售空');
        }
    }

}
</script>

<style scoped>
.font-w{font-weight: 700;font-size: 0.33rem;}
.flex{display: flex;align-items: center;}
.timeShop-list{padding: 0.2rem;}
.timeShop-item{border: 1px solid rgb(231, 230, 230);padding: 0.01rem;box-shadow: 0px 0px 5px #b9b9b9;border-radius: 0.2rem;margin-bottom: 0.2rem;}
.timeShop-img {width: 35%;}
.timeShop-img img{margin:  0 auto;width: 88%;}
.flex2{display: flex;align-items: center;justify-content: space-between;}
.timeShop-detail{width: 60%;}
.SoldPrice{font-size: 0.42rem; color: var(--theme-color);margin-right: 0.2rem;}
.snapUp{ background: var(--theme-color);padding: 0.05rem 0.2rem;  border-radius: 0.1rem;  color: white;  font-size: 0.28rem;}
.yure{background-color: #8f9192; }
.SecKill-schedule{width: 40%;   color: white;position: relative;
}
.SecKillNum{border-radius: 0.1rem; border-top-right-radius:0;border-bottom-right-radius:0.4em;width: 100%;background: linear-gradient(to right,var(--theme-color), rgb(252, 150, 150)); padding-left: 0.15rem;}
.SecKillImg{width: 22%;position: absolute;right: -0.16rem;}
.noShopImg{position:absolute;top: 0.2rem;right: 2.24rem; width: 1.3rem; height: 1.3rem;color: white; background-color: rgba(128, 127, 127, 0.8);border-radius: 50%;text-align: center;line-height: 1.3rem;font-size: .5rem; }

</style>