<template>
    <div class="contain-cont">
        <div class="contain">
            <div class="account-sign flex-wrap">
                <span>手机号：</span>
                <input type="text" placeholder="请输入您的手机号" v-model="mobile" />
            </div>
            <div class="account-sign flex-wrap">
                <div class="verifi-code flex-wrap">
                <span>验证码：</span>
                <input type="text" placeholder="请输入验证码" v-model="vefifiCode" />
                </div>
                <button class="verifi-btn" @click="getCode" :disabled="disabled">{{sendTime}}</button>
            </div>
            <div class="account-sign flex-wrap">
                <span class="pass-width">请输入新密码：</span>
                <input type="password" placeholder="请输入您的新密码" v-model="newPass"/>
            </div>
            <div class="account-sign flex-wrap">
                <span class="pass-width">再次确定密码：</span>
                <input type="password" placeholder="请再次确定您的新密码" v-model="surePass" />
            </div>
            <div class="submit-btn" @click="submit">提交</div>
            <div class="back-btn" @click="backLogin">返回</div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            disabled: false,
            sendTime: '获取验证码',
            mobile: '',   // 手机号
            vefifiCode: '',   // 验证码
            newPass: '',    // 新密码
            surePass: '',   // 确认新密码
        }
    },
    created(){
      // 进入页面  获取客户ID
      var that = this;
      var params=that.public.urlObj(location.href);
      if(params.orgId){
        localStorage.setItem('orgId',params.orgId);
      }
      
      that.getUserId();
    },
    methods:{
        // 客户ID
        getUserId(){
          var data = {}
          this.public.request('get','/api/AppCommon/QueryGuid',data,res => {
            console.log(res);
            if(res.status == 200 ){
              localStorage.setItem('userId',res.data);
            }
          })
        },
		//判断是否小程序浏览器加载
		isMini: function(){
			let mini = false;
			if (/MicroMessenger/i.test(navigator.userAgent)) {
				//ios的ua中无miniProgram，很坑爹,但都有MicroMessenger（表示是微信浏览器）
				this.wx.miniProgram.getEnv((res) => {
					if (res.miniprogram) {
						mini = true;
					}
				});
			}
			return mini;
		},

        // 返回上一个页面
        backLogin:function(){
			if(this.isMini()){
				this.wx.miniProgram.reLaunch({
				    url: window.baseUrl.xcxLogin?'/pages/login/index':'/pages/login/oldLogin/index',
				})
				return;
			}
			this.$router.go(-1);
        },
        // 获取验证码
        getCode(){
          var that = this;
          
          var reg = /^1[0-9]{10}$/;
          if(!that.mobile){
            that.$toast("手机号不能为空");
            return;
          }
          if(!reg.test(that.mobile)){
            that.$toast("请输入正确的手机号");
            return;
          }
          var data = {
            Mobile: that.mobile,
          }
          
          if(!that.disabled){
            that.disabled = true;
          }
          
          that.public.request('post','/api/AppCommon/IsMobileValid',data,res => {
            console.log(res);
            if(res.data.errorCode == '00'){
              that.$toast("手机号未注册，请先注册！");
            }else if(res.data.errorCode == '99'){
              // 倒计时
              var num = 60;
              var time = setInterval(function(){
                if(num == 0){
                  clearInterval(time);
                  that.disabled = false;
                  that.sendTime = '获取验证码';
                }else{
                  num--;
                  that.sendTime = num + '秒后重试';
                  that.disabled = true;
                  
                }
              },1000)
              data.Id = localStorage.getItem('userId');
              that.public.request('post','/api/AppCommon/SendMobileCode',data,res => {
                console.log(res);
                if(res.data.errorCode == '00'){
                  if(res.data.errorMsg == 'success'){
                    that.$toast("验证码发送成功")
                  }
                }else if(res.data.errorCode == '99'){
                  that.$toast(res.data.errorMsg);
                }
              })

            }
          })
          
        },
        // 提交
        submit(){
          var that = this;
          var userId = localStorage.getItem('userId');
          var data = {
            Id: userId,
            Mobile: that.mobile ? that.mobile : '' ,
            Code: that.vefifiCode ? that.vefifiCode : '',
            Password: that.newPass ? that.newPass : '',
            ConfirmPassword: that.surePass ? that.surePass : '',
          }
          if(!data.Mobile){
            that.$toast("请输入手机号");
            return;
          }
          var reg = /^1[0-9]{10}$/;
          if(!reg.test(data.Mobile)){
            that.$toast("请输入正确的手机号");
            return;
          }
          if(!data.Code){
            that.$toast("请输入验证码");
            return;
          }
          if(!data.Password){
            that.$toast("请输入密码");
            return;
          }
          if(!data.ConfirmPassword){
            that.$toast("请确认密码");
            return;
          }
          that.public.request('post','/api/AppCommon/SetUserPassword',data,res => {
            // console.log(res);
            if(res.data.errorCode == '00'){
              that.$toast("操作成功");
              setTimeout(function(){
				if(that.isMini()){
					that.wx.miniProgram.reLaunch({
						url: window.baseUrl.xcxLogin?'/pages/login/index':'/pages/login/oldLogin/index',
					})
				}else{
					that.$router.go(-1);
				}
              },2000)
              
            }else if(res.data.errorCode == '99'){
              that.$toast(res.data.errorMsg);
            }
          })
        }

    },
}
</script>

<style scoped>
.contain{
  width:80%;
  margin:1rem auto;
}
.contain .account-sign{
  margin-bottom:0.36rem;
}
.contain .account-sign span{
  width:1.6rem;
  font-size:0.3rem;
}
.contain .account-sign span.pass-width{
  width:2.2rem;
}
.contain .account-sign input{
  flex:1;
  border:0.02rem solid rgb(218, 215, 215);
  border-radius:0.16rem;
  padding:0.12rem 0.2rem;
  font-size:0.28rem;
}

.contain .account-sign .verifi-code{
  flex:1;
}
.contain .account-sign .verifi-btn{
  width:auto;
  padding:0 0.2rem;
  height:0.6rem;
  text-align:center;
  line-height:0.6rem;
  background:#169BD5;
  font-size:0.26rem;
  color:#fff;
  border-radius:0.2rem;
  margin-left:0.2rem;
}

/* 提交 */
.submit-btn{
  width:100%;
  height:0.9rem;
  line-height:0.9rem;
  text-align:center;
  font-size:0.3rem;
  color:#fff;
  margin:0.3rem 0 0.3rem;
  background:#169BD5;
  border-radius:0.3rem;
}
.back-btn{
  text-align:center;
  color:#169BD5;
  font-size:0.3rem;

}
</style>