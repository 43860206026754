<template>
    <div class="container">
        <div class="evaluate-box">
            <div class="star-box flex-wrap">
                <div class="star-tit">描述相符：</div>
                <div class="star-box flex-wrap">
                    <img :src="cur>=1?starActive:star" @click="selectCur(1)"  alt="">
                    <img :src="cur>=2?starActive:star" @click="selectCur(2)" alt="">
                    <img :src="cur>=3?starActive:star" @click="selectCur(3)" alt="">
                    <img :src="cur>=4?starActive:star" @click="selectCur(4)" alt="">
                    <img :src="cur>=5?starActive:star" @click="selectCur(5)" alt="">
                </div>
                
            </div>
            <div class="text-box">
                <textarea class="text-tit" placeholder="请输入您宝贵的评论内容" v-model="remark" name="" id="" cols="30" rows="10"></textarea>
            </div>
            <div class="anonymous">
                <div class="anonymou-box flex-wrap" v-if="!isAnonymou" @click="onAnonymou()">
                    <img src="@/assets/images/cart/icon_nochecked.png" alt="">
                    <span>匿名</span>
                </div>
                <div class="anonymou-box flex-wrap" v-if="isAnonymou" @click="onAnonymou()">
                    <img src="@/assets/images/cart/icon_checked.png" alt="">
                    <span>匿名</span>
                </div>
            </div>
        </div>
        <!-- 提交按钮 -->
        <div class="submit-but" @click="onSubmit()">提交</div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            cur: -1,
            star: require('../../assets/images/feedback_star.png'),
            starActive: require('../../assets/images/feedback_staractive.png'),
            orderId: '',        // 从上个页面传过来的订单id
            isAnonymou: false,     // 是否匿名
            anonymouNum: '',        // 匿名的值
            remark: '',     // 评论内容
        }
    },
    created(){
        var that = this;
        that.orderId = that.$route.query.orderId;
        console.log(that.orderId);
    },
    methods:{
        // 五星评价
        selectCur(num){
            var that = this;
            that.cur = num;
        },
        // 是否匿名
        onAnonymou(){
            this.isAnonymou = !this.isAnonymou;
            // 不匿名
            if(!this.isAnonymou){
                this.anonymouNum = '';
            }else{      // 匿名
                this.anonymouNum = 10;
            }
        },
        // 提交
        onSubmit(){
            var that = this;
            var cur = that.cur;
            var data = {
                OrderId: that.orderId,
                ScoreLevel: cur * 10,
                Remark: that.remark,
                IsAnonymous: that.anonymouNum,
            };
            if(cur <= 0){
                that.$toast("请评价星级");
                return false;
            }
            if(!data.Remark){
                that.$toast("请输入评论内容");
                return false;
            }
            // console.log(data);
            that.public.request('post','/api/goodsOrdersEvaluate/saveEvaluate',data,res => {
                console.log(res);
                if(res.data.errorCode == '00'){
                    that.$toast('评论成功');
                    setTimeout(function(){
                        that.$router.go(-1);
                    },2000)
                    
                }else{
                    that.$toast(res.data.errorMsg);
                }
            })
        }
    }

}
</script>


<style scoped>
    /* 星级 */
    .evaluate-box{ width:100%;padding:0.2rem;box-sizing:border-box; }
    .star-box .star-tit{ font-size:0.3rem;margin-right:0.2rem; }
    .star-box .star-box img{ width:0.36rem;margin-right:0.12rem; }
    /* 多行文本评论 */
    .text-box{ width:100%;padding:0.16rem;box-sizing:border-box;border:0.02rem solid #eee;margin-top:0.2rem; }
    .text-box .text-tit{ font-size:0.28rem;resize:none; }
    /* 提交 */
    .submit-but{ width:80%;height:0.8rem;text-align:center;line-height:0.8rem;color:#fff;font-size:0.3rem;background:#199ed8;border-radius:0.2rem;margin:0.6rem auto; }
    /* 匿名 */
    .anonymous{ width:100%;margin-top:0.12rem; }
    .anonymous .anonymou-box img{ width:0.36rem;height:0.36rem; }
    .anonymous .anonymou-box span{ font-size:0.3rem;margin-left:0.2rem; }
</style>