<template>
    <div class="container">
        <!-- 搜索 -->
        <div class="search-box flex-wrap">
            <div class="search-input flex-wrap">
                <input type="text" ref="getSelect" placeholder="请输入搜索内容" v-model="keyword" @keyup.enter="onSearch()" @keyup="seachInput">
                <img src="@/assets/images/index/main-sousuo.png" alt="搜索按钮" @click="onSearch()">
            </div>
            <div class="search-tit" @click="onSearch()">搜索</div>
        </div>
        <!-- 搜索联想建议 -->
        <div class="suggest-box" v-if="idSeachInput">
            <div class="suggest-item" v-for="(i,id) in lenovoList" :key="id" @click="seclectLenvo(i.value)">
                <img src="@/assets/images/index/main-sousuo.png" alt="搜索按钮" >
                <div class="suggest-content">  <span class="resultTip" slot="title" v-html="highlight(i.value)"></span></div>
            </div>
        </div>

        <!-- 历史搜索 -->
        <div class="sear-box" v-if="!idSeachInput">
            <div class="sear-item" v-if="historyList && historyList.length>0">
                <div class="item-tit flex-sp">
                    <span>历史搜索</span>
                    <img v-if="!deleteShow" src="@/assets/images/search/icon_delete.png" @click="onDelete()" alt="">
                    <span v-if="deleteShow" class="finish-font" @click="onMore('up')">完成</span>
                </div>
                <div class="label-list catch-more flex-wrap" >
                    <div class="label-item flex-wrap" v-for="(item,index) in historyList" :key="index">
                        <span @click="onSearch(item)">{{item}}</span>
                        <img class="img-close" v-if="deleteShow" src="@/assets/images/search/icon_deleteClose.png" alt="">
                    </div>
                    <img class="img-down" v-if="hasMoreBtn" @click="onMore('down')" src="@/assets/images/index/icon_down.png" alt="">
                    <img class="img-down" v-if="hasUpShow" @click="onMore('up')" src="@/assets/images/index/icon_up.png" alt="">
                    
                </div>
            </div>
            <!-- 常购清单 -->
            <div class="oftenShop flex-sb">
               <div class="flex-ac"> 
                   <img class="shopImg" src="@/assets/images/search/shop.png" alt="">
                    <b>常购清单</b>
                </div>
                <div class="flex-ac often" @click="oftenShopBtn">
                    <span>点击查看常购物品</span>
                    <img  src="@/assets/images/search/More.png" alt="">
                </div>

            </div>
            <!-- 搜索发现 -->
            <!-- <div class="searchFind ">
                <div class="flex-sb">
                    <div class="flex-ac">
                        <img class="findImg" src="@/assets/images/search/searchItem.png" alt="">
                        <b>搜索发现</b>
                    </div>
                    <img class="seachImg" src="@/assets/images/search/lookIten.png" alt="">
                </div>
                <div class="searchFindList flex-ac">
                    <div class="searchFindItem flex-ac">
                        <div class="line"></div>
                        <div>哈哈哈哈哈</div>
                    </div>
                     <div class="searchFindItem flex-ac">
                        <div class="line"></div>
                        <div>哈哈哈哈哈</div>
                    </div> <div class="searchFindItem flex-ac">
                        <div class="line"></div>
                        <div>哈哈哈哈哈</div>
                    </div>
                     <div class="searchFindItem flex-ac">
                        <div class="line"></div>
                        <div>哈哈哈哈哈</div>
                    </div>

                </div>
            </div> -->


            
            <!-- <div class="sear-item" v-if="hotSearchList.length>0">
                <div class="item-tit flex-sp">
                    <span>热门搜索</span>
                </div>
                <div class="label-list flex-wrap1">
                    <div class="label-item" v-for="(item,index) in hotSearchList" :key="index" @click="onSearch(item.m_Item2)">{{item.m_Item2}}</div>
                </div>
            </div>
            <div class="sear-item" v-if="recommendList.length>0">
                <div class="item-tit flex-sp">
                    <span>推荐标签</span>
                </div>
                <div class="label-list flex-wrap1">
                    <div class="label-item" v-for="(item,index) in recommendList" :key="index" @click="onSearch(item.m_Item2)">{{item.m_Item2}}</div>
                </div>
            </div> -->
            
        </div>

    </div>
</template>

<script>
export default {
    
    data(){
        return{
            hasMoreBtn: false,   // 下拉隐藏
            hasUpShow: false,   // 上拉隐藏
            deleteShow: false,    // 删除关闭
            historyList:[],
            // historyArr: [],
            hotSearchList:[],   // 热门搜索
            recommendList:[],   // 推荐标签
            keyword: '',        // 输入框输入的内容
            idSeachInput:false,//搜索联想建议
            lenovoList:[],
            page: 1,
            hidshow: true,
			androidFlag: false,//是否Android 浏览器
        }
    },
    created(){
        var that = this;
		// that.androidFlag = /(Android)/i.test(navigator.userAgent.toLowerCase());
		let clientType = localStorage.getItem("clientType");
		if(clientType == "app"){
			that.androidFlag = true;
		}else if(clientType == "ios"){
			//ios todo
		}
        
        // 历史搜索
        var historyBool = sessionStorage.getItem("historyBool");
        console.log(historyBool);
        if(!historyBool||historyBool==null){   // 首次进入执行一次
            that.getHistory();
        }else{
            var historyData = localStorage.getItem("historyData");
            historyData = JSON.parse(historyData);
            if(historyData.length>0){
                that.historyList = historyData;
                // 计算历史搜索有几行
                that.toggleHistoryData();
            }else{
                that.getHistory();
            }
            
        }

        
        // 热门搜索、推荐标签
        that.getHotRecom();
        
    },
    mounted() {
        var that = this;
        that.isBack = that.$route.meta.isBack;
        // console.log(that.isBack);
        if (window.history && window.history.pushState) {
            history.pushState(null, null, document.URL);
            window.addEventListener("popstate", that.popstateBack, true);
        }

        // 软键盘弹出隐藏功能
        const ua = window.navigator.userAgent;
        if (ua.indexOf('Android') > -1 || ua.indexOf('iPhone') > -1) {
            // 默认屏幕高度
            var docmHeight = window.innnerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            window.onresize = () => {
                // 实时屏幕高度
                var nowHeight = window.innnerHeight || document.documentElement.clientHeight || document.body.clientHeight;
                if (docmHeight !== nowHeight) {
                    // 软键盘弹出 tabbar隐藏
                    that.bus.$emit('hidShow',0);
                } else {
                    // 软键盘隐藏  tabbar显示
                    that.bus.$emit('hidShow',1);
                }
            }
        }

    },
	
	activated(){
		if(this.androidFlag && top.android){
			top.android.onGoBack(true); //调用app方法
		}
	},
	deactivated(){
		if(this.androidFlag && top.android){
			top.android.onGoBack(false); //调用app方法
		}
	},
    destroyed() {
        window.removeEventListener("popstate", this.popstateBack, true);
    },
    
    methods:{
        // 搜索
        onSearch(value){
            var that = this;
            that.idSeachInput=false;
            that.lenovoList=[];
            var deleteShow = that.deleteShow;
            if(deleteShow){     // 删除
                // var historyArr = that.$store.state.searchHistory;
                var historyArr = localStorage.getItem("historyData")
                historyArr = JSON.parse(historyArr);
                historyArr.forEach((item,index) => {
                    if(value === item){
                        historyArr.splice(index,1);
                    }
                })
                that.historyList = historyArr;
                localStorage.setItem("historyData",JSON.stringify(that.historyList));
                // that.$store.commit('setHistory',historyArr)
            }else{      // 搜索
                if(value){
                    var keyword = value;
                }else{
                    var keyword = that.keyword;
                }
                
                if(keyword){
                    var historyArr = localStorage.getItem("historyData");
                    // 历史记录有值
                    if(historyArr){
                        that.historyList = JSON.parse(historyArr);
                        if(that.historyList.indexOf(keyword) == -1){
                            that.historyList.unshift(keyword);
                        }
                    }else{  // 历史记录没有值
                        console.log(that.historyList);
                        that.historyList.unshift(keyword);
                    }
                    
                    
                    localStorage.setItem("historyData",JSON.stringify(that.historyList));
                    that.saveHistory();
                }
                
                // that.$emit('onHistoryShow',keyword);
                
                that.$router.push({
                    path: '/search',
                    query: {
                        keyword: keyword,
                    }
                })
            }
            that.keyword = ''; 
        },
        // 历史搜索数据
        getHistory(){
            var that = this;
            var page = that.page;
            var data = {
                page: page,
                rows:10,
            }
            that.public.request('post','/api/GoodsSearchHis/QueryPage',data,(res) => {
                if(res.data.errorCode == '00'){
                    var arr = res.data.rows;
                    var arrList = [];
                    arr.forEach((item)=> {
                        arrList.unshift(item.KeyWord);
                    })
                    that.historyList = arrList;
                    localStorage.setItem("historyData",JSON.stringify(arrList));
                    // 判断执行一次
                    sessionStorage.setItem("historyBool",true);
                    // 计算历史搜索有几行
                    that.toggleHistoryData();
                }
            });

        },
        // 保存历史搜索
        saveHistory(){
            var that = this;
            var data = {
                Keyword: that.keyword,
            }
            that.public.request('post','api/GoodsSearchHis/SaveData',data,(res) => {
                if(res.data.errorCode == '00'){
                    // console.log(res.data);
                    
                }
            })
        },
        // 搜索联想建议
        seachInput(){
              var that=this;
           if(this.keyword.trim()){
              that.getLenovo(that.keyword);
             }
           if(this.keyword==''){
                that.idSeachInput=false;
                that.lenovoList=[];
           }
        },
        // 热门搜索、推荐标签
        getHotRecom(){
            var that = this;
            that.public.getOrgWebInfo(res => {
                var arr1 = [];
                var arr2 = [];
                var keyWord = res.keyWord;
                keyWord.forEach((item,index) => {
                    if(item.m_Item1 === 'keyword'){
                        arr1.push(item);
                    }else if(item.m_Item1 === 'tag'){
                        arr2.push(item);
                    }
                })
                that.hotSearchList = arr1;
                that.recommendList = arr2;
            })
        },
        // 常购清单
        oftenShopBtn(){
              this.$router.push({
                path: "/oftenBreed"
            });

        },
        // 计算历史搜索有几行
        toggleHistoryData(){
            var that = this;
            var idx = 0;
            var count = 0;
            var iIndex = 0;
            // console.log(that.historyList);
            if(that.historyList.length>0){
                that.$nextTick(() => {
                    if(document.querySelector(".catch-more")){
                        // 获取父容器所有子节点
                        var child = document.querySelector(".catch-more").childNodes;
                        // console.log("child",child);
                        
                        child.forEach((i,index) => {
                            
                            if(index === 0){
                                iIndex = i.offsetLeft;
                            }
                            if(i.offsetLeft == iIndex){
                                count++;
                                if(count == 3 ){
                                    idx = index-1;
                                    that.hasMoreBtn = true;
                                }
                            }
                        })
                        // 超过两行截断数据
                        if(idx > 0){
                            that.historyList = [...that.historyList].slice(0,idx);
                            // console.log(that.historyList);
                        }
                    }
                })
            }
            
        },
        // 更多历史搜索
        onMore(type){
            var that = this;
            if(type == 'down'){
                that.hasMoreBtn = false;
                that.hasUpShow = true;
                // that.historyList = that.historyArr;
                var historyArr = localStorage.getItem("historyData")
                that.historyList = JSON.parse(historyArr);
            }else{
                that.hasUpShow = false;
                that.deleteShow = false;
                that.toggleHistoryData();
            }
        },
        // 删除历史
        onDelete(){
            var that = this;
            that.deleteShow = true;
            that.hasMoreBtn = false;
            that.hasUpShow = false;
            // that.historyList = that.historyArr;
            var historyArr = localStorage.getItem("historyData")
            that.historyList = JSON.parse(historyArr);
        },
        // 获取联想建议
        getLenovo(key){
            var that=this;
            if(!key){
                key=that.keyword
            }
            that.lenovoList=[];
            that.public.request( 
            "get",
            "/api/Store/GetKeyword?key="+key,'',res=>{
             that.lenovoList=res.data;
             that.idSeachInput=true;
            });
        },
        // 联想建议  搜索
        seclectLenvo(val){
            var that=this;
            that.keyword=val;
            that.onSearch(val);
        },
        // 高亮效果
        highlight(text){
            let keyword = this.keyword;
            if (text.indexOf(keyword) !== -1){
                let replaceReg = new RegExp(this.keyword, 'g');
                let newText=`<font color='#027ada'>${keyword}</font>`
               return   text = text.replace(replaceReg, newText);
            }else{
                 return text
            }
              
        },

        // 监听返回
        popstateBack() {
        var that = this;
        var isBack = that.isBack;
        // console.log("-------1.0",isBack);
        if (!isBack) {
            var userAgent = navigator.userAgent;
            var isAndroid =
            userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1; //android终端
            var isIos =
            (userAgent.indexOf("iphone") > -1 ||
                userAgent.indexOf("ipad") > -1 ||
                userAgent.indexOf("ipod") > -1) &&
            (platform.indexOf("iphone") > -1 ||
                platform.indexOf("ipad") > -1 ||
                platform.indexOf("ipod") > -1);

            if (window.WeixinJSBridge) {
            window.WeixinJSBridge.call("closeWindow"); // 微信
            } else if (window.AlipayJSBridge) {
            window.AlipayJSBridge.call("closeWebview"); // 支付宝
            } else if (isAndroid) {
            window.obj.closePageLppzRequest(""); // 安卓app
            } else if (isIos) {
            window.webkit.messageHandlers.closePageLppzRequest.postMessage(""); //ios app
            } else {
            location.replace("about:blank", "_top");
            }
        }
        },
    }
}
</script>


<style scoped>
    /* 搜索 */
    .search-box{ width:100%;height:1rem;padding:0 0.2rem;box-sizing:border-box;border-bottom:0.02rem solid #eee; }
    .search-box .back-img{ width:0.42rem;margin-right:0.2rem; }
    .search-box .search-input{ flex:1;padding:0.15rem 0.2rem;border:0.02rem solid #eee;border-radius:0.28rem; }
    .search-box .search-input input{ font-size:0.28rem; }
    .search-box .search-input img{ width:0.29rem;height:0.29rem; }
    .search-box .search-tit{ font-size:0.3rem;margin-left:0.12rem; }

    /* 历史搜索 */
     .sear-box{ width:100%;padding:0.2rem;box-sizing:border-box; }
    .sear-box .sear-item .item-tit{ width:100%;height:0.5rem; }
    .sear-item .item-tit span{ font-size:0.3rem; }
    .sear-item .item-tit img{ width:0.3rem;height:0.3rem; }
    .sear-item .item-tit .finish-font{ font-size:0.24rem;color:#fa4949; }

    .sear-item .label-list{ width:100%;flex-wrap:wrap;margin-top:0.16rem;border-bottom:0.02rem solid #eee;min-height:1.2rem; }
    .sear-item .label-list .img-down{ width:0.32rem;margin: 0 0 0.2rem 0; }
    .sear-item:last-child .label-list{ border-bottom:0; }
    .sear-item .label-list .label-item{ padding:0.06rem 0.2rem;font-size:0.3rem;background:#f7f7f7;border-radius:0.2rem;margin:0 0.4rem 0.2rem 0; }
    .sear-item .label-list .label-item .img-close{ width:0.26rem;height:0.26rem;margin-left:0.08rem; }

    .suggest-item{display: flex;align-items:center;border-bottom: 0.02rem solid #eee;padding: 0.2rem 0.3rem;}
    .suggest-item img{width: 0.3rem;margin-right: 0.2rem;}
    .suggest-content{font-size: 0.32rem;}
    .active{font-weight: 700;color: red;}

    .oftenShop{justify-content: space-between;  padding: .35rem 0;font-size: 0.28rem; background: url("../../assets/images/search/icon-bd1.jpg");border-radius: 0.2rem;background-size: cover;}
    .flex-ac{display: flex;align-items: center;}
    .shopImg{width: 16%; margin: 0 0.2rem }
     /* .often{margin-left: 1.25rem;} */
    .often img{ width: 10%;}
    .flex-sb{display: flex;align-items: center;justify-content: space-between;}

    .searchFind{font-size: 0.28rem;padding: 0.5rem 0.15rem;margin-top: 0.5rem;border-radius: 0.2rem;;background: url("../../assets/images/search/icon-bg2.jpg"); background-size: cover;}
    .findImg{width: 18%;margin: 0 0.2rem ;}
    .seachImg{width: 5%;margin-right: 0.35rem;}
    .searchFindList{flex-wrap:wrap; justify-content: space-between;margin-left: 0.2rem;}
    .searchFindItem{margin: 0.2rem 0;width: 40%; background-color:#fff0da ; padding: 0.1rem 0.2rem;border-radius: 0.2rem;}
    .line{background-color: red; height: 0.28rem;width: 0.05rem;margin-right: 0.05rem;}
    


</style>