<template>
	<div class="lotteryPage" :style="'background-image: url('+pageBg+')'">
		<audio ref="mp3" autoplay="autoplay" loop="true" :src="mp3Bg"></audio>
		<img class="titPng" :src="ruleImg" @click="showPopupTap(0)"></img>
		<img class="titlePng" :src="nameImg"></img>
		<div v-if="lotteryInfo" class="lotteryBody">
			<img class="zhuanBorder" :src="zpBorderBg"></img>
			<div class="roteDiv" :style="{transform:rotate_deg,transition:rotate_transition}">
				<img class="zhuanPan" :src="zpConBg"></img>
				<div class="zp-box">
					<div v-for="(item,index) in prizeArr" :class="'zp-part part-'+index"></div>
				</div>
				<div v-for="(item,index) in prizeArr" :class="'zp-con zp-con'+index">
					<img class="prizeImg" :src="item.SrcPic" alt=""/> 
					<div class="prizeTit">{{item.Remark?item.Remark:""}}</div>
				 </div>
			</div>
			<img class="clickBtn" :src="startImg" @click="drawLottery"></img>
		</div>
		
		<div v-if="lotteryInfo" class="CanYuTimes">累计{{totalPerson}}人次参与</div>
		<img v-if="lotteryInfo" class="recordSty" :src="myprizeImg" alt="" @click="showPopupTap(1)" />
		<van-swipe v-if="lotteryInfo && lotteryInfo.ShowWinners" class="person-swipe" vertical :autoplay="3000" :show-indicators="false">
			<van-swipe-item class="person-swipe-item" v-for="item in personList">
				<div class="person-swipe-sub" v-for="res in item">
					<span>{{res.CreateName}}</span><span>{{res.LevelName}}</span><span>{{res.Name}}</span>
				</div>
			</van-swipe-item>
		</van-swipe>
		
		<van-popup v-model="showPopup" round closeable position="bottom" :style="{ height: '70%' }">
			<div class="popup-content">
				<div class="nav-btn">
					<div v-for="(item,i) in navArray" class="nav-btn-li" :class="navCurrent==i?'active':''" @click="navChange(i)">{{item}}</div>
				</div>
				
				<div v-if="navCurrent==0" class="explain-box">
					<div class="explain-li">
						<p>活动奖品</p>
						<div v-for="item in setPrize">
						   <span>{{item.LevelName}}</span>
						   <span style="margin-left: 30rpx;">{{item.Name}}</span>
						</div>
					</div>
					<div class="explain-li">
						<p>活动时间</p>
						<span>{{lotteryInfo.STime}} — {{lotteryInfo.ETime}}</span>
					</div>
					<div class="explain-li">
						<p>活动说明</p>
						<span>{{lotteryInfo.Remark}}</span>
					</div>
				</div>
				<div v-if="navCurrent==1" class="prize-box">
					<div class="prize-li" v-for="item in myPrizeList" :class="item.Flg==0?'expire':''"  @click="prizeDetails(item)">
						<span>{{item.LevelName}}</span>
						<span>{{item.Name}}</span>
						<span class="time">中奖时间：{{item.CreateDate}}</span>
						<img v-if="item.Flg==0" class="status" src="@/assets/images/turntable/zp-prize-expire.png" alt="" />
						<img v-if="item.Flg==1" class="status" src="@/assets/images/turntable/zp-prize-exchange.png" alt="" />
					</div>
					
					<div v-if="myPrizeList.length==0" class="no-prize">暂无任何奖品</div>
				</div>
			</div>
		</van-popup>
		
		<van-popup v-model="showDtPopup" round closeable position="bottom" :style="{ height: '70%' }">
			<div v-if="prizeInfo" class="popup-dt-content">
			  <div class="label">
				 <span>奖品详情</span>
			  </div>
			  <div class="prize-level" :class="prizeInfo.Flg==0?'expire':''" >
				 <span>{{prizeInfo.LevelName}}</span> <span>{{prizeInfo.Name}}</span>
			  </div>
			  <div class="label">兑奖详情</div>
			 <div class="prize-cell">
				<span class="tag">兑奖期限</span>
				<span class="val">{{prizeInfo.STime}} 至 {{prizeInfo.ETime}}</span>
			 </div>
			 <div class="prize-cell">
				<span class="tag">兑奖须知</span>
				<span class="val">{{prizeInfo.Remark}}</span>
			 </div>
			 <div class="prize-cell">
				<span class="tag">兑奖码</span>
				<span class="val">{{prizeInfo.Code}}</span>
			 </div>
			 <div class="qr-code">
				<img :src="prizeInfo.QrCode" alt=""/>
			 </div>
		   </div>
		</van-popup>	
		<van-overlay z-index="9" :show="showResult" @click="showResult = false">
			<div class="result-content" @click.stop>
			   <div class="result-level">{{resultLevel}}</div>
			   <div class="result-red">{{resultName}}</div>
			   <div class="confirm-btn" @click="showResult = false">我知道了</div>
			   <img class="close-img" src="@/assets/images/turntable/cancel.png" @click="showResult = false"/>
			</div>
		</van-overlay>
	</div>
</template>


<script>
	import {Toast, Dialog} from "vant";
	export default {
		name: 'app',
		components: {
			Toast,
			[Dialog.Component.name]: Dialog.Component
		},
		data(){
		  return{
			  openId: "",
			  activityId: "", //活动id 5cd3df840186483d991b6b3de500529c
			  pageBg: "https://jhyypic.oss-cn-beijing.aliyuncs.com/upload/dzp/3758ead3bc6c42c191433c3ce97e4670.jpg",
			  ruleImg: "",
			  nameImg: "",
			  myprizeImg: "",
			  startImg: "",
			  zpBorderBg: "",
			  zpConBg: "",
			  lotteryInfo: null,
			  mp3Bg: null,
			  setPrize: [],
			  
			  totalPerson: 0, //累计参加人数
			  isAllowClick: true, //是否能够点击
			  rotate_deg: 0, //指针旋转的角度
			  lastDeg: 0, //记录上次旋转的角度(抽奖停留的位置)
			  rotate_transition: "", //初始化选中的过度属性控制
			  prizeArr: [1,2,3,4,5,6,7,8],
				
			
			showPopup: false,
			navCurrent: 1,
			navArray: ["活动说明", "我的奖品"],
			myPrizeList: [], //我的奖品
			prizeInfo: null, //奖品详情
			showDtPopup: false,
			showResult: false,
			resultFlag: false,
			resultLevel: "",
			resultName: "",
			personList: []  //中奖名单
		  }
		},
		
		created() {
			// this.activeId = this.getUrlKey("Id");
			this.activityId = this.$route.query.id;
			this.queryData();
			this.queryPersons();
		},
		methods: {
			queryData(){
			    let that = this;
				this.$toast.loading({
					message: "加载中...",
					duration: 0
				});
				this.public.request("get","api/BigWheelDrawMain/GetMainInfo",{
					// orgId: localStorage.getItem("orgId"),  //this.public.getConfig.defaultOrgId
					id: this.activityId
				},res=>{
					this.$toast.clear();
					if(res.data.errorCode == "00"){
						// that.bannerList = res.data.rows;
						res.data.rows.BigWheelDrawImage.forEach(item=>{
						  switch (item.Type) {
						    case "bjtp": //背景图片
						      that.pageBg = item.UpSrc;
						      break;
						    case "zpgz": //转盘规则
						      that.ruleImg = item.UpSrc;
						      break;
						    case "hdmc": //活动名称
						      that.nameImg = item.UpSrc;
						      break;
						    case "wdjp": //我的奖品
						      that.myprizeImg = item.UpSrc
						      break;
						    case "ksan": //开始按钮
						      that.startImg = item.UpSrc
						      break;
						    case "zpbj": //转盘背景
						      that.zpBorderBg = item.UpSrc;
						      break;
						    case "zpbb": //转盘白板
						      that.zpConBg = item.UpSrc;
						      break;
						    case "mp3":
								that.mp3Bg = item.UpSrc;
						      break
						    default:
						      break;
						  }
						})
						res.data.rows.RealDrawDetails.forEach(item=>{
						  if(item.Remark){
						    // item.Remark = item.Remark.length>4?item.Remark.substr(0,4)+"...":item.Remark;
						  }
						})
						
						that.$store.commit('setHeadTitle',res.data.rows.BigWheelDrawMain.Name);
						that.prizeArr = res.data.rows.RealDrawDetails;
						that.setPrize = res.data.rows.BigWheelDrawDetails;
						that.lotteryInfo = res.data.rows.BigWheelDrawMain;
						that.totalPerson = res.data.TagData?res.data.TagData.viewCount:0;
						that.addVisitRecord(res.data.rows.BigWheelDrawMain.Id);
					}else{
						that.$toast(res.data.errorMsg)
					}
					
				})
			    
			},
			
			/** 添加浏览记录 */
			addVisitRecord(mainId){
				let that = this;
				this.public.request("get","api/BigWheelDrawMain/AddVisitRecord",{
					id: mainId
				},res=>{
					// if(res.data.errorCode == "00"){
					// }else{
					// 	that.$toast(res.data.errorMsg);
					// }
				})
			},
			
			/** 中奖滚动名单 */
			queryPersons(){
			    let that = this;
				this.public.request("get","api/BigWheelDrawMain/GetLottery",{
					id: this.activityId
				},res=>{
					if(res.data.errorCode=="00"){
						that.personList = that.arrayResolve(3,res.data.rows);
					}
				})
			},
			/**
			 * 数组分解, n条为一组， 最后一组如果没有n条，则从第一条开始补齐
			 */
			arrayResolve(n, arr) {
				if(arr.length<=0){
					return;
				}
			  //需要补齐的length
			  const length = n - arr.length % n;
			  for(let i = 0; i < length; i ++){
			    arr.push(arr[i]);
			  }
			  let result = [];
			  for (let i = 0; i < arr.length; i += n) {
			    result.push(arr.slice(i, i + n));
			  }
			  return result;
			},
			
			/**
			 * 抽奖转盘转动
			 */
			drawLottery() {
				this.$refs.mp3.play();
				if (!this.isAllowClick){//正在抽奖
				  return
				};
				let that = this;
				Dialog.confirm({
				  message: '是否消耗'+ that.lotteryInfo.GoldNum + "积分, 进行一次抽奖",
				}).then(() => {
				    that.queryWheelDraw();
				}).catch(() => {
					// on cancel
				});
				// this.rotatingLottery(2);
			},
			
			 /** 点击抽奖 获取中奖结果
			   * errorCode：00-中奖，03-谢谢参与 04-活动暂停 05-已达上限 06-积分不足 07-未开始 08-已结束， 09-奖品已发完
			  */
			queryWheelDraw(){
				let that = this;
				this.isAllowClick = false;
				  // rotate_deg: "rotate(" + 0 + "deg)",
				this.public.request("get","api/BigWheelDrawMain/GetBigWheelDraw",{
					id: this.lotteryInfo.Id,
					openid: localStorage.getItem("openid")
				},res=>{
					if(res.data.errorCode == "00"){
						that.totalPerson = res.data.TagData?res.data.TagData:0
						that.rotatingLottery(res.data.rows);
					}else{
						console.log("抽奖异常信息",res.data)
						if(res.data.errorCode != "03"){
							that.isAllowClick = true; //恢复可点击
						}
						switch (res.data.errorCode) {
							case "03":
							  that.rotatingLottery(0);
							  break;
							case "04":
							  that.showModalEvent("活动已暂停","请联系客服")
							  break;
							case "05":
							  that.showModalEvent("您的中奖次数已达上限","不要忘记兑奖噢")
							  break;
							case "06":
							  that.showModalEvent("您的积分不足","无法参加活动")
							  break;
							case "07":
							  that.showModalEvent("活动未开始", "活动将于" + that.lotteryInfo.STime + "开启",true)
							  break;
							case "08":
							  that.showModalEvent("活动已结束","欢迎下次光临")
							  break;
							case "09":
							  that.showModalEvent("谢谢参与","该奖品已发完，再试一次吧")
							  break;
							default:
							  that.$toast(res.data.errorMsg)
							  break;
						}
					}
				})
			},
			
			/** 抽奖转动转盘 
			* let winningIndex = 5; //模拟中奖位置,此处到时调用后台接口取值
			*/
			rotatingLottery(winningIndex) {
			    let rand_circle = 5*360; //转盘转动旋转5圈	
			    let angle = 45; //总共8个扇形区域，每个区域45度 (360/8)
			
			    //转盘停留的位置  如果=0则停留在起始位置边框线上，否则停留对应位置上
			    let angleIndex = winningIndex>0 ? 9 - winningIndex : 0;
			
			    //根据位置，计算要旋转的度数
			    let deg = angleIndex==0 ? rand_circle : rand_circle + angleIndex * angle - 25;
			
			    //继上次停留位置接着转
			    let realDeg =  (360 - this.lastDeg % 360) + this.lastDeg + deg;
			
				this.lastDeg = realDeg;
				this.rotate_deg = "rotate(" + realDeg + "deg)";
				this.rotate_transition = "transform 5s ease-in-out";
			    let that = this
			    setTimeout(() => {
			        that.isAllowClick = true;
			        that.rotate_transition = "";
			        //  rotate_deg: "rotate(" + 0 + "deg)",
					
			      if(winningIndex==0){
			        that.showModalEvent("谢谢参与","再接再厉，大奖等你来拿");
			      }else{
			        let levelName = that.prizeArr[winningIndex-1].LevelName;
			        let prizeName = that.prizeArr[winningIndex-1].Name;
			        if(prizeName == "谢谢参与"){
			          that.showModalEvent("谢谢参与","再接再厉，大奖等你来拿");
			        }else{
			          that.showModalEvent(levelName, prizeName, true);
			        }
			      }
			    }, 5500)
			},
			
			/** 弹窗提示 */
			showModalEvent(title,content, flag){
				this.showResult = true;
				this.resultLevel = title;
				this.resultName = content;
				this.resultFlag = flag?flag:"";
			},
			
			/**
			 * 我的奖品
			 */
			showPopupTap(currentType){
				let that = this;
				this.public.request("get","api/BigWheelDrawMain/GetUserLottery",{
					id: this.activityId
				},res=>{
					if(res.data.errorCode == "00"){
					    that.myPrizeList = res.data.rows;
					    that.navCurrent = currentType;
						that.showPopup = true;
					}
				})
			},
			
			/**
			 * 活动说明，我的奖品切换事件
			 */
			navChange(i){
				this.navCurrent = i;
			},
			
			/** 奖品详情 */
			prizeDetails(item){
			    if(item.Type==20){//红包类型无详情
			      return;
			    }
				item.QrCode = window.baseUrl.url + "api/BigWheelDrawMain/ShowCode?code="+item.Code;
				this.showDtPopup = true;
				this.prizeInfo = item;
			},
			
			//获取url 参数
			getUrlKey(name){
			    return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
			}
		  
		},
		
		destroyed(){
			this.mp3Bg = "";
			if(this.$refs.mp3){
				this.$refs.mp3.stop();
			}
			this.$store.commit("setHeadTitle", "");
		},
		
	}
</script>

<style scoped>
	.lotteryPage {
		width: 100%;
		height: 100vh;
		/* background-image: url('../../assets/images/turntable/bg.png'); */
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: 100% 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	.titPng{
		width: 40%;
		margin-top: 20px;
	}
	.titlePng {
		width: 80%;
		margin: -30px 0;
	}
	.lotteryBody {
		position: relative;
		width: 270px;
		height: 270px;
	}
	.zhuanBorder {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	.roteDiv {
		position: absolute;
		width: 90%;
		height: 90%;
		top: 5%;
		left: 5%;
	}
	.zhuanPan {
		width: 100%;
		height: 100%;
	}
	.clickBtn {
		position: absolute;
		width: 80px;
		height: 90px;
		top: 48%;
		left: 48%;
		transform: translate(-50%, -50%);
	}
	
	.zp-box{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		border-radius: 50%;
		overflow: hidden;
		/* background-color: orange; */
	}
	.zp-part{
		position: absolute;
		width: 50%;
		height: 50%;
		top: 0;
		right: 0;
		transform-origin: 0 100%;
		border: red 1px solid;
	}
	.part-0{
		text-align: center;
		transform: rotate(0deg) skewY(-45deg);
		/* background-color: orangered; */
	}
	.part-1{
		transform: rotate(45deg) skewY(-45deg);
		/* background-color: aliceblue; */
	}
	.part-2{
		transform: rotate(90deg) skewY(-45deg);
		/* background-color: orangered; */
	}
	.part-3{
		transform: rotate(135deg) skewY(-45deg);
		/* background-color: aliceblue; */
	}
	.part-4{
		transform: rotate(180deg) skewY(-45deg);
		/* background-color: orangered; */
	}
	.part-5{
		transform: rotate(225deg) skewY(-45deg);
		/* background-color: aliceblue; */
	}
	.part-6{
		transform: rotate(270deg) skewY(-45deg);
		/* background-color: orangered; */
	}
	.part-7{
		transform: rotate(315deg) skewY(-45deg);
		/* background-color: aliceblue; */
	}
	
	.zp-con{
	   position: absolute;
	   width: 60px;
	   height: 60px;
	   display: flex;
	   flex-direction: column;
	   align-items: center;
	   justify-content: space-between;
	}
	.zp-con0{
	   top: 5%;
	   right: 25%;
	   transform: rotate(25deg);
	   text-align: center;
	}
	.zp-con1{
	   top: 25%;
	   right: 5%;
	   transform: rotate(70deg);
	   text-align: center;
	}
	
	.zp-con2{
	   bottom: 23%;
	   right: 5%;
	   transform: rotate(115deg);
	   text-align: center;
	}
	.zp-con3{
	   bottom: 5%;
	   right: 25%;
	   transform: rotate(155deg);
	   text-align: center;
	}
	.zp-con4{
	   bottom: 5%;
	   left: 24%;
	   transform: rotate(-155deg);
	   text-align: center;
	}
	.zp-con5{
	   bottom: 23%;
	   left: 5%;
	   transform: rotate(-115deg);
	   text-align: center;
	}
	.zp-con6{
	   top: 25%;
	   left: 5%;
	   transform: rotate(-70deg);
	   text-align: center;
	}
	.zp-con7{
	   top: 5%;
	   left: 24%;
	   transform: rotate(-25deg);
	   text-align: center;
	}
	
	.zp-con .prizeImg {
	   width: 40px;
	   height: 40px;
	}
	.zp-con .prizeTit{
	   font-size: 10px;
	}
	
	.CanYuTimes {
		padding: 8px 40px;
		background-color: #bc3817;
		color: #faf0d8;
		font-size: 12px;
		border-radius: 16px;
		margin-top: 30px;
	}
	.recordSty {
		position: absolute;
		width: 80px;
		height: 80px;
		bottom: 60px;
		right: 10px;
	}
		
	.popup-content{
		position: relative;
		background: #fff;
	}
	.nav-btn{
		width: 70%;
		height: auto;
		margin: 20px auto;
		display: flex;
		font-size: 15px;
	}
	.nav-btn-li{
		flex: 1;
		padding: 10px 0;
		text-align: center;
		border-bottom: 1px #fff solid;
	}
	.nav-btn-li.active{
		color: dodgerblue;
		border-bottom: 1px dodgerblue solid;
	}
	/* 活动说明 */
	.explain-box{
		padding: 0 30px;
	}
	.explain-li p{
		font-size: 14px;
		font-weight: bold;
		margin: 10px 0;
		color: #111;
	}
	.explain-li span{
		font-size: 14px;
		color: #666;
	}
	
	/* 我的奖品 */
	.prize-box{
		padding: 0 30px;
	}
	.prize-box .prize-li{
		position: relative;
		background: #bc3817;
		display: flex;
		flex-direction: column;
		justify-content: center;
		color: #fff;
		font-size: 15px;
		padding: 10px;
		margin: 10px 0;
		border-radius: 10px;
	}
	.prize-box .prize-li.expire{
		background: #A6A6A6;
	}
	.prize-box .prize-li .time{
		font-size: 12px;
		margin-top: 10px;
	}
	.prize-box .prize-li .status{
		position: absolute;
		width: 60px;
		height: 60px;
		right: 15px;
	}
	
	/* 奖品详情 */
	.popup-dt-content{
	   /* width: 100%; */
	   background: #fff;
	}
	.popup-dt-content .label{
	   padding: 20px;
	   display: flex;
	   align-items: center;
	   justify-content: space-between;
	   font-weight: bold;
	   font-size: 16px;
	}
	.prize-level{
	   position: relative;
	   background: #bc3817;
	   display: flex;
	   flex-direction: column;
	   align-items: center;
	   color: #fff;
	   font-size: 14px;
	   padding: 20px;
	   margin: 0 20px;
	   border-radius: 5px;
	}
	.prize-level.expire{
	   background: #A6A6A6;
	}
	.prize-cell{
	   padding: 5px 20px;
	   font-size: 15px;
	   display: flex;
	}
	.prize-cell .tag{
	   width: 90px;
	   color: #999;
	   display: inline-block;
	}
	.prize-cell .val{
	   flex: 1;
	}
	.qr-code img{
	   width: 150px;
	   height: 150px;
	   margin: 0 100px;
	}

	/* 抽奖结果弹窗 */
	.result-content{
	   position: fixed;
	   width: 80%;
	   height: 40%;
	   left: 10%;
	   top: 30%;
	   z-index: 9;
	   background: #fff;
	   font-size: 16px;
	   border-radius: 10px;
	   text-align: center;
	 }
	 .result-level{
	    margin: 15% 0 15% 0;
	 }
	 .result-red{
	    color: #EA6A6A;
	    padding: 0 20px;
	 }
	 .result-content .confirm-btn{
	   position: absolute;
	   width: 60%;
	   color: #fff;
	   font-size: 16px;
	   padding: 10px 0;
	   border-radius: 50px;
	   background: #F6BC5A;
	   bottom: 30px;
	   left: 20%;
	 }
	 .close-img{
	    position: absolute;
	    width: 15px;
	    height: 15px;
	    right: 20px;
	    top: 20px;
	 }
	 
	 .mp3-mute{
		 position: fixed;
		 width: 30px;
		 height: 30px;
		 top: 30px;
		 right: 30px;
		 z-index: 999;
	 }
	 .person-swipe{
		position: fixed;
	 	width: 75%;
	 	height: 120px;
		bottom: 50px;
		left: 10px;
	 	color:#f5f5f5;
	 }
	 .person-swipe-item{
	 	width: 100%;
	 	height: 100%;
	 }
	 .person-swipe-sub{
		width: 100%;
	 	font-size: 13px;
	 }
	 .person-swipe-sub span{
		 margin: 0 10px;
	 }
	 
	 .no-prize{
		 margin-top: 45%;
		 text-align: center;
		 font-size: 15px;
		 color: #666;
	 }
</style>
