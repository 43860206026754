<template>
    <div class="container">
		<div class="poster-type" style="display: flex; flex-wrap: wrap; padding: 5px;">
			<div class="poster-cell">热门</div><div class="poster-cell">感冒发烧</div>
			<div class="poster-cell">热门</div><div class="poster-cell">感冒发烧</div>
		</div>
		
		<!-- v-for="(item,index) in bookList" :key="index" -->
		<div class="poster-list" style="display: flex; flex-wrap: wrap; padding: 0 6px;">
			<div class="poster-item">
				<img class="poster-img" src="@/assets/images/banner-new-.jpg" alt="" />
				<div class="poster-name">海报文案宣传图</div>
			</div>
			<div class="poster-item">
				<img class="poster-img" src="@/assets/images/banner-new-.jpg" alt="" />
				<div class="poster-name">海报文案宣传图</div>
			</div>
			<div class="poster-item">
				<img class="poster-img" src="@/assets/images/banner-new-.jpg" alt="" />
				<div class="poster-name">海报文案宣传图</div>
			</div>
		</div>
           

        <div class="loading-box flex-center" v-if="noMore && bookList.length>0">
            <span class="load-txt">已经到底了！</span>
        </div>
        <!-- 加载样式 -->
        <div class="loading-box flex-center" v-if="showLoading && bookList.length>0">
            <img class="load-img" src="@/assets/images/index/loading.gif" alt="加载图片">
            <span class="load-txt">正在加载</span>
        </div>

        <!-- 暂无记录 -->
        <div class="no-data" v-if="bookList.length == 0">
            <img src="@/assets/images/nore.png" alt="暂无数据">
            <span>暂无数据</span>
        </div>

    </div>
</template>

<script>
export default {
    data(){
        return{
			bookList: [1],
            showLoading: false,
			noMore: false,
			page: 1,
            
        }
    },
    created(){
        var that = this;
        // that.getbookList();
    },
    mounted(){
        window.addEventListener('scroll', this.onReachBottom);
    },
    destroyed(){
        window.removeEventListener('scroll', this.onReachBottom);
    },
    methods:{
		
		// 求购登记列表
		getbookList(){
		    var that = this;
		    that.$toast.loading({
		        message: '加载中',
		        duration: 0
		    })
		    that.public.request('get','/api/sysUserInfo/GetMyList',{
		        OrgId: localStorage.getItem('orgId'),
				page: that.page,
				rows: 10,
		    },res => {
		        that.$toast.clear();
		        if(res.data.errorCode == '00'){
		            var curArr = res.data.rows ? res.data.rows : [];
		            that.bookList = that.page == 1?res.data.rows:that.bookList.concat(curArr);;
					that.noMore = curArr.length < 10?true:false;
					that.showLoading = curArr.length < 10?false:true;
		        }else{
		           that.$toast(res.data.errorMsg)
		        }
		    })
		},
		
        // 删除
        deleteBook(id,i){
			let that = this;
            that.$dialog.confirm({
                message: '确定删除吗',
            }).then(() => {
                that.public.request('post','/api/sysUserInfo/DeleteMyListInfo',{
                    id: id,
                },res => {
                    if(res.data.errorCode == '00'){
                        that.bookList.splice(i,1);
                    }else{
						that.$toast(res.data.errorMsg)
					}
                })
            }).catch(() => {
                // console.log('取消');
            })
            
        },

        //到达底部
        onReachBottom(){
            var that = this;
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
            //变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
            //滚动条到底部的条件
            // console.log(scrollTop);
            if(scrollTop>0){
                if(scrollTop + windowHeight + 17>=scrollHeight){
                    that.page = that.page + 1;
                    if(!that.noMore){
						that.getbookList();
                    }else{
                        console.log('已经到底了');
                    }
                }
            }

        },

        // 跳转详情
        toGoodsDetail(){

        },
		/**
		 * 新增
		 */
		toEditBook(id){
			this.$router.push({
			  path: "/editBuyBook",
			  query: {
			    id: id,
			  },
			});
		}

    }
}
</script>


<style scoped>
    .poster-cell{
    	padding: 2px 15px;
    	margin: 5px;
    	border: 1px #eee solid;
    	border-radius: 30px;
    	background: #f7f7f7;
		font-size: 14px;
    }
    .poster-item{
    	position: relative;
    	width: 46%;
    	margin: 5px 2%;
    }
    
    .poster-item .poster-img{
    	width: 100%;
    	height: 250px;
    }
    .poster-item .poster-name{
    	margin-top: 5px;
		font-size: 14px;
    }
</style>