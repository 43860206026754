<template>
  <!-- 登录的另一个样式 -->
  <div class="contain">
    <div class="logo-box" :style="{backgroundImage: themeImgObj.loginBackImg ? `url(${themeImgObj.loginBackImg})` : 'auto'}"> <!--:style="backgroundDiv"  -->
      <div class="logo-img theme-bg-color">
        <img
          class="logo-i"
          v-if="logoInfo.AppLogo"
          :src="logoInfo.AppLogo"
          alt=""
        />
        <img
          class="logo-i"
          v-if="!logoInfo.AppLogo"
          :src="logoInfo.Logo"
          alt=""
        />
      </div>
      <div class="logo-txt">{{ logoInfo.ShortName }}</div>
    </div>
    <!-- 手机账号密码登录 -->
	<div v-if="typeSign == 'account'">
      <div class="login-box">
        <div class="login-input">
          <input
            class="input-txt"
            type="number"
            placeholder="请输入账号"
            v-model="accountName"
          />
        </div>
        <div class="login-input">
          <input
            class="input-txt"
            type="password"
            placeholder="请输入密码"
            v-model="accountPass"
          />
        </div>
        <div class="pass-box flex-sp">
          <div class="pass-label flex-wrap" @click="selectBox">
			<input type="checkbox" v-model="select" id="checkpass">
			<span>记住密码</span>
          </div>
          <div v-if="!isGo" class="pass-label error-color">忘记密码</div>
          <div v-else class="pass-label" @click="toforgetPass">忘记密码?</div>
        </div>
        <div v-if="!isGo" class="login-btn error-bg">登录</div>
        <div v-else class="login-btn" @click="login">登录</div>
		<div class="check-box">
			<input type="checkbox" v-model="checked" @change="onChange"/>
			<span>同意并阅读<span class="tag" @click="onAgreement">《用户协议》</span>与<span class="tag use" @click="onPolicy">《隐私政策》</span></span>
		</div>
        <!-- 旧版 -->
       <!-- <div v-if="!isGo" class=" short-message error-color">手机短信登录</div>
        <div v-else class="short-message" @click="changeLogin">手机短信登录</div> -->
        <!--<div v-if="!isGo" class="login-Register error-color">注册</div>
        <div v-else class="login-Register" @click="goregister">注册</div> -->
        <!-- 新版 -->
        <div class="mobile-font">
          <!-- <span>咨询电话:15036846887</span> -->
          <span>咨询电话：{{logoInfo.Mobile}}</span>
          <img @click="callPhone()" :src="themeImgObj.loginMobile" alt="">
        </div>
        <div class="regis-btn" >
          <span>还没有注册？</span>
          <span v-if="!isGo" class="btn-b grey">注册</span>
          <span v-else class="btn-b" @click="goregister">注册</span>
        </div>
      </div>
    </div>

    <!-- 手机短信验证码登录 -->
    <div class="sign-info" v-else-if="typeSign == 'phone'">
      <div class="account-sign flex-wrap">
        <span>手机号：</span>
        <input type="number" placeholder="请输入您的手机号" v-model="mobile" />
      </div>
      <div class="account-sign flex-wrap">
        <div class="verifi-code flex-wrap">
          <span>验证码：</span>
          <input type="text" placeholder="请输入验证码" v-model="verifiCode" />
        </div>
        <button class="verifi-btn" @click="getCode" :disabled="disabled">
          {{ sendTime }}
        </button>
      </div>
      <div>
        <div class="sign-btn" @click="mobileLogin">登录</div>
        <div class="phone-sign" @click="changeLogin">账号密码登录</div>
        <div class="idition-text">版本1.0</div>
      </div>
    </div>
    
	<div class="goLoad" v-if="isgoLoad" @click="goLoadBtn">
      <div class="goLoad-item">
        <img src="@/assets/images/icon-goLoad.png" alt="" />
        <div>重新加载</div>
      </div>
    </div>
  
	<div class="version">版本：V1.0</div>
	
	
  </div>
</template>

<script>
	import {Dialog} from "vant"
export default {
	components: {
		[Dialog.Component.name]: Dialog.Component
	},
	data() {
		return {
			typeName: window.baseUrl.titleName,
			clientType: null,
			goOrder: false, //注册即下单
			typeSign: "account", // account账号登录  phone手机登录
			select: false, //记住密码
			accountName: "", //账号
			accountPass: "", //密码
			mobile: "", //手机号
			verifiCode: "", //密码 验证码
			disabled: false, // 获取验证码
			sendTime: "获取验证码",
			logoInfo: {},
			orgId: "",
			Applets: "", // 微信小程序的openid
			openid: "", // 微信公众号的openid
			junp: sessionStorage.getItem("junp"),
			isGo: false, //初始信息完成
			isgoLoad: false,
			themeImgObj:{},   // 主题背景图片
			num: 0,
			checked: false //是否同意用户协议
		};
	},
  created() {
    var that = this;
    that.checked = localStorage.getItem("agreement")=="true"?true:false;
    // 主题色图片
    that.themeImgObj = that.themeColorImg.imgObj;
    
    // 首次进入页面，清除之前存留的orgId
    localStorage.removeItem("orgId");
    //判断路径上是否有orgid 如果有保存 
    var orgIdObj = this.public.urlObj(location.href);
    if(orgIdObj.orgId && orgIdObj.orgId!='undefined' ){
      // console.log('有值');
      localStorage.setItem("orgId", orgIdObj.orgId);
      that.orgId = orgIdObj.orgId;
    }else{
      // console.log('没值');
      orgIdObj.orgId = that.public.getConfig.defaultOrgId;
      localStorage.setItem("orgId", orgIdObj.orgId);
      that.orgId = orgIdObj.orgId;
    }
    // console.log('最终结果',that.orgId);
    if(that.orgId=="undefined"){
      return that.$toast('配置信息错误');
    }

    // 获取localStorage里面加密的账号密码
    var cipherText = localStorage.getItem("cipherText");
    var cipherPsw = localStorage.getItem("cipherPsw");

    // 如果已经记住密码，则解码显示出来
    if (cipherText) {
      that.select = true;
      var cipText = that.public.decrypt(cipherText);
      var cipPsw = that.public.decrypt(cipherPsw);
      that.accountName = cipText;
      that.accountPass = cipPsw;
      // 自动登录
      // setTimeout(function () {
      //   that.automaticLogin();
      // }, 1000);
    }
	// 获取logo信息
    that.getCompanyLogo();
    that.setClientType();
	that.isFirstLogin();
  },

 methods: {
	 /**
	  * 是否第一次打开app
	  */
	 isFirstLogin(){
		let that = this;
		let firstStart = localStorage.getItem("appFirst");
		if(firstStart == "false"){
			return;
		}
		localStorage.setItem("appFirst",false);
		Dialog.confirm({
		   title: '用户协议和隐私政策',
		   message:'    如亲爱的用户，感谢您使用'+that.typeName+'！我们非常重视您的个人信息和隐私安全。为了更好的保障您的个人权益，请您在使用前仔细阅读<a href=\"./serveAgreement\">《用户协议》</a>和<a href=\"./privacyPolicy\">《隐私政策》</a>并在您充分理解并同意全部内容后开始使用我们的产品和服务。我们将严格按照法律法规及协议政策约定收集、使用和保护您的个人信息，感谢您的信任！<br />*需要申请您的电话、存储、联网权限，便于您更快捷的登录。',
		   messageAlign: "left",
		   confirmButtonText:"同意",
		   confirmButtonColor: "#3399ff",
		   cancelButtonText: "拒绝",
		   cancelButtonColor: "#999"
		}).then(() => { // on confirm
			// that.checked = true;
			// that.onChange();
		}).catch(() => { // on cancel
			 // that.checked = false;
		});
	 },
    // 把app类型保存到本地
    setClientType() {
      var that = this;
      var auto = that.$route.query.auto;  // 退出登录
      
      var clientType = that.$route.query.clientType;
      if (!clientType) {
        if (that.IsMiniProgram()) {
			clientType = "applets";
        } else if (that.isWx()) {
			clientType = "weixin";
        } else if(that.getOSType()=="ios"){
			clientType = "ios";
		}else {
			// that.getOSType()=="android"
			clientType = "app";
		}
      }
      // 获取微信小程序的openid
      if (clientType == "applets") {
        that.Applets = that.$route.query.openid;
        localStorage.setItem("Applets", that.Applets);
      }
      // 获取微信公众号的openid
      if (clientType == "weixin") {
        // that.openid = that.$route.query.openid;
        // 调用微信授权，把openid存到本地
        if(auto == 0){    // 没有登录 微信授权
          that.public.getopenId();
        }else{
          var openid = that.$route.query.openid;
          localStorage.setItem("openid",openid);
        }
      }
      that.clientType = clientType;
    },
    // 小程序验证
    IsMiniProgram() {
      if (/MicroMessenger/i.test(navigator.userAgent)) {
        //ios的ua中无miniProgram，很坑爹,但都有MicroMessenger（表示是微信浏览器）
        this.wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            return true;
          } else {
            return false;
          }
        });
      }
      return false;
    },
    // 微信公众号验证
    isWx() {
      if (/MicroMessenger/i.test(navigator.userAgent)) {
        return true;
      }
      return false;
    },
	
	/**
	 * 判断浏览器类型：Android/iOS
	 */
	getOSType() {
		if (/(Android)/i.test(navigator.userAgent)) {
			return 'android';
		} else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
			return 'ios';
		}else{
			return null;
		}
	},
	
    // 自动登录
    automaticLogin() {
      var that = this;
      var orgId = localStorage.getItem("orgId");
      var data = {
        Mobile: that.accountName ? that.accountName : "",
        Password: that.accountPass ? that.accountPass : "",
        OrgId: orgId,
      };
      // 如果是微信小程序
      if (that.clientType == "applets" && that.Applets) {
        data.Applets = that.Applets;
      }
      // 微信openid
      that.openid = localStorage.getItem('openid');
      if(that.clientType == 'weixin' && that.openid){
        data.OpenId = that.openid;
      }
      that.$toast.loading({
        message: "登录中...",
        duration: 0,
      });
      that.public.request("post", "/api/AppLogin/Login", data, (res) => {
        that.$toast.clear();
        if (res.data.errorCode == "00") {
          sessionStorage.setItem("member_token", res.data.rows);
          localStorage.setItem("orgId", res.data.errorMsg);
          sessionStorage.setItem("customer", res.data.extend.customerId);
          localStorage.setItem("clientType", that.clientType); // app类型
          var webconfig = JSON.stringify(res.data.extend.webConfig);
          localStorage.setItem("webconfig", webconfig);
          // 暂时跳转到首页
          that.$router.push({
            path: "/",
          });
        } else {  
          that.$toast(res.data.errorMsg);
        }
      });
    },

    // 获取logo信息
    getCompanyLogo() {
      var that = this;
       that.$toast.loading({
			message: '加载中...',
			duration: 0,
		})
      that.public.request("get","/api/SysOrgInfo/QueryOrgWebConfig",{
          orgId: that.orgId,
		},(res) => {
          if (res.data.errorCode == "00") {
            that.logoInfo = res.data.rows;
            document.title = [res.data.rows.FullName].join("");
            if(res.data.rows.FullName){
              that.$toast.clear();
              that.isgoLoad=false;
              that.isGo=true;
            }else{
              that.$toast.clear();
               setTimeout(() => {
                 that.isgoLoad=true}, 300);
            }
            //判断IsGoHome的值，是否自动跳转
            if (that.logoInfo.IsGoHome) {
              if (that.junp == null) {
                that.$router.push({
                  path: "/",
                });
                that.junp= sessionStorage.setItem("junp", true);
              }
            }

            if (res.data && res.data.rows) {
              that.goOrder = res.data.rows.LoginOrder;
              localStorage.goOrder = res.data.rows.LoginOrder;
            }
            // console.log(that.logoInfo);
          }else{
            that.$toast(res.data.errorMsg);
          }
        }
      );
    },
    goLoadBtn(){
      var that=this;
      that.isgoLoad=false;
      that.getCompanyLogo();
    },

    //  手机 账号切换
    changeLogin() {
      var that = this;
      if (that.typeSign == "account") {
        that.typeSign = "phone";
      } else if (that.typeSign == "phone") {
        that.typeSign = "account";
      }
    },
    // 修改密码页面
    toforgetPass() {
      this.$router.push({
        path: "/forgetPass",
      });
    },
	
    // 记住密码
    selectBox() {
      var that = this;
      that.select = !that.select;
    },

    // 获取验证码
    getCode() {
      var that = this;
      var reg = /^1[0-9]{10}$/;

      if (!that.mobile) {
        that.$toast("手机号不能为空");
        return;
      }
      if (!reg.test(that.mobile)) {
        that.$toast("请输入正确的手机号");
        return;
      }
      var data = {
        Mobile: that.mobile,
      };

      that.public.request(
        'post','/api/AppCommon/IsMobileValid',data,res=>{
          // console.log(res);
          if(res.data.errorCode=='00'){
            that.$toast('手机号未注册，请先注册')
          }else if(res.data.errorCode=='99'){

            // 验证码倒计时
            var num = 60;
            var time = setInterval(function() {
              if (num == 0) {
                clearInterval(time);
                that.disabled = false;
                that.sendTime = "获取验证码";
              } else {
                num--;
                that.sendTime = num + "秒后重试";
                that.disabled = true;
              }
            }, 1000);
            that.public.request(
              "post",
              "/api/SysUserInfo/SendMobileCode",
              data,
              (res) => {
                // console.log(res);
                if (res.data.errorCode == "00") {
                  if (res.data.errorMsg == "success") {
                    that.$toast("验证码发送成功");
                  }
                } else if (res.data.errorCode == "99") {
                  that.$toast(res.data.errorMsg);
                }
              }
            );
          }
        }
      )

    },
  // 手机号登录
    mobileLogin() {
      var that = this;
      var orgId = localStorage.getItem("orgId");
      var data = {
        Mobile: that.mobile ? that.mobile : "",
        Code: that.verifiCode ? that.verifiCode : "",
        OrgId: orgId,
        IsNeedPassed:true//20211109新版移动端，短信验证码需要审核通过才能登录
      };

      if (!data.Mobile) {
        that.$toast("请输入手机号");
        return;
      }
      var reg = /^1[0-9]{10}$/;
      if (!reg.test(data.Mobile)) {
        that.$toast("请输入正确的手机号");
      }
      if (!data.Code) {
        that.$toast("请输入验证码");
        return;
      }
      // 如果是微信小程序
      if (that.clientType == "applets" && that.Applets) {
        data.Applets = that.Applets;
      }
      // 微信openid
      that.openid = localStorage.getItem('openid');
      if(that.clientType == 'weixin' && that.openid){
        // data.OpenId = that.openid;
      }

      that.$toast.loading({
        message: "登录中...",
        duration: 0,
      });
      // 旧的接口：/api/AppLogin/SmsLogin  
      that.public.request("post", "/api/AppLogin/SmsLogin", data, (res) => {
        // console.log(res);
        that.$toast.clear();
        if (res.data.errorCode == "00") {
              if( res.data.MultiData.errorCode=='98'&& res.data.MultiData.rows!=null && res.data.MultiData.rows.length>=1){
                sessionStorage.setItem("member_token",res.data.MultiData.rows[0].token);
                localStorage.setItem("orgId", res.data.MultiData.rows[0].SubOrgId);
              }else{
                sessionStorage.setItem("member_token", res.data.rows);
                localStorage.setItem("orgId", res.data.errorMsg);    
              }
              localStorage.setItem("provKey",res.data.TagData);     // 把key值存到本地，通过key值获取账号密码，下次进入，自动登录
              localStorage.setItem("mobile",data.Mobile);
              // sessionStorage.setItem("customer", res.data.extend.customerId);
              // var webconfig = JSON.stringify(res.data.extend.webConfig);
              // localStorage.setItem("webconfig", webconfig);
              localStorage.setItem("clientType", that.clientType); // app类型
              that.$toast("登录成功");
              setTimeout(() => {
                if(res.data.MultiData.errorCode!='00'){
                  that.$router.push({
                      path: '/',
                    });
                }else{
                  // localStorage.setItem('agencyList',JSON.stringify(res.data.MultiData.rows));
                  //  选择机构
                    that.$router.push({
                      path: '/selectCompany'
                    });
                }
              }, 100);
        } else if (res.data.errorCode == "99") {
          that.$toast(res.data.errorMsg);
        }else{
          that.$toast(res.data.errorMsg);
        }
      });
    },

    // 账号密码登录
    login() {
      var that = this;
      var orgId = localStorage.getItem("orgId");
	  if(!this.checked){
		return this.$toast("请勾选下面的相关协议及政策");
	  }
      // 账号密码登录
      if (that.typeSign == "account") {
        var data = {
          Mobile: that.accountName ? that.accountName : "",
          Password: that.accountPass ? that.accountPass : "",
          OrgId: orgId,
        };
        // 如果是微信小程序
        if (that.clientType == "applets" && that.Applets) {
          data.Applets = that.Applets;
        }
        // 微信openid
        that.openid = localStorage.getItem('openid');
        // console.log(that.openid);
        if(that.clientType == 'weixin' && that.openid){
          data.OpenId = that.openid;
        }
        
        var reg = /^1[0-9]{10}$/;
        if (!data.Mobile) {
          that.$toast("手机号不能为空");
          return;
        }
        // if(!reg.test(data.Mobile)){
        //   that.$toast('请输入正确的手机号');
        //   return;
        // }
        if (!data.Password) {
          that.$toast("请输入密码");
          return;
        }

        // 保存手机号，在APP.vue中调用，判断token是否过期
        var cipher = that.public.encrypt(data.Mobile, data.Password);
        localStorage.setItem("mobile", cipher.cipherText);
        localStorage.setItem("password", cipher.cipherPsw);

        // 登录加密，如果选中，则加密保存
        if (that.select) {
          var cipher = that.public.encrypt(data.Mobile, data.Password);
          localStorage.setItem("cipherText", cipher.cipherText);
          localStorage.setItem("cipherPsw", cipher.cipherPsw);
        } else {
          // 如果没有选中，就清除原有的
          localStorage.removeItem("cipherText");
          localStorage.removeItem("cipherPsw");
        }

        that.$toast.loading({
          message: "登录中...",
          duration: 0,
        });
        // console.log(data);
        that.public.request("post", "/api/AppLogin/Login", data, (res) => {
          that.$toast.clear();
          if (res.data.errorCode != "99") {
            //注册及下单 goOrder为true
              var logingoOrder = localStorage.getItem("goOrder"); //获取logingoOrder
              if (logingoOrder != "true") {  
                //为false或者null
                if (res.data.TagData != null) {
                  //TagData有值
                  var message = res.data.TagData.errorMsg;
                  that.$toast(message);
                  return false;
                }
              }

             if(res.data.MultiData && res.data.MultiData.errorCode=='98'&& res.data.MultiData.rows!=null && res.data.MultiData.rows.length>=1){
                sessionStorage.setItem("member_token",res.data.MultiData.rows[0].token);
                localStorage.setItem("orgId", res.data.MultiData.rows[0].SubOrgId);
            }else{
                  sessionStorage.setItem("member_token", res.data.rows);
                  localStorage.setItem("orgId", res.data.errorMsg);    
            }
			if(res.data.extend && res.data.extend.IsPush){
				localStorage.setItem("isPush",res.data.extend.IsPush); //个性化推荐
			}
			if(res.data.extend && res.data.extend.customerId){
				sessionStorage.setItem("customer", res.data.extend.customerId);
			}
			if(res.data.extend && res.data.extend.webConfig){
				var webconfig = JSON.stringify(res.data.extend.webConfig);
				localStorage.setItem("webconfig", webconfig);
			}
			if(res.data.extend && res.data.extend.IsSpecial){
				sessionStorage.setItem("IsSpecial",res.data.extend.IsSpecial);
			}
			
			localStorage.setItem("clientType", that.clientType); // app类型
			// localStorage.setItem('agencyList',res.data.MultiCustomer);
			if(res.data.MultiCustomer){//多个药店,先选择
				that.$router.push({
					path: '/selectCompany'
				});
			}else{
				that.$router.push({
				   path: '/',
				});
			}
          } else {
            that.$toast(res.data.errorMsg);
          }
        });
      }

     
    },
    /**
     * 注册
     */
    goregister() {
      var that = this;
      var logoInfo = that.logoInfo;
	  // that.$router.push({
	  //   path: "/reg",
	  // });
	  let ua = navigator.userAgent; //.toLowerCase();
	  let isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1; 
	  if(isAndroid && that.typeName == "药盟主"){
		  top.android.openRegist(); //调起app打开小程序
	  }else{
		  that.$router.push({
		    path: "/reg",
		  });
	  }
    },
    // 拨打电话
    callPhone(){
        var mobile = this.logoInfo.Mobile;
        window.location.href = 'tel:' + mobile;
    },
	
	// 是否阅读协议
	onChange(e){
		localStorage.setItem("agreement",this.checked);
	},
	//查看用户协议
	onAgreement(){
		this.$router.push({
		    path: '/serveAgreement'
		})
		// location.href = "./about/ServiceContract.html";
	},
	//查看隐私政策
	onPolicy(){
		this.$router.push({
		    path: '/privacyPolicy'
		})
		// location.href = "./about/PrivacyPolicy.html";
	}
	
  },
  computed:{
    // // 主题色图片修改
    // bgLoginImg(){
    //   // this.theme是文件夹，将不同的皮肤放到不同的文件夹，用同样的命名
    //   var themeImg = sessionStorage.getItem("themeImg");
    //   return require(`@/assets/images/${themeImg}/logo-background1.jpg`);
    // }
  },
};
</script>

<style scoped>
input::-webkit-input-placeholder {
  color: #b1aeae;
}
.logo-box {
  width: 100%;
  padding: 0.5rem 0;
  box-sizing: border-box;
}

.logo-box img {
  display: block;
  width: 3rem;
  margin: 0 auto;
}

.logo-box span {
  display: block;
  font-size: 0.3rem;
  color: #333;
  text-align: center;
}

/* 登录 */
.sign-info {
  width: 80%;
  margin: 0.6rem auto;
}

.sign-info .account-sign {
  margin-bottom: 0.36rem;
}

.sign-info .account-sign span {
  display: inline-block;
  width: 1.8rem;
  font-size: 0.36rem;
  text-align: left;
}

.sign-info .account-sign input {
  flex: 1;
  border: 0.02rem solid rgb(218, 215, 215);
  border-radius: 0.16rem;
  padding: 0.08rem 0.2rem;
  font-size: 0.28rem;
  height: 0.8rem;
}

.sign-info .account-sign .verifi-code {
  flex: 1;
}

.sign-info .account-sign .verifi-btn {
  width: auto;
  padding: 0 0.2rem;
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  background: var(--theme-color);
  font-size: 0.28rem;
  color: #fff;
  border-radius: 0.2rem;
  margin-left: 0.2rem;
}

.pass-box .select img {
  width: 0.36rem;
  height: 0.36rem;
  margin-right: 0.1rem;
}

.pass-box .select span {
  font-size: 0.28rem;
}

.pass-box .forget-pass {
  font-size: 0.28rem;
  color: #999;
}

.sign-btn {
  width: 100%;
  height: 0.85rem;
  text-align: center;
  line-height: 0.85rem;
  color: #fff;
  font-size: 0.3rem;
  background: var(--theme-color);
  border-radius: 0.2rem;
  margin-top: 0.6rem;
}

.phone-sign {
  font-size: 0.36rem;
  color: var(--theme-color);
  text-align: center;
  margin: 0.36rem 0 0;
}

.idition-text {
  font-size: 0.28rem;
  color: #999;
  margin: 0.8rem 0 1rem;
  text-align: center;
}

/* 登录页面的另一个样式 */
.logo-box {
  width: 100%;
  height: 4.9rem;
  /* background: url("../../assets/images/login/logo-background.jpg") no-repeat; */
  background-size: 100% 100%;
}
.logo-box .logo-img {
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 50%;
  /* border: 0.16rem solid #599cff; */
  border: 0.16rem solid var(--theme-color);
  margin: 0.3rem auto 0.16rem;
  background: #fff;
  box-sizing: border-box;
  position: relative;
  padding: 0.18rem;
  display: flex;
  align-items: center;
}
.logo-box .logo-img .logo-i {
  width: 100%;
  border-radius: 0.38rem;
}
.logo-box .logo-txt {
  font-size: 0.53rem;
  color: #fff;
  text-align: center;
}
.login-box {
  width: 100%;
  padding-top: 0.2rem;
  box-sizing: border-box;
}
.login-box .login-input {
  width: 80%;
  height: 0.85rem;
  margin: 0 auto 0.3rem;
  background: #fff;
  border-radius: 0.2rem;
}
.login-box .login-input .input-txt {
  width: 100%;
  height: 100%;
  padding: 0.1rem;
  box-sizing: border-box;
  font-size: 0.36rem;
  text-align: center;
}
.pass-box {
  width: 80%;
  margin: 0 auto 0.3rem;
}
.pass-box .pass-label {
  /* width:1.92rem; */
  height: 0.63rem;
  line-height: 0.63rem;
  background: #fff;
  font-size: 0.3rem;
  border-radius: 0.1rem;
  text-align: center;
  padding: 0 0.12rem;
  box-sizing: border-box;
}
.pass-box .pass-label img {
  width: 0.36rem;
  height: 0.36rem;
  margin-right: 0.2rem;
}
.pass-box .pass-label.blue {
  /* color: #3399ff; */
  color: var(--theme-color);
}
.login-btn {
  width: 80%;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  font-size: 0.48rem;
  color: #fff;
  /* background: #3399ff; */
  background: var(--theme-color);
  border-radius: 0.2rem;
  margin: 0 auto 0.3rem;
}
.short-message {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 0.36rem;
  /* color: #3399ff; */
  color: var(--theme-color);
}

.contain {
  position: relative;
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  /* padding-bottom: 0.8rem; */
}
.login-Register {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 0.36rem;
  color: #3399ff;
  margin-top: 3%;
}
.error-bg {
  background-color: rgba(189, 189, 189, 0.933);
}
.error-color {
  color: rgba(148, 148, 148, 0.933);
}

.goLoad {
  position: fixed;
  bottom: 5.6rem;
  left: 2.7rem;
  width: 1.22rem;
  padding: 0.57rem 0.5rem;
  background-color: #000000;
  opacity: 0.7;
  color: #f7f7f7;
  border-radius: 0.2rem;
}
.goLoad-item img {
  width: 0.67rem;
  padding-left: 0.15rem;
  padding-bottom: 0.1rem;
}

/* 新版 */
.mobile-font{
  width:100%;
  font-size:0.26rem;
  color:#999;
  display:flex;
  align-items:center;
  justify-content:center;
  margin-top:0.2rem;
}
.mobile-font img{
  width:0.4rem;
  margin-left:0.2rem;
}
.regis-btn{
  text-align:center;
  font-size:0.24rem;
  color:#999;
  margin:0.2rem 0;
}
.regis-btn .btn-b{
  display:inline-block;
  padding:0.06rem 0.2rem;
  color:#fff;
  background: var(--theme-color);
  border-radius:0.3rem;
  margin-left:0.2rem;
}
.regis-btn .btn-b.grey{
  background:#999;
}

.version{
	position: absolute;
	width: 100%;
	color: #666;
	font-size: 14px;
	bottom: 30px;
	text-align: center;
}
.check-box{
	/* position: absolute; */
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	/* bottom: 30px; */
}
.check-box .tag{
	color: #3399ff;
}
</style>
