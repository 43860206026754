<template>
    <div class="container">
        <!-- 商品列表 -->
        <div class="floor-box">
            <div class="floor-list flex-wrap" v-for="(item,index) in browseList" :key="index">
                <img class="item-img" v-if="!item.select" src="@/assets/images/cart/icon_nochecked.png" @click="selectCur(index)" alt="选择图标">
                <img class="item-img" v-if="item.select" src="@/assets/images/cart/icon_checked.png" @click="selectCur(index)" alt="选择图标">
                <div class="floor-item flex-wrap"  @click="toGoodsDetail">
                    <img class="floor-good" :src="item.SpPic" alt="商品图片">
                    <div class="floor-specifi">
                        <span class="specifi-name">{{item.SpName}}</span>
                        <span class="specifi-num">{{item.Style}}</span>
                        <span class="specifi-company">{{item.Factory}}</span>
                        <div class="flex-sp">
                            <div class="specifi-price-box">
                                <span class="specifi-price font-red">￥ {{item.Price}}</span>
                                <!-- <span class="specifi-old-price font-grey">￥ 6.00</span> -->
                            </div>
                            
                            <img @click.stop="onCartShow(item.Spid)" class="shop-cart" :src="themeImgObj.cartImg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="loading-box flex-center" v-if="noMore && browseList.length>0">
            <span class="load-txt">已经到底了！</span>
        </div>
        <!-- 加载样式 -->
        <div class="loading-box flex-center" v-if="showLoading && browseList.length>0">
            <img class="load-img" src="@/assets/images/index/loading.gif" alt="加载图片">
            <span class="load-txt">正在加载</span>
        </div>
        
        
        <!-- 操作 -->
        <div class="operate-height">
            <div class="operate-box flex-wrap1">
                <div class="but-item red" @click="deleteWhole">清空记录</div>
                <div class="but-item blue" @click="deleteSelectGood">删除</div>
            </div>
        </div>
        
        
        <!-- 暂无记录 -->
        <div class="no-data" v-if="browseList.length == 0">
            <img src="@/assets/images/nore.png" alt="暂无数据">
            <span>暂无浏览记录</span>
        </div>


        <!-- 商品购物车弹窗 -->
        <cart-pop :cartShowPop="cartShowPop" :goodInfo="goodInfo" @diffCartShow="closeCart"></cart-pop>
        
    </div>
</template>

<script>
import cartPop from '../../components/cartPop.vue';
export default {
    components:{
        cartPop,
    },
    data(){
        return{
            page: 1,
            noMore: false,
            showLoading: true,
            browseList: [],
            select: false,  // 选中状态  false未选中 true已选中
            ids: [],    // 选中商品ID的集合

            cartShowPop: false,     // 组件显示隐藏
            goodInfo: {},     // 向组件传递商品详情
            typePage:'',    // 向组件传递页面类型
        }
    },
    created(){
        
        var that = this;
        // 主题色图片
        that.themeImgObj = that.themeColorImg.imgObj;

        // 最近浏览的数据
        that.getBrowase();
    },
    mounted(){
        window.addEventListener('scroll', this.onReachBottom);
    },
    destroyed(){
        window.removeEventListener('scroll', this.onReachBottom);
    },
    

    methods:{
        
        // 购物车弹窗组件
        onCartShow(spid){
            var that = this;
            
            var data = {
                SpId: spid,
                OrgId: "",
                pihao: "",
            };
            that.public.request('post','/api/store/queryGoodsInfo',data,res => {
                if(res.data.errorCode == '00'){
                    var goodInfo = res.data.rows[0];
                    that.goodInfo = goodInfo;
                    that.cartShowPop = true;
                }
            })
            
            
        },
        // 关闭组件弹窗
        closeCart(){
            var that = this;
            that.cartShowPop = false;
        },



        // 跳转详情
        toGoodsDetail(){

        },
        // 选中
        selectCur(index){
            var that = this;
            if(!that.browseList[index].select){
                that.browseList[index].select = true;
                that.ids.push(that.browseList[index].Id);
            }else{
                that.browseList[index].select = false;
                for(var i=0; i<that.ids.length; i++){
                    if(that.ids[i] == that.browseList[index].Id){
                        that.ids.splice(i,1);
                        break;
                    }
                }
            }
        },
        // 清空记录 全部记录
        deleteWhole(){
            var that = this;
            that.$dialog.confirm({
                message: '确定清空记录吗',
            }).then(() => {
                // console.log('确定');
                var data = {}
                that.public.request('post','/api/GoodsBrowseHistory/ClearBrowseHistory',data,res => {
                    console.log(res);
                    if(res.data.errorCode == '00'){
                        that.getBrowase();
                    }
                })
            }).catch(() => {
                // console.log('取消');
            })
            
        },
        // 删除选中的id
        deleteSelectGood(){
            var that = this;
            var ids = that.ids.join(",");
            // console.log(ids);
            if(!ids){
                that.$toast("请选择需要删除的商品！");
                return;
            }
            that.$dialog.confirm({
                message: '确定删除吗',
            }).then(() => {
                // console.log('确定');
                
                var data = {
                    Ids: ids,
                }
                that.public.request('post','/api/GoodsBrowseHistory/DeleteBrowseHistory',data,res => {
                    console.log(res);
                    if(res.data.errorCode == '00'){
                        that.getBrowase();
                    }
                })
            }).catch(() => {
                // console.log('取消');
            })
            
        },

        // 浏览数据列表
        getBrowase(){
            var that = this;
            var page = that.page;
            var data = {
                page: page,
                rows: 10,
            }
             that.$toast.loading({
                message: '加载中',
                duration: 0
            })
            that.public.request('post','/api/GoodsBrowseHistory/QueryBrowseHistoryList',data,res => {
                that.showLoading = false;
                that.$toast.clear();
                if(res.data.errorCode == '00'){
                    var allArr = [];
                    var initArr = that.browseList;
                    var curArr = res.data.rows ? res.data.rows : [];
                    var lastPageLength = curArr.length;
                    if(page > 1){
                        allArr = initArr.concat(curArr);
                    }else{
                        allArr = res.data.rows;
                    }
                    // 选中状态
                    for(var i =0; i<allArr.length; i++){
                        allArr[i].select = false;   // false未选中  true已选中
                    }
                    

                    that.browseList = allArr;
                    if(lastPageLength < 10){
                        that.noMore = true;
                        that.showLoading = false;
                    }
                }else if(res.data.errorCode == '99'){
                    if(page <= 1){
                        that.browseList = [];
                        that.noMore = false;
                        that.showLoading = false;
                    }else{
                        that.noMore = true;
                        that.showLoading = false;
                    }
                }
            })
        },

        //到达底部
        onReachBottom(){
            var that = this;
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
            //变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
            //滚动条到底部的条件
   //          console.log('距顶部 =='+scrollTop);
			// console.log('可视区高度 =='+windowHeight);
			// console.log('滚动条总高度 =='+scrollHeight);
            if(scrollTop>0){
                if(scrollTop + windowHeight + 17 >= scrollHeight){
                    //写后台加载数据的函数
                    console.log("----------------");
                    // console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
                    // console.log(that.noMore);
                    var page = that.page;
                    var noMore = that.noMore;
                    page++;
                    that.page = page;
                    if(noMore){
                        // console.log('已经到底了');
                    }else{
						// console.log('加载更多');
                        that.getBrowase();
                    }
                }
            }

        },

    }

}
</script>


<style scoped>
    /* 商品列表页面 */
    .floor-box{ width:100%;padding:0.2rem;box-sizing:border-box; }
    .floor-list{ width:100%;padding:0.2rem;box-sizing:border-box;background:#fff;box-shadow:0 0 0.1rem #eee;border-radius:0.2rem;margin-bottom:0.28rem; }
    .floor-list .item-img{ width:0.36rem;height:0.36rem;margin-right:0.2rem; }
    .floor-list .floor-item{ flex:1;box-sizing:border-box; }
    .floor-list .floor-item .floor-good{ width:1.6rem;max-height:1.8rem;margin-right:0.2rem;object-fit: contain; }
    .floor-list .floor-item .floor-specifi{ position:relative; width:4.3rem;}
    .floor-list .floor-item .floor-specifi span{ display:block; }
    .floor-list .floor-item .floor-specifi span.specifi-name{ font-size:0.3rem;width:4rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-weight:600; }
    .floor-list .floor-item .floor-specifi span.specifi-num{ font-size:0.24rem; }
    .floor-list .floor-item .floor-specifi span.specifi-company{ font-size:0.28rem;width:4rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis; }
    .floor-list .floor-item .floor-specifi .specifi-price-box span{ display:inline-block; }
    .floor-list .floor-item .floor-specifi span.specifi-price{ font-size:0.32rem;margin-right:0.18rem; }
    .floor-list .floor-item .floor-specifi span.specifi-old-price{ font-size:0.22rem;text-decoration:line-through; }
    .floor-list .floor-item .floor-specifi .shop-cart{ width:0.36rem; }
    

    /* 操作 */
    .operate-height{ width:100%;height:1rem; }
    .operate-box{ position:fixed;left:0;bottom:0;z-index:1;width:100%;height:1rem;background:#fff; }
    .operate-box .but-item{ width:50%;height:100%;text-align:center;line-height:1rem;font-size:0.3rem;color:#fff; }
    .operate-box .but-item.red{ background:#f75c5c; }
    .operate-box .but-item.blue{ background:#2da5eb; }
</style>