<template>
  <div class="content">
	  <div class="info" v-if="reportForm">
	  	<p>药盟主{{month}}月{{day}}号交易</p>
		<span>共计： {{reportForm.OrderCount}}单 {{reportForm.SUMOrderCost}}元</span>
		
		<p>药盟主{{month}}月{{day}}号注册情况</p>
		<span>新增注册：{{reportForm.NewCount}}</span>
		<span>共计注册：{{reportForm.SumCount}}</span>
		
		<p>各部门邀约注册详情</p>
		<span v-for="item in reportForm.dayList">{{item.DeptName}}: 共计{{item.DeptCount}} 新增{{item.DeptTodayAdd}}</span>
		<!-- <table v-if="reportForm" border="0" cellspacing="0" cellpadding="6">
			<thead><td>部门</td><td>共计</td><td>新增</td></thead>
			<tr v-for="item in reportForm.dayList">
				<td>{{item.DeptName}}</td><td>{{item.DeptCount}}</td><td>{{item.DeptTodayAdd}}</td>
			</tr>
		</table> -->
		
		<p>各部门员工录入详情</p>
		<span>总计录入：{{reportForm.InputEmployeeCount}}</span>
		<span v-for="item in reportForm.inputList">{{item.DeptName}}: {{item.InputEmployee}}</span>
		
	  </div>
	<!-- <button id="copy" :data-clipboard-text="copyMsg" @click="handleCopy">复制</button> -->
  </div>
</template>

<script>
// import HelloWorld from '@/components/HelloWorld.vue'
import Clipboard from 'clipboard'
export default {
  name: 'app',
  components: {},
  data(){
	  return{
		  reportForm: null,
		  copyMsg: "复制内容"
	  }
  },
  computed:{
	  nowDate(){
		return this.formatDate()
	  },
	  month(){
		return this.formatDate("month")
	  },
	  day(){
	  	return this.formatDate("day")
	  }
  },
  created() {
	  this.queryRepotForm();
  },
  methods:{
	  /**
	   * 查询报表信息
	   */
	  queryRepotForm(){
		  let that = this;
		  this.public.request("post","api/WeChatRoom/QueryDayReport",{
		  },res=>{
			  if(res.data.errorCode == "00"){
				that.reportForm = res.data.rows;
			  }else{
				that.$toast(res.data.errorCode)
			  }
		  })
	
	  },
	  
	  formatDate(type){
		  let date = new Date()
		  let y = date.getFullYear();
		  let m = date.getMonth()+1;
		  let d = date.getDate();
		  let hh = date.getHours();
		  let mm = date.getMinutes();
		  let ss = date.getSeconds();
		  // let ymd = y + '-' + this._format(m) + '-' + this._format(d) + " " + this._format(hh) + ":" + this._format(mm) + ":" + this._format(ss)
		  let ymd = y + '-' + this._format(m) + '-' + this._format(d)
		  if(type == "month"){
			  return m;
		  }
		  if(type == "day"){
			return d;
		  }
		  return ymd;
		  
		  // var date = new Date();
		  // var month = date.getMonth() + 1;//当前月
		  // var year = date.getFullYear();//当前年(4位)
		  // date.getYear();//当前年(2位)
		  // date.getDate(); //获取当前日(1-31)
		  // date.getDay(); //获取当前星期X(0-6,0代表星期天)
		  // date.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
		  // date.getHours(); //获取当前小时数(0-23)
		  // date.getMinutes(); //获取当前分钟数(0-59)
		  // date.getSeconds(); //获取当前秒数(0-59)
		  // date.getMilliseconds(); //获取当前毫秒数(0-999)
		  // date.oLocaleDateString(); //获取当前日期
		  // date.toLocaleTimeString(); //获取当前时间
		  // date.toLocaleString( ); //获取日期与时间
		  // Math.floor( ( month % 3 == 0 ? ( month / 3 ) : ( month / 3 + 1 ) ) );//当前季度
		  // date.now();//当前时间戳
		  // date.parse(new Date());//获取的时间戳是把毫秒改成000显示
	  },
	  _format(num){
		  return num<10?"0"+num:num
	  },
	  
	  /**
	   * 复制
	   */
	  handleCopy(){
		let _this = this
		let clipboard = new Clipboard('#copy',{
			text: ()=>{
				return "复制内容"
			}
		})
		clipboard.on('success', function() {
		  _this.$toast('复制成功！')
		  // clipboard.destory()
		})
		clipboard.on('error', function() {
		  _this.$toast('该浏览器暂不支持自动复制！')
		  // clipboard.destory()
		})
	  }
	  
  }
}
</script>

<style scoped>
	.content{
		padding: 0 15px;
	}
	.info{
		min-height: 200px;
		margin: 10px 0;
		padding: 10px;
		border-radius: 6px;
		background: #f7f7f7;
		display: flex;
		flex-direction: column;
	}
	.info p{
		margin: 10px 0 0 0;
		font-size: 16px;
		font-weight: bold;
	}
	.info span{
		font-size: 15px;
		line-height: 25px;
	}
	thead{
		font-size: 14px;
		font-weight: bold;
		/* text-align: center; */
	}
	thead td{border-bottom: 1px #ccc solid;}
	tr{
		font-size: 14px;
		/* text-align: center; */
	}
</style>
