import { render, staticRenderFns } from "./seckillNew.vue?vue&type=template&id=536d7034&scoped=true"
import script from "./seckillNew.vue?vue&type=script&lang=js"
export * from "./seckillNew.vue?vue&type=script&lang=js"
import style0 from "./seckillNew.vue?vue&type=style&index=0&id=536d7034&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "536d7034",
  null
  
)

export default component.exports