<template>
    <div class="contain-box">
        <div class="favour-list" v-if="favourList.length > 0">
            <div class="brand-item">
                <div class="pack-list flex-wrap">
                    <div class="pack-item" v-for="(item,index) in favourList" :key="index" @click="toGoodDetail(item.spid)">
                        
                        <div class="item-img">
                            <img class="shop-img" v-if="item.SpPicExtend" :src="item.SpPicExtend" alt="">
                            <img class="shop-img" v-if="!item.SpPicExtend" src="@/assets/images/no_good.png" alt="">
                        </div>
                        <div class="item-info">
                            <span class="info-name">{{item.spmch}}</span>
                            <div class="info-wrap flex-sp">
                                <span class="info-specifi">{{item.shpgg}}</span>
                                
                            </div>
                            <!-- <div class="info-wrap flex-sp">
                                <span class="info-num">库存:<span class="font-red">100</span>盒</span>
                            </div> -->
                            <!-- <span class="info-company">医药有限公司</span> -->
                            <!-- <div class="info-time">有效期：2021-10-12</div> -->
                            <div class="flex-sp">
                                <span class="info-price font-red">￥ {{item.zdshj}}/{{item.dw}}</span>
                                <img class="shop-cart" v-if="item.kcshl>0" @click.stop="onCartShow(item.spid)" src="@/assets/images/index/main-gwc.png" alt="">
                                <img class="shop-cart" v-if="item.kcshl<=0" src="@/assets/images/index/main-gwc_.png" alt="">
                            </div>
                            
                            
                        </div>
                        <div class="info-active flex-wrap">
                            <div class="active-box" v-for="(activeItem,i) in item.ActivityCacheDataList" :key="i">{{activeItem.ActivityTypeName}}</div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

        <div class="loading-box flex-center" v-if="noMoreTip && favourList.length >5">
            <span class="load-txt">已经到底了！</span>
        </div>

        <!-- 购物车弹窗 -->
        <cart-pop :cartShowPop="cartShowPop" :goodInfo="goodInfo" @diffCartShow="closeCart"></cart-pop>
    </div>
</template>

<script>
// import favourRequest from '../../../public/api/favourable';
import favourRequest from '@/assets/js/api/favourable';
import cartPop from '../../components/cartPop.vue';
export default {
    components:{
        cartPop,
    },
    data(){
        return{
            favourList:[],      // 列表数据
            noMoreTip: false,

            cartShowPop: false,     // 组件弹窗显示隐藏
            goodInfo: {},           // 组件商品信息
        }
    },
    created(){
        var that = this;
        var type = that.$route.query.type;
        that.getFavourList(type);
    },
    methods:{
        // 组件购物车弹窗
        onCartShow(spid){
            var that = this;
            var orgId = localStorage.orgId;
            var data = {
                SpId: spid,
                OrgId: orgId,
            }
            that.public.request('post','/api/Store/QueryGoodsInfo',data,res => {
                console.log(res);
                if(res.data.errorCode == '00'){
                    that.goodInfo = res.data.rows[0];
                    that.cartShowPop = true;
                }
            })
        },
        // 关闭组件弹窗
        closeCart(){
            var that = this;
            that.cartShowPop = false;
        },

        // 去详情页面
        toGoodDetail(spid){
            var that = this;
            that.$router.push({
                path: '/goodsDetail',
                query: {
                    spid: spid,
                }
            })
        },

        // 请求列表数据
        getFavourList(type){
            var that = this;
            var data = {
                offerType: type,
            };
            favourRequest.activeList(data,res => {
                if(res.data.errorCode == '00'){
                    var list = res.data.rows;
                    var allArr = new Array;
                    list.forEach((item,i) => {
                        item.ListGoodsOfferExtend.forEach((item1,j) => {
                            allArr.push(item1);
                        })
                    })
                    that.noMoreTip = true;
                    that.favourList = allArr;
                }else{
                    that.noMoreTip = false;
                }
            })
        }
    }
}
</script>

<style scoped>
    /* 列表 */
    .pack-list{ width:100%;padding:0.18rem 0.2rem;box-sizing:border-box;flex-wrap:wrap;align-items:inherit; }
    .pack-list .pack-item{ width:48.3%;box-shadow:0 0 0.1rem #d1d0d0;margin-right:0.2rem;margin-bottom:0.2rem;background:#fff;padding:0.18rem;box-sizing:border-box; }
    .pack-list .pack-item:nth-child(2n){ margin-right:0; }
    .pack-list .pack-item .item-info .info-wrap .info-num{ font-size:0.24rem;display:inline; }
    .pack-list .pack-item .item-info .info-wrap .info-num span{ display:inline; }
    .pack-list .pack-item .item-info .info-time{ font-size:0.22rem; }

    .pack-item .item-img{ width:100%; height:2.7rem; }
    .pack-item .item-img  .shop-img{ width:100%; max-height:100%;object-fit: contain; }
    .pack-item .item-img .item-setime{ position:absolute; left:3%; bottom:0; right:0; padding:0.02rem 0.2rem; background:#199ed8; border-radius:0.28rem; width:80%; }
    .pack-item .item-img .item-setime img{ width:0.22rem; height:0.22rem; margin-right:0.18rem; }
    .pack-item .item-img .item-setime .settime-tit{ color:#fff; font-size:0.24rem;}
    .pack-item .item-info { width:100%; box-sizing:border-box;position:relative; }
    .pack-item .item-info span{ display:block; font-size:0.26rem; }
    .pack-item .item-info span.info-name{ width:100%; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;font-weight:600; }
    .pack-item .item-info span.info-specifi{ font-size:0.24rem; }
    .pack-item .item-info span.info-company{ font-size:0.26rem; width:100%; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;font-weight:600; }
    .pack-item .item-info span.info-price{ font-size:0.32rem; }
    .pack-item .item-info span.info-old-price{ font-size:0.22rem; text-decoration:line-through; }
    .pack-item .item-info .shop-cart{ width:0.36rem; }
    .pack-item .info-active{ width:100%;margin-top:0.1rem;flex-wrap:wrap; }
    .pack-item .info-active .active-box{ display:inline-block;padding:0.02rem 0.08rem;font-size:0.24rem;color:#ff1515;border:0.02rem solid #ff1515;border-radius:0.12rem;margin-right:0.1rem;margin-bottom:0.1rem; }
    .pack-item .info-active .active-box:nth-child(){ margin-bottom:0; }
</style>