<template>
    <div class="container">
        <!-- 备案信息 -->
        <div class="record-info bor-bot2">
            <div class="info-tit font-red">医药信息监管要求，收货地址需是ERP备案的收货地址</div>
            <div class="info-t">备案信息：</div>
            <div class="info-name flex-wrap">
                <span>备案收货人：</span>
                <span class="info-r">{{recordConsignee}}</span>
            </div>
            <div class="info-name info-start flex-wrap1">
                <span>地址：</span>
                <span class="info-r">{{recordAddress}}</span>
            </div>
        </div>
        <!-- 新增收货地址 -->
        <div class="address-but" @click="address">新增收货地址</div>
        <!-- 收货地址列表 -->
        <div class="add-list">
            <div class="add-item flex-wrap" v-for="(item,index) in addressList" :key="index" @click="selectCur(index)">
                <img class="item-select" v-if="!item.selected" src="@/assets/images/cart/icon_nochecked.png"  alt="">
                <img class="item-select" v-if="item.selected" src="@/assets/images/cart/icon_checked.png"  alt="">
                <div class="item-right">
                    <div class="right-name">
                        <span>{{item.Consignee}}</span>
                        <span>{{item.Mobile}}</span>
                    </div>
                    <div class="right-address">{{item.Address}}</div>
                    <div class="right-set flex-wrap">
                        <div class="set-default" @click.stop="selectCur(index)">设为默认地址</div>
                        <div class="set-but">
                            <span class="orange" @click.stop="toEditAdd(index)">编辑</span>
                            <span class="red" @click.stop="deleteAddress(item.Id)">删除</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="add-item flex-wrap">
                <img class="item-select" src="@/assets/images/cart/icon_checked.png" alt="">
                <div class="item-right">
                    <div class="right-name">
                        <span>王晓明</span>
                        <span>123456789987</span>
                    </div>
                    <div class="right-address">河南省郑州市金水区龙子湖街道龙子湖地铁站地铁站地铁站</div>
                    <div class="right-set flex-wrap">
                        <div class="set-default">设为默认地址</div>
                        <div class="set-but">
                            <span class="orange" @click="toEditAdd">编辑</span>
                            <span class="red">删除</span>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            addressList: [],
            recordConsignee:'',
            recordAddress:'',
            addressId: '',
            token:'',
        }
    },
    created(){
        var that = this;
        // 获取地址的id
        that.addressId = that.$route.query.addressId;
        that.token=sessionStorage.getItem('member_token')
        that.getCustomerInfo();
        that.getAddress();
    },
    methods:{
		// 选择地址
		selectCur(index){
			var that = this;
			var addressId = that.addressList[index].Id;
			that.addressList.forEach((item,index1) => {
				item.selected = false;	// 地址不选择
				that.addressList[index].selected = true;
				localStorage.setItem('addressId',addressId);
			})
            // that.$router.replace({
            //     path:'/waitBuyPay',
            //     // query: {
            //     //     addressId: addressId,
            //     // }
            // })
			that.$router.go(-1);
			
		},
        // 删除地址
        deleteAddress(id){
            var that = this;
			var addressId = that.addressId;
            that.$dialog.confirm({
                message: '确定删除吗'
            }).then(() => {
                // 确定
                var data = {
                    id: id,
                }
                that.public.request('post','/api/ReceiveAddress/Delete',data,res => {
                    console.log(res);
                    if(res.data.errorCode == '00'){
						if(id == addressId){
							localStorage.removeItem('addressId');
						}
                        that.getAddress();
                    }
                })
            }).catch(() => {
                // 取消
            })
            
        },

        // 获取地址列表
        getAddress(){
            var that = this;
            var data = {}
			var addressId = that.addressId;
            if(!that.token){
                return false;
            }
            that.public.request('get','/api/ReceiveAddress/GetAddress',data,res => {
                if(res.data.errorCode == '00'){
                    var allArr = res.data.rows;
                    for(var i=0; i< allArr.length; i++){
                        allArr[i].selected =false;
						if(allArr[i].Id == addressId){
							allArr[i].selected = true;
						}
                    }
                    that.addressList = allArr;
                }
            })
        },
        // 编辑地址
        toEditAdd(index){
            var that = this;
            that.$router.push({
                path: '/editAddress',
            })
            var address = JSON.stringify(that.addressList[index]);
            localStorage.setItem('addressDetail',address)
        },
        // 新增收货地址
        address(){
            var that = this;
            if(!that.token){
                return that.$toast('请先登录，再新增')

            }
            that.$router.push({
                path: '/editAddress',
            })
        },
        //读取企业备案信息
        getCustomerInfo(){
            var that=this;
            var customerId = sessionStorage.getItem("customer");
            if(!customerId)
            {
                return;
            }
             that.public.request('post','/api/SysOrgInfo/QueryOrgInfo',{Id:customerId},res => {
                if(res.data.errorCode == '00' && !!res.data.rows){
                  that.recordConsignee=res.data.rows.OrgName;
                  that.recordAddress=res.data.rows.Address;
                }
            })
        },
    }
}
</script>


<style scoped>
    /* 备案收货信息 */
    .record-info{ width:100%;padding:0.2rem 0.2rem;box-sizing:border-box; }
    .record-info .info-tit{ font-size:0.3rem; }
    .record-info .info-t{ font-size:0.3rem; }
    .record-info .info-name{ width:100%; }
    .record-info .info-name.info-start{ align-items:flex-start; }
    .record-info .info-name span:first-child{ width:1.8rem;font-size:0.28rem; }
    .record-info .info-name .info-r{ flex:1;font-size:0.28rem;color:#666; }


    /* 新增收货地址 */
    .address-but{ width:70%;height:0.7rem;text-align:center;line-height:0.7rem;color:#fff;font-size:0.3rem;background:var(--theme-color);border-radius:0.2rem;margin:0.3rem auto 0.3rem; }


    /* 地址列表 */
    .add-list{ width:100%;padding:0 0.2rem;box-sizing:border-box; }
    .add-list .add-item{ width:100%;padding:0.2rem;box-sizing:border-box;background:#fff;border-radius:0.2rem;box-shadow:0 0 0.1rem #eee;margin-bottom:0.18rem; }
    .add-item .item-select{ width:0.36rem;margin-right:0.18rem; }
    .add-item .item-right{ flex:1; }
    .add-item .item-right .right-name span{ font-size:0.32rem;font-weight:600;margin-right:0.3rem; }
    .add-item .item-right .right-address{ font-size:0.28rem;color:#666;margin-bottom:0.08rem; }
    .add-item .item-right .right-set .set-default{ font-size:0.28rem;color:#23a2f7;flex:1; }
    .add-item .item-right .right-set .set-but span{ display:inline-block;width:1.1rem;height:0.4rem;text-align:center;line-height:0.4rem;font-size:0.28rem;border-radius:0.18rem;margin-left:0.2rem; }
    .add-item .item-right .right-set .set-but span.orange{ background:#f5a81b;color:#fff; }
    .add-item .item-right .right-set .set-but span.red{ background:#f34510;color:#fff; }
</style>