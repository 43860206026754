
import router from '@/router'
// 跳转活动详情
function jumpPage(goodInfo,id){
    if(id=='1'){
     return   router.push({
            path: '/activeDetail',
            query:{
                type: 'seckill',
                spid: goodInfo.GoodsId,
                id: goodInfo.Id,
            }
        })
    }  
    if(id=='3'){
        return   router.push({
               path: '/activeDetail',
               query:{
                   type: 'bargain',
                   spid: goodInfo.GoodsId,
                   id: goodInfo.Id,
               }
           })
       }
    var ActivityType = goodInfo.CurActivityData.ActivityType;
    var CurActivityData = goodInfo.CurActivityData;
    if(ActivityType == 0){ // 团购
        router.push({
            path: '/activeDetail',
            query:{
                type: 'group',
                spid: goodInfo.spid,
                id: CurActivityData.ActivityId,
            }
        })
    }else if(ActivityType == 1){  // 秒杀
        router.push({
            path: '/activeDetail',
            query:{
                type: 'seckill',
                spid: goodInfo.spid,
                id: CurActivityData.ActivityId,
            }
        })
    }else if(ActivityType == 2){   // 今日特价
        router.push({
            path: '/activeDetail',
            query:{
                type: 'bargain',
                spid: goodInfo.spid,
                id: CurActivityData.ActivityId,
            }
        })
    }else if(ActivityType == 9){   // 新人特价
        router.push({
            path: '/activeDetail',
            query:{
                type: 'new_bargain',
                spid: goodInfo.spid,
                id: CurActivityData.ActivityId,
            }
        })
    }else if(ActivityType == 3){  // 集采
        router.push({
            path: '/activeDetail',
            query:{
                type: 'purch',
                spid: goodInfo.spid,
                id: CurActivityData.ActivityId,
            }
        })
    }
}

export default {
    jumpPage,
}