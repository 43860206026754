<template>
  <div class="container">
    <div class="maincontent">
		<p style="text-align: center;"><span>{{subName}}隐私政策</span></p>
		<p style="margin: 0;font-size: 13px;color: #666;">协议发布时间：2022年6月17日</p>
		<p style="margin: 0;font-size: 13px;color: #666;">协议生效时间：2022年6月17日</p>
		
		<p><span>欢迎使用{{subName}}的产品或服务！{{subName}}的产品或服务是由{{companyName}}（注册地：{{companyAddr}}）通过但不限于{{subName}}官网、{{subName}}APP、相关微信开放平台账号或者小程序以及随技术发展可能出现的其他新形态等途径向您提供的产品或服务。</span></p>
		
		<p><span>{{subName}}（以下或称“我们”）尊重并保护用户（以下或称“您”）的隐私。在您使用{{subName}}各项服务时，我们将按照{{subName}}隐私权政策（以下简称“本政策”）收集、存储、使用及对外提供您的个人信息。同时，我们会通过本政策向您说明，我们如何为您提供访问、更新、管理和保护您的信息的服务。本政策与您使用各项服务关系紧密，我们建议您在使用各项服务之前仔细阅读并理解本政策全部内容，做出您认为适当的选择。我们努力用通俗易懂、简明扼要的文字表达，并对本政策中与您的权益存在重大关系的条款，采用下划线或加粗方式进行标注，您应重点阅读，在充分理解并同意后使用相关产品或服务，一旦您开始使用{{subName}}各项产品或服务，即表示您已充分理解并同意本政策。</span></p>
		
		<p><span>需要特别说明的是，本政策不适用于其他第三方向您提供的服务，第三方向您提供的服务适用其向您说明的隐私权政策。</span></p>
		
		<p><span>我们希望通过本《隐私政策》向您说明我们在收集和使用您相关个人信息时对应的处理规则，以及我们为您提供的访问、更正、删除和保护这些个人信息的方式，以便更好的保障您的权益。</span></p>
		
		<p>&nbsp;本《隐私政策》将帮助您了解以下内容：</p>
		
		<p>一、我们如何收集和使用您的个人信息</p>
		
		<p>二、我们如何使用COOKIES或同类技术</p>
		
		<p>三、我们如何保留、储存和保护您的个人信息安全</p>
		
		<p>四、如何管理您的个人信息</p>
		
		<p>五、第三方服务</p>
		
		<p>六、通知和修订</p>
		
		<p>七、如何联系我们</p>
		
		<p>一、我们如何收集和使用您的个人信息</p>
		
		<p>我们收集您的个人信息主要是为了您和其他用户能够更容易和更满意地使用{{subName}}各项产品或服务。而这些个人信息有助于我们实现这一目标。</p>
		
		<p>（一）我们将通过以下途径收集和获得您的个人信息：</p>
		
		<p>1.您提供的个人信息。</p>
		
		<p>（1）您在登录使用{{subName}}时，向我们提供的个人信息；</p>
		
		<p>（2）您通过{{subName}}向其他方提供的共享个人信息，以及您使用{{subName}}时所储存的个人信息。
		    请注意，如您在{{subName}}中其他用户可见的公开区域内上传或发布的个人信息中、您对其他人上传或发布的信息作出的回应中公开您的个人信息，该等个人信息可能会被他人收集并加以使用。当您发现他人不正当地收集或使用您的个人信息时，您可以通过本《隐私政策》第七条【如何联系我们】约定的联系方式联络我们。</p>
		
		<p>2、其他方分享的您的个人信息。亦即其他方使用{{subName}}时所提供有关您的共享个人信息。</p>
		
		<p>3、我们获取的您的个人信息。您在使用{{subName}}时，我们收集、汇总、记录的个人信息。</p>
		
		<p>（二）我们会出于以下目的，收集和使用您以下类型的个人信息：</p>
		
		<p>1、帮助您登录</p>
		
		<p>为便于我们为您提供{{subName}}完整的服务，您需要提供基本登录个人信息，包括手机号码。</p>
		<!-- 在{{subName}}登录或后续使用过程中，如果您提供其他额外个人信息<span>（包括您的昵称、头像、真实姓名、性别）</span>，将有助于我们给您提供个性化的推荐和更优的服务体验，但如果您不提供这些个人信息，并不会影响您使用{{subName}}的基本功能。 -->
		
		<p>2、维护基础功能的正常运行</p>
		
		<p>在您使用我们服务过程中，为识别账号异常状态、了解产品适配性，向您提供浏览、搜索等基本服务，维护基础功能的正常运行，我们可能会自动收集、储存关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：</p>
		
		<p>（1）日志信息：当您使用我们的服务时，我们可能会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。日志信息包括<span>您的登录账号、搜索查询内容、IP地址、浏览器的类型、电信运营商、网络环境、使用的语言、访问日期和时间及您访问的网页浏览记录、Push打开记录、崩溃记录、停留时长、刷新记录、发布记录、关注、订阅、收藏及分享。</span></p>
		
		<p>（2）设备信息：我们可能会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息<span>（包括IMEI、MAC、UUID、Serial、SIM卡IMSI识别码、设备机型、操作系统及版本、客户端版本、设备分辨率、包名、设备设置、进程及软件列表、唯一设备标识符、软硬件特征信息）、设备所在位置相关信息（包括IP 地址、GPS位置以及能够提供相关信息的WLAN接入点、蓝牙和基站传感器信息）。</span></p>
		               
		<p>为了系统更好的兼容，我们引用了[TBS腾讯浏览服务、腾讯浏览服务、腾讯X5浏览器、腾讯浏览器、腾讯x5浏览器内核]，第三方SDK也会收集一些相关信息，详细见《隐私政策》第五条【第三方服务】；同时为了收集上述基本的个人设备信息，我们将会申请访问您的设备信息的权限，我们收集这些信息是为了向您提供我们基本服务和基础功能，如您拒绝提供上述权限将可能导致您无法使用我们的产品与服务。</p>
		
		<p>请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。</p>
		
		<p>4、向您提供服务</p>
		
		<p>（1）个人信息的分享与发布：您在使用{{subName}}服务时、上传和/或发布个人信息以及进行相关行为（例如您在{{subName}}课程或直播中发言、分享）时，我们将收集您上传、发布或形成的个人信息，并有权展示您的昵称、头像和发布内容。</p>
		
		<p>（2）意见反馈：当您联系我们的用户响应功能时（包括提出意见反馈），我们可能需要您提供必要的个人信息以匹配并核验您的用户身份，以便保障您的账号与系统安全。我们可能还会保存您的联系方式（您与我们联系时使用的或您向我们主动提供的其他联系方式）、您与我们的通信/通话记录和内容、与您需求相关联的其他必要信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</p>
		
		<p>5、我们通过间接方式收集到的您的个人信息：</p>
		
		<p><span>我们可能从管理方、第三方合作伙伴获取您授权共享的相关个人信息。我们可能从第三方获取您授权共享的账户个人信息（头像、昵称、登录时间）并在您同意本《隐私政策》后将您的第三方账户与您的{{subName}}账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。</span>我们将在符合相关法律法规规定，并依据与关联方或第三方合作伙伴的约定、确信其提供的个人信息来源合法的前提下，收集并使用您的这些个人信息。</p>
		            
		
		<p>6、为您提供安全保障</p>
		
		<p>为提高您使用我们及合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或{{subName}}服务相关协议规则的情况，我们可能会收集、使用或整合您的账户信息、交易信息、设备信息、日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的个人信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</p>
		
		<p>7、改进我们的服务</p>
		
		<p>我们可能将通过某一项{{subName}}服务所收集的个人信息，用于我们的其他服务：在您使用某一项{{subName}}服务时所收集的您的个人信息，可能在另一项{{subName}}服务中用于向您提供特定内容或向您展示与您相关的、而非普遍推送的信息；我们可能让您参与有关{{subName}}服务的调查，帮助我们改善现有服务或设计新服务；同时，我们可能将您的个人信息用于软件更新。</p>
		
		<p>您了解并同意，在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的个人信息将无法识别您的身份，在此情况下我们有权使用已经去标识化的个人信息，对用户数据库进行分析并予以商业化的利用。</p>
		
		<p>8、其他用途</p>
		
		<p>请您注意，如果我们要将您的个人信息用于本《隐私政策》中未载明的其他用途或额外收集未提及的其他个人信息，我们会另行事先请您同意（确认同意的方式：勾选、弹窗、站内信、邮件、短信方式）。一旦您同意，该等额外用途将视为本《隐私政策》的一部分，该等额外个人信息也将适用本《隐私政策》。</p>
		
		<p>9、征得授权同意的例外</p>
		
		<p>根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：</p>
		
		<p>（1）与国家安全、国防安全有关的；</p>
		
		<p>（2）与公共安全、公共卫生、重大公共利益有关的；</p>
		
		<p>（3）与犯罪侦查、起诉、审判和判决执行等有关的；</p>
		
		<p>（4）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</p>
		
		<p>（5）所收集的个人信息是您自行向社会公众公开的；</p>
		
		<p>（6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
		
		<p>（7）根据您的要求签订合同所必需的；</p>
		
		<p>（8）用于维护{{subName}}服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
		
		<p>（9）为合法的新闻报道所必需的；</p>
		
		<p>（10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
		
		<p>（11）法律法规规定的其他情形。</p>
		
		<p>10、您理解并同意，{{subName}}服务可能需要您在您的设备中开启特定的访问权限，以实现这些权限所涉及个人信息的收集和使用：</p>
		
		<p>（1）如我们访问您的位置信息，是为了根据您的位置信息为您提供更契合您需求的页面展示和产品服务；</p>
		
		<p>（2）如我们访问您的摄像头，是为了使您可以使用摄像头进行扫码、拍摄，用于实现登录、观看视频功能；</p>
		
		<p>（3）如我们访问您的相册，是为了使您可以实现您设备中的照片、图片或视频的取用与上传，便于您进行更换头像、发表评论/分享；</p>
		
		<p>（4）如我们访问您的麦克风，是为了使您可以实现语音评论以及语音教学交互功能，在这些功能中我们会收集您的录音内容以识别您的具体需求；</p>
		
		<p>（5）您开启通知权限后，是为了使您及时接收到我们的课程更新提醒、直播提醒；</p>
		
		<p>（6）您使用{{subName}}服务中的课程服务，可能会生成视频缓存文件，我们会申请访问该视频缓存文件，是为了使您更为流畅的体验我们的课程服务或为您提供课程离线观看功能。
		    当您需要关闭该功能时，大多数移动设备都会支持您的这项需求，具体方法请参考或联系您移动设备的服务商或生产商。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的个人信息收集及使用。</p>
		
		<p>11、有关敏感个人信息的提示</p>
		
		<p>以上由您提供或我们收集您的个人信息中，可能包含您的个人敏感信息，例如系统账号、电话号码、网页浏览记录、位置信息。请您谨慎并留意个人敏感信息，您同意您的个人敏感信息我们可以按本《隐私政策》所述的目的和方式来处理。</p>
		
		<p>二、我们如何使用COOKIES或同类技术</p>
		
		<p>（一）COOKIES</p>
		
		<p>我们或我们的第三方合作伙伴可能通过COOKIES获取和使用您的个人信息，并将该等个人信息储存为日志信息。</p>
		
		<p>我们使用COOKIES是为了让您可以受益。比如，为使得{{subName}}的登录过程更快捷，您可以选择把用户名存在一个COOKIES中。这样下次当您要登录{{subName}}的服务时能更加方便快捷。然而，您应该能够控制COOKIES是否以及怎样被你的浏览器接受,我们引用了[TBS腾讯浏览服务、腾讯浏览服务、腾讯X5浏览器、腾讯浏览器、腾讯x5浏览器内核],请查阅相关浏览器附带的文件以获得更多这方面的个人信息。</p>
		
		<p>我们和第三方合作伙伴可能通过COOKIES收集和使用您的个人信息，并将该等个人信息储存。</p>
		
		<p>我们使用自己的COOKIES，可能用于以下用途：</p>
		
		<p>（1）记住您的身份。COOKIES有助于我们辨认您作为我们的注册用户的身份；</p>
		
		<p>（2）分析您使用我们服务的情况。我们可利用COOKIES来了解您使用{{subName}}APP服务进行什么活动、或哪些服务或服务最受欢迎，以便我们优化我们的服务；</p>
		

		<p><span>二、我们可能分享、转让或披露的个人信息</span></p>
		
		<p>（一）分享</p>
		
		<p>除以下情形外，未经您同意，我们不会与我们及我们的关联方之外的任何第三方分享您的个人信息：</p>
		
		<p>1、向您提供我们的服务。我们可能向合作伙伴及其他第三方分享您的个人信息，以实现您需要的核心功能或提供您需要的服务。</p>
		
		<p>2、维护和改善我们的服务。我们可能向合作伙伴及其他第三方分享您的个人信息，以帮助我们为您提供更有针对性、更完善的服务；</p>
		
		<p>3、实现本《隐私政策》第一条“我们如何收集和使用您的个人信息”部分所述目的；</p>
		
		<p>4、履行我们在本《隐私政策》或我们与您达成的其他协议中的义务和行使我们的权利；</p>
		
		<p>5、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他{{subName}}用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换个人信息。不过,这并不包括违反本《隐私政策》中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息</p>
		
		<p>6、应您合法需求，协助处理您与他人的纠纷或争议；</p>
		
		<p>7、应您的监护人合法要求而提供您的个人信息；</p>
		
		<p>8、根据与您签署的单项服务协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</p>
		
		<p>9、基于学术研究而提供；</p>
		
		<p>10、基于符合法律法规的社会公共利益而提供。</p>
		
		<p>我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息。对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本《隐私政策》以及其他任何相关的保密和安全措施来处理个人信息。</p>
		
		<p>（二）转让</p>
		
		<p>1、<span>随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您的个人信息有可能作为此类交易的一部分而被转移。</span>我们会要求新的持有您个人信息的公司、组织继续受本《隐私政策》的约束，否则,我们将要求该公司、组织重新向您征求授权同意。</p>
		
		<p>2、在获得您的明确同意后，我们会向其他方转让您的个人信息。</p>
		
		<p>（三）披露</p>
		
		<p>我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的个人信息：</p>
		
		<p>1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</p>
		
		<p>2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露个人信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的个人信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。</p>
		
		<p>（四）分享、转让、披露个人信息时事先征得授权同意的例外</p>
		
		<p>以下情形中，分享、转让、披露您的个人信息无需事先征得您的授权同意：</p>
		
		<p>1、与国家安全、国防安全有关的；</p>
		
		<p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
		
		<p>3、与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</p>
		
		<p>4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
		
		<p>5、您自行向社会公众公开的个人信息；</p>
		
		<p>6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
		<p>根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</p>
		
		<p>三、我们如何保留、储存和保护您的个人信息安全</p>
		
		<p>（一）我们仅在本《隐私政策》所述目的所必需期间和法律法规及监管规定的时限内保存您的个人信息。如我们终止服务或运营，我们将及时停止继续收集您个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理，法律法规或监管部门另有规定的除外。</p>
		
		<p><span>（二）我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内。以下情形除外：</span></p>
		
		<p>1、法律法规有明确规定；</p>
		
		<p>2、获得您的授权同意；</p>
		
		<p>3、您使用{{subName}}服务，且需要向境外传输您的个人信息完成交易的；</p>
		
		<p>针对以上情形，我们会确保依据本《隐私政策》及国家法律法规要求对您的个人信息提供足够的保护。</p>
		
		<p>四、如何管理您的个人信息</p>
		
		<p>我们鼓励您更新和修改您的个人信息以使其更准确有效，也请您理解，<span>您更正、删除、撤回授权或停止使用{{subName}}服务的决定，并不影响我们此前基于您的授权而开展的个人信息处理。</span>除法律法规另有规定，当您更正、删除您的个人信息时，我们可能不会立即从备份系统中更正或删除相应的个人信息，但会在备份更新时更正或删除这些个人信息。</p>
		
		<p>您可以通过以下方式来管理您的个人信息：</p>
		
		<p>（一）访问、更正和删除您的个人信息</p>
		
		<p>您能通过{{subName}}服务访问您的个人信息，并根据对应个人信息的管理方式自行完成或要求我们进行修改、补充和删除。我们将采取适当的技术手段或提供提交申请的联系渠道，尽可能保证您可以访问、更新和更正自己的个人信息或使用{{subName}}服务时提供的其他个人信息。
		    在访问、更正和删除前述个人信息时，我们可能会要求您进行身份验证，以保障个人信息安全。对于通过COOKIES或同类技术收集的您的个人信息，我们还在本《隐私政策》第二条“我们如何使用COOKIES或同类技术”部分说明了向您提供的选择机制。如果您无法通过上述路径访问、更正该等个人信息，您可以通过本《隐私政策》第七条【如何联系我们】约定的联系方式与我们取得联系。对于您在使用{{subName}}服务过程中产生的其他个人信息需要访问、更正或删除，我们会根据本《隐私政策》所列明的方式、期限及范围来响应您的请求。在部分个人信息删除时，我们可能会要求您进行身份验证，以保障个人信息安全。</p>
		
		<p>在以下情形中，您可以向我们提出删除个人信息的请求，<span>在您提出更正、删除个人信息及注销用户帐号后，我们将在15个工作日内完成核查和处理：</span></p>
		
		<p>1、如果我们处理个人信息的行为违反法律法规；</p>
		
		<p>2、如果我们收集、使用您的个人信息，却未征得您的授权同意；</p>
		
		<p>3、如果我们处理个人信息的行为严重违反了与您的约定；</p>
		
		<p>4、如果我们不再为您提供{{subName}}服务。</p>
		
		<p>若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。</p>
		

		<p>（二）改变您授权同意的范围</p>
		
		<p>您总是可以选择是否披露个人信息。有些个人信息是使用{{subName}}服务所必需的，但大多数其他个人信息的提供是由您决定的。您可以通过删除个人信息、关闭设备功能等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。</p>
		
		<p>当您撤回授权后，我们无法继续为您提供撤回授权所对应的服务，也不再处理您相应的个人信息。但您撤回授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</p>
		            
		<p>五、第三方服务</p >
        <p>为保障{{subName}}的产品与/或服务的相关功能的实现与应用安全稳定的运行，我们可能会接入由第三方提供的软件开发包（SDK）实现相关目的。我们会对合作方获取信息的软件工具开发包（SDK）进行严格的安全评估，以保护您的个人信息安全。以下是我们接入的主要的第三方SDK类服务商的信息：</p >
        <p> SDK 名称：微信openSDK</p >
        <p> 合作方：腾讯科技（深圳）有限公司</p > 
        <p> 合作目的：第三方支付服务</p >  
        <p> 个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息</p >  
        <p> 个人信息收集范围：设备MAC地址、唯一设备标识码、位置信息、应用列表</p >  
        <p> 隐私政策：https://open.weixin.qq.com/cgi-bin/frame?t=news/protocol_developer_tmpl</p >  
        <p> SDK 名称：支付宝SDK   com.alipay(支付宝;阿里乘车码;阿里芝麻信用实名认证;芝麻认证)]</p >  
        <p> 合作方：蚂蚁金服网络技术有限公司</p >  
        <p> 合作目的：使用支付宝授权在app内进行商品支付</p >  
        <p> 个人信息收集方式：嵌入第三方SDK，SDK收集传个人信息、设备信息</p >  
        <p> 个人信息收集范围：系统运营信息、网络状态信息、iOS广告标识符（IDFA）、MAC地址、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、国际移动用户识别码（IMSI）、应用列表信息、基站信息、社交平台OpenID、地理位置</p >  
		<p> 隐私政策：https://render.alipay.com/p/c/k2cx0tg8</p >
        <p> SDK简称：[TBS腾讯浏览服务、腾讯浏览服务、腾讯X5浏览器、腾讯浏览器、腾讯x5浏览器内核] </p >
        <p> 合作方：深圳市腾讯计算机系统有限公司  </p >
        <p> 合作目的：移动浏览场景体验优化</p >
        <p> 个人信息收集方式：嵌入第三方SDK，SDK收集传个人信息、设备信息</p >
        <p> 个人信息收集范围：常用设备信息（如IMEI/IMSI、SIM卡序列号/MAC地址、android_id）、设备制造商、网络类型、网络状态、手机操作系统、屏幕尺寸、ip地址、在后台运行中获取IMSI,MAC</p >
        <p> 隐私政策：https://x5.tencent.com/tbs/guide/develop.html#5</p >
        <p>为了您的信息安全，我们已与第三方SDK服务商签署严格数据安全保密协议，这些公司会严格遵守我们的数据隐私和安全要求。除非得到您的同意，我们不会与其共享您的个人身份信息。</p >
        <p>如上所述服务由相关的第三方负责运营。您使用该等第三方服务（包括您向该等第三方提供的任何个人信息），须受第三方自己的服务条款及个人信息保护声明（而非本政策）约束，您需要仔细阅读其条款。我们仅会出于正当、必要、特定的目的共享您的信息。我们会要求他们履行相关保密义务并采取相应的安全措施。</p >
		          
		<p>六、通知和修订</p>
		
		<p>我们可能适时修改本《隐私政策》的条款，该等修改构成本《隐私政策》的一部分。对于重大变更，我们会提供更显著的通知，您如果不同意该等变更，可以选择停止使用{{subName}}服务；如您仍然继续使用{{subName}}服务的，即表示同意受经修订的本《隐私政策》的约束。</p>
		            
		<p>我们的任何修改都会将您的满意度置于首位。我们鼓励您在每次使用{{subName}}服务时都查阅我们的隐私政策。</p>
		 
		<p>我们可能在必需时（例如当我们由于系统维护而暂停某一项服务时）发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。</p>
		            
		<p>最后，您必须对您的账号和密码信息负有保密义务。任何情况下，请小心妥善保管。</p>
		
		<p>七、如何联系我们</p>
		            
		<p>当您对本协议有任何疑问、投诉、建议或需要注销个人账号（删除账号下所有访问记录）时，您可以联系您的服务专员或致电联系我们400-676-9991 我们管理人员会根据您的反馈与建议，尽快审核所涉问题，并在验证您的用户身份，一般情况下，我们将在15个工作日内完成核查和处理，来全面保障您的隐私安全问题。</p>
		
		<p>如果您对本协议内容有任何疑问，请致电联系我们400-676-9991</p>
		            
		<p>感谢您花时间了解我们的隐私政策！我们将尽全力保护您的个人信息和合法权益，再次感谢您的信任！</p>
    </div>
  </div>
</template>

<script>
	export default{
		data(){
			return{
				subName: window.baseUrl.titleName,
				companyName: window.baseUrl.companyName,
				companyAddr: window.baseUrl.companyAddr
			}
		},
		beforeRouteEnter(to, from, next) {
			if(location.href.indexOf('type=xcx') != -1){
				to.meta.isTop = true; //小程序隐藏标题栏
			}else{
				to.meta.isTop = false;
			}
		    next((vm) => {
				if(from.path=="/"){
					window.history.back();
				}
		    });
		}
	}
</script>

<style scoped>
	body {
	    background-color: #F2F2F2;
	    font-family: PingFangSC;
	    color: #4A4A4A;
	}
	.maincontent {
	    background-color: #fff;
	    padding: 10px;
	}
	.maincontent p {
		color: #000;
		font-size: 14px;
	}
	.maincontent p span{
		font-weight: bold;
	}
	
</style>
