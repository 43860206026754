var imgObj = {
    loginBackImg: '',       // 登录也背景图
    
}
function themeImg(color){
    // console.log(color);
    if(color == '#D5251D'){     // 京东红
        // console.log("-------红色-----");
        // 首页图片
        imgObj.loginBackImg = require(`@/assets/images/theme_red/logo-background.jpg`);
        imgObj.loginMobile = require(`@/assets/images/theme_red/icon_mobile.png`);
        // 底部导航图片
        imgObj.tabbarIndex = require(`@/assets/images/theme_red/tabbar/icon_index_d.png`);
        imgObj.tabbarFl = require(`@/assets/images/theme_red/tabbar/3.1.png`);
        imgObj.tabbarSearch = require(`@/assets/images/theme_red/tabbar/icon_search_d.png`);
        imgObj.tabbarCart = require(`@/assets/images/theme_red/tabbar/icon_cart_d.png`);
        imgObj.tabbarMine = require(`@/assets/images/theme_red/tabbar/icon_mine_d.png`);
        // 个人中心
        imgObj.mineTopBg = require(`@/assets/images/theme_red/mine/bg.png`);
        imgObj.mineDfk = require(`@/assets/images/theme_red/mine/icon_dfk.png`);
        imgObj.mineYfh = require(`@/assets/images/theme_red/mine/icon_yfh.png`);
        imgObj.mineYqx = require(`@/assets/images/theme_red/mine/icon_yqx.png`);
        imgObj.mineYsh = require(`@/assets/images/theme_red/mine/icon_ysh.png`);
        imgObj.mineYyd = require(`@/assets/images/theme_red/mine/icon_yyd.png`);
        // 分类页面
        imgObj.classCart = require(`@/assets/images/theme_red/icon_cart.png`);
        // 购物车图标
        imgObj.cartImg = require('@/assets/images/theme_red/icon_add_cart.png');
        imgObj.cartImgGwc = require('@/assets/images/theme_red/v2_gwc.png');
        // 收藏图标
        imgObj.collectImg = require('@/assets/images/theme_red/feedback_staractive.png');
        // 购物车选择图标
        imgObj.selectImg = require('@/assets/images/theme_red/icon_checked1.png');
        
    }else if(color == '#00a2ea'){     // 蓝色
        // console.log("蓝色");
        // 首页图片
        imgObj.loginBackImg = require(`@/assets/images/theme_blue/logo-background.jpg`);
        imgObj.loginMobile = require(`@/assets/images/theme_blue/icon_mobile.png`);
        // 底部导航图片
        imgObj.tabbarIndex = require(`@/assets/images/theme_blue/tabbar/icon_index_d.png`);
        imgObj.tabbarFl = require(`@/assets/images/theme_blue/tabbar/3.1.png`);
        imgObj.tabbarSearch = require(`@/assets/images/theme_blue/tabbar/icon_search_d.png`);
        imgObj.tabbarCart = require(`@/assets/images/theme_blue/tabbar/icon_cart_d.png`);
        imgObj.tabbarMine = require(`@/assets/images/theme_blue/tabbar/icon_mine_d.png`);
        // 个人中心
        imgObj.mineTopBg = require(`@/assets/images/theme_blue/mine/bg.jpg`);
        imgObj.mineDfk = require(`@/assets/images/theme_blue/mine/icon_dfk.png`);
        imgObj.mineYfh = require(`@/assets/images/theme_blue/mine/icon_yfh.png`);
        imgObj.mineYqx = require(`@/assets/images/theme_blue/mine/icon_yqx.png`);
        imgObj.mineYsh = require(`@/assets/images/theme_blue/mine/icon_ysh.png`);
        imgObj.mineYyd = require(`@/assets/images/theme_blue/mine/icon_yyd.png`);
        // 分类页面
        imgObj.classCart = require(`@/assets/images/theme_blue/icon_cart.png`);
        // 购物车图标
        imgObj.cartImg = require('@/assets/images/theme_blue/icon_add_cart.png');
        imgObj.cartImgGwc = require('@/assets/images/theme_blue/v2_gwc.png');
        // 收藏图标
        imgObj.collectImg = require('@/assets/images/theme_blue/feedback_staractive.png');
        // 购物车选择图标
        imgObj.selectImg = require('@/assets/images/theme_blue/icon_checked.png');
    }else if(color == '#38b9b9'){ //良品铺子
		imgObj.loginBackImg = require(`@/assets/images/theme_38b9b9/logo-background.jpg`);
		imgObj.loginMobile = require(`@/assets/images/theme_38b9b9/icon_mobile.png`);
		// 底部导航图片
		imgObj.tabbarIndex = require(`@/assets/images/theme_38b9b9/tabbar/icon_index_d.png`);
		imgObj.tabbarFl = require(`@/assets/images/theme_38b9b9/tabbar/3.1.png`);
		imgObj.tabbarSearch = require(`@/assets/images/theme_38b9b9/tabbar/icon_search_d.png`);
		imgObj.tabbarCart = require(`@/assets/images/theme_38b9b9/tabbar/icon_cart_d.png`);
		imgObj.tabbarMine = require(`@/assets/images/theme_38b9b9/tabbar/icon_mine_d.png`);
		// 个人中心
		imgObj.mineTopBg = require(`@/assets/images/theme_38b9b9/mine/bg.jpg`);
		imgObj.mineDfk = require(`@/assets/images/theme_38b9b9/mine/icon_dfk.png`);
		imgObj.mineYfh = require(`@/assets/images/theme_38b9b9/mine/icon_yfh.png`);
		imgObj.mineYqx = require(`@/assets/images/theme_38b9b9/mine/icon_yqx.png`);
		imgObj.mineYsh = require(`@/assets/images/theme_38b9b9/mine/icon_ysh.png`);
		imgObj.mineYyd = require(`@/assets/images/theme_38b9b9/mine/icon_yyd.png`);
		// 分类页面
		imgObj.classCart = require(`@/assets/images/theme_38b9b9/icon_cart.png`);
		// 购物车图标
		imgObj.cartImg = require('@/assets/images/theme_38b9b9/icon_add_cart.png');
		imgObj.cartImgGwc = require('@/assets/images/theme_38b9b9/v2_gwc.png');
		// 收藏图标
		imgObj.collectImg = require('@/assets/images/theme_38b9b9/feedback_staractive.png');
		// 购物车选择图标
		imgObj.selectImg = require('@/assets/images/theme_38b9b9/icon_checked.png');
	}
}
var themeImgObj = {
    imgObj,
    themeImg,
}

export default themeImgObj;