<template>
  <div>
     <div class="item"> 
         <div class="tel flex-wrap">手机号:<input type="number" class="input" v-model="tel"  placeholder="请输入您的手机号"></div>
        <div>
             <div class="bind" @click="bindBtn">确认绑定</div>
        </div>
         
    </div>
  </div>
</template>

<script>
export default {
    data () {
        return {
            tel:'',
            openId:'',
            orgId:'',
            roundId:'',
        }
    },
    created () {
             var that = this;
			var params=that.public.urlObj(location.href);
			that.openId=params.openId;
			that.orgId=params.OrgId;
			that.roundId=params.roundId;
			console.log(params);
    },
    methods: {
        bindBtn(){
            var that=this;
            console.log(that.tel);
            if (that.tel=='') {
                that.$toast("手机号不能为空");
                return;
            }
            var data = {
                Mobile: that.tel, 
                orgId:that.orgId,
            };
            

        }
    }

}
</script>


<style scoped>
.item{font-size: .4rem;}
.tel{margin: 3.5rem 0 2rem 1.2rem;}
.input{width: 3.6rem;height: .8rem;border: .02rem solid black !important;padding-left: .2rem;}
.bind{color: white;background-color: rgb(48, 129, 223);padding: .15rem 1.5rem;  text-align: center;margin: 0 1rem;border-radius: 0.1rem;}

</style>