<template>
  <div >
       <div class="good-popup" v-if="isSpecification" @click="closePop()"></div>
         <div v-if="isSpecification" class="guiGe-pop"  :class="bottomHight?'hight':''">
            <div class="good-top flex-wrap" >   
                <img :src="guiGeDetail.SpPicExtend"  onerror="javascript:this.src='https://goodssuffix.oss-cn-qingdao.aliyuncs.com/upload/c5c2ae08dbfb45898d64061a0d82e83e/9cc5bc0235044c54916fd18243082d10.png'" />
                
                <div class="guiGe-detail flex-fc">
                    <span>{{guiGeDetail.spmch}}</span>
                    <span class="font-red">￥{{guiGeDetail.zdshj}}</span>
                </div>

            </div>
            <div class="guiGeList">
                    <div v-for="(item,key1) in guiGeDes" :key='key1' class="guiGe-item">
                        <div>
                            <div class="guiGe-del flex-wrap" >
                                <div class="flex-fc item-detail">
                                    <span>规格:{{item.shpgg}}</span>
                                    <span class="font-red">￥{{item.zdshj}}</span>
                                </div>
                                
                               <div class="flex-wrap">
                                  <div>库存:{{item.kcshl>0?"有货":"无货"}}</div> 
                                  <div class="numbox">
                                        <button class="reduce numbtn" @click="reduceBtn(key1,item)" >-</button>
                                        <input type="number" class="number"   v-model="item.num" @blur="sureEdit(key1,item)" >
                                        <button class="add numbtn" @click="addBtn(key1,item)">+</button>
                                  </div>
                                </div>
                             </div>
                        </div>
                       <div class="active">
                            <div v-if="item.ActivityCacheDataList.length>0"  >
                                <label v-for="(item2,ind) in item.ActivityCacheDataList" :key="ind"> 
                                    <input v-if="ind==0"  type="radio" :value="item2.ActivityId" :name='key1' checked @click="activity(key1,item2.ActivityId)">
                                    <input  type="radio" v-else  :value="item2.ActivityId" :name='key1'   @click="activity(key1,item2.ActivityId)" >
                                    {{item2.Description}}
                                </label>
                            </div>
                           
                       </div>
                 </div>
        </div>
         <div class="good-bottom " :style="bottomHight?'bootom:1rem':'.15rem'" >
             <div class="cat" @click="addCatBtn">{{btnText}}</div>
           
         </div>
       </div>
    
  </div>
</template>

<script>
export default {
    props:{
        isSpecification:Boolean,//弹窗显示隐藏 
        guiGeDetail:Object,
        guiGeDes:Object,
        guiGeType:Boolean,
        bottomHight:Boolean,
    },
    data () {
        return {
            number:0,
            guiGeList:{},
            Setp:0,
            buyList:[],
			
			btnText: "加入购物车"
        }
    },
	watch: {
		guiGeDetail: function(){
			let that = this;
			let goodsId = that.guiGeDetail.spid;
			// console.log("=====此商品id=====",goodsId)
			if(that.guiGeDetail.IsBuy){
				that.btnText = "加入购物车";
			}else{
				that.btnText = "申请合作";
			}
			//创建浏览记录
			that.createHistory(goodsId);
		}
	},
    created () {
		
    },
    methods: {
		//添加记录浏览量
		createHistory(goodSpid){
		  this.public.request("post","api/GoodHistory/CreateHistory",{
			  SpId: goodSpid, //（商品id）
			  CustomId: localStorage.getItem("orgId")  //（登陆客户orgid）
		  },res=>{
			  if(res.data.errorCode=="00"){
				console.log("浏览记录成功!")
			  }else{
				console.log("浏览记录失败~")
			  }
		  })
		},
		
        activity(spid,id){
            var that =this;

            var obj=that.guiGeDes;
            console.log(that.guiGeDes);
            for(var i in obj ){
                if(i==spid){
                    obj[i].ActivityCacheDataList.forEach(item=>{
                        if(item.ActivityId==id){
                           obj[i].ActivityId=item.ActivityId;
                           obj[i].ActivityType=item.ActivityType;
                           obj[i].Description=item.Description;
                        }
                    })
                    
                }

            }
            that.guiGeDes=obj;
        },
        // 关闭弹窗
        closePop(){
            var that = this;
            that.$emit("isGuiGeShow");
        },
        //加
        addBtn(id,item){
           var that=this;
           var obj=that.guiGeDes;
           if(that.guiGeDetail.OnlyPackageSold==true && that.guiGeDetail.OrderNumSetp>0){
               that.Setp=that.guiGeDetail.OrderNumSetp;
           }

           for(var i in obj ){
              if(i==id){
                  if(obj[i].kcshl<=obj[i].num){
                      that.$toast('超出库存量')
                  }else{
                      if( that.Setp>0){
                          obj[i].num=obj[i].num+that.Setp;
                      }else{
                          obj[i].num++;
                         
                      }
                      
                  }
              }
           }

           that.guiGeDes=obj;
           this.$forceUpdate();//强制更新
        },
        // 减
        reduceBtn(id,item){
            var that=this;
            var obj=that.guiGeDes;
             if(that.guiGeDetail.OnlyPackageSold==true && that.guiGeDetail.OrderNumSetp>0){
               that.Setp=that.guiGeDetail.OrderNumSetp;
              }
            for(var i in obj ){
              if(i==id){
                  if(obj[i].num==0){
                      return false;
                  }else{
                     if(that.Setp>0){
                          obj[i].num=obj[i].num-that.Setp;
                      }else{
                          obj[i].num--;
                      }
                  }
              }
           }
            that.guiGeDes=obj;
           this.$forceUpdate();//强制更新
        },
        //修改input框
        sureEdit(id,item){
           var that=this;
            var obj=that.guiGeDes;
             if(that.guiGeDetail.OnlyPackageSold==true&&that.guiGeDetail.OrderNumSetp>0){
               that.Setp=that.guiGeDetail.OrderNumSetp;
           }
            for(var i in obj ){
              if(i==id){
                  if(obj[i].num<0){
                      obj[i].num=0;
                  }else if(obj[i].num>obj[i].kcshl){
                      obj[i].num=obj[i].kcshl;
                      that.$toast('超出库存量')
                  }else if(obj[i].num==''){
                      obj[i].num=0;
                  }
                // 如果orgId 不是瑞龙的，则只能输入整数
            	  var orgId = localStorage.orgId;
				  if(orgId != 'd3d3041db35d42f39443e9e1e9d74410'){
					var regNum =  /^\+?[1-9][0-9]*$/;
					if(!regNum.test(obj[i].num)){
                       
						that.$toast('请输入大于0的整数');
                        
						obj[i].num=0;
					}
				  }
              
				
                  if(that.Setp>0){
                      if(obj[i].num%that.Setp !=0){
                          that.$toast("购买数量，必须为"+that.Setp+"的倍数");
                          obj[i].num=0;
                      }
                  }
                //   console.log( obj[i].num);
              }
           }
            that.guiGeDes=obj;
           this.$forceUpdate();//强制更新
        },
        //加入购物车
        addCatBtn(){
            var that=this;
			
			if(!that.guiGeDetail.IsBuy){
				if(!that.guiGeDetail.BuyPhone){
					return that.$toast("暂无联系方式");
				}
				
				that.$dialog.confirm({
				    message: "您即将拨打电话："+that.guiGeDetail.BuyPhone,
					confirmButtonText: "呼叫"
				}).then(() => {//confirm
					window.location.href = 'tel://' + that.guiGeDetail.BuyPhone
				}).catch(() => {
					//cancel
				});
				return;
			}
			
			// 查看是否登录
			var token = sessionStorage.getItem('member_token');
			if(token == null){
			    var orgId = localStorage.getItem("orgId");
			    that.$dialog.confirm({
						message: "你还未登录，是否登录!",
					}).then(() => {
						that.$router.push({
			                path: '/login',
			                query: {
			                    orgId: orgId,
			                    auto: 0,
			                }
			            });
					}).catch(() => {
						// console.log('取消');
					});
			    return false;
			}
			
			//统计分析 埋点进来
			that.keyId = ""; //主键
			that.public.setBuryPoint(5,1,that.keyId).then(res=>{
				// if(res.errorCode=="00"){
				// 	that.keyId = res.rows;
				// }
			});
			
            that.$toast.loading({
                 message: "正在加入",
            });
               var obj=that.guiGeDes;
               var list=[];
               //判断有没有商品
                for(var i in obj ){
                    if(obj[i].num>0){
                        if(obj[i].num>=that.guiGeDetail.MinBuyCount){
                            list.push(obj[i].num);
                        }
                    }
                }
                if(list.length==0){
                    that.$toast('购买数量无效');
                }

               for(var i in obj ){
                  if(obj[i].num>0){
                    if(obj[i].num>=that.guiGeDetail.MinBuyCount){
                      // console.log();
                    console.log(obj[i]);
                    var data={
                        GoodsCount: obj[i].num,
                        Spid:i,
                        GoodsPrice:obj[i].zdshj,
                        ReceiveId:that.guiGeDetail.OrgId,
                        OfferId:obj[i].ActivityId,
                        OfferRemark:obj[i].Description, 
                        OfferType:obj[i].ActivityType,
                    }
					
					if(that.guiGeDetail.CrossGroupId){
						data.TmsCrossGroupId = that.guiGeDetail.CrossGroupId;
					}
                    // console.log(data);
                    that.public.request('post',
                    'api/SellWellCar/AddSellWellCar',
                    data,
                    (res)=>{
                        if(res.data.errorCode=='00'){
                            that.$toast('加入成功');
                             that.$emit("isGuiGeShow");
                             that.public.getCartNum();

                        }else{
                            that.$toast(res.data.errorMsg);
                        }
                    }
                    )
                   }else{
                       that.$toast('购买数量 ,不能小于'+that.guiGeDetail.MinBuyCount);
                   }
                  }
               }
        }

        
    }

}
</script>

<style scoped>
  .good-popup{ position:fixed;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,0.5);z-index:33 }
  .guiGe-pop{ position:fixed;left:0;bottom:0rem;z-index:44;background:#fff;width:100%;  height: 9rem ;}
  .good-top{position: fixed; width: 100%;margin: 0.2rem 0; border-bottom: .04rem  solid rgba(230, 229, 229, 0.8);}
  .good-top img{ width:1.8rem;max-height:1.8rem;margin-right:0.2rem; margin-left: 0.5rem;}
  .flex-fc{display: flex;flex-direction: column;align-items: center;}
  .guiGe-detail{font-size: .35rem}
  .guiGe-detail {align-items: flex-start;}
  .guiGe-detail span:nth-child(1){margin-bottom: .2rem ; font-size: .36rem ;}
  .font-red{color: red;}
  .good-bottom{position: fixed;border-radius: 0.35rem; box-sizing: border-box;color: white; display: flex;font-size: .28rem;text-align: center;background-color: rgb(56, 151, 240);}
  .good-bottom{height: .8rem ;  line-height: .8rem ;  margin:  .2rem ; padding: 0 2.8rem ;}
  .guiGeList{ font-size: .28rem;overflow-y: scroll;height: 5.8rem ; margin: 2rem .2rem 0 .2rem;}
  .numbox{margin-left: .2rem;}
  .numbox input{  width: .5rem; height:  .5rem;   border-top:.02rem solid rgb(187, 186, 186);  border-bottom:.02rem solid rgb(187, 186, 186);  vertical-align: top;}
  .numbtn{width:  .5rem;height:  .5rem; border: .02rem  solid rgb(187, 186, 186);background-color: rgb(247, 247, 247);font-size: .28rem ;color: rgb(138, 138, 138); line-height: 0.5rem;  }
  .duihuan{position: absolute;  bottom: 1rem ;  width: 80%;   margin: 0 .8rem ;  background-color: #1ABC9C;  text-align: center; line-height: .8rem ; border-radius: 0.4rem; color: white;}
  .number{  padding-left: .15rem ;}
  .guiGe-del{justify-content: space-between;}
  .guiGe-item{border-bottom: .04rem  solid rgba(230, 229, 229, 0.8);padding: .2rem 0; }
  .item-detail{align-items: flex-start;margin-left: .2rem;}
  .item-detail span:nth-child(1){margin-bottom: .05rem;}
  .active{color: red; font-size: .2rem; padding: .1rem 0 0 .2rem;}
 .hight{bottom: 1.1rem;}
 .active div{display: flex; flex-direction: column;}
  
</style>