<template>
    <div class="container-box">
        <!-- 商品详情 -->
		<div class="good-list">
			<div class="good-item flex-wrap" v-for="(goodItem,index) in listGoods.orderDetails" :key="index">
                <!-- 普通商品 -->
				<div>
					<div class="flex-wrap"> 
						<div class="item-left">
							<img class="left-img" v-if="goodItem.SpPic" :src="goodItem.SpPic" alt="商品图片">
							<img class="left-img" v-if="!goodItem.SpPic" src="@/assets/images/loseImg.png" alt="商品图片">
						</div>
						<div class="item-info flex-column">
							<div class="info-name">{{goodItem.SpName}}</div>
							<div class="info-specifi">{{goodItem.Style}}/{{goodItem.dw}}</div>

							<div class="info-company">{{goodItem.Factory}}</div>
							<div class="info-seck flex-sp">
								<div class="info-price">
									<span class="info-tit font-red">￥ {{goodItem.Price}}</span>
									<!-- <span class="info-old-price font-grey">￥ 6.00</span> -->
								</div>
								<div class="seck-btn">x {{goodItem.Total}}</div>
							</div>

						</div>
						
					</div>
					<!-- <div v-if="goodItem.Gifts!=null">
						<div class="gift-box"  v-for="(gift,j) in goodItem.Gifts" :key="j">【赠品】 {{gift.SpName}} {{gift.Style}} &nbsp;&nbsp; {{gift.Price}} ×{{gift.Total}}</div>
					</div> -->
				</div>
				
			</div>
			
		</div>

        <!-- 支付 -->
        <div class="pay-but">
            <div class="pay-total">
                合计：<span class="price-red">￥{{totalPrice}}</span>元
            </div>
            <div class="but-confirm" :class="payBoolean?'':'but-grey'" @click="confirmPay">{{payBoolean?'确定支付':'正在支付'}}</div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            listGoods:[],
            key: '',        // 路由上的key值
            extend: {},     // extend里面有token,orgid,customerid,
            payBoolean: true,
            pointNum:2,//小数点
        }
    },
    created(){
        var that = this;
        that.key = that.$route.query.key;
        that.getGoodList();
        var shuju=JSON.parse(localStorage.getItem('webconfig'))
           if(shuju&&shuju.GoodPriceFixed){
            that.pointNum=shuju.GoodPriceFixed;
             }

    },
    mounted(){
        var that = this;
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js';
        document.body.appendChild(s);
        
        
        
    },
    methods:{
        // 由于js的载入是异步的，所以可以通过该方法，当AlipayJSBridgeReady事件发生后，再执行callback方法
        tradePayReady(tradeNO){
            var that = this;
            if (window.AlipayJSBridge) {
                that.tradePay(tradeNO);
            } else {
                document.addEventListener('AlipayJSBridgeReady', that.tradePay(tradeNO), false);
            }
        },
        // 新版林创支付宝支付
        tradePay(tradeNO){
            var that = this;
            AlipayJSBridge.call("tradePay", {
                tradeNO: tradeNO,
            }, function (res) {
                if ("9000" == res.resultCode) {  // 支付成功
                    console.log("支付成功");
                    that.$toast("支付成功");
                    setTimeout(function(){
                        window.AlipayJSBridge.call('closeWebview');
                    },2000)
                }else if(res.resultCode == "6001"){   // 用户中途取消
                    window.AlipayJSBridge.call('closeWebview');
                }else if(res.resultCode == '6002'){   // 网络链接出错
                    that.$toast("网络连接出错，请重新支付");
                    setTimeout(function(){
                        window.AlipayJSBridge.call('closeWebview');
                    },2000)
                }else if(res.resultCode == "4000"){
                    that.$toast("订单支付失败，请重新支付");
                    setTimeout(function(){
                        window.AlipayJSBridge.call('closeWebview');
                    },2000)
                }
            });
            
            
        },

        // 请求列表
        getGoodList(){
            var that = this;
            var data = {
                key: that.key,
            }
            that.public.request('post','/api/AliJsPay/GetQrAliPayData',data,res => {
                if(res.data.errorCode == '00'){
                    that.extend = res.data.extend;
                    sessionStorage.setItem("member_token",that.extend.token);
                    localStorage.setItem("orgId",that.extend.orgid);
                    sessionStorage.setItem("customer",that.extend.customerid);
                    that.listGoods = res.data.rows[0];
                }else{
                    that.$toast("aaaaaa-->" + res.data.errorMsg);
                }
            })
        },

        // 确认支付
        confirmPay(){
            var that = this;
            that.payBoolean = false;    // 正在支付
            var orgId = localStorage.getItem("orgId");
            var listGoods = that.listGoods;
            var loginPageUrl = location.origin + "/login?orgId=" + orgId; 
            var data = {
                BatchNo: listGoods.orderInfo.BatchOrderNo,
                PaySource: 'aliPay',
                GoUrl: loginPageUrl,
            }
            that.public.request('post','api/orderPay/GetAliPayAppPayData',data,res => {
                if(res.data.errorCode == "00"){
                    var payData = res.data.rows;
                    if(payData.hasOwnProperty("token_id")){
                        var qrAdvancePay = payData.token_id;
                        that.tradePayReady(qrAdvancePay);
                    }
                    
                }
            })
        }
    },

    computed:{
        totalPrice:function(){
            var that = this;
            var totalPrice = Number(0);
            var listGoods = that.listGoods;
            console.log(listGoods.orderDetails);
            if(listGoods.orderDetails){
                listGoods.orderDetails.forEach(item => {
                    totalPrice += item.Price * item.Total;
                });
                totalPrice = parseFloat(totalPrice).toFixed(this.pointNum);
                return totalPrice;
            }
            
        }
    }
}
</script>

<style scoped>
    .container-box{
        margin-bottom:1rem;
    }

    /* 商品详情列表 */
	.good-list {
		width: 100%;
		padding: 0.2rem 0;
	}

	.good-list .good-item {
		width: 100%;
		padding: 0.2rem;
		box-sizing: border-box;
		border-bottom: 0.2rem solid #fff;
		background: #f7f7f7;
	}

	.good-list .good-item .gift-box{
		font-size:0.26rem;
		color:#f32222;
		padding-left:0.2rem;
		box-sizing:border-box;
	}

	.good-list .good-item:last-child {
		border-bottom: none;
	}

	.good-item .item-left {
		width: 2.3rem;
		margin-right: 0.12rem;
	}

	.good-item .item-left .left-img {
		width: 2rem;
		max-height: 2rem;
		margin: 0 auto;
		border-radius:0.12rem;
	}

	.good-item .item-left .item-setime {
		width: 80%;
		margin: 0.12rem auto 0;
		padding: 0.02rem 0.16rem;
		background: #199ed8;
		border-radius: 0.28rem;
	}

	.good-item .item-left .item-setime img {
		width: 0.22rem;
		height: 0.22rem;
		margin-right: 0.12rem;
	}

	.good-item .item-left .item-setime .settime-tit {
		color: #fff;
		font-size: 0.22rem;
	}

	.good-item .item-info {
		flex: 1;
		box-sizing: border-box;
		justify-content: space-between;
	}

	.good-item .item-info .info-name {
		width: 4rem;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-weight: 600;
		font-size: 0.3rem;
	}

	.good-item .item-info .info-stock .stock-tit {
		font-size: 0.24rem;
	}

	.good-item .item-info .info-stock .stock-quota {
		font-size: 0.24rem;
	}

	.good-item .item-info .info-specifi {
		font-size: 0.24rem;
	}

	.good-item .item-info .info-company {
		font-size: 0.28rem;
		width: 4rem;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.good-item .item-info .info-seck .info-price .info-tit {
		font-size: 0.36rem;
	}

	.good-item .item-info .info-seck .info-price .info-old-price {
		font-size: 0.24rem;
		margin-left: 0.12rem;
		text-decoration: line-through;
	}

	.good-item .item-info .info-seck .seck-btn {
		display: inline-block;
		padding: 0.02rem 0.2rem;
		font-size: 0.28rem;
	}

    /* 底部支付 */
    .pay-but{
        width:100%;
        height:0.9rem;
        position:fixed;
        left:0;
        bottom:0;
        z-index:9;
        background:#fff;
        border-top:0.02rem solid #eee;
        display:flex;
        align-items:center;
        justify-content:flex-end;
        padding:0 0.3rem;
        box-sizing:border-box;
    }
    .pay-but .pay-total{
        font-size:0.28rem;
        margin-right:0.2rem;
    }
    .pay-but .pay-total .price-red{
        color:#f32222;
    }
    .pay-but .but-confirm{
        width:1.6rem;
        height:0.6rem;
        line-height:0.6rem;
        background:#f32222;
        border-radius:0.12rem;
        text-align:center;
        font-size:0.28rem;
        color:#fff;
    }
    .pay-but .but-confirm.but-grey{
        background:#bdbbbb;
    }
</style>