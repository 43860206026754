import { render, staticRenderFns } from "./redPackRain.vue?vue&type=template&id=c3708108&scoped=true"
import script from "./redPackRain.vue?vue&type=script&lang=js"
export * from "./redPackRain.vue?vue&type=script&lang=js"
import style0 from "./redPackRain.vue?vue&type=style&index=0&id=c3708108&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3708108",
  null
  
)

export default component.exports