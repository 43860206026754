<template>
  <div class="container">
    <!-- 商品图片轮播 -->
    <div class="goods-swiper">
      <van-swipe class="my-swipe" :autoplay="3000" :show-indicators="false">
        <van-swipe-item v-for="(imgItem, index) in goodInfo.ImgSrc" :key="index">
          <div class="item-img">
            <img v-if="imgItem" :src="imgItem" @click="previewImage(imgItem)" onerror="javascript:this.src='https://goodssuffix.oss-cn-qingdao.aliyuncs.com/upload/c5c2ae08dbfb45898d64061a0d82e83e/9cc5bc0235044c54916fd18243082d10.png'" />
            <img v-else src="@/assets/images/loseImg.png" alt="" />
          </div>
        </van-swipe-item>
      </van-swipe>
      <!-- 提示 -->
      <div class="tips">
        温馨提示：厂家可能会更新商品包装，如图片与实物不符以实物为准
      </div>
    </div>
     <div class="noData"  v-if="goodInfo.kcshl <= 0"> 
            <span>售罄</span>
      </div>

    <!-- 普通商品 -->
    <div class="goods-info bor-bot2">
      <div class="info-box">
        <div class="info-name flex-sp">
          <span class="name-tit">{{ goodInfo.spmch }}</span>
          <!-- <img @click="onShare" src="@/assets/images/icon_share.png" alt=""> -->
        </div>
        <div class="info-specs flex-sp">
          <span>规格：{{ goodInfo.shpgg }}</span>
          <span>库存：<span class="font-red">{{goodInfo.kcshl>0?(!ShowStock?goodInfo.kcshl:"有货"):"无货"}}</span>
			<!-- {{ goodInfo.dw }} -->
		  </span>
        </div>
        <div class="info-price font-red">
            <span v-if="!token && loginStatus==0">￥资质通过后可见</span>
            <span v-if="token || (!token && loginStatus==1) ">￥{{ goodInfo.zdshj }}</span>
			<!-- <span class="left-num">￥{{goodInfo.OriginPrice}}</span> -->
        </div>
        <div class="info-retail flex-sp" v-if="token  || (!token && loginStatus==1)">
          <div class="retail-tit">
            <span>建议零售价：</span>
            <span class="font-red">{{goodInfo.AdvicePrice ? goodInfo.AdvicePrice : "--"}}</span>
            <span>元</span>
          </div>
          <!-- <div class="retail-rete">
            <span>毛利率：</span>
            <span class="font-red">{{ groProRate ? groProRate : "--" }}%</span>
          </div> -->
        </div>
        <!-- 游客模式 -->
        <div class="info-retail flex-sp" v-if="!token && loginStatus==0">
          <div class="retail-tit">
            <span>建议零售价:</span>
            <span class="font-red">-----</span>
            <span>元</span>
          </div>
          <!-- <div class="retail-rete">
            <span>毛利率:</span>
            <span class="font-red">----%</span>
          </div> -->
        </div>
        <div class="info-set flex-sp">
          <div v-if="!goodInfo.define4" class="set-time">{{goodInfo.sxrq&&goodInfo.sxrq.indexOf('/')=='-1'?'效期':'近远效期'}}:{{ goodInfo.sxrq }}</div>
          <!-- <div class="add-subtract flex-wrap">
                        <span class="add">-</span>
                        <input class="num" type="number" v-model="cartNum">
                        <span class="num">2</span>
                        <span class="cut">+</span>
                    </div> -->
        </div>
        <div v-if="goodInfo.define4" style="font-size: 0.26rem;">
          <span>参考批号:{{ goodInfo.define4 }}</span>
        </div>
      </div>
       <div v-if="(JSON.stringify(goodInfo.StyleGroup)!='{}'&&goodInfo.StyleGroup!=null)" class="promote-box  specification flex-wrap" @click="specificationBtn()">
         <div> <span class="promote-tit">规格</span>
            <span >点击选择</span></div>
         <div></div>
        
      </div>

      <div class="promote-box flex-wrap">
        <span class="promote-tit">促销</span>
        <span class="promote-label" v-for="(active, i) in goodInfo.activeList" :key="i" v-if="active !='秒杀'"> {{active}}</span>
      </div>
  
      <div class="promote-list"
        v-if="goodInfo.ActivityCacheDataList &&goodInfo.ActivityCacheDataList.length > 0">
        <div class="promote-item"
          v-for="(item, ind) in goodInfo.ActivityCacheDataList" :key="ind">
          <div class="promote-item" v-if="item.ActivityType!=1">{{ item.Description }}</div>
        </div>
      </div>
    </div>

    <!-- 商品详情 -->
    <div class="goods-detail">
      <div class="detail-tit flex-wrap">
        <div class="tit-item"
          :class="selectType == 'detail' ? 'active' : ''"
          @click="changeType('detail')">
          商品详情
        </div>
        <div class="tit-item"
          :class="selectType == 'direcImg' ? 'active' : ''"
          @click="changeType('direcImg')">
          商品说明书
        </div>
      </div>
      <div class="detail-cont">
        <!-- 商品详情 -->
        <div class="detail-sp" v-if="selectType == 'detail'">
          <div class="detail-li flex-wrap">
            <span class="detail-name">名称：</span>
            <span class="detail-label">{{ goodInfo.spmch }}</span>
          </div>
          <div class="detail-li flex-wrap">
            <span class="detail-name">规格：</span>
            <span class="detail-label">{{ goodInfo.shpgg }}</span>
          </div>
           <div class="detail-li flex-wrap">
            <span class="detail-name">商品编号：</span>
            <span class="detail-label">{{ goodInfo.spbh }}</span>
          </div>
          <div class="detail-li flex-wrap">
            <span class="detail-name">单位：</span>
            <span class="detail-label">{{ goodInfo.dw }}</span>
          </div>
		  <div class="detail-li flex-wrap">
		    <span class="detail-name">产地：</span>
		    <span class="detail-label">{{ goodInfo.shpchd?goodInfo.shpchd:"暂无"}}</span>
		  </div>
          <div class="detail-li flex-wrap">
            <span class="detail-name">生产厂商：</span>
            <span class="detail-label">{{ goodInfo.shengccj }}</span>
          </div>
          <div class="detail-li flex-wrap">
            <span class="detail-name">是否可拆零：</span>
            <span class="detail-label" v-if="goodInfo.OnlyPackageSold">不可拆零</span>
            <span class="detail-label" v-if="!goodInfo.OnlyPackageSold">可拆零</span>
          </div>
          <div class="detail-li flex-wrap">
            <span class="detail-name">大包装：</span>
            <span class="detail-label">{{ goodInfo.jlgg }}</span>
          </div>
          <div class="detail-li flex-wrap">
            <span class="detail-name">中包装：</span>
            <span class="detail-label">{{ goodInfo.zbz }}</span>
          </div>
          <div class="detail-li flex-wrap">
            <span class="detail-name">批准文号：</span>
            <span class="detail-label">{{ goodInfo.pizhwh }}</span>
          </div>
          <div  class="addContent" v-html="goodInfo.DetailContent">
			<!-- <video src="https://jhyypic.oss-cn-beijing.aliyuncs.com/CxGoods/2679591e77624a6489912571f449832c/20220617102817.mp4" controls autoplay muted width="100%" height="500"></video> -->
          </div>
        </div>
        <!-- 商品说明书 -->
        <div class="direction-box" v-else-if="(selectType = 'direcImg')" style="min-height:5rem;">
          <div v-if="goodInfo.SpecificationsList.length ">
              <div v-for="(item,ind) in goodInfo.SpecificationsList" :key="ind" >
                <img class="direc-img" :src="item.Src" alt="">
            </div>
          </div>
          <div v-if="goodInfo.SpecificationsList.length==0">
            <img src="@/assets/images/loseImg.png" alt="" />
          </div>

          <!-- <img class="direc-img" v-if="directionImg" :src="directionImg" alt="">
                    <img class="direc-img" v-if="!directionImg" src="@/assets/images/loseImg.png" alt=""> -->
        </div>
      </div>
    </div>

    <!-- 返回首页 -->
    <div class="back-index" v-if="clientType!='app' && clientType!='ios'" @click="toIndex()">返回首页</div>

    <!-- 底部操作 -->
    <div class="bot-heig">
      <div class="bot-box flex-wrap">
        <div class="bot-left flex-wrap">
          <div class="bot-item flex-column" @click="collect()">
            <img v-if="collection != 1" src="@/assets/images/feedback_star.png"/>
            <img v-if="collection == 1" :src="themeImgObj.collectImg"/>
            <span>收藏</span>
          </div>
          <div class="bot-item flex-column" @click="toCartPage()">
            <img :src="themeImgObj.cartImgGwc" alt="" />
            <span class="bot-name">购物车</span>
            <span class="cart-count" v-if="$store.state.cartNum > 0">{{$store.state.cartNum}}</span>
          </div>
        </div>
        <div class="seck-but" v-if="goodInfo.kcshl > 0" @click="cartShow()">{{btnText}}</div>
        <div class="seck-but grey" v-else @click="onRegister()">缺货登记</div>
      </div>
    </div>

    <!-- 回到顶部 -->
    <!-- <div class="back-top">
            <img src="@/assets/images/icon_top.png" alt="返回顶部">
        </div> -->

    <!-- 缺货登记弹窗 -->
    <div class="register-popup" v-if="registerShow"></div>
    <div class="register-cont" v-if="registerShow">
      <div class="regis-tit">缺货登记</div>
      <div class="regis-item flex-wrap">
        <span class="item-tit">商品名称：</span>
        <div class="input-box">
          <input type="text" disabled v-model="goodInfo.spmch" />
        </div>
      </div>
      <div class="regis-item flex-wrap">
        <span class="item-tit">商品规格：</span>
        <div class="input-box">
          <input type="text" disabled v-model="goodInfo.shpgg" />
        </div>
      </div>
      <div class="regis-item flex-wrap">
        <span class="item-tit">生产厂商：</span>
        <div class="input-box">
          <input type="text" disabled v-model="goodInfo.shengccj" />
        </div>
      </div>
      <div class="regis-item flex-wrap">
        <span class="item-tit">需求数量：</span>
        <div class="input-box">
          <input
            type="number"
            v-model="registerNum"
            placeholder="请输入需求数量"
          />
        </div>
      </div>
      <div class="regis-item flex-wrap">
        <span class="item-tit">最长等待时间：</span>
        <div class="input-box flex-wrap">
          <input type="number" v-model="waitDay" placeholder="请输入等待天数" />
          <span class="input-tit">天</span>
        </div>

      </div>
      <div class="regis-but flex-wrap1">
        <div class="but-around grey" @click="onCancel">取消</div>
        <div class="but-around blue" @click="onSure">确定</div>
      </div>
    </div>

    <!-- 商品购物车弹窗 -->
    <cart-pop
      :cartShowPop="cartShowPop"
      :goodInfo="goodInfo"
      @diffCartShow="closeCart"
    ></cart-pop>

    <!-- 规格 -->
     <specification :guiGeType='guiGeType' :isSpecification='isSpecification' :bottomHight='bottomHight'  @isGuiGeShow="closeGuiGe" :guiGeDetail="guiGeDetail" :guiGeDes='guiGeDes'></specification>
	 
	 <!-- 图片预览-->
	 <div class="image-preview" v-if="isPreviewVisible">
		 <!-- :closeable = true -->
	      <van-image-preview 
			v-model="isPreviewVisible"
			:showIndex = false
			:images="zizhiImages">
	      </van-image-preview>
	  </div>
    
  </div>
</template>

<script>

import registerRequest from "@/assets/js/api/register.js";
import cartPop from "../../components/cartPop.vue";
import specification from '../../components/specification.vue';
export default {
  components: {
    cartPop,specification,
  },
  data() {
    return {
      registerShow: false, //缺货登记弹窗  false隐藏  true显示
      dateShow: false, // 日期选择弹窗
      minDate: new Date(),
      maxDate: new Date(2031, 12, 1),
      newDate: "",
      registerNum: "", // 缺货登记需求数量
      waitDay: "", // 缺货登记等待天数
      goodInfo: [], // 详情数据
      timeDown: "", // 倒计时
      groProRate: "", // 毛利率
      goodOrgId: "", // 商品orgId
      goodSpid: "", // 商品id
      selectType: "detail", // 商品详情和说明书切换
      cartNum: 1, // 商品购物车数量
      directionImg: "", // 商品详情说明书
      type: "", // 页面传过来的参数
      // cartShowPop: false, // 商品购物车弹窗
      selectId: "", // 选中活动的id
      collection: "", // 收藏

      editNumShow: false, // 编辑数字弹窗显示隐藏
      cartEditNum: 1, // 编辑弹窗输入框中的值
      token : sessionStorage.getItem('member_token'),

      cartShowPop: false, // 组件显示隐藏
      goodInfo: {}, // 向组件传递商品详情

      loginStatus: 0,    // 查看分享商品的人是否登录，loginStatus 1登录(显示商品价格) 0 未登录
      pointNum:2,//小数点
      clientType:'',
      isSpecification:false,//规格
      guiGeDetail :{},//规格详情
      guiGeDes:{},//规格
      guiGeType:false,
      bottomHight:false,
	  btnText: "加入购物车",
	  
	  ShowStock: JSON.parse(localStorage.getItem("ShowStock")),
	  keyId: "", //埋点 主键
	  
	  zizhiImages: [], // 当前预览的图片
	  isPreviewVisible: false, // 是否显示预览层
    };
  },
  computed: {
	  imageStyle() {
		return {
		  transform: `scale(${this.scale})`
		};
	  }
  },
  created() {
    var that = this;
	// 普通商品
	var spid = that.$route.query.spid;
	that.goodSpid = spid;
	//统计分析 埋点进来
	this.keyId = ""; //每次进入置空
	this.public.setBuryPoint(4,1,this.keyId,"",spid).then(res=>{
		if(res.errorCode=="00"){
			that.keyId = res.rows;
		}
	});
	
    // 主题色图片
    that.themeImgObj = that.themeColorImg.imgObj;
    // 小程序登录
    that.AppletLogin();
    var type = that.$route.query.type;
    that.goodOrgId = that.$route.query.goodOrgId; //商品orgId
    that.type = type;

    // 查看分享商品的人是否登录，loginStatus 1登录(显示商品价格) 0 未登录
    if(that.$route.query.loginStatus){
      that.loginStatus = that.$route.query.loginStatus;
      // console.log(that.loginStatus,'-----------------aa');
    }else{
      that.loginStatus = 0;
      // console.log(that.loginStatus,'-----------------aa');
    }
    var shuju=JSON.parse(localStorage.getItem('webconfig'))
        if(shuju){
           if(shuju.GoodPriceFixed){
                that.pointNum=shuju.GoodPriceFixed;
            }
        }

   that.clientType=localStorage.getItem('clientType');
    // 顶部标题
    // that.$store.commit("setHeadTitle", that.$route.query.headTit);
	that.$store.commit("setHeadTitle", "");
    var orgId = that.$route.query.orgId;
    if (orgId) {
      localStorage.removeItem("orgId");
      localStorage.setItem("orgId", orgId);
    }
	//商品信息
    that.getGoodInfo(orgId);
    // 商品说明书
    that.getDirecInfo();
	//创建浏览记录
	that.createHistory();
    // 请求购物车数量
   var token=sessionStorage.getItem('member_token')
    if(token){
		that.public.getCartNum();
    }

  },
  
  //界面销毁
  destroyed() {
	//埋点离开
	this.public.setBuryPoint(4,2,this.keyId,"",this.goodSpid).then(res=>{
		if(res.errorCode=="00"){}
	});
  },
  
  methods: {
	  //添加记录浏览量
	  createHistory(){
		  this.public.request("post","api/GoodHistory/CreateHistory",{
			  SpId: this.goodSpid, //（商品id）
			  CustomId: localStorage.getItem("orgId")  //（登陆客户orgid）
		  },res=>{
			  if(res.data.errorCode=="00"){
				console.log("浏览记录成功!")
			  }else{
				console.log("浏览记录失败~")
			  }
		  })
	  },
    // 编辑input数值
    editInput() {
      var that = this;
      that.editNumShow = true;
      that.$nextTick(() => {
        that.$refs.editNum.focus();
        that.$refs.editNum.select();
      });
      that.cartEditNum = that.cartNum;
    },
    // 取消编辑弹窗
    cancelEdit() {
      var that = this;
      that.editNumShow = false;
    },
    // 购物车数量减
    reduceNum1() {
      var that = this;

      if (that.cartEditNum > 1) {
        that.cartEditNum--;
      }
    },
    // 购物车数量加
    addNum1() {
      var that = this;
      that.cartEditNum++;
    },
    // 编辑弹窗确认
    sureEdit() {
      var that = this;
      that.editNumShow = false;
      that.cartNum = that.cartEditNum;
      that.cartEditNum = 1;
    },


    // 关闭组件弹窗
    closeCart() {
      var that = this;
      that.cartShowPop = false;
    },
    // 规格
    specificationBtn(){
       var that = this;
      // 查看是否登录
      var token = sessionStorage.getItem('member_token');
      if (token == null) {
		that.toLogin();
		return false;
      }
      that.isSpecification=true;
      var guiges=that.goodInfo.StyleGroup;
      Object.keys(guiges).forEach(key=>{
        if( guiges[key].ActivityCacheDataList.length>0){
            let item1='ActivityId';
            let value1=guiges[key].ActivityCacheDataList[0].ActivityId;
            guiges[key][item1]=value1;
            let item2='Description';
            let value2=guiges[key].ActivityCacheDataList[0].Description;
            guiges[key][item2]=value2;
            let item3='ActivityType';
            let value3=guiges[key].ActivityCacheDataList[0].ActivityType;
            guiges[key][item3]=value3;
        }
        let item='num';
        let value=0;
       guiges[key][item]=value;
      });
      that.guiGeDes=guiges;
      // console.log(that.guiGeDes);
      that.guiGeDetail = that.goodInfo;
     
    },
    //  关闭规格弹窗
    closeGuiGe() {
      var that = this;
      that.isSpecification = false;
    },

    // 去购物车页面
    toCartPage() {
      var that = this;
      // 查看是否登录
      var token = sessionStorage.getItem('member_token');
      if (token == null) {
        that.toLogin();
        return false;
      }

      that.$router.push({
        path: "/cart",
      });
    },

    // 加入购物车
    addCart() {
      var that = this;

      var goodInfo = that.goodInfo;
      var cartNum = that.cartNum; // 购买数量
      var stock = goodInfo.kcshl; // 库存数量

      var activeObj = {};
      goodInfo.ActivityCacheDataList.forEach((item, index) => {
        if (that.selectId == item.ActivityId) {
          activeObj = item;
        }
      });

      // 余数只要有值，则商品数量无效
      var num = Number(cartNum - Number(cartNum / goodInfo.zbz) * goodInfo.zbz);
      if (num) {
        that.$toast("商品数量无效！");
        return false;
      }

      var data = {
        Spid: goodInfo.spid,
        GoodsCount: cartNum, // 购物车数量
        GoodsPrice: goodInfo.zdshj, // 商品价格
        ReceiveId: goodInfo.OrgId, // orgid
        OfferId: activeObj.ActivityId,
        OfferRemark: activeObj.Description,
        OfferType: activeObj.ActivityType,
      };
	  
	  if(goodInfo.CrossGroupId){
	  	data.TmsCrossGroupId = goodInfo.CrossGroupId;
	  }

      var IsLowStockOrder;
      // 获取配置信息
      that.public.getOrgWebInfo(function(orgInfo) {
        IsLowStockOrder = orgInfo.IsLowStockOrder;
      });

      if (Number(cartNum) > Number(stock)) {
        // IsLowStockOrder  false 禁止下单， ture 可以下单
        if (!IsLowStockOrder) {
          that.$toast("库存不足");
          return;
        } else {
          that.$dialog
            .confirm({
              message: "库存不足，将以最大库存量下单，下单即生成采购计划！",
            })
            .then(() => {
       
              that.getAddCart(data);
            })
            .catch(() => {

            });
        }
      } else {
        that.getAddCart(data);
      }
    },
    
    getAddCart(data) {
      var that = this;
      that.public.request(
        "post",
        "/api/SellWellCar/AddSellWellCar",
        data,
        (res) => {

          if (res.data.errorCode == "00") {
            that.$toast("添加成功");
            // 购物车数量
            that.public.getCartNum();
            var goodInfo = that.goodInfo;
            // 中包装步长
            if(goodInfo.OnlyPackageSold && goodInfo.OrderNumSetp>0){
              that.cartNum = goodInfo.OrderNumSetp;
            }else{
              that.cartNum = 1;
            }
            // that.cartNum = 1;
            that.cartShowPop = false;
          } else if (res.data.errorCode == "99") {
            that.$toast(res.data.errorMsg);
            that.cartShowPop = false;
          }
        }
      );
    },

    // 购物车弹窗显示
    cartShow() {
      var that = this;
	  if(!that.goodInfo.IsBuy){
		  if(!that.goodInfo.BuyPhone){
			  return that.$toast("暂无联系方式");
		  }
		  that.$dialog.confirm({
		      message: "您即将拨打电话："+that.goodInfo.BuyPhone,
		  	confirmButtonText: "呼叫"
		  }).then(() => {//confirm
			window.location.href = 'tel://' + that.goodInfo.BuyPhone
		  }).catch(() => {
		  	//cancel
		  });
		  return;
	  }
      // 查看是否登录
      var token = sessionStorage.getItem('member_token');
      if (token == null) {
        that.toLogin();
        return false;
      }
      if((JSON.stringify(that.goodInfo.StyleGroup)!='{}'&&that.goodInfo.StyleGroup!=null)){
         that.isSpecification=true;
        var guiges=that.goodInfo.StyleGroup;
   
        Object.keys(guiges).forEach(key=>{
          let item='num';
          let value=0;
        guiges[key][item]=value;
        });
        that.guiGeDes=guiges;
        // console.log(that.guiGeDes);
        that.guiGeDetail = that.goodInfo;
      }else{
        that.cartShowPop = true;
        that.goodInfo = that.goodInfo;
      }

    },

    // 商品说明书
    getDirecInfo(){
      var that = this;
      var orgId = localStorage.getItem('orgId');
      var data = {
        OrgId: orgId,
        Code: "20009",  // 公众号商品详细
        AdvertPlatformType: 20,
      };
      that.public.request('post','/api/advertItem/queryAdvertItem',data,res => {

        if(res.data.errorCode == '00' && res.data.rows.length > 0){
          // that.directionImg = res.data.rows[0].ImgUrl;
        }
      })
    },
    // 切换商品详情或者说明书
    changeType(type){
      var that = this;
      that.selectType = type;
    },
    // 关闭购物车弹窗
    closePop() {
      var that = this;
      that.cartShowPop = false;
    },
    // 购物车数量减
    reduceNum() {
      var that = this;
      var goodInfo = that.goodInfo;
      var num = 1;
      // 中包装步长
      if(goodInfo.OnlyPackageSold && goodInfo.OrderNumSetp>0){
        num = goodInfo.OrderNumSetp;
      }else{
        num = 1;
      }
      if (that.cartNum > num) {
        // that.cartNum--;
        that.cartNum = Number(that.cartNum) - Number(num);
      }
    },
    // 购物车数量加
    addNum() {
      var that = this;
      var goodInfo = that.goodInfo;
      var num = 1;
      // 中包装步长
      if(goodInfo.OnlyPackageSold && goodInfo.OrderNumSetp>0){
        num = goodInfo.OrderNumSetp;
      }else{
        num = 1;
      }
      // that.cartNum++;
      that.cartNum = Number(that.cartNum) + Number(num);
    },

    // 返回首页
    toIndex() {
      var that = this;
      // 查看是否登录
      var token = sessionStorage.getItem('member_token');
      if (token == null) {
		that.toLogin();
        return false;
      }

      that.$router.push({
        path: "/",
        query: {
          refresh: "refresh",
        },
      });
    },
    // 商品说明书
    getDirecInfo() {
      var that = this;
      var orgId = localStorage.getItem("orgId");
      var data = {
        OrgId: orgId,
        Code: "20009", // 公众号商品详细
        AdvertPlatformType: 20,
      };
      that.public.request(
        "post",
        "/api/advertItem/queryAdvertItem",
        data,
        (res) => {

          if (res.data.errorCode == "00" && res.data.rows.length > 0) {
            // that.directionImg = res.data.rows[0].ImgUrl;
          }
        }
      );
    },
    // 切换商品详情或者说明书
    changeType(type) {
      var that = this;
      that.selectType = type;
    },

    // 进入详情加浏览记录
    addBrowseHistory() {
      var that = this;
      var orgId = localStorage.getItem("orgId");
      var goodInfo = that.goodInfo;
      var data = {
        ListHistory: [
          {
            Spid: goodInfo.spid,
            SpOrgId: orgId,
            Price: goodInfo.zdshj,
            Type: 10, // 10详情   20购物车 30搜索
          },
        ],
      };
      that.public.request(
        "post",
        "/api/GoodsBrowseHistory/BatchAddBrowseHistory",
        data,
        (res) => {
          if (res.data.errorCode == "00") {
            // console.log(res);
          }
        }
      );
    },

    // 请求详情数据
    getGoodInfo(orgId) {
      var that = this;
      that.$toast.loading({
        message: "加载中...",
        duration: 0,
      });
      // 普通商品
      var goodSpid = that.goodSpid;

      var data = {
        SpId: goodSpid,
        OrgId: "",
        pihao: "",
        IsShowDetailContent:true,
      };
      if (orgId) {
        data.OrgId = orgId;
      }
      that.public.request("post", "/api/store/queryGoodsInfo", data, (res) => {
        that.$toast.clear();
        var active='';
        if (res.data.errorCode == "00") {
          var goodInfo = res.data.rows[0];
          var IsSpecial = sessionStorage.getItem("IsSpecial");
          if(IsSpecial){
            goodInfo.ActivityCacheDataList = [];
          }
		  
		  if(goodInfo.IsBuy){
			  that.btnText = "加入购物车";
		  }else{
			  that.btnText = "申请合作";
		  }
		  
          that.goodInfo = goodInfo;
          if (goodInfo.ActivityCacheDataList.length > 0) {
             that.selectId =
              res.data.rows[0].ActivityCacheDataList[0].ActivityId;
              // active= res.data.rows[0].ActivityCacheDataList[0].ActivityTypeName
          }
          // 处理相同促销
          var obj=that.goodInfo;
          Object.getOwnPropertyNames(obj).forEach((key)=>{
              if(key=='ActivityCacheDataList'){
                  var list=[];
                  obj[key].forEach(item=>{
                    list.push(item.ActivityTypeName);
                  });
                    const set = new Set(list);
                    that.goodInfo.activeList=set;
              }
          });
          var istoken=sessionStorage.getItem('member_token')?true:false;
           this.wx.miniProgram.postMessage({
            data:{
              jiage:that.goodInfo.zdshj,
              img: that.goodInfo.ImgSrc[0],
              guiGe:that.goodInfo.shpgg,
              istoken:istoken,
              SpId:that.goodInfo.spid,
              name:that.goodInfo.spmch,
            }
          })

          // 收藏
          that.collection = res.data.extend;
          that.getgroPro(goodInfo.AdvicePrice, goodInfo.zdshj);
        } else if (res.data.errorCode == "99") {
          that.$toast(res.data.errorMsg);
        }
      });
    },

    // 计算毛利率  毛利率=(建议零售价 - 最低售货价) / 建议零售价 *100
    getgroPro(advicePrice, lowSellPrice) {
      var that = this;
      if (advicePrice > 0 && lowSellPrice) {
        that.groProRate = (
          ((advicePrice - lowSellPrice) / advicePrice) *
          100
        ).toFixed(that.pointNum);
      } else {
        that.groProRate = 0;
      }
    },

    // 收藏  取消收藏
    collect() {
      var that = this;

      // 查看是否登录
      var token = sessionStorage.getItem('member_token');
      if (token == null) {
        that.toLogin();
        return false;
      }

      var goodInfo = that.goodInfo;
      var collection = that.collection;
      var data = {
        Spid: goodInfo.spid,
        SpOrgId: "",
        Price: goodInfo.zdshj,
      };
      if (collection == 0) {
        that.public.request(
          "post",
          "/api/GoodsCollection/AddCollection",
          data,
          (res) => {
            // console.log(res);
            if (res.data.errorCode == "00") {
              that.$toast("收藏成功");
              that.collection = 1;
            } else if (res.data.errorCode == "99") {
              that.$toast(res.data.errorMsg);
            }
          }
        );
      } else {
        that.public.request(
          "post",
          "/api/GoodsCollection/CancelCollection",
          data,
          (res) => {
    
            if (res.data.errorCode == "00") {
              that.$toast("取消收藏");
              that.collection = 0;
            } else if (res.data.errorCode == "99") {
              that.$toast(res.data.errorMsg);
            }
          }
        );
      }
    },

    // 缺货登记弹窗
    onRegister() {
      var that = this;
      // 查看是否登录
      var token = sessionStorage.getItem('member_token');
      if (token == null) {
        that.toLogin();
        return false;
      }

      that.registerShow = true;
    },
    // 缺货登记弹窗取消
    onCancel() {
      this.registerShow = false;
    },
    // 缺货登记弹窗确定
    onSure() {
      var that = this;

      var goodInfo = that.goodInfo;
      var registerNum = that.registerNum; // 需求数量
      var waitDay = that.waitDay; // 等待天数
      if (!registerNum) {
        that.$toast("请输入需求数量");
        return;
      }
      var reg = /(^[1-9]\d*$)/;
      if (!reg.test(registerNum)) {
        that.$toast("请输入大于0的整数");
        return;
      }
      if (!waitDay) {
        that.$toast("请输入等待天数");
        return;
      }
      if (!reg.test(waitDay)) {
        that.$toast("请输入大于0的整数");
        return;
      }

      var data = {
        spid: goodInfo.spid,
        goodsCount: registerNum,
        waitDays: waitDay,
		ReceiveId: localStorage.getItem('orgId')
      };
      registerRequest.saveGoodsStockOutByDay(data, (res) => {
        // console.log(res);
        if (res.data.errorCode == "00") {
          that.$toast("登记成功");
          that.registerShow = false;
        } else if (res.data.errorCode == "99") {
          that.$toast(res.data.errorMsg);
        }
      });
    },
    // 取消选择时间
    cancelDate() {
      this.dateShow = false;
    },
    // 确定选择时间
    sureDate(value) {
      var that = this;
      that.dateShow = false;
      var year = value.getFullYear();
      var month = value.getMonth() + 1;
      var date = value.getDate();
      that.newDate = year + "." + month + "." + date;
    },


    createXmlHttpRequest(){
        var xmlHttp;
        if(window.ActiveXobject){ // 判断是否是ie浏览器
            try { // try开始
                xmlhttp = new ActiveXobject("Microsoft.XMLHTTP"); // 使用ActiveX对象创建ajax
            }catch(e){
                xmlHttp = false;
            } // try end
        }else{   //Chrome、FireFox等非ie内核
            try{
            xmlHttp = new XMLHttpRequest(); //视为非ie情况下
            }catch(e){
                xmlHttp = false; // 其他非主流浏览器
            }
        } // 判断结束,如果创建成功则返回一个DOM对象,如果创建不成功则返回一个false

        if(xmlHttp)
        {
            return xmlHttp;
        }else{
            alert("xmlhttp对象创建失败,请检查浏览器是否支持XmlHttpRequest!");
        }
    },
    /**
      * 小程序登录
	*/
	AppletLogin(){
		var that = this;
		if(location.href.indexOf('?') == -1) return;
		var params=that.public.urlObj(location.href);
		if(!params.jumid)return;
		  localStorage.setItem("clientType", "applets"); // app类型
		var id = params.jumid;
		// console.log("--------------",id);
		var xmlobj = that.createXmlHttpRequest();
		xmlobj.open("post",window.baseUrl.url+"/api/AppLogin/GetAppletLogin",false);
		xmlobj.setRequestHeader("cache-control","no-cache");
		xmlobj.setRequestHeader("Content-Type", "application/json;charset=utf-8"); //设置请求头信息
		xmlobj.onreadystatechange = function() {
			if(xmlobj.readyState == 4 && xmlobj.status == 200)
			{
				try{
					  var rsp = JSON.parse(xmlobj.responseText);
					  // console.log("rsp==>",rsp);
					  // if(!rsp || rsp.errorCode !="00"){
					  //     that.$router.push({
					  //         url:"/login"
					  //     });
					  //     return;
					  // }

					  var res={data:{}};
					  res.data = rsp;
					if(res.data.errorCode=='00'){
						that.$toast.clear();
						sessionStorage.setItem("member_token", res.data.rows);
						localStorage.setItem("orgId", res.data.errorMsg); 
						sessionStorage.setItem("customer", res.data.extend.customerId);
						var webconfig = JSON.stringify(res.data.extend.webConfig);
						localStorage.setItem("webconfig", webconfig);
						if(res.data.extend.IsSpecial){  // 测试账号，审核展示数据
							sessionStorage.setItem("IsSpecial",res.data.extend.IsSpecial);
						}
					}else{
						that.$toast(res.data.errorMsg);
					}
					// console.log(rsp);
				}
				catch(e){
						  that.$router.push({
							  url:"/login"
						  });     
				}
				
			}
		};
		xmlobj.send(JSON.stringify({Mobile:id})); //设置为发送给服务器数据
	},
	
	//去登录
	toLogin: function(){
		let that = this;
		var orgId = localStorage.getItem("orgId");
		that.$dialog.confirm({
		    message: "你还未登录，是否登录!",
		}).then(() => {
			if(that.isMini()){
				that.wx.miniProgram.reLaunch({
				     url: window.baseUrl.xcxLogin?'/pages/login/index':'/pages/login/oldLogin/index',
				})
				return;
			}
		    that.$router.push({
		      path: "/login",
		      query: {
		        orgId: orgId,
		        auto: 0,
		      },
		    });
		}).catch(() => {
		});
	},
	
	//判断是否小程序浏览器加载
	isMini: function(){
		let mini = false;
		if (/MicroMessenger/i.test(navigator.userAgent)) {
			//ios的ua中无miniProgram，很坑爹,但都有MicroMessenger（表示是微信浏览器）
			this.wx.miniProgram.getEnv((res) => {
				if (res.miniprogram) {
					mini = true;
				}
			});
		}
		return mini;
	},
	/**图片预览*/
	previewImage(imgUrl){
		 this.zizhiImages[0] = imgUrl;
		this.isPreviewVisible = true;
	}

  },
  
  
};
</script>

<style scoped>
/* 商品图片轮播 */
.goods-swiper {
  width: 100%;
  height: 6rem;
  position: relative;
}
.goods-swiper .my-swipe {
  width: 100%;
  height: 100%;
}
.goods-swiper .my-swipe .item-img {
  width: 100%;
  height: 100%;
}
.goods-swiper .my-swipe .item-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.goods-swiper .tips {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  font-size: 0.23rem;
  height: 0.5rem;
  line-height: 0.5rem;
  padding: 0 0.12rem;
  box-sizing: border-box;
  color: #fff;
}

/* 商品规格、价钱 */
.goods-info {
  width: 100%;
}
.goods-info .info-box {
  padding: 0.12rem 0.2rem;
  box-sizing: border-box;
}
.info-box .info-name {
  width: 100%;
  font-size: 0.32rem;
  color: #333;
  font-weight: 600;
}
.info-box .info-name img {
  width: 0.42rem;
  height: 0.42rem;
}
.info-box .info-specs {
  margin: 0.08rem 0 0.02rem;
}
.info-box .info-specs span {
  font-size: 0.26rem;
  color: #999;
}
.info-box .info-specs span.font-red {
  color: #D5251D;
}
.info-box .info-price{
  font-size: 20px !important;
}
.info-box .info-price .left-num {
  font-size: 0.26rem;
  color: #999;
  text-decoration: line-through;
  margin-left: 0.12rem;
}

.info-box .info-retail{
  font-size: 14px !important;
  color: #666;
}
.info-box .info-retail .font-red {
  color:#D5251D;
}

.info-box .info-set {
  margin: 0.12rem 0 0;
}
.info-box .info-set .set-time {
  font-size: 0.26rem;
  /* margin-bottom: 0.2rem; */
}
/* 距离结束倒计时 */
.time-setime {
  padding: 0.02rem 0.2rem;
  background: var(--theme-color);
  border-radius: 0.28rem;
  margin-left: 0.2rem;
  height: 0.32rem;
  flex:1;
  white-space:nowrap;
}
.time-setime img {
  width: 0.22rem;
  height: 0.22rem;
  margin-right: 0.18rem;
}
.time-setime .settime-tit {
  color: #fff;
  font-size: 0.24rem;
}

.promote-box {
  width: 100%;
  height: 0.8rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
  border-top: 0.02rem solid #eee;
}
.promote-box .promote-tit {
  font-size: 0.3rem;
  margin-right: 0.3rem;
}
.promote-box .promote-label {
  display: inline-block;
  padding: 0.03rem 0.22rem;
  font-size: 0.26rem;
  color: #fff;
  background: #D5251D;
  border-radius: 0.2rem;
  margin-right: 0.12rem;
}

.promote-list {
  width: 100%;
  background: #fff;
  padding: 0.18rem;
  box-sizing: border-box;
  border-top: 0.02rem solid #eee;
}
.promote-item {
  font-size: 0.28rem;
  margin-left: 0.3rem;
  color: #fd5a19;
}

/* 商品详情 */
.goods-detail .detail-tit {
  width: 100%;
  height: 0.8rem;
  justify-content: space-around;
  border-bottom: 0.02rem solid #eee;
}
.goods-detail .detail-tit .tit-item {
  font-size: 0.3rem;
  height: 100%;
  line-height: 0.8rem;
}
.goods-detail .detail-tit .tit-item.active {
  border-bottom: 0.02rem solid var(--theme-color);
  color: var(--theme-color);
}

.goods-detail .detail-cont {
  width: 100%;
  padding: 0.02rme;
  box-sizing: border-box;
}

.detail-cont .detail-sp {
  width: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
}
.detail-cont .detail-sp .detail-li {
  width: 100%;
  padding: 0.06rem 0;
}
.detail-cont .detail-sp .detail-li .detail-name {
  width: 2rem;
  font-size: 0.28rem;
  color: #666;
}
.detail-cont .detail-sp .detail-li .detail-label {
  font-size: 0.3rem;
  color: #666;
}
/* 商品说明书 */
.direction-box {
  width: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
}
.direction-box .derec-img {
  width: 100%;
}

/* 底部操作 */
.bot-heig {
  width: 100%;
  height: 0.9rem;
}
.bot-box {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 0.9rem;
  border-top: 0.02rem solid #eee;
  background: #fff;
}
.bot-box .bot-left {
  flex: 1;
  height: 100%;
  padding: 0 0.2rem;
  box-sizing: border-box;
  justify-content: space-around;
}
.bot-box .bot-left .bot-item {
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 13px !important;
}
.bot-box .bot-left .bot-item img {
  width: 0.4rem;
  height: 0.4rem;
}
.bot-box .bot-left .bot-item .cart-count {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.24rem;
  color: #fff;
  width: 0.36rem;
  height: 0.36rem;
  border-radius: 50%;
  background: var(--theme-color);
  text-align: center;
  line-height: 0.36rem;
}

.seck-but {
  width: 60%;
  height: 100%;
  text-align: center;
  line-height: 0.9rem;
  color: #fff;
  font-size: 0.3rem;
  background: var(--theme-color);
}
.seck-but.grey {
  background: #c0bfbf;
}

/* 返回首页 */
.back-index {
  position: fixed;
  right: 0;
  bottom: 1.5rem;
  width: 1.5rem;
  height: 0.6rem;
  border-radius: 0.3rem 0 0 0.3rem;
  background: var(--theme-color);
  line-height: 0.6rem;
  text-align: center;
  color: #fff;
  font-size: 0.3rem;
  z-index: 1;
}

/* 缺货登记弹窗 */
.register-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 6;
}
.register-cont {
  position: fixed;
  top: 3rem;
  left: 7%;
  width: 86%;
  background: #fff;
  z-index: 7;
  padding: 0.2rem;
  box-sizing: border-box;
  border-radius: 0.2rem;
}
.register-cont .regis-tit {
  width: 100%;
  text-align: center;
  font-size: 0.39rem;
}
.register-cont .regis-item {
  margin-bottom: 0.2rem;
  border-bottom: 0.02rem solid #eee;
  height: 0.8rem;
}
.register-cont .regis-item .item-tit {
  width: 2.3rem;
  font-size: 0.3rem;
}
.register-cont .regis-item .input-box {
  flex: 1;
}
.register-cont .regis-item .input-box input {
  font-size: 0.3rem;
  flex: 1;
}
.register-cont .regis-item .input-box img {
  width: 0.16rem;
  margin-left: 0.2rem;
}
.register-cont .regis-item .input-box .input-tit {
  font-size: 0.32rem;
}

.register-cont .regis-but {
  width: 100%;
  justify-content: space-around;
}
.register-cont .regis-but .but-around {
  width: 1.8rem;
  height: 0.6rem;
  font-size: 0.3rem;
  text-align: center;
  line-height: 0.6rem;
  border-radius: 0.2rem;
  color: #fff;
}
.register-cont .regis-but .but-around.grey {
  background: #c0bfbf;
}
.register-cont .regis-but .but-around.blue {
  background: #0cb4e7;
}

/* 商品弹窗 */
.good-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}
.good-pop {
  position: fixed;
  left: 0;
  bottom: 0rem;
  z-index: 10;
  background: #fff;
  width: 100%;
}
.good-pop .good-top {
  width: 100%;
  padding: 0.18rem 0.2rem;
  box-sizing: border-box;
  border-bottom: 0.02rem solid #eee;
}
.good-pop .good-top img {
  width: 1.8rem;
  max-height: 1.8rem;
  margin-right: 0.2rem;
}
.good-pop .good-top .good-right {
  flex: 1;
  height: 1.8rem;
  justify-content: space-between;
}
.good-pop .good-top .good-right .right-name {
  font-size: 0.3rem;
  font-weight: 600;
}
.good-pop .good-top .good-right .right-price span.price-tit {
  font-size: 0.5rem;
}
.good-pop .good-top .good-right .right-price span {
  font-size: 0.28rem;
}

.good-pop .good-specifi {
  width: 100%;
  padding: 0.18rem 0.2rem;
  box-sizing: border-box;
}
.good-pop .good-specifi .specifi-item {
  margin-bottom: 0.12rem;
}
.good-pop .good-specifi .specifi-item .item-title .tit {
  font-size: 0.28rem;
  color: #333;
  font-weight: 600;
  display: block;
  margin-right: 0.2rem;
}
.good-pop .good-specifi .specifi-item .item-title .tit-parameter {
  font-size: 0.28rem;
  color: #999;
}
.good-pop .good-specifi .specifi-item .last-span {
  margin-left: 0.28rem;
  color: #999;
  font-size: 0.26rem;
}
/* 商品加减 */
.add-subtract {
  flex: 1;
  justify-content: flex-end;
}
.add-subtract .add {
  font-size: 0.36rem;
  border: 0.05rem solid var(--theme-color);
  color: var(--theme-color);
  border-radius: 50%;
  display: inline-block;
  width: 0.38rem;
  height: 0.38rem;
  text-align: center;
  line-height: 0.3rem;
  box-sizing: border-box;
}
.add-subtract .num {
  font-size: 0.3rem;
  margin: 0 0.2rem;
  width: 1rem;
  text-align: center;
  border: 0.02rem solid #999;
  border-radius: 0.12rem;
  line-height: inherit;
}
.add-subtract .cut {
  font-size: 0.36rem;
  background: var(--theme-color);
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  width: 0.38rem;
  height: 0.38rem;
  text-align: center;
  line-height: 0.3rem;
}

.good-pop .good-sure {
  width: 100%;
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  color: #fff;
  background: #199ed8;
  margin: 0.2rem auto 0;
  font-size: 0.3rem;
}

/* 购物车弹窗活动 */
.good-pop .good-specifi .specifi-item .active-item {
  width: 100%;
  font-size: 0.3rem;
  color: var(--theme-color);
  padding: 0.06rem 0;
  box-sizing: border-box;
}
.good-pop .good-specifi .specifi-item .active-item label {
  margin-left: 0.2rem;
}

.good-code {
  font-size: 0.28rem;
}

/* 编辑商品数量弹窗 */
.popup-box {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 11000;
}
.popup-cont {
  position: fixed;
  width: 70%;
  padding: 0.2rem 0 0;
  box-sizing: border-box;
  background: #fff;
  border-radius: 0.12rem;
  left: 15%;
  top: 25%;
  z-index: 11001;
}
.popup-cont .good-tit {
  width: 100%;
  text-align: center;
  font-size: 0.3rem;
  margin-bottom: 0.2rem;
}
.popup-cont .good-num-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.1rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
}
.popup-cont .good-num-box .reduce-g {
  font-size: 0.39rem;
}
.popup-cont .good-num-box input {
  width: 2rem;
  height: 0.6rem;
  padding: 0.1rem;
  margin: 0 0.12rem;
  text-align: center;
  border: 0.02rem solid #c9c4c4;
  border-radius: 0.12rem;
  font-size: 0.3rem;
}
.popup-cont .good-num-box .add-g {
  font-size: 0.39rem;
}
.popup-cont .good-btn {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

.popup-cont .good-btn .btn-g {
  width: 50%;
  font-size: 0.3rem;
  text-align: center;
  color: #999;
}
.popup-cont .good-btn .btn-g.blue {
  color: #58a0f2;
}

/* 时间选择器 */
.van-picker__title {
  font-size: 18px;
 
}
/* 规格 */
.specification{
justify-content: space-between!important;
}
.specification div:nth-child(1) span:nth-child(2){
  font-size: .28rem ;
}
.specification div:nth-child(2){
  font-size: .32rem ;
  padding-right: .5rem;
}
.addContent{
  font-size: .28rem;
  /* width: 8.28rem ; */
   overflow-wrap: break-word;
 font-family: 'SimHei',sans-serif;
 color: #666;
  
}
.addContent img{
	width: 100% !important;
}
.noData{
width: 2rem;
    height: 2rem;
    position: absolute;
    top: 1.8rem;
    left: 2.5rem;
    background-color: rgba(128, 127, 127, 0.8);
    border-radius: 50%;
    text-align: center;
    line-height: 2rem;
    font-size: .5rem;
    color: white;
}

embed{
	width: 100% !important;
}

</style>
