import pub from '../../../../public/public.js'
// 品牌专区
function queryList(model,callBack) {
    return pub.request('get','/api/brand/queryList',model,callBack)
}

// 品牌列表
function GoodsPageList(model,callBack) {
    return pub.request('get','/api/brand/queryBrandGoodsPageList',model,callBack)
}

// 品牌详情
function queryDetail(model,callBack) {
    return pub.request('get','/api/brand/queryDetail',model,callBack)
}


export default {
	queryList,
    GoodsPageList,
    queryDetail
}