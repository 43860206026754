<template>
    <div class="contain-box">

    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    mounted(){
        var that = this;
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js';
        document.body.appendChild(s);
        
        // 预付款单
        var qrAdvancePay = that.$route.query.qrAdvancePay;
        window.onload =()=>{   //初始化产生一个全局变量 AlipayJSBridge
            
            if(qrAdvancePay){
                this.tradePayReady(qrAdvancePay);
            }
              
        }
    },
    methods:{
        // 新版林创支付宝支付
        tradePay(tradeNO){
            var that = this;
            AlipayJSBridge.call("tradePay", {
                tradeNO: tradeNO,
            }, function (res) {
                if ("9000" == res.resultCode) {  // 支付成功
                    console.log("支付成功");
                    that.$toast("支付成功");
                    setTimeout(function(){
                        window.AlipayJSBridge.call('closeWebview');
                    },2000)
                }else if(res.resultCode == "6001"){   // 用户中途取消
                    window.AlipayJSBridge.call('closeWebview');
                }else if(res.resultCode == '6002'){   // 网络链接出错
                    that.$toast("网络连接出错，请重新支付");
                    setTimeout(function(){
                        window.AlipayJSBridge.call('closeWebview');
                    },2000)
                }else if(res.resultCode == "4000"){
                    that.$toast("订单支付失败，请重新支付");
                    setTimeout(function(){
                        window.AlipayJSBridge.call('closeWebview');
                    },2000)
                }
            });
            
            
        },
        
        // 由于js的载入是异步的，所以可以通过该方法，当AlipayJSBridgeReady事件发生后，再执行callback方法
        tradePayReady(tradeNO){
            var that = this;
            if (window.AlipayJSBridge) {
                that.tradePay(tradeNO);
            } else {
                document.addEventListener('AlipayJSBridgeReady', that.tradePay(tradeNO), false);
            }
        },
    }
}
</script>