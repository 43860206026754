<template>
    <div class="container">
		<div class="book-li">
			<span class="book-li-tag">商品名称</span>
			<input class="book-li-ipt" type="text" placeholder="请输入商品名称" v-model="goodsName" />
		</div>
		<div class="book-li">
			<span class="book-li-tag">规格</span>
			<input class="book-li-ipt" type="text" placeholder="请输入商品规格" v-model="specs" />
		</div>
		<div class="book-li">
			<span class="book-li-tag">数量</span>
			<input class="book-li-ipt" type="number" placeholder="请输入求购数量" v-model="bookNum" />
		</div>
		<div class="book-li">
			<span class="book-li-tag">价格</span>
			<input class="book-li-ipt" type="number" placeholder="请输入商品价格" v-model="price" />
		</div>
		<div class="book-li">
			<span class="book-li-tag">厂家</span>
			<input class="book-li-ipt" type="text" placeholder="请输入厂家名称" v-model="factory" />
		</div>
		<div class="book-li">
			<span class="book-li-tag">补充说明</span>
			<input class="book-li-ipt" type="text" placeholder="请输入补充说明" v-model="remark" />
		</div>
		<div class="book-li">
			<span class="book-li-tag">截止日期</span>
			<span class="book-li-date" @click="showDate=true">{{endDate?endDate:"请选择日期"}}</span>
		</div>
		<div class="book-li">
			<span class="book-li-tag img">图片</span>
			<img v-if="imgSrc" class="book-li-img" :src="imgSrc" @click="uploadImg" />
			<img v-else class="book-li-img" src="@/assets/images/icon_uptp.png" @click="uploadImg" />
			<input  style="display: none" type="file" accept="image/*" @change="uploadFile" name="upload_file" ref="upload" />
		</div>
      
		<div class="add-box">
			<button class="add-btn" @click="saveBookTap">保 存</button>
		</div>	
		
		<van-popup v-model="showDate" round position="bottom">
			<van-datetime-picker
				type="date"
				@cancel="showDate=false"
				@confirm="confirmDate"
				:columns-order="['year', 'month', 'day']"/>
		</van-popup>
    </div>
</template>

<script>
export default {
    data(){
        return{
            id: "",
			bookInfo: null,
			goodsName: "",
			specs: "",
			bookNum: "",
			price: "",
			factory: "",
			remark: "",
			endDate: "",
			imgSrc: "",
			
			showDate: false
        }
    },
    created(){
		this.id = this.$route.query.id;
		if(this.id){
			this.queryBookInfo();
		}
    },
    methods:{
		//查询登记信息
		queryBookInfo(){
		    var that = this;
		    that.$toast.loading({
		        message: '加载中',
		        duration: 0
		    })
		    that.public.request('get','/api/sysUserInfo/GetMyListInfo',{
				id: that.id,
		    },res => {
		        that.$toast.clear();
		        if(res.data.errorCode == '00'){
					that.bookInfo = res.data.rows;
		        }else{
		          that.$toast(res.data.errorMsg);
		        }
		    })
		},
		
		confirmDate(value) {
		  let yy = new Date(value).getFullYear();
		  let mm = new Date(value).getMonth() + 1;
		  let dd = new Date(value).getDate();
		  mm = mm >= 10 ? mm : "0" + mm;
		  dd = dd >= 10 ? dd : "0" + dd;
		  this.endDate = yy + "-" + mm + "-" + dd;
		  this.showDate = false;
		},
		
		uploadImg(){
			this.$refs.upload.click();  
		},
		uploadFile(){
			var that = this;
			var files = that.$refs.upload.files;
			if (files.length === 0) {
			    return;
			}
			that.$toast.loading({
			    message: '上传中...',
			})
			var formData = new FormData();
			formData.append("file", files[0]);
			that.public.request("post","api/Tools/UploadImg?key=book",
			  formData,(res) => {  
			    that.$toast.clear();
			    if (res.data.errorCode == "00") {
			      // that.$toast('上传成功');
				  that.imgSrc = res.data.rows;
			    } else {
			      that.$toast(res.data.errorMsg);
			    }
			  }
			);
		},

        // 保存
        saveBookTap(){
			if(!this.goodsName){
				return this.$toast("请输入商品名称")
			}
			if(!this.specs){
				return this.$toast("请输入商品规格")
			}
			if(!this.factory){
				return this.$toast("请输入生产厂家")
			}
			let that = this;
			that.public.request('post','/api/sysUserInfo/PurchaseRegistration',{
				// Id: "",
				SpName: this.goodsName,
				EndTime: this.endDate,
				Style: this.specs,
				Number: this.bookNum,
				Price: this.price,
				Factory: this.factory,
				Info: this.remark,
				ImgUrl: this.imgSrc
			},res => {
				if(res.data.errorCode == '00'){
					that.$toast("保存成功");
					setTimeout(()=>{
						that.$router.go(-1);
					},1000)
				}else{
					that.$toast(res.data.errorMsg);
				}
			})
        },

    }
}
</script>


<style scoped>
	.book-li{
		padding: 10px 20px;
		display: flex;
		align-items: center;
		font-size: 16px;
	}
	.book-li-tag{
		width: 120px;
	}
	.book-li-tag.img{width: 85px;}
	.book-li-ipt,.book-li-date{
		width: 100%;
		height: 40px;
		padding: 0 10px;
		border: 1px #eee solid;
		border-radius: 5px;
	}
	.book-li-date{
		line-height: 40px;
	}
	.book-li-img{
		width: 150px;
		height: 150px;
	}
   
	.add-box{
		position: fixed;
		width: 100%;
		height: 80px;
		bottom: 0;
		left: 0;
		background: #fff;
		z-index: 1;
	}
	.add-box .add-btn{
		width: 90%;
		height: 40px;
		line-height: 40px;
		margin: 10px 5%;
		text-align: center;
		background: #2da5eb;
		color: #fff;
		font-size: 15px;
		border-radius: 5px;
	}
</style>