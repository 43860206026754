<template>
    <div class="container-box">
        <van-tabs  class="tabs"  @click="onClick" color='#007AFF' title-active-color='#007AFF' line-width='3rem' tab-font-size='20px' :sticky=true offset-top='.9rem'>
             <van-tab title="优惠卡卷">
                  <van-list  class="p5 coupon-list" v-model="loading"  :finished="finished"   finished-text="没有更多了"  @load="onLoad('20')" :offset="10"  :immediate-check='false'>
                    <div class="coupon-item " :style="'background:url('+couponBg+') no-repeat'" v-for="(item,index) in rollList" :key="index">
                      <div class="flex-wrap">
                          <div class="coupon-left flex-column;">
                            <div class="item-price font-red">￥{{item.CouponAmount}}元 </div>
                            <div class="item-explain">单笔订单实付满 <b>{{item.CouponMinOrderAmount}}</b> 元可用 </div>
                         </div>
                         <div class="coupon-right flex-center">
                            <div class="recevie-but active">已领取</div>   
                         </div>
                      </div>
                      <div class="item-time item-explain">
                           <span style="padding-right:0.25rem">消耗积分:<b>{{item.Points}}</b></span>
                            <span >兑换日期 {{item.SubmitDate}}</span>   
                      </div>
                      <div  class="item-explain" >有效日期 {{item.CouponBeginTime}}/{{item.CouponEndTime}}</div>
                   </div>
                   <div v-if="noRollData" class="no-data">
                        <img src="@/assets/images/cart/noActiveDrug.png" alt="" />
                   </div>
               </van-list>
            </van-tab>
           <van-tab title="积分好物">
               <van-list  v-model="loading" :finished="finished"  finished-text="没有更多了"  @load="onLoad(0)" :offset="10" :immediate-check='false '>
                <div>
                    <integral-item  v-for="(item,id) in shopList" :key="id" :itemList='item' :type='record'></integral-item>  
                </div>
               <div v-if="noShopData" class="no-data">
                    <img src="@/assets/images/cart/noActiveDrug.png" alt="" />
                </div>
              </van-list>   
           </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import integralItem from '@/components/integralGoodItem.vue'
export default {
    components:{
        integralItem,
    },
    data(){
        return{
            couponBg: require('@/assets/images/bg_cupon_mycoupon.png'),
            page:1,
            rows: 10,
            total:1 ,
            type:'',
            rollList: [],
            shopList:[],
            loading:false,
            finished:false,
            record:'record',
            noRollData:false,
            noShopData:false,
        }
    },
    created(){
          this.onLoad(20)
    },
    methods:{
        // 切换导航
        onClick(name,title){
            var that=this;
             that.loading= true,
             that.finished=false,
            that.page=1;
            that.rollList=[];
            that.shopList=[];
            that.noShopData=false;
            that.noRollData=false;
            if(title==='优惠卡卷'){   
                that.onLoad(20);
                that.type=20;
            }else if(title==='积分好物'){
                that.onLoad(10);
                that.type=10;
            }
        },
        onLoad(type){
           var that=this;
           var data={
             page:that.page,
             rows:that.rows,
             type:type?type:that.type,
             total: that.total,
             customerId:sessionStorage.getItem('customer'),
           }
           if(that.page==1){
               that.$toast.loading({
                   message:'加载中'
               });
           }
          setTimeout(()=>{
               that.public.request(
               'post',
                'api/PointsOrders/QueryPage',
                data,
                (res)=>{              
                    that.$toast.clear();       
                    this.loading = false; 
                    if(res.data.errorCode=='00'){
                        if(type==20){
                            that.rollList.push(...res.data.rows);
                        }else if(type==10 || that.type==10){
                             that.shopList.push(...res.data.rows);                 
                        }
                        if(that.page*that.rows>res.data.records){//判断是否加载完毕
                            that.finished = true;
                            if(!that.rollList.length){
                                that.noRollData=true;
                            }
                           if(!that.shopList.length){
                                that.noShopData=true;   
                            } 
                        }else{
                            that.page++;
                        }

                    }else{
                      that.$toast(res.data.errorMsg);
                    }      

                }
           );
          },500);

        }
    }
}
</script>

<style scoped>
    /* 顶部切换 */
    .top-nav{ width:100%;border-bottom:0.02rem solid #eee;position:fixed;top:0.9rem;left:0;z-index:10;background:#fff; }
    .top-nav .nav-item{ width:50%;height:1rem;line-height:1rem;text-align:center;font-size:0.32rem; }
    .top-nav .nav-item.active{ border-bottom:0.03rem solid #007aff; }


    .list-box{ margin-top:1.2rem; }
    /* 优惠卡券 */
    .coupon-list{ width:100%;padding:0.2rem;box-sizing:border-box; }
    .coupon-list .coupon-item{ width:100%;height:2.4rem;padding:0.25rem 0.4rem;box-sizing:border-box;background-size:100% 100% !important;margin-bottom:0.2rem; }
    .coupon-list .coupon-item .coupon-left{ width:75%;justify-content:space-between; }
    .coupon-list .coupon-item .coupon-left .item-price{ width:75%;font-size:0.5rem;text-align:center;font-weight:600; }
   .item-explain{ font-size:0.24rem;color:rgb(107, 107, 107); }
    .coupon-list .coupon-item .coupon-left .item-time span{ font-size:0.24rem; }

    .coupon-list .coupon-item .coupon-right{ flex:1; }
    .coupon-list .coupon-item .coupon-right .recevie-but{ width:100%;height:0.6rem;line-height:0.6rem;border-radius:0.28rem;color:#fff;text-align:center;font-size:0.3rem; }
    .coupon-list .coupon-item .coupon-right .recevie-but.active{ background:rgb(177, 177, 177); }
    .coupon-list .coupon-item .coupon-right .recevie-but.gray{ background:rgb(211, 210, 210); }
    .coupon-list .coupon-item .coupon-right .recevie-but.red{ background:#f58b8b; }

    /* 积分好物 */
    .integral-list{ width:100%; }
    .van-tab__text{font-size: .36rem /* 18/50 */;}
    
</style>