<template>
	<div>
		<a :href="newUrl">
			<div class="wx-scan">
				扫一扫
			</div>
		</a>
		
	</div>
</template>


<script>
	
	export default {
		data() {
			return {
				newUrl: '',		// 路径
			}
		},
		created(){
			var that = this;
			var url = location.href;
			that.newUrl = 'http://sao315.com/w/api/saoyisao?redirect_url='+url;
			var ua = window.navigator.userAgent.toLowerCase();
			 if(ua.match(/MicroMessenger/i) == 'micromessenger'){
				that.onWxScan();
			 }else{
	
			 }
			

		},
		mounted() {
			
			
		},
		destroyed(){
			
		},
		methods: {
			onWxScan(){
				var that = this;
				var code = this.$route.query.qrresult;
				var index=code.indexOf(",");
				if(index < 0){
					that.$toast('请描扫条形码');
					return false;
				}
				that.$router.replace({
					path: '/allProducts',
					query: {
						headTit: '全部商品',
						code: code,
					}
				})
				
			},
			
		}
	}
</script>

<style scoped>
	.wx-scan{ width:80%;height:0.8rem;text-align:center;line-height:0.8rem;margin:1rem auto 0;background:#59EF7E;border-radius:0.18rem;color:#fff;font-size:0.3rem; }
</style>
