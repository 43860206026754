<template>
    <div id="app" :style="{paddingTop:marTop+'rem'}">
        <div v-if="appType=='app'||appType=='ios'" class="app-bar" :style="{background:themeColor}"></div>
        <div class="tab-box" v-if="!$route.meta.isTop">
            <div class="tab-top flex-wrap" :style="{top:marTop+'rem'}">
                <img v-if="isCanBack" @click="navigationBack" class="tab-img" src="@/assets/images/tab/icon_left1.png" alt="">
                <div class="tab-tit" v-if="!headTitle">{{navTitle}}</div>
                <div class="tab-tit" v-if="headTitle">{{headTitle}}</div>
            </div>
        </div>

        <!-- 详情返回按钮 -->
        <div class="back-box" v-if="$route.meta.isBackPage" @click="navigationBack" :style="{top:topHeight+'rem'}">
            <img src="@/assets/images/icon_back.png" alt="">
        </div>
		<div class="back-box" v-if="$route.meta.register" @click="backLogin" :style="{top:topHeight+'rem'}">
		    <img src="@/assets/images/icon_back.png" alt="">
		</div>
        
        <keep-alive>
            <router-view v-if='$route.meta.keepAlive'/>
        </keep-alive>
        <router-view v-if='!$route.meta.keepAlive' />

        
        <div class="tabbar-box" v-if="$route.meta.isTabBar" v-show="hidshow">
            <div class="tabbar-bot flex-wrap" :class="{ 'phone': appType=='ios' }">
                <div class="tab-item flex-column" v-for="(item,index) in tabBarList" :key="index" @click="changeTab(index)">
                    <router-link :to="item.path">
                        <img v-if="index+1 != currentTab" class="tab-icon" :src="item.icon" alt="底部图标">
                        <img v-if="index+1 == currentTab" class="tab-icon" :src="item.selectedIcon" alt="底部图标">
                        <span :class="index+1 == currentTab ? 'active': ''">{{item.name}}</span>
                        <span v-if="index+1 == 4 && cartNum > 0" class="cart-num">{{cartNum}}</span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tabBarList: [
                    {
                        name: '首页',
                        icon: require("./assets/images/tab/icon_index.png"),
                        selectedIcon: require("./assets/images/tab/icon_index_d.png"),
                        path: "/"
                    },
                    {
                        name: '分类',
                        icon: require("./assets/images/tab/3.png"),
                        selectedIcon: require("./assets/images/tab/3.1.png"),
                        path: "/classifcation"
                    },
                    {
                        name: '搜索',
                        icon: require("./assets/images/tab/icon_search.png"),
                        selectedIcon: require("./assets/images/tab/icon_search_d.png"),
                        path: "/searchHistory"
                    },
                    {
                        name: '购物车',
                        icon: require("./assets/images/tab/icon_cart.png"),
                        selectedIcon: require("./assets/images/tab/icon_cart_d.png"),
                        path: "/cart"
                    },
                    {
                        name: '我的',
                        icon: require("./assets/images/tab/icon_mine.png"),
                        selectedIcon: require("./assets/images/tab/icon_mine_d.png"),
                        path: "/mine"
                    }
                ],
                isCanBack: false,    //顶部导航返回按钮
                docmHeight: window.innnerHeight || document.documentElement.clientHeight || document.body.clientHeight,  //默认屏幕高度       
                showHeight: window.innnerHeight || document.documentElement.clientHeight || document.body.clientHeight,   //实时屏幕高度        
                hidshow:true , //显示或者隐藏footer,
                themeImgObj:{},   // 主题背景图片
                themeColor: '#00a2ea',     // 主题色
				appType: 'app',     // 类型
                topHeight: 0.2,     // 顶部距离
                marTop: 0.8,  // 外边距
            }
        },
        created(){
            var that = this;
            // tabbar主题色图片
            var config = sessionStorage.getItem("orgWebconfig");
            config = JSON.parse(config);
            if(!config){
                that.$toast.loading({
                    message: "加载中",
                    duration: 0,
                });
                that.public.getWebconfig().then(res => {
                    that.$toast.clear();
                    var orgWebconfig = res.data.rows;
                    sessionStorage.setItem("orgWebconfig",JSON.stringify(orgWebconfig));
                    if(orgWebconfig.AppTheme){  // 后台设置了主题色
                        // console.log(orgWebconfig.SkinStyle);
                        that.themeColorImg.themeImg(orgWebconfig.AppTheme);
                        var style = document.createElement('style');
                        style.innerText = ":root{--theme-color:"+orgWebconfig.AppTheme+";}"
                        document.head.appendChild(style);
                        that.themeColor = orgWebconfig.AppTheme;
                        // console.log(that.themeColorImg.imgObj);
                        var themeImgObj = that.themeColorImg.imgObj;
                        that.tabBarList[0].selectedIcon = themeImgObj.tabbarIndex;
                        that.tabBarList[1].selectedIcon = themeImgObj.tabbarFl;
                        that.tabBarList[2].selectedIcon = themeImgObj.tabbarSearch;
                        that.tabBarList[3].selectedIcon = themeImgObj.tabbarCart;
                        that.tabBarList[4].selectedIcon = themeImgObj.tabbarMine;
                    }else{  // 后台没有设置主题色
                        var style = document.createElement('style');
                        style.innerText = ":root{--theme-color:#D5251D;}";
                        document.head.appendChild(style);
                        that.themeColor = "#D5251D";
                        that.themeColorImg.themeImg("#D5251D");
                        var themeImgObj = that.themeColorImg.imgObj;
                        that.tabBarList[0].selectedIcon = themeImgObj.tabbarIndex;
                        that.tabBarList[1].selectedIcon = themeImgObj.tabbarFl;
                        that.tabBarList[2].selectedIcon = themeImgObj.tabbarSearch;
                        that.tabBarList[3].selectedIcon = themeImgObj.tabbarCart;
                        that.tabBarList[4].selectedIcon = themeImgObj.tabbarMine;
                    }
                }).catch(err => {
                    that.$toast.clear();
                })
            }else{
                // console.log(config);
                if(config.AppTheme){  // 后台设置了主题色
                    // console.log(orgWebconfig.SkinStyle);
                    that.themeColorImg.themeImg(config.AppTheme);
                    var style = document.createElement('style');
                    style.innerText = ":root{--theme-color:"+config.AppTheme+";}"
                    document.head.appendChild(style);
                    that.themeColor = config.AppTheme;
                    var themeImgObj = that.themeColorImg.imgObj;
                    var themeImgObj = that.themeColorImg.imgObj;
                    that.tabBarList[0].selectedIcon = themeImgObj.tabbarIndex;
                    that.tabBarList[1].selectedIcon = themeImgObj.tabbarFl;
                    that.tabBarList[2].selectedIcon = themeImgObj.tabbarSearch;
                    that.tabBarList[3].selectedIcon = themeImgObj.tabbarCart;
                    that.tabBarList[4].selectedIcon = themeImgObj.tabbarMine;
                }else{  // 后台没有设置主题色、
                    var style = document.createElement('style');
                    style.innerText = ":root{--theme-color:#D5251D;}"
                    document.head.appendChild(style);
                    that.themeColor = "#D5251D";
                    that.themeColorImg.themeImg("#D5251D");
                    var themeImgObj = that.themeColorImg.imgObj;
                    that.tabBarList[0].selectedIcon = themeImgObj.tabbarIndex;
                    that.tabBarList[1].selectedIcon = themeImgObj.tabbarFl;
                    that.tabBarList[2].selectedIcon = themeImgObj.tabbarSearch;
                    that.tabBarList[3].selectedIcon = themeImgObj.tabbarCart;
                    that.tabBarList[4].selectedIcon = themeImgObj.tabbarMine;
                }
            }
            
        },
        
        
        mounted(){
            var that = this;
            this.setClientType();
            // console.log("---------mounted");
            // const s = document.createElement('script');
            // s.type = 'text/javascript';
            // s.src = 'https://res.wx.qq.com/open/js/jweixin-1.3.2.js';
            // document.body.appendChild(s);
            // window.onload =()=>{   //初始化产生一个全局变量 AlipayJSBridge
            //     that.wxRectptionReady();
            // }
            
            // 消息订阅
            that.bus.$on('hidShow', (value) => {
                //接收参数，触发方法相应的方法等操作
                console.log("----订阅消息-----",value);
                if(value == 0){
                    that.hidshow = false;
                }else if(value == 1){
                    that.hidshow = true;
                }
            })
            
        },
        
        

        methods: {
            /* 判断是否在小程序前台
              由于js的载入是异步的，所以可以通过该方法，当WeixinJSBridgeReady事件发生后，再执行callback方法
            */
            // wxRectptionReady(){
            //     var that = this;
            //     if (window.WeixinJSBridge) {
            //         that.wxJsBridge();
            //     } else {
            //         document.addEventListener('WeixinJSBridgeReady', that.wxJsBridge(), false);
            //     }
            // },
            // wxJsBridge(){
            //     var that = this;
            //     var mobile = localStorage.getItem("mobile");
            //     var password = localStorage.getItem("password");
            //     if(!mobile){
            //         return false;
            //     }
            //     mobile = that.public.decrypt(mobile);
            //     password = that.public.decrypt(password);
            //     // 监听小程序是否在前台
            //     var clientType = localStorage.getItem("clientType");
            //     if(clientType == "applets"){
            //         WeixinJSBridge.on('onPageStateChange',function(res){
            //             console.log('res is active', res.active);
            //             if(res.active){
            //                 that.getLogin(mobile,password);
            //             }
            //         })
            //     }
                
                
            // },
            //  重新登录 获取token
            // getLogin(mobile,password){
            //     var that = this;
            //     var orgId = localStorage.getItem("orgId");
            //     var clientType = localStorage.getItem("clientType");
            //     var Applets = that.$route.query.openid;
            //     var data = {
            //         Mobile: mobile,
            //         Password: password,
            //         OrgId: orgId,
            //     }
            //     console.log(data);
            //     // 获取微信小程序的openid
            //     // if (clientType == "applets" && Applets) {
            //     //     data.Applets = that.Applets;
            //     // }

            //     that.public.request("post","/api/AppLogin/Login",data, (res) => {
            //         if (res.data.errorCode != "99") {
            //             sessionStorage.setItem("member_token",res.data.rows);
            //             localStorage.setItem("orgId", res.data.errorMsg);
            //             sessionStorage.setItem("customer",res.data.extend.customerId);
            //             localStorage.setItem("clientType", clientType); // app类型
            //             var webconfig = JSON.stringify(res.data.extend.webConfig);
            //             localStorage.setItem("webconfig", webconfig); 
            //         } 
            //     })

            // },


            // 把app类型保存到本地
            setClientType() {
                var that = this;
                var clientType = localStorage.getItem("clientType");
				if(clientType){
					switch (clientType) {
					    case "applets":
					        that.appType = "applets";
					        that.topHeight = 0.2;
					        that.marTop = 0;
					        break;
					    case "weixin":
					        that.appType = "weixin";
					        that.topHeight = 0.2;
					        that.marTop = 0;
					        break;
						case "app":
						    that.appType = "app";
						    that.topHeight = 1;
						    that.marTop = 0.8;
						    break;
						case "ios":
							that.appType = "ios";
							that.topHeight = 1;
							that.marTop = 0.8;
							break;
					    default:
							break;
					}
				}else{
					clientType = that.$route.query.clientType;
					if(!clientType){
						if (that.IsMiniProgram()) {
						    clientType = "applets";
						    that.appType = "applets";
						    that.topHeight = 0.2;
							that.marTop = 0;
						} else if (that.isWx()) {
						    clientType = "weixin";
						    that.appType = "weixin";
						    that.topHeight = 0.2;
							that.marTop = 0;
						}else if(that.appSource()){
							clientType = "ios";
							that.appType = "ios";
							that.topHeight = 1;
							that.marTop = 0.8;
						} else {
						    clientType = "app";
						    that.appType = "app";
						    that.topHeight = 1;
							that.marTop = 0.8;
						}
					}
					console.log("应用类型：",clientType);
					localStorage.setItem('clientType',clientType);
				}
                
            },
			//判断是否小程序浏览器加载
			IsMiniProgram(){
				let mini = false;
				if (/MicroMessenger/i.test(navigator.userAgent)) {
					//ios的ua中无miniProgram，很坑爹,但都有MicroMessenger（表示是微信浏览器）
					this.wx.miniProgram.getEnv((res) => {
						if (res.miniprogram) {
							mini = true;
						}
					});
				}
				return mini;
			},
            // 微信公众号验证
            isWx() {
                if (/MicroMessenger/i.test(navigator.userAgent)) {
                    return true;
                }
                return false;
            },
			
			//判断是不是苹果手机
			 appSource() {
			    const u = navigator.userAgent;
			    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
				if(isiOS){
					return true;
				}
				return false;
			},


            // 切换底部导航
            changeTab: function (index) {
                this.$store.commit('setCurrentTab', index + 1);
            },
            // 返回上一个页面
            navigationBack() {
				// console.log("返回上一页")
                this.$router.go(-1);
            },
			
			//返回登录界面
			backLogin(){
				// window.baseUrl.xcxLogin
				if(this.IsMiniProgram()){
					this.wx.miniProgram.reLaunch({
					    url: window.baseUrl.xcxLogin?'/pages/login/index':'/pages/login/oldLogin/index',
					})
					return;
				}
				this.$router.go(-1);
			},
			
        },
        computed: {
             
            currentTab: {
                get() {
                    // console.log(this.$route);
                    // console.log(this.$store.state);
                    if (this.$store.state.currentTab) {
                        return this.$store.state.currentTab;
                        
                    } else {

                        
                        if (this.$route.name == '首页') {
                            // 顶部标题
                            // this.$store.commit('setHeadTitle', '首页');
                            this.isCanBack = false;
                            return 1;
                        } else if (this.$route.name == '分类') {
                            // 顶部标题
                            // this.$store.commit('setHeadTitle', '搜索');
                            this.isCanBack = false;
                            return 2;
                        }else if (this.$route.name == '搜索') {
                            // 顶部标题
                            // this.$store.commit('setHeadTitle', '搜索');
                            this.isCanBack = false;
                            return 3;
                        } else if (this.$route.name == '购物车') {
                            // 顶部标题
                            // this.$store.commit('setHeadTitle', '购物车');
                            this.isCanBack = false;
                            return 4;
                        } else if (this.$route.name == '我的') {
                            // 顶部标题
                            // this.$store.commit('setHeadTitle', '我的');
                            this.isCanBack = false;
                            return 5;
                        }

                    }
                }
            },
            navTitle: {
                get() {
                    var name = this.$route.name;
                    if (name != '首页' && name != '搜索' && name != '购物车' && name != '我的' && name != '分类') {

                        this.isCanBack = true;
                    }
					if(name == "用户注册"){
						this.isCanBack = false;
					}
                    return this.$route.name;
                }
            },
            // 购物车数量
            cartNum: {
                get() {
                    var name = this.$route.name;
                    if (name != '首页' && name != '搜索' && name != '购物车' && name != '我的' && name != '分类') {
                        this.isCanBack = true;
                    }
                    return this.$store.state.cartNum;
                }
            },
            // 设置顶部标题
            headTitle: {
				
                get() {
                    // console.log(this.$store.state);
                    return this.$store.state.headTitle;
                }
            },
            
        },
   
        watch: {
            $route(to, from) {
                var that = this;
                let tabBarList = that.tabBarList;
                for (var i = 0; i < tabBarList.length; i++) {
                    // 如果页面的name不等于底部导航的name，就显示
                    if (to.name == tabBarList[i].name) {
                        that.isCanBack = false;
                        break;
                    } else {
                        that.isCanBack = true;
                    }

                }
                
                if (to.name == '首页') {
                    this.$store.commit('setCurrentTab', 1);
                } else if (to.name == '分类') {
                    this.$store.commit('setCurrentTab', 2);
                }else if (to.name == '搜索') {
                    this.$store.commit('setCurrentTab', 3);
                } else if (to.name == '购物车') {
                    this.$store.commit('setCurrentTab', 4);

                } else if (to.name == '我的') {
                    this.$store.commit('setCurrentTab', 5);
                }

                // 监听小程序是否在前台
                // var clientType = localStorage.getItem("clientType");
                // if(clientType == "applets"){
                //     that.wxRectptionReady();
                // }

            },
            
        },
        
    }
</script>

<style scoped>
      #app {
          /* font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
          color: #2c3e50;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          z-index: -1;
          padding-top: 0.9rem;
      }

      .tab-box{
        width:100%;
        height: 0.9rem;
      }

      .tab-top {
          position: fixed;
          top: 0rem;
          left: 0;
          width: 100%;
          height: 0.9rem;
          padding: 0 0.2rem;
          box-sizing: border-box;
          background: var(--theme-color);
          z-index: 99;
          border-bottom: 0.02rem solid var(--theme-color);
      }

          .tab-top .tab-img {
              width: 0.4rem;
              height: 0.4rem;
          }

          .tab-top .tab-tit {
              flex: 1;
              text-align: center;
              font-size: 0.32rem;
              color:#fff;
              /* padding-right:0.5rem; */
          }


      .tabbar-box {
          width: 100%;
          height: 1.1rem;
      }

      .tabbar-bot {
          position: fixed;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1.1rem;
          justify-content: space-around;
          background: #fff;
          border-top: 0.02rem solid #eee;
          z-index: 10000;
      }

          .tabbar-bot .tab-item {
              align-items: center;
              position: relative;
          }

              .tabbar-bot .tab-item .tab-icon {
                  width: 0.6rem;
                  height: 0.6rem;
                  margin: 0 auto;
              }

              .tabbar-bot .tab-item span {
                  font-size: 0.24rem;
                  color: #333;
                  display: block;
                  text-align: center;
              }

                  .tabbar-bot .tab-item span.active {
                      color: var(--theme-color);
                  }

              /* 购物车数量 */
              .tabbar-bot .tab-item .cart-num {
                  position: absolute;
                  top: 0;
                  right: 0;
                  font-size: 0.24rem;
                  width: 0.32rem;
                  height: 0.32rem;
                  text-align: center;
                  line-height: 0.32rem;
                  border-radius: 50%;
                  background: var(--theme-color);
                  color: #fff;
              }
        .phone{
                height: 1.29rem !important;
        }

    /* 详情返回按钮 */
    .back-box{ width:0.5rem;height:0.5rem;position:fixed;left:0.2rem;z-index:99; }
    .back-box img{ width:100%;height:100%; }
	.app-bar{width:100%;height:0.81rem;position:fixed;top:0;left:0;z-index:6}
</style>
