<template>
	<div class="goodsList" >
	 <div class="goods-item" v-for="(item,index) in list" :key="index" @click="goToDetails(item)"> 
		 <div class="item-img" v-if="active==undefined">
			<img :src="item.ImgSrc[0]" alt="" v-if="item.ImgUrl">
			<img class="floor-good" v-else-if="item.SpPicExtend" :src="item.SpPicExtend" />
			<img class="floor-good" v-else src="@/assets/images/no_good.png" alt="商品图片">
		</div>
		<div class="item-img" v-if="active!=undefined">
		   <img :src="item.ImgUrl" v-if="item.ImgUrl" alt="">
		   <img class="floor-good" v-else src="@/assets/images/no_good.png" alt="商品图片">
		</div>
		<div v-if="item.kcshl<=0" class="noShopImg-box">
			<span class="noShopImg">售罄</span>
		</div>
	   <!-- 边框图 -->
		<!-- <img class="icon-bj" src="../assets/images/aa.png" alt=""> -->
		<!-- <div class="detail" >
			<div class="guiGe">{{item.shpgg}}</div>
			<div class="guiGe">{{item.Style}}</div>
			<div class="details">
				<b v-if="!token">资质通过后可见</b>
				<b v-if="item.CurActivityData==null&&token&&active==undefined">{{(item.zdshj)}}/{{item.dw}}</b>
				<b v-if="item.CurActivityData!=null&&token&&active==undefined">{{(item.CurActivityData.ActivityPrice)}}/{{item.dw}}</b>
				<b v-if="token&&active!=undefined">{{(item.SoldPrice)}}/{{item.Unit}}</b>
				<b>{{item.kcshl>0?"有货":"无货"}}</b>
			</div>
		</div> -->
	   <!-- 正常 -->
		<div class="item-info">
			<div class="info-name font-18">{{active?item.GoodsName:item.spmch}}</div>
			<div class="info-company font-hui" >{{active?item.Factory:item.shengccj}} </div>
			<div class="info-company font-1" >规格:{{item.shpgg}}</div>
			<div class="info-company font-1" style="display: flex; justify-content: space-between;">
				<!-- <span v-if="item.sxrq">{{item.sxrq.indexOf('/')==-1?'效期':'近远效期'}}:{{item.sxrq}}</span> -->
				<span v-if="item.sxrq">近效期:{{item.sxrq}}</span>
				<span v-if="token" :style="{color: item.kcshl>0?'#09A200':'#AF0603'}">{{item.kcshl>0?"库存:"+ (!ShowStock?item.kcshl:"有货"):"无货"}}</span>
			</div>
			<div class="info-cura font-1" v-if="item.ActivityCacheData">{{item.ActivityCacheData.ActivityTypeName}}</div>
			<div class="info-cura font-1" v-else-if="item.CurActivityData">{{item.CurActivityData.ActivityTypeName}}</div>
			<div class="new-price">
				<b v-if="!token" style="display: flex;align-items: center;"><span class="price-num">￥</span><span>资质通过后可见</span></b>
				<b v-if="item.CurActivityData==null&&token&&active==undefined"><span class="price-num">￥{{(item.zdshj)}}</span>/{{item.dw}}</b>
				<b v-if="item.CurActivityData!=null&&token&&active==undefined"><span class="price-num">￥{{item.CurActivityData.ActivityPrice}}</span>/{{item.dw}} <span class="price-old">￥{{item.CurActivityData.MarketPrice}}</span> </b>
				<b v-if="token&&active!=undefined"><span class="price-num">￥{{item.SoldPrice}}</span>/{{item.Unit}}</b>
				<!-- <b v-if="token"><span :style="{color: item.kcshl>0?'#09A200':'#AF0603'}">{{item.kcshl>0?"库存："+item.kcshl:"无货"}}</span></b> -->
				
				<img class="shop-cart" v-if="item.kcshl>0&&item.CurActivityData==null&&active==undefined" @click.stop="CartShow(item)" :src="themeImgObj.cartImg" alt="">
				<img class="shop-cart" v-if="item.kcshl>0&&item.CurActivityData!=null&&active==undefined" @click.stop="onCartShow(item)" :src="themeImgObj.cartImg" alt="">
				<img class="shop-cart" v-if="item.kcshl>0&&active=='1'" @click.stop="onCartShow1(item)" :src="themeImgObj.cartImg" alt="">
				<img class="shop-cart" v-if="active=='0'" @click.stop="noCart" :src="themeImgObj.cartImg" alt="" >
				<span class="seck-btn" v-if="item.kcshl<=0" @click.stop="onSupplyTap(item)">缺货登记</span>
			</div>
		</div>
		<!-- <div class="item-money"> -->
			<!-- 活动优惠标签 -->
			<!-- <div class="activity" v-if="item.CurActivityData!=null"><span class="activityItem">{{item.CurActivityData.ActivityTypeName}}</span></div>  
			<div class="activity" v-if="active==undefined&&item.ActivityCacheDataList.length!='0'&&item.CurActivityData==null" >
				<span class="activityItem" v-for="items in item.activeList" :key="items.spid" >{{items}}</span>
			</div> -->
			<!-- <img class="shop-cart" v-if="item.kcshl>0&&item.CurActivityData==null&&active==undefined" @click="CartShow(item)" :src="themeImgObj.cartImg" alt="">
			<img class="shop-cart" v-if="item.kcshl>0&&item.CurActivityData!=null&&active==undefined" @click="onCartShow(item)" :src="themeImgObj.cartImg" alt="">
			<img class="shop-cart" v-if="item.kcshl>0&&active=='1'" @click="onCartShow1(item)" :src="themeImgObj.cartImg" alt="">
			<img class="shop-cart" v-if="active=='0'" @click="noCart" :src="themeImgObj.cartImg" alt="" > -->
		 <!-- </div> -->
			
	   </div>
   </div>
</template>

<script>
import { List } from "vant";
export default {
    props: {
        list:{
            type:Array
        },
        active:{
            type:Number
        }
    },
    data () {
        return {
            token : sessionStorage.getItem('member_token'),
			ShowStock: JSON.parse(localStorage.getItem("ShowStock")),
            ActivityList:[],
        }
    },
    created () {
        // 主题色图片
        this.themeImgObj = this.themeColorImg.imgObj;
		this.list.forEach((item,i)=>{
			if(item.sxrq.indexOf('/')!=-1){
				item.sxrq = item.sxrq.split('/')[0]
			}
		})
    },
	activated(){
		this.themeImgObj = this.themeColorImg.imgObj;
		this.list.forEach((item,i)=>{
			if(item.sxrq.indexOf('/')!=-1){
				item.sxrq = item.sxrq.split('/')[0]
			}
		})
	},
    methods: {
		// 缺货登记弹窗
		onSupplyTap(data) {
			this.$emit('showSupplyDialog',data);
		},
		
        // 去详情
        goToDetails(goodInfo){
            console.log('去详情',goodInfo,this.active);
            this.$emit( 'toDetail',goodInfo,this.active);

        },
        // 普通商品/多规格弹出框
        CartShow(goodInfo){
            this.$emit( 'cartShow',goodInfo.spid,goodInfo,this.active);

        },
         // 活动商品弹出框
        onCartShow(goodInfo){
             this.$emit( 'onCartShow',goodInfo.spid,goodInfo.CurActivityData.PCUrl,goodInfo,this.active);
        },
        noCart(){
            this.$toast('活动还未开始');
        },
        onCartShow1(goodInfo){
            this.$emit( 'onCartShow',goodInfo.spid,'',goodInfo,this.active);
        }

    }
}
</script>

<style scoped>
    .goodsList{display: flex;align-items: center; flex-wrap: wrap; padding: 0.1rem;}
    .goods-item:nth-child(2n-1){margin-right: .1rem;}
    /* .goods-item { position: relative; width:3.35rem; border:0.02rem solid #eee; margin-bottom: 0.3rem;box-shadow: 0px 0px 5px #b9b9b9;} */
	.goods-item { position: relative; width:3.6rem; background: #fff;margin-bottom: 0.2rem;}
    .goods-item  .item-img{ width: 100%;}
	/* .goods-item  .item-img{ width: 100%;  overflow: hidden;} */
    .goods-item  .item-img img{width: 100%;height: 3rem; object-fit: contain;}
	.noShopImg-box{position: absolute; width: 100%; height: 3rem;top: 0;left: 0;font-size: .5rem; background-color: rgba(255, 255, 255, 0.5); display: flex; align-items: center;justify-content: center;}
    .noShopImg{width: 1.3rem; height: 1.3rem;color: white; background-color: rgba(128, 127, 127, 0.8);
	border-radius: 50%;display: inline-block; line-height: 1.3rem; text-align: center;}
    .item-money{padding: 0 .12rem; display: flex;align-items: center; position: relative;}
    .font-13{font-size: 0.25rem;}
    .font-1{    font-size: .2rem;}
    .font-18{font-size: 0.3rem;}
    .money{font-weight: 700;font-size: 0.3rem; }
    .font-hui{color: rgb(139, 139, 139);}
    .activity{font-size: 14px !important; margin-bottom: 0.15rem;}
    .activity .activityItem{padding:0.06rem 0.1rem; border: 1px solid var(--theme-color); color: var(--theme-color); border-radius: 0.1rem;margin-right: .1rem;}
    .goods-item .item-info { width:100%; padding:0.1rem 0.12rem; box-sizing:border-box; position:relative;font-size:0.2rem;  }
    .goods-item .item-info .info-name{ width:100%; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;font-weight:600; }
    .goods-item .item-info .info-company{ font-size:0.22rem; width:100%; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; }
    .goods-item .item-info .info-old-price{ font-size:0.22rem; text-decoration:line-through; }
	.goods-item .item-info .new-price{display: flex;align-items: center;justify-content: space-between; font-size: 14px;}
	.goods-item .item-info .new-price .price-num{font-size: 20px; color: red;}
	.price-old{position: relative;font-size: 14px; color: #999; font-weight: 550;}
	.price-old::after{
	  position: absolute;
	  content: '';
	  left: 0;
	  top: 50%;
	  width: 100%;
	  height: 1px;
	  background: #666;
	  transform: rotate(10deg);
	}
    .shop-cart{width:0.4rem;position: absolute;right: 10px;}
    .noActivity{height: 0.3rem}
    /* 边框图 */
	.detail{position: relative;}
    .icon-bj{  width: 100%;  position: absolute;top: 0rem;}
    .guiGe{ position: absolute; bottom: 0;color: white; font-size: .25rem; font-weight: 700; width: 53%;text-align: center; height: 0.4rem; overflow: hidden; white-space: nowrap;text-overflow: ellipsis;margin-left: 0.1rem}
    .details{position: absolute;width: 36%; text-align: center; bottom: 3px;  right: 0.02rem;display: flex; flex-direction: column; color: rgb(255, 250, 240); font-size: .24rem; align-items: flex-end;}
    .details b{width: 100%;white-space: nowrap; overflow: hidden; font-size: 14px}
    .details b:first-child{font-size: .3rem;}
	
	.seck-btn{
		background: var(--theme-color);padding: 1px 10px; border-radius: 2px; color: #fff;
	}
	.info-cura {
		color: red;
		padding: 0 5px;
		display: inline-block;
		border: 1px red solid;
	}
</style>