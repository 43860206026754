<template>
    <div class="container">
        <!-- 图片轮播 -->
        <div class="seck-swiper">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">     
                <!-- 品牌分组的图片   -->
                <!-- <van-swipe-item v-if="!!GroupPic">
                    <div class="item-img">
                        <img :src="GroupPic" alt="">
                    </div>
                </van-swipe-item>
			<template v-if="!GroupPic"> -->
               <!-- 品牌分组的图片   -->
                <van-swipe-item v-if="!!GroupPic">
                    <div class="item-img">
                        <img :src="GroupPic" alt="">
                    </div>
                </van-swipe-item>
                <template v-if="!GroupPic">
                <div v-for="(item,index) in bannerList" :key="index">
                    <van-swipe-item v-if="bannerList.length>0">
                        <div class="item-img">
                            <img :src="item.ImgUrl" alt="">
                        </div>
                    </van-swipe-item>
                </div>
                <van-swipe-item v-if="bannerList.length<=0 && bannerShow">
                    <div class="item-img">
                        <img src="@/assets/images/pinpai.png" alt="">
                    </div>
                </van-swipe-item>
                </template>
            </van-swipe>
        </div>
        <!-- 品牌列表 -->
        <div class="brand-list" v-show="isRefresh">
            <div class="brand-item bor-bot2" v-for="(item,index) in newBrandList" :key="index">
                <div class="item-img">
                    <img class="img-top" v-if="item.Pic" :src="item.Pic" alt="">
                    <div class="item-top" v-if="!item.Pic">{{item.Name}}</div>
                </div>
                
                <div class="pack-list flex-wrap">
                    <div class="pack-item" v-for="(goodItem,j) in item.goodList" :key="j" @click="toDetail(goodItem.Spid)">
                        <div class="item-img">
                            <img class="shop-img" v-if="goodItem.SpPic" :src="goodItem.SpPic" alt="商品图片">
                            <img class="shop-img" v-if="!goodItem.SpPic" src="@/assets/images/loseImg.png" alt="商品图片">
                        </div>
                        <div class="noShopImg" v-if="goodItem.GoodsInfo.kcshl<=0" >
                        <!-- <div class="noShopImg"  > -->
                             售罄
                        </div>
                        <div class="item-info">
                            <span class="info-name">{{goodItem.SpName}}</span>
                            <div class="info-wrap flex-sp">
                                <span class="info-specifi">{{goodItem.Style}}</span>
                                <span class="info-num">数量：<span class="font-red">{{goodItem.GoodsInfo.kcshl}}</span></span>
                            </div>
                            <span class="info-company">{{goodItem.Factory}}</span>
                            <!-- <div class="info-time">远近效期：2020-12/2020-12</div> -->
                            <span class="info-price font-red">￥ {{goodItem.Price}}</span>
                            <img class="shop-cart" v-if="goodItem.GoodsInfo.kcshl>0" @click.stop="onCartShow(goodItem.Spid)" src="@/assets/images/index/main-gwc.png" alt="">
                            <img class="shop-cart" v-if="goodItem.GoodsInfo.kcshl<=0" src="@/assets/images/index/main-gwc_.png" alt="">
                        </div>
                    </div>
                    <div class="but-more" @click="toPageList(item.Id)">更多</div>
                </div>
            </div>
            <!-- 加载样式 -->
			<!-- <div class="loading-box flex-center">
				<img class="load-img" src="@/assets/images/index/loading.gif" alt="加载图片">
				<span class="load-txt">正在加载</span>
			</div> -->

            <!-- 购物车弹窗 -->
            <cart-pop :cartShowPop="cartShowPop" :goodInfo="goodInfo" @diffCartShow="closeCart"></cart-pop>
        </div>
    </div>
</template>

<script>
// import brand from '../../../public/api/brand';
import brand from '@/assets/js/api/brand'
import cartPop from '../../components/cartPop.vue';
export default {
    components:{
        cartPop,
    },
    data(){
        return{
            GroupId:'',//品牌分组相关-品牌分组Id
            GroupPic:'',//品牌分组相关-品牌分组图片
            brandList: [],      // 品牌专区
            newBrandList: [],
           
            page: 1,    
            isRefresh: true,        // 重新渲染
            bannerList: [],     // 顶部图片轮播
            bannerShow: false,  // 防止进入页面展示本地图片  false隐藏  true展示

            cartShowPop: false,     // 组件弹窗显示隐藏
            goodInfo: {},           // 组件商品信息
        }
        
    },
    created(){
         let that=this;        
         // 获取路由上的参数id
	    var url = that.$route.query.url;
	    var urlObj=new Object();
	    if (!url) {
	      urlObj = that.$route.query;
	    } else {
	      urlObj = that.public.urlObj(url);
	    }
	    if(urlObj && urlObj.GroupId){
	        that.GroupId=urlObj.GroupId;
	    }  
        // 请求品牌
        this.getBrandList();
        this.getBannerImg();
    },
    methods:{
        // 顶部图片轮播
        /*
            公众号-首页轮播图	20001
            公众号-秒杀列表页banner	20002
            公众号-团购列表页banner	20003
            公众号-集采列表页banner	20004
            公众号-品牌列表页banner	20005
            公众号-今日特价列表页banner	20006
            公众号-套餐包专区banner   20007
            公众号-近效期列表页banner	20008
            公众号-优惠活动列表页banner  20010
             */
        getBannerImg(){
            var that = this;
            var data = {
                Code: "20005",
            };
            
            that.public.request('post',"/api/advertItem/queryAdvertItem",data, res => {
                if(res.data.errorCode == "00"){
                    that.bannerList = res.data.rows;
                }
                that.bannerShow = true;
            })
        },


        // 组件购物车弹窗
        onCartShow(spid){
            var that = this;
            var orgId = localStorage.orgId;
            var data = {
                SpId: spid,
                OrgId: orgId,
            }
            that.public.request('post','/api/Store/QueryGoodsInfo',data,res => {
                // console.log(res);
                if(res.data.errorCode == '00'){
                    that.goodInfo = res.data.rows[0];
                    that.cartShowPop = true;
                }
            })
        },
        // 关闭组件弹窗
        closeCart(){
            var that = this;
            that.cartShowPop = false;
        },


        // 跳转详情页面
        toDetail(spid){
            this.$router.push({
                path: '/goodsDetail',
                query: {
                    spid: spid,
                }
            })
        },
        // 去品牌列表页面
        toPageList(id){
            this.$router.push({
                path: '/brandList',
                query: {
                    brandId: id,
                }
            })
        },
        // 请求品牌
        getBrandList(){
            var that = this;
            var data = {
                GroupId:that.GroupId,
            };
            that.$toast.loading({
                message: "加载中...",
                duration: 0,
            });
            brand.queryList(data,res => {
                that.$toast.clear();
                // console.log(res);
                if(res.data.errorCode == '00'){
                    that.brandList = res.data.rows;
                    if(that.brandList.length>0){
                        that.brandList.forEach((item,index) => {
                            that.getBrandPageList(item.Id);
                        })
                    }
                     //处理品牌分组相关
                    that.handleBrandGroup(that.GroupId,res.data.extend);                    
                }
            })
        },
        handleBrandGroup(groupId,groupInfo){
            let that=this;
            if(!groupId||!groupInfo)
             return;
             if(!!groupInfo.WebName){               
                // 顶部标题
                that.$store.commit("setHeadTitle", groupInfo.WebName);
             } 
             if(!!groupInfo.AppBanner){
                 that.GroupPic=groupInfo.AppBanner;
             }
        },
        // 请求品牌列表
        getBrandPageList(id){
            var that = this;
            var page = that.page;
            var data = {
                brandId: id,
                page: 1,
                rows: 4,
            };
            brand.GoodsPageList(data,res => {
                // console.log(res);
                if(res.data.errorCode == '00'){
                    var list = res.data.rows;
                    var brandList = that.brandList;
                    brandList.forEach((item,index) => {
                        if(id == item.Id){
                            item.goodList = list;
                        }
                    })
                    that.newBrandList = brandList;
                    that.isRefresh = false;
                    that.$nextTick(()=>{
                        that.isRefresh = true;
                    })
                    
                    // console.log(that.newBrandList);
                }
            })
        }
    }
}
</script>


<style scoped>
    /* 图片轮播 */
    .seck-swiper{ width:100%;height:2.8rem; }
    .seck-swiper .my-swipe{ width:100%;height:100%; }
    .seck-swiper .my-swipe .item-img{ width:100%;height:100%; }
    .seck-swiper .my-swipe .item-img img{ width:100%;height:100%; }

    /* 品牌列表 */
    .brand-list .brand-item .item-img{ width:100%; }
    .brand-list .brand-item .item-img .img-top{ width:100%; }
    .brand-list .brand-item .item-img .item-top{ width:100%;height:0.9rem;text-align:center;line-height:0.9rem;border-bottom:0.02rem solid #eee;font-size:0.32rem; }

    .pack-list{ width:100%;padding:0.18rem 0.25rem;box-sizing:border-box;flex-wrap:wrap; }
    .pack-list .pack-item{ width:46.3%;box-shadow:0 0 0.1rem #b9b9b9;margin-bottom:0.2rem; position: relative;}
    .pack-item:nth-child(2n-1){margin-right: .3rem;}
    .pack-list .pack-item:nth-child(2n){ margin-right:0; }
    .pack-list .pack-item .item-info .info-wrap .info-num{ font-size:0.24rem;display:inline; }
    .pack-list .pack-item .item-info .info-wrap .info-num span{ display:inline; }
    .pack-list .pack-item .item-info .info-time{ font-size:0.22rem; }
    .pack-item .item-img{ width:100%; padding:0.2rem;box-sizing:border-box; }
    .pack-item .item-img  .shop-img{ width:100%; height: 2.7rem;object-fit: contain; margin:0 auto;object-fit: contain;  }
    .pack-item .item-img .item-setime{ position:absolute; left:3%; bottom:0; right:0; padding:0.02rem 0.2rem; background:#199ed8; border-radius:0.28rem; width:80%; }
    .pack-item .item-img .item-setime img{ width:0.22rem; height:0.22rem; margin-right:0.18rem; }
    .pack-item .item-img .item-setime .settime-tit{ color:#fff; font-size:0.24rem;}
    .pack-item .item-info { width:100%; padding:0.1rem 0.12rem; box-sizing:border-box;position:relative; }
    .pack-item .item-info span{ display:block; font-size:0.3rem; }
    .pack-item .item-info span.info-name{ width:100%; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;font-weight:600; }
    .pack-item .item-info span.info-specifi{ font-size:0.24rem; }
    .pack-item .item-info span.info-company{ font-size:0.26rem; width:100%; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;font-weight:600; }
    .pack-item .item-info span.info-price{ font-size:0.32rem; }
    .pack-item .item-info span.info-old-price{ font-size:0.22rem; text-decoration:line-through; }
    .pack-item .item-info .shop-cart{ position:absolute; right:0.2rem; bottom:0.18rem; width:0.36rem; }


    /* 更多 */
    .but-more{ width:80%;margin:0.2rem auto;height:0.7rem;line-height:0.7rem;font-size:0.3rem;color:#fff;text-align:center;background:#dddbdb;border-radius:0.12rem; }
    .noShopImg{position:absolute;top: 0.6rem;right: 0.8rem; width: 1.6rem; height: 1.6rem;color: white; background-color: rgba(128, 127, 127, 0.8);border-radius: 50%;text-align: center;line-height: 1.6rem;font-size: .5rem; }
</style>