<template>
  <div class="container">
    <!-- 图片轮播 -->
    <div class="seck-swiper">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <!-- 团购 -->
        <div v-for="(item, i) in bannerList" :key="'indexa' + i">
          <van-swipe-item v-if="type === 'group'">
            <div class="item-img">
              <img :src="item.ImgUrl" alt="" />
            </div>
          </van-swipe-item>
        </div>

        <van-swipe-item
          v-if="type === 'group' && bannerList.length <= 0 && bannerShow"
        >
          <div class="item-img">
            <img src="@/assets/images/pt.png" alt="" />
          </div>
        </van-swipe-item>
        <!-- 集采 -->
        <div v-for="(item, ind) in bannerList" :key="'indexb' + ind">
          <van-swipe-item v-if="type === 'purch'">
            <div class="item-img">
              <img :src="item.ImgUrl" alt="" />
            </div>
          </van-swipe-item>
        </div>

        <van-swipe-item
          v-if="type === 'purch' && bannerList.length <= 0 && bannerShow"
        >
          <div class="item-img">
            <img src="@/assets/images/jc.png" alt="" />
          </div>
        </van-swipe-item>
        <!-- 秒杀 -->
        <div v-for="(item, indexj) in bannerList" :key="'index' + indexj">
          <van-swipe-item v-if="type === 'seckill'">
            <div class="item-img">
              <img :src="item.ImgUrl" alt="" />
            </div>
          </van-swipe-item>
        </div>

        <van-swipe-item
          v-if="type === 'seckill' && bannerList.length <= 0 && bannerShow"
        >
          <div class="item-img">
            <img src="@/assets/images/seckill.jpg" alt="" />
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <!-- 秒杀列表 -->
     <div class="Seckill-area " v-if="type === 'seckill'&&timeList.length>0">
        <div class="Seckill-top flex">
            <img src="../../assets/images/index/miaosha.jpg" alt="">
            <div class="Seckill-heading">
                <div class="font-w">热卖中</div>
                <div class="font-hui">不能错过</div>
            </div>
            <div  class="Seckill-time " id="Seckill-time" >
                <div class="Seckill-List" v-for="(timeItem,timeInd) in timeList" :key="timeInd" >
                 <div class="time-item " :class="timeInd==newIndex?'clickTime':''"  :name="timeInd=='0'?'item1':''"  :id="timeInd==newIndex?'scorllLeft':'' " @click="cutoverTime(timeItem.hour,timeInd)">
                         <div class="font-w " >{{timeItem.hour}}:00</div>
                         <div class="font-hui ">{{timeItem.title}}</div>
                 </div>
                </div>
            </div>
        </div>
              <seckillNew :list="timeShop" :active='activeTime' @toDetail='toGoodDetail' @cartShow='onCartShow' @onCartShow='onCartShow'></seckillNew>
     </div>
    <!--团购、集采、列表 -->
    
    <div class="seck-list"  v-if="type === 'purch'||type === 'group'">
      <div
        class="seck-item "
        v-for="(item, index) in activeList"
        :key="index"
        @click="toGoodDetail('',item.GoodsId, item.Id)"
      >
      
        <div class="item-left">
          <img
            class="left-img"
            v-if="!item.ImgUrl"
            src="@/assets/images/loseImg.png"
            alt=""
          />
          <img class="left-img" v-else :src="item.ImgUrl" alt="" />
          <div class="noShopImg" v-if="item.kcshl<=0||item.GoodsNum - item.AttentNum <= 0">
            售罄
          </div>
          <img class="bjt" src="../../assets/images/aa.png" alt="">
        

          <div class="item-setime flex-wrap">
            <img src="@/assets/images/index/v2_ms_ico.png" alt="倒计时" />
            <div class="settime-tit" v-html="item.countTime"></div>
          </div>
        </div>
        
        <div class="item-info flex-column">
          <div class="info-name">{{ item.GoodsName }}</div>
          <div class="info-specifi guige">{{ item.Style }}</div>
          <div class="info-company">{{ item.Factory }}</div>
          <div class="info-stock flex-sp">
            <span class="stock-tit" v-if="type == 'group' || type == 'purch'"
              >库存:{{ item.kcshl }}</span
            >
            <span class="stock-tit" v-if="type == 'seckill'"
              >库存:{{ item.GoodsNum - item.AttentNum }}</span
            >
            <span
              class="stock-quota font-red"
              v-if="type == 'group' || type == 'purch'"
              >限购{{ item.MaxNum }}</span
            >
            <span class="stock-quota font-red" v-if="type == 'seckill'"
              >限购{{ item.SingleMaxNum }}</span
            >
          </div>
         <div>
               <span class="info-tit font-red jiage" v-if="token">{{ item.SoldPrice }}/{{ item.Unit }}</span>
              <span class="info-tit font-red jiage" v-if="!token">资质通过后可见</span>
         </div>
          <div class="info-seck flex-sp">
            <div class="info-price">
              <span class="info-old-price font-grey yuanjia" v-if="!item.MarketPrice&&token"
                >￥ {{ item.OriginPrice }}</span
              >
              <span class="info-old-price font-grey yuanjia" v-if="item.MarketPrice&&token"
                >￥ {{ item.MarketPrice }}</span
              >
              <!-- <span  class="info-old-price font-grey" v-if="!token">￥0.00</span> -->
            </div>
            <div
              class="seck-btn"
              v-if="type == 'group' && item.kcshl > 0"
              @click.stop="onCartShow(item.GoodsId)"
            >
            <img  src="@/assets/images/index/main-gwc.png" alt="">
            </div>
            <div
              class="seck-btn"
              v-if="type == 'purch' && item.kcshl > 0"
              @click.stop="onCartShow(item.GoodsId)"
            >
             <img  src="@/assets/images/index/main-gwc.png" alt="">
            </div>
            <div
              class="seck-btn"
              v-if="type == 'seckill' && item.GoodsNum - item.AttentNum > 0"
              @click.stop="onCartShow(item.GoodsId)"
            >
            <img  src="@/assets/images/index/main-gwc.png" alt="">
            </div>
            <div
              class="seck-btn grey"
              v-if="(type == 'group' || type == 'purch') && item.kcshl <= 0"
            >
               <img  src="@/assets/images/index/main-gwc_.png" alt="">
            </div>
            <div
              class="seck-btn grey"
              v-if="type == 'seckill' && item.GoodsNum - item.AttentNum <= 0"
            >
               <img  src="@/assets/images/index/main-gwc_.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <!-- 加载样式 -->
      <div class="loading-box flex-center" v-if="showLoading">
        <img
          class="load-img"
          src="@/assets/images/index/loading.gif"
          alt="加载图片"
        />
        <span class="load-txt">正在加载</span>
      </div>
      <!-- <div class="loading-box flex-center" v-if="noMoretip && activeList.length>0  ">
                <span class="load-txt">暂无数据！</span>
            </div> -->
      <div
        class="loading-box flex-center"
        v-if="noMoretip && activeList.length > 4"
      >
        <span class="load-txt">已经到底了！</span>
      </div>
      <!-- 暂无数据 -->
      <div class="empty-box" v-if="activeList.length <= 0 && !showLoading">
        <div class="no-data">
          <img src="@/assets/images/cart/noActiveDrug.png" alt="" />
          <span>暂无数据</span>
        </div>
      </div>
    </div>
   

    <!-- 活动商品购物车弹窗 -->
    <active-cart
      :cartShowPop="cartShowPop"
      :goodSpid="goodSpid"
      :goodDetail="goodDetail"
      :typePage="typePage"
      @diffCartShow="closeCart"
    ></active-cart>
  </div>
</template>

<script>
import activeRequest from "@/assets/js/api/active.js";
import seckillNew from "../../components/seckillNew.vue"

// 引入活动弹窗组件
import activeCart from "../../components/activeCartPop.vue";
export default {
  components: { activeCart ,seckillNew},
  data() {
    return {
      show: false,
      type: "", // 团购、集采、秒杀类型
      page: 1,
      activeList: [], // 团购、集采、秒杀列表
      showLoading: true, // 加载更多
      noMoretip: false, // 没有更多数据了
      bannerList: [], // 顶部图片
      bannerShow: false, // 防止进入页面展示本地图片  false隐藏  true展示

      cartShowPop: false, // 组件显示隐藏
      goodSpid: "", // 向组件传递的spid
      goodDetail: {}, // 向组件传递商品详情
      typePage: "", // 向组件传递页面类型
      token: sessionStorage.getItem('member_token'),
       newIndex:0,
      timeShop:[],
      timeList:[],
      allTimeList:[],
      activeTime:0,
      SpikeFloor:null,
    };
  },
  created() {
    var that = this;
    var query = that.$route.query;
    if (query.headTit == "group") {
      that.typePage = query.headTit;
      that.$store.commit("setHeadTitle", "团购专区");
      that.type = "group";
      that.getGroupList();
      that.getBannerImg(that.type);
    } else if (query.headTit == "purch") {
      that.typePage = query.headTit;
      that.$store.commit("setHeadTitle", "集采专区");
      that.type = "purch";
      that.getPurchList();
      that.getBannerImg(that.type);
    } else if (query.headTit == "seckill") {
      that.typePage = query.headTit;
      that.$store.commit("setHeadTitle", "秒杀专区");
      that.type = "seckill";
      that.getSeckillList();
      that.getBannerImg(that.type);
    }
  },
  mounted() {
    var that=this;
    window.addEventListener("scroll", this.onReachBottom);
     this.hourlyRefresh(function (hour) {
        // console.log('执行整点计划 =>', hour);
        that.getSeckillList();
        });
  },
  destroyed() {
    this.$store.commit("setHeadTitle", "");
    window.removeEventListener("scroll", this.onReachBottom);
  },

  methods: {
    // 秒杀顶部图片轮播
    /*
            公众号-首页轮播图	20001
            公众号-秒杀列表页banner	20002
            公众号-团购列表页banner	20003
            公众号-集采列表页banner	20004
            公众号-品牌列表页banner	20005
            公众号-今日特价列表页banner	20006
             */
    getBannerImg(type) {
      var that = this;
      var data = {};
      if (type == "group") {
        // 团购
        data.Code = "20003";
      } else if (type == "purch") {
        // 集采
        data.Code = "20004";
      } else if (type == "seckill") {
        // 秒杀
        data.Code = "20002";
      }
      that.public.request(
        "post",
        "/api/advertItem/queryAdvertItem",
        data,
        (res) => {
          if (res.data.errorCode == "00") {
            that.bannerList = res.data.rows;
          }
          that.bannerShow = true;
        }
      );
    },
    // 商品弹窗显示
    onCartShow(spid,id,goodInfo,idd) {
      var that = this; // 查看是否登录

      if (that.token == null) {
        var orgId = localStorage.getItem("orgId");
        that.$dialog
          .confirm({
            message: "你还未登录,是否登录!",
          })
          .then(() => {
            that.$router.push({
              path: "/login",
              query: {
                orgId: orgId,
              },
            });
          })
          .catch(() => {
            // console.log('取消');
          });
        return false;
      }

      that.cartShowPop = true;
      if(idd=='1'){
                  that.goodDetail = goodInfo;
          return that.goodSpid = goodInfo.GoodsId;
      }
      var activeList = that.activeList;
      activeList.forEach((item, i) => {
        if (spid == item.GoodsId) {
          that.goodDetail = item;
        }
      });
      that.goodSpid = spid;
    },
    // 商品弹窗隐藏
    closeCart() {
      var that = this;
      that.cartShowPop = false;
    },
    // 秒杀列表数据
    // getSeckillList() {
    //   var that = this;
    //   var page = this.page;
    //   var data = {
    //     Id: "",
    //     page: page,
    //     rows: 4,
    //   };
    //   activeRequest.SeckillQueryList(data, (res) => {
    //     // console.log(res);
    //     if (res.data.errorCode == "00") {
    //       var allArr = [];
    //       var initArr = that.activeList;
    //       var curArr = res.data.rows ? res.data.rows : [];
    //       var pageLength = curArr.length;
    //       if (page > 1) {
    //         allArr = initArr.concat(curArr);
    //       } else {
    //         allArr = res.data.rows;
    //       }

    //       allArr.forEach((item, i) => {
    //         // 天时分秒  倒计时
    //         that.public.countdown(item.EndTime, (res) => {
    //           item.countTime = res;
    //         });
    //       });

    //       that.activeList = allArr;

    //       if (pageLength < 6) {
    //         that.noMoretip = true;
    //         that.showLoading = false;
    //       }
    //     } else if (res.data.errorCode == "99") {
    //       if (page <= 1) {
    //         that.nearExpiryList = [];
    //         that.noMoretip = false;
    //         that.showLoading = false;
    //       } else {
    //         that.noMoretip = true;
    //         that.showLoading = false;
    //       }
    //     }
    //   });
    // },
    // 秒杀商品
     getSeckillList(id){
            var that = this;
            var data = {
                hour:id,
				rows: 100
            }
             that.timeShop=[];
             that.allTimeList=[];
             that.timeList=[];
             that.activeTime=0;
            that.public.request('post','/api/seckill/QueryListNew',data,res => {
                if(res.data.errorCode == '00'){
                //    console.log('执行时间商品',res.data.rows);
                   that.allTimeList=res.data.rows;
                   res.data.rows.forEach(item=>{
                        var h_hour = new Date().getHours();//当前小时
                         var data={
                                hour:item.hour,
                                title:item.title,
                            }
                        if(h_hour==item.hour){
                            that.timeList.push(data);
                            var list=item.list;
                            list.forEach((item,ind)=>{
                              let num=item.SecKillNum/item.GoodsNum;
                               num = (num*100);
                              list[ind].percentage=num;
                            });
                             that.timeShop=list;
                            that.activeTime=1;

                        }else if(h_hour<item.hour){
                            that.timeList.push(data);
                             
                        }
                   });
                   //时间排序
                   that.timeList.sort((a,b) => {
                       return a.hour-b.hour
                       });

                      // 拿到当前时间的数据
                     if( that.timeShop.length=='0'){
                       res.data.rows.forEach(item=>{
                       if(that.timeList[0].hour==item.hour){
                         that.timeShop= item.list;
                       }
                       });
                     }
                }
                // console.log(that.timeList);
                // console.log( that.timeShop);
                
            })
        },
        // 预热
        warmUpBtn(){
          var that=this;
          return that.$toast('活动还未开始');

        },
     // 切换时间
        cutoverTime( time,id){
            var that=this;
            that.newIndex=id;
            that.activeTime=0;
            var element=document.getElementById('Seckill-time');
            // var aa=document.getElementsByName('item1')[0].offsetLeft;
            var item=document.getElementById('scorllLeft').clientWidth+6;//每一个大小
            id=id-1;
            item= item*id;//需要移动的大小
            element.scrollLeft = item;
            that.timeShop=[];
            that.allTimeList.forEach(item=>{
                if(item.hour==time){
                    that.timeShop=item.list;
                    if(item.title=='正在秒杀'){
                    that.activeTime=1;
                     }
                }
            });
            // console.log( that.timeShop);
        },
     hourlyRefresh (callback) {
        var date, hour, minute, second, readyHour;
        setInterval(function () {
            date = new Date()
            hour = date.getHours();
            minute = date.getMinutes();
            second = date.getSeconds();
            // console.log('当前时间:' + hour + ':' + minute + ':' + second)    
            if(minute === 0) {
            if(!readyHour) {
                readyHour = true;
                callback(hour);
            }
            } else {
            readyHour = false;
            }
        }, 1000)
       },
    // 集采列表数据
    getPurchList() {
      var that = this;
      var page = that.page;
      var data = {
        Id: "",
        Type: 20,
        page: page,
        rows: 4,
      };
      activeRequest.groupQueryList(data, (res) => {
        // console.log(res.data.rows);
        if (res.data.errorCode == "00") {
          var allArr = [];
          var initArr = that.activeList;
          var curArr = res.data.rows ? res.data.rows : [];
          var pageLength = curArr.length;
          if (page > 1) {
            allArr = initArr.concat(curArr);
          } else {
            allArr = res.data.rows;
          }

          allArr.forEach((item, i) => {
            // 天时分秒  倒计时
            that.public.countdown(item.EndTime, (res) => {
              item.countTime = res;
            });
          });

          that.activeList = allArr;

          if (pageLength < 6) {
            that.noMoretip = true;
            that.showLoading = false;
          }
        } else if (res.data.errorCode == "99") {
          if (page <= 1) {
            that.nearExpiryList = [];
            that.noMoretip = false;
            that.showLoading = false;
          } else {
            that.noMoretip = true;
            that.showLoading = false;
          }
        }
      });
    },
    // 团购列表数据
    getGroupList() {
      var that = this;
      var page = that.page;
      var data = {
        Id: "",
        Type: 10,
        page: page,
        rows: 4,
      };
      activeRequest.groupQueryList(data, (res) => {
        // console.log(res.data.rows);
        if (res.data.errorCode == "00") {
          var allArr = [];
          var initArr = that.activeList;
          var curArr = res.data.rows ? res.data.rows : [];
          var pageLength = curArr.length;
          if (page > 1) {
            allArr = initArr.concat(curArr);
          } else {
            allArr = res.data.rows;
          }

          allArr.forEach((item, i) => {
            // 天时分秒  倒计时
            that.public.countdown(item.EndTime, (res) => {
              item.countTime = res;
            });
          });

          that.activeList = allArr;

          if (pageLength < 6) {
            that.noMoretip = true;
            that.showLoading = false;
          }
        } else if (res.data.errorCode == "99") {
          if (page <= 1) {
            that.nearExpiryList = [];
            that.noMoretip = false;
            that.showLoading = false;
          } else {
            that.noMoretip = true;
            that.showLoading = false;
          }
        }
      });
    },
    // 跳转详情
    toGoodDetail(good,spid,id) {
      var that = this;
      var type = that.type;
      if(good){//秒杀跳转
        if(that.activeTime=='1'){
          return this.$router.push({
              path: "/activeDetail",
              query: {
                type: type,
                spid: good.GoodsId,
                id: good.Id,
              },
            });
        }else if(that.activeTime==0){
           return this.$router.push({
              path: '/goodsDetail',
              query:{
                        spid: good.GoodsId,
                        headTit: '详情'
                    }
            });
        }
      }
      this.$router.push({
        path: "/activeDetail",
        query: {
          type: type,
          spid: spid,
          id: id,
        },
      });
    },

    // 到达底部
    onReachBottom() {
      var that = this;
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;

      if (scrollTop > 0) {
        if (scrollTop + windowHeight + 5 > scrollHeight) {
          var activeList = that.activeList;
          if (activeList.length > 0) {
            var page = that.page;
            var noMoretip = that.noMoretip;
            page++;
            that.page = page;
            if (noMoretip) {
              // console.log("已经到底了");
            } else {
              // console.log("加载更多");

              that.getGroupList();
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped>
/* 图片轮播 */
.seck-swiper {
  width: 100%;
  height: 3.2rem;
}
.seck-swiper .my-swipe {
  width: 100%;
  height: 100%;
}
.seck-swiper .my-swipe .item-img {
  width: 100%;
  height: 100%;
}
.seck-swiper .my-swipe .item-img img {
  width: 100%;
  height: 100%;
}

/* 秒杀列表 */
.seck-list{display: flex; align-items: center;  flex-wrap: wrap;margin: 0.2rem 0.3rem;}
.seck-list .seck-item { position: relative; width: 46%;padding: 0.1rem;margin-bottom: .25rem;box-sizing: border-box;border:0.02rem solid #eee; box-shadow:0 0 0.1rem #b9b9b9;}
.seck-list .seck-item:nth-child(2n-1) {margin-right: 0.35rem;}
/* .seck-item .item-left {position: relative;} */
.seck-item .item-left .left-img {width:100%;margin: 0 auto;object-fit: contain;height: 2.7rem;}
.seck-item .item-left .item-setime {width: 66%; margin: 0.12rem auto 0; padding: 0.02rem 0.16rem; background: #199ed8; border-radius: 0.28rem;position: absolute;bottom: 0.1rem;left: 0;}
.seck-item .item-left .item-setime img {width: 0.22rem;height: 0.22rem;margin-right: 0.12rem;}
.seck-item .item-left .item-setime .settime-tit {color: #fff; font-size: 0.22rem;}

.seck-item .item-info {flex: 1; height: 1.56rem; box-sizing: border-box; justify-content: space-between;}
.seck-item .item-info .info-name {width: 100%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;font-weight: 600;font-size: 0.3rem;}
.seck-item .item-info .info-stock .stock-tit {font-size: 0.24rem;}
.seck-item .item-info .info-stock .stock-quota {font-size: 0.24rem;}
.seck-item .item-info .info-specifi { font-size: 0.24rem;}
.seck-item .item-info .info-company { width: 3rem !important; font-size: 0.28rem; width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
.info-tit { font-size: 0.27rem;}
.seck-item .item-info .info-seck .info-price .info-old-price { font-size: 0.24rem; text-decoration: line-through;}
.seck-item .item-info .info-seck .seck-btn { width: .36rem;}
.seck-item .item-info .info-seck .seck-btn.grey { background: #c5c5c5;}

/* 商品弹窗 */
.good-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.good-pop {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;
  background: #fff;
  width: 100%;
}
.good-pop .good-top {
  width: 100%;
  padding: 0.18rem 0.2rem;
  box-sizing: border-box;
  border-bottom: 0.02rem solid #eee;
}
.good-pop .good-top img {
  width: 1.8rem;
  max-height: 1.8rem;
  margin-right: 0.2rem;
}
.good-pop .good-top .good-right {
  flex: 1;
  height: 1.8rem;
  justify-content: space-between;
}
.good-pop .good-top .good-right .right-name {
  font-size: 0.3rem;
  font-weight: 600;
}
.good-pop .good-top .good-right .right-price span.price-tit {
  font-size: 0.5rem;
}
.good-pop .good-top .good-right .right-price span {
  font-size: 0.28rem;
}

.good-pop .good-specifi {
  width: 100%;
  padding: 0.18rem 0.2rem;
  box-sizing: border-box;
}
.good-pop .good-specifi .specifi-item .item-title .tit {
  font-size: 0.3rem;
  color: #333;
  font-weight: 600;
}
.good-pop .good-specifi .specifi-item .item-title .tit-parameter {
  font-size: 0.28rem;
  color: #999;
}
.good-pop .good-specifi .specifi-item .last-span {
  margin-left: 0.28rem;
  color: #999;
}
/* 商品加减 */
.add-subtract {
  flex: 1;
  justify-content: flex-end;
}
.add-subtract .add {
  font-size: 0.36rem;
  background: #ff1515;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  width: 0.36rem;
  height: 0.36rem;
  text-align: center;
  line-height: 0.32rem;
}
.add-subtract .num {
  font-size: 0.3rem;
  margin: 0 0.2rem;
}
.add-subtract .cut {
  font-size: 0.36rem;
  background: #ff1515;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  width: 0.36rem;
  height: 0.36rem;
  text-align: center;
  line-height: 0.32rem;
}

.good-pop .good-sure {
  width: 100%;
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  color: #fff;
  background: #199ed8;
  margin: 0.2rem auto 0;
  font-size: 0.3rem;
}
  .noShopImg{position:absolute;top: 0.3rem;right: 0.7rem; width: 1.6rem; height: 1.6rem;color: white; background-color: rgba(128, 127, 127, 0.8);border-radius: 50%;text-align: center;line-height: 1.6rem;font-size: .5rem; }
  .empty-box{margin: 0 auto;}
  .bjt{position: absolute;top: 0; left: 0;}
  .guige{position: absolute;top: 2.42rem;left: 0; color: white;font-size: .25rem;font-weight: 700;   width: 55%;text-align: center; height: 0.4rem; overflow: hidden; white-space: nowrap;text-overflow: ellipsis;margin-left: 0.1rem}
  .jiage{position: absolute; top: 2rem; color: white; right: 0.01rem; width: 35%;font-weight: 700;text-align: center;white-space: nowrap;  overflow: hidden;}
  .yuanjia{position: absolute;top:2.45rem;right: 0.01rem;  color: white;  width: 35%; text-align: center;font-size: .25rem; font-weight: 700;white-space: nowrap; overflow: hidden;}

/* 秒杀专区 */
.Seckill{ box-sizing: border-box;   border-bottom:0.18rem solid #f7f7f7;}
.Seckill .title{ line-height: 0.8rem; padding: 0 0.3rem; font-size: 0.3rem; color: #333;font-weight: 600;}
.flex{display: flex;align-items: center;}
.Seckill-top{margin: 0.13rem 0.22rem;}
.Seckill-top img{width: 0.8rem; border-radius: 50%;}
.Seckill-heading{width: 1rem; text-align: center;margin-left: 0.25rem;}
.font-w{font-weight: 600;font-size: 0.32rem;}
.font-hui{color: rgb(97, 97, 97);}
.Seckill-time{margin-left: 0.15rem; flex: 1;display: flex;box-sizing: border-box;overflow-x: scroll;flex-wrap: nowrap;}
.Seckill-List{text-align: center;margin-right: 0.23rem;}
.time-item div:nth-child(2){width: 1rem;}
.clickTime div:first-child{color: red;}
.clickTime div:nth-child(2){background-color: red;border-radius: 0.2rem;color: white;}
.Seckill-time::-webkit-scrollbar {display:none ;}

/* .timeShop-list{padding: 0.2rem;}
.timeShop-item{border: 1px solid rgb(231, 230, 230);padding: 0.01rem;box-shadow: 0px 0px 5px #b9b9b9;border-radius: 0.2rem;margin-bottom: 0.2rem;}
.timeShop-img {width: 35%;}
.flex2{display: flex;align-items: center;justify-content: space-between;}
.timeShop-detail{width: 60%;}
.SoldPrice{font-size: 0.42rem; color: red;margin-right: 0.2rem;}
.snapUp{ background: red; padding: 0.05rem 0.2rem;  border-radius: 0.1rem;  color: white;  font-size: 0.28rem;}
.yure{background-color: #8f9192; }
.SecKill-schedule{width: 40%;   color: white;position: relative;
}
.SecKillNum{border-radius: 0.1rem; border-top-right-radius:0;border-bottom-right-radius:0.4em;width: 100%;background: linear-gradient(to right,rgb(248, 42, 42), rgb(252, 150, 150)); padding-left: 0.3rem;}
.SecKillImg{width: 22%;position: absolute;right: -0.16rem;} */

</style>
