<template>
	<div class="content" v-if="videoInfo">
	  	<video class="video" ref="mVideo" controls autoplay :src="videoInfo.VideoPath" @timeupdate="timeupdate" @ended="timeEnd" playsinline webkit-playsinline>
		</video>
		
		<div class="title">{{videoInfo.Title}}</div>
	</div>
</template>
<script>
	import {Toast} from "vant"
	export default {
		name: 'app',
		components: {Toast},
		data(){
			return{
				videoId: "",
				videoInfo: null,
				longTime: 0, //视频总时长
				currentTime: 0 //播放的位置
			}
		},
		created() {
			this.videoId = this.$route.query.vid;
			this.queryVideoDetails();
		},
		mounted() {
			// this.public.hideOptionMenu();//屏蔽分享
			// this.$refs.mVideo.addEventListener("play",function(){})
			// this.$refs.mVideo.addEventListener("pause",function(){})
		},
		methods: {
			/** 视频详情 */
			queryVideoDetails(){
				let that = this;
				this.public.request("post","api/ExpertHall/QueryInfo",{
					id: this.videoId,
				},res=>{
					if(res.data.errorCode == "00"){
						that.videoInfo = res.data.rows;
						that.$store.commit("setHeadTitle", res.data.rows.Title);
					}else{
						Toast(res.data.errorMsg);
					}
				})
			},
			
			// 监听视频的实时进度 ，设置红包倒计时，以及禁止视频快进
			timeupdate(e) {
				this.longTime = parseInt(e.srcElement.duration);
				this.currentTime = parseInt(e.srcElement.currentTime);
			},
			
			/**
			 * 视频播放完毕
			 */
			timeEnd(e){
				this.saveWatchTime();
			},
			
			//记录观看的位置
			saveWatchTime(){
				let that = this;
				// this.currentTime = 650;
				let hour = Math.floor(this.currentTime/3600)
				let min = Math.floor(this.currentTime/60) % 60;
				let sec = this.currentTime % 60
				
				this.public.request("post","api/experthall/MemberWatch",{
				    VideoId: this.videoId,
				    WatchedTimeHour: hour,
				    WatchedTimeMinute: min,
				    WatchedTimeSecond: sec,
				    IsFullWatch: (this.currentTime == this.longTime?true:false),//是否观看完毕
				},res=>{
					if(res.data.errorCode == "00"){
					}else{
						that.$toast(res.data.errorMsg);
					}
				})
				
			},
			
		},
		//界面销毁之前
		beforeDestroy(){ //在销毁之前拿到video实例 销毁video
		},
		//界面离开了
		destroyed(){
			this.$store.commit('setHeadTitle','');
			this.saveWatchTime();
		},
		
	}
</script>

<style scoped>
	.video{
		width: 100%;
		height: 234px;
		background: #000;
		display: block;
	}
	.title{
		padding: 10px 13px;
		font-size: 16px;
		font-weight: bold;
	}
</style>
