<template>
   <div class="contain">
        <div class="company-list">
            <div class="company-item flex-sp" v-for="(item,index) in companyList" :key="index">
                <span>{{item.OrgName}}</span>
                <input name="radioOrg" :value="item.Id" v-model="selCompanyId" type="radio" >
            </div>
        </div>
        <div class="fixed-bot">
			<div class="sure-box flex-wrap">
				<div class="but-i" @click="toBack">取消</div>
				<div class="but-i blue" @click="toIndex">确定</div>
			</div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
			selCompanyId:'',
            companyList:[],
        }
    },
    created(){
        this.loadCustomerList();
    },
    methods:{
        loadCustomerList(){
			let that = this;
            that.public.request('post','/api/Store/GetUserStores',{},res => {
                if(res.data.errorCode == '00'){
                    that.companyList = res.data.rows;
					let storageCustomer = sessionStorage.getItem('customer');
					if(storageCustomer){
						that.selCompanyId = storageCustomer;
					}
                }else{
					that.$toast(res.data.errorMsg);
				}
            })
        },
        // 进入首页
        toIndex(){
            var that=this;
            if(!that.selCompanyId){
                that.$toast("请选择药店");
                return;
            }
            sessionStorage.setItem('customer',that.selCompanyId);
            that.$router.push({
                path: '/'
            })
        },
        // 取消  返回上一个页面
        toBack(){
            this.$router.go(-1);
        },
        
    }
}
</script>


<style scoped>
    .contain{
        width:100%;
        /* margin:-0.9rem 0; */
    }
    .company-list{
        width:100%;
    }
    .company-list .company-item{
        width:100%;
        height:0.9rem;
        border-bottom:0.02rem solid #eee;
        padding:0 0.3rem;
        box-sizing:border-box;
    }
    .company-list .company-item span{
        font-size: 0.3rem;
        display:inline-block;
        flex:1;
        overflow:hidden;
        white-space:nowrap;
        text-overflow:ellipsis;
    }
    .company-list .company-item input{
        width:0.32rem;
        height:0.32rem;
        margin-left:0.2rem;
    }


    .fixed-bot{
        width:100%;
        height:1rem;
    }

    .sure-btn{
        position:fixed;
        left:0;
        bottom:0;
        width:100%;
        height:1rem;
        font-size:0.3rem;
        color:#fff;
        background:var(--theme-color);
        text-align:center;
        line-height:1rem;
        z-index:1;
    }


    /* 取消  确定 */
    .sure-box{ position:fixed;left:0;bottom:0;width:100%;height:1rem;background:#fff;border-top:0.02rem solid #eee; }
    .sure-box .but-i{ width:50%;height:100%;text-align:center;line-height:1rem;font-size:0.3rem;z-index:2; }
    .sure-box .but-i.blue{ background:var(--theme-color);color:#fff; }
    
</style>
