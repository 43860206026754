<template>
  <div class="container">
    <!-- tab导航 -->
    <div class="tab-box flex-wrap">
      <div
        class="tab-item"
        :class="status == 0 ? 'active' : ''"
        @click="tabChange(0)"
      >
        全部
      </div>
      <div
        class="tab-item"
        :class="status == 100 ? 'active' : ''"
        @click="tabChange(100)"
      >
        待支付
      </div>
      <div
        class="tab-item"
        :class="status == 15 ? 'active' : ''"
        @click="tabChange(15)"
      >
        已预订
      </div>
      <div
        class="tab-item"
        :class="status == 20 ? 'active' : ''"
        @click="tabChange(20)"
      >
        已发货
      </div>
      <div
        class="tab-item"
        :class="status == 30 ? 'active' : ''"
        @click="tabChange(30)"
      >
        已收货
      </div>
      <div
        class="tab-item"
        :class="status == 99 ? 'active' : ''"
        @click="tabChange(99)"
      >
        已取消
      </div>
    </div>
    <!-- 订单列表 -->
    <div class="order-list">
      <div
        class="order-cont bor-bot2"
        v-for="(item, index) in listGoodsOrder"
        :key="index"
      >
        <div class="order-item" @click="toOrderDetail(item.orderInfo.OrderNo)">
          <div class="item-number flex-sp">
            <span><strong class="fontRed" v-if="item.orderInfo.IsGift">【赠品】</strong>订单编号:{{ item.orderInfo.OrderNo }}</span>
            <span class="fontRed">{{item.orderInfo.AppletOrderStatusName}}</span>
          </div>
          <div class="item-time flex-sp">
            <span>{{ item.orderInfo.CreateDate }}</span>
            <span>共{{!!item.orderInfoExtend && item.orderInfoExtend.length > 0? item.orderInfoExtend[0]["GoodsType"]: 0}}个品种</span>
          </div>
          <div class="item-imgs flex-wrap">
            <img
              v-for="(detailitem, detailindex) in item.orderDetails"
              :key="detailindex"
              :src="detailitem.SpPic == ''? '@/assets/images/index/xd3.png': detailitem.SpPic"
			onerror="javascript:this.src='https://goodssuffix.oss-cn-qingdao.aliyuncs.com/upload/c5c2ae08dbfb45898d64061a0d82e83e/9cc5bc0235044c54916fd18243082d10.png'" />
            <div>
              <img class="more" src="../../assets/images/mine/more.png" alt="">
            </div>
          </div>
          <div class="item-price flex-wrap">
            <div class="invoice-btn" v-if="item.orderInfo.ElectronList.length>0" @click.stop="seeInvoice(item.orderInfo)">查看发票</div>
            <div  class="price-discount" v-if="  item.orderInfo.DiscountAmount > 0 ||  item.orderInfo.CouponAmount > 0">
              <span>优惠：</span>
              <span class="fontRed" >￥{{  (item.orderInfo.DiscountAmount + item.orderInfo.CouponAmount).toFixed(2)  }}</span
              >
            </div>
            <div class="orderNumber">
              <span>金额：</span>
				<span v-if="item.orderInfo.OrderType==122" class="fontRed">￥{{item.orderInfo.GoodsPackTotal}}</span>
               <span v-else class="fontRed">￥{{item.orderInfo.IsGift?"0":item.orderInfo.OrderAmount}}</span>
            </div>
          </div>
        </div>
        <div class="aaa">
          
          <div class="item-but flex-wrap">
            <div class="but-i red" v-if="item.orderInfo.NeedPay &&  item.orderInfo.Status == 10 &&  item.orderInfo.Status != 99 "  @click.stop="onCancel(item.orderInfo.BatchOrderNo)">
              取消
            </div>

            <div class="but-i blue"
              @click="toOrderPay(item.orderInfo)"
              v-if="item.orderInfo.OrderPayType==1 && item.orderInfo.Payed == false && item.orderInfo.Status == 10">
              付款
            </div>

            <div class="but-i blue"
              @click="toBuyAgain(item.orderInfo.OrderNo)"
              v-if="item.orderInfo.AppletOrderStatusName == '已取消'">
              再次购买
            </div>

            <div
              class="but-i but-evaluate"
              v-if="item.orderInfo.Status == 20"
              @click.stop="onSureReceive(item.orderInfo.Id)"
            >
              确认收货
            </div>
            <div
              class="but-i but-logis"
              v-if="item.orderInfo.Status == 20 && item.orderInfo.LogisticsTrackNo"
              @click.stop="toLogistics(item.orderInfo.Id)"
            >
              物流信息
            </div>
            <div
              class="but-i but-evaluate"
              v-if="item.orderInfo.Status == 30"
              @click="toEvaluate(item.orderInfo.Id)"
            >
              评价
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="loading-box flex-center"
      v-if="noMore && listGoodsOrder.length > 4"
    >
      <span class="load-txt">已经到底了！</span>
    </div>
    <!-- 暂无数据 -->
    <div class="empty-box" v-if="listGoodsOrder.length == 0">
      <div class="no-data">
        <img src="@/assets/images/nore.png" alt="" />
        <span>暂无数据</span>
      </div>
    </div>

    <!--查看发票-->
    <div class="invoice-popup" v-if="invoiceShow"></div>
    <div class="invoice-pop" v-if="invoiceShow">
      <div class="invoice-tit">发票信息</div>
      <div class="invoice-box">
        <div class="invoice-item flex-sp" v-for="(item,index) in pdfList" :key="index">
          <span class="invo-type">{{item.Name}}</span>
          <span class="font-blue" @click="onPreview(item)">预览</span>
        </div>
      </div>
      <div class="invo-close" @click="closeInvoice">关闭</div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      totalPage: 1,
      showLoading: false, // 加载样式
      noMore: false, // 暂无数据，已经到达底部
      listGoodsOrder: [], // 订单列表
      status: 0, //订单状态
      pdfList: [],
      invoiceShow: false,
    };
  },
  mounted() {
    var that = this;
    var status = that.$route.query.status;
    if(status != undefined){
      that.status = status;
    }
    that.queryOrdersPage();
    // 请求购物车数量
    that.public.getCartNum();
    window.addEventListener("scroll", this.onReachBottom);
  },
  destroyed() {
    this.$store.commit("setHeadTitle", "");
    window.removeEventListener("scroll", this.onReachBottom);
  },
    beforeCreate() {
    // document.querySelector("body").setAttribute("style", "background:#f2f2f2");
  },
  methods: {
    // 查看发票
    seeInvoice(orderInfo){
      var that = this;
      // 获取类型
      var clientType = localStorage.getItem('clientType');   // 项目类型 app/小程序(applets)/公众号(weixin)
      // console.log(clientType);
      if(clientType == "applets"){
        var pdfInfo = encodeURIComponent(JSON.stringify(orderInfo.ElectronList));
        that.wx.miniProgram.navigateTo({
          url: '/pages/pdf/pdf?pdfInfo=' + pdfInfo,
        })
      }else if(clientType == 'app' || clientType == 'ios'){
        var pdfInfo = JSON.stringify(orderInfo.ElectronList);
        var webconfig =JSON.parse(localStorage.getItem('webconfig'));
        var appid="", primitiveid = "";
        if (webconfig && webconfig.PrimitiveId) {
            primitiveid = webconfig.PrimitiveId;
        }
        if (webconfig && webconfig.WeixinAppId) {
            appid = webconfig.WeixinAppId;
        }
        var url = '/pages/pdf/pdf?pdfInfo=' + pdfInfo;
        top.wxPay.WXPayOrder(url,appid, primitiveid); //调起app
      }else if(clientType == 'weixin'){
        that.pdfList = orderInfo.ElectronList;
        that.invoiceShow = true;
      }
      
    },
    // 关闭发票预览弹窗
    closeInvoice(){
      var that = this;
      that.invoiceShow = false;
    },
    // 发票预览
    onPreview(itemInfo){
      var that = this;
      var pdfUrl = window.baseUrl.url + itemInfo.Value;
      window.open(pdfUrl);
      // that.$router.push({
      //   path: "/pdf",
      //   query: {
      //     pdfUrl: itemInfo.Value,
      //   }
      // })
      
    },
    // 物流信息
    toLogistics(id){
      var that = this;
      that.$router.push({
        path: "/logisticsInfo",
        query:{
          id: id,
        }
      })
    },
    // 付款
    toOrderPay(orderInfo) {
      var that = this;
      // 获取类型
      var clientType = localStorage.getItem('clientType');
      let MasterBatchNo = orderInfo.MasterBatchNo?orderInfo.MasterBatchNo:"";
      // 如果是小程序，则跳转小程序支付，否则跳转到h5
      if(clientType == 'applets'){
		  //统计分析 埋点进来
		  that.keyId = ""; //主键
		  that.public.setBuryPoint(7,1,that.keyId).then(res=>{
		  	// if(res.errorCode=="00"){
		  	// 	that.keyId = res.rows;
		  	// }
		  });
		  
        var token = sessionStorage.getItem('member_token');
        that.$toast({
            message: '开始拉起小程序支付中',
            position: 'bottom',
        })
        try{
            that.$toast("小程序支付跳转中..");
            that.wx.miniProgram.navigateTo({
                url: '/pages/pay/index?orderNo=' + orderInfo.BatchOrderNo + '&token=' + token + '&MasterBatchNo='+MasterBatchNo,
            })
            setTimeout(function () {
                that.payConfirm();
            }, 3000);
        } catch(err){
            that.$toast("支付跳转异常");
        }
        setTimeout(function(){
          that.$dialog.confirm({
              title: '提示',
              message: '是否支付成功?',
          }).then(() => {
              that.queryOrdersPage();
          }).catch(() => {
              that.queryOrdersPage();
          })
        },3000)
        
      }else{
        that.$router.push({
          path: "/orderPay",
          query: {
            batchNo: orderInfo.BatchOrderNo,
			MasterBatchNo: MasterBatchNo
          },
        });
      }
      
    },
    // 确认收货
    onSureReceive(id) {
      var that = this;

      that.$dialog
        .confirm({
          message: "确认收货吗",
        })
        .then(() => {
          // 收货statu 30
          var data = {
            Id: id,
            Status: 30,
            IsNoSignature: true,
          };
          that.public.request(
            "post",
            "/api/ordersInfo/editOrderStatus",
            data,
            (res) => {
              // console.log(res);
              if (res.data.errorCode == "00") {
                that.$toast("收货成功");
                setTimeout(function() {
                  that.queryOrdersPage();
                }, 1000);
              } else {
                that.$toast(res.data.errorMsg);
              }
            }
          );
        })
        .catch(() => {
          // console.log('取消');
        });
    },
    // 取消订单
    onCancel(BatchNo) {
      var that = this;

      that.$dialog
        .confirm({
          message: "确认取消吗",
        })
        .then(() => {
          var data = {
            BatchOrderNo: BatchNo,
          };
          that.public.request(
            "post",
            "/api/ordersInfo/cancelOrder",
            data,
            (res) => {
              if (res.data.errorCode == "00") {
                that.$toast("取消成功");
                setTimeout(function() {
                  that.page = 1;
                  that.queryOrdersPage();
                }, 2000);
              } else {
                that.$toast("请联系业务员取消订单");
              }
            }
          );
        })
        .catch(() => {
          // console.log('取消');
        });
    },
    // 评价页面
    toEvaluate(id) {
      this.$router.push({
        path: "/evaluate",
        query: {
          orderId: id,
        },
      });
    },
    // 订单详情
    toOrderDetail(orderno) {
      if (!orderno) {
        $this.$toast("订单编号无效！");
        return;
      }
      this.$router.push({
        path: "/orderDetail",
        query: {
          OrderNo: orderno,
        },
      });
    },
    // tab切换
    tabChange(tab) {
      let that = this;
      if (tab == that.status) {
        return;
      }
      that.status = tab;
      that.page = 1;
      that.totalPage = 1;
      that.noMore = false;
      that.queryOrdersPage();
    },
    //加载订单数据
    queryOrdersPage() {
      var that = this;
      var data = {
        page: that.page,
        rows: 10,
        status: that.status,
      };
      that.$toast.loading({
        message: "加载中",
        duration: 0,
      });

      that.public.request(
        "post",
        "/api/ordersInfo/queryOrdersPage",
        data,
        (result) => {
          that.$toast.clear();
          var res = result.data;
          if (res.errorCode != "00") {
            if (res.data.errorCode == "99") {
              if (that.page <= 1) {
                that.listGoodsOrder = [];
                that.noMore = false;
              } else {
                that.noMore = true;
              }
            }
            return;
          }
          var allArr = [];
          var initArr = that.listGoodsOrder;
          var curArr = res.rows ? res.rows : [];
          if (that.page > 1) {
            allArr = initArr.concat(curArr);
          } else {
            allArr = curArr;
          }
          that.totalPage = res.total;
          that.listGoodsOrder = allArr;
          if (that.totalPage <= that.page) {
            that.noMore = true;
          }
        }
      );
    },

    // 去支付
    toPay(item) {
      console.log(item);
    },
    // 再次购买
    toBuyAgain(id) {
      console.log(id);
      var that = this;
      that.$toast.loading({
          message:'正在添加'
      })

      that.public.request(
        "post",
        "/api/ordersInfo/queryOrdersDetail",
        { OrderNo: id },
        (result) => {
          //   console.log(result);
          var res = result.data.rows;
          //   console.log(res);
          var arr = [];
          arr = res[0].orderDetails;
          arr.forEach((item) => {
            var data = {
              Spid: item.spid,
              GoodsCount: item.Total, // 购物车数量
              GoodsPrice: item.Price, // 商品价格
              ReceiveId: localStorage.getItem("orgId"), // orgid
            };

			if(item.CrossGroupId){
				data.TmsCrossGroupId = item.CrossGroupId;
			}

            // 判断获取库存
            that.public.request(
              "post",
              "/api/Store/QueryGoodsInfo",
              {
              OrgId: data.ReceiveId,
              SpId: data.Spid,
            },
              (res) => {
                if (res.data.errorCode == "00") {
                  var stock = res.data.rows[0].kcshl; // 库存数量
                  var IsLowStockOrder;
                  // 获取配置信息
                  that.public.getOrgWebInfo(function(orgInfo) {                
                    IsLowStockOrder = orgInfo.IsLowStockOrder;
                  });

                //   console.log("库存数量", res.data.rows[0].kcshl);
                  if (Number(data.GoodsCount) > Number(stock)) {
                    if (!IsLowStockOrder) {
                      that.$toast("库存不足");
                      return;
                    } else {
                      that.$dialog.confirm({
                          message:
                            "库存不足，将以最大库存量下单，下单即生成采购计划！",
                        }).then(() => {
                          // console.log('确定');

                          that.getAddCart(data);
                        }).catch(() => {
                          // console.log('取消');
                        });
                    }
                  }else{
                       that.getAddCart(data);
                  }
                } else {
                  that.$toast(res.data.errorMsg);
                }
              }
            );
          });
        }
      );
    },
    // 加入到购物车
    getAddCart(data) {  
      var that = this;
      that.public.request(
        "post",
        "/api/SellWellCar/AddSellWellCar",
        data,
        (res) => {
          // console.log(res);
          if (res.data.errorCode == "00") {
            that.$toast("已添加到购物车");
            that.public.getCartNum(); // 购物车数量
            that.cartNum = 1;
            // that.cartShowPop = false;
          } else if (res.data.errorCode == "99") {
            that.$toast(res.data.errorMsg);
            // that.cartShowPop = false;
          }
        }
      );
    },

    //到达底部
    onReachBottom() {
      var that = this;
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      // console.log(scrollTop);
      if (scrollTop > 0) {
        if (scrollTop + windowHeight + 2 >= scrollHeight) {
          //写后台加载数据的函数
          console.log("----------------");
          // console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
          if (that.listGoodsOrder.length > 0) {
            var page = that.page;
            var noMore = that.noMore;
            page++;
            that.page = page;
            if (noMore) {
              console.log("已经到底了");
            } else {
              that.queryOrdersPage();
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped>
	.container{
		background: #f2f2f2;
	}
/* tab导航 */
.tab-box {
  width: 100%;
  height: 0.9rem;
  justify-content: space-around;
  border-bottom: 0.02rem solid #eee;
  background-color: white;
}
.tab-box .tab-item {
  height: 100%;
  line-height: 0.9rem;
  font-size: 0.3rem;
}
.tab-box .tab-item.active {
  color: var(--theme-color);
  border-bottom: 0.02rem solid var(--theme-color);
}

/* 订单列表 */
.order-list .order-cont {
     background-color: white;
    margin: 0.25rem;
    border-radius: 0.15rem;
}
.order-list .order-cont .order-item {
  width: 100%;
  border-bottom: 0.02rem solid #eee;
}
.order-list .order-cont .order-item .item-number {
  width: 100%;
  padding: 0.1rem 0.2rem;
  box-sizing: border-box;
}
.order-list .order-cont .order-item .item-number span:first-child {
  font-size: 0.3rem;
  font-weight: 600;
}
.order-list .order-cont .order-item .item-number span:last-child {
  font-size: 0.3rem;
}
.order-cont .order-item .item-time {
  width: 100%;
  padding: 0 0.2rem 0.1rem;
  box-sizing: border-box;
  border-bottom: 1px solid rgb(240, 240, 240);
}
.order-cont .order-item .item-time span {
  font-size: 0.26rem;
  color: #999;
}
.order-cont .order-item .item-imgs {
  width: 100%;
  min-height: 80px;
  padding: 0 0.2rem;
  box-sizing: border-box;
  /* background: #f7f7f7; */
  position: relative;
}
.order-cont .order-item .item-imgs img {
    width: 26%;
  max-height: 2rem;
  margin-right: 0.12rem;
  display: inline-block;
  object-fit: contain;
  border: 1px solid rgb(235, 233, 233);
  margin-top: 0.2rem;
}
.order-cont .order-item .item-price {
  width: 100%;
  padding: 0.18rem 0.2rem;
  box-sizing: border-box;
  justify-content: flex-end;
}
.order-cont .order-item .item-price .price-discount{
  font-size: 0.3rem;
}
.order-cont .order-item .item-price .invoice-btn{
  font-size:0.3rem;
  margin-right:0.3rem;
}
.order-cont .order-item .item-price .price-discount:first-child {
  margin-right: 0.2rem;
}
.order-cont .item-but {
  width: 100%;
  /* height: 0.8rem; */
  padding: 0.2rem 0.2rem;
  box-sizing: border-box;
  justify-content: flex-end;
}
.order-cont .item-but .but-i {
  padding: 0.03rem 0.2rem;
  font-size: 0.28rem;
  color: #fff;
  border-radius: 0.2rem;
  margin-left: 0.2rem;
}
.order-cont .item-but .but-i.red {
  background: #f57c5e;
}
.order-cont .item-but .but-i.blue {
  background: var(--theme-color);
}
.order-cont .item-but .but-i.but-sure {
  border: 0.02rem solid #f57c5e;
  color: #f57c5e;
}
.order-cont .item-but .but-i.but-logis {
  border: 0.02rem solid #199ed8;
  color: #199ed8;
}
.order-cont .item-but .but-i.but-evaluate {
  background: #ff9933;
  color: #fff;
}
.fontRed{
  color:var(--theme-color);
}
.orderNumber{font-size: 0.3rem;}
.order-cont .order-item .item-imgs .more{border: 0;
    height: -18.5rem;
    position: absolute;
    top: 35px;
    width: 5%;
    right: 5px;}



/* 发票管理 */
.invoice-popup{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background:rgba(0,0,0,0.5);
  z-index:10;
}
.invoice-pop{
  position: fixed;
  top:30%;
  width:80%;
  left:10%;
  background:#fff;
  border-radius:0.2rem;
  padding:0.2rem 0.3rem;
  box-sizing:border-box;
  z-index:11;
}
.invoice-pop .invoice-tit{
  font-size:0.32rem;
}
.invoice-pop .invoice-box{
  width:100%;
  height:3rem;
  overflow-y:auto;
  margin:0.2rem 0;
}
.invoice-pop .invoice-box .invoice-item span{
  font-size:0.26rem;
}
.invoice-pop .invoice-box .invoice-item .invo-type{
  display: inline-block;
  width:3.5rem;
  overflow:hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
}
.invoice-pop .invo-close{
  width:0.9rem;
  text-align:center;
  height:0.4rem;
  line-height:0.4rem;
  background:#999;
  border-radius:0.18rem;
  margin: 0 auto;
  color: #fff;
}

.font-blue{
  color:#0000FF;
}
</style>
