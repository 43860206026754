<template>
	<div class="container">
		
		<div class="tab-top">
          <div class="tab-tit">
              提交订单
          </div>
        </div>
	 <div class="roundTable" v-if="token">
			<!-- 收货地址 -->
	<keep-alive>
			<div class="address-box bor-bot2">
			<div class="not-address flex-sp" v-if="!addShow" @click="toAddress()">
				<div class="img-box flex-wrap">
					<img src="@/assets/images/icon_address.png" alt="地图图标">
					<span>暂无收货地址，请添加</span>
				</div>
				<img class="img-r" src="@/assets/images/mine/arrow-r.png" alt="右箭头">
			</div>
			<div class="address-info flex-wrap1" v-if="addShow" @click="toAddress(addressInfo.Id)">
				<img class="img-add" src="@/assets/images/icon_add.png" alt="地图图标">
				<div class="address-right flex-wrap">
					<div class="use-info">
						<div class="right-mobile flex-sp">
							<span>收货人:{{addressInfo.Consignee}}</span>
							<span>{{addressInfo.Mobile}}</span>
						</div>
						<div class="add-cont">收货地址:{{addressInfo.Address}}</div>
					</div>
					<img class="img-r" src="@/assets/images/mine/arrow-r.png" alt="右箭头">
				</div>
			</div>
		</div>
	</keep-alive>
		<!-- 支付方式，发票类型 -->
		<div class="invoce-box bor-bot2">
			<div class="invoce-item flex-wrap" @click="showPicker = true">
				<div class="item-left">
					<span class="invoce-tit">支付方式</span>
				</div>
				<div class="picker-box flex-wrap">
					<span class="picker-tit">{{payType}}</span>
					<img src="@/assets/images/mine/arrow-r.png" alt="图标">
				</div>
			</div>
			<div class="invoce-item flex-wrap" @click="invoiceShow = true">
				<div class="item-left">
					<span class="invoce-tit">发票类型</span>
				</div>
				<div class="picker-box flex-wrap">
					<span class="picker-tit">{{invoiceType}}</span>
					<img src="@/assets/images/mine/arrow-r.png" alt="图标">
				</div>
			</div>
		</div>
		
		<!-- 备注 -->
		<div class="remarks flex-wrap bor-bot2">
			<span>备注:</span>
			<input type="text" v-model="remarks" placeholder="请输入您的特殊要求">
		</div>
		<!-- 商品详情 -->
		
		<div class="good-list bor-bot2">
			<div class="good-item flex-wrap" v-for="(goodItem,index) in goodDetail.goodList" :key="index">
				<div>
					<div class="flex-wrap"> 
						<div class="item-left">
							<img class="left-img" v-if="goodItem.SpPic" :src="goodItem.SpPic"  onerror="javascript:this.src='https://goodssuffix.oss-cn-qingdao.aliyuncs.com/upload/c5c2ae08dbfb45898d64061a0d82e83e/9cc5bc0235044c54916fd18243082d10.png'" >
							<img class="left-img" v-if="!goodItem.SpPic" src="@/assets/images/loseImg.png"  onerror="javascript:this.src='https://goodssuffix.oss-cn-qingdao.aliyuncs.com/upload/c5c2ae08dbfb45898d64061a0d82e83e/9cc5bc0235044c54916fd18243082d10.png'" >
						</div>
						<div class="item-info flex-column">
							<div class="info-name">{{goodItem.SpName}}</div>
							<div class="info-specifi">{{goodItem.Style}}/{{goodItem.dw}}</div>

							<div class="info-company">{{goodItem.Factory}}</div>
							<div class="info-seck flex-sp">
								<div class="info-price">
									<span class="info-tit font-red">￥ {{goodItem.Price}}</span>
								</div>
								<div class="seck-btn">x {{goodItem.Number}}</div>
							</div>

						</div>
					</div>
					
				</div>	
				
				
			</div>
			<div class="good-list bor-bot2 " style="padding:0">
			
				<div v-if="goodDetail.giftList">
					<div v-for="(item,a) in goodDetail.giftList" :key="a"  >
						<div class="giveaway"> [赠品] <span> {{item.Spmc}} {{item.pihao}}/{{item.Unit}} x{{item.GiftCount}}</span></div>
				</div>
				</div>
				<div v-if="goodDetail.gift2List">
					<div v-for="(item,b) in goodDetail.gift2List" :key="b" >
					<div class="giveaway"> [赠品] <span> {{item.Spmc}} {{item.pihao}}/{{item.Unit}} x{{item.GiftCount}}</span></div>
				</div>
				</div>
			
		</div>
			
		</div>
		
	

		<!-- 价格详情 -->
		<div  class="price-list bor-bot2">
			
			<div class="price-item flex-sp">
				<span class="item-tit">商品金额</span>
				<span class="font-red">￥{{totalPirce}}</span>
			</div>
			<div class="price-item flex-sp" v-if="freight>0">
				<span class="item-tit">运费</span>
				<span class="font-red" >￥{{freight}}</span>
				
			</div>		

		</div>


		<!-- 返回首页 -->
		<div class="goIndex">
			<button @click="goIndexBtn">返回首页</button>
		</div>

		<!-- 支付弹窗 -->
		<van-popup v-model="showPicker" round position="bottom">
			<van-picker item-height="40" show-toolbar :columns="columns" value-key="name" @cancel="showPicker = false"
				@confirm="onConfirm" />
		</van-popup>
		<!-- 手机号 -->
		<div class="phones" v-if="isPhones">
			<div class="closure" @click="closureBtn">x</div>
			<div class="flex-wrap"><div class="tel">手机号:</div><input class="num" v-model="ismobile" type="number" placeholder="请输入手机号"></div>
			<div class="flex-wrap" style='margin-top: .2rem;' ><div class="tel">密 码 :</div><input v-model="verifiCode" class="num" type="password" placeholder="请输入密码"></div>
			<button @click="sureBtn">确认绑定</button>
		</div>

		<!-- 发票弹窗 -->
		<van-popup v-model="invoiceShow" round position="bottom">
			<van-picker item-height="40" show-toolbar :columns="invoiceCloumn" value-key="InvoiceTypeName"
				@cancel="invoiceShow = false" @confirm="onInvoice" />
		</van-popup>

		<!-- 底部  总价 -->
		<div class="bot-fixed">
			<div class="bot-total flex-wrap">
				<div class="total-left flex-sp">
					<span class="left-tit" v-if="goodDetail.length>0">共计:{{goodDetail.ListGoods.length}}件商品</span>
					<div class="total-price">
						<span>合计:</span>
						<span class="font-red">￥{{totalPirce}}</span>
					</div>
				</div>
				<div class="but-submit" @click="submitOrder">提交订单</div>
			</div>
		</div>
	</div>
	
	
	</div>
</template>


<script>
import Vue from 'vue';
import { Dialog } from 'vant';
Vue.use(Dialog);
	export default {
		data() {
			return {
				payType: '线上付款',
				showPicker: false,
				invoice_index: '', // 发票下标
				invoiceType: '', // 发票名字
				addShow: false, //  false没有地址， true已有地址
				freight: 0,			// 运费
				isDecimalDiscount: false,		// 抹零优惠
				decimalDiscount: 2,				// 抹零优惠
				pointNum:2,//小数点
				goodDetail: [],//商品
				addressInfo: {},//地址
				remarks: '', // 备注
				goodTotal: '', // 商品总金额
				totalMoney: 0,		// 商品处理之后的金额
				columnId: 1, // 支付方式选中对象id
				invoiceCloumn: [],//发票
				invoiceShow: false,//发票显示	
				columns: [{
						id: 0,
						name: '对公打款',
					},
					{
						id: 1,
						name: '线上付款',
					},
					{
						id: 2,
						name: '其他',
					},
				],
				token:false,
				roundId:'',//圆桌会议id
				isMaxNum:false,
				text:false,
				isPhones:false,
				ismobile:'',
				verifiCode:'',//密码
				orgId:'',
				openId:'',
				
			}
		},
		created() {
			var that = this;
			var params=that.public.urlObj(location.href);
			var openId=params.openId;
			var orgId=params.OrgId;
			that.roundId=params.roundId
			// https://app.yaomengzhu.com/roundTable?OrgId=2679591e77624a6489912571f449832c&openId=oiwRD5OZ6E-rJtl49UOu_mNRYIvw&roundId=312a76fd8dff4b03be5dbba6e545beb9
			// that.roundId = "312a76fd8dff4b03be5dbba6e545beb9";
			// openId = "oiwRD5OZ6E-rJtl49UOu_mNRYIvw";
			// orgId = "2679591e77624a6489912571f449832c";
			//先默认登录，登录后在下单
				that.$toast.loading({
					message: "加载中...",
					duration: 0,
				});
				var data={
					orgId: orgId,
					applets:openId
				};
				that.public.request('post','/api/applogin/LoginByApplets',data,
				res=>{
					if (res.data.errorCode == "00"){	
						 that.token=true;	
						 sessionStorage.setItem("member_token", res.data.rows);
          				 localStorage.setItem("orgId", res.data.errorMsg);
         				 sessionStorage.setItem("customer", res.data.extend.customerId);
         				 var webconfig = JSON.stringify(res.data.extend.webConfig);
         				 localStorage.setItem("webconfig", webconfig);
						 var shuju=webconfig;
							if(shuju.GoodPriceFixed){
								that.pointNum=shuju.GoodPriceFixed;
								};
						console.log(11);
						that.$toast('获取成功');
						that.public.getOrgWebInfo(function(orgInfo) {
							// console.log(orgInfo);
							if (orgInfo.EnablePay) {
								that.payType = '线上付款';
								that.columnId = 1;
							} else {
								that.payType = '对公打款';
								that.columnId = 0;
								that.columns.splice(1,1);  // 删除数组中的线上付款
							}
						});	
						// 获取数据
						that.getWaitBuy();
						var addressId = localStorage.getItem('addressId');
						that.getAddress(addressId);
						that.getInvoice();
					}else{
						that.$toast('获取失败，请重新扫描')
						
					}
				});
		        that.orgId=orgId;
				that.openId=openId;
		},
		activated() {
			//退当前页面
			var that=this;
			var addressId = localStorage.getItem('addressId');
		    that.getAddress(addressId);
			console.log(22);
		
		},
		deactivated(){
			this.remarks='';//清除备注内容
		},
		
		methods: {

			//返回首页
			goIndexBtn(){
				var that=this;
				that.$router.push({
					path: '/',
				})
			},
			// 选择地址
			toAddress: function(addressId) {
				var that = this;
				that.$router.push({
					path: '/receiveAddress',
					query: {
						addressId: addressId,
					}
				})
			},
			// 地址列表
			getAddress(addressId) {
				var that = this;
				var addressId = addressId;
				var data = {
					id: '',
				}
				if (addressId) {
					data.id = addressId;
				}
				that.public.request('get', '/api/ReceiveAddress/GetReceiveAddress', data, res => {
					
					if (res.data.errorCode == '00') {
						if (!res.data.rows) {
							that.addShow = false;
						} else {
							that.addShow = true;
							that.addressInfo = res.data.rows;
						}
					}
				}, 'get')
			},
			// 发票列表
			getInvoice() {
				var that = this;
				var data = {}
				that.public.request('post', '/api/InvoiceType/QueryList', data, res => {
					if (res.data.errorCode == '00') {
						that.invoiceCloumn = res.data.rows;
						that.invoice_index = res.data.rows[0].InvoiceTypeCode;
						that.invoiceType = res.data.rows[0].InvoiceTypeName;
					}
				})
			},
			// 支付选择
			onConfirm(value) {
				this.columnId = value.id; // 选中的支付方式的对象
				this.payType = value.name;
				this.showPicker = false;
			},
			// 发票选择
			onInvoice(value) {
				this.invoiceType = value.InvoiceTypeName;
				this.invoice_index = value.InvoiceTypeCode; // 选中发票对象下标
				this.invoiceShow = false;
			},
			// 商品详情
			getWaitBuy(id) {
				var that = this;
				console.log(id);
				console.log(88);

				console.log('圆桌会议id',that.roundId);
				that.public.request('post', '/api/saleround/QueryList?id='+that.roundId, '', res => {
					that.$toast.clear();
					if (res.data.errorCode == '00') {
						// if (!res.data.rows) {
						// 	that.$toast('数据已过期');
						// 	setTimeout(function() {
						// 		that.$router.push({
						// 			path: '/',
						// 		})
						// 	}, 1000)
						// 	return false;
						// }

						var goodDetail = res.data.rows;
						var total = res.data.rows.Price;
						that.goodDetail = goodDetail;
						// 运费
						if(that.goodDetail.freightSet.IncludePostage  > total){
							that.freight= goodDetail.freightSet.Postage;
						}	
						var totalMoney = Number(total).toFixed(that.pointNum);
						that.totalMoney = totalMoney;
						that.goodDetail.goodList.forEach(item=>{
							if(item.Number>item.SpStock){
								that.isMaxNum=true;
							}
						})		
					}else if(res.data.errorCode == '100'||res.data.errorCode == '101'){//活动未启用
						that.$toast(res.data.errorMsg);
						var tim=setTimeout(function() {
								that.$router.push({
									path: '/',
								});
								clearTimeout(tim);
							}, 1000)
							return false;
						
					}else{
						// if (!res.data.rows) {
						// 	that.$toast('数据已过期');
						// 	setTimeout(function() {
						// 		that.$router.push({
						// 			path: '/',
						// 		})
						// 	}, 1000)
						// 	return false;
						// }
					}
				})
			},
			// 抹零优惠
			isSetDecimalDiscount() {
				var that = this;
				var webConfig = localStorage.webConfig;
				if (webConfig) {
					webConfig = JSON.parse(localStorage.webConfig);
					if (webConfig.DecimalDiscount) {
						var tempDecimalDiscount = parseInt(webConfig.DecimalDiscount);
						if (tempDecimalDiscount > 0) {
							that.isDecimalDiscount = true;
							that.decimalDiscount = Number(tempDecimalDiscount / 10);
						}
					}
				}
			},
			// 抹零优惠
			formatMoney(tempmoney){
				var that = this;
				var resultMonty = tempmoney;
				if (that.isDecimalDiscount) {
					//var temp = 10 * decimalDiscount;
					var temp = Math.pow(10, that.decimalDiscount);
					resultMonty=Math.round(tempmoney * temp) / temp;
				}
				return resultMonty;
			},
			// 提交
			submitOrder() {
				var that = this;
				if(that.isMaxNum){
					that.$toast('超出最大库存量，将以最大库存量下单')
				}
				that.$toast.loading({
					message: "正在提交...",
					duration: 0,
				});
				var data = {
					saleId:that.roundId,//会销主键
					customerremark: that.remarks ? that.remarks : '',		// 客户备注	
				}
			
				// 收货人地址信息
				if (that.addShow) {
					var address = that.addressInfo;
					data.Mobile = address.Mobile; // 收货人手机号
					data.Consignee = address.Consignee;		// 名称
					var address = address.Address; 		// 地址
					data.Address = address;

				} else {
					that.$toast('请选择收货地址');
					return false;
				}
				// 支付方式
				data.OrderPayType = that.columnId;
				if (data.OrderPayType == -1) {
					that.$toast('请选择支付方式');
					return false;
				}
				// 发票类型
				data.TmsInvoiceType = that.invoice_index;
				
				//统计分析 埋点进来
				that.keyId = ""; //主键
				that.public.setBuryPoint(6,1,that.keyId).then(res=>{
					// if(res.errorCode=="00"){
					// 	that.keyId = res.rows;
					// }
				});
				
				that.public.request('post', '/api/saleround/createorder', data, res => {
					that.$toast.clear();
					
					if(res.data.errorCode == '00'){
						if(res.data.rows.AdvancePay && data.OrderPayType == '1'){	// 跳转支付宝、微信支付

							that.$router.push({
								path: '/orderPay',
								query:{
									MasterBatchNo: res.data.rows.MasterBatchNo,
									batchNo: res.data.rows.BatchOrderNo,
									pageType: 'waitBuyPay',
								}
							})
						}else{
							that.$router.push({
								path: '/orderSuccess',
								query: {
									OrderNo: res.data.rows.OrderNo,
									BatchNo: res.data.rows.BatchOrderNo,
									MasterBatchNo: res.data.rows.MasterBatchNo,
								}
							})
						}
					}else if(res.data.errorCode == '99'){
						that.$toast(res.data.errorMsg);	
					}else if(res.data.errorCode=='109'){
						that.isPhones=true;
					}else if(res.data.errorCode == '100'){//活动未启用
						that.$toast('暂无购买权限');
						var tim=setTimeout(function() {
								that.$router.push({
									path: '/',
								});
								clearTimeout(tim);
							}, 1000)
							return false;
					}
				});
			},
			sureBtn(){
				var that=this;
				
				console.log(that.ismobile);
				console.log(that.verifiCode);
				 var data = {
					Mobile:that.ismobile ,
					Password:that.verifiCode,
					OrgId: that.orgId,
					Applets:that.openId,
					};
				if (!data.Mobile) {
				that.$toast("手机号不能为空");
				return;
				}
				
				if (!data.Password) {
				that.$toast("请输入密码");
				return;
				}
				
				// 保存手机号，在APP.vue中调用，判断token是否过期
				var cipher = that.public.encrypt(data.Mobile, data.Password);
				localStorage.setItem("mobile", cipher.cipherText);
				localStorage.setItem("password", cipher.cipherPsw);
				that.$toast.loading({
					message: "登录中...",
					duration: 0,
					});

			 that.public.request("post", "/api/AppLogin/Login", data, (res) => {
                that.$toast.clear();
		    	if (res.data.errorCode != "99") {
				  that.$toast('登录成功')
					sessionStorage.setItem("member_token", res.data.rows);
					localStorage.setItem("orgId", res.data.errorMsg);
					sessionStorage.setItem("customer", res.data.extend.customerId);
					localStorage.setItem("clientType", that.clientType); // app类型
					var webconfig = JSON.stringify(res.data.extend.webConfig);
					localStorage.setItem("webconfig", webconfig);

					// 获取数据
					that.getWaitBuy();
					var addressId = localStorage.getItem('addressId');
					that.getAddress(addressId);
					that.getInvoice();
					that.isPhones=false; 
					that.ismobile='';
					that.verifiCode='';            
				} else {
				that.$toast(res.data.errorMsg);
					}
				});
				},
			closureBtn(){
					this.isPhones=false; 
				}
			
		},
		computed: {
			// 商品优惠金额(合计)
			totalPirce: function() {
				var that = this;
				if (that.goodDetail.length == 0) return;
				var rows = that.goodDetail.goodList;
				if (!rows) return;

				var totalMoney = that.totalMoney;
				// 加上运费
				totalMoney = Number(totalMoney) + Number(that.freight);
				totalMoney = Number(totalMoney).toFixed(that.pointNum);
				return totalMoney;
			},
			// // 商品总金额
			// goodTotalPrice:function(){
			// 	var that = this;
			// 	if (that.goodDetail.length == 0) return;
			// 	var rows = that.goodDetail.goodList;
			// 	if (!rows) return;
			// 	var price = 0;
			// 	rows.forEach((item,i) => {
			// 		if(item.CartSource>0){
			// 			price += item.BuyPrice * item.BuyCount;
			// 		}else{
			// 			price += item.OriginPrice * item.BuyCount;
			// 		}
					
			// 	})
			// 	//抹零优惠处理
			// 	var tempNum = Number(price).toFixed(that.pointNum);
			// 	if (that.isDecimalDiscount) {
			// 		tempNum = that.formatMoney(price);
			// 	}
			// 	return tempNum;
			// }
		}
	}
</script>


<style scoped>
	/* 收货地址 */
	.address-box {
		width: 100%;
		padding: 0.2rem;
		box-sizing: border-box;
	}

	.address-box .not-address .img-box img {
		width: 0.6rem;
		margin-right: 0.2rem;
	}

	.address-box .not-address .img-box span {
		font-size: 0.3rem;
	}

	.address-box .not-address .img-r {
		width: 0.16rem;
		margin-left: 0.2rem;
	}

	.address-box .address-info {
		align-items: flex-start;
	}

	.address-box .address-info .img-add {
		width: 0.36rem;
		margin-right: 0.2rem;
	}

	.address-box .address-info .address-right {
		flex: 1;
	}

	.address-box .address-info .address-right .use-info {
		flex: 1;
	}

	.address-box .address-info .address-right .use-info .right-mobile span {
		font-size: 0.3rem;
	}

	.address-box .address-info .address-right .add-cont {
		font-size: 0.28rem;
		color: #999;
	}

	.address-box .address-info .address-right .img-r {
		width: 0.16rem;
		margin-left: 0.28rem;
	}


	/* 商品详情列表 */
	.good-list {
		width: 100%;
		padding: 0.2rem 0;
	}

	.good-list .good-item {
		width: 100%;
		padding: 0.2rem;
		box-sizing: border-box;
		border-bottom: 0.2rem solid #fff;
		background: #f7f7f7;
	}

	.good-list .good-item .gift-box{
		font-size:0.26rem;
		color:#f32222;
		padding-left:0.2rem;
		box-sizing:border-box;
	}

	.good-list .good-item:last-child {
		border-bottom: none;
	}

	.good-item .item-left {
		width: 2.3rem;
		margin-right: 0.12rem;
	}

	.good-item .item-left .left-img {
		width: 2rem;
		max-height: 2rem;
		margin: 0 auto;
		border-radius:0.12rem;
		object-fit: contain;
	}

	.good-item .item-left .item-setime {
		width: 80%;
		margin: 0.12rem auto 0;
		padding: 0.02rem 0.16rem;
		background: #199ed8;
		border-radius: 0.28rem;
	}

	.good-item .item-left .item-setime img {
		width: 0.22rem;
		height: 0.22rem;
		margin-right: 0.12rem;
	}

	.good-item .item-left .item-setime .settime-tit {
		color: #fff;
		font-size: 0.22rem;
	}

	.good-item .item-info {
		flex: 1;
		box-sizing: border-box;
		justify-content: space-between;
	}

	.good-item .item-info .info-name {
		width: 4rem;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-weight: 600;
		font-size: 0.3rem;
	}

	.good-item .item-info .info-stock .stock-tit {
		font-size: 0.24rem;
	}

	.good-item .item-info .info-stock .stock-quota {
		font-size: 0.24rem;
	}

	.good-item .item-info .info-specifi {
		font-size: 0.24rem;
	}

	.good-item .item-info .info-company {
		font-size: 0.28rem;
		width: 4rem;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.good-item .item-info .info-seck .info-price .info-tit {
		font-size: 0.36rem;
	}

	.good-item .item-info .info-seck .info-price .info-old-price {
		font-size: 0.24rem;
		margin-left: 0.12rem;
		text-decoration: line-through;
	}

	.good-item .item-info .info-seck .seck-btn {
		display: inline-block;
		padding: 0.02rem 0.2rem;
		font-size: 0.28rem;
	}


	/* 优惠券、发票 */
	.invoce-box {
		width: 100%;
		padding: 0 0.2rem;
		box-sizing: border-box;
	}

	.invoce-box .invoce-item {
		width: 100%;
		height: 0.8rem;
		border-bottom: 0.02rem solid #eee;
	}

	.invoce-box .invoce-item:last-child {
		border-bottom: none;
	}

	.invoce-box .invoce-item .invoce-tit {
		font-size: 0.3rem;
		width: 1.6rem;
	}

	.invoce-box .invoce-item .picker-box {
		flex: 1;
	}

	.invoce-box .invoce-item .picker-box .picker-tit {
		font-size: 0.28rem;
		flex: 1;
		text-align: right;
	}

	.invoce-box .invoce-item .picker-box img {
		width: 0.15rem;
		margin-left: 0.18rem;
	}


	/* 价格详情 */
	.price-list {
		width: 100%;
		padding: 0.1rem 0.2rem;
		box-sizing: border-box;
	}

	.price-list .price-item {
		width: 100%;
		height: 0.6rem;
	}

	.price-list .price-item:first-child {
		border-bottom: 0.02rem solid #eee;
	}

	.price-list .price-item span {
		font-size: 0.3rem;
	}

	.price-list .price-item span.item-tit {
		font-size: 0.32rem;
	}


	/* 备注 */
	.remarks {
		width: 100%;
		height: 1.2rem;
		padding: 0 0.2rem;
		box-sizing: border-box;
	}

	.remarks span {
		font-size: 0.32rem;
		width: 1.2rem;
	}

	.remarks input {
		flex: 1;
		font-size: 0.3rem;
	}


	/* 底部  总价 */
	.bot-fixed {
		width: 100%;
		height: 1rem;
	}

	.bot-total {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1rem;
		padding: 0 0.2rem;
		box-sizing: border-box;
		z-index: 2;
		background: #fff;
		border-top: 0.02rem solid #eee;
	}

	.bot-total .total-left {
		flex: 1;
	}

	.bot-total .total-left .left-tit {
		font-size: 0.3rem;
	}

	.bot-total .total-left .total-price span:first-child {
		font-size: 0.3rem;
	}

	.bot-total .total-left .total-price span:last-child {
		font-size: 0.36rem;
	}

	.bot-total .but-submit {
		font-size: 0.3rem;
		background: #f32222;
		;
		padding: 0.08rem 0.2rem;
		border-radius: 0.2rem;
		color: #fff;
		margin-left: 0.16rem;
	}


	/* 套餐包 */
	.good-list .good-item .pack-item{ margin-bottom:0.2rem; }
	.good-list .good-item .pack-seck{ display:flex;justify-content:flex-end; }
	.good-list .good-item .pack-seck .info-price .info-tit{ font-size:0.32rem; }
	.good-list .good-item .pack-seck .seck-btn{ font-size:0.3rem;margin-left:0.6rem; }
</style>
<style>
	/* 弹窗背景色 */
	.van-overlay {
		background-color: rgba(0, 0, 0, 0.6) !important;
	}
	.roundTable{
		margin-top: 1rem /* 50/50 */;
	}
	.tab-tit{
		line-height: 0.9rem;
	}
.goIndex button{		
    font-size: 0.31rem;
    background-color: #ff8800;
    color: white;
    position: fixed;
    bottom: 2.34rem ;
    border-bottom-right-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    padding: 0.1rem 0.1rem 0.1rem 0.05rem;
	}
	.giveaway{
		font-size: 0.28rem;
		color: #f32222;
		width: 100%;
		box-sizing: border-box;
		background: #f7f7f7;
		padding: 0 0.2rem;
	}
	.phones{
	width: 6.24rem ;
    position: fixed;
    top: 3.2rem ;
    left: .45rem ;
    background:rgba(58, 58, 58, 0.8);
	border-radius: 0.2rem;
    padding: 1.2rem  0 .5rem .2rem ;
	font-size: .37rem;
	color: white;

	}
	.phones .num{
		border: .02rem /* 1/50 */ solid 	rgb(253, 251, 251);;
		width: 4rem /* 200/50 */;
		padding-left: .1rem;
		height: .8rem;
		color: rgb(253, 251, 251);
	}
	.num::placeholder{
			color: rgb(216, 213, 213);
	}
	.phones button {
			background-color: #f74949;
			border-radius: .2rem;
			padding: .1rem .8rem;
			margin: 1rem .8rem .0 1.5rem

		};
	.phones div:nth-child(2){
		margin-top: .2rem;
	}
	.closure{
	position: fixed;
    top: 3.56rem ;
    right: .9rem ;
	}
	.tel{
		width: 1.45rem ;
	}
</style>
