//  处理公共的js,请求，时间处理函数,上传压缩图片
import axios from "axios";
import store from "../src/store/index.js"

import {
	Toast
} from "vant"
Vue.use(Toast);

var setting = {
	compressImg: compressImg,
	getCookie: getCookie,
	setCookie: setCookie,
	delCookie: delCookie,
	encrypt: encrypt,
	decrypt: decrypt,
	request: request,
	urlObj: urlObj,
	getCartNum: getCartNum,
	countdown: countdown,
	timeStamp:timeStamp,
	getOrgWebInfo: getOrgWebInfo,	
	getWebconfig: getWebconfig,
	IsMiniProgram: IsMiniProgram,
	isWx: isWx,
	_Requst:_Requst,
	downloadIamge: downloadIamge,
	getopenId: getopenId,
	getUserInfo: getUserInfo,
	getConfig: {
		/**
		 * 默认机构标识
		 */
		defaultOrgId: window.baseUrl.orgId,

	},
	setBuryPoint: setBuryPoint  //统计分析点
}

// 响应拦截
axios.interceptors.response.use(response=>{
	var customer1=response.config.headers.customer;
	var customer2=response.headers.customer;
	if(customer2){
		if(customer1!=customer2){
			sessionStorage.setItem('customer',customer2)
		}
	}
	return response;
});


// request 拦截器
// 可以自请求发送前对请求做一些处理
// axios.interceptors.request.use(config => {
// 	//接受JSON数据格式
    
//     var token = sessionStorage.getItem('member_token')||"";
// 	var orgId = setting.getConfig.defaultOrgId;
// 	var customer = sessionStorage.getItem('customer')||"";
// 	config.headers["token"] = token; // 将token拼接到headers头部
// 	config.headers["orgId"] = orgId; // 将orgId拼接到headers头部
// 	config.headers["customer"] = customer; // 将customer拼接到headers头部   一个人所属的药店
// 	config.headers["Content-Type"] = "application/json";

//     return config
// })


function request(type, map, data, callBack, typeGet) {
	
	var token = sessionStorage.getItem('member_token')||"";
	var orgId = setting.getConfig.defaultOrgId;
	var localOrgId = localStorage.orgId;	// 本地缓存的orgId
	if(orgId != localOrgId){	// 如果设置的orgId不等于缓存的orgId，则用设置的orgId
		localStorage.removeItem("orgId");
		localStorage.setItem("orgId",orgId);
		orgId = orgId;
	}
	var customer = sessionStorage.getItem('customer')||"";
	axios.defaults.headers["token"] = token; // 将token拼接到headers头部
	axios.defaults.headers["orgId"] = orgId; // 将orgId拼接到headers头部
	axios.defaults.headers["customer"] = customer; // 将customer拼接到headers头部   一个人所属的药店
	// axios.defaults.headers["Content-Type"] = "application/json";
	// axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
	if(map.indexOf('?')!=-1){
		map = map + "&_d=" + new Date().valueOf();
	}else{
		map = map + "?_d=" + new Date().valueOf();
	}
	if (type === "get") {
		axios.get(map, {
			params: data,
		}).then(res => {
			// console.log(res);
			if(!res.data){
				return;
			}
			if (res.data.errorCode !== "00" && res.status != '200') {
				Toast("网络异常请稍后重试");
				callBack(res);
				return;
			};
			callBack(res);
		}).catch(err => {
			console.log("err1",err);
			Toast("服务器错误");
		});
	} else if (type === "post") {
		// post请求中用get传参
		if (typeGet === "get") {
		 	axios.post(map, "", {
				params: data
			}).then(res => {
				callBack(res);
			}).catch(err => {
				console.log("err2", err);
				Toast("服务器错误");
			});
		} else if (typeGet === "upImg") { // 上传图片
			axios.post(map, data).then(res => {
				callBack(res);
			}).catch(err => {
				console.log("err3", err);
				Toast("服务器错误");
			});
		} else { // 正常的post的请求
			// console.log(data);
			axios.post(map, data).then(res => {
			// axios.post(map, qs.stringify(data)).then(res => {
				// console.log(res);
				if (res.data.errorCode !== "00" &&
					res.data.errorCode !== "01" &&
					res.data.errorCode !== "02" &&
					res.data.errorCode !== "03" &&
					res.data.errorCode !== "98") {

					// console.log(`Post ${+ " "}${res.data.errorMsg}`);

					if (res.data.errorMsg.indexOf("Connection") > -1 ||
						res.data.errorMsg.toLowerCase().indexOf("timeout") > -1) {
						Toast("网络卡了,请稍后刷新下");
						return;
					}

					callBack(res);
					return;
				};
				callBack(res);
			}).catch(err => {
				console.log("err4", err);
				Toast("服务器错误");
			});
		}

	}
}

/**
 * 埋点，分析统计点
 * @inOut  操作类型 1-界面进入，2-界面离开
 * @pointType 统计位置类型 1 首页 2 分类 3搜索  4商品详情 5加入购物车  6下单 7支付
 * @keyId 埋点主键
 * @apiResult 埋点接口数据信息
 */
function setBuryPoint(pointType,inOut,keyId,apiResult,goodsId){
	let that = this;
	let client = localStorage.getItem("clientType");
	return new Promise((resolve,reject) => {
		that.request("post","/api/BuriedPointLog/create",{
			ClientType: (client=="applets"?2:1), //客户端类型  1 客户端  2 TMS
			OrgId: localStorage.getItem("orgId"),
			Id: keyId,
			Type: pointType,
			PageType: inOut,
			ApiResultFlg: (apiResult?JSON.stringify(apiResult):""),
			SpId: (goodsId?goodsId:""), //4-商品详情用
			Remark: "" //备注
		},res => {
			resolve(res.data);
		},err=>{
			reject(err);
		});
	})
}

// 压缩图片
function compressImg(base64, scale, files, callback) {
	// 处理缩放，转换格式
	// 下面的注释就不写了，就是new 一个图片，用canvas来压缩
	const img = new Image();
	img.src = base64;
	img.onload = function() {
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");
		canvas.setAttribute("width", this.width);
		canvas.setAttribute("height", this.height);
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
		// 转成base64 文件
		let base64 = canvas.toDataURL("image/jpeg");
		// 根据自己需求填写大小 我的目标是小于2兆
		while (base64.length > 1024 * 1024 * 2) {
			scale -= 0.01;
			base64 = canvas.toDataURL("image/jpeg", scale);
		}
		// baser64 TO blob 
		const arr = base64.split(",");
		const mime = arr[0].match(/:(.*?);/)[1];

		const bytes = atob(arr[1]);
		const bytesLength = bytes.length;
		const u8arr = new Uint8Array(bytesLength);
		for (let i = 0; i < bytes.length; i++) {
			u8arr[i] = bytes.charCodeAt(i);
		}
		const blob = new Blob([u8arr], {
			type: mime
		});
		callback(blob, base64, files);
	};

}

// 设置cookies
function setCookie(name, value, time) {
	const newDate = new Date();
	newDate.setTime(time * 1000);
	document.cookie = name + "=" + value + ";path=/;expires=" + newDate.toGMTString();
}

// 获取getCoolie
function getCookie(name) {
	var arr;
	const reg = new RegExp(`(^| )${name}=([^;]*)(;|$)`);
	if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
	else return null;
}

//删除三方登录的cookie
function delCookie(name) {
	const exp = new Date();
	exp.setTime(exp.getTime() - 1);
	const cval = this.getCookie(name);
	if (cval != null) {
		document.cookie =
			name + "=" + cval + ";path=/;expires=" + exp.toGMTString();
	}
}

// 加密
import CryptoJS from "crypto-js";
import Vue from "vue";

function encrypt(portId, psw) {
	const cipherText = CryptoJS.AES.encrypt(
		portId + "",
		"secretkey123"
	).toString();
	const cipherPsw = CryptoJS.AES.encrypt(psw + "", "secretkey123").toString();
	// console.log(cipherText + "---" + cipherPsw);
	const obj = {
		cipherText: cipherText,
		cipherPsw: cipherPsw
	};
	return obj;
}

// 解密
function decrypt(value) {
	const bytes = CryptoJS.AES.decrypt(value, "secretkey123");
	const values = bytes.toString(CryptoJS.enc.Utf8);
	return values;
}

// url路径解密
function urlObj(url) {
	if(url){
		// console.log('url',url);
	const params = url.split("?")[1].split("&");
	var obj = {};
	// for (var i = 0; i < params.length; i++) {
	// var param = params[i].split("=");
	// obj[param[0]] = unescape(param[1]);
	// }
	params.map(item => obj[item.split("=")[0]] = unescape(item.split("=")[1]));
	// console.log('obj',obj);

	return obj;
	}
}
    //获取浏览器参数
    function _Requst (queryString) {
        if (!queryString) return "";
        var name, value = "";
        var str = window.location.search;
        var num = str.indexOf("?");
        str = str.substr(num + 1); //取得所有参数   stringvar.substr(start [, length ]
        var arr = str.split("&"); //各个参数放到数组里
        for (var i = 0; i < arr.length; i++) {
            num = arr[i].indexOf("=");
            if (num > 0) {
                name = arr[i].substring(0, num);
                if (name.toLowerCase() === queryString.toLowerCase()) {
                    value = arr[i].substr(num + 1);
                    break;
                }
            }
        }
        return unescape(value);
       
    };


// 购物车数量
function getCartNum() {
	const that = this;
	const data = {};
	that.request("post",
		"/api/sellWellCar/querySellWellCarCount",
		data,
		res => {
			// console.log(res);
			
			if (res.data.errorCode == "00") {
				store.commit("setCartNum", res.data.rows.Count);
			}

		});
}

// 获取机构设置
function getOrgWebInfo(callBack) {
	const that = this;
	const orgId = localStorage.getItem("orgId");
	const data = {
		orgId: orgId,
	};
	that.request("get",
		"/api/SysOrgInfo/QueryOrgWebConfig",
		data,
		res => {
			// console.log(res);
			if (res.data.errorCode !== "00") {
				return false;
			}
			try {
				localStorage.ReceivePayNotify = res.rows.ReceivePayNotify;
			} catch (e) {

			}
			callBack(res.data.rows);
		});
}

// 路由守卫调用接口
function getWebconfig(){
	var that = this;
	return new Promise((resolve,reject) => {
		const orgId = setting.getConfig.defaultOrgId;
		const data = {
			orgId: orgId,
		};
		axios.get('/api/SysOrgInfo/QueryOrgWebConfig',{
			params: data,
		}).then(res => {
			if (res.data.errorCode !== "00" && res.status != '200') {
				Toast("网络异常请稍后重试");
				reject(res);
				return;
			};
			try {
				localStorage.ReceivePayNotify = res.rows.ReceivePayNotify;
			} catch (e) {

			}
			resolve(res);
		}).catch(err => {
			reject(err);
		})
	})
}

// 天时分秒 倒计时
// 倒计时
function countdown(endTime, callBack) {
	const that = this;
	const start = new Date().getTime();
	endTime = endTime.replace(/-/g, '/');
	const end = new Date(endTime).getTime();
	// console.log(start + "---" + end);
	var time = parseInt(end/1000 - start/1000);

	// 首次进入页面先渲染数据
	const day = parseInt(time / 60 / 60 / 24);
	var hourse = parseInt(time / 60 / 60) % 24;
	var minute = parseInt(time / 60) % 60;
	var second = parseInt(time % 60);
	hourse = hourse < 10 ? (`0${hourse}`) : hourse;
	minute = minute < 10 ? (`0${minute}`) : minute;
	second = second < 10 ? (`0${second}`) : second;
	const timeDown = day + "天" + hourse + ":" + minute + ":" + second;
	
	callBack(timeDown);
	var setInter = setInterval(function() {
		if (time <= 0) {
			clearInterval(setInter);
			callBack("活动已结束");
		} else {
			time--;
			const day = parseInt(time / 60 / 60 / 24);
			let hourse = parseInt(time / 60 / 60) % 24;
			let minute = parseInt(time / 60) % 60;
			let second = parseInt(time % 60);
			hourse = hourse < 10 ? (`0${hourse}`) : hourse;
			minute = minute < 10 ? (`0${minute}`) : minute;
			second = second < 10 ? (`0${second}`) : second;
			const timeDown = day + "天" + hourse + ":" + minute + ":" + second;
			callBack(timeDown);
		}
	},
	1000);


}
// 处理首页倒计时(时间戳)
function timeStamp(endTime){
	const that = this;
	const start = new Date().getTime();
	endTime = endTime.replace(/-/g, '/');
	const end = new Date(endTime).getTime();
	// console.log(start + "---" + end);
	var time = parseInt(end/1000 - start/1000);
	return time;
}

// 小程序验证
function IsMiniProgram() {
	if (/MicroMessenger/i.test(navigator.userAgent)) {
		//ios的ua中无miniProgram，很坑爹,但都有MicroMessenger（表示是微信浏览器）
		this.wx.miniProgram.getEnv((res) => {
		if (res.miniprogram) {
			return true;
		} else {
			return false;
		}
		});
	}
	return false;
}
// 微信公众号验证
function isWx() {
	if (/MicroMessenger/i.test(navigator.userAgent)) {
		return true;
	}
	return false;
}

// 下载图片到相册
function downloadIamge(imgsrc,name){
	var alink = document.createElement("a");
	alink.href = imgsrc;
	alink.download = "pic"; //图片名
	alink.click();

	// var image = new Image();

	// // 解决跨域 Canvas 污染问题
	// // image.setAttribute("crossOrigin", "anonymous");

	// image.onload = function() {

	// 	var canvas = document.createElement("canvas");

	// 	canvas.width = image.width;

	// 	canvas.height = image.height;

	// 	var context = canvas.getContext("2d");

	// 	context.drawImage(image, 0, 0, image.width, image.height);

	// 	var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

	// 	var a = document.createElement("a"); // 生成一个a元素

	// 	var event = new MouseEvent("click"); // 创建一个单击事件

	// 	a.download = name || "photo"; // 设置图片名称

	// 	a.href = url; // 将生成的URL设置为a.href属性

	// 	a.dispatchEvent(event); // 触发a的单击事件

	// };

	// image.src = imgsrc;

}

// 获取公众号的openid
function getopenId(){
	var urlArgs = getURLParameters();
	var wxCode = localStorage.getItem("wxCode");
	if (urlArgs.code && wxCode != urlArgs.code){
		getUserInfo(urlArgs.code).then(res => {
			// console.log(res);
			if(res.data.errorCode == '00'){
				localStorage.setItem('openid',res.data.rows.OpenId);
				// sessionStorage.setItem("wxUserInfo",resData.rows);
				sessionStorage.setItem("wxUserInfo",res.data.rows);
			}

		}).catch(res => {
			// console.log(res);
			localStorage.setItem('openid',"oKCM801mgUTOKmJrK3TkeiC_ypxQ");
		});
	}else{
		getCode();
	}
}

// 解析路由
function getURLParameters(){
    var url = location.href;
    return (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
        (a, v) => ((a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)), a),
        {}
    );
}

function getCode(){
    var that = this;
    var redirect = encodeURIComponent(funcUrlDel("code"));
	var orgWebconfig = sessionStorage.getItem("orgWebconfig");
	orgWebconfig = JSON.parse(orgWebconfig);
	console.log("app",orgWebconfig.WechatAppId);
    // var url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid="+orgWebconfig.WechatAppId+"&redirect_uri="+redirect+"&response_type=code&scope=snsapi_userinfo&state=state&connect_redirect=1#wechat_redirect";
	var url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid="+window.baseUrl.appid+"&redirect_uri="+redirect+"&response_type=code&scope=snsapi_userinfo&state=state&connect_redirect=1#wechat_redirect";
    location.href = url;
}
// 删除路由中的参数，防止重复
function funcUrlDel(name){
	var loca = window.location;
	var baseUrl = loca.origin + loca.pathname + "?";
	var query = loca.search.substr(1);
	if (query.indexOf(name) > -1) {
		var obj = {}
		var arr = query.split("&");
		for (var i = 0; i < arr.length; i++) {
			arr[i] = arr[i].split("=");
			obj[arr[i][0]] = arr[i][1];
		};
		delete obj[name];
		var url = baseUrl + JSON.stringify(obj).replace(/[\"\{\}]/g, "").replace(/\:/g, "=").replace(/\,/g, "&");
		return url
	} else {
		return window.location.href;
	};
}
// 获取客户信息
function getUserInfo(code){
    var that = this;
    localStorage.setItem("wxCode",code);
    // console.log("=========ab");
    
    return new Promise((resolve, reject) => {
	  var orgId = setting.getConfig.defaultOrgId;
      var data={
        state: orgId,//机构标识
        code: code//微信code
      };
    //   console.log("=========abc");
    request('post','/api/OAuth2/GetWeChatInfo',data,res => {
        // console.log("=========ab123");
        if(res.data.errorCode == '00'){
        //   console.log("=========ab234");
          resolve(res);
        }else{
			// console.log("=========ab234",res);
          reject(res);
        }
      })
      
    });

}



export default setting;
