<template>
    <div class="container-box">
        <div class="tab-top">
          <!-- <div class="tab-tit">
             下单成功
          </div> -->
          <div class="info flex-ac">
            <div class="info-item">
                <div class="number">1</div>
                <div>购物车</div>
                <div class="line"></div>
            </div>    
            <div class="info-item">
                <div class="line-left"></div>
                <div class="number">2</div>
                <div>收货信息</div>
                <div class="line"></div>
            </div>  
            <div class="info-item">
                <div class="line-left"></div>
                <div class="number">3</div>
                <div>确认订单</div>
                <div class="line"></div>
            </div>  
            <div class="info-item">
                <div class="line-left"></div>
                <div class="number">4</div>
                <div>下单成功</div>
            </div> 
          </div>
        </div>
        <div class="order-box">
            <img class="succeed-img" v-if="!oteherDetail.AdvancePay" src="../../assets/images/succeed.png" alt="">
            <img class="succeed-img" v-if="oteherDetail.AdvancePay && !oteherDetail.Payed" src="../../assets/images/icon_fail.png" alt="">
            <img class="succeed-img" v-if="oteherDetail.AdvancePay  && oteherDetail.Payed" src="../../assets/images/succeed.png" alt="">
            <div class="succeed-top" v-if="!oteherDetail.AdvancePay" >下单成功</div>
            <div class="succeed-top" v-if="oteherDetail.AdvancePay &&  !oteherDetail.Payed" >下单成功，支付失败</div>
            <div class="succeed-top" v-if="oteherDetail.AdvancePay && oteherDetail.Payed" >下单成功，支付成功</div>
           <div v-for="(item,ind) in orderDetail" :key="ind" class="item" >
                <div class="order-b flex-wrap">
                    <div class="order-name">订单号：</div>
                    <div class="order-tit">{{item.OrderNo}}</div>
                </div>
                
                <div class="order-b flex-wrap">
                    <div class="order-name">下单金额</div>
                    <div class="order-tit">￥{{item.OrderCost}}</div>
                </div>
           </div>
            <div class="order-b flex-wrap">
                <div class="order-name">下单时间：</div>
                <div class="order-tit">{{oteherDetail.CreateDate}}</div>
            </div>
            <!-- <div class="order-b flex-wrap">
                <div class="order-name">发票信息</div>
                <div class="order-tit">{{oteherDetail.TmsInvoiceTypeName}}</div>
            </div> -->
            
            <div class="but-box">
                <div class="but-b" @click="toOrderPage()">查看订单</div>
                <div class="but-b" style="background:rgb(175 172 172 / 93%);" @click="toIndex()">返回首页</div>
            </div>
        </div>

        <!-- 大转盘弹窗 -->
        <!-- <div class="turntable-popup" v-if="turntableAct" @click="closeTurntable"></div>
        <div class="turntable-pop" v-if="turntableAct" @click="toTurntable(orderDetail.OrderNo,orderDetail.AdvancePay)"> -->
            <!-- <img class="close-img" src="@/assets/images/icon_close.png" @click.stop="closeTurntable"> -->
            <!-- 禹药翁 -->
            <!-- <img class="turntable-img" v-if="orgId==='42d0b61b7a7741fa9bb7b91d3422ee0c'" src="@/assets/images/newyear_redpacket/coupon_active.png" alt="">
            <img class="turntable-img" v-if="orgId!=='42d0b61b7a7741fa9bb7b91d3422ee0c'" src="@/assets/images/newyear_redpacket/redPacket.png" alt="">
        </div> -->
    </div>
</template>

<script>
// import order from '../../../public/api/order'
import order from '@/assets/js/api/order'
export default {
    data(){
        return{
			MasterBatchNo: "",
			BatchNo: "",
            orderDetail: [],    // 订单详情
            turntableAct: false,  // 判断转盘
            orgId: '',
            oteherDetail:[],
            timer: null,    // 定时器
        }
    },
    created(){
        var that = this;
        that.orgId = localStorage.getItem("orgId");
        // 获取路由上的参数
        this.BatchNo = that.$route.query.BatchNo;
		this.MasterBatchNo = that.$route.query.MasterBatchNo;
        that.getOrderDetail();
        // 请求购物车数量
        that.public.getCartNum();
    },
    beforeCreate() {
    // document.querySelector("body").setAttribute("style", "background:#f2f2f2");
    },
    destroyed(){
        //    document.querySelector("body").setAttribute("style", "background:#FFFFFF");
        clearInterval(this.timer);
    },
    methods:{
        // 查看订单
        toOrderPage(){
            var that = this;
            that.$router.push({
                path: '/purchaseOrder'
            })
        },
        // 返回首页
        toIndex(){
            this.$router.push({
                path: '/'
            })
        },
        getOrderDetail(){
            var that = this;
            that.public.request('post','api/ordersInfo/queryBatchOrders',{
				BatchOrderNo: this.BatchNo,
				MasterBatchNo: this.MasterBatchNo
			},res => {
                if(res.data.errorCode=='00'){
                    that.orderDetail  =res.data.rows;
                    that.oteherDetail=res.data.rows[0];
                }
            })
        },

        // 请求订单详情
        // getOrderDetail(){
        //     var that = this;
        //     var query = that.query;
        //     var data = {
        //         OrderNo: query.OrderNo,
        //     };
        //     order.queryOrdersDetail(data,res => {
           
        //         if(res.data.errorCode == '00'){
        //             that.orderDetail = res.data.rows[0].orderInfo;
        //             var payType=res.data.rows[0].orderInfo.AdvancePay;
        //             if(payType==false){
        //                 that.isTurntable(data.OrderNo,payType);
        //             }
        //         }
        //     })


        // },
        // 跳转大转盘页面
        toTurntable(OrderNo,AdvancePay){
            var that = this;
            that.$router.push({
                path: '/turntable',
                query:{
                    OrderNo: OrderNo,
                    AdvancePay: AdvancePay,
                }
            })
        },

        // 判断是否有下单后发放的转盘活动
        isTurntable(OrderNo,AdvancePay){
            var that = this;
            var orgId = localStorage.getItem('orgId');
            var data = {
                
                OrderNo: OrderNo,
                orgId: orgId,
            }
            that.public.request('post','api/Turntable/GetTurntableListByCustomerId',data, res => {
                // console.log(res);
                if(res.data.errorCode == '00'){
                    if(res.data.extend){
                        var totalMoney=res.data.extend.OrderMoney;
                        var criteria =res.data.extend.Info.WhereMoney;
                        if(parseInt(totalMoney/criteria)>=1){
                            that.turntableAct=true;
                        }else{
                            that.turntableAct=false;
                            return;
                        }
                    }
                    if(res.data.rows!=null&&res.data.rows!==false){
                        that.turntableAct=true;
                        
                    }else{
                        that.turntableAct=false;
                        return;
                    }
                }
            })
        },
        // 关闭大转盘
        closeTurntable(){
            var that = this;
            that.turntableAct = false;
        },
    }
}
</script>

<style scoped>
	.container-box{
		background: #f2f2f2;
	}
/* body{background-color: #f2f2f2;} */
.tab-top{padding: 0;background-color: var(--theme-color); color: white;border-bottom:0 ;font-size: 0.3rem;} 
    .order-box{ width:100%;padding:0.2rem;box-sizing:border-box; margin-top: 3rem;}
    .order-b .order-name{ width:1.5rem;font-size:0.3rem; }
    .order-b .order-tit{ font-size:0.26rem; }

    /* 查看订单、返回首页 */
    .but-box{ display:flex;align-items:center;justify-content:space-around;margin-top:0.2rem; }
    .but-box .but-b{ padding:0.05rem 0.25rem; color:#fff;background:var(--theme-color);border-radius:0.12rem;font-size:0.3rem;margin-top: 0.2rem; }
    

    /* 大转盘弹窗 */
    .turntable-popup{ position:fixed;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,0.4);z-index:9; }
    .turntable-pop{ position:fixed;top:20%;left:10%;width:80%;z-index:10;border-radius:7%; }
    .turntable-pop .close-img{ width:0.5rem;position:absolute;top:0.03rem;right:0.03rem; }
    .turntable-pop .turntable-img{ width:100%; }
    .item{margin-bottom: 0.05rem; border-bottom: .02rem /* 1/50 */ solid #cccccb;}

    .info{background-color: var(--theme-color);position: relative; padding: 1rem 0;}
    .flex-ac{display: flex;align-items: center;}
    .number{    width: 20px;  height: 20px;  line-height: 20px;  text-align: center;  border-radius: 50%;  color:var(--theme-color); margin: 0 auto;background: #fcfdfd;  margin-bottom: 10px;}
    .line{width: 46%; height: 2px; position: absolute; top: 18%; right: 0; background: #fcfcfc;}
    .info-item{   width: 25%; float: left; text-align: center; position: relative;  }
    .line-left{width: 46%; height: 2px;  position: absolute;  top: 18%; left: 0;background-color: #ffffff;}
.order-box{position: absolute;    width: 83%;
    top: 0.35rem;
    left: 0.62rem;
      padding: 0.35rem 1rem;
    z-index: 99;
    border-radius: 0.2rem;
    /* text-align: center; */
    background: rgb(243, 243, 243);}
    .succeed-img{width: 20%;margin: 0 auto;margin-bottom: 0.2rem;}
.succeed-top{text-align: center;font-size: 0.4rem;}
</style>