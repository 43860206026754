<template>
	<div id="container">
		<div class="banner">
			<!-- <img src="@/assets/images/banner-new-.jpg"> -->
			<img src="@/assets/images/banner-new-bg.jpg" alt="" />
		</div>
		<!-- <div class="product-list">
			<div class="cell" v-for="item in productList" @click="handleDetails(item)">
				<div class="img-box">
					<img v-if="item.SpPicExtend" :src="item.SpPicExtend">
					<img v-else src="@/assets/images/no_good.png" alt="商品图片">
					<div class="tips" v-if="item.SetpStock<=0">即将到货</div>
				</div>
				<div class="tit">
					<span class="name">{{item.spmch}}</span>
					<span class="spec">{{item.shpgg}}</span>
				</div>
				<p class="shop">{{item.shengccj}}</p>
				<p class="price">￥{{item.zdshj}}</p>
				<div class="money">零售价:￥{{item.AdvicePrice}} <i>|</i> 毛利率: {{item.MarginRate}}%</div>
			</div>
		</div>
		<div class="empty" v-if="productList.length==0">
			<img src="@/assets/images/cart/empty_img.png" alt="">
			<span>暂无数据</span>
		</div> -->
	</div>
</template>

<script>
	export default{
		components:{},
		data(){
			return{
				productList: []
			}
		},
		beforeRouteEnter(to, from, next) {
			if(from.path == "/") {//从首页进来，刷新
				to.meta.isRefresh = true; //从首页过来
			}else{
				to.meta.isRefresh = false;// 通过返回过来
			}
			next();
		},
		created(){
			// this.queryProducts();
		},
		activated() {
			if(this.$route.meta.isRefresh){
				// this.queryProducts();
			}
		},
		methods:{
			/**
			 * 查询新品列表
			 */
			queryProducts(){
				let that = this;
				this.public.request("post","api/Store/QueryStoreGoods",{
					OrgId: localStorage.getItem("orgId"),
					IsNewFlag: true,
					page: 1,
					rows: 50,
				},res=>{
					if(res.data.errorCode=="00"){
						that.productList = res.data.rows;
					}else{
						 that.$toast(res.data.errorMsg);
					}
				})
			},
			
			/**
			 * 商品详情
			 */
			handleDetails(goods){
				this.$router.push({
				    path: '/goodsDetail',
				    query:{
				        spid: goods.spid,
				        headTit: '详情'
				    }
				})
			}
			
		}
	}
</script>

<style scoped>
	.banner img{
		width: 100%;
		/* height: 200px; */
		/* margin-top: 1px; */
	}
	.product-list{
		position: relative;
		padding-bottom: 20px;
		display: flex;
		flex-flow: wrap;
		align-items: center;
	}
	.product-list .cell{
		position: relative;
		width: 45%;
		padding: 4px;
		margin: 15px 0 0 2%;	
		border-radius: 3px;
		box-shadow: 0px 0px 5px #b9b9b9;
	}
	.product-list .cell .img-box{
		position: relative;
		/* height: 130px; */
	}
	.product-list .cell .img-box img{
		width: 100%;
		height: 130px;
		border-radius: 3px;
	}
	.product-list .cell .img-box .tips{
		position: absolute;
		width: 100%;
		height: 25px;
		color: #fff;
		font-size: 14px;
		line-height: 25px;
		text-align: center;
		background: #111;
		opacity: 0.5;
		bottom: 0;
	}
	.product-list .cell .tit{
		margin-top: 5px;
		font-size: 15px;
		color: #111;
		/* display: -webkit-box;
		overflow: hidden;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1; */
	}
	.product-list .cell .tit .name{
		font-weight: bold;
		/* overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis; */
	}
	.product-list .cell .tit .spec{
		font-size: 12px;
		color: #333;
	}
	.product-list .cell .shop{
		font-size: 12px;
		margin: 2px 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.product-list .cell .price{
		font-size: 16px;
		color: red;
		font-weight: bold;
		margin: 2px 0;
	}
	.product-list .cell .money{
		font-size: 12px;
		color: #999;
	}
	
	.empty{
		display: flex;
		flex-direction: column;
		font-size: 15px;
		color: #666;
		align-items: center;
		justify-content: center;
		margin-top: 100px;
	}
	.empty img{
		width: 120px;
		height: 120px;
		margin-bottom: 10px;
	}
</style>