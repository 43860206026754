<template>
    <div >
        <div class="integral-item">
            <div class="shop-info flex-wrap">
                <div class="shop-img">
                    <img :src=itemList.Pic alt="">
                </div>
                <div class="info-right">
                    <div class="shop-name flex-sp">
                        <div class="txt-n">{{itemList.Name}}（{{itemList.GoodsStyle}}/{{itemList.GoodsUnit}}）</div>
                        <div class="price-n">￥{{itemList.GoodsPrice}} X {{itemList.Num}}</div>
                    </div>
                    <div class="info-factory">生产厂家：{{itemList.GoodsFactory}}</div>
                    <div class="info-number">{{itemList.OrderNo}}</div>
                    <div class="info-integral">-10 积分</div>
                </div>
            </div>
            <div class="convert-box flex-sp">
                <span class="convert-time">兑换时间：{{itemList.SubmitDate}}</span>
                <div class="convert-statu flex-wrap" v-if="type">
                    <img src="@/assets/images/supper.png" alt="">
                    <span>{{itemList.StatusName}}</span>
                </div>
            </div>
            <div class="operation-box flex-sp" v-if="!type">
                <div class="opera-statu flex-wrap">
                    <img src="@/assets/images/supper.png" alt="">
                    <span>{{itemList.StatusName}}</span>
                </div>
                <div class="opera-but flex-wrap" >
                    <div class="but-item" @click="checkOrder()">查看订单</div>
                    <div class="but-item active" @click="exchangeBtn()">再次兑换</div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    name:'integralGoodItem',
    props:{
        itemList:{
           type: Object, 
        },
        type:{
            type: String, 
        }
    },
    data(){
        return{
        }
    },
    methods: {
        checkOrder(){
            var that=this;
            var no=that.itemList.OrderNo
            that.$router.push({ 
                path: '/pointsOrderDetails',
                 query: {no},
                })

        },
        exchangeBtn(){
              var that=this;
              const mid=that.itemList.Mid;
              const  id=that.itemList.ItemId;
              const type=10;
              that.$router.push({
                path: '/details',
                 query: {mid,id,type},
              });
        }
    },
}
</script>

<style scoped>
    .integral-item{ width:100%;padding:0.2rem 0.3rem;box-sizing:border-box;border-bottom:0.1rem solid #eee; }
    .integral-item .shop-info{ margin-bottom:0.1rem; }
    .integral-item .shop-info .shop-img{ width:1.9rem;height:1.9rem;margin-right:0.2rem; }
    .integral-item .shop-info .shop-img img{ width:100%;height:100%;border-radius:0.12rem; }
    .integral-item .shop-info .info-right{ flex:1; }
    .shop-info .info-right .shop-name .txt-n{ font-size:0.3rem;font-weight:700;width:2.6rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis; }
    .shop-info .info-right .shop-name .price-n{ font-size:0.28rem;color:red; }
    .shop-info .info-right .info-factory{ font-size:0.28rem;color:#8c8c8c;width:4.6rem;overflow:hidden;white-space:nowrap;text-overflow:ellipsis; }
    .shop-info .info-right .info-number{ font-size:0.28rem;color:#8c8c8c; }
    .shop-info .info-right .info-integral{ width:1.6rem;height:0.5rem;line-height:0.5rem;border:0.02rem dashed #00cc99;text-align:center; }
    .integral-item .convert-box .convert-time{ font-size:0.28rem;color:#8c8c8c; }
    .integral-item .convert-box .convert-statu img{ width:0.36rem;height:0.36rem;border-radius:50%; }
    .integral-item .convert-box .convert-statu span{ font-size:0.28rem;color:#8c8c8c; }

    .integral-item .operation-box .opera-statu img{ width:0.36rem;height:0.36rem;border-radius:50%; }
    .integral-item .operation-box .opera-statu span{ font-size:0.28rem;color:#8c8c8c; }
    .integral-item .operation-box .opera-but .but-item{ display:inline-block;padding:0.08rem 0.16rem;background:#E6E6E6;border-radius:0.32rem;color:#8c8c8c;font-size:0.28rem;margin-left:0.2rem; }
    .integral-item .operation-box .opera-but .but-item.active{ background:#00cc99;color:#fff; }
</style>