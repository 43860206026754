<template>
    <div class="ifcation-box">
        <!-- 搜索 -->
        <div class="input-box">
            <div class="search-input flex-wrap">
                    <input type="text" v-model="keyword" @keyup.enter="seachBtn()" placeholder="商品/厂家/标签"  :adjust-position="false">
                    <img @click="seachBtn()" src="@/assets/images/index/main-sousuo.png" alt="搜索">
                </div>
        </div>
        <!-- 分类 -->
        <div class="sort-box">
            <ul class="sort-left">
                <li :class="index==clickItem?'active':''"  @click="Labelbtn(index)"  v-for="(item,index) in LabelList " :key="index">{{item.Name}}</li>
            </ul>
            
			<div class="good-list" ref="vanlist">
            <van-list
            v-model="loading"
            :finished="finished"
            finished-text="已经加载全部了"
            @load="onLoad"
            :immediate-check='false'
            >
                <div class="good-item" v-for="(item,index) in goodList" :key="index" @click="gotoDetail(item)">
                    <div class="item-info">
                        <img class="info-img"  :src="item.ImgSrc[0]" alt="" v-if="item.ImgUrl">
                        <img class="info-img" v-else-if="item.SpPicExtend" :src="item.SpPicExtend" />
                        <img class="info-img" v-else src="@/assets/images/no_good.png" alt="商品图片">
                        <div class="info-det">
                            <div class="info-name">{{item.spmch}}</div>
                            <div class="info-specs flex-wrap">
                                <span>规</span>
                                <span>{{item.shpgg}}</span>
                            </div>
                            <div class="info-specs flex-wrap">
                                <span>厂</span>
                                <span>{{item.shengccj}}</span>
                            </div>
                            <div class="info-specs flex-wrap">
                                <span>效</span>
                                <span>{{item.sxrq}}</span>
                            </div>
                            <div class="info-type">
                                <span v-for="(items,ind) in item.activeList" :key="ind" >{{items}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="info-price">
                        
                        <div class="flex-wrap">
                            <div class="price-origin">
								<span class="origin-txt">建议零售价</span>
                                <span>￥{{item.AdvicePrice}}</span>
                            </div>
                        </div>
						<span v-if="token" class="price-txt price-right">￥{{item.CurActivityData?item.CurActivityData.ActivityPrice:item.zdshj}}</span>
						<span v-else class="price-right">￥资质通过后可见</span>
						<span v-if="token && item.CurActivityData" class="price-old">￥{{item.zdshj}}</span>
						
						
                    </div>

                </div>
            </van-list>
			</div>
        </div>
    </div>
</template>

<script>
// 引入跳转活动详情的js
import jumpActivity from "../../assets/js/toActivityPage";
export default {
    data() {
        return {
			token : sessionStorage.getItem('member_token'),
            goodList: [],
            loading: false,
            finished: false,
            LabelList:[],
            clickItem:0,
            page: 1,
            keyword: "", // 搜索内容
            sortType: "", // 售价排序方式 asc价格从低到高，desc价格从高到低
            OnlyPackageSold: "", // 默认为空，0零货， 1整件
            tag: "", // 从首页进入的type为10的类型，传递的tag
            sortField: "Price", // 售价排序字段
            TypeId: "", // 选择分类的id
            total:1,
			androidFlag: false,//是否Android 浏览器
			
			keyId: "" ,//埋点 主键
			apiResult: null,
			scrollTop: 0
        };
    },
    created () {
		// this.androidFlag = /(Android)/i.test(navigator.userAgent.toLowerCase());
		let clientType = localStorage.getItem("clientType");
		if(clientType == "app"){
			this.androidFlag = true;
		}else if(clientType == "ios"){
			//ios  todo
		}
		
    },
    mounted(){
		//记录商品列表滚动的高度
		const ScrollEl = this.$refs.vanlist;
		ScrollEl.addEventListener('scroll',()=>{
			this.scrollTop = ScrollEl.scrollTop;
		});
	},
    beforeRouteEnter(to, from, next){
        if(from.path == '/goodsDetail'||from.path=='/activeDetail'){
            // console.log('没有刷新');
             to.meta.isRefresh = true;
        }else{
            // console.log('刷新');
             to.meta.isRefresh = false;
        }
        next();
    },
   activated(){
       var that=this;
	   if(this.androidFlag && top.android){
			top.android.onGoBack(true); //调用app方法
	   }
	   
	   //统计分析 埋点进来
	   this.keyId = ""; //每次进入置空
	   this.public.setBuryPoint(2,1,this.keyId).then(res=>{
			if(res.errorCode=="00"){
				that.keyId = res.rows;
			}
	   });
        // 主题图片
        that.themeImgObj = that.themeColorImg.imgObj;
        console.log("分类created"); 
        that.classCart = that.themeImgObj.classCart;
		
		//定位到滚动的位置
		const ScrollEl = this.$refs.vanlist;
		ScrollEl.scrollTop = this.scrollTop;

       if( that.$route.meta.isRefresh==false){
           that.clickItem=0;
           that.page=1;
           that.goodList=[];
           that.total=1;
           that.LabelList=[];
           that.keyword='';
           that.getLabelList();
       }
        // 软键盘弹出隐藏功能
        const ua = window.navigator.userAgent;
        if (ua.indexOf('Android') > -1 || ua.indexOf('iPhone') > -1) {
            // 默认屏幕高度
            var docmHeight = window.innnerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            window.onresize = () => {
                // 实时屏幕高度
                var nowHeight = window.innnerHeight || document.documentElement.clientHeight || document.body.clientHeight;
                if (docmHeight !== nowHeight) {
                    // 软键盘弹出 tabbar隐藏
                    that.bus.$emit('hidShow',0);
                } else {
                    // 软键盘隐藏  tabbar显示
                    that.bus.$emit('hidShow',1);
                }
            }
        }
   },
   deactivated(){
		if(this.androidFlag && top.android){
			top.android.onGoBack(false); //调用app方法
		}
		//埋点离开
		this.public.setBuryPoint(2,2,this.keyId,this.apiResult).then(res=>{
			if(res.errorCode=="00"){}
		});
   },
    methods: {
        // 下拉加载更多
        onLoad() {
            var that=this;
            // 异步更新数据
            // setTimeout 仅做示例，真实场景中一般为 ajax 请求
            setTimeout(() => {
                  // 数据全部加载完成
                 if(that.total==that.page){
                    //  console.log(111);
                     this.finished = true;  
                 }else{
                     that.page=that.page+1;
                     that.getGoodList();
                 }
            }, 500);
        },
        // 请求商品数据
        getGoodList(){
            var that=this;
            	that.loading=true;
             var data={
                    OnlyPackageSold: "",
                    SortField: that.sortField,
                    SortType: "",
                    TypeId: that.TypeId,
                    keyword: that.keyword,
                    page: that.page,
                    rows: 10,
            }
             that.public.request('post','/api/Store/QueryStoreGoods',data,res=>{
                 // that.$toast.clear();        
                     if(res.data.errorCode=='00'){
                         that.loading=false;
                         that.total=res.data.total;
						 // that.goodList=[...res.data.rows,...that.goodList];
						 if(that.page == 1){
						 	that.goodList = []
						 }
                        res.data.rows.forEach(item=>{
                            var list=[];
                            if(item.ActivityCacheDataList != null){
                                item.ActivityCacheDataList.forEach(item1=>{
                                    list.push(item1.ActivityTypeName);
                                });
                                const set = new Set(list);
                                item.activeList=set;
                            }
							that.goodList.push(item);
                        });
						
						that.apiResult = res.data;
						that.apiResult.rows = null;
                    }else{
                        that.$toast(res.data.errorMsg);
                    }
             })
        },
        getLabelList(){
            var that=this;
            var data={
                OrgId:localStorage.getItem('orgId')
            }
           // that.$toast.loading({
           //      message: '加载中',
           //      duration: 0,
           //  })
            that.public.request('post','/api/orggoodstype/QueryTopType',data,res=>{
                // that.$toast.clear();
               if(res.data.errorCode=='00'){
                    that.LabelList=res.data.rows;
                   that.TypeId=that.LabelList[0].Code;
                   that.getGoodList();
               }else{
                  that.$toast(res.data.errorMsg);
               }
            });
            
        },
        Labelbtn(ind){
            var that=this;
            that.clickItem=ind;
            that.TypeId=that.LabelList[ind].Code;
            that.goodList=[];
            that.page=1;
            that.total=1;
            this.finished = false;  
            that.getGoodList();
        },
        seachBtn(){
            var that=this;
            // console.log(111);
             that.loading=true;
             that.TypeId=that.LabelList[that.clickItem].Code;
             that.goodList=[];
             that.page=1;
             that.total=1;
             this.finished = false;
             that.getGoodList();
        },
        // 去详情
        gotoDetail(goodInfo){
            var that=this;
            // console.log(goodInfo);
            if(goodInfo.CurActivityData!=null){
                  jumpActivity.jumpPage(goodInfo);
            }else{
                    that.$router.push({
                    path: '/goodsDetail',
                    query:{
                        spid: goodInfo.spid,
                        headTit: '详情'
                    }
                })
            }
        }
    },
}
</script>

<style scoped>
    /* 搜索 */
    .input-box{
        width:100%;
        padding:0.2rem 0.3rem;
        box-sizing:border-box;
    }
    .input-box .search-input{
        flex:1;
        border:0.02rem solid #eee;
        border-radius:0.2rem;
        padding:0.16rem 0.2rem;
        box-sizing:border-box;
        background:#F9F9FB;
    }
    .input-box .search-input input{
        flex:1;
        font-size:0.28rem;
    }
    .input-box .search-input img{
        width:0.36rem;
        height:0.36rem;
        margin-left:0.2rem;
    }
    /* 分类 */
    .sort-box{
        width:100%;
        display:flex;
        
    }
    .sort-box .sort-left{
      width: 30%;
	  height: 80vh;
      background: #F4F4F6;
      overflow: hidden;
      overflow-y: scroll;
    }
    .sort-box .sort-left li{
        line-height:0.8rem;
        text-align:center;
        font-size:0.28rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 0.1rem;
    }
    .sort-box .sort-left li.active{
        background: var(--theme-color);
        color:#fff;
        font-weight:700;
    }
    .sort-box .good-list{
		position: relative;
		flex: 1;
        /* width:70%; */
		height:80vh;
        padding:0 0.15rem;
		overflow: hidden; 
		overflow-y: scroll;
    }
	.sort-box .good-list .van-list{
		width: 100%;
		height: 100%;
	}
    .sort-box .good-list .good-item{
        /* padding-bottom:0.2rem; */
        border-bottom:0.02rem dashed #ccc7c7;
        margin-bottom:0.2rem;
    }
    .sort-box .good-list .good-item .item-info{
        width:100%;
        display:flex;
        align-items:center;
    }
    .sort-box .good-list .good-item .item-info .info-img{
        width:1.8rem;
        max-height:2rem;
        object-fit:contain;
        margin-right:0.2rem;
    }
    .sort-box .good-list .good-item .item-info .info-det{
        flex:1;
        height:100%;
    }
    .sort-box .good-list .good-item .item-info .info-det .info-name{
        width:2.5rem;
        font-size:0.32rem;
        font-weight:700;
        overflow:hidden;
        white-space:nowrap;
        text-overflow:ellipsis;
    }
    .sort-box .good-list .good-item .item-info .info-det .info-specs{
        width:2.5rem;
        margin-top:0.12rem;
    }
    .sort-box .good-list .good-item .item-info .info-det .info-specs span:first-child{
        display:inline-block;
        font-size:0.24rem;
        border:0.02rem solid #999;
        color:#999;
        padding:0 0.02rem;
        box-sizing:border-box;
        margin-right:0.1rem;
    }
    .sort-box .good-list .good-item .item-info .info-det .info-specs span:last-child{
        display:inline-block;
        flex:1;
        overflow:hidden;
        white-space:nowrap;
        text-overflow:ellipsis;
        font-size:0.26rem;
    }
    .sort-box .good-list .good-item .item-info .info-det .info-type{
        margin-top:0.12rem;
		font-size: 14px !important;
    }
    .sort-box .good-list .good-item .item-info .info-det .info-type span{
        display:inline-block;
        padding:0 0.12rem;
        box-sizing:border-box;
        color: var(--theme-color);
        border:0.02rem solid var(--theme-color);
        border-radius:0.1rem;
        margin-right:0.12rem;
    }
    .sort-box .good-list .good-item .info-price{
        display:flex;
        align-items:center;
        justify-content:flex-end;
        margin-top:0.15rem;
    }
    .sort-box .good-list .good-item .info-price .price-txt{
        font-size:0.46rem;
        font-weight:700;
        color: #f81111;

    }
    .sort-box .good-list .good-item .info-price .price-right{
        margin-left:0.6rem;
    }
    .sort-box .good-list .good-item .info-price .price-right .price-origin span{
        font-size:0.24rem;
        display:flex;
        flex-direction:column;
        align-items:flex-end;
    }
    .sort-box .good-list .good-item .info-price .price-right .price-origin span:first-child{
        font-size:0.26rem;
    }
    .sort-box .good-list .good-item .info-price .price-right img{
        width:0.36rem;
        margin-left:0.36rem;
    }
	
	.price-old{position: relative;font-size: 16px; color: #999; margin-left: 5px;}
	.price-old::after{
	  position: absolute;
	  content: '';
	  left: 0;
	  top: 50%;
	  width: 100%;
	  height: 1px;
	  background: #666;
	  transform: rotate(10deg);
	}
</style>