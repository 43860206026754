<template>
    <div class="content">
        <div class="logo-box">
            <img v-if="logoInfo && logoInfo.Logo" :src="logoInfo.Logo" />
            <span v-if="logoInfo && logoInfo.FullName">{{ logoInfo.FullName }}</span>
        </div>
        <!-- <div class="sign-info">
            <div class="account-sign flex-wrap">
                <span>手机号：</span>
                <input type="text" placeholder="请输入手机号" v-model="Mobile" />
            </div>
            <div class="account-sign flex-wrap">
                <span v-html="'密&emsp;码：'"></span>
                <input type="password" placeholder="请输入密码" v-model="Password" />
            </div>
            <div>
                <div class="sign-btn" @click="bindOpenId">绑定</div>
            </div>
        </div> -->
    </div>
</template>
<script>
import wechatHelp from "@/assets/js/api/wechat.js"
export default {
    data() {
        return {
            openId: '',
            orgId: '',
            logoInfo: {},
            Mobile: '',
            Password: '',
        }
    },
    created() {
        var that = this;
        if (!!location.search) {
            var params = that.public.urlObj(location.href);
            if (!!params) {
                if (!!params.OrgId) {
                    that.orgId = params.OrgId;
                }
                if (!!params.openId)
                    that.openId = params.openId;
                if(!!params.mobile){
                    localStorage.setItem("Mobile_bind",params.mobile);
                }
            }
        }
        if (!that.orgId) {
            that.orgId = that.public.getConfig.defaultOrgId;
        }
        localStorage.setItem("orgId", that.orgId);
        that.getCompanyLogo();
        //获取机构openid
        wechatHelp.run();

    },
    methods: {
        //--获取业务员openid 开始
 
        //--获取业务员openid 结束
        getCompanyLogo() { 
            var that = this;
            var data = { orgId: that.orgId, }
            that.public.request('get', '/api/SysOrgInfo/QueryOrgWebConfig', data, res => {
                if (res.data.errorCode == '00') {
                    that.logoInfo = res.data.rows;
                    document.title = [res.data.rows.FullName, '-平台'].join('');
                }
            })
        },
        bindOpenId() {
            var that = this;
            //    var data={Password:that.Password,Mobile:that.Mobile,OpenId:that.openId,OrgId:that.orgId};   
            var data = { Password: that.Password, Mobile: that.Mobile, openId: that.openId };
            if (!data.Mobile)
                return that.$toast("手机号不能为空!");
            if (!data.Password)
                return that.$toast("密码不能为空!");
            //    if(!data.OpenId) 
            //         return that.$toast("未获取到微信Id!"); 

            that.$toast.loading({
                message: "处理中...",
                duration: 0,
            });
            that.public.request('post', '/api/appLogin/employeeLogin', data, res => {
                that.$toast.clear();
                if (res.data.errorCode == '00') {
                    //业务员绑定成功
                    that.$router.push({
                        path: '/EmployeeIndex',
                    })
                } else {
                    that.$toast(res.data.errorMsg);
                }
            })
        }
    }
}
</script>
<style scoped>
.logo-box {
    width: 100%;
    padding: 0.9rem 0;
    box-sizing: border-box;
}

.logo-box img {
    display: block;
    width: 3rem;
    margin: 0 auto 0.2rem;
}

.logo-box span {
    display: block;
    font-size: 0.3rem;
    color: #333;
    text-align: center;
}

.sign-info {
    width: 80%;
    margin: 0 auto;
}

.sign-info .account-sign {
    margin-bottom: 0.36rem;
}

.sign-info .account-sign span {
    display: inline-block;
    width: 1.4rem;
    font-size: 0.3rem;
    text-align: left;
}

.sign-info .account-sign input {
    flex: 1;
    height: .8rem
        /* 40/50 */
    ;
    border: 0.02rem solid rgb(218, 215, 215);
    border-radius: 0.16rem;
    padding: 0.08rem 0.2rem;
    font-size: 0.28rem;
}

.sign-btn {
    width: 100%;
    height: 0.85rem;
    text-align: center;
    line-height: 0.85rem;
    color: #fff;
    font-size: 0.3rem;
    background: #169bd5;
    border-radius: 0.2rem;
    margin-top: 0.6rem;
}
</style>