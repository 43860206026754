<template>
  <div>
      <shopList :list="likeList" @toDetail='toDetail' @onCartShow='onCartShow' ></shopList>

        <!-- <active-cart :cartNums='cartNum' :cartShowPop="activeCartShowPop"  :goodSpid="goodSpid" :goodDetail="goodDetail" :typePage="typePage" @diffCartShow="closeCartActive"></active-cart> -->

        <!-- 普通商品购物车弹窗 -->
        <cart-pop :cartShowPop="cartShowPop"  :goodInfo="goodInfo"  @diffCartShow="closeCart" ></cart-pop>
        <!-- 多规格弹窗 -->
        <!-- <specification  :isSpecification='isSpecification'  @isGuiGeShow="closeGuiGe" :guiGeDetail="guiGeDetail" :guiGeDes='guiGeDes' :bottomHight='bottomHight'/> -->
  </div>
</template>

<script>
import shopList from "../../components/shopList.vue"
import cartPop from "../../components/cartPop.vue"
export default {
   components: {
       shopList,
	   cartPop
   },
   data () {
       return {
           likeList:[],
           page:1,
		   cartNum:0,
		   goodInfo:{},
		   cartShowPop:false
       }
   },
   created () {
       this.guessLike();
   },
   methods: {
           guessLike(){
            var that = this;
            var orgId = localStorage.getItem("orgId");
            var page = that.page;
           
            var data = {
                OrgId: orgId,
                page: page,
                rows: 6,
            }
            if(page>1){
                that.showLoading = true;
            }
            that.public.request('post','/api/Store/QueryStoreGoods',data,res => {
                // console.log("猜你喜欢",res);
                // console.log('that.page;',that.page);
                that.showLoading = false;
                if(page>1){
                    that.showLoading = false;
                }
                if(res.data.errorCode == "00"){
                    
                    var allArr = [];
                    var initArr = that.likeList;
                    var curArr = res.data.rows ? res.data.rows : [];
                    var pageLength = curArr.length;
                    if(page > 1){
                    
                        allArr = initArr.concat(curArr);
                    }else{
                      
                        allArr = res.data.rows;
                    }
                    
                    if(page == 1){
                        that.isLike = false;
                        that.$nextTick(()=>{
                            that.isLike = true;
                        })
                    }
                    
                    that.likeList = allArr;
                    // console.log("----------",that.likeList);
                    if(pageLength < 6){
                        that.noMoretip = true;
                        
                    }
                   
                }else{
                    if(page <=1){
                        that.likeList = [];
                        that.noMoretip = false;
                    }else{
                        that.noMoretip = true;
                    }
                }
				
            })
        },
         toDetail(goodInfo){
            var that=this;
            //  类型Type20列表 10标签的列表      
            
            // 跳转普通商品详情
            if(goodInfo.CurActivityData == null){
                that.$router.push({
                    path: '/goodsDetail',
                    query:{
                        spid: goodInfo.spid,
                        headTit: '详情'
                    }
                    
                })
            }else{  // 跳转活动商品详情
                jumpActivity.jumpPage(goodInfo);
            }
                  
        },
		     // 关闭组件弹窗
		closeCart() {
            var that = this;
            that.cartShowPop = false;
        },
		onCartShow(id,goodInfo){
		var that = this;
		console.log(goodInfo);
		 if((JSON.stringify(goodInfo.StyleGroup)!='{}'&&goodInfo.StyleGroup!=null)){
            that.isSpecification=true;
            var guiges=goodInfo.StyleGroup;
            Object.keys(guiges).forEach(key=>{
                 if( guiges[key].ActivityCacheDataList.length>0){
                    let item1='ActivityId';
                    let value1=guiges[key].ActivityCacheDataList[0].ActivityId;
                    guiges[key][item1]=value1;
                    let item2='Description';
                    let value2=guiges[key].ActivityCacheDataList[0].Description;
                    guiges[key][item2]=value2;
                    let item3='ActivityType';
                    let value3=guiges[key].ActivityCacheDataList[0].ActivityType;
                    guiges[key][item3]=value3;
                 }
                let item='num';
                let value=0;
            guiges[key][item]=value;
            });
            that.guiGeDes=guiges;
            console.log(that.guiGeDes);
            that.guiGeDetail = goodInfo;
           }else{
               console.log(22);
               that.cartShowPop = true;
            that.goodInfo = goodInfo;	
           }
        
		}
   }
}
</script>

<style>

</style>