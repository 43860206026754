<template>
	<div class="sign-in">
		<div class="list">
			<div class="gift" v-for="item in giftList">
				<img :src="item.GiftPic" alt="">
				<div class="info">
						<span>{{item.GiftName}}</span>
					<span class="num">数量{{item.Number}}盒</span>
					<span class="time">{{item.CreateDate}}</span>
				</div>
			</div>
		</div>
		<div class="empty-box" v-if="giftList.length==0">
			<img src="@/assets/images/nore.png" alt="">
			<span>暂未获得礼品</span>
		</div>
	</div>
</template>


<script>
	import {Toast, Dialog} from "vant"	
	export default {
		name: 'app',
		components: {
			Toast, [Dialog.Component.name]: Dialog.Component
			// HelloWorld
		},
		data(){
		  return{
			  giftList: [],
			  page: 1,
		  }
		},
		
		created() {
			// this.activeId = this.getUrlKey("Id");
			this.querySignInfo();
		},
		methods: {
			querySignInfo(){
				let that = this;
				this.public.request("post","api/signin/QueryPointGift",{
					page: this.page,
					rows: 50,
				},res=>{
					if(res.data.errorCode == "00"){
						that.giftList = res.data.rows
					}else{
						that.$toast(res.data.errorMsg)
					}
				})
				
			},
			
			//获取url 参数
			getUrlKey(name){
			    return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
			}
		  
		},
		
	}
</script>

<style scoped>
	.list{
		position: relative;
		background: #f7f7f7;
	}
	.gift{
		position: relative;
		display: flex;
		padding: 10px;
		background: #fff;
		margin: 5px 0;
		border-radius: 6px;
	}
	.gift img{
		width: 30%;
		height: 70px;
		margin-right: 10px;
	}
	.gift .info{
		display: flex;
		flex-direction: column;
		font-size: 16px;
	}
	.gift .info .time,.num{
		font-size: 12px;
		color: #666;
		margin: 2px 0;
	}
	
	.empty-box{
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 15px;
		color: #666;
		padding: 40% 0;
	}
	
	.empty-box img{
		width: 2.5rem;
		height: 2.5rem;
		margin-bottom: 20px;
	}
</style>
