import pub from '../../../../public/public.js'
// 近效期列表
function queryList(model,callBack) {
    return pub.request('get','/api/nearExpiry/queryList',model,callBack)
}
// 近效期详情
function queryDetail(model,callBack) {
    return pub.request('get','/api/nearExpiry/queryDetail',model,callBack)
}

export default {
	queryList,
    queryDetail,
}