<template>
    <div class="contain-box">
        <!-- 顶部导航 -->
        <div class="tab-box">
            <div class="tab-top flex-wrap" >
                <img @click="backPage" class="tab-img" src="@/assets/images/tab/icon_left.png" alt="">
                <div class="tab-tit">支付</div>
                
          </div>
        </div>
        <div v-for="(item,index) in orderInfo.rows" :key="index">
            <div class="order-box">
                <div class="order-tit">支付当前关联订单</div>
                <div class="order-price flex-sp">
                    <div class="order-code">订单号：{{item.OrderNo}}</div>
                    <div class="order-total">金额：￥{{item.OrderType==122?item.GoodsPackTotal:item.OrderCost}}</div>
                </div>
            </div>
            <div class="total-price">
                <span>总计：</span>
                <span class="font-red">￥{{item.OrderType==122?item.GoodsPackTotal:item.OrderCost}}</span>
            </div>
        </div>
        
        <div class="pay-box" v-for="item in paymentChannel">
			<div v-if="item.PaymentCode == 'TLZF'">
				<div class="pay-type flex-sp" @click="selectPay('wxNative')">
				    <img class="pay-img" src="@/assets/images/paytype_wechat.png" alt="">
				    <img class="select-img" v-if="payType=='wxNative'" src="@/assets/images/cart/icon_checked.png" alt="">
				    <img class="select-img" v-if="payType!='wxNative'" src="@/assets/images/cart/icon_nochecked.png" alt="">
				</div>
				<div class="pay-type flex-sp" @click="selectPay('aliPay')" v-if="clientType == 'app' || clientType == 'ios'">
				    <img class="pay-img" src="@/assets/images/paytype_alipay.png" alt="">
				    <img class="select-img" v-if="payType=='aliPay'" src="@/assets/images/cart/icon_checked.png" alt="">
				    <img class="select-img" v-if="payType!='aliPay'" src="@/assets/images/cart/icon_nochecked.png" alt="">
				</div>
			</div>
			<div v-if="item.PaymentCode == 'ZXBANK'" class="pay-type flex-sp" @click="selectPay('bankpay')">
			    <img class="pay-img" src="@/assets/images/paytype_bankpay.png" alt="">
			    <img class="select-img" v-if="payType=='bankpay'" src="@/assets/images/cart/icon_checked.png" alt="">
			    <img class="select-img" v-if="payType!='bankpay'" src="@/assets/images/cart/icon_nochecked.png" alt="">
			</div>
        </div>
		
        <div class="pay-but">
            <div class="but-b" @click="getPay">点击付款</div>
            <div class="but-b" @click="toPurchaseOrder">返回</div>
        </div>

        <!-- 大转盘弹窗 -->
        <!-- <div class="turntable-popup" v-if="turntableAct" @click="closeTurntable"></div>
        <div class="turntable-pop" v-if="turntableAct" @click="toTurntable(orderInfo.rows[0].OrderNo,orderInfo.rows[0].AdvancePay)"> -->
            <!-- <img class="close-img" src="@/assets/images/icon_close.png" @click.stop="closeTurntable"> -->
            <!-- 禹药翁 -->
            <!-- <img class="turntable-img" v-if="orgId==='42d0b61b7a7741fa9bb7b91d3422ee0c'" src="@/assets/images/newyear_redpacket/coupon_active.png" alt="">
            <img class="turntable-img" v-if="orgId!=='42d0b61b7a7741fa9bb7b91d3422ee0c'" src="@/assets/images/newyear_redpacket/redPacket.png" alt="">
        </div> -->
    </div>
</template>
<script>
import { debounce } from "lodash";
export default {
    data(){
        return{
			MasterBatchNo: "",
            BatchNo: '',  
            orderInfo: {},      // 订单信息    
            turntableAct: false,  // 判断转盘
            clientType: '',     // 项目类型  app/小程序(applets)/公众号(weixin)
            payType: 'wxNative',     // aliPay支付宝  wxNative微信  bankpay中信银行
            urlRouter: '',      // waitBuyPay  从创建订单页面进来的
            orgId: '',
            payData: null,      // 支付数据
            fromPathUrl: '',        // 上个页面的路径
			paymentChannel: [],  //支付类型
        }
    },
    created(){
        var that = this;
        that.orgId = localStorage.getItem('orgId');
		// 获取类型
		that.clientType = localStorage.getItem('clientType');
        // 接收提交订单页面传递过来的batchNo
		that.MasterBatchNo = that.$route.query.MasterBatchNo;
        that.BatchNo = that.$route.query.batchNo;
        that.urlRouter = that.$route.query.pageType;
        that.queryBatchOrders();
    },
    beforeRouteEnter(to, from, next) {
        next(vm=>{    
            vm.fromPathUrl = from.path;
        })
    },
    mounted(){

        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js';
        document.body.appendChild(s);
        
        // 在支付宝中获取token_id
        var tokenId = this.$route.query.token_id;
        window.onload =()=>{   //初始化产生一个全局变量 AlipayJSBridge
            
            if(tokenId){
                this.tradePayReady(tokenId);
            }
              
        }
        
        
        if (window.history && window.history.pushState) {
            history.pushState(null, null, document.URL);
            window.addEventListener('popstate', this.goBack, false);
        }
    },
    destroyed(){
        window.removeEventListener('popstate', this.goBack, false);
    },
    
    
    
    methods:{
        // 物理返回
        goBack(){
            var that = this;
            if(that.urlRouter == 'waitBuyPay'){
                //replace替换原路由，作用是避免回退死循环
                that.$router.replace({path: '/cart'});
            }else{
                that.$router.go(-1);
            }
            
            
        },
        // 顶部导航返回
        backPage(){
            var that = this;
            if(that.urlRouter == 'waitBuyPay'){
                that.$router.go(-2);
            }else{
                that.$router.go(-1);
            }
            
        },
        // 支付方式
        selectPay(type){
            var that = this;
            that.payType = type;
        },
        // 返回
        toPurchaseOrder(){
            var that = this;
            that.$router.push({
                path: '/purchaseOrder',
                query:{
                    status: 0,
                }
            })
        },
        // 跳转大转盘页面
        toTurntable(OrderNo,AdvancePay){
            var that = this;
            that.$router.push({
                path: '/turntable',
                query:{
                    OrderNo: OrderNo,
                    AdvancePay: AdvancePay,
                }
            })
        },
        // 新版林创支付宝支付
        tradePay(tradeNO){
            var that = this;
            AlipayJSBridge.call("tradePay", {
                tradeNO: tradeNO,
            }, function (res) {
                if ("9000" == res.resultCode) {  // 支付成功
                    // console.log("支付成功");
                    that.$toast("支付成功");
                    setTimeout(function(){
                        window.AlipayJSBridge.call('closeWebview');
                    },2000)
                }else if(res.resultCode == "6001"){   // 用户中途取消
                    window.AlipayJSBridge.call('closeWebview');
                }else if(res.resultCode == '6002'){   // 网络链接出错
                    that.$toast("网络连接出错，请重新支付");
                    setTimeout(function(){
                        window.AlipayJSBridge.call('closeWebview');
                    },2000)
                }else if(res.resultCode == "4000"){
                    that.$toast("订单支付失败，请重新支付");
                    setTimeout(function(){
                        window.AlipayJSBridge.call('closeWebview');
                    },2000)
                }
            });
            
            
        },
        
        // 由于js的载入是异步的，所以可以通过该方法，当AlipayJSBridgeReady事件发生后，再执行callback方法
        tradePayReady(tradeNO){
            var that = this;
            if (window.AlipayJSBridge) {
                that.tradePay(tradeNO);
            } else {
                document.addEventListener('AlipayJSBridgeReady', that.tradePay(tradeNO), false);
            }
        },
        
        
        // 点击付款
        getPay(){
            var that = this;
            var clientType = that.clientType;   // 项目类型 app/小程序(applets)/公众号(weixin)
            var orgId = localStorage.getItem('orgId');
            var payType = that.payType;     // 支付方式
            var orderInfo = that.orderInfo;     // 订单信息
            var token = sessionStorage.getItem('member_token');
			
			//统计分析 埋点进来
			that.keyId = ""; //主键
			that.public.setBuryPoint(7,1,that.keyId).then(res=>{
				// if(res.errorCode=="00"){
				// 	that.keyId = res.rows;
				// }
			});
			
            // console.log(token);
            // 阿来
            if(orgId == 'a56cde800c89408ba6026bbcfc9585a2' && clientType != 'applets'){
                var data = {
                    BatchNo: that.BatchNo,
                }
                that.public.request('post','api/orderPay/getQRCodePayUrlBase',data, res => {
                    // console.log(res);
                    if(res.data.errorCode == '00'){
                        var url = res.data.rows;
                        top.window.location = url;
                        setTimeout(function () {
                            that.payConfirm();
                        }, 3000);
                    }
                    
                })
                return;
            }
			// 中信银行支付
            if(payType == "bankpay"){
				that.public.request('post','api/OrderPay/ZxShouFuTongPay',{
					BatchOrderNo: that.BatchNo,
					MasterBatchNo: that.MasterBatchNo,
					OrgId: localStorage.getItem('orgId')
				}, res => {
				    if(res.data.errorCode == '00'){
						that.$toast("支付成功");
						setTimeout(function () {
							if(that.fromPathUrl == '/purchaseOrder'){
							    that.$router.go(-1);
							}else{
							    that.$router.replace({
							        path: '/orderSuccess',
							        query: {
							            BatchNo: that.BatchNo,
							        }
							    })
							}
						}, 1000);
				    }else{
						that.$toast(res.data.errorMsg);
					}
				})
				// debounce(function(){ //防抖函数，防止快速连续点击支付多次
				// },1000);
			}else if(clientType == "app" || clientType == "ios"){ // app支付
                that.$toast({
                    message: '开始拉起APP支付中',
                    position: 'bottom',
                })
                // 支付宝支付
                if(payType == "aliPay"){
                    var loginPageUrl = location.origin + "/login?orgId=" + orgId; 
                    /**
                     *  药盟主支付（通联支付）
                     */
                    if(orgId == '2679591e77624a6489912571f449832c'){
                        var data = {
                            BatchNo: that.BatchNo,
                            OrgId:orgId,
                            paytype:"A01",
                        }
                        that.public.request('post','api/OrderPay/GetAppUnionPay',data,res=>{
                            if(res.data.errorCode=='00'){
                                that.isCanClick=true;
                                console.log(res);
                                let url=res.data.rows.url;
                                url = encodeURIComponent(url);
                                that.$toast({
                                            message: '开始跳转支付宝',
                                            position: 'bottom',
                                            });
                                            console.log(loginPageUrl);
                                top.zfbPay.zfbPayOrder(url, loginPageUrl);
                                setTimeout(function () {
                                            that.payConfirm();
                                    }, 3000);
                            return;
                            }else{
                                that.$dislog.confirm({
                                        title:'网络错误,请求异常',
                                        message: '是否重新发起支付?',
                                    }).then(() => {
                                        that.getPay();
                                    }).catch(() => {
                                    });
                            }
                        });
                    }else{      // 不是药盟主的支付（易生支付）
                        var data = {
                            BatchNo: that.BatchNo,
                            PaySource: payType,
                            GoUrl: loginPageUrl,
                        }
                        that.public.request('post','api/orderPay/getWeixinAppPayData',data, res => {
                            // console.log(res);
                            if(res.data.errorCode == "00"){
                                that.payData = res.data.rows;
                                /* 新版林创 */
                                if(orgId == "efd712fe5a714c1e9db1d0d1e9875917"){
                                    top.zfbPay.zfbPayOrder(res.data.extend,loginPageUrl);
                                    setTimeout(function () {
                                        that.payConfirm();
                                    }, 3000);
                                    return false;
                                }
                                
                                /* 不是林创 */
                                if(that.payData === "90"){
                                    // console.log('支付宝未授权,将要去授权!');
                                    top.zfbPay.zfbPayOrder(res.data.extend,loginPageUrl);
                                    that.$dialog.confirm({
                                        title: '支付宝授权提示',
                                        message: '是否授权成功?',
                                    }).then(() => {
                                        that.pbulic.request('post','api/orderPay/GetAuthRefer',{}, res => {
                                            if(res.data.errorCode == '00'){
                                                that.$dislog.confirm({
                                                    title:'获取支付宝信息成功',
                                                    message: '是否重新发起支付?',
                                                }).then(() => {
                                                    that.getPay();
                                                }).catch(() => {

                                                })
                                            }
                                        })
                                    }).catch(() => {
                                        return;
                                    })
                                    return;
                                }

                                /* 不是林创 */
                                if(payType == "aliPay" && that.payData.hasOwnProperty("token_id")){
                                    if(orgId == "efd712fe5a714c1e9db1d0d1e9875917"){
                                        var curUrl = location.href + '&token_id=' + that.payData.token_id;
                                        var currentUrl = encodeURIComponent(curUrl);
                                        // console.log(currentUrl);
                                        var aliPayUrl = "alipays://platformapi/startapp?appId=20000067&url=" + currentUrl;
                                        top.zfbPay.zfbPayOrder(aliPayUrl,location.href);
                                    }else{
                                        var url = that.payData.token_id;
                                        that.$toast({
                                            message: '开始跳转支付宝',
                                            position: 'bottom',
                                        })
                                        top.zfbPay.zfbPayOrder(url, location.href);
                                    }
                                    
                                    
                                    setTimeout(function () {
                                        that.payConfirm();
                                    }, 3000);
                                    return;
                                }
                            }
                        })
                    }
                    
                }else if(payType == "wxNative"){
                    // 微信支付
                    var webconfig =JSON.parse(localStorage.getItem('webconfig'));
                    var appid="", primitiveid = "";
                    if (webconfig && webconfig.PrimitiveId) {
                        primitiveid = webconfig.PrimitiveId;
                    }

                    if (webconfig && webconfig.WeixinAppId) {
                        appid = webconfig.WeixinAppId;
                    }
                    var url = '/pages/pay/index?orderNo=' + that.BatchNo + '&amount=' + orderInfo.extend + '&token=' + token + '&MasterBatchNo=' +that.MasterBatchNo;
                    // console.log(url);
                    top.wxPay.WXPayOrder(url, appid, primitiveid); //调起app
                    setTimeout(function () {
                        that.payConfirm();
                    }, 3000);
                }

            }else if(clientType == 'applets'){  // 小程序
                that.$toast({
                    message: '开始拉起小程序支付中',
                    position: 'bottom',
                })
                try{
                    that.$toast("小程序支付跳转中..");
                    that.wx.miniProgram.navigateTo({
                        url: '/pages/pay/index?orderNo=' + that.BatchNo + '&amount=' + orderInfo.extend + '&token=' + token + '&MasterBatchNo=' +that.MasterBatchNo,
                    })
                    setTimeout(function () {
                        that.payConfirm();
                    }, 3000);
                } catch(err){
                    that.$toast("支付跳转异常");
                }
            }else {     // 从微信
                
                that.$toast({
                    message: '开始拉起微信支付中',
                    position: 'bottom',
                })
                var openid = localStorage.getItem('openid');
                var data = {
                    BatchNo: that.BatchNo,

                }
                if(openid){
                    data.OpenId = openid;
                }
                that.public.request('post','api/orderPay/getMicorPayData',data, res => {
                    if(res.data.errorCode == "00"){
                        if(that.payData == null){
                            that.$toast('请求异常,请稍后重试');
                            return;
                        }
                        that.payData = res.data.rows;
                        that.doWeixinPay();
                        setTimeout(function () {
                            that.payConfirm();
                        }, 3000);
                    }
                })

            }

        },
        doWeixinPay(){
            var that = this;
            if (typeof WeixinJSBridge == "undefined") {
                if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady(), false);
                } else if (document.attachEvent) {
                    document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady());
                    document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady());
                }
            } else {
                that.onBridgeReady();
            }
        },
        onBridgeReady(){
            var that = this;
            var payData = that.payData;
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', {
                "appId": payData.appId, //公众号名称，由商户传入     
                "timeStamp": payData.timeStamp, //时间戳，自1970年以来的秒数     
                "nonceStr": payData.nonceStr, //随机串     
                "package": payData.package,
                "signType": payData.signType, //微信签名方式：     
                "paySign": payData.paySign //微信签名 
            },
            function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    that.$toast("付款成功");
                    setTimeout(function(){
                        // that.$router.replace({
                        //     path: '/purchaseOrder'
                        // });
                        that.$router.replace({
                            path: '/orderSuccess',
                            query: {
                                BatchNo: that.BatchNo,
                            }
                        })
                    },2000)
                }
            });
        },
        // 支付订单对话框
        payConfirm(){
            var that = this;
            that.$dialog.confirm({
                title: '支付提示',
                message: "是否支付成功?",
            })
            .then(() => {
                
                if(that.turntableAct === false){
                    // 返回全部订单页面
                    // 如果从全部订单页面跳转过来的，则返回全部订单
                    if(that.fromPathUrl == '/purchaseOrder'){
                        that.$router.go(-1);
                    }else{
                        // that.$router.replace({
                        //     path: '/purchaseOrder',
                        // });
                        that.$router.replace({
                            path: '/orderSuccess',
                            query: {
                                BatchNo: that.BatchNo,
                            }
                        })
                    }
                }
                
            })
            .catch(() => {
                // console.log('取消');
                if(that.turntableAct === false){
                    // 返回全部订单页面
                    // 如果从全部订单页面跳转过来的，则返回全部订单
                    if(that.fromPathUrl == '/purchaseOrder'){
                        that.$router.go(-1);
                    }else{
                        // that.$router.replace({
                        //     path: '/purchaseOrder',
                        // });
                        that.$router.replace({
                            path: '/orderSuccess',
                            query: {
                                BatchNo: that.BatchNo,
                            }
                        })
                    }
                }
            });
        },
        // 获取订单
        queryBatchOrders(){
            var that = this;
            that.public.request('post','api/ordersInfo/queryBatchOrders',{
				BatchOrderNo: that.BatchNo,
				MasterBatchNo: that.MasterBatchNo
			},res => {
                // console.log(res);
                if(res.data.errorCode=='00'){
                    that.orderInfo = res.data;
					that.paymentChannel = res.data.TagData.PaymentChannel;
					if(that.paymentChannel && that.paymentChannel.length>0){
						// let hasTLZF = that.paymentChannel.some(item => item.PaymentCode == "TLZF");
						// let hasZXBANK = that.paymentChannel.some(item => item.PaymentCode == "ZXBANK");
						if(that.paymentChannel[0].PaymentCode=="TLZF"){
							that.payType = "wxNative";
						}else if(that.paymentChannel[0].PaymentCode=="ZXBANK"){
							that.payType = "bankpay";
						}else{
							that.payType = "wxNative";
						}
					}
					
                    if (res.data && res.data.rows && res.data.rows.length > 0) {
                        that.isTurntable(res.data.rows[0].OrderNo, res.data.rows[0].AdvancePay);
                    }
                    if (res.records === 1) {
                        that.$toast('存在支付记录，请重新下单');
                    }
                }
                
            })
        },
        // 判断是否有下单后发放的转盘活动
        isTurntable(OrderNo,AdvancePay){
            var that = this;
            var orgId = localStorage.getItem('orgId');
            var data = {
                AfterOrder: true,
                OrderNo: OrderNo,
                orgId: orgId,
            }
            that.public.request('post','api/Turntable/GetTurntableListByCustomerId',data, res => {
                // console.log(res);
                if(res.data.errorCode == '00'){
                    if(res.data.extend){
                        var totalMoney=res.data.extend.OrderMoney;
                        var criteria =res.data.extend.Info.WhereMoney;
                        if(parseInt(totalMoney/criteria)>=1){
                            that.turntableAct=true;
                        }else{
                            that.turntableAct=false;
                            return;
                        }
                    }
                    if(res.data.rows!=null&&res.data.rows!==false){
                        that.turntableAct=true;
                        
                    }else{
                        that.turntableAct=false;
                        return;
                    }
                }
            })
        },
        // 关闭大转盘
        closeTurntable(){
            var that = this;
            that.turntableAct = false;
        },
    }
}
</script>




<style scoped>
    .order-box{ width:100%;border-bottom:0.02rem solid #e0dfdf; }
    .order-box .order-tit{ width:100%;padding:0 0.3rem;box-sizing:border-box;font-size:0.3rem;margin:0.1rem 0; }
    .order-box .order-price{ width:100%;padding:0 0.3rem;box-sizing:border-box;font-size:0.3rem;margin-bottom:0.1rem; }
    .total-price{ width:100%;padding:0 0.2rem;box-sizing:border-box;font-size:0.3rem;display:flex;justify-content:flex-end;margin:0.1rem 0 0.2rem; }


    /* 支付 */
    .pay-box{ width:100%;padding:0 0.6rem;box-sizing:border-box; }
    .pay-box .pay-type .pay-img{ width:2.2rem; }
    .pay-box .pay-type .select-img{ width:0.36rem; }


    /* 付款按钮 */
    .pay-but{ width:100%;padding:0 0.3rem;box-sizing:border-box;display:flex;justify-content:space-around;margin-top:0.8rem; }
    .pay-but .but-b{ padding:0.08rem 0.2rem;background:#f0ad4e;border-radius:0.12rem;font-size:0.32rem;color:#fff; }


    /* 大转盘弹窗 */
    .turntable-popup{ position:fixed;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,0.4);z-index:9; }
    .turntable-pop{ position:fixed;top:20%;left:10%;width:80%;z-index:10;border-radius:7%; }
    .turntable-pop .close-img{ width:0.5rem;position:absolute;top:0.03rem;right:0.03rem; }
    .turntable-pop .turntable-img{ width:100%; }


    .tab-box{
        width:100%;
        height: 0.9rem;
      }

      .tab-top {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 0.9rem;
          padding: 0 0.2rem;
          box-sizing: border-box;
          background: #fff;
          z-index: 99;
          border-bottom: 0.02rem solid #eee;
      }

          .tab-top .tab-img {
              width: 0.4rem;
              height: 0.4rem;
          }

          .tab-top .tab-tit {
              flex: 1;
              text-align: center;
              font-size: 0.32rem;
              /* padding-right:0.5rem; */
          }
</style>