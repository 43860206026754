<template>
  <div>
    <!-- 商品弹窗  加入购物车 -->
    <div class="good-popup" v-if="cartShowPop" @click="closePop()" @touchmove.prevent></div>
    <div class="good-pop" v-if="cartShowPop" :style="{ bottom: botDis + 'rem' }">
      <div class="good-top flex-wrap">
        <img v-if="goodsInfo.SpPicExtend" :src="goodsInfo.SpPicExtend"    onerror="javascript:this.src='https://goodssuffix.oss-cn-qingdao.aliyuncs.com/upload/c5c2ae08dbfb45898d64061a0d82e83e/9cc5bc0235044c54916fd18243082d10.png'" />
        <img v-else-if="!goodsInfo.SpPicExtend" src="@/assets/images/no_good.png"
			onerror="javascript:this.src='https://goodssuffix.oss-cn-qingdao.aliyuncs.com/upload/c5c2ae08dbfb45898d64061a0d82e83e/9cc5bc0235044c54916fd18243082d10.png'" 
        />
        <div class="good-right flex-column">
          <div class="right-name">{{ goodsInfo.spmch }}</div>
          <div class="right-price flex-sp">
            <span class="price-tit font-red" v-if="goodDetail.SoldPrice">￥{{ goodDetail.SoldPrice }}</span>
            <span class="price-tit font-red" v-if="!goodDetail.SoldPrice">￥{{ goodDetail.ActivityCacheDataList[0].MinAmount }}</span>
            <span v-if="typePage != 'nearExpiry'">
              仅剩：<span class="font-red">{{ goodsInfo.kcshl }}{{ goodsInfo.dw }}</span>
            </span>
            <span v-if="typePage == 'nearExpiry'">
              仅剩：<span class="font-red">{{ goodDetail.Stock }}{{ goodsInfo.dw }}</span>
            </span>
            <!-- <span v-if="typePage == 'seckill'"
              >库存：<span class="font-red"
                >{{ goodsInfo.kcshl}}{{
                  goodsInfo.dw
                }}</span
              ></span
            > -->
          </div>
          <div class="good-code">商品编号：{{ goodsInfo.spbh }}</div>
        </div>
      </div>
      <div class="good-specifi">
        <div class="specifi-item ">
          <div class="item-title ">
            <span class="tit" style="display: inline-block;width:13%">规格：</span>
            <span class="tit-parameter">{{ goodsInfo.shpgg }}/{{ goodsInfo.dw }}</span >
            <span class="last-span">大包装：{{ goodsInfo.jlgg }}{{ goodsInfo.dw }}</span >
            <span class="last-span">中包装：{{ goodsInfo.zbz }}{{ goodsInfo.dw }}</span >
          </div>
        </div>
        <div class="specifi-item">
          <div class="item-title flex-wrap">
            <span class="tit">单位：</span>
            <span class="tit-parameter">{{ goodsInfo.dw }}</span>
          </div>
        </div>
        <div class="specifi-item">
          <div class="item-title flex-wrap">
            <span class="tit">生产厂商：</span>
            <span class="tit-parameter">{{ goodsInfo.shengccj }}</span>
          </div>
        </div>
        <div class="specifi-item">
          <div class="item-title flex-wrap">
            <span class="tit">是否可拆零：</span>
            <span class="tit-parameter" v-if="goodsInfo.OnlyPackageSold">不可拆零</span>
            <span class="tit-parameter" v-if="!goodsInfo.OnlyPackageSold">可拆零</span>
          </div>
        </div>

         <!-- <div class="specifi-item" v-if="goodsInfo.sxrq">
                    <div class="item-title flex-wrap">
                        <span class="tit">近远效期:</span>
                        <span class="tit-parameter" >{{goodsInfo.sxrq}}</span>
                    </div>
                </div> -->
        <!-- <div class="specifi-item">
                    <div class="active-item flex-wrap" v-for="(item,ind) in goodsInfo.ActivityCacheDataList" :key="ind" >
                        <input v-if="ind==0" type="radio" v-model="selectId" :value="item.ActivityId"  name="activity" >
                        <input v-else v-model="selectId" :value="item.ActivityId" type="radio" name="activity" >
                        <label>{{item.Description}}</label>
                        
                    </div>
                    
                </div> -->
        <div class="specifi-item">
          <div class="item-title flex-sp">
            <span class="tit">数量</span>
            <div class="add-subtract flex-wrap">
              <span class="add" @click="reduceNum()">-</span>
              <input class="num" type="number" v-model="cartNum" @click="editInput()"/>
              <!-- <span class="num">2</span> -->
              <span class="cut" @click="addNum()">+</span>
            </div>
          </div>
        </div>
      </div>
      <div class="good-sure" @click="addCart()">{{btnText}}</div>
    </div>

    <!-- 编辑商品数量弹窗 -->
    <div class="popup-box" v-if="editNumShow" @touchmove.prevent></div>
    <div class="popup-cont" v-if="editNumShow">
      <div class="good-tit">编辑商品数量</div>
      <div class="good-num-box">
        <span class="reduce-g" @click="reduceNum1()">-</span>
        <input type="number" ref="editNum" v-model="cartEditNum" @keyup.enter="sureEdit()"/>
        <span class="add-g" @click="addNum1()">+</span>
      </div>
      <div class="good-btn">
        <div class="btn-g" id="cancel" @click="cancelEdit()">取消</div>
        <div class="btn-g blue" id="sure" @click="sureEdit()">确认</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cartPop",
  props: {
    cartShowPop: Boolean, // 商品弹窗显示隐藏
    goodSpid: String, // 商品spid
    goodDetail: Object, // 商品详情
    typePage: String, // 商品页面类型
  },
  data() {
    return {
      goodsInfo: {}, // 购物车弹窗详情
      cartNum: 1, // 购物车数量

      editNumShow: false, // 编辑数字弹窗显示隐藏
      cartEditNum: 1, // 编辑弹窗输入框中的值

      botDis: 0, // 弹窗距离底部的距离

      maxNum: "",
      minNum: "",
      isPhone:false,
	  
	  btnText: "确定"
    };
  },

  watch: {
	goodDetail: function(){
		let that = this;
		let goodsId = that.goodDetail.spid?that.goodDetail.spid:that.goodDetail.GoodsId;
		// console.log("=====此商品id=====",JSON.stringify(that.goodDetail))
		if(that.goodDetail.IsBuy){
			that.btnText = "确定";
		}else{
			that.btnText = "申请合作";
		}
		//创建浏览记录
		that.createHistory(goodsId);
	},
    goodSpid: function() {
      var that = this;
      var type = that.typePage; // 页面类型
      // console.log(that.goodDetail);
      // console.log(that.goodSpid);
      // console.log(type);
      // console.log(that.$route.path);
      that.getGoodsInfo();
      // 如果是首页的购物车弹窗，则修改底部距离
      if (that.$route.path == "/" || that.$route.path == "/search") { 
        if(that.isPhone){
          that.botDis = 1.28;
        }else{
           that.botDis = 1.15;

        }
       
      }
    },
  },
	created(){
        this.appSource();
	},
  methods: {
	  //添加记录浏览量
	  createHistory(goodSpid){
	    this.public.request("post","api/GoodHistory/CreateHistory",{
	  	  SpId: goodSpid, //（商品id）
	  	  CustomId: localStorage.getItem("orgId")  //（登陆客户orgid）
	    },res=>{
	  	  if(res.data.errorCode=="00"){
	  		console.log("浏览记录成功!")
	  	  }else{
	  		console.log("浏览记录失败~")
	  	  }
	    })
	  },
	  
    //判断是不是苹果手机
      appSource() {
         const u = navigator.userAgent;
         const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
         if (isiOS) {//苹果
             return this.isPhone=true;               
         } else {
             return this.isPhone=false;
            
         }
     },
    // 加入购物车
    addCart() {
      var that = this;
	  if(!that.goodDetail.IsBuy){
		if(!that.goodDetail.BuyPhone){
		  return that.$toast("暂无联系方式");
		}
		
		that.$dialog.confirm({
		    message: "您即将拨打电话："+that.goodDetail.BuyPhone,
			confirmButtonText: "呼叫"
		}).then(() => {//confirm
			window.location.href = 'tel://' + that.goodDetail.BuyPhone
		}).catch(() => {
			//cancel
		});
		return;
	  }

      // 查看是否登录
      var token = sessionStorage.getItem('member_token');
      if(token == null){
          var orgId = localStorage.getItem("orgId");
          that.$dialog.confirm({
      			message: "你还未登录，是否登录!",
      		}).then(() => {
      			that.$router.push({
                      path: '/login',
                      query: {
                          orgId: orgId,
                          auto: 0,
                      }
                  });
      		}).catch(() => {
      			// console.log('取消');
      		});
          return false;
      }
	  
	  //统计分析 埋点进来
	  that.keyId = ""; //主键
	  that.public.setBuryPoint(5,1,that.keyId).then(res=>{
	  	// if(res.errorCode=="00"){
	  	// 	that.keyId = res.rows;
	  	// }
	  });

      var goodInfo = that.goodsInfo; // 商品详情
      var goodDetail = that.goodDetail; // 商品详情1
      var cartNum = that.cartNum; // 购买量
      var stock = goodInfo.kcshl; // 库存数量
      var zbz = goodInfo.zbz; // 中包装
      var type = that.typePage; // 页面类型
      // 购物车弹窗活动数据处理
      // var activeObj = {};
      // goodInfo.ActivityCacheDataList.forEach((item,index) => {
      //     if(that.selectId == item.ActivityId){
      //         activeObj = item;
      //     }
      // })

      // 关闭购物车弹窗
      that.$emit("diffCartShow");

      // 余数只要有值，则商品数量无效
      var num = cartNum - Number(cartNum / zbz) * zbz;

      if (num) {
        that.$toast("商品数量无效！");
        return false;
      }

      if (type != "seckill") {
        if (stock <= 0) {
          that.$toast("库存不足");
          return false;
        }
        if (Number(cartNum) > Number(stock)) {
          var orgId = localStorage.getItem("orgId");
          if (orgId != "7fa7c5c3582540849327ff308382a7e3") {
            that.$toast("库存不足!");
            return;
          }
        }
      } else {
        var stockNum =
          Number(goodDetail.GoodsNum) - Number(goodDetail.AttentNum);
        if (stockNum <= 0) {
          that.$toast("库存不足");
          return false;
        }
        if (Number(cartNum) > Number(stockNum)) {
          var orgId = localStorage.getItem("orgId");
          if (orgId != "7fa7c5c3582540849327ff308382a7e3") {
            that.$toast("库存不足!");
            return;
          }
        }
      }

      if (Number(cartNum) < 1) {
        that.$toast("数量不能小于1");
        return false;
      }

      var goodDetail = that.goodDetail;
      if (!goodDetail.SoldPrice ) {
        if(!goodDetail.ActivityCacheDataList[0].MinAmount){
            that.$toast("商品价格无效!");
            return;
        }
      }

      //cartSource-20秒杀 30-团购 35-集采,80-近效期,90-今日特价
      if (type == "nearExpiry") {
        // 近效期
        var cartSource = 80;
        var Stock = Number(goodDetail.Stock);
        if(Number(cartNum) > Number(Stock)){
          that.$toast("库存不足!");
          return false;
        }
      } else if (type == "group") {
        // 团购
        var cartSource = 30;
        if (Number(cartNum) > Number(goodDetail.MaxNum)) {
          that.$toast("超出最大限购量" + goodDetail.MaxNum);
          return false;
        } else if (Number(cartNum) < Number(goodDetail.MinNum)) {
          that.$toast("小于最小购买量" + goodDetail.MinNum);
          return false;
        }
      } else if (type === "bargain") {
        // 今日特价
        var cartSource = 90;
        if (Number(cartNum) > Number(goodDetail.GoodsNum)) {
          that.$toast("每人限购" + goodDetail.GoodsNum);
          return false;
        }
      }else if (type === "new_bargain") {
        // 新人特价
        var cartSource = 121;
        if (Number(cartNum) > Number(goodDetail.GoodsNum)) {
          that.$toast("每人限购" + goodDetail.GoodsNum);
          return false;
        }
      } else if (type === "purch") {
        // 集采
        var cartSource = 35;
        if (Number(cartNum) > Number(goodDetail.MaxNum)) {
          that.$toast("超出最大限购量" + goodDetail.MaxNum);
          return false;
        } else if (Number(cartNum) < Number(goodDetail.MinNum)) {
          that.$toast("小于最小购买量" + goodDetail.MinNum);
          return false;
        }
      } else if (type === "seckill") {
        // 秒杀
        var cartSource = 20;
        if (Number(cartNum) > Number(goodDetail.SingleMaxNum)) {
          that.$toast("每人限购" + goodDetail.SingleMaxNum);
          return false;
        }
      }
      var data = {
        Spid: goodInfo.spid,
        GoodsCount: cartNum, // 购物车数量
        CartSource: cartSource,
      };
	  
	  if(goodInfo.CrossGroupId){
	  	data.TmsCrossGroupId = goodInfo.CrossGroupId;
	  }
	  
      // 活动商品id
      if(goodDetail.Id){
        data.CartSourceId = goodDetail.Id;
      }else{
        data.CartSourceId = goodDetail.CurActivityData.ActivityId;
      }
      // 活动价格
      if(goodDetail.SoldPrice){
        data.GoodsPrice = goodDetail.SoldPrice;
      }else{
        data.GoodsPrice = goodDetail.CurActivityData.ActivityPrice;
      }
	  
      that.getAddCart(data);
    },
    getAddCart(data) {
      var that = this;
      that.public.request("post", "/api/SellWellCar/AddSellWellCar",
        data,(res) => {
          // console.log(res);
          if (res.data.errorCode == "00") {
            that.$toast("添加成功");
            that.public.getCartNum(); // 购物车数量
            // 中包装   近效期参与中包装，其余活动不参与中包装
            if(that.goodsInfo.OnlyPackageSold && Number(that.goodsInfo.OrderNumSetp)>0){
              that.cartNum = that.goodsInfo.OrderNumSetp;
            }else{
              that.cartNum = 1;
            }
            // that.cartNum = 1;
            that.cartShowPop = false;
          } else if (res.data.errorCode == "99") {
            that.$toast(res.data.errorMsg);
            that.cartShowPop = false;
          }
        }
      );
    },
    // 编辑input数值
    editInput() {
      var that = this;
      that.editNumShow = true;
      that.$nextTick(() => {
        that.$refs.editNum.focus();
        that.$refs.editNum.select();
      });

      that.cartEditNum = that.cartNum;
    },
    // 取消编辑弹窗
    cancelEdit() {
      var that = this;
      that.editNumShow = false;
    },
    // 购物车数量减
    reduceNum1() {
      var that = this;
      // OnlyPackageSold  true可拆零  false不可拆零, 只有近效期才能享受中包装，其余活动不参与中包装
      if(that.goodsInfo.OnlyPackageSold && Number(that.goodsInfo.OrderNumSetp)>0){
        var OrderNumSetp = that.goodsInfo.OrderNumSetp;
      }else{
        var OrderNumSetp = 1;
      }
      // var OrderNumSetp = 1;
      if (that.maxNum) {
        if (that.cartEditNum > that.minNum) {
          // that.cartEditNum--;
          if(Number(that.cartEditNum) > Number(OrderNumSetp)){
            that.cartEditNum = Number(that.cartEditNum) - Number(OrderNumSetp);
          }
          
        } else {
          that.$toast("最少购买" + that.minNum + that.goodsInfo.dw);
        }
      } else {
        // that.cartEditNum--;
        if(Number(that.cartEditNum) > Number(OrderNumSetp)){
          that.cartEditNum = Number(that.cartEditNum) - Number(OrderNumSetp);
        }
        
      }
    },
    // 购物车数量加
    addNum1() {
      var that = this;
      // OnlyPackageSold  true可拆零  false不可拆零, 只有近效期才能享受中包装，其余活动不参与中包装
      if(that.goodsInfo.OnlyPackageSold && Number(that.goodsInfo.OrderNumSetp)>0){
        var OrderNumSetp = that.goodsInfo.OrderNumSetp;
      }else{
        var OrderNumSetp = 1;
      }
      // var OrderNumSetp = 1;
      if (that.maxNum) {
        if (that.cartEditNum < that.maxNum) {
          // that.cartEditNum++;
          that.cartEditNum = Number(that.cartEditNum) + Number(OrderNumSetp);
        } else {
          that.$toast("最多购买" + that.maxNum + that.goodsInfo.dw);
        }
      } else {
        // that.cartEditNum++;
        that.cartEditNum = Number(that.cartEditNum) + Number(OrderNumSetp);
      }
    },
    // 编辑弹窗确认
    sureEdit() {
      var that = this;
      that.editNumShow = false;
      // OnlyPackageSold  true可拆零  false不可拆零, 只有近效期才能享受中包装，其余活动不参与中包装
      if(that.goodsInfo.OnlyPackageSold && Number(that.goodsInfo.OrderNumSetp)>0){
        var OrderNumSetp = that.goodsInfo.OrderNumSetp;
      }else{
        var OrderNumSetp = 1;
      }
      // var OrderNumSetp = 1;
      if (that.maxNum) {
        if (Number(that.cartEditNum) < Number(that.minNum)) {
          that.cartNum = that.minNum;
          that.$toast("最少购买" + that.minNum + that.goodsInfo.dw);
        } else if (Number(that.cartEditNum) > Number(that.maxNum)) {
          that.cartNum = that.maxNum;
          that.$toast("最多购买" + that.maxNum + that.goodsInfo.dw);
        } else {
          // 取余
          if(that.cartEditNum%OrderNumSetp == 0){
            that.cartNum = that.cartEditNum;
          }else{
            that.$toast("购买数量"+that.cartEditNum+"，必须为"+OrderNumSetp+"的倍数");
            that.cartNum = that.cartNum;
          }
          
        }
      } else {
        // 取余
        if(that.cartEditNum%OrderNumSetp == 0){
          that.cartNum = that.cartEditNum;
        }else{
          that.$toast("购买数量"+that.cartEditNum+"，必须为"+OrderNumSetp+"的倍数");
          that.cartNum = that.cartNum;
        }
      }

      that.cartEditNum = 1;
    },

    // 购物车数量减
    reduceNum() {
      var that = this;
      // OnlyPackageSold  true可拆零  false不可拆零, 只有近效期才能享受中包装，其余活动不参与中包装
      if(that.goodsInfo.OnlyPackageSold && Number(that.goodsInfo.OrderNumSetp)>0){
        var OrderNumSetp = that.goodsInfo.OrderNumSetp;
      }else{
        var OrderNumSetp = 1;
      }
      // var OrderNumSetp = 1;
      if (Number(that.cartNum) != 1) {
        if (Number(that.cartNum) > Number(that.minNum)) {
          // that.cartNum--;
          if(Number(that.cartNum) > Number(OrderNumSetp)){
            that.cartNum = Number(that.cartNum) - Number(OrderNumSetp);
          }
          
        } else {
          that.$toast("最少购买" + that.minNum + that.goodsInfo.dw);
        }
      } else {
        if (Number(that.cartNum) > 1) {
          // that.cartNum--;
          if(Number(that.cartNum) > Number(OrderNumSetp)){
            that.cartNum = Number(that.cartNum) - Number(OrderNumSetp);
          }
          
        }
      }
    },
    // 购物车数量加
    addNum() {
      var that = this;
      // OnlyPackageSold  true可拆零  false不可拆零, 只有近效期参与中包装，其余活动不参与
      if(that.goodsInfo.OnlyPackageSold && Number(that.goodsInfo.OrderNumSetp)>0){
        var OrderNumSetp = that.goodsInfo.OrderNumSetp;
      }else{
        var OrderNumSetp = 1;
      }
      // var OrderNumSetp = 1;
      if (Number(that.maxNum)) {
        if (Number(that.cartNum) < Number(that.maxNum)) {
          // that.cartNum++;
          that.cartNum = Number(that.cartNum) + Number(OrderNumSetp);
        } else {
          that.$toast("最多购买" + that.maxNum + that.goodsInfo.dw);
        }
      } else {
        // that.cartNum++;
        that.cartNum = Number(that.cartNum) + Number(OrderNumSetp);
      }
    },
    // 获取商品信息
    getGoodsInfo() {
      var that = this;
      // OnlyPackageSold  true可拆零  false不可拆零, 近效期参与中包装，其余活动不参与近效期
      if(that.goodsInfo.OnlyPackageSold && Number(that.goodsInfo.OrderNumSetp)>0){
        that.cartNum = that.goodsInfo.OrderNumSetp;
      }else{
        that.cartNum = 1;
      }
      // that.cartNum = 1;
      var spid = that.goodSpid;
      var orgId = localStorage.orgId;
      var data = {
        SpId: spid,
        OrgId: orgId,
      };
      that.public.request("post", "/api/Store/QueryGoodsInfo", data, (res) => {
        if (res.data.errorCode == "00") {
			that.goodsInfo = res.data.rows[0];
			// 步长
			var OrderNumSetp = Number(that.goodsInfo.OrderNumSetp);
			// that.selectId = res.data.rows[0].ActivityCacheDataList[0].Activ  ityId;
			if(that.goodDetail.MinNum){
				that.minNum = that.goodDetail.MinNum;
				that.maxNum = that.goodDetail.MaxNum;
			}else{
			   // OnlyPackageSold  true可拆零  false不可拆零, 近效期参与中包装，其余活动不参与中包装
				if(that.goodsInfo.OnlyPackageSold && OrderNumSetp > 0){
					that.minNum = OrderNumSetp;
				}else{
					that.minNum = 1;
				}
				if(that.goodsInfo.CurActivityData){  // 除了近效期暂时为null，其他活动都有数据
					that.minNum = that.goodsInfo.CurActivityData.MinAmount ? that.goodsInfo.CurActivityData.MinAmount : that.minNum;
					that.maxNum = that.goodsInfo.CurActivityData.MaxAmount;
				}
			}
			that.cartNum = that.minNum;
        } else {
			that.$toast(res.data.errorMsg);
        }
      });
    },
    // 关闭购物车弹窗
    closePop() {
      var that = this;
      // OnlyPackageSold  true可拆零  false不可拆零
	  var OrderNumSetp = Number(that.goodsInfo.OrderNumSetp);
	  
	   // that.cartNum=that.maxNum ? that.minNum : 1;
      if(that.goodsInfo.OnlyPackageSold && OrderNumSetp > 0){
        that.cartNum=that.maxNum ? that.minNum : OrderNumSetp;
      }else{
        that.cartNum=that.maxNum ? that.minNum : 1;
      }
      this.$emit("diffCartShow");
    
    },
  },
};
</script>

<style scoped>
/* 商品弹窗 */
.good-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.good-pop {
  position: fixed;
  left: 0;
  /* bottom: 1.2rem; */
  z-index: 2;
  background: #fff;
  width: 100%;
}
.good-pop .good-top {
  width: 100%;
  padding: 0.18rem 0.2rem;
  box-sizing: border-box;
  border-bottom: 0.02rem solid #eee;
}
.good-pop .good-top img {
  width: 1.8rem;
  max-height: 1.8rem;
  margin-right: 0.2rem;
}
.good-pop .good-top .good-right {
  flex: 1;
  height: 1.8rem;
  justify-content: space-between;
}
.good-pop .good-top .good-right .right-name {
  font-size: 0.3rem;
  font-weight: 600;
}
.good-pop .good-top .good-right .right-price span.price-tit {
  font-size: 0.5rem;
}
.good-pop .good-top .good-right .right-price span {
  font-size: 0.28rem;
}

.good-pop .good-specifi {
  width: 100%;
  padding: 0.18rem 0.2rem;
  box-sizing: border-box;
}
.good-pop .good-specifi .specifi-item {
  margin-bottom: 0.12rem;
}
.good-pop .good-specifi .specifi-item .item-title .tit {
  font-size: 0.28rem;
  color: #333;
  font-weight: 600;
  display: block;
  /* margin-right: 0.2rem; */
}
.good-pop .good-specifi .specifi-item .item-title .tit-parameter {
  font-size: 0.28rem;
  color: #999;
}
.good-pop .good-specifi .specifi-item .last-span {
  margin-left: 0.28rem;
  color: #999;
  font-size: 0.26rem;
}
/* 商品加减 */
.add-subtract {
  flex: 1;
  justify-content: flex-end;
}
.add-subtract .add {
  font-size: 0.36rem;
  border: 0.05rem solid #ff1515;
  color: #ff1515;
  border-radius: 50%;
  display: inline-block;
  width: 0.38rem;
  height: 0.38rem;
  text-align: center;
  line-height: 0.3rem;
  box-sizing: border-box;
}
.add-subtract .num {
  font-size: 0.3rem;
  margin: 0 0.2rem;
  width: 1rem;
  text-align: center;
  border: 0.02rem solid #999;
  border-radius: 0.12rem;
  line-height: inherit;
}
.add-subtract .cut {
  font-size: 0.36rem;
  background: #ff1515;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  width: 0.38rem;
  height: 0.38rem;
  text-align: center;
  line-height: 0.3rem;
}

.good-pop .good-sure {
  width: 100%;
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  color: #fff;
  background: var(--theme-color);
  margin: 0.2rem auto 0;
  font-size: 0.3rem;
}

/* 购物车弹窗活动 */
.good-pop .good-specifi .specifi-item .active-item {
  width: 100%;
  font-size: 0.3rem;
  color: #ff1515;
  padding: 0.06rem 0;
  box-sizing: border-box;
}
.good-pop .good-specifi .specifi-item .active-item label {
  margin-left: 0.2rem;
}

/* 编辑商品数量弹窗 */
.popup-box {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 11000;
}
.popup-cont {
  position: fixed;
  width: 70%;
  padding: 0.2rem 0 0;
  box-sizing: border-box;
  background: #fff;
  border-radius: 0.12rem;
  left: 15%;
  top: 25%;
  z-index: 11001;
}
.popup-cont .good-tit {
  width: 100%;
  text-align: center;
  font-size: 0.3rem;
  margin-bottom: 0.2rem;
}
.popup-cont .good-num-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.1rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
}
.popup-cont .good-num-box .reduce-g {
  font-size: 0.39rem;
}
.popup-cont .good-num-box input {
  width: 2rem;
  height: 0.6rem;
  padding: 0.1rem;
  margin: 0 0.12rem;
  text-align: center;
  border: 0.02rem solid #c9c4c4;
  border-radius: 0.12rem;
  font-size: 0.3rem;
}
.popup-cont .good-num-box .add-g {
  font-size: 0.39rem;
}
.popup-cont .good-btn {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

.popup-cont .good-btn .btn-g {
  width: 50%;
  font-size: 0.3rem;
  text-align: center;
  color: #999;
}
.popup-cont .good-btn .btn-g.blue {
  color: #58a0f2;
}
</style>
