<template>
    <div class="contain">
        <div class="coupon-list" v-if="conponShow">
            <div class="coupon-item flex-wrap" v-for="(item,index) in couponList" :key="index">
                <div class="coupon-left flex-column;">
                    <div class="item-price font-red">{{item.Amount}}元</div>
                    <div class="item-explain">订单金额满￥{{item.MixOrderAmount}}可用</div>
                    <div class="item-time">
                        <span>领取时间:</span>
                        <span class="font-red" v-if="item.GetBeginTime">{{item.GetBeginTime}}</span>
                        <span class="font-red" v-if="!item.GetBeginTime">{{item.BeginTime}}</span>
                        <span>到</span>
                        <span class="font-red" v-if="item.GetEndTime">{{item.GetEndTime}}</span>
                        <span class="font-red" v-if="!item.GetEndTime">{{item.EndTime}}</span>
                    </div>
                </div>
                <div class="coupon-right flex-center">
                    <div class="recevie-but active" v-if="item.Enable" @click="onReceive(index)">立即领取</div>
                    <div class="recevie-but gray" v-if="!item.Enable">已领取</div>
                </div>
            </div>
            <!-- <div class="coupon-item flex-wrap">
                <div class="coupon-left flex-column;">
                    <div class="item-price font-red">10元</div>
                    <div class="item-explain">订单金额满￥100可用</div>
                    <div class="item-time">
                        <span>有效期</span>
                        <span class="font-red">2020-12-01</span>
                        <span>到</span>
                        <span class="font-red">2020-12-02</span>
                    </div>
                </div>
                <div class="coupon-right flex-center">
                    <div class="recevie-but gray">已领取</div>
                </div>
            </div> -->
        </div>
        <!-- 暂无优惠券 -->
        <div class="no-data" v-if="couponList.length == 0">
            <img src="@/assets/images/nore.png" alt="暂无数据">
            <span>没有优惠券可以领取</span>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            conponShow:true,
            couponList: [],     // 优惠券列表
        }
    },
    created(){
        var that = this;
		var url = that.$route.query.url;
		if (!url) {
		  var urlObj = that.$route.query;
		} else {
		  var urlObj = that.public.urlObj(url);
		}
		// 顶部标题
		that.$store.commit("setHeadTitle", urlObj.name);
        // 优惠券列表
        that.getCouponList();
    },
    methods:{
        // 优惠券列表
        getCouponList(){
            var that = this;
            var data = {}
            that.$toast.loading({
                message: '加载中',
                duration: 0,
            })
            var token= sessionStorage.getItem('member_token')
            if(!token){
                 that.$toast.clear();
                 return false
            }



            that.public.request('post','/api/Coupon/QueryAvailableCoupon',data,res => {
                if(res.data.errorCode == '00'){
                    that.$toast.clear();
                    var couponList = res.data.rows;
                    that.couponList = couponList;
                }else if(res.data.errorCode == '99'){
                    that.$toast.clear();
                    that.$toast(res.data.errorMsg);
                }
            })
        },
        // 立即领取
        onReceive(index){
            var that = this;
            var id = that.couponList[index].Id;
            var data = {
                couponId: id,
            }
            that.public.request('get','/api/Coupon/GetCoupon',data,res => {
                if(res.data.errorCode == '00'){
                    that.couponList[index].Enable = false;
                }else{
                    that.$toast(res.data.errorMsg);
                }
            })
        },
    }
}
</script>

<style scoped>
    .coupon-list{ width:100%;padding:0.2rem;box-sizing:border-box; }
    .coupon-list .coupon-item{ width:100%;height:2rem;padding:0.25rem 0.4rem;box-sizing:border-box;background:url('../../assets/images/bg_cupon_mycoupon.png') no-repeat;background-size:100% 100%;margin-bottom:0.2rem; }
    .coupon-list .coupon-item .coupon-left{ width:75%;justify-content:space-between; }
    .coupon-list .coupon-item .coupon-left .item-price{ width:75%;font-size:0.5rem;text-align:center;font-weight:600; }
    .coupon-list .coupon-item .coupon-left .item-explain{ font-size:0.24rem;color:#666; }
    .coupon-list .coupon-item .coupon-left .item-time span{ font-size:0.24rem; }

    .coupon-list .coupon-item .coupon-right{ flex:1; }
    .coupon-list .coupon-item .coupon-right .recevie-but{ width:100%;height:0.6rem;line-height:0.6rem;border-radius:0.28rem;color:#fff;text-align:center;font-size:0.3rem; }
    .coupon-list .coupon-item .coupon-right .recevie-but.active{ background:rgb(255, 94, 0); }
    .coupon-list .coupon-item .coupon-right .recevie-but.gray{ background:rgb(211, 210, 210); }
    .coupon-list .coupon-item .coupon-right .recevie-but.red{ background:#f58b8b; }

    
</style>