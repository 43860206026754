<template>
    <div class="container">
		<div class="book-li">
			<span class="book-li-tag">选择银行</span>
			<img v-if="bankInfo" class="icon" src="https://yxyypic.oss-cn-beijing.aliyuncs.com/CxGoods/2679591e77624a6489912571f449832c/20240715092908.png" alt="" />
			<div class="book-li-ipt" style="line-height: 40px;" @click="showBank=true">{{bankInfo?bankInfo.BankName:"银行类型"}}</div>
		</div>
		<div class="book-li">
			<span class="book-li-tag">银行卡号</span>
			<input class="book-li-ipt" type="number" placeholder="请输入本人的银行卡号" v-model="bankId" />
		</div>
		<div class="book-li">
			<span class="book-li-tag">真实姓名</span>
			<input class="book-li-ipt" type="text" placeholder="请填写您的真实姓名" v-model="realName" />
		</div>
		<div class="book-li">
			<span class="book-li-tag">身份证号</span>
			<input class="book-li-ipt" type="text" placeholder="请输入身份证号" v-model="cardId" />
		</div>
		<div class="book-li">
			<span class="book-li-tag">联系方式</span>
			<input class="book-li-ipt" type="number" placeholder="预留手机号" v-model="mobile" />
		</div>
		<div class="book-li">
			<input class="book-li-ipt" type="number" placeholder="手机验证码" v-model="smsCode" />
			<span class="book-li-tag send" :class="{'disabled':sendCodeBtn!='发送验证码'}" @click="sendMsgCode">{{sendCodeBtn}}</span>
		</div>
		<div style="font-size: 14px; color: #999; padding: 10px 20px;">请使用银行签约时预留的手机号，验证码3分钟内有效</div>
		<button class="add-btn" @click="saveBindTap">保 存</button>
		
		<van-popup v-model="showBank" round position="bottom" :style="{height: '50%' }">
			<div class="bank-list">
				<p v-for="(item,i) in bankList" @click="checkBank(item)">{{item.BankName}}</p>
			</div>
		</van-popup>
    </div>
</template>

<script>
export default {
    data(){
        return{
			bankList: [],
			showBank: false,
			bankInfo: null,
			bankId: "",
			realName: "",
			cardId: "",
			mobile: "",
			smsCode: "",
			signId: "",
			sendCodeBtn: "发送验证码"
        }
    },
    created(){
		// this.id = this.$route.query.id;
		this.queryBankName();
    },
    methods:{
		//获取银行名称列表
		queryBankName(){
		    var that = this;
		    that.$toast.loading({
		        message: '加载中',
		        duration: 0
		    })
		    that.public.request('post','/api/SysUserBankType/QueryValidList',{
				// 参数
		    },res => {
		        that.$toast.clear();
		        if(res.data.errorCode == '00'){
					that.bankList = res.data.rows;
		        }else{
		          that.$toast(res.data.errorMsg);
		        }
		    })
		},
		
		//选择的银行
		checkBank(bankItem){
			this.bankInfo = bankItem;
			this.showBank = false;
		},
		
		//发送验证码
		sendMsgCode(){
		    var that = this;
			if(this.sendCodeBtn != "发送验证码"){
				return;
			}
			if(!this.bankInfo){
				return this.$toast("请选择银行类型")
			}
			if(!this.bankId){
				return this.$toast("请输入银行卡号")
			}
			if(!this.realName){
				return this.$toast("请输入真实姓名")
			}
			if(!this.cardId){
				return this.$toast("请输入身份证号")
			}
			if(!this.mobile){
				return this.$toast("请输入预留的手机号")
			}
		    that.$toast.loading({
		        message: '发送中',
		        duration: 0
		    })
		    that.public.request('post','/api/SysUserBank/sendSigncode',{
				BankNo: that.bankInfo.BankNo, // 银行编号
				Acc: that.bankId, // 账号
				Name: that.realName, // 真实姓名
				IdNo: that.cardId, // 有效证件号码
				Phone: that.mobile, // 手机号
		    },res => {
		        that.$toast.clear();
		        if(res.data.errorCode == '00'){
					that.signId = res.data.rows;
					// 倒计时
					let num = 60;
					let time = setInterval(function() {
					  if (num == 0) {
					    clearInterval(time);
					    that.sendCodeBtn = "发送验证码";
					  } else {
					    num--;
					    that.sendCodeBtn = num + "秒后重试";
					  }
					}, 1000);
		        }else if(res.data.errorCode == '100'){
					that.$toast("该银行卡号已经签约");
				}else{
		          that.$toast(res.data.errorMsg);
		        }
		    })
		},
		
        // 保存
        saveBindTap(){
			let that = this;
			if(!this.smsCode){
				return this.$toast("请输入验证码");
			}
			// if(!this.signId){
			// 	return this.$toast("银行卡号异常");
			// }
			that.public.request('post','/api/SysUserBank/verifysigncode',{
				Id: that.signId, //获取验证码时返回的id
				Code: that.smsCode, //验证码
			},res => {
				if(res.data.errorCode == '00'){
					that.$toast("保存成功");
					setTimeout(()=>{
						that.$router.go(-1);
					},1000)
				}else{
					that.$toast(res.data.errorMsg);
				}
			})
        },
		
		// confirmDate(value) {
		//   let yy = new Date(value).getFullYear();
		//   let mm = new Date(value).getMonth() + 1;
		//   let dd = new Date(value).getDate();
		//   mm = mm >= 10 ? mm : "0" + mm;
		//   dd = dd >= 10 ? dd : "0" + dd;
		//   this.endDate = yy + "-" + mm + "-" + dd;
		//   this.showDate = false;
		// },
    }
}
</script>


<style scoped>
	.container{
		width: 100%;
		height: 100%;
		background: #f7f7f7;
	}
	.book-li{
		padding: 10px 20px;
		display: flex;
		align-items: center;
		font-size: 16px;
		background: #fff;
		border-bottom: 1px #eee solid;
	}
	.book-li .icon{
		width: 40px;
		height: 40px;
		border-radius: 50%;
		margin-right: 10px;
	}
	.book-li-ipt{
		width: 100%;
		height: 40px;
		color: #666;
		/* padding: 0 10px; */
		/* border: 1px #eee solid; */
	}
	.book-li-tag{
		width: 120px;
		color: #999;
	}
	.book-li-tag.send{
		color: #2da5eb;
	}
	.book-li-tag.disabled{
		color: #999;
	}
   
	.add-btn{
		width: 90%;
		height: 40px;
		line-height: 40px;
		margin: 50px 5%;
		text-align: center;
		background: #2da5eb;
		color: #fff;
		font-size: 15px;
		border-radius: 5px;
	}
	
	.bank-list{
		background: #fff;
		text-align: center;
		padding: 20px;
	}
	.bank-list p{
		margin: 0;
		padding: 10px;
		font-size: 15px;
		border-bottom: 1px #eee solid;
	}
</style>