<template>
    <div class="container">
        <div class="serve-list">
            <div class="item-s flex-wrap" @click="toSerAgreent">
                <span>服务协议</span>
                <img src="@/assets/images/mine/arrow-r.png">
            </div>
            <div class="item-s flex-wrap" @click="toPriPolicy">
                <span>隐私政策</span>
                <img src="@/assets/images/mine/arrow-r.png">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            
        }
    },
    created(){
        
    },
    methods:{
        // 服务协议
        toSerAgreent(){
            this.$router.push({
                path: '/serveAgreement'
            })
            // location.href = './ServiceContract.html'
        },
        // 隐私协议
        toPriPolicy(){
            this.$router.push({
                path: '/privacyPolicy'
            })
            // location.href = './AboutUs.html'
        }
    }
}

</script>


<style scoped>
    .serve-list .item-s{ width:100%;height:1rem;padding:0 0.2rem;box-sizing:border-box;border-bottom:0.02rem solid #eee; }
    .serve-list .item-s span{ flex:1;font-size:0.3rem; }
    .serve-list .item-s img{ width:0.16rem;margin-left:0.2rem; }
</style>