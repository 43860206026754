<template>
    <div>
       <van-tabs  class="tabs"  @click="onClick" color='#007AFF' title-active-color='#007AFF' line-width='1.1rem'  :sticky=true offset-top='.9rem'>
           <van-tab title="全部">
               <van-list  v-model="loading" :finished="finished"  finished-text="没有更多了"  @load="onLoad(0)" :offset="10" :immediate-check=false >
                <div >
                    <integral-item  v-for="(item,id) in allList" :key="id" :itemList='item'></integral-item>  
                </div>
                <div v-if="noData" class="no-data">
                   <img src="@/assets/images/cart/noActiveDrug.png" alt="" />
                 </div>
              </van-list>   
           </van-tab>
    
          <van-tab title="待审核">
            <van-list  v-model="loading" :finished="finished"  finished-text="没有更多了"  @load="onLoad(10)" :offset="10"  :immediate-check=false>
                <div >
                    <integral-item v-for="(item,id) in pendingReview" :key="id" :itemList='item'></integral-item>  
                </div>
                <div v-if="noReview" class="no-data">
                   <img src="@/assets/images/cart/noActiveDrug.png" alt="" />
                 </div>
            </van-list>   
         </van-tab>

          <van-tab title="待收货">
            <van-list  v-model="loading" :finished="finished"  finished-text="没有更多了"  @load="onLoad(30)" :offset="10" :immediate-check=false >
                <div>
                    <integral-item v-for="(item,id) in toReceived" :key="id" :itemList='item'></integral-item>  
                </div>
                <div v-if="noUnreceived" class="no-data">
                   <img src="@/assets/images/cart/noActiveDrug.png" alt="" />
                 </div>
            </van-list> 
          </van-tab>

          <van-tab title="已收货">
            <van-list  v-model="loading" :finished="finished"  finished-text="没有更多了"  @load="onLoad(40)" :offset="10" :immediate-check=false >
                <div  >
                    <integral-item v-for="(item,id) in receivedList" :key="id" :itemList='item'></integral-item>  
                </div>
                <div v-if="noReceipt" class="no-data">
                   <img src="@/assets/images/cart/noActiveDrug.png" alt="" />
                 </div>
            </van-list> 
         </van-tab>
          <van-tab title="未通过">
            <van-list  v-model="loading" :finished="finished"  finished-text="没有更多了"  @load="onLoad(90)" :offset="10" :immediate-check=false >
                <div>
                    <integral-item  v-for="(item,id) in notPassList" :key="id" :itemList='item'></integral-item>  
                </div>
                <div v-if="notPassData" class="no-data">
                   <img src="@/assets/images/cart/noActiveDrug.png" alt="" />
                 </div>
            </van-list> 
         </van-tab>
       </van-tabs>
    </div>
</template>

<script>
import Vue from "vue";
import { Tab, Tabs, List} from "vant";
import { Cell } from 'vant';

Vue.use(Cell);

Vue.use(List, Tab, Tabs);
import integralItem from '@/components/integralGoodItem.vue'
export default {
   components:{
        integralItem,
    },
    data(){
        return{
            status: 0,
            finished:'',
            loading:false,
            page:1,
            total:1,
            rows:10,
            Type:10,
            Status:'',
            CustomerId:sessionStorage.getItem('customer'),
            allList:[],//全部
            pendingReview:[],// 待审核
            toReceived:[],// 待收货
            receivedList:[],// 已收货
            notPassList:[],// 未通过
            noData:false,
            noReview:false,
            noUnreceived:false,
            noReceipt:false,
            notPassData:false,

        }
    },
    created () {
      this.onLoad(0)
    },
    methods: {
      onClick(name,title){
        var that=this;
        that.page=1;
        that.loading= true;
        that.finished=false;
        that.allList=[];
        that.pendingReview=[];
        that.toReceived=[];
        that.receivedList=[];
        that.notPassList=[];
        that.noData=false;
        that.noReview=false;
        that.noUnreceived=false;
        that.noReceipt=false;
        that.notPassData=false;  
        if(title==='全部'){
          that.onLoad(0);
        }else if(title==='待审核'){
           that.onLoad(10);
        }else if(title==='待收货'){
           that.onLoad(30);
        }else if(title==='已收货'){
           that.onLoad(40);
        }else if(title==='未通过'){
           that.onLoad(90);
        }
        
      },
      onLoad(Status){
        var that=this;
        var data={
          page:that.page,
          rows:that.rows,
          Type:that.Type,
          total:that.total,
          CustomerId:that.CustomerId,
          Status:Status
        }
        if(that.page==1){
            that.$toast.loading({
            message:'加载中'
          });
        }
       setTimeout(()=>{
          that.public.request(
          'post',
          'api/PointsOrders/QueryPage',
          data,
          (res)=>{
             that.loading = false;
             that.$toast.clear();
             if(res.data.errorCode=='00'){
               if(Status===0){
                  that.allList.push(...res.data.rows);
               }else if(Status===10){
                that.pendingReview.push(...res.data.rows);
               }else if(Status===30){
                    that.toReceived.push(...res.data.rows); 
               }else if(Status===40){
                 that.receivedList.push(...res.data.rows);
               }else if(Status===90){
                  that.notPassList.push(...res.data.rows);  
               }
              
              this.finished = false;
              if(that.page*that.rows>res.data.records){
                this.finished = true;	// 结束加载状态 
                if(Status===0 &&that.allList.length==0){
                       that.noData=true;
                  }else if(Status===10 &&that.pendingReview.length==0){
                       that.noReview=true;
                  }else if(Status===30 &&that.toReceived.length==0){
                       that.noUnreceived=true;
                  }else if(Status===40 &&that.receivedList.length==0){
                       that.noReceipt=true;
                  }else if(Status===90 &&that.notPassList.length==0){
                       that.notPassData=true;  
                  }    
              }else{
                that.page++
              }
             }else{
               that.$toast(res.data.errorCode)
             }
          });
       },500);

      }
    }
}
</script>


<style scoped>
/* tab导航 */
.tab-box {
  width: 100%;
  height: 0.9rem;
  justify-content: space-around;
  border-bottom: 0.02rem solid #eee;
}
.tab-box .tab-item {
  height: 100%;
  line-height: 0.9rem;
  font-size: 0.3rem;
}
.tab-box .tab-item.active {
  color: #199ed8;
  border-bottom: 0.02rem solid #199ed8;
}
</style>