<template>
  <div >
    <div >
      <div>
          <!-- 优惠卷 -->
         <div class="roll" v-if="type=='20'">
           <div class="coupon-list" >
                <div class="coupon-item flex-wrap" :style="'background:url('+couponBg+') no-repeat'" >
                    <div class="coupon-left flex-column;">
                        <div class="item-price font-red">80元</div>
                        <div class="item-explain">单笔订单实付满20元可用</div>
                        <div class="item-time">
                            <span>有效天数：30</span>
                            
                        </div>
                    </div>
                    <div class="coupon-right flex-center">
                        <div class="recevie-but active">立即兑换</div>
                    </div>
                </div>
            </div>   
          </div>
          <!-- 商品  -->
         <div class="shop" v-if="type=='10'"> 
            <img v-if="defaultTu" src='@/assets/images/loseImg.png' alt="">
            <img v-else :src=ShopDetail.SpPic alt="">
           
         </div>
      </div>

      <div class="p-20 font-12 ">
          <div class="flex-sb">
              <div> 
                <div>
                    <div class="padding-top-5"><span>{{ShopDetail.SpName}}</span></div>
                    <div class="padding-top-5"><span>{{ShopDetail.Name}}</span></div>
                    <div class="color-hui padding-top-5">剩余可兑换 <span class="color-lv"> {{shopList.Residue}}</span> </div>
                    <div class="color-hui padding-top-5" v-if="type=='10'">规格 : {{ShopDetail.Style}}</div>
                </div>
              </div>
            <div class="numbox">
                <button class="reduce numbtn" @click="reduceBtn">-</button>
                <input type="number" class="number"   v-model="number" @blur="sureEdit">
                <button class="add numbtn" @click="addBtn">+</button>
            </div>
          </div>
          <div class="flex-sb padding-top-5">
              <div class="color-hui" v-if="type=='20'">有效期天数<span class="color-lv">  {{ShopDetail.UseDay}}</span></div>
              <div class="color-hui" v-if="type=='10'">单位：{{ShopDetail.Unit}}</div>
              <div class="color-hui">每人限购 <span class="color-lv"> {{ShopDetail.EachLimit}}</span>  {{ShopDetail.Unit}}</div>
          </div>
          <div class="flex-sb">
              <div class="color-hui  padding-top-5" v-if="type=='10'">厂家：{{ShopDetail.Factory}}</div>
              <div v-else></div>
              <div class="color-hui  padding-top-5" >    
              兑换需要<span class="color-lv"> {{ShopDetail.RequirePoints}} </span>积分
          </div>
          </div>
      </div>    
      </div>
    <div class="duihuan font-12" @click="exchangeBtn()"> 
        <span  >立即兑换</span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Dialog } from 'vant';

// 全局注册
Vue.use(Dialog);
export default {
    data () {
        return {
            couponBg: require('@/assets/images/bg_cupon_mycoupon.png'),
            shopList:{},
            ShopDetail:{},
            number:1,//input框值
            maxNumber:'',
            type:this.$route.query.type,
            Mid:this.$route.query.mid,
            Id:this.$route.query.id,
            defaultTu:false,
            errorMsg:''
        }
    },
    created () {
        this.getShopDetail();
    },
    methods: {
        // 获取数据
        getShopDetail(){
            var that=this;
            var  data={
                Mid:that.Mid,
                Id:that.Id,
            }
            this.$toast.loading({
               message: "加载中...",
               duration: 0,
             });
            that.public.request(
                'post',
                'api/PointChangeSetting/GetItemInfoById',
                data,
                (res)=>{
                    that.$toast.clear();
                    if(res.data.errorCode==='00'){
                        
                        that.shopList=res.data.extend;
                        that.ShopDetail=res.data.rows;
                        that.maxNumber=that.ShopDetail.EachLimit;
                        // console.log(that.shopList);
                    }else{
                         that.$toast(res.data.errorMsg);
                         that.defaultTu=true;
                    }
                }
            )
        },
        // 输入框
        sureEdit(){
            var that=this;
            if(that.number<1){
                that.number=1;
            }else if(that.number>that.maxNumber){
                that.number=that.maxNumber;
                that.$toast('超出最大限购量');
            }
        },
        // 减
        reduceBtn(){
             var that=this;
            if(that.number==1){
                return false;
            }else{
                 that.number--;
            }
        },
        // 加
        addBtn(){
            var that=this;
           if(that.shopList.Residue>0){
             if(that.number>=that.shopList.Residue){
                  return  that.$toast('不能超过最大库存');
                
             }else{
               if(that.number>=that.maxNumber){
                 that.$toast('超出最大限购量');
                    return false;
                }else{
                 that.number++;
                }
              }
           
           }else{
               return  that.$toast('库存不足');
           }
              
        },
        // 兑换
        exchangeBtn(){
            var that=this;
            var data={
                Mid:that.Mid,
                ItemId:that.Id,
                Type:that.type,
                Num:that.number,
            }
            if(that.shopList.Residue>0){
                if(that.shopList.Residue<that.number){
                  return  that.$toast('超过剩余量，最大兑换量为：'+that.shopList.Residue);
                }
            }else{
                return  that.$toast('库存不足');

            }
           if(that.type==='20'){//优惠卷     
                that.$toast.loading({
                    message:'兑换中'
                })           
                that.public.request(
                'post',
                'api/PointsOrders/CreateOrder',
                data,
                (res)=>{
                    that.$toast.clear();
                    if(res.data.errorCode==='00'){                      
                          Dialog.confirm({
                            title: '恭喜您已成功兑换优惠卡券',
                            message: '优惠券已发放到我的卡券中',
                             confirmButtonText:'去查看',
                             confirmButtonColor:'#1ABC9C',
                             cancelButtonText:'我知道了',
                            cancelButtonColor :' #A1A1A1',
                            })
                            .then(() => {
                                // console.log('跳转到卡卷');
                                that.$router.push({
                                    path:'/myCoupon'
                                });
                               
                            })
                            .catch(() => {
                                //  console.log('跳转到积分商城首页');
                                   that.$router.push({
                                    path:'/pointsMall'
                                });
                            });
                       
                    }else{
                        that.$toast(res.data.errorMsg);
                        that.errorMsg=res.data.errorMsg;
                    }
                 
                });
            } else{//积分好物
                const mid=that.Mid;
                const id=that.Id; 
                const num=that.number;
                if(that.shopList){     
                    that.$router.push({
                    path: "/shopOrder",
                    query: {mid,id,num}
                });
                 }
            }  
        },
    },
};
</script>

<style scoped>

.p-20{
    padding: 0 .4rem /* 20/50 */;
}
/* 优惠卷 */
    .coupon-list{ width:100%;padding:0.2rem;box-sizing:border-box; }
    .coupon-list .coupon-item{ width:100%;height:2rem;padding:0.25rem 0.4rem;box-sizing:border-box;background-size:100% 100% !important;margin-bottom:0.2rem; }
    .coupon-list .coupon-item .coupon-left{ width:75%;justify-content:space-between; }
    .coupon-list .coupon-item .coupon-left .item-price{ width:75%;font-size:0.5rem;text-align:center;font-weight:600; }
    .coupon-list .coupon-item .coupon-left .item-explain{ font-size:0.24rem;color:#666; }
    .coupon-list .coupon-item .coupon-left .item-time span{ font-size:0.24rem; }

    .coupon-list .coupon-item .coupon-right{ flex:1; }
    .coupon-list .coupon-item .coupon-right .recevie-but{ width:100%;height:0.6rem;line-height:0.6rem;border-radius:0.28rem;color:#fff;text-align:center;font-size:0.3rem; }
    .coupon-list .coupon-item .coupon-right .recevie-but.active{ background:#E94949;}
    .coupon-list .coupon-item .coupon-right .recevie-but.gray{ background:rgb(211, 210, 210); }
    .coupon-list .coupon-item .coupon-right .recevie-but.red{ background:#f58b8b; }

.shop{
height: 4rem /* 200/50 */;
border: .02rem solid rgb(156, 156, 156) ;
margin: .4rem /* 20/50 */;  


}
.shop img{
    width: 100%;
    height: 4rem /* 200/50 */;
    object-fit: fill;
}
.flex-sb{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.width-65{
    width: 65%;
}
.color-hui{
    color: rgb(156, 156, 156);
}
.font-12{
    font-size: .26rem /* 13/50 */;
}
.color-lv{
    color: #1ABC9C;
}

.flex-fd{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.flex-st{
    display: flex;
    justify-content: flex-end;
}
.padding-top-5 {
    padding-top: .1rem /* 5/50 */;
}

.numbox input{
    width: .9rem;
    height:  .7rem;
    border-top:1px solid rgb(187, 186, 186);
    border-bottom:1px solid rgb(187, 186, 186);
    vertical-align: top;
    
}
.numbtn{
    width:  .8rem;
    height:  .7rem;
    border: 1px solid rgb(187, 186, 186);
    background-color: rgb(247, 247, 247);
    font-size: .4rem /* 20/50 */;
    color: rgb(138, 138, 138);
    line-height: 0.7rem;
    
}
.duihuan{
    position: absolute;
    bottom: 1rem /* 50/50 */;
    width: 80%;
    margin: 0 .8rem /* 40/50 */;
    background-color: #1ABC9C;
    text-align: center;
    line-height: .8rem /* 40/50 */;
    border-radius: 0.4rem;
    color: white;
}
.number{
    padding-left: .3rem /* 15/50 */;
}


</style>
