import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import Router from 'vue-router'
// import VueClipboard from 'vue-clipboard'
// Vue.use(VueClipboard)
// import Mui from 'vue-awesome-mui'
// import 'vue-awesome-mui/mui/dist/css/mui.css'
// Vue.use(Mui)

// 跨域请求  多个地址
Vue.prototype.requestUrl = window.baseUrl;
Vue.prototype.$http = axios;
axios.defaults.crossDomain = true;

var httpUrl = window.baseUrl.url;  
axios.interceptors.request.use((config) => {
    var url = [httpUrl, config.url].join("");
    config.url = url;
    return config;
});

// 防止路由重复  Avoided redundant navigation to current
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 引入全局css样式
import './assets/css/common.css'
import './assets/css/themeColor.css'

import VueDirectiveTouch from 'vue-directive-touch'
Vue.use(VueDirectiveTouch)
// 引入组件
import {Grid,GridItem,Swipe,SwipeItem,NoticeBar,Icon,Area,Picker,Popup,Toast,DatetimePicker,Dialog, Tab, Tabs,List,Uploader,Switch,Calendar,Circle,Progress,Overlay,Sticky} from 'vant';
Vue.use(Grid).use(GridItem).use(Swipe).use(SwipeItem).use(Switch).use(Calendar).use(Progress);
Vue.use(NoticeBar).use(Icon).use(Area).use(Picker).use(Popup).use(Circle).use(Overlay).use(Sticky);
Vue.use(Toast).use(DatetimePicker).use(Dialog).use(Tab).use(Tabs).use(List).use(Uploader);

// 引入js
import publicJs from '../public/public.js'
import vueConfig, { configureWebpack } from '../vue.config'
import themeColorImg from './assets/js/themeColorImg.js'
Vue.prototype.themeColorImg = themeColorImg;
Vue.config.productionTip = false;

Vue.prototype.public = publicJs;

Vue.prototype.$axios = axios;
// 全局消息订阅发布
Vue.prototype.bus = new Vue();

import wx from 'weixin-js-sdk'
Vue.prototype.wx = wx;

import echarts from 'echarts'
Vue.prototype.$echarts = echarts;

// axios.default.baseURL='/api';
// store.state.domain = 'https://yjcapi.yaojingling.cn/';

//打包时使用域名
// axios.defaults.baseURL = 'http://test.shop.yaojingling.cn:8096';
// axios.defaults.baseURL="http://"+location.host;
// console.log(axios.defaults.baseURL);
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


//  (function () {
//      var src = '//cdn.jsdelivr.net/npm/eruda';
//      // if (!/eruda=true/.test(window.location) && localStorage.getItem('active-eruda') != 'true') return;
//      document.write('<scr' + 'ipt src="' + src + '"></scr' + 'ipt>');
//      document.write('<scr' + 'ipt>eruda.init();</scr' + 'ipt>');
//  })();

// 全局前置路由守卫---初始化的时候被调用、每次路由切换的时候被调用
router.beforeEach((to, from, next) => {
  // 标题
  document.title = to.meta.title?to.meta.title:window.baseUrl.titleName;
  var config = sessionStorage.getItem("orgWebconfig");
  if(config && config!="undefined"){    // 没有webconfig，则请求一次，有值就不请求
	  config = JSON.parse(config);
	  if(config.AppTheme){  // 后台设置了主题色
		// console.log(orgWebconfig.SkinStyle);
		themeColorImg.themeImg(config.AppTheme);
		var style = document.createElement('style');
		style.innerText = ":root{--theme-color:"+config.AppTheme+";}"
		document.head.appendChild(style);
	  }else{  // 后台没有设置主题色
		var style = document.createElement('style');
		style.innerText = ":root{--theme-color:#D5251D;}"
		document.head.appendChild(style);
		themeColorImg.themeImg("#D5251D");
	  }
  }else{
	   publicJs.getWebconfig().then(res => {
		 // console.log(res);
		 var orgWebconfig = res.data.rows;
		 var orgWebconfig1 = JSON.stringify(orgWebconfig);
		 sessionStorage.setItem("orgWebconfig",orgWebconfig1);
		 // console.log(orgWebconfig,"----------");
		 if(orgWebconfig.AppTheme){  // 后台设置了主题色
		   // console.log(orgWebconfig.SkinStyle);
		   themeColorImg.themeImg(orgWebconfig.AppTheme);
		   var style = document.createElement('style');
		   style.innerText = ":root{--theme-color:"+orgWebconfig.AppTheme+";}"
		   document.head.appendChild(style);
		   
		 }else{  // 后台没有设置主题色
		   var style = document.createElement('style');
		   style.innerText = ":root{--theme-color:#D5251D;}";
		   document.head.appendChild(style);
		   themeColorImg.themeImg("#D5251D");
		 }
	   }).catch(err => {
		 // console.log(err);
	   })
  }
  
  // console.log(sessionStorage.getItem('member_token'));
  //登陆后给路径添加a=a,用途：小程序分享判断token
  if (!to.query.a) {
    var token = sessionStorage.getItem('member_token');
    if(!token){
      next({
        path: to.path,
        query: {
          ...to.query,
          a: 'a'
        }
      })
    }
   
  }
  // if(to.path == '/'){
  //   next()
  // }
  next();
})

// 跳转新页面，回到顶部
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
  
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
