<template>
  <div class="app">
	<div class="ymz-tit">关注【药盟主】公众号，查看更多活动咨询</div>
	<div class="ymz-sub">
		<span>点它</span>
		<img src="@/assets/images/pages/ic-arrow-r.png" alt="">
		<img src="@/assets/images/pages/ic-arrow-r.png" alt="">
		<span class="name" @click="handleFollow">药盟主</span>
		<span>2023-09-11 15:20</span>
	</div>
	  
	<div class="ymz-tips">
		<img src="@/assets/images/pages/ic-arrow-d.png" alt="">
		<img src="@/assets/images/pages/ic-arrow-d.png" alt="">
		<span>点击下图， 一键关注</span>
		<img src="@/assets/images/pages/ic-arrow-d.png" alt="">
		<img src="@/assets/images/pages/ic-arrow-d.png" alt=""> 
	</div>
	<div class="ymz-official">
		<div class="ymz-official-info" @click="handleFollow">
			<img class="logo" src="@/assets/images/pages/ic_logo.png" alt="">
			<div class="tit">
				<span>药盟主</span>
				<span class="sub">B2B医药控销供应链平台</span>
			</div>
			<img class="arrow" src="@/assets/images/icon_jiantou.png" alt="">
		</div>
		<div class="ymz-txt">公众号</div>
	</div>
	<div class="ymz-img">
		<img src="@/assets/images/pages/ymz.gif" alt="">
	</div>
	
	<div class="ymz-read">阅读 2万+</div>
	<div class="ymz-footer">
		<!-- <img src="@/assets/images/pages、ic_logo.png" alt="">
		<span>药盟主</span>
		<button>+关注</button> -->
	</div>
  </div>
</template>

<script>
export default {
  name: 'app',
  data(){
	  return{
		  uinCode: "3894710349", //公众号 uin源码
		  uinBase64: "Mzg5NDcxMDM0OQ==", //公众号 uin编码
		  appId: "wxd1b24cad4f8a76c2", //公众号appid
	  }
  },
  computed:{
	 
  },
  created() {
  },
  methods:{
	  /**
	   * 关注公众号
	   */
	  handleFollow(){
		let url = "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz="+this.uinBase64+"&scene=110#wechat_redirect"
		//跳转
		// window.location.href = url;
		//跳转，不保留历史消息
		window.location.replace(url);
	  }
	  
  }
}
</script>

<style scoped>
	.ymz-tit{
		padding: 20px 15px 10px 15px;
		font-size: 23px;
		color: #000;
	}
	.ymz-sub{
		padding: 0 15px;
		font-size: 14px;
		color: #999;
		display: flex;
		align-items: center;
	}
	.ymz-sub img{
		width: 20px;
		height: 18px;
	}
	.ymz-sub span{
		margin-right: 5px;
	}
	.ymz-sub .name{
		color: #19AFEF;
		margin: 0 10px;
		font-size: 16px;
	}
	.ymz-tips{
		padding-top: 30px;
		font-size: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.ymz-tips img{
		width: 18px;
		height: 18px;
	}
	.ymz-tips span{
		margin: 0 5px;
	}
	.ymz-official{
		margin: 0 15px;
		background: #f7f7f7;
		border-radius: 10px;
	}
	.ymz-official .ymz-official-info{
		position: relative;
		display: flex;
		align-items: center;
		margin: 0 15px;
		padding: 10px 0;
		border-bottom: 1px #eee solid;
	}
	.ymz-official .ymz-official-info .logo{
		width: 40px;
		height: 40px;
		margin-right: 10px;
	}
	.ymz-official .ymz-official-info .tit{
		display: flex;
		flex-direction: column;
		font-size: 18px;
	}
	.ymz-official .ymz-official-info .tit .sub{
		color: #666;
		font-size: 14px;
	}
	.ymz-official .ymz-official-info .arrow{
		position: absolute;
		width: 15px;
		height: 20px;
		right: 0;
	}
	.ymz-official .ymz-txt{
		padding: 10px 15px;
		font-size: 14px;
		color: #999;
	}
	.ymz-img{
		padding: 0 15px;
	}
	.ymz-img img{
		width: 100%;
	}
	.ymz-read{
		color: #999;
		font-size: 16px;
		margin-top: 50px;
		padding: 0 15px;
	}
	.ymz-footer{
		display: flex;
		align-items: center;
		padding: 15px;
		font-size: 15px;
		border-bottom: 30px #f7f7f7 solid;
	}
	.ymz-footer img{
		width: 30px;
		height: 30px;
		margin-right: 5px;
	}
	.ymz-footer button{
		width: 50px;
		height: 25px;
		color: #fff;
		border-radius: 5px;
		background: #07C160;
		margin-left: 5px;
		font-size: 13px;
	}
	
</style>
