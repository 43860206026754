<template>
  <div class="container">
    <!-- 顶部切换 -->
    <div class="nav-box flex-wrap" :class="hightShow ? 'hight-line' : ''" :style="{top:topHeight+'rem'}">
      <div class="nav-item" @click="tabChange('coupon')" :class="tabType == 'coupon' ? 'active' : ''" >
        <div >
          优惠卷{{ youHuiTag }}
          <span>
            <img
              v-if="tabType != 'coupon'"
              class="icon-up"
              src="@/assets/images/icon-up01.png"
              alt=""
            />
            <img
              v-if="tabType == 'coupon'"
              class="icon-up"
              src="@/assets/images/icon-up02.png"
              alt=""
            />
          </span>
        </div>
        <div :style="{ display: youHui ? 'block' : 'none' }">
          <div
            class="chailing"
            status="10"
            style="color: #333;"
            @click="youHuiBtn('10')"
          >
            优惠券(待使用)
          </div>
          <div
            class="chailing"
            status="20"
            style="color: #333;"
            @click="youHuiBtn('20')"
          >
            优惠券(已使用)
          </div>
          <div
            class="chailing"
            status="30"
            style="color: #333;"
            @click="youHuiBtn('30')"
          >
            优惠券(已过期)
          </div>
        </div>
      </div>
      <div class="nav-item" @click="tabChange('picket')"  :class="tabType == 'picket' ? 'active' : ''">
        <div >
          红包{{ hongBaoTag }}
          <span>
            <img
              v-if="tabType != 'picket'"
              class="icon-up"
              src="@/assets/images/icon-up01.png"
              alt=""
            />
            <img
              v-if="tabType == 'picket'"
              class="icon-up"
              src="@/assets/images/icon-up02.png"
              alt=""
            />
          </span>
        </div>
        <div :style="{ display: hongBao ? 'block' : 'none' }">
          <div
            class="chailing"
            status="1"
            style="color: #333;"
            @click="hongBaoBtn('10')"
          >
            红包(待使用)
          </div>
          <div
            class="chailing"
            status="0"
            style="color: #333;"
            @click="hongBaoBtn('20')"
          >
            红包(已使用)
          </div>
          <div
            class="chailing"
            status="30"
            style="color: #333;"
            @click="hongBaoBtn('30')"
          >
            红包(已过期)
          </div>
        </div>
      </div>
      <!-- <div class="nav-item" :class=" tabType=='coupon' ? 'active':'' " @click="tabChange('coupon')"  >
                <span > 优惠券</span> 
                <span >
                  <img v-if="tabType !='coupon' " class='icon-up'  src="@/assets/images/icon-up01.png" alt="" />
                  <img v-if="tabType =='coupon' " class='icon-up'  src="@/assets/images/icon-up02.png" alt=""  />
              </span>
              <div>5632</div>
            </div>
            
            

            <div class="nav-item" :class=" tabType=='picket' ? 'active':'' " @click="tabChange('picket')" >红包

                  <span >
                  <img v-if="tabType !='picket' " class='icon-up'  src="@/assets/images/icon-up01.png" alt="" />
                  <img v-if="tabType =='picket' " class='icon-up'  src="@/assets/images/icon-up02.png" alt="" />
              </span>
            </div> -->
    </div>
    <!-- 优惠券 -->
    <div class="coupon-list" v-if="tabType == 'coupon'">
      <div  
        class="coupon-item flex-wrap"
        v-for="(item, index) in couponList"
        :key="index"
      >
        <div class="coupon-left flex-column;">
          <div class="item-price font-red">{{ item.Amount }}元</div>
          <div class="item-explain">
            订单金额满￥{{ item.MixOrderAmount }}可用
          </div>
         <div class="item-time">
            <span>有效期 </span>
            <span class="font-red">{{item.GetBeginTime?item.GetBeginTime.split(" ")[0]:item.BeginTime.split(" ")[0]}}</span>
            <span> 到 </span>
            <span class="font-red" v-if="">{{item.GetEndTime?item.GetEndTime.split(" ")[0]:item.EndTime.split(" ")[0]}}</span>
          </div>
        </div>
        <div class="coupon-right flex-center" v-if="youHuiTag == '(待使用)'">
          <div
            class="recevie-but active"
            v-if="typePage == 'orderPay'"
            @click="employ(item.Id)">
            使用
          </div>
          <div class="recevie-but grey" v-else @click="employ(item.Id)">
            待使用
          </div>
        </div>
        <div class="coupon-right flex-center" v-if="youHuiTag == '(已使用)'">
          <div class="recevie-but grey">已使用</div>
        </div>
        <div class="coupon-right flex-center" v-if="youHuiTag == '(已过期)'">
          <div class="recevie-but grey">已过期</div>
        </div>
      </div>
      <!-- <div class="coupon-item flex-wrap">
                <div class="coupon-left flex-column;">
                    <div class="item-price font-red">10元</div>
                    <div class="item-explain">订单金额满￥100可用</div>
                    <div class="item-time">
                        <span>有效期</span>
                        <span class="font-red">2020-12-01</span>
                        <span>到</span>
                        <span class="font-red">2020-12-02</span>
                    </div>
                </div>
                <div class="coupon-right flex-center">
                    <div class="recevie-but gray">已领取</div>
                </div>
            </div> -->
    </div>
    <!-- 红包 -->
    <div class="red-packet" v-if="tabType == 'picket'">
      <div
        class="packet-item flex-wrap"
        v-for="(item, index) in readPacketList"
        :key="index"
      >
        <img
          class="packet-img"
          src="@/assets/images/mine/hongbao.png"
          alt="红包"
        />
        <div class="packet-info">
          <div class="limit-price font-red">￥{{ item.Moneys }}</div>
          <div class="info-limit flex-sp">
            <span class="limit-tit">最低消费￥{{ item.MinMoneys }}可用</span>
           <div v-if="hongBaoTag == '(待使用)'||hongBaoTag ==''">
              <span
              class="info-but active"
              v-if="typePage == 'orderPay'"
              @click="employRedPack(item.Id)"
              >使用</span>
            <span class="info-but grey" v-else @click="employRedPack(item.Id)"
              >待使用</span >
            </div>
            <div class="info-but grey" v-if="hongBaoTag == '(已使用)'&&hongBaoTag !=''">已使用</div>
            <div class="info-but grey"  v-if="hongBaoTag == '(已过期)'&&hongBaoTag !=''">已过期</div>
          </div>
          <span class="packet-time"
            >使用日期：{{ item.StarTime }}/{{ item.EndTime }}</span
          >
        </div>
      </div>
      <!-- <div class="packet-item flex-wrap">
                <img class="packet-img" src="@/assets/images/mine/hongbao.png" alt="红包">
                <div class="packet-info">
                    <div class="info-limit flex-sp">
                        <span class="limit-tit">最低消费￥100.00可用</span>
                        <span class="limit-price font-red">￥10.00</span>
                    </div>
                    <span class="packet-time">使用日期：2020-12-09 09:00/2020-12-10 12:00</span>
                </div>
                <img class="item-apply" src="@/assets/images/mine/icon_apply.png" alt="已使用图标">
            </div> -->
    </div>
    <!-- 暂无优惠券 -->
    <div class="no-data" v-if="tabType == 'coupon' && couponList.length == '0'">
      <img src="@/assets/images/nore.png" alt="暂无数据" />
      <span>没有优惠券可以领取</span>
    </div>
    <!-- 暂无红包 -->
    <div
      class="no-data"
      v-if="
        tabType == 'picket' && readPacketList && readPacketList.length == '0'
      "
    >
      <img src="@/assets/images/nore.png" alt="暂无数据" />
      <span>没有红包可以领取</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabType: "picket",//默认展示红包
      couponList: [], // 我的优惠券列表
      readPacketList: [], //我的可用红包
      couponExtend: [], // 优惠券extend
      redPackExtend: [], // 红包extend
      hightShow: false,
      typePage: "", // 接收从订单页面传过来的值
      youHui: false,
      hongBao: false,
      youHuiTag: "",
      hongBaoTag: "",
     unused :[], //未使用且未过期
     expired : [], //未使用但过期
     used :[], //已使用
     
      topHeight: 0.9,   // 顶部距离
    };
  },
  created() {
    var that = this;
    switch (localStorage.clientType) {
        case "app":
            that.appType = "app";
            that.topHeight = 1.7;
            break;
		case "ios":
		    that.appType = "ios";
		    that.topHeight = 1.7;
		    break;
        case "applets":
            that.appType = "applets";
            that.topHeight = 0.9;
            break;
        case "weixin":
            that.appType = "weixin";
            that.topHeight = 0.9;
            break;
        default:
            that.topHeight = 0.9;
			break;
    }
    that.typePage = that.$route.query.type;
    //我的优惠券
    that.getMyCoupon();
    //我的红包
    that.getUserRedPacketList();
  },
  methods: {
    // 使用红包
    employRedPack(id) {
      var that = this;
      var coupon = {};
      console.log(that.redPackExtend);
      that.readPacketList.forEach((item, index) => {
        if (item.Id == id) {
          item.couponName = "红包";
          that.$store.commit("setCouponItem", item);
          that.$store.commit("setCouponExtend", that.redPackExtend);
          that.$router.go(-1);
        }
      });
    },
    // 使用优惠券
    employ(id) {
      var that = this;
      var coupon = {};
      that.couponList.forEach((item, index) => {
        if (item.Id == id) {
          item.couponName = "优惠券";
          that.$store.commit("setCouponItem", item);
          // console.log(that.couponExtend);
          that.$store.commit("setCouponExtend", that.couponExtend);
          that.$router.go(-1);
        }
      });
    },
    // 我的优惠券
    getMyCoupon() {
      var that = this;
      var data = {
        isShowGet: true,
      };
      that.public.request(
        "post",
        "/api/Coupon/QueryUserAvailableCoupon",
        data,
        (res) => {
          if (res.data.errorCode == "00") {
            // console.log(that.$store);
            if (that.typePage == "orderPay") {
              that.couponList = that.$store.state.couponList;
            } else {
              that.couponList = res.data.rows;
            }

            that.couponExtend = res.data.extend;
          }
        },
        "get"
      );
    },
    // tab切换
    tabChange(tab) {
      let that = this;

      that.tabType = tab;

      if (tab == "coupon") {
        that.hightShow = !that.hightShow;
        that.youHui = that.hightShow;
        that.hongBao = false;

        // that.getMyCoupon();
      } else if (tab == "picket") {
        that.hightShow = !that.hightShow;
        that.hongBao = that.hightShow;
        that.youHui = false;
        // that.getUserRedPacketList();
      }
    },
    // 选择优惠卷
    youHuiBtn(id) {
      var that = this;
     ;
      if (id == "10") {
        that.youHuiTag = "(待使用)";
      } else if (id == "20") {
        that.youHuiTag = "(已使用)";
      } else if (id == "30") {
        that.youHuiTag = "(已过期)";
      }
      that.getChooseYouHui(id);
    },
    // 优惠卷
    getChooseYouHui(id) {
 
      var state = Number(id);
      var that = this;
      that.public.request(
        "post",
        "api/coupon/GetMemberCouponList?" + "status=" + state,
        {},
        (res) => {
          that.hightShow = false;
          that.couponList = res.data.rows;
        }
      );
    },

    // 选择红包
    hongBaoBtn(id) {
      var that = this;
      if (id == "10") {
        that.hongBaoTag = "(待使用)";
         that.readPacketList = that.unused;
      } else if (id == "20") {
        that.hongBaoTag = "(已使用)";
         that.readPacketList = that.expired;
      } else if (id == "30") {
        that.hongBaoTag = "(已过期)";
         that.readPacketList = that.used;
      }
    },

    //我的红包
    getUserRedPacketList() {
      var that = this;
      that.public.request(
        "post",
        "/api/ReadPacket/GetUseRedPacketListByCar",
        {},
        (result) => {
          // /api/ReadPacket/GetRedPacketListByUser
          if (result.data.errorCode != "00") {
            return;
          }
          if (that.typePage == "orderPay") {
            var redPackList = that.$store.state.redPackList;
          } else {
            var redPackList = result.data.rows;
          }

          var currentTime = new Date().getTime();
          for (var i in redPackList) {
            if (redPackList[i].StarTime) {
              redPackList[i].StarTime = redPackList[i].StarTime.substring(
                0,
                16
              );
            }

            if (redPackList[i].EndTime) {
              redPackList[i].End = new Date(
                redPackList[i].EndTime.substring(0, 19).replace(/-/g, "/")
              ).getTime();
              redPackList[i].EndTime = redPackList[i].EndTime.substring(0, 16);
            }
          }
          // var unused = []; //未使用且未过期
          // var expired = []; //未使用但过期
          // var used = []; //已使用
          for (var n in redPackList) {
            if (
              redPackList[n].Status == 1 &&
              redPackList[n].End >= currentTime
            ) {
              that.unused.push(redPackList[n]);
            } else if (
              redPackList[n].Status == 1 &&
              redPackList[n].End < currentTime
            ) {
              that.expired.push(redPackList[n]);
            } else {
              //已使用
              that.used.push(redPackList[n]);
            }
          }
          that.readPacketList = that.unused;
          // console.log("未使用且未过期", that.unused);
          // console.log("过期", that.expired);
          // console.log(result);
          that.redPackExtend = result.data.extend;
          // console.log(that.readPacketList);
        }
      );
    },
  },
};
</script>

<style scoped>
/* 顶部切换 */
.nav-box {
  position: fixed;
  top: 0.9rem;
  left: 0;
  width: 100%;
  height: 0.9rem;
  /* border-bottom: 0.02rem solid #eee; */
  background: #fff;
  z-index: 1;
}
.nav-box .nav-item {
  width: 50%;
  height: 100%;
  text-align: center;
  justify-content: space-around;
  line-height: 0.9rem;
  font-size: 0.3rem;
}

.nav-box .active {
  color: #199ed8;
  border-bottom: 0.02rem solid #199ed8;
  /* height: 3.9rem; */
}
.hight-line {
  height: 3.5rem !important;
}

/* 优惠券 */
.coupon-list {
  width: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
  margin-top: 1rem;
}
.coupon-list .coupon-item {
  width: 100%;
  height: 2rem;
  padding: 0.25rem 0.4rem;
  box-sizing: border-box;
  background: url("../../assets/images/bg_cupon_mycoupon.png") no-repeat;
  background-size: 100% 100%;
  margin-bottom: 0.2rem;
}
.coupon-list .coupon-item .coupon-left {
  width: 75%;
  justify-content: space-between;
}
.coupon-list .coupon-item .coupon-left .item-price {
  width: 75%;
  font-size: 0.5rem;
  text-align: center;
  font-weight: 600;
}
.coupon-list .coupon-item .coupon-left .item-explain {
  font-size: 0.24rem;
  color: #666;
}
.coupon-list .coupon-item .coupon-left .item-time span {
  font-size: 0.24rem;
}

.coupon-list .coupon-item .coupon-right {
  flex: 1;
}
.coupon-list .coupon-item .coupon-right .recevie-but {
  width: 100%;
  height: 0.6rem;
  line-height: 0.6rem;
  border-radius: 0.28rem;
  color: #fff;
  text-align: center;
  font-size: 0.3rem;
}
.coupon-list .coupon-item .coupon-right .recevie-but.active {
  background: rgb(255, 94, 0);
}
.coupon-list .coupon-item .coupon-right .recevie-but.grey {
  background: rgb(211, 210, 210);
}

/* 红包 */
.red-packet {
  width: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
  margin-top: 1rem;
}
.red-packet .packet-item {
  width: 100%;
  background: #f4da8c;
  border-radius: 0.2rem;
  box-shadow: 0 0 0.1rem #eee;
  margin-bottom: 0.2rem;
  padding: 0.2rem;
  box-sizing: border-box;
  position: relative;
}
.red-packet .packet-item .packet-img {
  width: 1rem;
  margin-right: 0.1rem;
}
.red-packet .packet-item .packet-info .info-limit .limit-tit {
  font-size: 0.28rem;
  color: #fff;
}
.red-packet .packet-item .packet-info .limit-price {
  font-size: 0.5rem;
}
.red-packet .packet-item .packet-info .info-limit .info-but {
  padding: 0 0.2rem;
  font-size: 0.28rem;
  color: #fff;
  border-radius: 0.2rem;
}
.red-packet .packet-item .packet-info .info-limit .info-but.active {
  background: #ee5519;
}
.red-packet .packet-item .packet-info .info-limit .info-but.grey {
  background: rgb(211, 210, 210);
}
.red-packet .packet-item .packet-info .packet-time {
  display: block;
  font-size: 0.26rem;
  color: #fff;
}
.red-packet .packet-item .item-apply {
  width: 1.2rem;
  position: absolute;
  top: 0.15rem;
  right: 1.6rem;
}
.icon-up {
  display: inline-block;
  width: 0.3rem;
  padding-left: 0.1rem;
}
</style>
