<template>
    <div class="container" v-if="!!pageData&&!!pageData.orderInfo">
        <!-- 订单状态 -->
        <div class="order-status bor-bot2 flex-sp">
            <span>订单状态</span>
            <span class="fontRed">{{pageData.orderInfo.AppletOrderStatusName}}</span>
        </div>
        <!-- 收货地址 -->
        <div class="address-box bor-bot2">
            <div class="address-info flex-wrap1">
                <img class="img-add" src="@/assets/images/icon_add.png" alt="地图图标">
                <div class="address-right flex-wrap">
                    <div class="use-info">
                        <div class="right-mobile flex-sp">
                            <span>收货人:{{pageData.orderInfo.ContactName}}</span>
                            <span>{{pageData.orderInfo.OrdersMobile}}</span>
                        </div>
                        <div class="add-cont">{{!!pageData.orderInfo.ReceiveXqAddress?pageData.orderInfo.Address+pageData.orderInfo.ReceiveXqAddress:pageData.orderInfo.Address}}</div>
                    </div>
                    <img class="img-r" src="@/assets/images/mine/arrow-r.png" alt="右箭头">
                </div>
            </div>
        </div>
        <!-- 订单详情 -->
        <div class="order-info bor-bot2">
            <div class="info-item">订单编号:{{pageData.orderInfo.OrderNo}}  <span style="color: red;">{{pageData.orderInfo.IsGift?"【赠品】":""}}</span></div>
            <div class="info-item">支付方式:{{pageData.orderInfo.OrderPayTypeName}}</div>
            <!-- <div class="info-item">发票类型:{{pageData.orderInfo.TmsInvoiceTypeName}}</div> -->
        </div>
		<div class="order-info bor-bot2">
			<div style="font-weight: bold;font-size: 15px;">遇到问题？</div>
			<div class="contact-box">
				<div class="contact" @click="handleContact">
					<img src="@/assets/images/concat.png" alt="">
					<span>联系客服</span>
				</div>
				<div class="contact" @click="handleApplyafter">
					<img src="@/assets/images/after.png" alt="">
					<span>售后申请</span>
				</div>
			</div>
		</div>
		
        <!-- 价格详情 -->
        <div class="price-list bor-bot2">
            <!--
                1.优惠券有值则加优惠券的值
                2.红包有值则显示加红包的值
                3.没有优惠券和红包，则显示
            -->
            <!-- <div class="price-item flex-sp">
                <span class="item-tit">订单金额</span>
                
                <span class="fontRed" v-if="pageData.orderInfo.CouponAmount>0">￥{{(pageData.orderInfo.OrderCost+pageData.orderInfo.CouponAmount + pageData.orderInfo.DiscountAmount - pageData.orderInfo.FreightAmount).toFixed(2)}}</span>
                
                <span class="fontRed" v-if="pageData.orderInfo.RedPacketAmount>0">￥{{(pageData.orderInfo.OrderCost+pageData.orderInfo.RedPacketAmount + pageData.orderInfo.DiscountAmount - pageData.orderInfo.FreightAmount).toFixed(2)}}</span>
                
                <span class="fontRed" v-if="pageData.orderInfo.CouponAmount<=0 && pageData.orderInfo.RedPacketAmount<=0">￥{{(pageData.orderInfo.OrderCost+pageData.orderInfo.RedPacketAmount + pageData.orderInfo.DiscountAmount - pageData.orderInfo.FreightAmount).toFixed(2)}}</span>
            </div> -->
            <div class="price-item flex-sp">
                <span class="item-tit">订单金额:</span>
                <span class="fontRed">￥{{orderPrice>0?orderPrice.toFixed(2):0}}</span>
            </div>
            <div class="price-item flex-sp" v-if="pageData.orderInfo.CouponAmount>0">
                <span class="item-tit">优惠券</span>
                <span class="fontRed">-￥{{pageData.orderInfo.CouponAmount}}</span>
            </div>
            <div class="price-item flex-sp" v-if="pageData.orderInfo.RedPacketAmount>0">
                <span class="item-tit">红包</span>
                <span class="fontRed">-￥{{pageData.orderInfo.RedPacketAmount}}</span>
            </div>
            <div class="price-item flex-sp" v-if="pageData.orderInfo.FreightAmount>0">
                <span class="item-tit">运费</span>
                <span class="fontRed">+￥{{pageData.orderInfo.FreightAmount}}</span>
            </div>
            <div class="price-item flex-sp">
                <span class="item-tit">优惠金额</span>
                <span class="fontRed">-￥{{pageData.orderInfo.DiscountAmount}}</span>
            </div>
             <div class="price-item flex-sp">
                <span class="item-tit">余额支付</span>
                <span class="fontRed">-￥{{pageData.orderInfo.AccountPay}}</span>
            </div>
            <div class="price-item flex-sp">
                <span class="item-tit">实付金额</span>
				 <span v-if="pageData.orderInfo.OrderType==122" class="fontRed">￥{{pageData.orderInfo.GoodsPackTotal.toFixed(2)}}</span>
                <span v-else class="fontRed">￥{{pageData.orderInfo.OrderCost>0?pageData.orderInfo.OrderCost.toFixed(2):0}}</span>
            </div>
            
        </div>
        <!-- 商品详情 -->
        <div class="good-list bor-bot2">
            <div class="good-item flex-wrap" v-for="(item,index) in pageData.orderDetails" :key="index">
                <div class="item-left">
                    <img class="left-img" :src="item.SpPic==''?'@/assets/images/index/xd3.png':item.SpPic" alt="商品图片"
                     onerror="javascript:this.src='https://goodssuffix.oss-cn-qingdao.aliyuncs.com/upload/c5c2ae08dbfb45898d64061a0d82e83e/9cc5bc0235044c54916fd18243082d10.png'" 
                    
                    >
                </div>
                <div class="item-info flex-column">
                    <div class="info-name">{{item.SpName}}</div>
                    <div class="info-specifi">{{item.Style}}/{{item.dw}}</div>
                    
                    <div class="info-company">{{item.Factory}}</div>
                    <div class="info-seck flex-sp">
                        <div class="info-price">
                            <span class="info-tit fontRed">￥ {{(item.SupplyPrice).toFixed(pointNum)}}</span>
                            <!-- <span class="info-old-price font-grey">￥ 6.00</span> -->
                        </div>
                        <div class="seck-btn">x {{item.Total}}</div>
                    </div>
                    
                </div>
            </div>
        </div>
        
        
    </div>
</template>


<script>
export default {
    data(){
        return{
            orderNo:'',
            pageData:{},
            pointNum:2,//小数点
            orderPrice:0,//订单金额
        }
    },
    created(){
        var that = this;
        // 获取url路径上面的参数
        if(location.search){
            var params = that.public.urlObj(location.href);
            if(!!params.OrderNo){
            that.orderNo=params.OrderNo;
            }
        }
        var shuju=JSON.parse(localStorage.getItem('webconfig'))
		if(shuju.GoodPriceFixed){
		  that.pointNum=shuju.GoodPriceFixed;
		}
		
    },
    mounted(){
        var that=this; 
        that.loadData();
    },
    methods:{
        loadData(){
			var that=this;
			if(!that.orderNo){
				that.$toast("订单编号无效!");
				return;
			}
			var data = {
				OrderNo: that.orderNo,
				
			}
			that.$toast.loading({
				message: '加载中',
				duration: 0
			})        
			that.public.request('post','/api/ordersInfo/queryOrdersDetail',data,result => {
                that.$toast.clear();
                var res=result.data;
                // that.orderPrice=res.extend;
				that.orderPrice = 0;
                if(res!=null && !!res.rows && res.rows.length>0){
                    that.pageData=res.rows[0];
					if(that.pageData.orderDetails && that.pageData.orderDetails.length>0){
						that.pageData.orderDetails.forEach(item => {
							that.orderPrice += item.SupplyPrice * item.Total;
						})
					}
					
					if(that.pageData.orderInfo.IsGift){//赠品  实付金额0
						that.pageData.orderInfo.OrderCost = 0;
					}else{
						//供货价+运费-相关优惠条件
						let free = that.pageData.orderInfo.FreightAmount; //运费
						let coupon = that.pageData.orderInfo.CouponAmount; //优惠券
						let redpacket = that.pageData.orderInfo.RedPacketAmount; //红包
						let discount = that.pageData.orderInfo.DiscountAmount; //优惠（折扣）
						let wallet = that.pageData.orderInfo.AccountPay; //钱包余额抵付
						that.pageData.orderInfo.OrderCost = that.orderPrice + free - coupon - redpacket - discount - wallet
					}
					
                }      
            })
        },
		
		/**
		 * 人工客服
		 */
		handleContact(){
			let client = localStorage.getItem('clientType');
			if(client == "applets" || client == "weixin"){
				this.wx.miniProgram.navigateTo({
				    url: '/pages/wxlogin/wxClient/index?orderNo=' + this.orderNo
				})
			}else{
				let webconfig =JSON.parse(localStorage.getItem('webconfig'));
				let pageUrl = "/pages/wxlogin/wxClient/index?orderNo=" + this.orderNo; //小程序界面
				if(client == "ios"){
					//ios  todo
				}else{
					// top.android.openMini(webconfig.WeixinAppId, webconfig.PrimitiveId, pageUrl); //调起app打开小程序
					top.android.WXPayOrder(pageUrl, webconfig.WeixinAppId, webconfig.PrimitiveId); //调起app，打开小程序
				}
			}
		},
		/**
		 * 申请售后
		 */
		handleApplyafter(){
			let client = localStorage.getItem('clientType');
			if(client == "applets" || client == "weixin"){
				this.wx.miniProgram.navigateTo({
				    url: '/pages/index/salesAfter/index?orderNo=' + this.orderNo
				})
			}else{
				let webconfig =JSON.parse(localStorage.getItem('webconfig'));
				let pageUrl = "/pages/index/salesAfter/index?orderNo=" + this.orderNo; //小程序界面
				if(client == "ios"){
					//ios  todo
				}else{
					// top.android.openMini(webconfig.WeixinAppId, webconfig.PrimitiveId, pageUrl); //调起app打开小程序
					top.android.WXPayOrder(pageUrl, webconfig.WeixinAppId, webconfig.PrimitiveId); //调起app，打开小程序
				}
			}
		}
		
    },
    computed:{
        // orderPrice:function(){
        //     var that = this;
        //     var orderPrice = Number(0);
        //     var pageData = that.pageData;
        //     console.log(pageData);
        //     var OrderCost = pageData.orderInfo.OrderCost;   // 实付金额
        //     var CouponAmount = pageData.orderInfo.CouponAmount;  // 优惠券
        //     var RedPacketAmount = pageData.orderInfo.RedPacketAmount;
        //     var DiscountAmount = pageData.orderInfo.DiscountAmount;  // 优惠金额
        //     var FreightAmount = pageData.orderInfo.FreightAmount;  // 运费
        //     // 优惠券有值则加优惠券的值
        //     if(CouponAmount > 0){
        //         // console.log("-------------aa");
        //         orderPrice = Number(OrderCost) + Number(CouponAmount) + Number(DiscountAmount) - Number(FreightAmount);
        //     }
        //     // 红包有值则显示加红包的值
        //     if(RedPacketAmount > 0 ){
        //         // console.log("-------------bb");
        //         orderPrice = Number(OrderCost) + Number(RedPacketAmount) + Number(DiscountAmount) - Number(FreightAmount);
        //     }
        //     // 没有优惠券和红包，则显示
        //     if(CouponAmount <= 0 && RedPacketAmount <= 0){
        //         // console.log("-------------cc");
        //         orderPrice = Number(OrderCost) + Number(RedPacketAmount) + Number(DiscountAmount) - Number(FreightAmount);
        //     }
        //     orderPrice = orderPrice.toFixed(this.pointNum);
        //     return orderPrice;
        // }
    }
}
</script>


<style scoped>
    .order-status{ width:100%;height:1rem;padding:0 0.2rem;box-sizing:border-box; }
    .order-status span{ font-size:0.3rem; }


    /* 收货地址 */
    .address-box{ width:100%;padding:0.2rem;box-sizing:border-box; }
    .address-box .address-info{ align-items:flex-start; }
    .address-box .address-info .img-add{ width:0.36rem;margin-right:0.2rem; }
    .address-box .address-info .address-right{ flex:1; }
    .address-box .address-info .address-right .use-info{ flex:1; }
    .address-box .address-info .address-right .use-info .right-mobile span{ font-size:0.3rem; }
    .address-box .address-info .address-right .add-cont{ font-size:0.28rem;color:#999; }
    .address-box .address-info .address-right .img-r{ width:0.16rem;margin-left:0.28rem; }


    /* 商品详情列表 */
    .good-list{ width:100%;padding:0.2rem 0; }
    .good-list .good-item{ width:100%;padding:0.2rem;box-sizing:border-box;border-bottom:0.2rem solid #fff;background:#f7f7f7; }
    .good-list .good-item:last-child{ border-bottom:none; }
    .good-item .item-left{ width:2.3rem;margin-right:0.12rem; }
    .good-item .item-left .left-img{ width:2rem;max-height:2rem;margin:0 auto;object-fit: contain;  }
    .good-item .item-left .item-setime { width:80%;margin:0.12rem auto 0;padding:0.02rem 0.16rem; background:#199ed8; border-radius:0.28rem; }
    .good-item .item-left .item-setime img{ width:0.22rem; height:0.22rem; margin-right:0.12rem; }
    .good-item .item-left .item-setime .settime-tit{ color:#fff; font-size:0.22rem; }

    .good-item .item-info { flex:1; box-sizing:border-box;justify-content:space-between; }
    .good-item .item-info .info-name{ width:100%; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;font-weight:600;font-size:0.3rem; }
    .good-item .item-info .info-stock .stock-tit{ font-size:0.24rem; }
    .good-item .item-info .info-stock .stock-quota{ font-size:0.24rem; }
    .good-item .item-info .info-specifi{ font-size:0.24rem; }
    .good-item .item-info .info-company{ font-size:0.28rem; width:100%; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; }
    .good-item .item-info .info-seck .info-price .info-tit{ font-size:0.36rem; }
    .good-item .item-info .info-seck .info-price .info-old-price{ font-size:0.24rem;margin-left:0.12rem;text-decoration:line-through; }
    .good-item .item-info .info-seck .seck-btn{ display:inline-block;padding:0.02rem 0.2rem;font-size:0.28rem; }


    /* 价格详情 */
    .price-list{ width:100%;padding:0.1rem 0.2rem;box-sizing:border-box; }
    .price-list .price-item{ width:100%;height:0.6rem; }
    .price-list .price-item:last-child{ border-top:0.02rem solid #eee; }
    .price-list .price-item span{ font-size:0.3rem; }
    .price-list .price-item span.item-tit{ font-size:0.32rem; }


    /* 订单详情 */
    .order-info{ width:100%;padding:0.2rem;box-sizing:border-box; }
    .order-info .info-item{ font-size:0.28rem;color:#999; }
    .fontRed{ color:var(--theme-color);}
	
	.contact-box{
		display: flex;
		padding: 10px;
		font-size: 15px;
	}
	.contact{
		display: flex;
		align-items: center;
		font-size: 15px;
		margin-right: 60px;
	}
	.contact img{
		width: 25px;
		height: 25px;
		margin-right: 5px;
	}
</style>