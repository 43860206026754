<template>
    <div>
        <!-- 商品弹窗  加入购物车 -->
        <div class="good-popup" v-if="cartShowPop" @click="closePop()" @touchmove.prevent></div>
        <div class="good-pop" v-if="cartShowPop" :style="{bottom:botDis+'rem'}">
            <div class="good-top flex-wrap">
                <img v-if="goodInfo.SpPicExtend" :src="goodInfo.SpPicExtend"    onerror="javascript:this.src='https://goodssuffix.oss-cn-qingdao.aliyuncs.com/upload/c5c2ae08dbfb45898d64061a0d82e83e/9cc5bc0235044c54916fd18243082d10.png'" />
                <img v-else-if="!goodInfo.SpPicExtend" src="@/assets/images/no_good.png" alt="">
                <div class="good-right flex-column">
                    <div class="right-name">{{goodInfo.spmch}}</div>
                    <div class="right-price flex-sp">
                        <span class="price-tit font-red">￥{{goodInfo.zdshj}}</span>
                        <span>库存：<span class="font-red">{{goodInfo.kcshl>0?(!ShowStock?goodInfo.kcshl:"有货"):"无货"}}</span></span>
                    </div>
                    <div class="good-code">商品编号：{{goodInfo.spbh}}</div>
                </div>
            </div>
            <div class="good-specifi">
                <div class="specifi-item ">
                    <div class="item-title ">
                        <span class="tit" style="display: inline-block;width:13%">规格：</span>
                         <span class="tit-parameter">{{goodInfo.shpgg}}/{{goodInfo.dw}}</span>
                         <span class="last-span">大包装：{{goodInfo.jlgg}}{{goodInfo.dw}}</span>
                        <span class="last-span">中包装：{{goodInfo.zbz}}{{goodInfo.dw}}</span>
                    </div>
                </div>
                <div class="specifi-item">
                    <div class="item-title flex-wrap">
                        <span class="tit">单位：</span>
                        <span class="tit-parameter">{{goodInfo.dw}}</span>
                    </div>
                </div>
                <div class="specifi-item">
                    <div class="item-title flex-wrap">
                        <span class="tit">生产厂商：</span>
                        <span class="tit-parameter">{{goodInfo.shengccj}}</span>
                    </div>
                </div>
                <div class="specifi-item">
                    <div class="item-title flex-wrap">
                        <span class="tit">是否可拆零：</span>
                        <span class="tit-parameter" v-if="goodInfo.OnlyPackageSold">不可拆零</span>
                        <span class="tit-parameter" v-if="!goodInfo.OnlyPackageSold">可拆零</span>
                    </div>
                </div>
                   <div class="specifi-item">
                    <div class="item-title flex-wrap">
                        <span class="tit">{{goodInfo.sxrq&&goodInfo.sxrq.indexOf()=='-1'?'效期':'近远效期'}}:</span>
                        <span class="tit-parameter" >{{goodInfo.sxrq}}</span>
                    </div>
                </div>
                <div class="specifi-item" v-if="goodInfo.ActivityCacheDataList.length>0">
                    <div class="active-item flex-wrap" v-for="(item,ind) in goodInfo.ActivityCacheDataList" :key="ind" >
                        <input v-if="ind==0" type="radio" v-model="selectId" :value="item.ActivityId"  name="activity" >
                        <input v-else v-model="selectId" :value="item.ActivityId" type="radio" name="activity" >
                        <label>{{item.Description}}</label>
                    </div>
                    
                </div>
             
                <div class="specifi-item">
                    <div class="item-title flex-sp">
                        <span class="tit">数量</span>
                        <div class="add-subtract flex-wrap">
                            <span class="add" @click="reduceNum()">-</span>
                            <input class="num" type="number" v-model="cartNum"  @click="editInput()" >
                            <!-- <span class="num">2</span> -->
                            <span class="cut" @click="addNum()">+</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="good-sure" @click="addCart()">{{btnText}}</div>
        </div>

        <!-- 编辑商品数量弹窗 -->
        <div class="popup-box" v-if="editNumShow" @touchmove.prevent></div>
        <div class="popup-cont" v-if="editNumShow">
            <div class="good-tit">编辑商品数量</div>
            <div class="good-num-box">
                <span class="reduce-g" @click="reduceNum1()">-</span>
                <input type="number"  ref="editNum" v-model="cartEditNum" @keyup.enter="sureEdit()">
                <span class="add-g" @click="addNum1()">+</span>
            </div>
            <div class="good-btn">
                <div class="btn-g" id="cancel" @click="cancelEdit()">取消</div>
                <div class="btn-g blue" id="sure" @click="sureEdit()">确认</div>
            </div>
        </div>
    </div>
    
</template>

<script>
export default {
    name: 'cartPop',
    props:{
        cartShowPop: Boolean,    // 商品弹窗显示隐藏  
        goodInfo: Object,     // 商品详情
    },
    data(){
        return {
			ShowStock: JSON.parse(localStorage.getItem("ShowStock")),
            cartNum: 1,         // 购物车数量
            selectId: '',       // 选中活动的id

            editNumShow: false,     // 编辑数字弹窗显示隐藏
            cartEditNum:1,          // 编辑弹窗输入框中的值

            botDis: 0,            // 弹窗距离底部的距离
            isPhone:false,
			
			btnText: "加入购物车"
        }
    },
    watch:{
        goodInfo:function(){
			var that = this;
            var goodInfo = that.goodInfo;
			// console.log("=====此商品id=====",goodInfo.spid)
			//创建浏览记录
			that.createHistory(goodInfo.spid?goodInfo.spid:goodInfo.GoodsId);
			
			if(goodInfo.IsBuy){
				that.btnText = "加入购物车";
			}else{
				that.btnText = "申请合作";
			}
            // console.log(goodInfo);
            var IsSpecial = sessionStorage.getItem("IsSpecial");
            if(IsSpecial){
                goodInfo.ActivityCacheDataList = [];
            }
			
			// OnlyPackageSold  true可拆零  false不可拆零
			if(goodInfo.OnlyPackageSold && goodInfo.OrderNumSetp>0){
				if(goodInfo.MinBuyCount && goodInfo.MinBuyCount>0){
					if(goodInfo.OrderNumSetp>=goodInfo.MinBuyCount){
						that.cartNum = goodInfo.OrderNumSetp;
					}else{
						that.cartNum = goodInfo.MinBuyCount;
					}
				}else{
					that.cartNum = goodInfo.OrderNumSetp;
				}
				// that.cartNum = goodInfo.OrderNumSetp;
			}else{
				// 后台没有设置最低下单量
				if(goodInfo.MinBuyCount && goodInfo.MinBuyCount>0){
					that.cartNum = goodInfo.MinBuyCount;
				}else{
					that.cartNum = 1;
				}
			}
			
            if(goodInfo.ActivityCacheDataList.length>0){
                that.selectId = goodInfo.ActivityCacheDataList[0].ActivityId;
            } 
            // 如果是首页的购物车弹窗，则修改底部距离
            if(that.$route.path == "/"||that.$route.path == "/text"){
                if(that.isPhone){
                    that.botDis = 1.25;
                }else{
                    that.botDis = 1.13;
                }
            }
        }
    },
    
    created(){
		this.appSource();
	},
    methods: {
		//添加记录浏览量
		createHistory(goodSpid){
		  this.public.request("post","api/GoodHistory/CreateHistory",{
			  SpId: goodSpid, //（商品id）
			  CustomId: localStorage.getItem("orgId")  //（登陆客户orgid）
		  },res=>{
			  if(res.data.errorCode=="00"){
				console.log("浏览记录成功!")
			  }else{
				console.log("浏览记录失败~")
			  }
		  })
		},
        //判断是不是苹果手机
        appSource() {
            const u = navigator.userAgent;
            const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isiOS) {//苹果
                return this.isPhone=true;               
            } else {
                return this.isPhone=false;
                
            }
        },
        // 编辑input数值
        editInput(){
            var that = this;
            that.editNumShow = true;
            that.$nextTick(() => {
                that.$refs.editNum.focus();
                that.$refs.editNum.select();
            })
            that.cartEditNum = that.cartNum;
            
        },
        // 取消编辑弹窗
        cancelEdit(){
            var that = this;
            that.editNumShow = false;
        },
        // 购物车数量减
        reduceNum1(){
            var that = this;
            // 中包装步长
            // OnlyPackageSold  true可拆零  false不可拆零
            if(that.goodInfo.OnlyPackageSold && Number(that.goodInfo.OrderNumSetp)>0){
                var OrderNumSetp = that.goodInfo.OrderNumSetp;
            }else{
                var OrderNumSetp = 1;
            }
			
			that.cartEditNum = Number(that.cartEditNum) - Number(OrderNumSetp);
			// 开启中包装之后，最低下单量返回0
			var MinBuyCount = that.goodInfo.MinBuyCount;
			if(MinBuyCount && MinBuyCount>0){
				if(that.cartEditNum <= MinBuyCount){
					that.$toast("小于最低下单量："+ MinBuyCount);
					that.cartEditNum = MinBuyCount;
				}
			}
            // if(Number(that.cartEditNum) > Number(OrderNumSetp)){
            //     // 最低下单量
            //     if(MinBuyCount>0){
            //         if(Number(that.cartEditNum) > Number(MinBuyCount)){
            //             that.cartEditNum--;
            //         }else{
            //             // that.$toast("小于最低下单量："+ MinBuyCount);
            //         }
            //     }else{
            //         that.cartEditNum = Number(that.cartEditNum) - Number(OrderNumSetp);
            //     }
                
            // }
        },
        // 购物车数量加
        addNum1(){
            var that = this;
            // 中包装步长
            // OnlyPackageSold  true可拆零  false不可拆零
            if(that.goodInfo.OnlyPackageSold && Number(that.goodInfo.OrderNumSetp)>0){
                var OrderNumSetp = that.goodInfo.OrderNumSetp;
            }else{
                var OrderNumSetp = 1;
            }
            // that.cartEditNum++;
            that.cartEditNum = Number(that.cartEditNum) + Number(OrderNumSetp);
        },
        // 编辑弹窗确认
        sureEdit(){
            var that = this;
            that.editNumShow = false;
            var MinBuyCount = that.goodInfo.MinBuyCount;
            console.log(that.cartEditNum);

            // 中包装步长
            // OnlyPackageSold        false不可拆零
            if(that.goodInfo.OnlyPackageSold && Number(that.goodInfo.OrderNumSetp)>0){
                var OrderNumSetp = that.goodInfo.OrderNumSetp;
                // 取余
                if(that.cartEditNum<=0){
                    that.cartEditNum=OrderNumSetp;
                    that.cartNum = that.cartEditNum;

                }else{
                     if(that.cartEditNum%OrderNumSetp==0){
                    that.cartNum = that.cartEditNum;
                    }else{
                        that.$toast("购买数量"+that.cartEditNum+"，必须为"+OrderNumSetp+"的倍数");
                        that.cartNum = that.cartNum;
                    }
                }  
            }else{
                // 设置了最低下单量
                if(MinBuyCount>0){
                    if(that.cartEditNum >= MinBuyCount){
                        that.cartNum = that.cartEditNum;
                    }else{
                        that.$toast("小于最低下单量:"+ MinBuyCount);
                        that.cartNum = that.cartNum;
                    }
                }else{
                    if(that.cartEditNum<=0){
                     that.cartEditNum=1;
                     that.cartNum = that.cartEditNum;
                    }else{
                        that.cartNum = that.cartEditNum;
                    }
                    
                }
                
            }
            that.cartEditNum = 1;
        },

        // 加入购物车
        addCart(){
            var that = this;
			
			if(!that.goodInfo.IsBuy){
				if(!that.goodInfo.BuyPhone){
				  return that.$toast("暂无联系方式");
				}
				
				that.$dialog.confirm({
				    message: "您即将拨打电话："+that.goodInfo.BuyPhone,
					confirmButtonText: "呼叫"
				}).then(() => {//confirm
					window.location.href = 'tel://' + that.goodInfo.BuyPhone
				}).catch(() => {
					//cancel
				});
				return;
			}

            // 查看是否登录
            var token = sessionStorage.getItem('member_token');
            if(token == null){
                var orgId = localStorage.getItem("orgId");
                that.$dialog.confirm({
						message: "你还未登录，是否登录!",
					}).then(() => {
						that.$router.push({
                            path: '/login',
                            query: {
                                orgId: orgId,
                                auto: 0,
                            }
                        });
					}).catch(() => {
						// console.log('取消');
					});
                return false;
            }
			
			//统计分析 埋点进来
			that.keyId = ""; //主键
			that.public.setBuryPoint(5,1,that.keyId).then(res=>{
				// if(res.errorCode=="00"){
				// 	that.keyId = res.rows;
				// }
			});

            var goodInfo = that.goodInfo;     
            var cartNum = that.cartNum;     // 购买数量
            var stock = goodInfo.kcshl;    // 库存数量
            
            var activeObj = {};
            goodInfo.ActivityCacheDataList.forEach((item,index) => {
                if(that.selectId == item.ActivityId){
                    activeObj = item;
                }
            })

            // 关闭商品弹窗
            that.$emit("diffCartShow");

            // 余数只要有值，则商品数量无效
            var num = Number(cartNum-(Number(cartNum/goodInfo.zbz)*goodInfo.zbz));
            if(num){
                that.$toast("商品数量无效！");
                return false;
            }

            var data = {
                Spid: goodInfo.spid,
                GoodsCount: cartNum,    // 购物车数量
                GoodsPrice: goodInfo.zdshj,    // 商品价格
                ReceiveId: goodInfo.OrgId,     // orgid
                OfferId: activeObj.ActivityId,
                OfferRemark: activeObj.Description,
                OfferType: activeObj.ActivityType,
            }
			
			if(goodInfo.CrossGroupId){
				data.TmsCrossGroupId = goodInfo.CrossGroupId;
			}

            var IsLowStockOrder;
            // 获取配置信息
            that.public.getOrgWebInfo(function(orgInfo){
                // console.log(orgInfo);
                IsLowStockOrder = orgInfo.IsLowStockOrder; 
            });
            
            if(Number(cartNum)>Number(stock)){
                // IsLowStockOrder  false 禁止下单， ture 可以下单
                if(!IsLowStockOrder){
                    that.$toast("库存不足");
                    return;
                }else{
                    that.$dialog.confirm({
                        message: '库存不足，将以最大库存量下单，下单即生成采购计划！',
                    }).then(() => {
                        // console.log('确定');
                        that.getAddCart(data);
                        
                    }).catch(() => {
                        // console.log('取消');
                    })
                }
            }else{
                that.getAddCart(data);
            }
        },
        getAddCart(data){
            var that = this;
            that.public.request('post','/api/SellWellCar/AddSellWellCar',data,res => {
                // console.log(res);
                if(res.data.errorCode == '00'){
                    that.$toast('添加成功');
                    that.public.getCartNum();   // 购物车数量
                    // OnlyPackageSold  true可拆零  false不可拆零
                    // if(that.goodInfo.OnlyPackageSold && Number(that.goodInfo.OrderNumSetp)>0){
                    //     that.cartNum = that.goodInfo.OrderNumSetp;
                    // }else{
                    //     that.cartNum = 1;
                    // }
                    
                    that.$emit("diffCartShow");
                }else if(res.data.errorCode == '99'){
                    that.$toast(res.data.errorMsg);
                    that.$emit("diffCartShow");
                }
            })
        },

        // 购物车弹窗显示
        cartShow(){
            var that = this;
            that.cartShowPop = true;
            // OnlyPackageSold  true可拆零  false不可拆零
            if(that.goodInfo.OnlyPackageSold && Number(that.goodInfo.OrderNumSetp)>0){
                that.cartNum = that.goodInfo.OrderNumSetp;
            }else{
                that.cartNum = 1;
            }
            
        },  
        // // 关闭购物车弹窗
        // closePop(){
        //     var that = this;
        //     that.cartShowPop = false;
        // },
        // 购物车数量减
        reduceNum(){
            var that = this;
            // 中包装步长
            // OnlyPackageSold  true可拆零  false不可拆零
            if(that.goodInfo.OnlyPackageSold && Number(that.goodInfo.OrderNumSetp)>0){
                var OrderNumSetp = that.goodInfo.OrderNumSetp;
            }else{
                var OrderNumSetp = 1;
            }
			
			if(Number(that.cartNum) > Number(OrderNumSetp)){
				that.cartNum = Number(that.cartNum) - Number(OrderNumSetp);
			}
			// 开启中包装之后，最低下单量返回0
			var MinBuyCount = that.goodInfo.MinBuyCount;
			if(MinBuyCount && MinBuyCount>0){
				if(that.cartNum < MinBuyCount){
					that.$toast("小于最低下单量："+ MinBuyCount);
					that.cartNum = MinBuyCount;
				}
			}
			
			
			
            // if(Number(that.cartNum) > Number(OrderNumSetp)){
            //     // 后台设置最低下单量
            //     if(that.goodInfo.MinBuyCount>0){
            //         if(Number(that.cartNum)>Number(that.goodInfo.MinBuyCount)){
            //             that.cartNum--;
            //         }else{
            //             that.$toast("小于最低下单量："+that.goodInfo.MinBuyCount);
            //         }
                    
            //     }else{
            //         that.cartNum = Number(that.cartNum) - Number(OrderNumSetp);
            //     }
                
            // }
        },
        // 购物车数量加
        addNum(){
            var that = this;
            // 中包装步长
            // OnlyPackageSold  true可拆零  false不可拆零
            if(that.goodInfo.OnlyPackageSold && Number(that.goodInfo.OrderNumSetp)>0){
                var OrderNumSetp = that.goodInfo.OrderNumSetp;
            }else{
                var OrderNumSetp = 1;
            }
            
            
            that.cartNum = Number(that.cartNum) + Number(OrderNumSetp);
        },

        // 关闭购物车弹窗
        closePop(){
            var that = this;
            that.$emit("diffCartShow");
        },
    }
}
</script>

<style scoped>
    /* 商品弹窗 */
    .good-popup{ position:fixed;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,0.5);z-index:1 }
    .good-pop{ position:fixed;left:0;bottom:0rem;z-index:2;background:#fff;width:100%; }
    .good-pop .good-top{ width:100%;padding:0.18rem 0.2rem;box-sizing:border-box;border-bottom:0.02rem solid #eee; }
    .good-pop .good-top img{ width:1.8rem;max-height:1.8rem;margin-right:0.2rem; }
    .good-pop .good-top .good-right{ flex:1;height:1.8rem;justify-content:space-between; }
    .good-pop .good-top .good-right .right-name{ font-size:0.3rem;font-weight:600; }
    .good-pop .good-top .good-right .right-price span.price-tit{ font-size:0.5rem; }
    .good-pop .good-top .good-right .right-price span{ font-size:0.28rem; }

    .good-pop .good-specifi{ width:100%;padding:0.18rem 0.2rem;box-sizing:border-box; }
    .good-pop .good-specifi .specifi-item{ margin-bottom:0.12rem; }
    .good-pop .good-specifi .specifi-item .item-title .tit{ font-size:0.28rem;color:#333;font-weight:600;display:block; }
    .good-pop .good-specifi .specifi-item .item-title .tit-parameter{ font-size:0.28rem;color:#999; }
    .good-pop .good-specifi .specifi-item .last-span{ margin-left:0.28rem;color:#999;font-size:0.26rem; }
    /* 商品加减 */
    .add-subtract{ flex:1;justify-content:flex-end }
    .add-subtract .add{ font-size:0.36rem;border:0.05rem solid #ff1515;color:#ff1515;border-radius:50%;display:inline-block;width:0.38rem;height:0.38rem;text-align:center;line-height:0.3rem;box-sizing:border-box; }
    .add-subtract .num{ font-size:0.3rem;margin:0 0.2rem;width: 1rem;text-align: center;border: 0.02rem solid #999;border-radius:0.12rem;line-height:inherit; }
    .add-subtract .cut{ font-size:0.36rem;background:#ff1515;color:#fff;border-radius:50%;display:inline-block;width:0.38rem;height:0.38rem;text-align:center;line-height:0.3rem; }

    .good-pop .good-sure{ width:100%;height:0.8rem;text-align:center;line-height:0.8rem;color:#fff;background: var(--theme-color);margin:0.2rem auto 0; font-size:0.3rem;}

    /* 购物车弹窗活动 */
    .good-pop .good-specifi .specifi-item .active-item{ width:100%;font-size:0.3rem;color:#ff1515;padding:0.06rem 0;box-sizing:border-box; }
    .good-pop .good-specifi .specifi-item .active-item label{ margin-left:0.2rem; }


    .good-code{ font-size:0.28rem; }

    /* 编辑商品数量弹窗 */
    .popup-box{
        position:fixed;
        left:0;
        top:0;
        right:0;
        bottom:0;
        background:rgba(0,0,0,0.4);
        z-index:11000;
    }
    .popup-cont{
        position:fixed;
        width:70%;
        padding:0.2rem 0 0;
        box-sizing:border-box;
        background:#fff;
        border-radius:0.12rem;
        left:15%;
        top:25%;
        z-index:11001;
    }
    .popup-cont .good-tit{
        width:100%;
        text-align:center;
        font-size:0.3rem;
        margin-bottom:0.2rem;
    }
    .popup-cont .good-num-box{
        width:100%;
        display:flex;
        align-items:center;
        justify-content:center;
        margin-bottom:0.1rem;
        padding: 0 0.2rem;
        box-sizing:border-box;
    }
    .popup-cont .good-num-box .reduce-g{
        font-size:0.39rem;
    }
    .popup-cont .good-num-box input{
        width:2rem;
        height:0.6rem;
        padding:0.1rem;
        margin:0 0.12rem;
        text-align:center;
        border: 0.02rem solid #c9c4c4;
        border-radius:0.12rem;
        font-size:0.3rem;
    }
    .popup-cont .good-num-box .add-g{
        font-size:0.39rem;
    }
    .popup-cont .good-btn{
        width:100%;
        height:50px;
        display:flex;
        align-items:center;
    }
    
    .popup-cont .good-btn .btn-g{
        width:50%;
        font-size:0.3rem;
        text-align:center;
        color:#999;
    }
    .popup-cont .good-btn .btn-g.blue{
        color:#58A0F2;
    }
</style>