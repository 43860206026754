const isProduction = process.env.NODE_ENV === 'production';

module.exports = {
    // publicPath: process.env.NODE_ENV === 'production' ? './' : './',
    // 基本路径 baseURL已经过时
    publicPath: './',
    
    // 构建时的输出目录
    outputDir: "dist",
    // 放置静态资源的目录
    assetsDir: "static",
    // 指定生成的 index.html 的输出路径 (相对于 outputDir)
    indexPath: 'index.html',
    productionSourceMap: false,
    // devServer:{
    //     host: 'localhost',
    //     port: 8080,
    //     proxy:{
    //         '/api':{
    //             target: 'http://192.168.3.13:9034', //要访问的跨域的api的域名
				// ws: true, //是否启用websockets
				// secure:false, //使用的是http协议则设置为false，https协议则设置为true
    //             changeOrigin: true,
    //             pathRewrite: {
    //                 "^/api": "/api"
    //             }
    //         }
    //     },
    //     // https:true,
    //     // before: app => {}
    // },

    // 配置webpack
    configureWebpack: config => {
        if (isProduction) {
            // 开启分离js
            config.optimization = {
                runtimeChunk: 'single',
                splitChunks: {
                    chunks: 'all',
                    maxInitialRequests: Infinity,
                    minSize: 20000,
                    cacheGroups: {
                        vendor: {
                            test: /[\\/]node_modules[\\/]/,
                            name (module) {
                                // get the name. E.g. node_modules/packageName/not/this/part.js
                                // or node_modules/packageName
                                const packageName = module.context.match(/[\\/]node_modules[\\/](.*?)([\\/]|$)/)[1]
                                // npm package names are URL-safe, but some servers don't like @ symbols
                                return `npm.${packageName.replace('@', '')}`
                            }
                        }
                    }
                }
            };
        }
        config.devtool ="source-map";
    }
}