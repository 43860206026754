<template>
	<div class="sign-content">
		<!-- <div class="title">签到人信息统计</div> -->
		<div class="over-box">
			<span>活动结束时间：</span>
			<input class="date-ipt" type="date" v-model="cDate" @input="handlerDate">
			<input class="time-ipt" type="time" v-model="pTime" @input="handlerTime">
			<!-- <span class="date none" @click="showCalendar = true">{{cDate?cDate:"请选择时间"}}</span>
			<span class="time none" @click="showTime = true">{{pTime?pTime:"请选择时间"}}</span> -->
			<span class="btn" @click="submitConirm">确定</span>
			
		</div>
		<table border>
			<thead>
				<td>序列</td><td>机构名称</td><td>签到人</td><td>签到礼品</td><td>现场礼品</td><td>积分</td>
			</thead>
			<tr v-for="(item,i) in signList">
				<td class="step">{{i+1}}</td>
				<td class="txt">{{item.OrgName}}</td>
				<td class="txt">{{item.LinkName}}</td>
				<td class="img">
					<img :src="item.GiftSign.GiftImg" alt="">
					<div>{{item.GiftSign.GiftName}}{{item.GiftSign.Count}}{{item.GiftSign.Unit}}</div>
				</td>
				<td class="img">
					<div v-for="gift in item.GiftList">
						<img :src="gift.GiftImg" alt="">
						<div>{{gift.GiftName}}{{gift.Count}}{{gift.Unit}}</div>
					</div>
				</td>
				<td class="txt">{{item.Point}}</td>
			</tr>
		</table>
		
		<!-- <van-calendar v-model:show="showCalendar" :show-confirm="false" @confirm="onDateConfirm" />
		<van-popup v-model:show="showTime" position="bottom" :style="{ height: 'auto' }" >
			<van-datetime-picker
			  type="time"
			  title="时间选择"
			  :min-hour="0"
			  :max-hour="23"
			  @confirm="onTimeConfirm"
			  @cancel="showTime = false"
			/> 
		</van-popup> -->
	</div>
</template>


<script>
	import {Toast, Dialog} from "vant"
	export default {
		name: 'app',
		components: {
			Toast, [Dialog.Component.name]: Dialog.Component
			// HelloWorld
		},
		data(){
		  return{
			  activeId: "",
			  signList: [],
			  
			  showCalendar: false,
			  cDate: "",
			  showTime: false,
			  pTime: "",
		  }
		},
		
		created() {
			this.activeId = this.getUrlKey("Id");
			// this.activeId = "71596b59b2134b61aa3e24e6fd8905dd"
			this.querySignInfo();
		},
		methods: {
			querySignInfo(){
				let that = this;
				this.public.request("post","api/gameactive/QueryCenter",{
					Id: this.activeId,
					orgId: this.public.getConfig.defaultOrgId,
				},res=>{
					if(res.data.errorCode == "00"){
						that.signList = res.data.rows;
						if(res.data.TagData){
							let tagDate = res.data.TagData.ActiveEndDate;
							that.cDate = tagDate.split(" ")[0];
							that.pTime = tagDate.split(" ")[1];
						}
					}else{
						that.$toast(res.data.errorMsg)
					}
				})
				
			},
			
			//获取url 参数
			getUrlKey(name){
			    return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
			},
			
			handlerDate(value){
				console.log("aaaaaaaaa==",this.cDate)
			},
			handlerTime(value){
				this.pTime = this.pTime+":00";
				console.log("bbbbbbbbb==",this.pTime)
			},
			/* 选择日期 */
			onDateConfirm(value){
				const formatDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
				this.showCalendar = false;
				this.cDate = formatDate(value);
				this.showTime = true;
			},
		  
			/* 选择时间 */
			onTimeConfirm(value){
				this.showTime = false;
				this.pTime = value+":00";
			},
			/* 确定提交 */
			submitConirm(){
				let that = this;
				if(!this.cDate){
					return Toast("请选择日期")
				}
				if(!this.pTime){
					return Toast("请选择时间")
				}
				let dateTime = this.cDate + " " + this.pTime;
				// dateTime = dateTime.replace(/-/g,"/");
				this.public.request("post","api/gameactive/SaveEndData",{
					orgId: this.public.getConfig.defaultOrgId,
					ActiveId: this.activeId,
					ActiveEndDate: dateTime,
				},res=>{
					if(res.data.errorCode == "00"){
						that.$toast("保存成功");
					}else{
						that.$toast(res.data.errorMsg)
					}
				})
			}
		},
		
	}
</script>

<style scoped>
	.sign-content{
		padding: 30px 5%;
	}
	.title{
		position: fixed;
		width: 100%;
		height: 0.8rem;
		left: 0;
		top: 0;
		line-height: 0.8rem;
		font-size: 0.3rem;
		text-align: center;
		z-index: 999999;
		color: #fff;
	}
	.over-box{
		padding: 10px 0 20px 0;
		font-size: 0.2rem;
		display: flex;
		align-items: center;
	}
	.over-box .date-ipt{
		width: 1.6rem;
	}
	.over-box .time-ipt{
		width: 1rem;
		margin-left: 20px;
	}
	.over-box .none{
		color: #999;
	}
	.over-box .date{
		margin: 0 0.1rem;
	}
	.over-box .time{
		display: inline-block;
		min-width: 1.2rem;
	}
	.over-box .btn{
		margin-left: 0.5rem;
		background: #00a2ea;
		font-size: 0.15rem;
		padding: 5px 0.5rem;
		color: #fff;
		border-radius: 3px;
	}
	table{
		position: relative;
		width: 100%;
		text-align: center;
		border: 1px solid #eee;
		font-size: 0.18rem;
	}
	thead{
		font-weight: bold;
		font-size: 0.2rem;
	}
	td{
		position: relative;
		padding: 20px 0;
	}
	td.step{
		width: 5%;
		font-weight: bold;
	}
	td.txt{
		width: 15%;
	}
	td.img{
		width: 26%;
	}
	img{
		width: 70%;
		height: 120px;
		margin: 0 auto;
		margin-bottom: 10px;
	}
	
</style>
