<template>
    <div class="SinglBox">
    <!-- 图片轮播 -->
       <div class="seck-swiper">
           <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
               <div v-for="(item,index) in bannerList" :key="index">
                   <van-swipe-item v-if="bannerList.length>0">
                       <div class="item-img">
                           <img :src="item.ImgUrl" alt="">
                       </div>
                   </van-swipe-item>
               </div>
               <van-swipe-item v-if="bannerList.length<=0 && bannerShow">
                   <div class="item-img">
                       <img src="@/assets/images/near.png" alt="">
                   </div>
               </van-swipe-item>
               
           </van-swipe>
       </div>
       <div class="SinglList">
           <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :immediate-check='false'>
                 <shopList :list='SinglList' :active='1' @toDetail='toGoodsDetail' @onCartShow='onCartShow'></shopList>
           </van-list>
       </div>
       <activeCart 
        :cartShowPop="cartShowPop"
        :goodSpid="goodSpid"
        :goodDetail="goodDetail"
        :typePage="typePage"
        @diffCartShow="closeCart"></activeCart>
    </div>
</template>

<script>
import activeCart from "../../components/activeCartPop.vue";
import shopList from "../../components/shopList.vue"
export default {
     components: { activeCart, shopList},
    data () {
        return {
            SinglList:[],
            loading: false,
            finished: false,
            records:0,
            page:1,
            rows:10,
            bannerShow:false,
            bannerList:[],
            minBay:1,//最购买量
            isSingles:true,
            cartShowPop: false, // 组件显示隐藏
            goodSpid: "", // 向组件传递的spid
            goodDetail: {}, // 向组件传递商品详情
            typePage: "", // 向组件传递页面类型
            token:sessionStorage.getItem('member_token'),
        }
    },
    created () {
        this.getBannerImg();
        this.getList();
    },
    methods: {
        getList(){
            var that=this;
            let data={
                page:that.page,
                rows:that.rows,
            }
            if(that.page=='1'){
                that.$toast.loading({
					message: "加载中...",
					duration: 0,
				});
            }
            that.public.request('post','api/SingleProductMail/QueryList',data,res=>{
                that.$toast.clear();
                 if(res.data.errorCode=='00'){
                    that.SinglList=[...that.SinglList,...res.data.rows];
                    that.records=res.data.records;
                 }else{
                    that.$toast(res.data.errorMsg);
                 }
            });
        },
        // 下拉加载
        onLoad(){
             var that=this;
             setTimeout(() => {
                 // 数据全部加载完成
                if(that.SinglList.length==that.records){//加载完成
                   this.finished = true; 
                }else{//继续加载
                        that.page=that.page+1;
                        that.getList();//获取数据 的数据请求
                 }
             }, 500);
        },
        // banner图
        getBannerImg(){
            var that = this;
            var data = {
                Code:'20013'
            };
            that.public.request("post","/api/advertItem/queryAdvertItem",data,res=>{
                 that.bannerList = res.data.rows;
            });
             that.bannerShow = true;
        },
        //  跳转详情
      toGoodsDetail(item) {
         var that = this;
        //  that.$router.push({
        //     path: "/goodsDetail",
        //     query: {
        //         spid: item.spid,
        //         type:'Single'
        //       },
        //     });
        // },
        this.$router.push({
             path: "/activeDetail",
            query: {
                type: 'Single',
                spid: item.GoodsId,
                id: item.Id,
            },
           });
        },
          // 商品弹窗隐藏
        closeCart() {
        var that = this;
        that.cartShowPop = false;
        },
        onCartShow(id,it,item,){
            var that = this;
             if (that.token == null) {
                var orgId = localStorage.getItem("orgId");
                that.$dialog
                .confirm({
                    message: "你还未登录,是否登录!",
                })
                .then(() => {
                    that.$router.push({
                    path: "/login",
                    query: {
                        orgId: orgId,
                    },
                    });
                })
                .catch(() => {
                    // console.log('取消');
                });
                return false;
            }
            that.cartShowPop = true;
            that.goodSpid=item.GoodsId;
            that.goodDetail=item;
            that.typePage='Single';
        },
    }

}
</script>

<style>

</style>