<template>
  <div class="containerTpl" :style="'background:url('+ images.pageBgImage + ') no-repeat'">
    <div class="wheel-pad">
      <!-- 转盘 -->
      <div id="wheelBox" :style="'background:url('+ images.backgroundImage + ') no-repeat'" >
        <ul id="wheelList">
          <li v-for="(item,index) in prizeData" :key="index" :style="{webkitTransform: 'rotate(' + -item.angle + 'deg)'}" >
            <div class="prize-box">
              <img class="prizeimg" v-if="item.url" :src="item.url" />
              <div class="text" v-if="item.prizeName">{{item.prizeName}}</div>
            </div>
            <div class="line">
              <img :src="images.lineImage" />
            </div>
            
          </li>
        </ul>
        <img :src="images.btnImage" @click="startPlay" class="turnBtn"/>
      </div>
      <!-- 抽奖次数 -->
      <div class="luck-draw">您还剩余抽奖次数：{{times}}次</div>
      <!-- 活动规则 -->
      <div class="activity-rule">
        <div class="rule-tit">活动规则</div>
        <div class="rule-box">
          <span>1.活动时间：；</span>
          <span>2. 用户登陆即送一次抽奖机会；</span>
          <span>3. 奖品使用规则请在"我的-中奖记录"中查看；</span>
          <span>4. 活动期间，用户分享好友成功即获得一次抽奖机会；</span>
          <span>5. 活动期间，用户邀请好友成功即获得一次抽奖机会；</span>
          <span>6. 活动期间，用户邀请好友超过10人抽奖机会翻倍；</span>
        </div>
      </div>
    </div>

    <!-- 没抽中弹窗 -->
    <div class="participa-popup" v-if="participaShow" @click="closePart"></div>
    <div class="participa-pop" v-if="participaShow" @click="closePart">
      <img src="@/assets/images/turntable/fail.png" alt="">
    </div>
    <!-- 抽中奖项弹窗 -->
    <div class="award-popup" v-if="awardShow" @click="closeAward"></div>
    <div class="award-pop" v-if="awardShow" @click="closeAward" :style="'background: url('+awardBackground+') 50% 100% no-repeat '">
      <div class="award-tit">您将获得200元红包</div>
    </div>
    
  </div>
</template>


<script>
  export default {
    
    data(){
      return {
        images:{
          backgroundImage: require('@/assets/images/turntable/icon_bg.png'),
          lineImage: require('@/assets/images/turntable/icon_line.jpg'),
          btnImage: require('@/assets/images/turntable/icon_btn.png'),
          pageBgImage: require('@/assets/images/turntable/turntable-bg.jpeg')
        },
        awardBackground: require('@/assets/images/turntable/success-continue.png'),
        prizeData: [
          {
            id:'1',
            // url: require('@/assets/images/icon_redPack.png'),
            prizeName:'谢谢参与'
          },{
            id:'2',
            prizeName:'谢谢参与'
          },{
            id:'3',
            prizeName:'谢谢参与'
          },{
            id:'4',
            prizeName:'谢谢参与'
          },{
            id:'5',
            prizeName:'谢谢参与'
          },{
            id:'6',
            prizeName:'谢谢参与'
          }
        ],
        lineRotateZ:0,
        pIndex: 0, // 中奖物品的下标
        rotNum:  0, // 旋转圈数基数
        time: 5000, // 旋转时间
        timer: null, // 定时器
        oTurntable: '', // 旋转圆盘背景图
        type: 0, // 0 图片 1 汉字


        OrderNo: '',      // 订单编号
        AdvancePay: '',   // 是否预支付
        after:0,    // 下单前、下单后 转动次数
        times: 0,     // 转盘次数
        ids:[],       // 中奖数据的id
        prize:{},     // 中奖的奖项
        participaShow: false,     // 没有中奖的弹窗  false隐藏  true显示
        awardShow: false,     // 中奖弹窗显示  false隐藏   true显示
        
      }
    },   
    created() {
      var that = this;
      this.prizeData = this.autoRotate(this.prizeData);
      that.OrderNo = that.$route.query.OrderNo;
      that.AdvancePay = that.$route.query.AdvancePay;
      
      // 没有订单编号  下单前
      if(!that.OrderNo){
        that.after = 1;
      }else{
        that.after = 0;
      }

      that.getTurntableDetail();
    },
    //渲染完了
    mounted() {
      this.oTurntable = document.querySelector('#wheelList');
      // 过度中属性用时5s
      this.oTurntable.style.webkitTransition = 'transform ' + this.time / 1000 + 's ease';
    },
    methods:{
      // 关闭抽中奖项的弹窗
      closeAward(){
        this.awardShow = false;
        this.$router.replace({
          path: '/',
          query: {
            refresh: "refresh",
          },
        })
      },
      // 关闭没有抽中奖项的弹窗
      closePart(){
        this.participaShow = false;
        this.$router.replace({
          path: '/',
          query: {
            refresh: "refresh",
          },
        })
      },
      // 请求转盘明细
      getTurntableDetail(){
        var that = this;
        var orgId = localStorage.getItem('orgId');
        var data = {
          AfterOrder: that.after,
          orgId: orgId,
          OrderNo: that.OrderNo,
        }
        that.public.request('post','/api/Turntable/GetTurntableListByCustomerId',data,res => {
          // console.log(res);
          if(res.data.errorCode == '00'){
            var info = res.data.rows;
            var extend = res.data.extend;
            if(extend){
              // 判断转盘次数
              if(that.after == 1){  // 如果是下单前发放，默认转一次
                that.times = 1;
              }else{  // 如果是下单后发放，进行计算
                var totalMoney = extend.OrderMoney;
                var criteria = extend.Info.WhereMoney;
                that.times = parseInt(totalMoney / criteria);
                if (that.times < 1) {
                  that.$toast("不满足活动条件！");
                  return;
                }
              }
            }
            if(info == null || info == false){
              that.$toast("暂无转盘活动！");
              return;
            }
            for(var i in info){
              that.prizeData[info[i].Index -1] = {
                id: info[i].Id,
                url: require('@/assets/images/icon_redPack.png'),
                angle: that.prizeData[info[i].Index -1].angle,
              }
              that.ids.push(info[i].Id);
            }
            console.log("prizeData",that.prizeData);
            
          }
        });
      },

      // 查找转盘中谢谢参与的位置
      findEmpty(){
        var that = this;
        var prizeData = that.prizeData;
        for(var i in prizeData){
          if(prizeData[i].prizeName){
            return prizeData[i];
            
          }
        }
        

      },

      // 获取获奖数据
      getTurnResult(){
        var that = this;
        var orgId = localStorage.getItem("orgId");
        var ids = that.ids.join(",");
        
        var data = {
          AfterOrder: that.after,
          OrderNo: that.OrderNo ? that.OrderNo : '',
          OrgId: orgId,
          Ids: ids,
          AdvancePay: that.AdvancePay ? that.OrderNo : '',
        }
        
        that.public.request('post','api/Turntable/SaveTurntableItemByCustomer',data,res => {
          // console.log(res);
          if(res.data.errorCode == "01"){
            setTimeout(that.getTurnResult(),5000)
          }else if(res.data.errorCode == '00'){
            
            if(res.data.rows.Moneys==0){   // 价格为0，选中谢谢参与

              that.prize = that.findEmpty();
              
              console.log(that.prize);
            }else{  // 价格不为0，选中奖项
              that.prize = that.prizeData[res.data.rows.Index];
            }

            let prize = that.prize;
            this.startBtn(prize);
            
          }
        })
        
      },




      //自动生成角度添加到数组上
      autoRotate(arr) {
        if (arr.length) {
          let len = arr.length;
          let base = 360 / len;
          let line = base /2
          this.lineRotateZ = line
          arr.forEach((item, index) => {
            // 指的是某个奖品区域的中间 : base/2
            item.angle = 360 - (line + index * base);
          });
        }
        return arr;
      },
      // 点击开始,请求接口抽奖
      startPlay(){
        var that = this;
        if(that.times<=0){
          that.$toast("抽奖剩余次数不足");
          return false;
        }
        that.getTurnResult();
        
        
      },
      // 开始转动,通过奖项级别进行匹配:id 
      startBtn(val) {
        const self = this
        self.prizeData.forEach((i,d)=>{
          if(i.id == val.id){
            self.pIndex = d
          }
        })
        
        // 拿到相应的角度调旋转接口
        self.startrotate(self.prizeData[self.pIndex].angle, () => {
          self.fulfillHandle(self.prizeData[self.pIndex]);
        });
      },

      //开始旋转 angle角度  complete回调成功函数
      startrotate(angle, complete) {
        // 相应的角度 + 满圈 只是在原角度多转了几圈 360 * 6
        let rotate = 2160 * (this.rotNum + 1) + angle + 30;
        this.oTurntable.style.webkitTransform = 'rotate(' + rotate + 'deg)';
        clearTimeout(this.timer);
        // 设置5秒后停止旋转,处理接口返回的数据
        this.timer = setTimeout(() => {
          complete();
          this.rotNum++;
        }, this.time);
      },
      //得奖后的处理
      fulfillHandle(prize) {
        var that = this;
        that.times --;
        // 没有中奖
        if(prize.prizeName){
          setTimeout(function(){
            that.participaShow = true;
          },300)
        }else{  // 中奖了
          setTimeout(function(){
            that.awardShow = true;
          },300)
        }
        
      },
    }
  }
</script>


<style lang="less" scoped>

  .containerTpl{
    background-size:100% 100% !important;
    width:100%;
    height:100vh;
  }
  .wheel-pad{
    padding-top:3.6rem;
  }

  // 抽奖
  .luck-draw{
    width:65%;
    height:0.8rem;
    line-height:0.8rem;
    text-align:center;
    font-size:0.3rem;
    font-weight:700;
    color:#fff;
    background-color:#ff3333;
    border-radius:0.12rem;
    margin: 0.2rem auto;
  }
  // 活动规则
  .activity-rule{
    width:90%;
    background:#fff;
    border-radius:0.12rem;
    padding:0.2rem;
    box-sizing:border-box;
    margin:0 auto;
  }
  .activity-rule .rule-tit{
    width:100%;
    text-align:center;
    color: coral;
    font-size:0.32rem;
  }
  .activity-rule .rule-box span{
    display:block;
    font-size:0.24rem;
  }

  // 没抽中弹窗
  .participa-popup{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:rgba(0,0,0,0.5);
    z-index:10;
  }
  .participa-pop{
    width:80%;
    position:fixed;
    top:30%;
    left:10%;
    z-index:11;
  }
  .participa-pop img{
    width:100%;
    
  }

  // 抽中奖项弹窗
  .award-popup{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:rgba(0,0,0,0.5);
    z-index:10;
  }
  .award-pop{
    position:fixed;
    width:80%;
    top:36%;
    left:10%;
    z-index:11;
    height:3.6rem;
    margin:0 auto;
  }
  .award-pop .award-tit{
    color:#fff;
    font-size:0.32rem;
    text-align:center;
    margin-top:1.8rem;

  }


  @width:240px;
  @height:240px;
  #wheelBox {
    width: 280px;
    height: 280px;
    position: relative;
    overflow: hidden;
    text-align: center;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100% !important;
    background-position: center center;
    .turnBtn {
      position: absolute;
      width: 1rem;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-59%);
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: 100% auto;
      z-index: 3;
    }
    ul {
      background-color:#fff;
      border-radius: 50%;
      position: absolute;
      width: @width;
      height:  @height;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: 100% auto;
      li {
        position: absolute;
        box-sizing: border-box;
        text-align: center;
        // padding-top: 0.5rem;
        color: #7e250d;
        font-size: 0.3rem;
        top: 0pc;
        left: 0px;
        width: 100%;
        height: 100%;
        line-height: 20px;
        // transform-origin: 50% 50%;
        .prizeimg {
          position: absolute;
          top: 0.5rem;
          left: 59px;
          transform: rotate(-30deg);
          width:40%;
          margin:auto;
        }
        .text{
          width: 110px;
          text-align: center;
          margin: 5px auto  0 auto;
          color: #bfa74f;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          transform: rotate(-30deg);
          position: absolute;
          top: 0.6rem;
          left: 0.5rem;
          font-size:0.24rem;
        }
        .line{
          position: absolute;
          top: 0;
          display: inline-block;
          background-color: #ffffff;
          width: 2px;
          overflow: hidden;
          height: 150px;
          // transform-origin: 50% 100%;
          transform: rotateZ(0deg);
        }
      }
    }
  }

  .prize-box{
    position:absolute;
    top:0;
    left:0;
    width:45%;
    height:45%;
    // border:1px solid blue;
    transform-origin:right bottom;
  }
</style>    