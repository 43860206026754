<template>
    <div class="contain-box">
        <!-- 步骤 -->
        <!-- <div class="step-box">
            <div class="step-item">
                <div class="item-s">
                    <span class="step-line white-line"></span>
                    <span class="step-num blue">1</span>
                    <span class="step-line blue"></span>
                </div>
                <div class="step-txt blue">用户注册</div>
            </div>
            <div class="step-item">
                <div class="item-s">
                    <span class="step-line"></span>
                    <span class="step-num">2</span>
                    <span class="step-line"></span>
                </div>
                <div class="step-txt">企业信息</div>
            </div>
            <div class="step-item">
                <div class="item-s">
                    <span class="step-line"></span>
                    <span class="step-num">3</span>
                    <span class="step-line white-line"></span>
                </div>
                <div class="step-txt">开始使用</div>
            </div>
        </div> -->
        <!-- 步骤1 -->
        <div class="step1">
                <div class="input-box">
                    <img class="input-img" src="@/assets/images/reg/setPhone.png" alt="">
                    <input class="input-value" v-model="Mobile" type="number" name="Mobile" placeholder="请输入您的手机号">
                </div>
                <div class="input-code">
                    <div class="input-left">
                        <input type="number" v-model="Code" name="Code" placeholder="请输入验证码">
                    </div>
                    <button class="submit-code" :disabled="disabled" @click="getCode">{{sendTime}}</button>
                </div>

            
                <div class="input-box">
                    <input class="input-value" type="password" v-model="Password" name="Password" placeholder="登录密码">
                </div>
                <div class="input-box">
                    <input class="input-value" type="text" v-model="OrgName" name="OrgName" placeholder="请输入单位名称">
                </div>
                <div class="input-box" v-if="isShowChinaAreas"  @click="addArea = true ">

                    <img class="address-img" src="@/assets/images/icon_add1.png" alt="">
                    <div class="select-box">
                        <!-- <input class="input-value address-input" disabled v-model="areaName" type="number"  placeholder="请选择地址"> -->
                        <span v-if="!areaName" class="address-input grey">请选择地址</span>
                        <span v-if="areaName" class="address-input">{{areaName}}</span>
                        <img src="@/assets/images/mine/arrow-r.png" alt="">
                    </div>
                    
                </div>
                <div class="input-box">
                    <input class="input-value" type="text" v-model="BizAddress" name="BizAddress" placeholder="请输入单位地址">
                </div>
                <div class="input-box">
                    <input class="input-value" type="number" v-model="inviteCode" name="inviteCode" placeholder="请输入邀请码">
                </div>
                
            

                <div class="upload-box">
                    <div class="upload-txt">上传营业执照：</div>
                    <div class="upload-operate">
                        <input type="file" accept="image/*" @change="changeImg($event,'BizLicensePic')" ref="input"/>
                        <!-- <span class="select-btn" @click="upload">选择文件</span>
                        <span>未选择任何文件</span> -->
                    </div>
                </div>
                <div class="upload-box">
                    <div class="upload-txt">上传GSP证：</div>
                    <div class="upload-operate">
                        <input type="file" accept="image/*" @change="changeImg($event,'GSPLicensePic')" ref="input"/>
                        <!-- <span class="select-btn">选择文件</span>
                        <span>未选择任何文件</span> -->
                    </div>
                </div>
                <div class="upload-box">
                    <div class="upload-txt">上传许可证：</div>
                    <div class="upload-operate">
                        <input type="file" accept="image/*" @change="changeImg($event,'LicensePic')" ref="input"/>
                        <!-- <span class="select-btn">选择文件</span>
                        <span>未选择任何文件</span> -->
                    </div>
                </div>

                <button class="submit" @click="formSubmit">提交</button>
            
            
        </div>
        <van-popup v-model="addArea" round position="bottom">
            <van-picker show-toolbar title="请选择地区" value-key="Name" :columns="areaList" @change="onAreaChange" @cancel="onCancel" @confirm="onAreaConfirm"/>  
        </van-popup>
    </div>
</template>

<script>
export default {
    data(){
        return{
            formDate:{},

            disabled: false,
            sendTime: '获取验证码',

            Mobile: '',
            Code: '',

            BizLicensePic: '',
            GSPLicensePic: '',
            LicensePic: '',
            Password: '',
            OrgName: '',
            BizAddress: '',
            inviteCode: '',
            addArea: false,  //地址
            areaName: '',   // 选择的省市区 名称总和
            areaList: [{values: []}, {values: []}, {values: []}],        //自定义数据三级结构
            sureAdd: [],    // 点击确定传过来的值  选择地址
            isShowChinaAreas: false,    // true显示省市区  false隐藏省市区   禹药翁

            orgId: '',
            InviteCodeList: [],     // 邀请码
        }
    },
    created(){
        var that = this;
        that.getInviteCode();
    },
    mounted(){
        var that = this;
        that.orgId = localStorage.getItem('orgId');
        if(that.orgId == '42d0b61b7a7741fa9bb7b91d3422ee0c'){
            that.isShowChinaAreas = true;
        }
        that.getArea(1,0);      //渲染页面时请求第一层省级数据
    },
    methods:{
        formSubmit(){
            var that = this;
            
            var orgId = localStorage.getItem('orgId');
            if(!orgId){
                that.$toast('没有选择公司');
                return false;
            }
            var InviteCodeList = that.InviteCodeList;       // 邀请码
            var inviteCode = that.inviteCode;        // 输入的邀请码
            var code1 = false;
            if(inviteCode){
                InviteCodeList.forEach((item,index) => {
                    if(item.Code == inviteCode ){
                        code1 = true;
                    }
                })
            }
            if(inviteCode == ''){
                code1 = true;
            }
            if(code1 == false){
                that.$toast('邀请码无效!');
                return false;
            }
            
            
            var data = {
                Mobile: that.Mobile,
                Code: that.Code,
                Password: that.Password,
                OrgName: that.OrgName,
                BizAddress: that.BizAddress,
                inviteCode: inviteCode,
                BizLicensePic: that.BizLicensePic,
                GSPLicensePic: that.GSPLicensePic,
                LicensePic: that.LicensePic,
            }
            if(that.isShowChinaAreas){
                if(that.sureAdd.length<=0){
                    that.$toast('请选择地址');
                    return false;  
                }
                data.AreaProvince = that.sureAdd[0].Code;
                data.AreaCode = that.sureAdd[1].Code;
                data.ChainAreasCode = that.sureAdd[2].Code;
                if(!that.sureAdd[0].Code){
                    that.$toast('请选择地址');
                    return false;   
                }
                if(!that.sureAdd[1].Code){
                    that.$toast('请选择地址');
                    return false;   
                }
            }
            if(!data.Mobile){
                that.$toast('请输入您的手机号');
                return false;
            }
            if(!data.Code){
                that.$toast('请输入验证码');
                return false;
            }
            if(!data.Password){
                that.$toast('请输入密码');
                return false;
            }
            if(!data.OrgName){
                that.$toast('请输入单位名称');
                return false;
            }
            if(!data.BizAddress){
                that.$toast('请输入单位地址');
                return false;
            }
            if(!data.BizLicensePic){
                that.$toast('请上传营业执照');
                return false;
            }
            if(!data.GSPLicensePic){
                that.$toast('请上传GSP证');
                return false;
            }
            if(!data.BizLicensePic){
                that.$toast('请上传许可证');
                return false;
            }
            console.log(data);
            that.public.request('post','/api/sysUserRegister/bindAndRegisterMobile',data,res => {
                // console.log(res);
                if(res.data.errorCode == '00'){
                    that.$toast('注册成功，请登录');
                    setTimeout(function(){
                        that.$router.go(-1);
                    },2000)
                }else{
                    that.$toast(res.data.errorMsg);
                }
            })
            
        },
        // 获取验证码
        getInviteCode(){
            var that = this;
            var orgId = localStorage.getItem('orgId');
            that.public.request('get','/api/InviteCode/GetInviteCode?orgId='+orgId,{},res => {
                // console.log(res);
                if(res.data.errorCode == '00'){
                    that.InviteCodeList = res.data.rows;
                }
            })
        },
        // 请求地区数据，拼接三级联动
        getArea(parentId,index){
            var that = this;
            // console.log(parentId);
            var data = {
                AreaCode: parentId,
            }
            that.public.request('post','/api/SysOrganize/GetChild',data,res => {
                // console.log(res);
                if(res.data.errorCode == '00'){
                    const regionList = res.data.rows;
                    that.areaList[index].values = [
                        {Name:'请选择'},
                        ...regionList
                    ];
                    if(index < 2){
                        that.areaList[index + 1].values = [];
                    }
                    that.areaList = [...that.areaList];
                    // console.log(that.areaList);
                }
                
            })
        },
        // 当地区选择变化时
        onAreaChange(picker,values,index){
            // console.log(values);
            if (index < 2) {
                this.getArea(values[index].Code, index + 1);                        //传参 参数为上层选择的地区的code
            }
        },
        //点击取消
        onCancel() {
            this.addArea = false;
        },

        //点击确定
        onAreaConfirm(value) {
            // console.log(value);
            var that = this;
            if (value[2] && value[2].Code) {
                that.areaName = value[0].Name + value[1].Name + value[2].Name;
            } else if (value[1] && value[1].Code) {
                that.$toast('请选择省市区');
                // that.areaName = value[0].Name + value[1].Name;
            } else if (value[0] && value[0].Code) {
                that.$toast('请选择省市区');
            }
            that.sureAdd = value;
            that.addArea = false;
            console.log(that.sureAdd);
        },

        // 上传图片
        upload(){
            this.$refs.input.click();
        },
        /**
         *  上传图片
         */
        changeImg(e,name) {
            var that = this;
            console.log(e);
            console.log(e.target.files[0]);
            var file = e.target.files[0];
            var formData = new FormData();
            formData.append("file", file);
            that.$toast.loading({ message: "上传中.." });
            that.public.request('post',"/api/sysUserRegister/UploadBizFile",formData,res => {
                console.log(res);
                that.$toast.clear();
                if(res.data.errorCode == '00'){
                // var imgSrc = res.data.rows;
                    if(name == 'BizLicensePic'){
                        that.BizLicensePic = res.data.rows;
                    }else if(name == 'GSPLicensePic'){
                        that.GSPLicensePic = res.data.rows;
                    }else if(name == 'LicensePic'){
                        that.LicensePic = res.data.rows;
                    }
                
                
                }else{
                    that.$toast(res.data.errorMsg);
                }
            },'upImg')
        },
        // 获取验证码
        getCode(){
            var that = this;
            
            if(!that.disabled){
                that.disabled = true;
            }
            setTimeout(function(){
                that.disabled = false;
            },2000)
            
            var reg = /^1[0-9]{10}$/;
            if(!that.Mobile){
                that.$toast("手机号不能为空");
                return;
            }
            if(!reg.test(that.Mobile)){
                that.$toast("请输入正确的手机号");
                return;
            }
            var data = {
                Mobile: that.Mobile,
            }
            that.public.request('post','/api/AppCommon/IsMobileValid',data,res => {
                console.log(res);
                if(res.data.errorCode == '00'){

                    // 倒计时
                    var num = 60;
                    var time = setInterval(function(){
                        if(num == 0){
                            clearInterval(time);
                            that.disabled = false;
                            that.sendTime = '获取验证码';
                        }else{
                            num--;
                            that.sendTime = num + '秒后重试';
                            that.disabled = true;
                            
                        }
                    },1000)
                    data.Id = localStorage.getItem('userId');
                    that.public.request('post','/api/AppCommon/SendMobileCode',data,res => {
                        // console.log(res);
                        if(res.data.errorCode == '00'){
                            that.$toast("验证码发送成功")

                            
                        }else if(res.data.errorCode == '99'){
                            that.$toast(res.data.errorMsg);
                        }
                    })


                }else if(res.data.errorCode == '99'){
                    that.$toast(res.data.errorMsg);
                }
            })
        },
    }
}
</script>

<style scoped>
    .step-box{
        width:100%;
        padding:0.3rem 0;
        box-sizing:border-box;
        display:flex;
        align-items:center;
    }
    .step-box .step-item{
        width:33.33%;
    }
    .step-box .step-item .item-s{
        display:flex;
        align-items:center;
    }
    .step-box .step-item .item-s .step-num{
        font-size:0.28rem;
        color:#fff;
        width:0.4rem;
        height:0.4rem;
        line-height:0.4rem;
        text-align:center;
        background:#9B9B9B;
        border-radius:50%;
    }
    .step-box .step-item .item-s .step-num.blue{
        background:#005BAB;
    }
    .step-box .step-item .item-s .step-line{
        flex:1;
        height:0.05rem;
        background:#9B9B9B;
    }
    .step-box .step-item .item-s .step-line.blue{
        background:#005BAB;
    }
    .step-box .step-item .item-s .step-line.white-line{
        background:#fff;
    }
    .step-box .step-item .step-txt{
        font-size:0.28rem;
        color:#9B9B9B;
        text-align:center;
        margin-top:0.2rem;
    }
    .step-box .step-item .step-txt.blue{
        color:#6565f3;
    }

    /* 步骤1 */
    .step1{
        width:100%;
        padding:0 0.3rem;
        box-sizing:border-box;
        margin:0.6rem 0 1rem;;
    }
    .step1 .input-box{
        width:100%;
        height:0.8rem;
        padding:0.12rem 0.2rem;
        box-sizing:border-box;
        border:0.02rem solid #6565f3;
        border-radius:0.18rem;
        display:flex;
        align-items:center;
        margin-bottom:0.3rem;
    }
    .step1 .input-box .input-img{
        width:0.32rem;
        margin-right:0.28rem;
    }
    .step1 .input-box .input-value{
        flex:1;
        height:100%;
        font-size:0.3rem;
    }
    .step1 .input-box .address-input{
        flex:1;
        height:100%;
        font-size:0.3rem;
        text-align:right;
    }
    .step1 .input-box .address-input.grey{
        color:#999898;
    }
    .step1 .input-box .select-box{
        flex:1;
        height:100%;
        display:flex;
        align-items:center;
    }
    .step1 .input-box .select-box img{
        width:0.18rem;
        margin-left:0.2rem;
    }
    .step1 .input-box .address-img{
        width:0.36rem;
    }

    .step1 .input-code{
        display:flex;
        align-items:center;
        margin-bottom:0.3rem;
    }
    .step1 .input-code .input-left{
        flex:1;
        height:0.8rem;
        border:0.02rem solid #6565f3;
        border-radius:0.18rem;
        padding:0.12rem 0.2rem;
        box-sizing:border-box;
    }
    .step1 .input-code .input-left input{
        font-size:0.3rem;
        height:100%;
    }
    .step1 .input-code .submit-code{
        height:0.8rem;
        padding:0.1rem 0.2rem;
        box-sizing:border-box;
        font-size:0.3rem;
        color:#fff;
        background:#F0AD4E;
        margin-left:0.3rem;
        border-radius:0.18rem;
    }

    /* 上传文件 */
    .upload-box{
        margin-bottom:0.3rem;
    }
    .upload-box .upload-txt{
        font-size:0.3rem;
    }
    .upload-box .upload-operate{
        display:flex;
        align-items:center;
        font-size:0.28rem;
        margin-top:0.2rem;
    }
    .upload-box .upload-operate .select-btn{
        width:1.4rem;
        height:0.5rem;
        font-size:0.26rem;
        text-align:center;
        line-height:0.5rem;
        background:#C9C9C9;
        border-radius:0.1rem;
    }


    .submit{
        position:fixed;
        width:100%;
        height:0.9rem;
        left:0;
        bottom:0;
        z-index:0;
        font-size:0.3rem;
        background:#005BAB;
        text-align:center;
        line-height:0.9rem;
        color:#fff;
    }
</style>