<template>
    <div class="container">
        <!-- 图片轮播 -->
        <div class="seck-swiper">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                <div v-for="(item,index) in bannerList" :key="index">
                    <van-swipe-item v-if="bannerList.length>0">
                        <div class="item-img">
                            <img :src="item.ImgUrl" alt="">
                        </div>
                    </van-swipe-item>
                </div>
                <van-swipe-item v-if="bannerList.length<=0 && bannerShow">
                    <div class="item-img">
                        <img src="@/assets/images/page.png" alt="">
                    </div>
                </van-swipe-item>
                
            </van-swipe>
        </div>
        <!-- 套餐 -->
        <div class="package-box" v-for="(item,index) in packList" :key="index">
            <div class="pack-title flex-sp">
                <span class="pack-name">{{item.SpName}}</span>
                <div class="pack-right flex-wrap">
                    <span class="right-tit">距结束：</span>
                    <div class="time-setime flex-wrap">
                        <img src="@/assets/images/index/v2_ms_ico.png" alt="倒计时">
                        <div class="settime-tit" v-html="item.countTime"></div>
                    </div>
                </div>
            </div>
            <div class="pack-list flex-wrap">
                <div class="pack-item" v-for="(packItem,j) in item.PackageDetailList" :key="j" @click="toDetail(packItem.Spid)">
                    <div class="item-img">
                        <img class="shop-img" v-if="!packItem.SpPic" src="@/assets/images/loseImg.png" alt="商品图片">
                        <img class="shop-img" v-if="packItem.SpPic" :src="packItem.SpPic" alt="商品图片">
                    </div>
                    <div class="item-info">
                        <span class="info-name">{{packItem.SpName}}</span>
                        <div class="info-wrap flex-sp">
                            <span class="info-specifi">{{packItem.Style}}</span>
                            <!-- <span class="info-num">数量：<span class="font-red">{{packItem.Numbrer}}</span>盒</span> -->
                        </div>
                        <span class="info-company">{{packItem.Factory}}</span>
                        <!-- <div class="info-time">远近效期：{{packItem.sxrqRange}}</div> -->
                        <div  class="info-price flex-ac " v-if="token|| (!token && loginStatus==1)">
                            <span class=" font-red jiage">￥{{packItem.InvoicePrice}} <s class="NormalPrice">￥{{packItem.Price}}</s></span>  <span>x{{packItem.Numbrer}}</span></div>
                        <span class="info-price font-red" v-if="!token&&loginStatus==0">￥资质通过后可见</span>
                    </div>
                </div>
				<!-- 礼品 -->
				<div class="pack-item" v-if="item.GoodOfferList" v-for="gift in item.GoodOfferList">
				    <div class="item-img">
				        <img class="shop-img" :src="gift.SpPic" alt="商品图片">
				    </div>
				    <div class="item-info">
				        <span class="info-name">{{gift.Spmc}}</span>
				        <div class="info-wrap flex-sp">
				            <span class="info-specifi">{{gift.Style}}</span>
				            <span class="info-num">数量：<span>{{gift.GiftCount}}</span></span>
				        </div>
				    </div>
					<div class="info-price flex-ac" style="position: absolute;width: 90%; left: 5%;bottom: 5px;">
					    <span class=" font-red jiage">价值:￥{{gift.GiftPrice}} </span>
						<span class="font-red" style="border: 1px solid #ff1515;font-size: 10px;padding: 0 10px;border-radius: 3px;">礼品</span>
					</div>
				</div>
				
            </div>
            <div class="settle flex-sp bor-bot2">
                <div class="settle-left flex-wrap">
                    <div class="left-tit">套餐价</div>
                    <div class="left-price"  v-if="token|| (!token && loginStatus==1)">
                        <span class="font-red">￥{{item.AreaPrice}}</span>
                        <span>￥{{item.Price}}</span>
                    </div>
                      <div v-if="!token&&loginStatus==0">  <span class="font-red font-15">￥资质通过后可见</span></div>
              
                </div>
                <div class="add-subtract flex-wrap">
                    <span class="add" @click="changeNum('cut',index)">-</span>
                    <input class="num" type="number" v-model="item.cartNum"  @click.stop="editInput(item.cartNum,index)" >
                    <!-- <span class="num">{{item.cartNum}}</span> -->
                    <span class="cut" @click="changeNum('add',index)">+</span>
                </div>
                <div class="add-cart" @click.stop="addCart(index)">加入购物车</div>
            </div>
        </div>
        <!-- 套餐2 -->
        <!-- <div class="package-box">
            <div class="pack-title flex-sp">
                <span class="pack-name">修正冬季搭配套餐</span>
                <div class="pack-right flex-wrap">
                    <span class="right-tit">距结束：</span>
                    <div class="time-setime flex-wrap">
                        <img src="@/assets/images/index/v2_ms_ico.png" alt="倒计时">
                        <div class="settime-tit" v-html="'12天&nbsp;12:00:30'"></div>
                    </div>
                </div>
            </div>
            <div class="pack-list flex-wrap">
                <div class="pack-item" v-for="(item,index) in 3" :key="index">
                    <div class="item-img">
                        <img class="shop-img" src="@/assets/images/index/xd3.png" alt="商品图片">
                    </div>
                    <div class="item-info">
                        <span class="info-name">阿司匹林肠溶片</span>
                        <div class="info-wrap flex-sp">
                            <span class="info-specifi">100mg*48片/盒</span>
                            <span class="info-num">数量：<span class="font-red">1</span>盒</span>
                        </div>
                        <span class="info-company">沈阳奥吉娜药业有限公司</span>
                        <div class="info-time">远近效期：2020-12/2020-12</div>
                        <span class="info-price font-red">￥ 10.00</span>
                    </div>
                </div>
            </div>
            <div class="settle flex-sp bor-bot2">
                <div class="settle-left flex-wrap">
                    <div class="left-tit">套餐价</div>
                    <div class="left-price">
                        <span class="font-red">￥20.00</span>
                        <span>￥20.00</span>
                    </div>
                </div>
                <div class="add-subtract flex-wrap">
                    <span class="add">-</span>
                    <span class="num">2</span>
                    <span class="cut">+</span>
                </div>
            </div>
        </div> -->


        <div class="loading-box flex-center" v-if="noMore && packList.length > 5">
            <span class="load-txt">已经到底了！</span>
        </div>
        <!-- 加载样式 -->
        <div class="loading-box flex-center" v-if="showLoading && packList.length > 0">
            <img class="load-img" src="@/assets/images/index/loading.gif" alt="加载图片">
            <span class="load-txt">正在加载</span>
        </div>
        <!-- 暂无数据 -->
        <div class="empty-box" v-if="packList.length == 0">
            <div class="no-data">
                <img src="@/assets/images/cart/noActiveDrug.png" alt="暂无数据">
                <span>暂无数据</span>
            </div>
        </div>
  <!-- 返回首页 -->
    <div class="back-index" v-if="clientType!='app' && clientType!='ios'" @click="toIndex()">返回首页</div>
        <!-- 返回首页 -->
        <!-- <div class="back-index">返回首页</div> -->

        <!-- 底部操作 -->
        <!-- <div class="bot-heig">
            <div class="bot-box flex-wrap">
                <div class="bot-left flex-wrap">
                    <div class="bot-item flex-column">
                        <img src="@/assets/images/feedback_star.png" alt="">
                        <span>收藏</span>
                    </div>
                    <div class="bot-item flex-column">
                        <img src="@/assets/images/v2_gwc.png" alt="">
                        <span>购物车</span>
                    </div>
                </div>
                <div class="seck-but">加入购物车</div>
            </div>
        </div> -->

        <!-- 回到顶部 -->
        <!-- <div class="back-top">
            <img src="@/assets/images/icon_top.png" alt="返回顶部">
        </div> -->


        <!-- 编辑商品数量弹窗 -->
        <div class="popup-box" v-if="editNumShow"></div>
        <div class="popup-cont" v-if="editNumShow">
            <div class="good-tit">编辑商品数量</div>
            <div class="good-num-box">
                <span class="reduce-g" @click="reduceNum1()">-</span>
                <input type="number"  ref="editNum" v-model="cartEditNum" @keyup.enter="sureEdit()">
                <span class="add-g" @click="addNum1()">+</span>
            </div>
            <div class="good-btn">
                <div class="btn-g" id="cancel" @click="cancelEdit()">取消</div>
                <div class="btn-g blue" id="sure" @click="sureEdit()">确认</div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    data(){
        return{
            showLoading: true,  // 加载
            noMore: false,      // 暂无数据
            page: 1,
            packList: [],  // 套餐包列表
            bannerList: [],     // 顶部图片轮播
            bannerShow: false,  // 防止进入页面展示本地图片  false隐藏  true展示
            editNumShow: false,	// 编辑商品数量弹窗
            cartNum: 1,		// 购物车数量
            cartEditNum: 1,	//  编辑购物车弹窗数量
            cartIndex: 0,	// 修改数量商品的下标
            token: sessionStorage.getItem('member_token'),
            loginStatus: 0,    // 查看分享商品的人是否登录，loginStatus 1登录(显示商品价格) 0 未登录
            clientType:'',
            
        }
    },
    created(){
        var that = this;
            // 小程序登录
        that.AppletLogin();
        
        // 查看分享商品的人是否登录，loginStatus 1登录(显示商品价格) 0 未登录
        if(that.$route.query.loginStatus){
        that.loginStatus = that.$route.query.loginStatus;
        }else{
        that.loginStatus = 0;
        }
            that.clientType=localStorage.getItem('clientType');
        that.getPackage();  // 请求数据
        that.getBannerImg();
    },
    methods:{
          createXmlHttpRequest(){
        var xmlHttp;
        if(window.ActiveXobject){ // 判断是否是ie浏览器
            try { // try开始
                xmlhttp = new ActiveXobject("Microsoft.XMLHTTP"); // 使用ActiveX对象创建ajax
            }catch(e){
                xmlHttp = false;
            } // try end
        }else{   //Chrome、FireFox等非ie内核
            try{
            xmlHttp = new XMLHttpRequest(); //视为非ie情况下
            }catch(e){
                xmlHttp = false; // 其他非主流浏览器
            }
        } // 判断结束,如果创建成功则返回一个DOM对象,如果创建不成功则返回一个false

        if(xmlHttp)
        {
            return xmlHttp;
        }else{
            alert("xmlhttp对象创建失败,请检查浏览器是否支持XmlHttpRequest!");
        }
    },

          /**
      * 小程序登录
      */
      AppletLogin(){
          console.log(111);
        var that = this;
        if(location.href.indexOf('?') == -1) return;
        var params=that.public.urlObj(location.href);
        if(!params.jumid)return;
          localStorage.setItem("clientType", "applets"); // app类型
        var id = params.jumid;
        var xmlobj = that.createXmlHttpRequest();
        xmlobj.open("post",window.baseUrl.url+"/api/AppLogin/GetAppletLogin",false);
        xmlobj.setRequestHeader("cache-control","no-cache");
        xmlobj.setRequestHeader("Content-Type", "application/json;charset=utf-8"); //设置请求头信息
        xmlobj.onreadystatechange = function() {
            if(xmlobj.readyState == 4 && xmlobj.status == 200)
            {
                try{
                      var rsp = JSON.parse(xmlobj.responseText);
                      console.log("rsp==>",rsp);
                      var res={data:{}};
                      res.data = rsp;
                    if(res.data.errorCode=='00'){
                        that.$toast.clear();
                        sessionStorage.setItem("member_token", res.data.rows);
                        localStorage.setItem("orgId", res.data.errorMsg); 
                        sessionStorage.setItem("customer", res.data.extend.customerId);
                        var webconfig = JSON.stringify(res.data.extend.webConfig);
                        localStorage.setItem("webconfig", webconfig);
                        that.token=res.data.rows;
                        if(res.data.extend.IsSpecial){  // 测试账号，审核展示数据
                            sessionStorage.setItem("IsSpecial",res.data.extend.IsSpecial);
                        }
                    }else{
                        that.$toast(res.data.errorMsg);
                    }
                }
                catch(e){
                          that.$router.push({
                              url:"/login"
                          });     
                }
            }
        };
        xmlobj.send(JSON.stringify({Mobile:id})); //设置为发送给服务器数据
      
    },
     // 返回首页
    toIndex() {
      var that = this;

      // 查看是否登录
      var token = sessionStorage.getItem('member_token');
      if (token == null) {
        var orgId = localStorage.getItem("orgId");
        that.$dialog
          .confirm({
            message: "你还未登录，是否登录!",
          })
          .then(() => {
            that.$router.push({
              path: "/login",
              query: {
                orgId: orgId,
                auto: 0,
              },
            });
          })
          .catch(() => {
            // console.log('取消');
          });
        return false;
      }

      that.$router.push({
        path: "/",
        query: {
          refresh: "refresh",
        },
      });
    },


        // 顶部图片轮播
        /*
            公众号-首页轮播图	20001
            公众号-秒杀列表页banner	20002
            公众号-团购列表页banner	20003
            公众号-集采列表页banner	20004
            公众号-品牌列表页banner	20005
            公众号-今日特价列表页banner	20006
            公众号-套餐包专区banner   20007
            公众号-近效期列表页banner	20008
            公众号-优惠活动列表页banner  20010
             */
        getBannerImg(){
            var that = this;
            var data = {
                Code: "20007",
            };
            that.public.request('post',"/api/advertItem/queryAdvertItem",data, res => {
                if(res.data.errorCode == "00"){
                    that.bannerList = res.data.rows;
                }
                that.bannerShow = true;
            })
        },

        // 编辑input数值
			editInput(countNum,index){
				var that = this;
				that.editNumShow = true;
				that.$nextTick(() => {
					that.$refs.editNum.focus();
					that.$refs.editNum.select();
				})
				that.cartEditNum = countNum;
				that.cartIndex = index;
			},
			// 取消编辑弹窗
			cancelEdit(){
				var that = this;
				that.editNumShow = false;
			},
			// 购物车数量减
			reduceNum1(){
				var that = this;
				if(that.cartEditNum > 1){
					that.cartEditNum--;
				}
			},
			// 购物车数量加
			addNum1(){
				var that = this;
				that.cartEditNum++;
			},
			// 编辑弹窗确认
			sureEdit(){
				var that = this;
				that.editNumShow = false;
				var index = that.cartIndex;
				var orgId = localStorage.orgId;
				// 如果orgId 不是瑞龙的，则只能输入整数
				if(orgId != 'd3d3041db35d42f39443e9e1e9d74410'){
					var regNum =  /^\+?[1-9][0-9]*$/;
					if(!regNum.test(that.cartEditNum)){
						that.$toast('请输入大于0的整数')
						return;
					}
				}
				that.packList[index].cartNum = that.cartEditNum;
				that.cartEditNum = 1;
			},

        // 跳转详情
        toDetail(spid){
            this.$router.push({
                path: '/goodsDetail',
                query: {
                    spid: spid,
                }
            })
        },
        // 加入购物车
        addCart(index){
            var that = this;
           if (that.token == null) {
                var orgId = localStorage.getItem("orgId");
                that.$dialog
                .confirm({
                    message: "你还未登录，是否登录!",
                })
                .then(() => {
                    that.$router.push({
                    path: "/login",
                    query: {
                        orgId: orgId,
                        auto: 0,
                    },
                    });
                })
                .catch(() => {
                    // console.log('取消');
                });
                return false;
            }
            var packList = that.packList;
            var orgId = localStorage.orgId;
            var data = {
                GoodsOrgId: packList[index].OrgId,
                Spid: packList[index].Id,
                GoodsCount: packList[index].cartNum,
                GoodsPrice: packList[index].AreaPrice,
				// GoodsPrice: packList[index].AreaPrice,
                ReceiveId: orgId,
                IsPackage: true,
            }
            if(data.GoodsCount <= 0){
                that.$toast('请输入大于0的整数');
                return false;
            }
			if(packList[index].CrossGroupId){
				data.TmsCrossGroupId = packList[index].CrossGroupId;
			}
            that.$toast.loading("正在添加");
            that.public.request('post','/api/sellWellCar/addSellWellCar',data,res => {
                if(res.data.errorCode == '00'){
                    that.$toast("添加成功");
                }else{
                    that.$toast(res.data.errorMsg);
                }
            })
        },
        // 请求套餐数据
        getPackage(){
            var that = this;
            var page = that.page;
            var data = {
                page: page,
                rows: 15,
            }
            that.public.request('get','/api/GoodsPackage/QueryList',data,res => {
                if(res.data.errorCode == '00'){
                    var allArr = [];
                    var initArr = that.packList;
                    var curArr = res.data.rows ? res.data.rows : '';
                    var lastPageLength = curArr.length;
                    if(page > 1){
                        allArr = initArr.concat(curArr);
                    }else{
                        allArr = res.data.rows;
                    }
                    //  增加一个购物车数量
                    for(var i=0; i<allArr.length; i++){
                        allArr[i].cartNum = 1;
                    }
                    allArr.forEach((item,index) => {
                        // 天时分秒  倒计时
                        that.public.countdown(item.EndTime,res => {
                            item.countTime = res;
                        });
                    })
                    that.packList = allArr;
                    if(lastPageLength < 6){
                        that.noMore = true;
                        that.showLoading = false;
                    }
                }else if(res.data.errorCode == '99'){
                    if(page <= 1){
                        that.packList = [];
                        that.showLoading = false;
                        that.noMore = false;
                    }else{
                        that.showLoading = false;
                        that.noMore = true;
                    }
                }
            })
        },

        // 购物车数量加减
        changeNum(type,index){
            var that = this;
            if(type == 'cut'){
                that.packList[index].cartNum--;
                if(that.packList[index].cartNum <= 1){
                    that.packList[index].cartNum = 1;
                }
            }else if(type == 'add'){
                that.packList[index].cartNum++;
            }
        }
    }
}

</script>

<style scoped>
    /* 图片轮播 */
    .seck-swiper{ width:100%;height:3.2rem; }
    .seck-swiper .my-swipe{ width:100%;height:100%; }
    .seck-swiper .my-swipe .item-img{ width:100%;height:100%; }
    .seck-swiper .my-swipe .item-img img{ width:100%;height:100%; }

    /* 套餐包 */
    .package-box .pack-title{ width:100%;height:0.8rem;padding:0 0.2rem;box-sizing:border-box;border-bottom:0.02rem solid #eee; }
    .package-box .pack-title .pack-name{ font-size:0.3rem;font-weight:600; }
    .package-box .pack-title .pack-right .right-tit{ font-size:0.3rem; }
    /* 距离结束倒计时 */
    .time-setime{ flex:1;padding:0.02rem 0.2rem; background:#199ed8; border-radius:0.28rem; }
    .time-setime img{ width:0.22rem; height:0.22rem; margin-right:0.18rem; }
    .time-setime .settime-tit{ color:#fff; font-size:0.24rem;}

    .pack-list{ width:100%;padding:0.18rem 0.2rem;box-sizing:border-box;flex-wrap:wrap; }
    .pack-list .pack-item{position: relative; width:48.3%;height:4.5rem;box-shadow:0 0 0.1rem #eee;margin-right:0.2rem;margin-bottom:0.2rem; }
    .pack-list .pack-item:nth-child(2n){ margin-right:0; }
    .pack-list .pack-item .item-info .info-wrap .info-num{ font-size:0.24rem;display:inline; }
    .pack-list .pack-item .item-info .info-wrap .info-num span{ display:inline; }
    .pack-list .pack-item .item-info .info-time{ font-size:0.22rem; }

    .pack-item .item-img{ width:100%; height:2.7rem; }
    .pack-item .item-img  .shop-img{ width:100%;height:2.7rem;object-fit: contain; }
    .pack-item .item-img .item-setime{ position:absolute; left:3%; bottom:0; right:0; padding:0.02rem 0.2rem; background:#199ed8; border-radius:0.28rem; width:80%; }
    .pack-item .item-img .item-setime img{ width:0.22rem; height:0.22rem; margin-right:0.18rem; }
    .pack-item .item-img .item-setime .settime-tit{ color:#fff; font-size:0.24rem;}
    .pack-item .item-info { width:100%; padding:0.1rem 0.12rem; box-sizing:border-box; }
    .pack-item .item-info span{ display:block; font-size:0.26rem; }
    .pack-item .item-info span.info-name{ width:100%; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;font-weight:600; }
    .pack-item .item-info span.info-specifi{ font-size:0.24rem; }
    .pack-item .item-info span.info-company{ font-size:0.26rem; width:100%; overflow:hidden; white-space:nowrap; text-overflow:ellipsis;font-weight:600; }
    .pack-item .item-info span.info-price{ font-size:0.32rem; }
    .pack-item .item-info span.info-old-price{ font-size:0.22rem; text-decoration:line-through; }
    .pack-item .item-info .shop-cart{ position:absolute; right:0.2rem; bottom:0.3rem; width:0.36rem; }

    .settle{ width:100%;height:1rem;padding:0 0.06rem;box-sizing:border-box;border-top:0.02rem solid #eee; }
    .settle .settle-left .left-tit{ font-size:0.28rem; }
    .settle .settle-left .left-price span:first-child{ font-size:0.48rem; }
    .settle .settle-left .left-price span:last-child{ font-size:0.2rem;color:#999;text-decoration:line-through;margin-left:0.05rem; }
    /* 商品加减 */
    .add-subtract{ flex:1;justify-content:center; margin: 0 0.06rem;}
    .add-subtract .add{ font-size:0.36rem;background:#ff1515;color:#fff;border-radius:50%;display:inline-block;width:0.36rem;height:0.36rem;text-align:center;line-height:0.32rem; }
    .add-subtract .num{ font-size:0.3rem;margin: 0 0.05rem;max-width: 1rem;text-align: center;border: 0.02rem solid #999;border-radius: 0.12rem;line-height: inherit; }
    .add-subtract .cut{ font-size:0.36rem;background:#ff1515;color:#fff;border-radius:50%;display:inline-block;width:0.36rem;height:0.36rem;text-align:center;line-height:0.32rem; }
    /* 加入购物车 */
    .settle .add-cart{ padding:0.15rem 0.08rem;background:#73cef5;border-radius:0.12rem;color:#fff;font-size:0.24rem; }

    /* 底部操作 */
    .bot-heig{ width:100%;height:0.9rem; }
    .bot-box{ position:fixed;left:0;bottom:0;z-index:1;width:100%;height:0.9rem;border-top:0.02rem solid #eee;background:#fff; }
    .bot-box .bot-left{ flex:1;height:100%;padding:0 0.2rem;box-sizing:border-box;justify-content:space-around; }
    .bot-box .bot-left .bot-item{ height:100%;justify-content:center;align-items:center; }
    .bot-box .bot-left .bot-item img{ width:0.4rem;height:0.4rem;}

    .seck-but{ width:60%;height:100%;text-align:center;line-height:0.9rem;color:#fff;font-size:0.3rem;background:#ff1515; }

    /* 返回首页 */
    .back-index{ position:fixed;left:0;bottom:1.5rem;width:1.5rem;height:0.6rem;border-radius:0 0.3rem 0.3rem 0;background:rgb(255, 145, 0);line-height:0.6rem;text-align:center;color:#fff;font-size:0.3rem;z-index:3 }


    /* 编辑商品数量弹窗 */
    .popup-box{
        position:fixed;
        left:0;
        top:0;
        right:0;
        bottom:0;
        background:rgba(0,0,0,0.4);
        z-index:11000;
    }
    .popup-cont{
        position:fixed;
        width:70%;
        padding:0.2rem 0 0;
        box-sizing:border-box;
        background:#fff;
        border-radius:0.12rem;
        left:15%;
        top:25%;
        z-index:11001;
    }
    .popup-cont .good-tit{
        width:100%;
        text-align:center;
        font-size:0.3rem;
        margin-bottom:0.2rem;
    }
    .popup-cont .good-num-box{
        width:100%;
        display:flex;
        align-items:center;
        justify-content:center;
        margin-bottom:0.1rem;
        padding: 0 0.2rem;
        box-sizing:border-box;
    }
    .popup-cont .good-num-box .reduce-g{
        font-size:0.39rem;
    }
    .popup-cont .good-num-box input{
        width:2rem;
        height:0.6rem;
        padding:0.1rem;
        margin:0 0.12rem;
        text-align:center;
        border: 0.02rem solid #c9c4c4;
        border-radius:0.12rem;
        font-size:0.3rem;
    }
    .popup-cont .good-num-box .add-g{
        font-size:0.39rem;
    }
    .popup-cont .good-btn{
        width:100%;
        height:50px;
        display:flex;
        align-items:center;
    }
    
    .popup-cont .good-btn .btn-g{
        width:50%;
        font-size:0.3rem;
        text-align:center;
        color:#999;
    }
    .popup-cont .good-btn .btn-g.blue{
        color:#58A0F2;
    }
    .font-15{font-size: .3rem;}
     .jiage{font-size: 15px !important;}
    .flex-ac{display: flex;align-items: center;justify-content: space-between;}
    .NormalPrice{color: #918f8f !important;font-size: 0.2rem;}
</style>