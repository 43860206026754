<template>
  <div class="container">
    <!-- 商品图片轮播 -->
    <div class="goods-swiper">
      <van-swipe class="my-swipe" :autoplay="3000" :show-indicators="false">
        <van-swipe-item v-for="(imgItem, index) in goodInfo.ImgSrc" :key="index">
          <div class="item-img">
            <img v-if="imgItem" :src="imgItem" @click="previewImage(imgItem)" onerror="javascript:this.src='https://goodssuffix.oss-cn-qingdao.aliyuncs.com/upload/c5c2ae08dbfb45898d64061a0d82e83e/9cc5bc0235044c54916fd18243082d10.png'" />
            <img v-else src="@/assets/images/loseImg.png" alt="" />
          </div>
        </van-swipe-item>
        <div class="noData"  v-if="goodInfo.kcshl <= 0||goodDetail.Stock<=0"> 
            <span>售罄</span>
         </div>
      </van-swipe>
      <!-- 提示 -->
      <div class="tips">
        温馨提示：厂家可能会更新商品包装，如图片与实物不符以实物为准
      </div>
    </div>
    <!-- 商品规格、价钱 团购、集采、 秒杀商品展示-->
    <div class="goods-info bor-bot2"
      v-if="type == 'group' || type == 'purch' || type == 'seckill'">
      <div class="info-box">
        <div class="info-name">{{ goodInfo.spmch }}</div>
        <div class="info-specs flex-sp">
          <span>规格：{{ goodInfo.shpgg ? goodInfo.shpgg : "" }}</span>
          <span>仅剩：<span class="fontRedS">{{ goodInfo.kcshl }}{{ goodInfo.dw }}</span></span>
        </div>
        <div class="info-price flex-wrap">
          <div class="info-left">
             <span class="left-tit fontRedS" v-if="!token && loginStatus==0">￥资质通过后可见</span>
            <span class="left-tit fontRedS" v-if="token   || (!token && loginStatus==1)">￥{{ goodDetail.SoldPrice }}</span>
           
            <!-- <span class="left-num">￥{{goodInfo.MarketPrice}}</span> -->
            <span class="left-num" v-if="goodDetail.MarketPrice&&token">￥{{ goodDetail.MarketPrice }}</span>
            <span class="left-num" v-if="!goodDetail.MarketPrice&&token">￥{{ goodDetail.OriginPrice }}</span>
            
          </div>
          <div class="info-time flex-wrap">
            <div class="time-tit">距结束</div>
            <div class="time-setime flex-wrap">
              <img src="@/assets/images/index/v2_ms_ico.png" alt="倒计时" />
              <div class="settime-tit" v-html="goodDetail.timeDown"></div>
            </div>
          </div>
        </div>
        <div class="info-retail flex-sp" v-if="token || (!token && loginStatus==1)">
          <div class="retail-tit">
            <span>建议零售价：</span>
            <span class="font-red">{{
              goodInfo.AdvicePrice > 0 ? goodInfo.AdvicePrice : "--"
            }}</span>
            <span>元</span>
          </div>
          <!-- <div class="retail-rete">
            <span>毛利率：</span>
            <span class="font-red">{{ groProRate }}%</span>
          </div> -->
        </div>
        <div class="info-retail flex-sp" v-if="!token && loginStatus == 0">
          <div class="retail-tit">
            <span>建议零售价：</span>
            <span class="font-red">--</span>
            <span>元</span>
          </div>
          <!-- <div class="retail-rete">
            <span>毛利率：</span>
            <span class="font-red">--%</span>
          </div> -->
        </div>
        <!-- <div class="info-set flex-sp">
                    <div class="set-time">远近效期：2020-12-01/2020-12-02</div>
                    <div class="add-subtract flex-wrap">
                        <span class="add">-</span>
                        <span class="num">2</span>
                        <span class="cut">+</span>
                    </div>
                </div> -->
      </div>
      <div class="promote-box flex-wrap">
        <span class="promote-tit">促销</span>
        <span class="promote-label"
          v-for="(activeItem, i) in goodInfo.ActivityCacheDataList" :key="i">{{ activeItem.ActivityTypeName }}</span>
      </div>
      <div class="promote-list "
        v-if="goodInfo.ActivityCacheDataList &&
            goodInfo.ActivityCacheDataList.length > 0&&token">
        <div class="promote-item"
          v-for="(item, ind) in goodInfo.ActivityCacheDataList" :key="ind">
          {{ item.Description }}
        </div>
      </div>
    </div>
    <!-- 特价商品展示 -->
    <div class="goods-info bor-bot2" v-if="type == 'bargain' || type == 'new_bargain'">
      <div class="info-box">
        <div class="info-name">{{ goodInfo.spmch }}</div>
        <div class="info-specs flex-sp">
          <span>规格：{{ goodInfo.shpgg ? goodInfo.shpgg : "" }}</span>
          <span>仅剩：<span class="font-red">{{ goodInfo.kcshl }}{{ goodInfo.dw }}</span></span>
        </div>
        <div class="info-price flex-wrap">
          <div class="info-left">
            <!-- <span class="left-tit font-red">￥{{ goodDetail.SoldPrice }}</span> -->
             <span class="left-tit font-red" v-if="!token && loginStatus == 0">￥资质通过后可见</span>
             <span class="left-tit font-red" v-if="token || (!token && loginStatus==1)">￥{{ goodDetail.SoldPrice }}</span>
             <span class="left-num" v-if="token">￥{{ goodDetail.MarketPrice }}</span>
          </div>
        </div>
        <div class="info-retail flex-sp">
          <div class="retail-tit" v-if="token || (!token && loginStatus==1)">
            <span>建议零售价：</span>
            <span class="font-red">{{goodInfo.AdvicePrice > 0 ? goodInfo.AdvicePrice : "--"}}</span>
            <span>元</span>
          </div>
          <div class="retail-tit" v-if="!token && loginStatus==0">
            <span>建议零售价：</span>
            <span class="font-red">--</span>
            <span>元</span>
          </div>
          <!-- <div class="retail-rete" v-if="token || (!token && loginStatus==1)">
            <span>毛利率：</span>
            <span class="font-red">{{ groProRate }}%</span>
          </div>
            <div class="retail-rete" v-if="!token &&loginStatus==0">
            <span>毛利率：</span>
            <span class="font-red">--%</span>
          </div> -->
        </div>
        <!-- <div class="info-set flex-sp">
                    <div class="set-time">远近效期：{{goodInfo.lastmodifytime}}</div>
                    <div class="add-subtract flex-wrap">
                        <span class="add">-</span>
                        <span class="num">2</span>
                        <span class="cut">+</span>
                    </div>
                </div> -->
      </div>
       <!-- <div v-if="goodInfo.StyleGroup" class="promote-box  specification flex-wrap" @click="specificationBtn()">
         <div> <span class="promote-tit">规格</span>
            <span >点击选择</span></div>
         <div>></div>
      </div> -->
      <div class="promote-box flex-wrap">
        <span class="promote-tit">促销</span>
        <span class="promote-label"
          v-for="(activeItem, i) in goodInfo.ActivityCacheDataList" :key="i">{{ activeItem.ActivityTypeName }}</span>
      </div>
    </div>
    <!-- 近效期展示 -->
    <div class="goods-info bor-bot2" v-if="type == 'nearExpiry'">
      <div class="info-box">
        <div class="info-name">{{ goodInfo.spmch }}</div>
        <div class="info-specs flex-sp">
          <span>{{ goodInfo.shpgg }}/{{ goodInfo.dw }}</span>
          <!-- <span>
            仅剩：<span class="font-red">{{ goodInfo.kcshl }}{{ goodInfo.dw }}</span>
          </span> -->
          <span>
            仅剩：<span class="font-red">{{ goodDetail.Stock }}{{ goodInfo.dw }}</span>
          </span>
        </div>
        <div class="info-price flex-sp">
          <div class="info-left" v-if="token  || (!token && loginStatus==1)">
            <span class="left-tit fontRedS" >￥{{ goodDetail.SoldPrice }}</span>
            <span class="left-num">￥{{ goodDetail.MarketPrice }}</span>
          </div>
          <div class="info-left" v-if="!token && loginStatus==0">
            <span class="left-tit fontRedS" >￥资质通过后可见</span>
          </div>
          <!-- <div class="retail-rete" v-if="token || (!token && loginStatus==1)">
            <span>毛利率：</span>
            <span class="font-red">{{ groProRate }}%</span>
          </div>
           <div class="retail-rete" v-if="!token && loginStatus==0">
            <span>毛利率：</span>
            <span class="font-red">--%</span>
          </div> -->
        </div>
        <div class="info-retail flex-sp">
          <div class="retail-tit" v-if="token || (!token && loginStatus==1)">
            <span>建议零售价：</span>
            <!-- <span>55</span> -->
            <span class="font-red" >{{
              goodInfo.AdvicePrice > 0 ? goodInfo.AdvicePrice : "--"
            }}</span>
            <span>元</span>
          </div>
           <div class="retail-tit" v-if="!token && loginStatus == 0">
            <span>建议零售价：</span>
            <!-- <span>55</span> -->
            <span class="font-red" >--</span>
            <span>元</span>
          </div>
          <!-- <div class="add-subtract flex-wrap">
                        <span class="add">-</span>
                        <span class="num">2</span>
                        <span class="cut">+</span>
                    </div> -->
        </div>
      </div>
      <!-- <div class="promote-box flex-wrap">
                <span class="promote-tit">促销</span>
                <span class="promote-label">2020-12-02</span>
            </div> -->
    </div>

    <!-- 商品详情 -->
    <div class="goods-detail">
      <div class="detail-tit flex-wrap">
        <div class="tit-item" :class="selectType == 'detail' ? 'active' : ''" @click="changeType('detail')">
          商品详情
        </div>
        <div class="tit-item" :class="selectType == 'direcImg' ? 'active' : ''" @click="changeType('direcImg')">
          商品说明书
        </div>
      </div>
      <div class="detail-cont">
        <!-- 商品详情 -->
        <div class="detail-sp" v-if="selectType == 'detail'">
          <div class="detail-li flex-wrap">
            <span class="detail-name">名称：</span>
            <span class="detail-label">{{ goodInfo.spmch }}</span>
          </div>
          <div class="detail-li flex-wrap">
            <span class="detail-name">规格：</span>
            <span class="detail-label">{{ goodInfo.shpgg }}</span>
          </div>
          <div class="detail-li flex-wrap">
            <span class="detail-name">商品编号：</span>
            <span class="detail-label">{{ goodInfo.spbh }}</span>
          </div>

          <div class="detail-li flex-wrap">
            <span class="detail-name">单位：</span>
            <span class="detail-label">{{ goodInfo.dw }}</span>
          </div>
		  <div class="detail-li flex-wrap">
		    <span class="detail-name">产地：</span>
		    <span class="detail-label">{{ goodInfo.shpchd?goodInfo.shpchd:"暂无" }}</span>
		  </div>
          <div class="detail-li flex-wrap">
            <span class="detail-name">生产厂商：</span>
            <span class="detail-label">{{ goodInfo.shengccj }}</span>
          </div>
          <div class="detail-li flex-wrap">
            <span class="detail-name">是否可拆零：</span>
            <span class="detail-label" v-if="goodInfo.OnlyPackageSold">不可拆零</span>
            <span class="detail-label" v-if="!goodInfo.OnlyPackageSold">可拆零</span>
          </div>
          <div class="detail-li flex-wrap">
            <span class="detail-name">大包装：</span>
            <span class="detail-label">{{ goodInfo.jlgg }}</span>
          </div>
          <div class="detail-li flex-wrap">
            <span class="detail-name">中包装：</span>
            <span class="detail-label">{{ goodInfo.zbz }}</span>
          </div>
          <div class="detail-li flex-wrap">
            <span class="detail-name">批准文号：</span>
            <span class="detail-label">{{ goodInfo.pizhwh }}</span>
          </div>
          <div class="detail-li flex-wrap">
            <span class="detail-name">有效期：</span>
            <span class="detail-label">{{ goodInfo.jxq }}</span>
          </div>
          <div  class="addContent" v-html="goodInfo.DetailContent">
            {{goodInfo.DetailContent}}
          </div>
        </div>
        <!-- 商品说明书 -->
        <div class="direction-box" v-else-if="(selectType = 'direcImg')"
          style="min-height:5rem;">
          <div v-if="goodInfo.SpecificationsList.length ">
              <div v-for="(item,ind) in goodInfo.SpecificationsList" :key="ind" >
                <img class="direc-img" :src="item.Src" alt="">
            </div>
          </div>
          <div v-if="goodInfo.SpecificationsList.length==0">
            <img src="@/assets/images/loseImg.png" alt="" />
          </div>
          <!-- <img
            class="direc-img"
            v-if="directionImg"
            :src="directionImg"
            alt=""
          />
          <img
            class="direc-img"
            v-if="!directionImg"
            src="@/assets/images/loseImg.png"
            alt=""
          /> -->
        </div>
      </div>
    </div>

    <!-- 返回首页 -->
    <div class="back-index" v-if="clientType!='app' && clientType!='ios'" @click="toIndex()">返回首页</div>

    <!-- 底部操作 -->
    <div class="bot-heig">
      <div class="bot-box flex-wrap">
        <div class="bot-left flex-wrap">
          <div class="bot-item flex-column" @click="collect()">
            <img v-if="collection != 1" src="@/assets/images/feedback_star.png"/>
            <img v-if="collection == 1" :src="themeImgObj.collectImg"/>
            <span>收藏</span>
          </div>
          <div class="bot-item flex-column" @click="toCartPage()">
            <img :src="themeImgObj.cartImgGwc" alt="" />
            <span class="bot-name">购物车</span>
            <span class="cart-count" v-if="cartSum > 0">{{ cartSum }}</span>
          </div>
        </div>
        <!-- <div class="seck-but" v-if="type == 'bargain'" @click="addCart">加入购物车</div>
                <div class="seck-but" v-if="type == 'seckill'">立即秒杀</div> -->
        <!-- <div class="seck-but grey" v-else-if="type == 'bargain'" @click="onRegister">缺货登记</div> -->
        <div  class="seck-but" :class="goodDetail.timeDown=='活动已结束'?'bg-grey':''"    v-if="type === 'nearExpiry' || type === 'bargain' || type== 'new_bargain'" @click="cartShow(goodDetail.GoodsId,goodDetail)">
          {{btnText?btnText:"点击抢购"}}
        </div>
        <div class="seck-but" :class="goodDetail.timeDown=='活动已结束'?'bg-grey':''" v-if="type === 'group'" @click="cartShow(goodDetail.GoodsId,goodDetail)">
          {{btnText?btnText:"点击拼团"}}
        </div>
        <div class="seck-but" :class="goodDetail.timeDown=='活动已结束'?'bg-grey':''" v-if="type === 'purch'" @click="cartShow(goodDetail.GoodsId,goodDetail)">
          {{btnText?btnText:"点击集采"}}
        </div>
        <div class="seck-but" :class="goodDetail.timeDown=='活动已结束'?'bg-grey':''" v-if="type === 'seckill'" @click="cartShow(goodDetail.GoodsId,goodDetail)">
          {{btnText?btnText:"立即秒杀"}}
        </div>
      </div>
    </div>
    <!-- 活动商品购物车弹窗 -->
    <active-cart :cartNums='cartNum' :cartShowPop="activeCartShowPop"  :goodSpid="activitySpId" :goodDetail="activeDetail" :typePage="typePage" @diffCartShow="closeCart"></active-cart>
    <specification :guiGeType='guiGeType' :isSpecification='isSpecification'  @isGuiGeShow="closeGuiGe" :guiGeDetail="guiGeDetail" :guiGeDes='guiGeDes' ></specification>
	
	<!-- 图片预览-->
	<div class="image-preview" v-if="isPreviewVisible">
			 <!-- :closeable = true -->
	     <van-image-preview 
				v-model="isPreviewVisible"
				:showIndex = false
				:images="zizhiImages">
	     </van-image-preview>
	 </div>
	
  </div>
</template>

<script>
import nearExpiry from "@/assets/js/api/nearExpiry.js";
import activeRequest from "@/assets/js/api/active";
// 引入活动弹窗组件
import activeCart from '../../components/activeCartPop.vue';
import specification from '../../components/specification.vue';
export default {
  components: {
      activeCart,specification,
  },
  data() {
    return {
      goodSpid: "", // 商品spid
      goodInfo: {}, // 集采详情
      selectId: "", // 选中活动的id
      goodId: "", // 商品id,
      goodDetail: {}, // 商品详情
      selectType: "detail", // 商品详情和说明书切换
      collection: "", // 收藏
      directionImg: "", // 商品详情说明书
      cartShowPop: false, // 商品购物车弹窗
      cartNum: 1, // 购物车数量

      activeCartShowPop: false,     // 组件显示隐藏
      activitySpId: '',         // 向组件传递的spid
      activeDetail: {},     // 向组件传递商品详情
      typePage:'',    // 向组件传递页面类型

      editNumShow: false, // 编辑数字弹窗显示隐藏
      cartEditNum: 1, // 编辑弹窗输入框中的值

      timeDown: "", // 倒计时
      ninNum: "", //最少添加
      maxNum: "", //最多添加
      token : sessionStorage.getItem('member_token'),
      loginStatus: 0,    // 查看分享商品的人是否登录，loginStatus 1登录(显示商品价格) 0 未登录
      pointNum:2,//小数点
      clientType:'',
      isSpecification:false,//规格
      guiGeDetail :{},//规格详情
      guiGeDes:{},//规格
      guiGeType:true,
	  
	  btnText: "",
	  keyId: "", //埋点 主键
	  
	  zizhiImages: [], // 当前预览的图片
	  isPreviewVisible: false, // 是否显示预览层
    };
  },
  created() {
    var that = this;
	//统计分析 埋点进来
	this.keyId = ""; //每次进入置空
	this.public.setBuryPoint(4,1,this.keyId).then(res=>{
		if(res.errorCode=="00"){
			that.keyId = res.rows;
		}
	});
	
    // 主题色图片
    that.themeImgObj = that.themeColorImg.imgObj;
    // 小程序登录
    that.AppletLogin();

    // 查看分享商品的人是否登录，loginStatus 1登录(显示商品价格) 0 未登录
    if(that.$route.query.loginStatus){
      that.loginStatus = that.$route.query.loginStatus;
      // console.log(that.loginStatus,'-----------------aa');
    }else{
      that.loginStatus = 0;
      // console.log(that.loginStatus,'-----------------aa');
    }

    // 请求购物车数量
    if(that.token){
      that.public.getCartNum();
    }

    that.cartSum = that.$store.state.cartNum;

    // console.log(that.$route.query);
    that.type = that.$route.query.type;
    that.goodSpid = that.$route.query.spid; // 商品spid
    that.goodOrgId = that.$route.query.goodOrgId; //商品orgId
    that.goodId = that.$route.query.id;
    var orgId = that.$route.query.orgId;
    if (orgId) {
      localStorage.removeItem("orgId");
      localStorage.setItem("orgId", orgId); 
    }
    that.clientType=localStorage.getItem('clientType');

    that.getGoodInfo(orgId);
    that.getDetail();
    that.getDirecInfo();
	//创建浏览记录
	that.createHistory();
    var shuju=JSON.parse(localStorage.getItem('webconfig'))
     if(shuju&&shuju.GoodPriceFixed){
         that.pointNum=shuju.GoodPriceFixed;
      }

  },
  
  //界面销毁
  destroyed() {
  	//埋点离开
  	this.public.setBuryPoint(4,2,this.keyId).then(res=>{
  		if(res.errorCode=="00"){}
  	});
  },
  
  methods: {
	  //添加记录浏览量
	  createHistory(){
		  this.public.request("post","api/GoodHistory/CreateHistory",{
			  SpId: this.goodSpid, //（商品id）
			  CustomId: localStorage.getItem("orgId")  //（登陆客户orgid）
		  },res=>{
			  if(res.data.errorCode=="00"){
				console.log("浏览记录成功!")
			  }else{
				console.log("浏览记录失败~")
			  }
		  })
	  },
	  
    // 加入购物车
    addCart() {
      var that = this;

      var goodInfo = that.goodInfo; // 商品详情
      var goodDetail = that.goodDetail; // 商品详情1
      var cartNum = that.cartNum; // 购买量
      var stock = goodInfo.kcshl; // 库存数量
      var zbz = goodInfo.zbz; // 中包装
      var goodId = that.goodId;

      // 购物车弹窗活动数据处理
      // var activeObj = {};
      // goodInfo.ActivityCacheDataList.forEach((item,index) => {
      //     if(that.selectId == item.ActivityId){
      //         activeObj = item;
      //     }
      // })

      that.cartShowPop = false;

      // 余数只要有值，则商品数量无效
      var num = cartNum - Number(cartNum / zbz) * zbz;

      if (num) {
        that.$toast("商品数量无效！");
        return false;
      }
      if (stock <= 0) {
        that.$toast("库存不足");

        return false;
      }
      if (Number(cartNum) < 1) {
        that.$toast("数量不能小于1");
        return false;
      }
      if (Number(cartNum) > Number(stock)) {
        var orgId = localStorage.getItem("orgId");
        if (orgId != "7fa7c5c3582540849327ff308382a7e3") {
          that.$toast("库存不足!");
          return;
        }
      }
      var goodDetail = that.goodDetail;
      if (!goodDetail.SoldPrice) {
        that.$toast("商品价格无效!");
        return;
      }

      //cartSource-20秒杀 30-团购 35-集采,80-近效期,90-今日特价 121-新人特价
      var type = that.type;
      if (type === "nearExpiry") {
        // 近效期
        var cartSource = 80;
        var inventory = Number(goodDetail.Stock);
        if(Number(cartNum) > Number(inventory)){
          that.$toast("库存不足!");
          return false;
        }
      } else if (type === "group") {
        // 团购
        var cartSource = 30;
        if (Number(cartNum) > Number(goodDetail.MaxNum)) {
          that.$toast("超出最大限购量" + goodDetail.MaxNum);
          return false;
        } else if (Number(cartNum) < Number(goodDetail.MinNum)) {
          that.$toast("小于最小购买量" + goodDetail.MinNum);
          return false;
        }
      } else if (type === "bargain") {
        // 今日特价
        var cartSource = 90;
        // console.log(cartNum);
        // console.log(goodDetail.SingleMaxNum);
        if (Number(cartNum) > Number(goodDetail.SingleMaxNum)) {
          that.$toast("每人限购" + goodDetail.SingleMaxNum);
          return false;
        }
      }else if (type === "new_bargain") {
        // 今日特价
        var cartSource = 121;
        // console.log(cartNum);
        // console.log(goodDetail.SingleMaxNum);
        if (Number(cartNum) > Number(goodDetail.SingleMaxNum)) {
          that.$toast("每人限购" + goodDetail.SingleMaxNum);
          return false;
        }
      } else if (type === "purch") {
        // 集采
        var cartSource = 35;
        if (Number(cartNum) > Number(goodDetail.MaxNum)) {
          that.$toast("超出最大限购量" + goodDetail.MaxNum);
          return false;
        } else if (Number(cartNum) < Number(goodDetail.MinNum)) {
          that.$toast("小于最小购买量" + goodDetail.MinNum);
          return false;
        }
      } else if (type === "seckill") {
        // 秒杀
        var cartSource = 20;
        if (Number(cartNum) > Number(goodDetail.SingleMaxNum)) {
          that.$toast("每人限购" + goodDetail.SingleMaxNum);
          return false;
        }
      }
      var data = {
        Spid: goodInfo.spid,
        GoodsCount: cartNum, // 购物车数量
        CartSource: cartSource,
        CartSourceId: goodId, // 商品id
        GoodsPrice: goodDetail.SoldPrice,
      };
	  
	  if(goodInfo.CrossGroupId){
	  	data.TmsCrossGroupId = goodInfo.CrossGroupId;
	  }
	  
      that.getAddCart(data);
    },
    getAddCart(data) {
      var that = this;
      that.public.request("post","/api/SellWellCar/AddSellWellCar",
        data,(res) => {
          // console.log(res);
          if (res.data.errorCode == "00") {
            that.$toast("添加成功");
            that.public.getCartNum(); // 购物车数量
            that.cartNum = 1;
            that.cartShowPop = false;
          } else if (res.data.errorCode == "99") {
            that.$toast(res.data.errorMsg);
            that.cartShowPop = false;
          }
        }
      );
    },
    // 编辑input数值
    editInput() {
      var that = this;
      that.editNumShow = true;
      that.$nextTick(() => {
        that.$refs.editNum.focus();
        that.$refs.editNum.select();
      });
      that.cartEditNum = that.cartNum;
    },
    // 取消编辑弹窗
    cancelEdit() {
      var that = this;
      that.editNumShow = false;
    },
     // 规格
    specificationBtn(){
       var that = this;
      // 查看是否登录
      var token = sessionStorage.getItem('member_token');
      if (token == null) {
        var orgId = localStorage.getItem("orgId");
        that.$dialog.confirm({
            message: "你还未登录，是否登录!",
          }).then(() => {
            that.$router.push({
              path: "/login",
              query: {
                orgId: orgId,
                auto: 0,
              },
            });
          }).catch(() => {
          });
        return false;
      }
      that.isSpecification=true;
      var guiges=that.goodInfo.StyleGroup;
   
      Object.keys(guiges).forEach(key=>{
        let item='num';
        let value=0;
       guiges[key][item]=value;
      });
      that.guiGeDes=guiges;
      console.log(that.guiGeDes);
      that.guiGeDetail = that.goodInfo;
     
    },
    //  关闭规格弹窗
    closeGuiGe() {
      var that = this;
      that.isSpecification = false;
    },
    // 购物车数量减
    reduceNum1() {
      var that = this;

      if (that.cartEditNum > that.ninNum) {
        that.cartEditNum--;
      } else {
        that.$toast("最少购买" + that.ninNum + that.goodInfo.dw);
      }
    },
    // 购物车数量加
    addNum1() {
      var that = this;
      if(!that.maxNum){
        that.cartEditNum++;

      }else{
        if (that.cartEditNum < that.maxNum) {
        that.cartEditNum++;
      } else {
        that.$toast("最多购买" + that.maxNum + that.goodInfo.dw);
      }
      }
    },
    // 编辑弹窗确认
    sureEdit() {
      var that = this;
      that.editNumShow = false;
      // console.log(typeof that.cartEditNum);

      if (that.cartEditNum > that.maxNum) {
        that.cartEditNum = that.maxNum;
        that.$toast("最多购买" + that.maxNum + that.goodInfo.dw);
      } else if (that.cartEditNum < that.ninNum) {
        that.cartEditNum = that.ninNum;
        that.$toast("最少购买" + that.ninNum + that.goodInfo.dw);
      } else {
        that.cartNum = that.cartEditNum;
      }
    },

    
    // 关闭购物车弹窗
    closePop() {
      var that = this;
      that.cartShowPop = false;
    },
    // 购物车数量减
    reduceNum() {
      var that = this;

      if (that.cartNum > that.ninNum) {
        that.cartNum--;
      } else {
        that.$toast("最少购买" + that.ninNum + that.goodInfo.dw);
      }
    },
    // 购物车数量加
    addNum() {
      var that = this;
     if(!that.maxNum){
       that.cartNum++;

     }else{
        if (that.cartNum < that.maxNum) {
        that.cartNum++;
      } else {
        that.$toast("最多购买" + that.maxNum + that.goodInfo.dw);
      }
     }
    },


    // 商品弹窗显示
    cartShow(spid,item) {
      var that = this; // 查看是否登录
      // console.log("spid=>",spid);
      // console.log("type=>",that.type);
      // console.log("item=>",item);
	  if(!that.goodInfo.IsBuy || that.btnText == "申请合作"){
		  if(!that.goodInfo.BuyPhone){
		    return that.$toast("暂无联系方式");
		  }
		  
		  that.$dialog.confirm({
		      message: "您即将拨打电话："+that.goodInfo.BuyPhone,
		  	confirmButtonText: "呼叫"
		  }).then(() => {//confirm
			window.location.href = 'tel://' + that.goodInfo.BuyPhone
		  }).catch(() => {
		  	//cancel
		  });
		  return;
	  }
	  
      if(item.timeDown && item.timeDown.indexOf('结束') != -1){
        that.$toast('活动已结束');
        return false;
      }
      var token = sessionStorage.getItem('member_token');
      if (token == null) {
        var orgId = localStorage.getItem("orgId");
        that.$dialog.confirm({
            message: "你还未登录，是否登录!",
          }).then(() => {
            that.$router.push({
              path: "/login",
              query: {
                orgId: orgId,
                auto: 0,
              },
            });
          }).catch(() => {
            // console.log('取消');
          });
        return false;
      }

      that.activeCartShowPop = true;
      that.typePage = that.type;
      
      
      that.activeDetail = item;
      that.activitySpId = spid;
    },
    // 关闭组件弹窗
    closeCart() {
      var that = this;
      that.activeCartShowPop = false;
    },


    // 去购物车页面
    toCartPage() {
      var that = this;
      // 查看是否登录
      var token = sessionStorage.getItem('member_token');
      if (token == null) {
        var orgId = localStorage.getItem("orgId");
        that.$dialog.confirm({
            message: "你还未登录，是否登录!",
          }).then(() => {
            that.$router.push({
              path: "/login",
              query: {
                orgId: orgId,
                auto: 0,
              },
            });
          }).catch(() => {
            // console.log('取消');
          });
        return false;
      }

      that.$router.push({
        path: "/cart",
      });
    },
    // 返回首页
    toIndex() {
      var that = this;

      // 查看是否登录
      var token = sessionStorage.getItem('member_token');
      if (token == null) {
        var orgId = localStorage.getItem("orgId");
        that.$dialog.confirm({
            message: "你还未登录，是否登录!",
          }).then(() => {
            that.$router.push({
              path: "/login",
              query: {
                orgId: orgId,
                auto: 0,
              },
            });
          }).catch(() => {
            // console.log('取消');
          });
        return false;
      }

      that.$router.push({
        path: "/",
        query: {
          refresh: "refresh",
        },
      });
    },
    // 商品说明书
    getDirecInfo() {
      var that = this;
      var orgId = localStorage.getItem("orgId");
      that.public.request("post","/api/advertItem/queryAdvertItem",{
          OrgId: orgId,
          Code: "20009", // 公众号商品详细
          AdvertPlatformType: 20,
        },(res) => {
          // console.log(res);
          if (res.data.errorCode == "00" && res.data.rows.length > 0) {
            // that.directionImg = res.data.rows[0].ImgUrl;
          }
        }
      );
    },
    // 收藏  取消收藏
    collect() {
      var that = this;

      // 查看是否登录
      var token = sessionStorage.getItem('member_token');
      if (token == null) {
        var orgId = localStorage.getItem("orgId");
        that.$dialog.confirm({
            message: "你还未登录，是否登录!",
          }).then(() => {
            that.$router.push({
              path: "/login",
              query: {
                orgId: orgId,
                auto: 0,
              },
            });
          }).catch(() => {
            // console.log('取消');
          });
        return false;
      }

      var goodInfo = that.goodInfo;
      var collection = that.collection;
      if (collection == 0) {
        that.public.request("post","/api/GoodsCollection/AddCollection",{
            Spid: goodInfo.spid,
            SpOrgId: "",
            Price: goodInfo.zdshj,
          },(res) => {
            // console.log(res);
            if (res.data.errorCode == "00") {
              that.$toast("收藏成功");
              that.collection = 1;
            } else{
              that.$toast(res.data.errorMsg);
            }
          }
        );
      } else {
        that.public.request("post", "/api/GoodsCollection/CancelCollection",{
            Spid: goodInfo.spid,
            SpOrgId: "",
            Price: goodInfo.zdshj,
          },(res) => {
            // console.log(res);
            if (res.data.errorCode == "00") {
              that.$toast("取消收藏");
              that.collection = 0;
            } else{
              that.$toast(res.data.errorMsg);
            }
          }
        );
      }
    },
    // 切换商品详情或者说明书
    changeType(type) {
      var that = this;
      that.selectType = type;
    },
    // 进入详情加浏览记录
    addBrowseHistory() {
      var that = this;
      var orgId = localStorage.getItem("orgId");
      var goodInfo = that.goodInfo;
      that.public.request("post","/api/GoodsBrowseHistory/BatchAddBrowseHistory",{
          ListHistory: [{
              Spid: goodInfo.spid,
              SpOrgId: orgId,
              Price: goodInfo.zdshj,
              Type: 10, // 10详情   20购物车 30搜索
            }],
        },(res) => {
          if (res.data.errorCode == "00") {
            // console.log(res);
          }
        }
      );
    },
    // 请求详情数据
    getDetail(id) {
      var that = this;
		if (that.type == "new_bargain") { // 新人特价
		  var goodId = that.goodId;
		  that.public.request("get","/api/NewComerPrice/QueryDetail?", {
		    id: goodId,
		  },(res) => {
		      if (res.data.errorCode == "00") {
		        that.goodDetail = res.data.rows[0];
		      } else{
		        that.$toast(res.data.errorMsg);
		      }
		    }
		  );
	}else if (that.type == "bargain") {// 今日特价
        var goodId = that.goodId;
        that.public.request("get","/api/DailySpecial/QueryDetail?",{
            id: goodId,
          },(res) => {
            if (res.data.errorCode == "00") {
              that.goodDetail = res.data.rows[0];
            } else{
              that.$toast(res.data.errorMsg);
            }
          }
        );
      } else if (that.type == "seckill") {// 秒杀
        var goodId = that.goodId;
        // console.log(data);
        that.public.request("post", "/api/SecKill/QueryInfo", {
          Id: goodId,
        }, (res) => {
          // console.log(res);
          if (res.data.errorCode == "00") {
            var goodDetail = res.data.rows[0];
            // 天时分秒  倒计时
            that.public.countdown(goodDetail.EndTime, (res) => {
              goodDetail.timeDown = res;
            });
            that.goodDetail = res.data.rows[0];
          } else{
            that.$toast(res.data.errorMsg);
          }
        });
      } else if (that.type === "nearExpiry") { // 近效期详情
        var goodId = that.goodId;
        nearExpiry.queryDetail({
          id: goodId,
        }, (res) => {
          // console.log(res);
          if (res.data.errorCode === "00") {
            that.goodDetail = res.data.rows;
          } else{
            that.$toast(res.data.errorMsg);
          }
        });
      } else if (that.type === "group") {// 团购
        var goodId = that.goodId;
        activeRequest.groupQueryInfo({
          Id: goodId,
        }, (res) => {
          // console.log(res);
          if (res.data.errorCode === "00") {
            var goodDetail = res.data.rows[0];
            // 天时分秒  倒计时
            that.public.countdown(goodDetail.EndTime, (res) => {
              goodDetail.timeDown = res;
            });
            that.goodDetail = res.data.rows[0];
          } else{
            that.$toast(res.data.errorMsg);
          }
        });
      } else if (that.type == "purch") {
        var goodId = that.goodId;
        activeRequest.groupQueryInfo({
          Id: goodId,
        }, (res) => {
          // console.log(res);
          if (res.data.errorCode === "00") {
            var goodDetail = res.data.rows[0];
            // 天时分秒  倒计时
            that.public.countdown(goodDetail.EndTime, (res) => {
              goodDetail.timeDown = res;
            });
            that.goodDetail = res.data.rows[0];
          } else{
            that.$toast(res.data.errorMsg);
          }
        });
      }
    },

    // 商品详情
    getGoodInfo(orgId) {
      var that = this;
      that.$toast.loading({
        message: "加载中...",
        duration: 0,
      });

      var goodSpid = that.goodSpid;

      var data = {
        SpId: goodSpid,
        OrgId: "",
        pihao: "",
        IsShowDetailContent:true,
      };
      if (orgId) {
        data.OrgId = orgId;
      }
      that.public.request("post", "/api/store/queryGoodsInfo", data, (res) => {
        that.$toast.clear();
        if (res.data.errorCode == "00") {
          var goodInfo = res.data.rows[0];
		  
		  if(!goodInfo.IsBuy){
			  that.btnText = "申请合作";
		  }
		  
          var jxq = goodInfo.sxrq.split("/");
          goodInfo.jxq = jxq[0];

          
          goodInfo.activityPrice = null;
          goodInfo.ActivityCacheDataList.forEach((item1, i) => {
            // 如果有活动商品，则按活动价格显示
            // 0团购 1秒杀 2今日特价 3集采
            if (
              item1.ActivityType == 0 ||
              item1.ActivityType == 1 ||
              item1.ActivityType == 2 ||
              item1.ActivityType == 3
            ) {
              // console.log("--------------");
              goodInfo.activityType = item1.ActivityType;
              goodInfo.activityPrice = item1.MinAmount;
              goodInfo.activityId = item1.ActivityId;
            }
          });
          

          that.goodInfo = goodInfo;
          // console.log( that.goodInfo);
		  if(that.goodInfo.ActivityCacheData){
			  var istoken=sessionStorage.getItem('member_token')?true:false;
			    this.wx.miniProgram.postMessage({
			    data:{
			      jiage:that.goodInfo.ActivityCacheDataList[0].MinAmount,
			      img: that.goodInfo.ImgSrc[0],
			      guiGe:that.goodInfo.shpgg,
			      huoDong:that.goodInfo.ActivityCacheDataList[0].ActivityTypeName,
			      istoken:istoken,
			      SpId:that.goodInfo.spid,
			      name:that.goodInfo.spmch,
			    }
			  })
		  }
          // 收藏
          that.collection = res.data.extend;
        
          that.addBrowseHistory();
        } else if (res.data.errorCode == "99") {
          that.$toast(res.data.errorMsg);
        }
      });
    },


    createXmlHttpRequest(){
        var xmlHttp;
        if(window.ActiveXobject){ // 判断是否是ie浏览器
            try { // try开始
                xmlhttp = new ActiveXobject("Microsoft.XMLHTTP"); // 使用ActiveX对象创建ajax
            }catch(e){
                xmlHttp = false;
            } // try end
        }else{   //Chrome、FireFox等非ie内核
            try{
            xmlHttp = new XMLHttpRequest(); //视为非ie情况下
            }catch(e){
                xmlHttp = false; // 其他非主流浏览器
            }
        } // 判断结束,如果创建成功则返回一个DOM对象,如果创建不成功则返回一个false

        if(xmlHttp)
        {
            return xmlHttp;
        }else{
            alert("xmlhttp对象创建失败,请检查浏览器是否支持XmlHttpRequest!");
        }
    },
    /**
      * 小程序登录
      */
      AppletLogin(){
        var that = this;
        if(location.href.indexOf('?') == -1) return;
        var params=that.public.urlObj(location.href);
        if(!params.jumid)return;
          localStorage.setItem("clientType", "applets"); // app类型
        var id = params.jumid;
        // console.log("--------------",id);
        var xmlobj = that.createXmlHttpRequest();
        xmlobj.open("post",window.baseUrl.url+"/api/AppLogin/GetAppletLogin",false);
        xmlobj.setRequestHeader("cache-control","no-cache");
        xmlobj.setRequestHeader("Content-Type", "application/json;charset=utf-8"); //设置请求头信息
        xmlobj.onreadystatechange = function() {
            if(xmlobj.readyState == 4 && xmlobj.status == 200)
            {
                try{
                      var rsp = JSON.parse(xmlobj.responseText);
                      console.log("rsp==>",rsp);
                      if(!rsp || rsp.errorCode !="00"){
                          that.$router.push({
                              url:"/login"
                          });
                          return;
                      }

                      var res={data:{}};
                      res.data = rsp;
                    if(res.data.errorCode=='00'){
                        that.$toast.clear();
                        that.$toast("成功");
                        sessionStorage.setItem("member_token", res.data.rows);
                        localStorage.setItem("orgId", res.data.errorMsg); 
                        sessionStorage.setItem("customer", res.data.extend.customerId);
                        var webconfig = JSON.stringify(res.data.extend.webConfig);
                        localStorage.setItem("webconfig", webconfig);
                        if(res.data.extend.IsSpecial){  // 测试账号，审核展示数据
                            sessionStorage.setItem("IsSpecial",res.data.extend.IsSpecial);
                        }
                    }else{
                        that.$toast(res.data.errorMsg);
                    }
                    // console.log(rsp);
                }
                catch(e){
                          that.$router.push({
                              url:"/login"
                          });     
                }
                
            }
        };
        xmlobj.send(JSON.stringify({Mobile:id})); //设置为发送给服务器数据

      
    },
	
	/**图片预览*/
	previewImage(imgUrl){
		this.zizhiImages[0] = imgUrl;
		this.isPreviewVisible = true;
	},

  },
  computed: {
    // 计算毛利率
    groProRate: function() {
      var that = this;
      var goodInfo = that.goodInfo;
      if (goodInfo.AdvicePrice > 0 && goodInfo.zdshj > 0) {
        var groPro = (
          ((goodInfo.AdvicePrice - goodInfo.zdshj) / goodInfo.AdvicePrice) *
          100
        ).toFixed(that.pointNum);
        return groPro;
      } else if (goodInfo.AdvicePrice <= 0) {
        return "--";
      }
    },
  },
};
</script>

<style scoped>
.bg-grey{
  background: grey !important;
}
/* 商品图片轮播 */
.goods-swiper {
  width: 100%;
  height: 6rem;
  position: relative;
}
.goods-swiper .my-swipe {
  width: 100%;
  height: 100%;
}
.goods-swiper .my-swipe .item-img {
  width: 100%;
  height: 100%;
}
.goods-swiper .my-swipe .item-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.goods-swiper .tips {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  font-size: 0.23rem;
  height: 0.5rem;
  line-height: 0.5rem;
  padding: 0 0.12rem;
  box-sizing: border-box;
  color: #fff;
}

/* 商品规格、价钱 */
.goods-info {
  width: 100%;
}
.goods-info .info-box {
  padding: 0.12rem 0.2rem;
  box-sizing: border-box;
}
.info-box .info-name {
  width: 100%;
  font-size: 0.32rem;
  color: #333;
  font-weight: 600;
}
.info-box .info-specs {
  margin: 0.08rem 0 0.02rem;
}
.info-box .info-specs span {
  font-size: 0.26rem;
  color: #999;
}
.info-box .info-specs span.font-red {
  color:  #D5251D;
}
.info-box .info-price .info-left .left-tit {
  font-size: 0.4rem;
}
.info-box .info-price .info-left .left-num {
  font-size: 0.26rem;
  color: #999;
  text-decoration: line-through;
  margin-left: 0.12rem;
}
.info-box .info-price .info-time {
  flex: 1;
  margin-left: 0.2rem;
  justify-content: flex-end;
}
.info-box .info-price .info-time .time-tit {
  font-size: 0.26rem;
}
.info-box .info-retail .retail-tit span {
  font-size: 0.28rme;
  color: #666;
}
.info-box .info-retail .retail-tit span.font-red {
  color:  #D5251D;
}
.info-box .info-retail .retail-rete span {
  font-size: 0.28rem;
  color: #666;
}
.info-box .info-retail .retail-rete span.font-red {
  color: #D5251D;
}
.info-box .info-set {
  margin: 0.12rem 0 0;
}
.info-box .info-set .set-time {
  font-size: 0.26rem;
  margin-bottom: 0.2rem;
}
/* 距离结束倒计时 */
.time-setime {
  padding: 0.02rem 0.2rem;
  background: var(--theme-color);
  border-radius: 0.28rem;
  margin-left: 0.2rem;
  height: 0.32rem;
  white-space:nowrap;
}
.time-setime img {
  width: 0.22rem;
  height: 0.22rem;
  margin-right: 0.18rem;
}
.time-setime .settime-tit {
  color: #fff;
  font-size: 0.24rem;
}
/* 商品加减 */
/* .add-subtract{ flex:1;justify-content:flex-end }
    .add-subtract .add{ font-size:0.36rem;background:#ff1515;color:#fff;border-radius:50%;display:inline-block;width:0.36rem;height:0.36rem;text-align:center;line-height:0.32rem; }
    .add-subtract .num{ font-size:0.3rem;margin:0 0.2rem; }
    .add-subtract .cut{ font-size:0.36rem;background:#ff1515;color:#fff;border-radius:50%;display:inline-block;width:0.36rem;height:0.36rem;text-align:center;line-height:0.32rem; } */

.promote-box {
  width: 100%;
  height: 0.8rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
  border-top: 0.02rem solid #eee;
}
.promote-box .promote-tit {
  font-size: 0.3rem;
  margin-right: 0.3rem;
}
.promote-box .promote-label {
  display: inline-block;
  padding: 0.03rem 0.22rem;
  font-size: 0.26rem;
  color: #fff;
  background: #D5251D;
  border-radius: 0.2rem;
  margin-right: 0.12rem;
}

/* 商品详情 */
.goods-detail .detail-tit {
  width: 100%;
  height: 0.8rem;
  justify-content: space-around;
  border-bottom: 0.02rem solid #eee;
}
.goods-detail .detail-tit .tit-item {
  font-size: 0.3rem;
  height: 100%;
  line-height: 0.8rem;
}
.goods-detail .detail-tit .tit-item.active {
  border-bottom: 0.02rem solid  var(--theme-color);
  color:  var(--theme-color);
}

.goods-detail .detail-cont {
  width: 100%;
  padding: 0.02rme;
  box-sizing: border-box;
}

.detail-cont .detail-sp {
  width: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
}
.detail-cont .detail-sp .detail-li {
  width: 100%;
  padding: 0.06rem 0;
}
.detail-cont .detail-sp .detail-li .detail-name {
  width: 2rem;
  font-size: 0.28rem;
  color: #666;
}
.detail-cont .detail-sp .detail-li .detail-label {
  font-size: 0.3rem;
  color: #666;
}
/* 商品说明书 */
.direction-box {
  width: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
}
.direction-box .derec-img {
  width: 100%;
}

/* 底部操作 */
.bot-heig {
  width: 100%;
  height: 0.9rem;
}
.bot-box {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 0.9rem;
  border-top: 0.02rem solid #eee;
  background: #fff;
}
.bot-box .bot-left {
  flex: 1;
  height: 100%;
  padding: 0 0.2rem;
  box-sizing: border-box;
  justify-content: space-around;
}
.bot-box .bot-left .bot-item {
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}
.bot-box .bot-left .bot-item img {
  width: 0.4rem;
  height: 0.4rem;
}
.bot-box .bot-left .bot-item .cart-count {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.24rem;
  color: #fff;
  width: 0.36rem;
  height: 0.36rem;
  border-radius: 50%;
  background:  var(--theme-color);
  text-align: center;
  line-height: 0.36rem;
}

.seck-but {
  width: 60%;
  height: 100%;
  text-align: center;
  line-height: 0.9rem;
  color: #fff;
  font-size: 0.3rem;
  background:  var(--theme-color);
}
.seck-but.grey {
  background: #c0bfbf;
}

/* 商品加减 */
.add-subtract {
  flex: 1;
  justify-content: flex-end;
}
.add-subtract .add {
  font-size: 0.36rem;
  border: 0.05rem solid  var(--theme-color);
  color:  var(--theme-color);
  border-radius: 50%;
  display: inline-block;
  width: 0.38rem;
  height: 0.38rem;
  text-align: center;
  line-height: 0.3rem;
  box-sizing: border-box;
}
.add-subtract .num {
  font-size: 0.3rem;
  margin: 0 0.2rem;
  width: 1rem;
  text-align: center;
  border: 0.02rem solid #999;
  border-radius: 0.12rem;
  line-height: inherit;
}
.add-subtract .cut {
  font-size: 0.36rem;
  background:  var(--theme-color);
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  width: 0.38rem;
  height: 0.38rem;
  text-align: center;
  line-height: 0.3rem;
}

/* 返回首页 */
.back-index {
  position: fixed;
  left: 0;
  bottom: 1.8rem;
  width: 1.5rem;
  height: 0.6rem;
  border-radius: 0 0.3rem 0.3rem 0;
  background:  var(--theme-color);
  line-height: 0.6rem;
  text-align: center;
  color: #fff;
  font-size: 0.3rem;
  z-index: 3;
}

/* 缺货登记弹窗 */
.register-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 6;
}
.register-cont {
  position: fixed;
  top: 3rem;
  left: 7%;
  width: 86%;
  background: #fff;
  z-index: 7;
  padding: 0.2rem;
  box-sizing: border-box;
  border-radius: 0.2rem;
}
.register-cont .regis-tit {
  width: 100%;
  text-align: center;
  font-size: 0.39rem;
}
.register-cont .regis-item {
  margin-bottom: 0.2rem;
  border-bottom: 0.02rem solid #eee;
  height: 0.8rem;
}
.register-cont .regis-item .item-tit {
  width: 2.3rem;
  font-size: 0.3rem;
}
.register-cont .regis-item .input-box {
  flex: 1;
}
.register-cont .regis-item .input-box input {
  font-size: 0.3rem;
  flex: 1;
}
.register-cont .regis-item .input-box img {
  width: 0.16rem;
  margin-left: 0.2rem;
}

.register-cont .regis-but {
  width: 100%;
  justify-content: space-around;
}
.register-cont .regis-but .but-around {
  width: 1.8rem;
  height: 0.6rem;
  font-size: 0.3rem;
  text-align: center;
  line-height: 0.6rem;
  border-radius: 0.2rem;
  color: #fff;
}
.register-cont .regis-but .but-around.grey {
  background: #c0bfbf;
}
.register-cont .regis-but .but-around.blue {
  background: var(--theme-color);
}

/* 商品弹窗 */
.good-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}
.good-pop {
  position: fixed;
  left: 0;
  bottom: 0rem;
  z-index: 10;
  background: #fff;
  width: 100%;
}
.good-pop .good-top {
  width: 100%;
  padding: 0.18rem 0.2rem;
  box-sizing: border-box;
  border-bottom: 0.02rem solid #eee;
}
.good-pop .good-top img {
  width: 1.8rem;
  max-height: 1.8rem;
  margin-right: 0.2rem;
}
.good-pop .good-top .good-right {
  flex: 1;
  height: 1.8rem;
  justify-content: space-between;
}
.good-pop .good-top .good-right .right-name {
  font-size: 0.3rem;
  font-weight: 600;
}
.good-pop .good-top .good-right .right-price span.price-tit {
  font-size: 0.5rem;
}
.good-pop .good-top .good-right .right-price span {
  font-size: 0.28rem;
}

.good-pop .good-specifi {
  width: 100%;
  padding: 0.18rem 0.2rem;
  box-sizing: border-box;
}
.good-pop .good-specifi .specifi-item {
  margin-bottom: 0.12rem;
}
.good-pop .good-specifi .specifi-item .item-title .tit {
  font-size: 0.28rem;
  color: #333;
  font-weight: 600;
  display: block;
  margin-right: 0.2rem;
}
.good-pop .good-specifi .specifi-item .item-title .tit-parameter {
  font-size: 0.28rem;
  color: #999;
}
.good-pop .good-specifi .specifi-item .last-span {
  margin-left: 0.28rem;
  color: #999;
  font-size: 0.26rem;
}
/* 商品加减 */
.add-subtract {
  flex: 1;
  justify-content: flex-end;
}
.add-subtract .add {
  font-size: 0.36rem;
  border: 0.05rem solid  var(--theme-color);
  color:  var(--theme-color);
  border-radius: 50%;
  display: inline-block;
  width: 0.38rem;
  height: 0.38rem;
  text-align: center;
  line-height: 0.3rem;
  box-sizing: border-box;
}
.add-subtract .num {
  font-size: 0.3rem;
  margin: 0 0.2rem;
  width: 1rem;
  text-align: center;
  border: 0.02rem solid #999;
  border-radius: 0.12rem;
  line-height: inherit;
}
.add-subtract .cut {
  font-size: 0.36rem;
  background:  var(--theme-color);
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  width: 0.38rem;
  height: 0.38rem;
  text-align: center;
  line-height: 0.3rem;
}

.good-pop .good-sure {
  width: 100%;
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  color: #fff;
  background: var(--theme-color);
  margin: 0.2rem auto 0;
  font-size: 0.3rem;
}

/* 购物车弹窗活动 */
.good-pop .good-specifi .specifi-item .active-item {
  width: 100%;
  font-size: 0.3rem;
  color:  var(--theme-color);
  padding: 0.06rem 0;
  box-sizing: border-box;
}
.good-pop .good-specifi .specifi-item .active-item label {
  margin-left: 0.2rem;
}

.good-code {
  font-size: 0.28rem;
}

/* 编辑商品数量弹窗 */
.popup-box {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 11000;
}
.popup-cont {
  position: fixed;
  width: 70%;
  padding: 0.2rem 0 0;
  box-sizing: border-box;
  background: #fff;
  border-radius: 0.12rem;
  left: 15%;
  top: 25%;
  z-index: 11001;
}
.popup-cont .good-tit {
  width: 100%;
  text-align: center;
  font-size: 0.3rem;
  margin-bottom: 0.2rem;
}
.popup-cont .good-num-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.1rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
}
.popup-cont .good-num-box .reduce-g {
  font-size: 0.39rem;
}
.popup-cont .good-num-box input {
  width: 2rem;
  height: 0.6rem;
  padding: 0.1rem;
  margin: 0 0.12rem;
  text-align: center;
  border: 0.02rem solid #c9c4c4;
  border-radius: 0.12rem;
  font-size: 0.3rem;
}
.popup-cont .good-num-box .add-g {
  font-size: 0.39rem;
}
.popup-cont .good-btn {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

.popup-cont .good-btn .btn-g {
  width: 50%;
  font-size: 0.3rem;
  text-align: center;
  color: #999;
}
.popup-cont .good-btn .btn-g.blue {
  color: #58a0f2;
}
.promote-item {
  font-size: 0.28rem;
  margin-left: 0.3rem;
  color:  var(--theme-color);
}
/* 规格 */
.specification{
justify-content: space-between!important;
}
.specification div:nth-child(1) span:nth-child(2){
  font-size: .28rem ;
}
.specification div:nth-child(2){
  font-size: .32rem ;
  padding-right: .5rem;
}
.addContent{
  font-size: .28rem;
  /* width: 8.28rem ; */
   overflow-wrap: break-word;
   font-family: 'SimHei',sans-serif;
   color: #666;
  
}
.addContent img{
	width: 100% !important;
}
.noData{width: 2rem;
    height: 2rem;
    position: absolute;
    top: 1.8rem;
    left: 2.5rem;
    background-color: rgba(128, 127, 127, 0.8);
    border-radius: 50%;
    text-align: center;
    line-height: 2rem;
    font-size: .5rem;
    color: white;}

.fontRedS{color: #D5251D !important;}
</style>
