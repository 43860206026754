<template>
  <div class="contain-box" :style="'background:url('+ boxBg +') no-repeat;'">
    <!-- <div class="playBotn"  @click="playPack" >
      点我开始抢红包
    </div> -->
    <div class="ser_home" v-show="packShow" >
      <ul class="red_packet" id="red_packet" >
        <template v-for="(item, index) in liParams">
          <li
            :style="{ left: item.left, animationDuration: item.durTime, webkitAnimationDuration: item.durTime}"
            :class="item.cls"
            :data-index="index"
            @webkitAnimationEnd="removeDom"
           :key="index">
            <a
              @click.stop="redPack(index)"
              v-if="item.isShow"
            >  
              <i :style="{ transform: item.transforms, webkitTransform: item.transforms,backgroundImage:'url(' + redPackImg + ')',}"></i>
            </a>
          </li>
        </template>
      </ul>
      <div class="packRed">
        <img src="@/assets/images/topIcon.png" alt="">
        <span>剩余时间：{{packTime}}秒</span>
      </div>
    </div>

    <!-- 红包倒计时 -->
    <div class="redPack-progress" v-if="countDown!='开始'">
      <div class="progress-num">{{countDown}}</div>
      <div class="progress-box" :style="'background:url('+ progressBg + ') repeat-x;'">
        <div class="progress-line" :style="'background:url('+ progressColor + ') repeat-x;'"></div>
      </div>
    </div>
    <!-- 获取红包之后的弹窗 -->
    <div class="redPack-after" v-if="redPackAfterShow" @click="closeAfter"></div>
    <div class="redpack-after-pop" v-if="redPackAfterShow" @click="closeAfter">
      <img src="@/assets/images/newyear_redpacket/successAlert.png" alt="">
    </div>

  </div>
</template>

<script>
export default {
  name: "whole",
  data() {
    return {
      liParams: [],
      timer: null,
      duration: 100000, // 红包定义时间
      packShow: true,
      packTime: 30, //弹窗展示时间
      setInter: "",
      packetArr:[],   // 红包的id,
      redPackNum: 0,    // 获取红包数量
      redPackAfterShow: false,

      progressBg: require('@/assets/images/progressBg.png'),
      progressColor: require('@/assets/images/progressColor.png'),
      boxBg: require('@/assets/images/newyear_redpacket/redpacketRain_bg.jpeg'),
      redPackImg: require('@/assets/images/newyear_redpacket/redPacket.png'),
      countDown: 3,
      
    };
  },
  created() {},
  mounted() {
    var that = this;
    // 倒计时
    var countDown = that.countDown;
    var setCountDown = setInterval(function(){
      if(countDown > 1){
        countDown--;
      }else if(countDown == 1){
        countDown = "开始";
        clearInterval(setCountDown);
        setTimeout(function(){
          that.playPack();
        },1000)
        
      }
      // console.log(countDown);
      that.countDown = countDown;
      
    },1000)
  },
  methods: {

    // 关闭获取红包之后的弹窗
    closeAfter(){
      var that = this;
      that.redPackAfterShow = false;
    },

    // 获取的红包雨上传到后台
    upRedpack(){
      var that = this;
      var packetArr = that.packetArr;
      var redPackNum = that.redPackNum;
      if(redPackNum != 0){
        var num = packetArr.length;
        num = num - redPackNum;
        packetArr.splice(redPackNum,num);
      }else{
        packetArr = [];
      }
      var data = {
        Detail: packetArr,
      }
      that.public.request('post','api/ReadPacket/GetReadPacketByOrgId',data, res => {
        if(res.data.errorCode == '00'){
          // console.log("成功");
        }
      })

    },
    
    // 请求红包雨数据
    getredPackDetail(){
      var that = this;
      that.public.request('post','api/ReadPacket/GetReadPacketsListByOrgId',{}, res => {
        if(res.data.errorCode == '00'){
          var arr = res.data.rows;
          var packetArr = that.packetArr;
          if(arr != null){
            arr.forEach(item => {
              packetArr.push({
                Id: item.Id,
                PacketId: item.PacketId,
              });
            })
          }
          // console.log("packetArr",packetArr);
          that.packetArr = packetArr;
          that.startRedPacket();
        }
        
      })
    },
    
    // 执行红包雨
    playPack() {
      var that = this;
      that.packShow = true;
      that.getredPackDetail();
      
      setTimeout(() => {
        that.packShow = false;
        var num = 0;
        that.liParams.forEach(item => {
          if(item.isShow == false){
            num++;
          }
        })
        that.redPackNum = num;
        that.redPackAfterShow = true;
        clearInterval(that.setInter);
        that.upRedpack();

        // that.$dialog
        //   .alert({
        //     title: "标题",
        //     message: "恭喜您抢到了" + that.redPackNum + "个红包",
        //   })
        //   .then(() => {
            
        //     clearInterval(that.setInter);
        //     // location.reload();
        //   });

      }, 30000);
      that.setInter = setInterval(() => {
        that.packTime -= 1;
      }, 1000);
    },
    /**
     * 开启动画
     */
    startRedPacket() {
      var that = this;
      let win =
        document.documentElement.clientWidth || document.body.clientWidth;
        
      let left = parseInt(Math.random() * (win - 50) + 0);
      let rotate = parseInt(Math.random() * (45 - -45) - 45) + "deg"; // 旋转角度
    //   let scales = (Math.random() * (12 - 8 + 1) + 8) * 0.1; // 图片尺寸
      let durTime = Math.random() * (2.5 - 1.2 + 1) + 3 + "s"; // 时间  1.2和1.2这个数值保持一样
      that.liParams.push({
        left: left + "px",
        cls: "move_1",
        // transforms: "rotate(" + rotate + ") scale(" + scales + ")",
        transforms: "rotate("+rotate+")",
        durTime: durTime,
        isShow: true,
      });
      setTimeout(() => {
        // 多少时间结束
        clearTimeout(that.timer);
        return;
      }, that.duration);
      that.timer = setTimeout(() => {
        that.startRedPacket();
      }, 200);
    },
    /**
     * 回收dom节点
     */
    removeDom(e) {
      let target = e.currentTarget;
      document.querySelector("#red_packet").removeChild(target);
    },
    redPack(index) {
      var that = this;
      var liParams = that.liParams;
      liParams[index].isShow = false;
      that.liParams = liParams;
      // console.log(that.liParams);
      // that.packNum += 1;
      
      
    },
  },
};
</script>

<style scoped>
/* 页面背景图 */
.contain-box{
  background-size:100% 100% !important;
  width:100%;
  height:100vh;
}

/* 红包倒计时 */
.redPack-progress{
  position:fixed;
  width:80%;
  top:36%;
  left:10%;
  z-index:9;
}
.redPack-progress .progress-num{
  width:100%;
  text-align:center;
  font-size:0.6rem;
  font-weight:700;
  color:rgb(255, 223, 88);
}
.redPack-progress .progress-box{
  width:80%;
  height:0.3rem;
  border-radius:0.22rem;
  margin:0.2rem auto;
}
.redPack-progress .progress-box .progress-line{
  width:100%;
  height:100%;
  border-radius:0.22rem;
}

/* 获取红包之后的弹窗 */
.redPack-after{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background:rgba(0,0,0,0.5);
  z-index:10;
}
.redpack-after-pop{
  position:fixed;
  width:70%;
  top:30%;
  left:15%;
  z-index:11;
}
.redpack-after-pop img{
  width:100%;
}


.playBotn {
  width: 100%;
  font-size: 40px;
  text-align: center;
  background: red;
  color: #fff;
}
.ser_home {
  position: fixed;
  top: 0;
  left: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  z-index: 99;
  width: 100%;
  height: 100vh;
}
.packRed {
  position: fixed;
  width:100%;
  top:0.1rem;
  display:flex;
  align-items:center;
  justify-content:center;
  
}
.packRed img{
  width:0.7rem;
}
.packRed span{
  font-size:0.36rem;
  font-weight:700;
  color:#fff;
  margin-left:0.3rem;
}

p {
    padding: 10px 15px;
    box-sizing: border-box;
    border-radius: 20px;
    background: red;
    color: #fff;
    font-size: 26px;
  }
.red_packet {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  
}
i {
    width: 70px;
    height: 90px;
    display: block;
    background-size: 100% 100% !important;
  }
  li {
    position: absolute;
    animation: all 3s linear;
    top: -100px;
    z-index: 10;
    
  }
  .move_1 {
      -webkit-animation: aim_move 5s linear 1 forwards;
      animation: aim_move 5s linear 1 forwards;
    }
  a {
    display: block;
  }

@keyframes aim_move {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(120vh);
    transform: translateY(120vh);
  }
}
</style>

