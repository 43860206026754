<template>
  <div class="container">
     <!-- 顶部标题 -->
        <div class="tab-top flex-wrap" >
            <img class="tab-img" src="@/assets/images/tab/icon_left.png" alt="" @click="navigationBack">
            <div class="tab-tit">全部商品</div>
        </div>
    <!-- 商品列表页面 -->
    <div class="goods-cont">

      <!-- 商品列表 -->
          <shopList class="shopLists"  :list="goodList" @toDetail='toGoodsDetail' @cartShow='onCartShow' @onCartShow='onCartShow1'></shopList>

      <div class="loading-box flex-center" v-if="noMore && goodList.length > 4">
        <span class="load-txt">已经到底了！</span>
      </div>
      <!-- 加载样式 -->
      <div
        class="loading-box flex-center"
        v-if="showLoading && goodList.length > 0"
      >
        <img class="load-img" src="@/assets/images/index/loading.gif" alt="" />
        <span class="load-txt">正在加载</span>
      </div>
      <!-- 暂无数据 -->
      <div class="empty-box" v-if="goodList.length == 0">
        <div class="no-data">
          <img src="@/assets/images/cart/noActiveDrug.png" alt="" />
          <span>暂无数据</span>
        </div>
      </div>
    </div>

    <!-- 分类弹窗 -->
    <div class="sort-pop" v-if="sortShow" @click="closeClass()"></div>
    <div class="sort-cont flex-wrap1" v-if="sortShow">
      <div class="sort-left">
        <div
          class="left-item"
          v-for="(item, index) in typeList"
          :key="index"
          :class="typeIndex == index ? 'active' : ''"
          @click="changtype(index)"
        >
          {{ item.text }}
        </div>
      </div>
      <div class="sort-right flex-wrap">
        <div class="sort-top">
          <div class="sort-type flex-wrap">
            <div
              class="right-item"
              v-for="(item1, i) in typeList[typeIndex].children"
              :key="i"
              @click="changeTab(item1.code)"
              :class="TypeId == item1.code ? 'active' : ''"
            >
              {{ item1.text }}
            </div>
          </div>
        </div>
        <div class="cancel" @click="cancelType()">取消</div>
      </div>
    </div>

    <!-- 商品购物车弹窗 -->
    <cart-pop
      :cartShowPop="cartShowPop"
      :goodInfo="goodInfo"
      @diffCartShow="closeCart"
    ></cart-pop>

    <!-- 活动商品购物车弹窗 -->
    <active-cart
      :cartShowPop="activeCartShowPop"
      :goodSpid="goodSpid"
      :goodDetail="goodDetail"
      :typePage="typePage"
      @diffCartShow="closeCart1"
    ></active-cart>

  
  </div>
</template>

<script>
import cartPop from "../../components/cartPop.vue";
import shopList from "../../components/shopList.vue"
// 引入活动弹窗组件
import activeCart from "../../components/activeCartPop.vue";
// 引入跳转活动详情的js
import jumpActivity from "../../assets/js/toActivityPage";
export default {
  components: {
    cartPop,
  
    activeCart,
    shopList,
  },
  data() {
    return {
      type: "", // 接收不同页面传过来的类型
      page: 1,
      showLoading: true, // 加载样式
      noMore: false, // 暂无数据，已经到达底部
      sortShow: false, // 分类弹窗
      screenShow: false, // 筛选弹窗
      goodList: [], // 全部商品列表
      sortField: "Price", // 售价排序字段
      sortType: "", // 售价排序方式 asc价格从低到高，desc价格从高到低
      keyword: "", // 搜索内容
      TypeId: "", // 选择分类的id
      OnlyPackageSold: "", // 默认为空，0零货， 1整件
      tag: "", // 从首页进入的type为10的类型，传递的tag
      typeList: [], // 分类数据
      typeIndex: "0",

      cartShowPop: false, // 组件显示隐藏
      goodInfo: {}, // 向组件传递商品详情
      typePage: "", // 向组件传递页面类型

      historyShow: false, // 历史搜索
      isBack: true,

      scanUrl: "", // 微信扫码路径
      scanCode: "", // 条形码的数值

      navTitle: "", // 顶部导航标题

      activeCartShowPop: false, // 组件显示隐藏
      goodSpid: "", // 向组件传递的spid
      goodDetail: {}, // 向组件传递商品详情
      typePage: "", // 向组件传递页面类型
      token: sessionStorage.getItem('member_token'),
      GoodsSource:'',//期货现货判断
      isGoodsSource:false,
      ShopNo:'',
      
    };
  },
  created() {
     var params=this.public.urlObj(location.href);
       this.ShopNo=params.ShopNo;
       this.getAllGoods();
    
   
  },
   mounted() {
    var that = this;
    that.isBack = that.$route.meta.isBack;
 
    if (window.history && window.history.pushState && !that.isBack) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", that.popstateBack, true);
    }

    window.addEventListener("scroll", that.onReachBottom);
  },
  destroyed() {
    this.$store.commit("setHeadTitle", "");
    window.removeEventListener("scroll", this.onReachBottom);
    window.removeEventListener("popstate", this.popstateBack, true);
  },
  methods: {
    // 返回上一页
   navigationBack() {
      this.$router.push({
         path: '/cart',
      });
    },
  
   // 活动商品弹窗显示
    onCartShow1(id,url,item) {
      //  console.log('item',item);
      var that = this; // 查看是否登录

      if (that.token == null) {
        var orgId = localStorage.getItem("orgId");
        that.$dialog
          .confirm({
            message: "你还未登录，是否登录!",
          })
          .then(() => {
            that.$router.push({
              path: "/login",
              query: {
                orgId: orgId,
                auto: 0,
              },
            });
          })
          .catch(() => {
            // console.log('取消');
          });
        return false;
      }

      that.activeCartShowPop = true;
      if(item.CurActivityData.ActivityType == 0){ // 团购
        that.typePage = "group";
      }else if(item.CurActivityData.ActivityType == 1){ // 秒杀
        that.typePage = "seckill";
      }else if(item.CurActivityData.ActivityType == 2){  // 今日特价
        that.typePage = "bargain";
      }else if(item.CurActivityData.ActivityType == 3){  // 集采
        that.typePage = "purch";
      }
      that.goodDetail = item;
      that.goodSpid = item.spid;
      

    },

    // 商品弹窗隐藏
    closeCart1() {
      var that = this;
      that.activeCartShowPop = false;
    },
   
    // 购物车弹窗组件
    onCartShow(spid) {
      var that = this; // 查看是否登录

      if (that.token == null) {
        var orgId = localStorage.getItem("orgId");
        that.$dialog
          .confirm({
            message: "你还未登录，是否登录!",
          })
          .then(() => {
            that.$router.push({
              path: "/login",
              query: {
                orgId: orgId,
                auto: 0,
              },
            });
          })
          .catch(() => {
          });
        return false;
      }

      that.cartShowPop = true;
      var goodList = that.goodList;
      goodList.forEach((item, i) => {
        if (spid == item.spid) {
          that.goodInfo = item;
        }
      });
    },
    // 关闭组件弹窗
    closeCart() {
      var that = this;
      that.cartShowPop = false;
    },

   //  跳转详情
    toGoodsDetail(item) {
      var that = this;
      if(item.CurActivityData == null){ // 普通商品
        that.$router.push({
          path: "/goodsDetail",
          query: {
            spid: item.spid,
          },
        });
      }else if(item.CurActivityData != null){  // 活动商品
        jumpActivity.jumpPage(item);
      }
    },

    // 关闭分类弹窗
    closeClass() {
      this.sortShow = false;
    },

    // 全部商品
    getAllGoods() {
      var that = this;
      var page = that.page;
      var sortField = that.sortField;
      var sortType = that.sortType;
      var keyword = that.keyword;
      var TypeId = that.TypeId;
      var OnlyPackageSold = that.OnlyPackageSold;
      var tag = that.tag;
       that.allgoodList=[];
      var GoodsSource=that.GoodsSource;
       var data={};
  var data={};
        data = {
          page: page,
          rows: 10,
          SortField: sortField,
          SortType: sortType,
          keyword: keyword,
          TypeId: TypeId,
          GoodsSource: GoodsSource,
          tag: tag,
          ShopNo:that.ShopNo,
          }
      // console.log(data);
      that.$toast.loading({
        message: "加载中",
        duration: 0,
      });
      var IsSpecial = sessionStorage.getItem("IsSpecial")
      if(IsSpecial){
        var url = '/api/SCustomerGoods/QueryPage';
      }else{
        var url = '/api/Store/QueryStoreGoods';
      }
      that.public.request("post", url, data, (res) => {
        // console.log(res);
        if (res.data.errorCode == "00") {
          that.$toast.clear();
          var allArr = [];
          var initArr = that.goodList;
          var curArr = res.data.rows ? res.data.rows : [];
          var lastPageLength = curArr.length;
          if (page > 1) {
            allArr = initArr.concat(curArr);
          } else {
            allArr = res.data.rows;
          }
          allArr.forEach((item, index) => {
            // item.activityPrice = null;
            if(item.ActivityCacheDataList != null){
              item.ActivityCacheDataList.forEach((item1, i) => {
                if (item1.ActivityType === 1) {   // 秒杀
                  // 天时分秒  倒计时
                  that.public.countdown(item1.EndTime, (res) => {
                    item1.countTime = res;
                  });
                }
              });
            }
            if(item.ActivityCacheDataList == null){
              item.ActivityCacheDataList = [];
            }
          });
          // 如果搜索内容有数据，则把搜索内容存放到store里面
          if (allArr.length > 0 && keyword) {
            var arr = that.$store.state.searchHistory;
            arr.unshift(keyword);
            arr = Array.from(new Set(arr));
            that.$store.commit("setHistory", arr);
          }
          that.goodList = allArr;
          if (lastPageLength < 10) {
            that.noMore = true;
            that.showLoading = false;
          }
        } else if (res.data.errorCode == "99") {
          that.$toast.clear();
          if (page <= 1) {
            that.goodList = [];
            that.noMore = false;
            that.showLoading = false;
          } else {
            that.noMore = true;
            that.showLoading = false;
          }
        }
         that.goodList.forEach(item=>{
                var list=[];
                item.ActivityCacheDataList.forEach(item1=>{
                    list.push(item1.ActivityTypeName);
                });
                const set = new Set(list);
                item.activeList=set;
            }); 
          that.allgoodList=that.goodList;   
      });
    },

    //到达底部
    onReachBottom() {
      var that = this;
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      // console.log(scrollTop,'距离顶部');
      if (scrollTop > 0) {
        if (scrollTop + windowHeight + 5 >= scrollHeight) {
          //写后台加载数据的函数
          // console.log('noMore',that.noMore);
          // console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
          if (that.goodList.length > 0) {
            var page = that.page;
            var noMore = that.noMore;
            page++;
            that.page = page;
            if (noMore) {
              // console.log('已经到底了');
            } else {
              console.log("加载");
              that.getAllGoods();
            }
          }
        }
      }
    },
    // 监听返回
    popstateBack() {
      var that = this;
      var isBack = that.isBack;
      if (!isBack) {
        var userAgent = navigator.userAgent;
        var isAndroid =
          userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1; //android终端
        var isIos =
          (userAgent.indexOf("iphone") > -1 ||
            userAgent.indexOf("ipad") > -1 ||
            userAgent.indexOf("ipod") > -1) &&
          (platform.indexOf("iphone") > -1 ||
            platform.indexOf("ipad") > -1 ||
            platform.indexOf("ipod") > -1);

        if (window.WeixinJSBridge) {
          window.WeixinJSBridge.call("closeWindow"); // 微信
        } else if (window.AlipayJSBridge) {
          window.AlipayJSBridge.call("closeWebview"); // 支付宝
        } else if (isAndroid) {
          window.obj.closePageLppzRequest(""); // 安卓app
        } else if (isIos) {
          window.webkit.messageHandlers.closePageLppzRequest.postMessage(""); //ios app
        } else {
          location.replace("about:blank", "_top");
        }
      }
    },
  },
};
</script>

<style scoped>
/* 顶部导航标题 */
.tab-top {
  width: 100%;
  height: 0.9rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
  background: #fff;
}

.tab-top .tab-img {
  width: 0.4rem;
  height: 0.4rem;
}

.tab-top .tab-tit {
  flex: 1;
  text-align: center;
  font-size: 0.32rem;
  /* padding-right:0.5rem; */
}
/* 顶部固定 */
.fixed-top {
  position: fixed;
  top: 0rem;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 10;
}


/* 搜索框 */
.input-box{
    width:100%;
    padding:0.16rem 0.3rem;
    box-sizing:border-box;
    border-bottom:0.02rem solid #eee;
    /* margin-top:0.9rem; */
}
.input-box .scan-code{
    width:0.46rem;
    height:0.46rem;
    margin-right:0.2rem;
}
.input-box .back-img{
    width:0.42rem;
    margin-right:0.2rem;
}
.input-box .search-input{
    flex:1;
    border:0.02rem solid #eee;
    border-radius:0.2rem;
    padding:0.12rem 0.2rem;
    box-sizing:border-box;
}
.input-box .search-input input{
    flex:1;
    font-size:0.28rem;
}
.input-box .search-input img{
    width:0.36rem;
    height:0.36rem;
    margin-left:0.2rem;
}


.font-blue {
  color: #007aff;
}

/* 商品列表页面 */
.goods-cont {
  width: 100%;
  margin-top: 1rem;
}
.goods-cont .screen-nav {
  width: 100%;
  height: 0.9rem;
  padding: 0 0.3rem;
  box-sizing: border-box;
  border-bottom: 0.02rem solid #eee;
  position: relative;
}
.goods-cont .screen-nav span {
  font-size: 0.32rem;
  color: #333;
}
.goods-cont .screen-nav span.active {
  color: #2f2fff;
}
.goods-cont .screen-nav .sort img.price-img {
  width: 0.38rem;
  margin-left: 0;
}
.goods-cont .screen-nav .sort img {
  width: 0.28rem;
  height: 0.28rem;
  margin-left: 0.1rem;
}
.goods-cont .screen-nav .nav-type {
  position: absolute;
  left: 0.2rem;
  top: 0.7rem;
  border: 0.02rem solid #eee;
  padding: 0.1rem 0.2rem;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 0 0.2rem 0.03rem #eee;
  border-radius: 0.2rem;
  z-index: 9;
}
.goods-cont .screen-nav .nav-type span {
  display: block;
  font-size: 0.3rem;
  color: #666;
  margin-bottom: 0.1rem;
}

.goods-cont .floor-list {
  width: 100%;
}
.floor-list .floor-item {
  width: 100%;
  padding: 0.2rem 0.3rem;
  box-sizing: border-box;
  border-bottom: 0.02rem solid #eee;
}
.floor-list .floor-item .floor-good {
  width: 2.2rem;
  max-height: 2.5rem;
  margin-right: 0.2rem;
}
.floor-list .floor-item .floor-good img {
  width: 100%;
  height: 100%;
  max-height: 2rem;
  object-fit: contain;
}
.floor-list .floor-item .floor-specifi {
  position: relative;
  width: 100%;
}
.floor-list .floor-item .floor-specifi span {
  display: block;
}
.floor-list .floor-item .floor-specifi span.specifi-name {
  font-size: 0.3rem;
  width: 4rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
}
.floor-list .floor-item .floor-specifi span.specifi-num {
  font-size: 0.24rem;
}
.floor-list .floor-item .floor-specifi span.specifi-company {
  font-size: 0.28rem;
  width: 4.3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.floor-list .floor-item .floor-specifi .specifi-price-box span {
  display: inline-block;
}
.floor-list .floor-item .floor-specifi span.specifi-price {
  font-size: 0.32rem;
  margin-right: 0.18rem;
}
.floor-list .floor-item .floor-specifi span.specifi-old-price {
  font-size: 0.22rem;
  text-decoration: line-through;
}
.floor-list .floor-item .floor-specifi .shop-cart {
  position: absolute;
  right: 0rem;
  bottom: 0.2rem;
  width: 0.36rem;
}
/* 库存、满赠、远近效期 */
.floor-list .floor-item .floor-specifi .specifi-stock span {
  font-size: 0.24rem;
}
.floor-list .floor-item .floor-specifi .specifi-time {
  font-size: 0.24rem;
}
/* 活动 */
.floor-list .floor-item .active-box {
  width: 100%;
}
.floor-list .floor-item .active-box .active-tit {
  font-size: 0.24rem;
}

/* 分类弹窗 */
.sort-pop {
  position: fixed;
  top: 1.9rem;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
}
.sort-cont {
  position: fixed;
  top: 1.9rem;
  left: 0;
  width: 100%;
  height: 5rem;
  background: #fff;
  z-index: 3;
  border-radius: 0 0 0.2rem 0.2rem;
}
.sort-cont .sort-left {
  width: 22%;
  background: #f7f7f7;
  height: 100%;
  overflow: hidden;
  overflow: auto;
  border-radius: 0 0 0 0.2rem;
}
.sort-cont .sort-left .left-item {
  width: 100%;
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  font-size: 0.32rem;
  white-space: nowrap;
  padding: 0 0.12rem;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sort-cont .sort-left .left-item.active {
  background: #fff;
  color: #2fbdff;
}
.sort-cont .sort-right {
  width: 78%;
  border-radius: 0 0 0 0.2rem;
  padding: 0.2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.sort-cont .sort-right .sort-top {
  width: 100%;
  height: 80%;
  overflow-y: auto;
}
.sort-cont .sort-right .sort-type {
  flex-wrap: wrap;
}
.sort-cont .sort-right .cancel {
  width: 100%;
  height: 16%;
  text-align: center;
  line-height: 0.8rem;
  font-size: 0.3rem;
  color: #fff;
  background: #2fbdff;
  border-radius: 0.12rem;
  margin: 0 auto;
}
.sort-cont .sort-right .right-item {
  padding: 0.12rem 0rem;
  font-size: 0.28rem;
  border-radius: 0.1rem;
  margin: 0 0.18rem 0.2rem 0;
  background: #b1afaf;
  color: #fff;
  width: 31%;
  text-align: center;
  white-space: nowrap;
}
.sort-cont .sort-right .right-item.active {
  color: #2fbdff;
  border: 0.02rem solid #2fbdff;
  background: #fff;
  box-sizing: border-box;
}
.sort-cont .sort-right .right-item:nth-child(3n) {
  margin-right: 0;
}

/* 筛选弹窗 */
.screen-popup {
  position: fixed;
  top: 0.9rem;
  left: 0;
  right: 0;
  bottom: 1.1rem;
  background: rgba(0, 0, 0, 0.4);
  z-index: 4;
}
.screen-cont {
  position: fixed;
  top: 0.9rem;
  left: 20%;
  bottom: 1.1rem;
  width: 80%;
  background: #fff;
  z-index: 5;
}
.screen-cont .screen-top {
  width: 100%;
  height: 0.8rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
  font-size: 0.36rem;
  color: #666;
  background: #f7f7f7;
  line-height: 0.8rem;
}

.screen-cont .screen-box {
  width: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
}
.screen-cont .screen-box .screen-item .item-tit {
  width: 100%;
  height: 0.5rem;
}
.screen-box .screen-item .item-tit span {
  font-size: 0.3rem;
}
.screen-box .screen-item .item-tit img {
  width: 0.28rem;
  height: 0.28rem;
}

.screen-box .screen-item .label-list {
  width: 100%;
  flex-wrap: wrap;
  margin-top: 0.16rem;
  border-bottom: 0.02rem solid #eee;
  min-height: 1.2rem;
}
.screen-box .screen-item:last-child .label-list {
  border-bottom: 0;
}
.screen-box .screen-item .label-list .label-item {
  padding: 0.03rem 0.2rem;
  font-size: 0.28rem;
  background: #f7f7f7;
  border-radius: 0.28rem;
  margin: 0 0.4rem 0.2rem 0;
}
.screen-box .screen-item .label-list .label-item.active {
  background: #2fbdff;
  color: #fff;
}
</style>
