<template>
    <div class="contain-box">
        <div class="order-code flex-sp" v-if="JSON.stringify(logisticsObj) != '{}'">
            <div class="code-txt">运单号：{{logisticsObj.nu}}</div>
            <div class="code-btn tag" @click="onCopy(logisticsObj.nu)">复制</div>
        </div>
        <!-- 物流信息 -->
        <div class="status-box">
            <ul class="status-list" v-if="JSON.stringify(logisticsObj) != '{}'">
                <li class="status-item"  v-for="(item,index) in logisticsObj.data" :key="index" :class="[index==0? 'active':'',index==(logisticsObj.data.length-1)?'item-bottom':'']">
                    <div class="item-time">{{item.time}}</div>
                    <div class="item-txt" v-html="item.context"></div>
                </li>
                <!-- <li class="status-item item-bottom">
                    <div class="item-time">商家打印订单</div>
                </li> -->
            </ul>

            
            
        </div>
        <!-- 暂无物流信息 -->
        <div class="empty-box" v-if="JSON.stringify(logisticsObj) == '{}'">
            <div class="no-data">
            <img src="@/assets/images/cart/icon_logistics.png" alt="" />
            <span>商家正在加急</span>
            </div>
        </div>
        
    </div>
</template>

<script>
import Clipboard from "clipboard";
export default {
    data(){
        return{
            logisticsObj: {},   // 物流信息
            Id: '',     // 商品id
        }
    },
    created(){
        var that = this;
        that.Id = that.$route.query.id;
        that.getlogistics();
    },
    methods:{
        
        // 复制
        onCopy(val){
            var that = this;
            let clipboard = new Clipboard(".tag", {
                text: function() {
                    return val;
                },
            });
            clipboard.on("success", (e) => {
                that.$toast("复制成功");
                // 释放内存
                clipboard.destroy();
            });
            clipboard.on("error", (e) => {
                that.$toast("复制失败");
                clipboard.destroy();
            });
        },

        // 物流信息
        getlogistics(){
            var that = this;
            that.$toast.loading({
                message: '加载中...',
                duration: 0,
            })
            var data = {
                Id: that.Id,
            }
            that.public.request('post','/api/ordersinfo/Logistics',data, res => {
                console.log(res);
                that.$toast.clear();
                if(res.data.errorCode == "00"){
                    var result = JSON.parse(res.data.rows);
                    console.log(result);
                    var telRegPhone = /(([0\+]\d{2,3}-)?(0\d{2,3})-)(\d{7,8})(-(\d{3,}))?/g;
                    var telRegMobile = /[1][3,5,7,8][0-9]{9}/g
                    
                    var phone = '';
                    let mobile = '';
                    result.data.map((item,index) => {
                        // if(telRegPhone.test(item.context)){
                        //     phone = item.context.match(telRegPhone);
                        //     console.log(phone);
                        //     var html = '';
                        //     for(var i=0; i<phone.length; i++){
                        //         console.log(phone[i],"------------");
                        //         html += "<span onclick='call("+phone[i].length+"))'>"+phone[i]+"</span>"
                        //         console.log(html);
                        //         item.context = item.context.replace(telRegPhone,html)
                        //     }
                            
                        // }
                        if(telRegMobile.test(item.context)){
                            mobile = item.context.match(telRegMobile);
                            mobile.forEach((item1,index)=>{
                                // item.context = item.context.replace(telRegMobile,"<span style='color:#8383f8;'>"+item1+"</span>")
                                item.context = item.context.replace(telRegMobile,`<a  href="tel:${item1}" style='color:#0592fa;'>${item1}</a>`)
                            })
                        }
                    })
                    
                    
                    that.logisticsObj = result;
                }else if(res.data.errorCode == '99'){
                    that.logisticsObj = {};
                }
                
                
                

            })
        }
    }
}
</script>


<style scoped>
    /* 运单号 */
    .order-code{
        width:100%;
        height:1rem;
        background:#eee;
        padding:0.2rem 0.4rem;
        box-sizing:border-box;
    }
    .order-code .code-txt{
        font-size:0.3rem;
    }
    .order-code .code-btn{
        font-size:0.3rem;
        padding:0.02rem 0.2rem;
        background:#fff;
        border:0.01rem solid #868484;
        box-sizing:border-box;
        border-radius:0.12rem;
    }

    /* 物流信息 */
    .status-box{
        width:100%;
        padding:0.2rem 0.3rem;
        box-sizing:border-box;
    }
    .status-box .status-list .status-item{
        width:100%;
        padding-left:0.4rem;
        box-sizing:border-box;
        border-left:0.02rem solid #b9b7b7;
    }
    .status-box .status-list .status-item.item-bottom{
        border-left:none;
    }
    .status-box .status-list .status-item:before{
        content:'';
        width:0.3rem;
        height:0.3rem;
        display: inline-block;
        border-radius:50%;
        border:0.02rem solid #999;
        background:#fff;
        margin-right:0.4rem;
        margin-left:-0.58rem;
    }
    .status-box .status-list .status-item.active:before{
        background:#0592fa;
        border:0.02rem solid #0592fa;
    }
    .status-box .status-list .status-item .item-time{
        font-size:0.3rem;
        color:#666;
        margin-top:-0.45rem;
    }
    .status-box .status-list .status-item .item-txt{
        font-size:0.3rem;
        color:#999;
        padding:0.03rem 0 0.45rem;
    }
</style>