<template>
    <div class="container">
        <div class="plan-img">
            
            <img :src="item.SufixUrl" v-for="(item,index) in planDetail" :key="index">
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            planDetail: [],
        }
    },
    created(){
        var that = this;
        var id = that.$route.query.id;
        // 请求详情
        that.getPlanDetail(id);
    },
    methods:{
        // 请求详情
        getPlanDetail(id){
            var that = this;
            var data = {
                PaperPlanId: id,
            }
            that.public.request('post','/api/PaperPlanSuffix/QueryList',data,res => {
                console.log(res);
                if(res.data.errorCode == '00'){
                    that.planDetail = res.data.rows;
                }
            },'get')
        }
    }
}
</script>

<style scoped>
    .plan-img{ width:100%;padding:0.2rem;box-sizing:border-box; }
</style>