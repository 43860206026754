import pub from '../../../../public/public.js'
// 团购、集采列表
function groupQueryList(model,callBack) {
    return pub.request('post','/api/groupActivity/queryList',model,callBack)
}

// 团购详情
function groupQueryInfo(model,callBack) {
    return pub.request('post','/api/groupActivity/queryInfo',model,callBack)
}

// 今日特价列表
function dailySpecialQueryList(model,callBack) {
    return pub.request('get','/api/dailySpecial/queryList',model,callBack)
}

// 秒杀列表
function SeckillQueryList(model,callBack) {
    return pub.request('post','/api/secKill/queryList',model,callBack)
}



export default {
	groupQueryList,
    groupQueryInfo,
    dailySpecialQueryList,
    SeckillQueryList,
}