<template>
  <div class="billManage">
    <div class="filter" :style="{top:topHeight+'rem'}">
      <img src="@/assets/images/icon_loudou.png" alt="" />
      <span @click="goToBill">去开发票</span>
      <span @click="isFilter" class="filterbtn">发票筛选</span>
    </div>
    <!-- 商品 -->
    <div class="floor-box" v-if="billList.length">
      <div class="floor-list" v-for="(item, ind) in billList" :key="ind">
        <div class="floor-item">
          <div class="invoiceTime" @click="billDetails(item)">
            <span>{{ item.BillDate }}</span>
            <img src="@/assets/images/icon_jiantpu2.png" alt="" />
          </div>

          <div class="invoiceStyle">
            <div class="invoiceName">
              <span>{{ item.SendOrgName }}</span>
              <span class="invoice" v-if="item.BillType == 0">未知</span>
              <span class="invoice" v-if="item.BillType == 10"
                >增值税普通发票</span
              >
              <span class="invoice" v-if="item.BillType == 20"
                >增值税专用发票</span
              >
              <span class="invoice" v-if="item.BillType == 30">电子发票</span>
            </div>
            <div class="invoiceSum">
              <span
                >共计：<s>{{ item.OrderCost }}元</s></span
              >
            </div>
          </div>

          <div class="invoiceDetails">
            <div class="invoiceImg">
              <div class="preview" v-if="item.BillPics">
                <span @click="previewPicture(item.BillPics)">点击预览</span>

                <span>共{{ item.BillPics.length }}张</span>
              </div>

              <span v-else>暂无内容</span>
            </div>
            <div class="order1">
              <span>包含{{ item.OrderNum }}个订单</span>
              <span>{{ item.StatusName }}</span>
              <span
                v-if="item.BillPicUrl"
                @click="getAddress(item.BillPicUrl)"
                class="tag"
                >复制附件地址</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="noData" v-else>
      暂无数据~~
    </div>

    <!-- 筛选弹层 -->
    <van-popup
      v-model="isshowFilter"
      position="right"
      :style="{ height: '100%', width: '80%' }"
      @close="cancelbtn"
    >
      <div class="order">
        <div class="date">
          <p>订单日期</p>
          <div class="choose">
            <input
              type="text"
              placeholder="起始时间"
              @click="starTimebtn"
              :value="dateBegin"
              readonly
            />

            <i class="line"></i>
            <input
              type="text"
              placeholder="终止时间"
              @click="endTimebtn"
              :value="dateEnd"
              readonly
            />
          </div>
        </div>

        <div>
          <p>订单金额范围</p>
          <div class="choose">
            <input
              type="number"
              name=""
              id=""
              placeholder="最低价"
              v-model="filterList.OrderCost"
            />
            <i class="line"></i>

            <input
              type="number"
              placeholder="最高价"
              v-model="filterList.OrderCostEnd"
            />
          </div>
        </div>

        <div>
          <p>审核状态类型</p>
          <div class="choose ">
            <span
              class="auditBtn"
              :class="{ checked: filterList.Status == 10 }"
              @click="clockBtns(10)"
              >待审核</span
            >
            <span
              class="auditBtn"
              :class="{ checked: filterList.Status == 20 }"
              @click="clockBtns(20)"
              >已通过</span
            >
          </div>
        </div>

        <div>
          <p>发票 类型</p>
          <div class="choose  style">
            <span
              :class="{ checked: filterList.BillType == 10 }"
              @click="activeStyleBtn(10)"
              >增值税普通发票</span
            >
            <span
              :class="{ checked: filterList.BillType == 20 }"
              @click="activeStyleBtn(20)"
              >增值税专用发票</span
            >
            <span
              :class="{ checked: filterList.BillType == 30 }"
              @click="activeStyleBtn(30)"
              >电子发票</span
            >
          </div>
        </div>

        <div class="choose  processBtn">
          <span class="cancel" @click="cancelbtn">取消</span>
          <span @click="sureBtn()">提交</span>
        </div>
      </div>
    </van-popup>
    <!-- 时间选择 -->
    <div class="van-overlay" v-if="filterList.isStarTime">
      <div class="datetime">
        <van-datetime-picker
          v-if="filterList.isStarTime"
          v-model="filterList.BillDateBegin"
          type="date"
          title="选择时间"
          @cancel="cancelDate1"
          @confirm="confirmDate1"
          :min-date="minDate"
          :max-date="maxDate"
          :columns-order="['year', 'month', 'day']"
          :formatter="formatter1"
        />
      </div>
    </div>

    <div class="van-overlay" v-if="filterList.isEndTime">
      <div class="datetime">
        <van-datetime-picker
          v-if="filterList.isEndTime"
          v-model="filterList.BillDateEnd"
          type="date"
          title="选择时间"
          :min-date="minDate2"
          :max-date="maxDate2"
          @cancel="cancelDate2"
          @confirm="confirmDate2"
          :columns-order="['year', 'month', 'day']"
          :formatter="formatter1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { ImagePreview } from "vant";
import { Popup } from "vant";
// import { Calendar } from "vant";
import Clipboard from "clipboard";
// Vue.use(Calendar);
import { DatetimePicker } from "vant";
Vue.use(DatetimePicker);
Vue.use(ImagePreview, Popup);

export default {
  data() {
    return {
      billList: [],
      photo: [],
      isshowFilter: false,
      minDate: new Date(2000, 0, 1),
      minDate2: new Date(2000, 0, 1),
      maxDate: new Date(),
      maxDate2: new Date(),
      dateBegin: "",
      dateEnd: "",
      

      filterList: {
        isStarTime: false, //起始时间
        isEndTime: false,
        BillDateBegin: "",
        BillDateEnd: "",
        Status: "",
        BillType: "", //发票类型
        OrderCost: "",
        OrderCostEnd: "",
        page: 1,
        rows: 10,
        customer: sessionStorage.getItem('customer'),
      },
       token : sessionStorage.getItem('member_token'),

      topHeight: 0.8,   // 顶部距离

    };
  },
  mounted() {
    window.addEventListener("scroll", this.onReachBottom);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onReachBottom);
  },
  beforeCreate() {
    // document.querySelector("body").setAttribute("style", "background:#f2f2f2");
  },
  created() {
    this.GetBillList();
    var that = this;
    switch (localStorage.clientType) {
        case "app":
            that.appType = "app";
            that.topHeight = 1.7;
            break;
		case "ios":
		    that.appType = "ios";
		    that.topHeight = 1.7;
		    break;
        case "applets":
            that.appType = "applets";
            that.topHeight = 0.8;
            break;
        case "weixin":
            that.appType = "weixin";
            that.topHeight = 0.8;
            break;
        default:
            that.topHeight = 0.8;
    }
  },
  methods: {
    isFilter() {
      this.filterList.page = 1;
      this.isshowFilter = true;
    },
    GetBillList() {
      this.$toast.loading({
        message: "加载中...",
        duration: 0,
      });
      this.noMore = true;

      var list = {
        BillDateBegin: this.filterList.BillDateBegin,
        BillDateEnd: this.filterList.BillDateEnd,
        Status: this.filterList.Status,
        BillType: this.filterList.BillType, //发票类型
        OrderCost: parseInt(this.filterList.OrderCost),
        OrderCostEnd: parseInt(this.filterList.OrderCostEnd),
        page: this.filterList.page,
        rows: this.filterList.rows,
        SendOrgId: this.filterList.customer
      };
      if(!this.token){
         this.$toast.clear();
        return false
      }
      this.public.request(
        "post",
        "/api/Bill/GetBillListMobilePage",
        list,
        (result) => {
          this.$toast.clear();
          var that = this;

          if (result.data.errorCode == "00") {
            var allArr = [];
            var initArr = that.billList;
            var curArr = result.data.rows ? result.data.rows : [];
            var lastPageLength = curArr.length;
            if (that.filterList.page > 1) {
              allArr = initArr.concat(curArr);
            } else {
              allArr = result.data.rows;
            }

            allArr.forEach((item) => {
              if (item.BillPics != null) {
                if (typeof item.BillPics == "string") {
                  item.BillPics = JSON.parse(item.BillPics);
                }
              }
            });

            that.billList = allArr;
            that.noMore = false;
            if (lastPageLength < 10) {
              that.noMore = true;
            }
          } else {
            if (that.filterList.page <= 1) {
              that.billList = [];
              that.noMore = false;
            } else {
              that.noMore = true;
            }
          }
        }
      );
    },
    //到达底部
    onReachBottom() {
      var that = this;
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      //       console.log('距顶部 =='+scrollTop);
      // console.log('可视区高度 =='+windowHeight);
      // console.log('滚动条总高度 =='+scrollHeight);
      if (scrollTop > 0) {
        if (scrollTop + windowHeight + 17 >= scrollHeight) {
          //写后台加载数据的函数
          // console.log("----------------");
          // console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
          // console.log(that.noMore);
          var page = that.filterList.page;
          var noMore = that.noMore;
          page++;
          that.filterList.page = page;
          if (noMore) {
            // console.log("已经到底了");
          } else {
            // console.log("加载更多");
            that.GetBillList();
          }
        }
      }
    },
    // 去开发票
    goToBill() {
      this.$router.push({
        path: "/InvoiceOrder",
      });
    },
    // 浏览图片
    previewPicture(value) {
      ImagePreview({
        images: value,
        startPosition: 0,
        showIndex: true,
        closeable: true,
      });
    },
    // 复制附件地址
    getAddress(val) {
      // console.log(val);
      var that = this;
      let clipboard = new Clipboard(".tag", {
        text: function() {
          return val;
        },
      });
      clipboard.on("success", (e) => {
        that.$toast("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        that.$toast("复制失败");
        clipboard.destroy();
      });
    },
    // 跳转发票详情
    billDetails(val) {
      this.$router.push({
        path: "/InvoiceDetails",
        query: { Id: val.Id },
      });
    },
    // 弹层
    // 审核状态
    clockBtns(num) {
      this.filterList.Status = num;
    },
    // 发票类型
    activeStyleBtn(num) {
      this.filterList.BillType = num;
    },

    formatter1(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      return val;
    },

    // 起始时间
    starTimebtn() {
      this.filterList.isStarTime = true;
      //  var subDate = new Date();
      // this.filterList.BillDateBegin = new Date(subDate.getFullYear(), 0, 1);
      this.filterList.BillDateBegin = new Date();
    },

    cancelDate1() {
      this.filterList.isStarTime = false;
      this.filterList.BillDateBegin = "";
      this.dateBegin = "";
    },
    confirmDate1(value) {
      let yy = new Date(value).getFullYear();
      let mm = new Date(value).getMonth() + 1;
      let dd = new Date(value).getDate();
      let hh = new Date(value).getHours();
      let mf = new Date(value).getMinutes();
      let ss = new Date(value).getSeconds();

      mm = mm >= 10 ? mm : "0" + mm;
      dd = dd >= 10 ? dd : "0" + dd;
      hh = hh >= 10 ? hh : "0" + hh;
      mf = mf >= 10 ? mf : "0" + mf;
      ss = ss >= 10 ? ss : "0" + ss;

      this.filterList.BillDateBegin =
        yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      this.dateBegin = yy + "年" + mm + "月" + dd + "日";
      this.filterList.isStarTime = false;
      mm = mm - 1;
      this.minDate2 = new Date(yy, mm, dd);
    },

    // 终止时间
    endTimebtn() {
      this.filterList.isEndTime = true;
      //  var subDate = new Date();
      // this.filterList.BillDateEnd = new Date(subDate.getFullYear(), 0, 1);
      this.filterList.BillDateEnd = new Date();
    },
    cancelDate2() {
      this.filterList.isEndTime = false;
      this.filterList.BillDateEnd = "";
      this.dateEnd = "";
    },
    confirmDate2(value) {
      let yy = new Date(value).getFullYear();
      let mm = new Date(value).getMonth() + 1;
      let dd = new Date(value).getDate();
      let hh = new Date(value).getHours();
      let mf = new Date(value).getMinutes();
      let ss = new Date(value).getSeconds();

      mm = mm >= 10 ? mm : "0" + mm;
      dd = dd >= 10 ? dd : "0" + dd;
      hh = hh >= 10 ? hh : "0" + hh;
      mf = mf >= 10 ? mf : "0" + mf;
      ss = ss >= 10 ? ss : "0" + ss;
      this.filterList.BillDateEnd =
        yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      this.dateEnd = yy + "年" + mm + "月" + dd + "日";
      // console.log("this.filterList.BillDateEnd", this.filterList.BillDateEnd);
      this.filterList.isEndTime = false;
      mm = mm - 1;
      this.maxDate = new Date(yy, mm, dd);
    },
    // 取消
    cancelbtn() {
      this.isshowFilter = false;
      this.filterList.isStarTime = false;
      this.filterList.isEndTime = false;
      this.filterList.Status = "";
      this.filterList.BillType = "";
      this.filterList.OrderCost = "";
      this.filterList.OrderCostEnd = "";
      this.filterList.BillDateBegin = "";
      this.filterList.BillDateEnd = "";
      this.dateBegin = "";
      this.dateEnd = "";
      this.minDate = new Date(2000, 0, 1);
      this.minDate2 = new Date(2000, 0, 1);
      this.maxDate = new Date();
      this.maxDate2 = new Date();
    },
    //  确定
    sureBtn() {
      if (this.filterList.OrderCost > this.filterList.OrderCostEnd) {
        var cost = this.filterList.OrderCost;
        this.filterList.OrderCost = this.filterList.OrderCostEnd;
        this.filterList.OrderCostEnd = cost;
      }
      this.GetBillList();
      document.documentElement.scrollTop = 0;
      this.cancelbtn();
    },
  },
};
</script>

<style scoped lang="less">
.billManage {
  background-color: #f2f2f2;
  font-size: 0.26rem;
}
.filter {
  height: 0.59rem;
  line-height: 0.7rem;
  overflow: hidden;
  background-color: white;
  position: fixed;
  top: 0.8rem;
  width: 100%;
}
.filter img {
  width: 0.3rem;
  height: 0.3rem;
  position: absolute;
  right: 1.3rem;
  top: 0.2rem;
}
.filter span {
  margin: 0 0.4rem;
}
.filter .filterbtn {
  float: right;
  margin-right: 0.2rem;
}
/* 列表 */
.van-swipe__track {
  z-index: 999;
}

.noData {
  margin: 2rem 3rem;
}
s {
  text-decoration: none;
}
.floor-box {
  margin-top: 0.6rem;
}
.floor-item {
  background-color: white;
  margin: 0.16rem;
  border-radius: 0.1rem;
  padding: 0.25rem;
}
.invoiceTime img {
  display: inline;
  width: 0.3rem;
  height: 0.3rem;
  vertical-align: middle;
}
.invoiceTime,
.invoiceStyle,
.invoiceDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.invoice {
  padding: 0 0.1rem;
}
.invoiceName,
.order1,
.preview {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.invoiceName .invoice {
  border: 0.01rem solid #aaa9a9;
  text-align: center;
}
.invoiceSum {
  font-weight: 700;
}
.invoiceSum s {
  font-size: 0.3rem;
}
.order1 {
  align-items: flex-end;
}
.invoiceDetails {
  height: 1.3rem;
}
.invoiceImg {
  text-align: center;
  margin: 0 0.5rem;
}
.preview span:nth-child(2) {
  margin: 0.1rem;
}
/* 弹层 */
.order {
  margin: 0.2rem 0.5rem 0 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.choose {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0.2rem 0 0.6rem 0;
}
.choose span {
  width: 2.4rem;
  line-height: 0.6rem;
  text-align: center;
  margin: 0 0.2rem 0.2rem 0;
  color: white;
  background-color: #c9c9c9;
}
.order input {
  width: 2.5rem;
  height: 0.5rem;
  border: 0.01rem solid black;
}
.line {
  display: inline-block;
  width: 0.3rem;
  height: 0.01rem;
  background-color: black;
  margin: 0.1rem;
}
.style {
  justify-content: flex-start !important;
  flex-wrap: wrap;
}
.checked {
  background-color: var(--theme-color) !important;
}
.processBtn span {
  line-height: 0.7rem;
  margin-top: 1rem;
  border-radius: 0.1rem;
  background-color:var(--theme-color)  !important;
}
.van-overlay {
  z-index: 9999;
}
.van-picker {
  top: 8rem;
}
</style>
