<template>
    <div class="container">
        <div class="contain">
            <div class="account-sign flex-wrap">
                <span>手机号：</span>
                <input type="text" placeholder="请输入您的手机号" v-model="mobile" />
            </div>
            <div class="account-sign flex-wrap">
                <div class="verifi-code flex-wrap">
                <span>原密码：</span>
                <input type="password" placeholder="请输入原密码" v-model="primaryPass" />
                </div>
                <!-- <button class="verifi-btn" :disabled="disabled">{{sendTime}}</button> -->
            </div>
            <div class="account-sign flex-wrap">
                <span class="pass-width">请输入新密码：</span>
                <input type="password" placeholder="请输入您的新密码" v-model="newPass"/>
            </div>
            <div class="account-sign flex-wrap">
                <span class="pass-width">再次确定密码：</span>
                <input type="password" placeholder="请再次确定您的新密码" v-model="surePass" />
            </div>
            <div class="submit-btn" @click="submit">提交</div>
            <div class="back-btn" @click="backLogin">返回</div>
        </div>
    </div>
</template>


<script>
export default {
    data(){
        return{
            mobile: '',     // 手机号
            primaryPass: '',    // 原密码
            newPass: '',        // 新密码
            surePass: '',       // 确认密码
        }
    },
    methods:{
        // 修改密码提交
        submit(){
            var that = this;
            var data = {
                Mobile: that.mobile ? that.mobile : '',
                Password: that.primaryPass ? that.primaryPass : '',
                NewPassword: that.newPass ? that.newPass : '',
                ConfirmPassword: that.surePass ? that.surePass : '',
            }
            if(!data.Mobile){
                that.$toast('请输入手机号');
                return;
            }
            var reg = /^1[0-9]{10}$/;
            if(!reg.test(data.Mobile)){
                that.$toast('请输入正确的手机号');
                return;
            }
            if(!data.Password){
                that.$toast('请输入原密码');
                return;
            }
            if(!data.NewPassword){
                that.$toast('请输入新密码');
                return;
            }
            if(!data.ConfirmPassword){
                that.$toast('请确认您的密码');
                return;
            }
            that.public.request('post','/api/SysUserInfo/ResetWxUserPassword',data,res => {
                console.log(res);
                if(res.data.errorCode == '00'){
                    that.$toast('修改成功');
                    setTimeout(function(){
                        // that.$router.go(-1);
                        localStorage.removeItem("token");
                        //localStorage.customer = "";
                        localStorage.user = "";
                        //localStorage.setItem("CloseIndexPopupLayer", false);

                        var orgid = localStorage.orgId;
                        var openid = localStorage.openid;
                        var clientType = localStorage.clientType;
                        var appVersionUrl = "";

                        var queryObj={
                            orgId: orgid,
                            openid:openid,
                            clientType:clientType,
                            auto:0,
                            _:(new Date()).valueOf()    
                        }

                        if (!!localStorage.appv) {
                            queryObj.appv=localStorage.appv;
                        }
                        // 清除localStorage所有缓存
                        localStorage.clear();
                        that.$router.push({
                            path: '/login',
                            query:queryObj
                        })  
                    },2000)
                }else if(res.data.errorCode == '99'){
                    that.$toast(res.data.errorMsg);
                }
            })
        },

        // 返回上一页
        backLogin:function(){
            this.$router.go(-1);
        },
    }
}
</script>


<style scoped>
    .contain{
        width:80%;
        margin:1rem auto;
    }
    .contain .account-sign{
        margin-bottom:0.36rem;
    }
    .contain .account-sign span{
        width:1.4rem;
        font-size:0.3rem;
    }
    .contain .account-sign span.pass-width{
        width:2.2rem;
    }
    .contain .account-sign input{
        flex:1;
        border:0.02rem solid rgb(218, 215, 215);
        border-radius:0.16rem;
        padding:0.12rem 0.2rem;
        font-size:0.28rem;
    }

    .contain .account-sign .verifi-code{
        flex:1;
    }
    .contain .account-sign .verifi-btn{
        width:auto;
        padding:0 0.2rem;
        height:0.6rem;
        text-align:center;
        line-height:0.6rem;
        background:var(--theme-color);
        font-size:0.26rem;
        color:#fff;
        border-radius:0.2rem;
        margin-left:0.2rem;
    }

    /* 提交 */
    .submit-btn{
        width:100%;
        height:0.9rem;
        line-height:0.9rem;
        text-align:center;
        font-size:0.3rem;
        color:#fff;
        margin:0.3rem 0 0.3rem;
        background:var(--theme-color);
        border-radius:0.3rem;
    }
    .back-btn{
        text-align:center;
        color:var(--theme-color);
        font-size:0.3rem;
    }
</style>