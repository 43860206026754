<template>
    <div class="contain-box">
        <!-- 顶部导航 -->
        <!-- <div class="tab-top flex-sp" >
                <img @click="backPage" class="tab-img" src="@/assets/images/tab/icon_left.png" alt="">
                <div class="tab-tit">我的资质</div> -->
                <!-- <span class="tab-right">更新记录</span> -->
        <!-- </div> -->
        <div class="notice" :style="{top:topHeight+'rem'}">
            <div class="notice-img"><img src="@/assets/images/icon-zhuyi.png" alt=""></div>
            <div>如果资质证件有更新请及时上传并确定完成邮寄，上传证件需为盖门店公章的复印件，我们将及时为您审核。</div>
        </div>
        
       <div v-if="token">
                <!-- 资质列表 -->
            <div class="qualifi-list" >
                <div class="qualifi-item" v-for="(item,index) in qualificationList" :key="index">
                    <div class="item-img">   
                        <img  src="@/assets/images/qiye2.png" alt="">   
                    </div>
                    <div class="item-info">
                        <div class="flex-sp">
                            <div class="info-name">{{item.Name}}</div>
                            <div class="info-up" @click="infoUp(index,item.Num,item)">{{item.AuditName}}</div>
                            <input  style="display: none" type="file" accept="image/*" @change="uploadFile(index)" name="upload_file" ref="infoUp" />
                        </div>
                        <div class="info-cont">
                            <span class="cont-txt">资质内容：</span>
                            <span>无</span>
                        </div>
                        <div class="info-text flex-sp">
                            <div class="info-auth">
                                <span class="auth-txt">授权委托人：</span>
                                <span>无</span>
                            </div>
                            <div class="info-auth">
                                <span class="auth-txt">失效时间：</span>
                                <span>{{item.ExpireStr}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 详情咨询 -->
            <div class="consult-detail">
                <div class="consult-tit">详情请咨询：</div>

                <div class="consult-box flex-sp" >
                    <div class="consult-left flex-wrap">
                        <!-- <img src="@/assets/images/quan.png" alt=""> -->
                        <span>服务顾问</span>
                    </div>
                    <div class="consult-right flex-wrap">
                        <span class="con-name">{{consultantList.EmployeeName}}</span>
                        <span class="con-mobile" @click="callPhone(consultantList.EmployeeMobile)">{{consultantList.EmployeeMobile}}</span>
                        <!-- <span class="con-mobile" @click="callPhone('15139730456')">15139730456</span> -->
                        <img src="@/assets/images/icon_mobile.png" alt="">
                    </div>
                </div>
            </div>
       </div>
       <div class="no-data" v-if="!token">  
           <img src="@/assets/images/cart/noActiveDrug.png" alt="" /> 
            <span>暂无数据</span>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            token:sessionStorage.getItem('member_token'),
            phone:'15139852036',
            qualificationList:[],
            consultantList:{},//顾问电话
            content:'上传资质',
            topHeight: 0.9,
        }
    },
    created () {
        this.getQualificationList();
        var that = this;
        switch (localStorage.clientType) {
            case "app":
                that.appType = "app";
                that.topHeight = 1.7;
                break;
			case "ios":
				that.appType = "ios";
				that.topHeight = 1.7;
				break;
            case "applets":
                that.appType = "applets";
                that.topHeight = 0.9;
                break;
            case "weixin":
                that.appType = "weixin";
                that.topHeight = 0.9;
                break;
            default:
                that.topHeight = 0.9;
				break
        }
        console.log(localStorage.clientType);
    
    },  
    methods: {
        backPage(){
         this.$router.go(-1)
    },
    callPhone (phoneNumber) {
       window.location.href = 'tel:' + phoneNumber
    },
    getQualificationList(){
        var that=this;
        if(!that.token){
            return false;
        }
        that.$toast.loading({
            message:'加载中'
        });
       that.public.request(
            'post',
            '/api/Qualification/GetCustomerQualification',
            '',
            (res)=>{            
                    that.$toast.clear();
                    if(res.data.errorCode=='00'){
                        // that.qualificationList=res.data.rows.customer;
                        var list=res.data.rows.customer;
                        list.forEach(item=>{
                           if( item.AuditStatus=='0'){
                               item.AuditName='上传资质';
                           }else if( item.AuditStatus=='10'){
                               item.AuditName='资质待审核';
                           }else if( item.AuditStatus=='20'){
                               item.AuditName='资质审核通过';
                           }else if( item.AuditStatus=='30'){
                               item.AuditName='审核未通过';
                           }
                        });
                        that.qualificationList=list;
                        that.consultantList=res.data.rows.salesman;
                    }else{
                      that.$toast(res.data.errorMsg);
                    }
                }
        );
    },
    // 上传资质
    infoUp(ind,name,item){
        var that=this; 
         if(that.qualificationList[ind].AuditStatus=="10"){
            return that.$toast('资质正在审核');
        }     
         that.$refs.infoUp[ind].click();  
         that.$refs.infoUp[ind].dataset.type = name;
         that.$refs.infoUp[ind].dataset.ImgType=item.Type;
         that.$refs.infoUp[ind].dataset.ImgName=item.Name;
         if(item.Type=='60'){
               that.$refs.infoUp[ind].dataset.EntrustQualificationId=item.EntrustQualificationId;
          }

    },
    uploadFile(index,name){
        var that=this;
        var file = that.$refs.infoUp[index].files;
        var key = event.currentTarget.dataset.type;
        var ImgType=event.currentTarget.dataset.ImgType;
        var ImgName=event.currentTarget.dataset.ImgName;

        var  hostUrl = that.requestUrl.url;
        var formData = new FormData();
          if(ImgType=='60'){
             var EntrustQualificationId=event.currentTarget.dataset.EntrustQualificationId;
              formData.append("EntrustQualificationId",EntrustQualificationId);
        }
        formData.append("file", file[0]);
        formData.append("ImgType",ImgType);
        formData.append("ImgName",ImgName);
        console.log(formData);
        that.$toast.loading({ message: "上传中.." });
        that.public.request(
        "post",
        "api/MchkQualification/UploadImg?key=" + key,
        formData,
        (res) => {  
          that.$toast.clear();
          if (res.data.errorCode == "00") {
            that.$toast('上传成功');
            var aa=setTimeout(res=>{
                that.getQualificationList();
                clearTimeout(aa);
            },1000)
            
          } else {
            that.$toast(res.data.errorMsg);
          }
        }
      );
    }
  }
}
</script>

<style scoped>
    .tab-box{
        width:100%;
        height: 0.9rem;
    }

    .tab-top {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 0.9rem;
        padding: 0 0.2rem;
        box-sizing: border-box;
        background: #fff;
        z-index: 99;
        border-bottom: 0.02rem solid #eee;
    }

    .tab-top .tab-img {
        width: 0.4rem;
        height: 0.4rem;
    }

    .tab-top .tab-tit {
        flex: 1;
        text-align: center;
        font-size: 0.32rem;
    }
    .tab-top .tab-right{
        font-size:0.32rem;
    }
    .notice-img{
        width: 0.65rem;
        margin-right: 0.1rem;
    }
    .notice{
        display: flex;
        padding: .2rem ;
        justify-content: space-between;
        background-color: #faeedf;
        color: #E77E1C;
        font-size: .26rem ;
        position: fixed;

    }
    /* 资质列表 */
    .qualifi-list{
        width:100%;
        padding:0rem 0.3rem;
        margin-top: 1.2rem ;
        box-sizing:border-box;
    }
    .qualifi-list .qualifi-item{
        width:100%;
        border-bottom:0.02rem solid #eee;
        display:flex;
        padding:0.2rem 0;
        box-sizing:border-box;
    }
    .qualifi-list .qualifi-item .item-img{
        width:0.6rem;
        height:0.6rem;
        margin-right:0.2rem;
    }
    .qualifi-list .qualifi-item .item-img img{
        width:100%;
        height:100%;
        border-radius:50%;
    }
    .qualifi-item .item-info{
        flex:1;
    }
    .qualifi-item .item-info .info-name{
        font-size:0.32rem;
    }
    .qualifi-item .item-info .info-cont{
        font-size:0.28rem;
        margin:0.12rem 0;
    }
    .qualifi-item .item-info .info-cont .cont-txt{
        color:#aaa9a9;
    }
    .qualifi-item .item-info .info-text .info-auth{
        font-size:0.28rem;
    }
    .qualifi-item .item-info .info-text .info-auth .auth-txt{
        color:#aaa9a9;
    }
    /* 详情咨询 */
    .consult-detail{
        width:100%;
        padding:0.2rem 0.3rem;
        box-sizing:border-box;
    }
    .consult-detail .consult-tit{
        font-size:0.36rem;
        font-weight:700;
    }
    .consult-detail .consult-box{
        width:100%;
        background:#F1F1F1;
        border-radius:0.12rem;
        padding:0.2rem 0.2rem;
        box-sizing:border-box;
        margin-top:0.22rem;
    }
    .consult-detail .consult-box .consult-left img{
        width:0.56rem;
        height:0.56rem;
        border-radius:50%;
        margin-right:0.12rem;
    }
    .consult-detail .consult-box .consult-left span{
        font-size:0.28rem;
        font-weight:700;
    }
    .consult-detail .consult-box .consult-right .con-name{
        color:#656566;
        font-size:0.28rem;
    }
    .consult-detail .consult-box .consult-right .con-mobile{
        color:#2F7FC7;
        font-size:0.28rem;
        margin:0 0.12rem;
    }
    .consult-detail .consult-box .consult-right img{
        width:0.56rem;
        height:0.56rem;
        border-radius:50%;
    }
    a{
        font-size:0.28rem;
        color: #2F7FC7;

    }
    .info-up{
        color: #fcfcfc;
        font-size:0.25rem;
        background-color:#1B9ADC ;
        padding: .08rem .15rem;
        border-radius: .15rem;
        width: 1.65rem;
        height: 0.4rem;
        line-height: 0.4rem;
        text-align: center;
    }
</style>