<template>
  <div class="container">
    <!-- <div style="display:none;"> -->
    <!-- 收货地址 -->
    <!-- <div class="address-box bor-bot2">
				<div class="not-address flex-sp" v-if="!addShow" @click="toAddress()">
					<div class="img-box flex-wrap">
						<img src="@/assets/images/icon_address.png" alt="地图图标">
						<span>暂无收货地址，请添加</span>
					</div>
					<img class="img-r" src="@/assets/images/mine/arrow-r.png" alt="右箭头">
				</div>
				<div class="address-info flex-wrap1" v-if="addShow" @click="toAddress(addressInfo.Id)">
					<img class="img-add" src="@/assets/images/icon_add.png" alt="地图图标">
					<div class="address-right flex-wrap">
						<div class="use-info">
							<div class="right-mobile flex-sp">
								<span>收货人：{{addressInfo.Consignee}}</span>
								<span>{{addressInfo.Mobile}}</span>
							</div>
							<div class="add-cont">收货地址：{{addressInfo.Address}}</div>
						</div>
						<img class="img-r" src="@/assets/images/mine/arrow-r.png" alt="右箭头">
					</div>
				</div>
			</div> -->
    <!-- 优惠券、发票 -->
    <!-- <div class="invoce-box bor-bot2">
				<div class="invoce-item flex-wrap" @click="showPicker = true">
					<div class="item-left">
						<span class="invoce-tit">支付方式</span>
					</div>
					<div class="picker-box flex-wrap">
						<span class="picker-tit">{{payType}}</span>
						<img src="@/assets/images/mine/arrow-r.png" alt="图标">
					</div>
				</div>
				<div class="invoce-item flex-wrap" @click="invoiceShow = true">
					<div class="item-left">
						<span class="invoce-tit">发票类型</span>
					</div>
					<div class="picker-box flex-wrap">
						<span class="picker-tit">{{invoiceType}}</span>
						<img src="@/assets/images/mine/arrow-r.png" alt="图标">
					</div>
				</div>
				<div class="invoce-item flex-wrap">
					<span class="invoce-tit">{{couponTit}}</span>
					<div class="picker-box flex-wrap" @click="toMyCoupon">
						<span class="picker-tit">{{couponName}}</span>
						<img src="@/assets/images/mine/arrow-r.png" alt="图标">
					</div>
				</div>
				<div class="invoce-item surplusMoney">
					<div class="item-left">
						<span class="invoce-tit">可用余额:{{surplusMoney}}元</span>
					</div>
					<div   class="input-sy" @click="selectMoney">
						<img v-if="!isSelected" src="@/assets/images/cart/icon_nochecked.png" alt="" />
						<img  v-if="isSelected" src="@/assets/images/cart/icon_checked1.png" alt="" />
					</div>
					
				</div>
			</div> -->
    <!-- 备注 -->
    <!-- <div class="remarks flex-wrap bor-bot2">
				<span>备注:</span>
				<input type="text" v-model="remarks" placeholder="请输入您的特殊要求">
			</div> -->
    <!-- 商品详情 -->
    <!-- <div class="good-list bor-bot2">
				
				
				<div class="good-item flex-wrap" v-for="(goodItem,index) in goodDetail.ListGoods" :key="index">
					<div v-if="goodItem.PackageDetail.length<=0">
						<div class="flex-wrap"> 
							<div class="item-left">
								<img class="left-img" v-if="goodItem.SpPic" :src="goodItem.SpPic"    onerror="javascript:this.src='https://goodssuffix.oss-cn-qingdao.aliyuncs.com/upload/c5c2ae08dbfb45898d64061a0d82e83e/9cc5bc0235044c54916fd18243082d10.png'" >
								<img class="left-img" v-if="!goodItem.SpPic" src="@/assets/images/loseImg.png"    onerror="javascript:this.src='https://goodssuffix.oss-cn-qingdao.aliyuncs.com/upload/c5c2ae08dbfb45898d64061a0d82e83e/9cc5bc0235044c54916fd18243082d10.png'" >
							</div>
							<div class="item-info flex-column">
								<div class="info-name">{{goodItem.SpName}}</div>
								<div class="info-specifi">{{goodItem.Style}}/{{goodItem.dw}}</div>

								<div class="info-company">{{goodItem.Factory}}</div>
								<div class="info-seck flex-sp">
									<div class="info-price">
										<span class="info-tit font-red">￥ {{goodItem.BuyPrice}}</span>
										<span class="info-old-price font-grey">￥ 6.00</span>
									</div>
									<div class="seck-btn">x {{goodItem.BuyCount}}</div>
								</div>

							</div>
							
						</div>
						<div v-if="goodItem.Gifts!=null">
							<div class="gift-box"  v-for="(gift,j) in goodItem.Gifts" :key="j">【赠品】 {{gift.SpName}} {{gift.Style}} &nbsp;&nbsp; {{gift.Price}} ×{{gift.Total}}</div>
						</div>
						
					</div> -->

    <!-- 套餐包 -->
    <!-- <div v-if="goodItem.PackageDetail.length>0">
						<div class="pack-item flex-wrap" v-for="(packItem,j) in goodItem.PackageDetail" :key="j">
							<div class="item-left">
								<img class="left-img" v-if="packItem.SpPic" :src="packItem.SpPic" alt="商品图片">
								<img class="left-img" v-if="!packItem.SpPic" src="@/assets/images/loseImg.png" alt="商品图片">
							</div>
							<div class="item-info flex-column">
								<div class="info-name">{{packItem.SpName}}</div>
								<div class="info-specifi">{{packItem.Style}}</div>

								<div class="info-company">{{packItem.Factory}}</div>
							</div>
						</div>
						<div class="pack-seck">
							<div class="info-price">
								<span class="info-tit font-red">￥ {{goodItem.BuyPrice}}</span>
								
							</div>
							<div class="seck-btn">x {{goodItem.BuyCount}}</div>
						</div>
					</div>
					
				</div>
				
			</div> -->

    <!-- 价格详情 -->
    <!-- <div class="price-list bor-bot2">
				<div class="price-item flex-sp">
					<span class="item-tit">商品金额</span>
					<span class="font-red">￥{{goodTotalPrice}}</span>
				</div>
				<div class="price-item flex-sp" v-if="couponMoney.money != 0">
					<span class="item-tit">卡券优惠</span>
					<span class="font-red">-￥{{couponMoney.money}}</span>
				</div>
				<div class="price-item flex-sp" v-for="(item,index) in discountArr" :key="index">
					<span class="item-tit">{{item.name}}</span>
					<span class="font-red" v-if="item.name=='运费'">+￥{{item.money}}</span>
					<span class="font-red" v-else>-￥{{item.money}}</span>
				</div>
				

			</div> -->

    <!-- 支付弹窗 -->
    <!-- <van-popup v-model="showPicker" round position="bottom">
				<van-picker item-height="40" show-toolbar :columns="columns" value-key="CheckoutName" @cancel="showPicker = false"
					@confirm="onConfirm" />
			</van-popup> -->

    <!-- 发票弹窗 -->
    <!-- <van-popup v-model="invoiceShow" round position="bottom">
				<van-picker item-height="40" show-toolbar :columns="invoiceCloumn" value-key="InvoiceTypeName"
					@cancel="invoiceShow = false" @confirm="onInvoice" />
			</van-popup> -->

    <!-- 底部  总价 -->
    <!-- <div class="bot-fixed">
				<div class="bot-total flex-wrap">
					<div class="total-left flex-sp">
						<span class="left-tit" v-if="goodDetail.length>0">共计：{{goodDetail.ListGoods.length}}件商品</span>
						<div class="total-price">
							<span>合计：</span>
							<span class="font-red">￥{{totalPirce}}</span>
						</div>
					</div>
					<div class="but-submit" @click="submitOrder">提交订单</div>
				</div>
			</div>
		</div> -->

    <!-- 新版 -->
    <div class="submit-box">
      <!-- 顶部红色位置 -->
      <div class="red-box"></div>
      <!-- 订单详情 -->
      <div class="order-info">
        <div class="order-line"></div>
        <div class="info-shop">
          <!-- 地址 -->
          <div class="address-info" v-if="addShow" @click="toAddress(addressInfo.Id)">
            <div class="address-label">
              <span>默认</span>
              <span></span>
            </div>
            <div class="address-txt flex-sp">
              <span>{{ addressInfo.Address }}</span>
              <img src="@/assets/images/mine/arrow-r.png" alt="" />
            </div>
            <div class="address-name">
              <span>{{ addressInfo.Consignee }}</span>
              <span>{{ addressInfo.Mobile }}</span>
            </div>
          </div>
          <dir class="address-no flex-sp" v-if="!addShow" @click="toAddress()">
            <div class="address-img flex-wrap">
              <img src="@/assets/images/icon_address.png" alt="" />
              <span>暂无收货地址，请添加</span>
            </div>
            <img class="img-right" src="@/assets/images/mine/arrow-r.png" alt="" />
          </dir>
          <!-- 商品详情 -->
          <div class="shop-detail">
            <div class="shop-n">{{ fullName }}</div>
            <div class="shop-list">
              <div class="shop-item flex-wrap" v-for="(goodItem, index) in goodDetail.ListGoods" :key="index"
                v-show="index < isShowNum">
                <img class="shop-item-img" v-if="goodItem.SpPic" :src="goodItem.SpPic"
                  onerror="javascript:this.src='https://goodssuffix.oss-cn-qingdao.aliyuncs.com/upload/c5c2ae08dbfb45898d64061a0d82e83e/9cc5bc0235044c54916fd18243082d10.png'"
                  alt="" />
                <img class="shop-item-img" v-if="!goodItem.SpPic" src="@/assets/images/loseImg.png"
                  onerror="javascript:this.src='https://goodssuffix.oss-cn-qingdao.aliyuncs.com/upload/c5c2ae08dbfb45898d64061a0d82e83e/9cc5bc0235044c54916fd18243082d10.png'"
                  alt="" />
                <div class="item-det">
                  <div class="item-name flex-sp">
                    <span>{{ goodItem.SpName }}</span>
                    <span>￥ {{ goodItem.BuyPrice }}</span>
                  </div>
                  <div class="item-specs flex-sp">
                    <span>{{ goodItem.Style }}/{{ goodItem.dw }}</span>
                    <span>x {{ goodItem.BuyCount }}</span>
                  </div>
                  <div class="item-factory">厂家：{{ goodItem.Factory }}</div>
                </div>
              </div>
              <!-- 显示所有商品 -->
              <div class="list-more-txt flex-wrap" v-if="goodDetail.ListGoods && goodDetail.ListGoods.length > 2"
                @click="showMore">
                <img src="@/assets/images/icon_up03.png" alt="" />
                <span>{{ isShowNum > 2 ? "收起" : "显示更多商品" }}</span>
              </div>
            </div>
          </div>
          <!-- 支付方式、发票类型 -->
          <div class="select-box">
            <div class="select-row flex-wrap" @click="showPicker = true">
              <span class="sel-left">支付方式</span>
              <div class="sel-rig flex-wrap">
                <span class="sel-tit">{{ payType }}</span>
                <img src="@/assets/images/mine/arrow-r.png" alt="" />
              </div>
            </div>
            <div class="select-row flex-wrap" @click="invoiceShow = true">
              <span class="sel-left">发票类型</span>
              <div class="sel-rig flex-wrap">
                <span class="sel-tit">{{ invoiceType }}</span>
                <img src="@/assets/images/mine/arrow-r.png" alt="" />
              </div>
            </div>
            <div class="select-row flex-wrap">
              <span class="sel-left">{{ couponTit }}</span>
              <div class="sel-rig flex-wrap" @click="toMyCoupon">
                <span class="sel-tit sel-active">{{ couponName }}</span>
                <img src="@/assets/images/mine/arrow-r.png" alt="" />
              </div>
            </div>
            <div class="invoce-item flex-sp">
              <div class="item-left">
                <span class="invoce-tit">可用余额:{{ surplusMoney }}元</span>
              </div>
              <div class="input-sy" @click="selectMoney">
                <img v-if="!isSelected" src="@/assets/images/cart/icon_nochecked.png" alt="" />
                <img v-if="isSelected" src="@/assets/images/cart/icon_checked1.png" alt="" />
              </div>
            </div>
            <div class="select-row flex-wrap">
              <span class="sel-remark">备注：</span>
              <div class="sel-rig flex-wrap">
                <input type="text" placeholder="请输入您的特殊要求" v-model="remarks" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 支付弹窗 -->
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker item-height="40" show-toolbar :columns="columns" value-key="CheckoutName" @cancel="showPicker = false"
        @confirm="onConfirm" />
    </van-popup>

    <!-- 发票弹窗 -->
    <van-popup v-model="invoiceShow" round position="bottom">
      <van-picker item-height="40" show-toolbar :columns="invoiceCloumn" value-key="InvoiceTypeName"
        @cancel="invoiceShow = false" @confirm="onInvoice" />
    </van-popup>

    <!-- 底部  总价 -->
    <div class="bot-fixed">
      <div class="bot-total flex-wrap">
        <div class="total-left flex-sp">
          <span class="left-tit" v-if="goodDetail.length > 0">共计：{{ goodDetail.ListGoods.length }}件商品</span>
          <div class="total-price">
            <span>合计：</span>
            <span class="font-red">￥{{ totalPirce }}</span>
          </div>
        </div>
        <div class="but-submit" @click="submitOrder('')">提交订单</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      payType: "线下收款",
      showPicker: false,
      columns: [
        {
          id: 0,
          name: "对公打款",
        },
        {
          id: 1,
          name: "线上付款",
        },
        {
          id: 2,
          name: "其他",
        },
      ],
      columnId: 4, // 默认线下收款

      invoiceCloumn: [],
      invoice_index: "", // 发票下标
      invoiceType: "", // 发票名字
      invoiceShow: false,
      addShow: false, //  false没有地址， true已有地址
      goodDetail: [],
      addressInfo: {},
      couponTit: "优惠券",
      couponName: "请选择",
      coupon: {}, // 选中优惠券
      remarks: "", // 备注
      goodTotal: "", // 商品总金额
      discountObj: {}, // 优惠类型分类
      DiscountType: 1, //1是优惠券 2是红包
      couponList: [], // 优惠券
      redPackList: [], // 红包
      couponExtend: [], // 优惠券extend
      redPackExtend: [], // 红包extend

      isDecimalDiscount: false, // 抹零优惠
      decimalDiscount: 2, // 抹零优惠

      //  卡券优惠
      couponMoney: {
        money: 0,
      },
      discountArr: [], // 优惠数组
      discount: 0, // 优惠总金额
      totalMoney: 0, // 商品处理之后的金额
      freight: 0, // 运费
      pointNum: 2, //小数点
      surplusMoney: 0, //账户余额
      isSelected: false, //是否使用余额
      money: 0,
      isUserMoney: true, //是否需要支付

      isShowNum: 2, // 显示更多
      fullName: "", // 机构名称
    };
  },
  created() {
    var that = this;
    that.public.getOrgWebInfo(function (orgInfo) {
      that.fullName = orgInfo.FullName;
      // if (orgInfo.EnablePay) {
      // 	that.payType = '线上付款';
      // 	that.columnId = 1;
      // } else {
      // 	that.payType = '对公打款';
      // 	that.columnId = 0;
      // 	that.columns.splice(1,1);  // 删除数组中的线上付款
      // }
    });

    var shuju = JSON.parse(localStorage.getItem("webconfig"));
    if (shuju && shuju.GoodPriceFixed) {
      that.pointNum = shuju.GoodPriceFixed;
    }

    // 获取优惠券的extend
    that.extend = that.$store.state.extend;
    // 获取商品详情
    that.getWaitBuy();
    that.getAddress();
    that.getInvoice();
    that.getSurplusMoney();
    that.getQueryPayType();
  },
  activated() {
    var that = this;
    if (that.$route.query.refresh == "refresh") {
      // 获取商品详情
      that.getWaitBuy();
      that.getInvoice();
      that.getSurplusMoney();
    }
    this.isSelected = false;
    this.isUserMoney = true;
    // 获取优惠券的extend
    that.extend = that.$store.state.extend;
    var addressId = localStorage.getItem("addressId");
    that.getAddress(addressId);
    // 选中优惠券
    var couponItem = that.$store.state.couponItem;
    // console.log(Object.keys(couponItem).length);
    if (Object.keys(couponItem).length != 0 && couponItem.couponName == "优惠券") {
      var couponName = "满" + couponItem.MixOrderAmount + "减" + couponItem.Amount;
      that.couponName = couponName;
      that.couponTit = couponItem.couponName;
      that.couponExtend = that.$store.state.extend;
      that.coupon = couponItem;
      that.couponMoney.money = couponItem.Amount;
    } else if (Object.keys(couponItem).length != 0 && couponItem.couponName == "红包") {
      var couponName = "满" + couponItem.MinMoneys + "减" + couponItem.Moneys;
      that.couponName = couponName;
      that.couponTit = couponItem.couponName;
      that.redPackExtend = that.$store.state.extend;
      that.couponMoney.money = couponItem.Moneys;
    }
  },
  deactivated() {
    // 清除选中的优惠券
    this.$store.commit("setCouponItem", "");
    this.coupon = {};
    this.couponMoney.money = 0;
    this.couponName = "请选择";
    // this.couponList = [];
    this.redPackList = [];
    this.remarks = "";
    this.isSelected = false;
    this.isUserMoney = true;
  },
  destroyed() {
    // 清除选中的优惠券
    // this.$store.commit('setCouponItem','');
    // this.coupon = {};
    // this.couponName = "请选择";
    // this.couponMoney.money = 0;
  },

  methods: {
    // 显示更多
    showMore() {
      var that = this;
      // 展开更多
      if (that.isShowNum <= 2) {
        that.isShowNum = that.goodDetail.ListGoods.length;
      } else {
        // 收起
        that.isShowNum = 2;
      }
    },
    // 支付方式
    getQueryPayType() {
      var that = this;
      that.public.request("post", "/api/SellWellCar/QueryPayType", "", (res) => {
        // console.log('可选择支付方式',res.data.rows);
        that.columns = res.data.rows;
        (that.payType = that.columns[0].CheckoutName),
          (that.columnId = that.columns[0].OrderPayType);
      });
    },
    // 获取优惠券
    getCoupon(totalMoney) {
      var that = this;
      var data = {
        isShowGet: false,
      };
      that.public.request("post", "/api/coupon/queryUserAvailableCoupon", data, (res) => {
        // console.log(res);
        if (res.data.errorCode === "00"&&  res.data.rows.length > 0) {
          var couponItem = that.$store.state.couponItem;
          // console.log(Object.keys(couponItem).length);
          if (Object.keys(couponItem).length == 0) {
            // console.log("获取优惠券1",couponItem);
            that.couponList = res.data.rows;
            var couponArr = []; // 能使用的优惠券
            var noCouponArr = []; // 不能使用的优惠券

            res.data.rows.forEach((item, index) => {
              try {
                var money = that.getCurCoupMoney(totalMoney, res.data.extend, item);
                if (money <= 0) 
                {
                  noCouponArr.push(item);
                }
                else if (money >= item.MixOrderAmount) 
                {
                  couponArr.push(item);
                } else 
                {
                  noCouponArr.push(item);
                }
              }
              catch (e) {
                  noCouponArr.push(item);
              } 
            });
            that.couponExtend = res.data.extend;
            that.$store.commit("setCouponList", couponArr);
          }
        } else {
        }
      });
    },
    // 请求红包数据
    getRedPack(totalMoney) {
      var that = this;
      var data = {};
      that.public.request(
        "post",
        "/api/ReadPacket/GetUseRedPacketListByCar",
        data,
        (res) => {
          if (res.data.errorCode === "00") {
            var couponItem = that.$store.state.couponItem;
            // that.redPack = res.data.rows;
            if (Object.keys(couponItem).length == 0) {
              var redPackList = res.data.rows;

              // 可用红包
              var redPackArr = [];
              // 不可用红包
              var noredPackArr = [];
              redPackList.forEach((item, index) => {
                var money = that.getCurRedPackMoney(totalMoney, res.data.extend, item);

                if (money > item.MinMoneys) {
                  redPackArr.push(item);
                } else {
                  noredPackArr.push(item);
                }
              });

              that.redPackList = redPackArr;
              that.redPackExtend = res.data.extend;
              that.$store.commit("setRedPackList", redPackArr);
            }
          }
        }
      );
    },
    // 查询账户余额
    getSurplusMoney() {
      var that = this;
      that.public.request("post", "/api/sellwellcar/QueryAccontInfo", "", (res) => {
        if (res.data.errorCode == "00") {
          that.surplusMoney = res.data.rows.TotalMoney;
        }
      });
    },
    selectMoney() {
      var that = this;
      that.isSelected = !that.isSelected;
      if (that.isSelected && that.surplusMoney >= that.totalMoney) {
        that.isUserMoney = false;
      }
    },

    // 抹零优惠
    isSetDecimalDiscount() {
      var that = this;
      var webConfig = localStorage.webConfig;
      if (webConfig) {
        webConfig = JSON.parse(localStorage.webConfig);
        if (webConfig.DecimalDiscount) {
          var tempDecimalDiscount = parseInt(webConfig.DecimalDiscount);
          if (tempDecimalDiscount > 0) {
            that.isDecimalDiscount = true;
            that.decimalDiscount = Number(tempDecimalDiscount / 10);
          }
        }
      }
    },
    // 抹零优惠
    formatMoney(tempmoney) {
      var that = this;
      var resultMonty = tempmoney;
      if (that.isDecimalDiscount) {
        //var temp = 10 * decimalDiscount;
        var temp = Math.pow(10, that.decimalDiscount);
        resultMonty = Math.round(tempmoney * temp) / temp;
      }
      return resultMonty;
    },
    // 提交
    submitOrder(code) {
      var that = this;
      that.$toast.loading({
        message: "正在提交...",
        duration: 0,
      });

      var data = {
        ListSellWellCarId: [], // 全部商品id
        CustomerRemark: that.remarks ? that.remarks : "", // 备注
        couponId: "", // 优惠券id
        isAccount: that.isSelected, //是否使用余额
        NextCode: code ? code : "", // 验证是否之前下过相同的商品
      };
      // 商品列表id
      that.goodDetail.ListGoods.forEach((item, index) => {
        data.ListSellWellCarId.push({
          Id: item.Id,
        });
      });
      // 收货人地址信息
      if (that.addShow) {
        var address = that.addressInfo;
        data.Mobile = address.Mobile; // 收货人手机号
        data.Consignee = address.Consignee; // 名称
        data.Address = address.Address;
        data.ReceiveAddressId = address.Id;
      } else {
        that.$toast("请选择收货地址");
        return false;
      }
      // 支付方式
      data.OrderPayType = that.columnId;
      if (data.OrderPayType == -1) {
        that.$toast("请选择支付方式");
        return false;
      }
      // 优惠券
      // console.log(that.coupon);
      if (that.coupon.couponName == "优惠券") {
        data.couponId = that.coupon.Id;
      } else if (that.coupon.couponName == "红包") {
        data.ReadPacketDetailId = that.coupon.Id;
        data.ReadType = that.coupon.Type;
      }
      // 发票类型
      data.TmsInvoiceType = that.invoice_index;

      //统计分析 埋点进来
      that.keyId = ""; //主键
      that.public.setBuryPoint(6, 1, that.keyId).then((res) => {
        // if(res.errorCode=="00"){
        // 	that.keyId = res.rows;
        // }
      });

      that.public.request("post", "/api/SellWellCar/CreateOrder", data, (res) => {
        that.$toast.clear();
        // 存在哪个品种，未付款“存在***品种已下单未付款，是否继续”
        if (res.data.errorCode == "01") {
          that.$dialog
            .confirm({
              message: res.data.errorMsg,
            })
            .then(() => {
              that.submitOrder("01");
            })
            .catch(() => { });
          return;
        }
        // 存在品种当天已购买，“存在***品种今日已下单，是否继续”
        if (res.data.errorCode == "02") {
          that.$dialog
            .confirm({
              message: res.data.errorMsg,
            })
            .then(() => {
              that.submitOrder("02");
            })
            .catch(() => { });
          return;
        }
        if (res.data.errorCode == "00") {
          if (res.data.rows.AdvancePay && data.OrderPayType == "1" && that.isUserMoney) {
            // 跳转支付宝、微信支付

            that.$router.push({
              path: "/orderPay",
              query: {
                batchNo: res.data.rows.BatchOrderNo,
                MasterBatchNo: res.data.rows.MasterBatchNo,
                pageType: "waitBuyPay",
              },
            });
          } else {
            that.$router.push({
              path: "/orderSuccess",
              query: {
                OrderNo: res.data.rows.OrderNo,
                BatchNo: res.data.rows.BatchOrderNo,
                MasterBatchNo: res.data.rows.MasterBatchNo,
              },
            });
          }
        } else if (res.data.errorCode == "99") {
          that.$toast(res.data.errorMsg);
        }
      });
    },

    // 选择地址
    toAddress: function (addressId) {
      var that = this;
      that.$router.push({
        path: "/receiveAddress",
        query: {
          addressId: addressId,
        },
      });
    },

    // 发票列表
    getInvoice() {
      var that = this;
      var data = {};
      that.public.request("post", "/api/InvoiceType/QueryList", data, (res) => {
        if (res.data.errorCode == "00") {
          // console.log('发票',res.data.rows);
          that.invoiceCloumn = res.data.rows;
          that.invoice_index = res.data.rows[0].InvoiceTypeCode;
          that.invoiceType = res.data.rows[0].InvoiceTypeName;
        }
      });
    },

    // 商品详情
    getWaitBuy() {
      var that = this;
      var data = {};
      that.$toast.loading({
        message: "加载中...",
        duration: 0,
      });
      that.public.request("post", "/api/SellWellCar/OrderConfirm", data, (res) => {
        that.$toast.clear();
        if (res.data.errorCode == "00") {
          if (!res.data.rows) {
            that.$toast("数据已过期");
            setTimeout(function () {
              that.$router.push({
                path: "/cart",
              });
            }, 2000);
            return false;
          }

          var goodDetail = res.data.rows;

          //超过库存数量，更改下单数量   计算总价
          goodDetail.ListGoods.forEach((item, index) => {
            if (item.BuyCount > item.kcshl) {
              item.BuyCount = item.kcshl;
            }
          });

          var total = 0;
          let isFree = true; //是否全部包邮
          let freePic = 0; //包邮的总价格
          goodDetail.ListGoods.forEach((item, index) => {
            total += item.BuyPrice * item.BuyCount; //总价格
            if (item.ActivityCacheData && item.ActivityCacheData.IsFree) {
              freePic += item.BuyPrice * item.BuyCount;
            }
            if (!item.ActivityCacheData || !item.ActivityCacheData.IsFree) {
              isFree = false;
            }
          });

          that.goodDetail = goodDetail;

          var discount = 0;
          // 为优惠类型分类
          var discountObj = {};
          // console.log("分类",discountObj);
          goodDetail.ListGoods.forEach((item, index) => {
            var obj = item.Preferential;
            // console.log(obj);
            if (Object.keys(obj).length == 1) {
              for (var key in obj) {
                if (discountObj.hasOwnProperty(key) == true) {
                  discountObj[key] += obj[key];
                } else {
                  discountObj[key] = obj[key];
                }
              }
            }
          });
          var discountArr = [];
          for (var key in discountObj) {
            discountArr.push({ name: key, money: discountObj[key] });
          }

          that.discountArr = discountArr;
          that.discountObj = discountObj;
          //计算优惠后的价格
          for (var key in discountObj) {
            if (key != "满赠优惠") {
              discount += discountObj[key];
            }
          }
          that.discount = discount;
          // console.log(that.discount);
          // 物流设置--运费判断
          that.freight = 0;
          var discountArr = that.discountArr;
          if (!isFree) {
            //全部商品含有 不包邮的， 收取运费
            let totalPic = Number(total) - Number(freePic);
            if (
              that.goodDetail.IsSetFreight &&
              that.goodDetail.IncludePostage > totalPic
            ) {
              that.freight = goodDetail.Postage;
              discountArr.push({ name: "运费", money: goodDetail.Postage });
            }
          }

          var totalMoney = Number(total).toFixed(that.pointNum);

          // 获取优惠券
          that.getCoupon(totalMoney);
          // 获取红包
          that.getRedPack(totalMoney);

          that.totalMoney = totalMoney;
        } else {
          if (!res.data.rows) {
            that.$toast("数据已过期,请重新选择");
            setTimeout(function () {
              that.$router.push({
                path: "/cart",
              });
            }, 2000);
            return false;
          }
        }
      });
    },
    // 地址列表
    getAddress(addressId) {
      var that = this;
      var addressId = addressId;

      var data = {
        id: "",
      };

      if (addressId) {
        data.id = addressId;
      }
      that.public.request(
        "get",
        "/api/ReceiveAddress/GetReceiveAddress",
        data,
        (res) => {
          if (res.data.errorCode == "00") {
            if (!res.data.rows) {
              that.addShow = false;
            } else {
              that.addShow = true;
              that.addressInfo = res.data.rows;
            }
          }
        },
        "get"
      );
    },

    // 支付选择
    onConfirm(value) {
      this.columnId = value.OrderPayType; // 选中的支付方式的对象
      this.payType = value.CheckoutName;
      this.showPicker = false;
    },
    // 发票选择
    onInvoice(value) {
      this.invoiceType = value.InvoiceTypeName;
      this.invoice_index = value.InvoiceTypeCode; // 选中发票对象下标
      this.invoiceShow = false;
    },
    // 优惠券页面
    toMyCoupon() {
      var that = this;
      that.$router.push({
        path: "/myCoupon",
        query: {
          type: "orderPay",
        },
      });
    },

    // 获取当前优惠券的有效金额
    getCurCoupMoney(totalMoney, extend, couponItem) {
      var that = this;
      var totalUnCoupidMoney = totalMoney;
      var goodList = that.goodDetail.ListGoods;
      if (!extend || !couponItem) {
        return totalUnCoupidMoney;
      }
      if (goodList == undefined || goodList == null || goodList.length === 0) {
        return totalUnCoupidMoney;
      }
      //优惠券设置了不使用优惠券就不能使用该优惠券，减去对应的商品
      if (couponItem.NoActiveList && couponItem.NoActiveList.length > 0) {
        goodList = goodList.filter((car) => {
          //car.CartSource = 112;
          if (car.OfferId) {
            car.CartSource = 122;
            totalUnCoupidMoney = totalUnCoupidMoney - car.BuyCount * car.BuyPrice - car.DiscountAmount;
            return !couponItem.NoActiveList.some((i) => i === 5);
          }
          return true;
        });

        if (goodList.length == 0) {
          totalUnCoupidMoney = 0;
          return totalUnCoupidMoney;
        }
      }

      goodList.filter((car) => {
        if (couponItem.NoActiveList && couponItem.NoActiveList.length > 0) {
          let noActive = false;
          switch (parseInt(car.CartSource)) {
            case 20: //秒杀
              noActive = couponItem.NoActiveList.some((i) => i === 0);
              break;
            case 35: //集采
              noActive = couponItem.NoActiveList.some((i) => i === 1);
              break;
            case 30: //团购
              noActive = couponItem.NoActiveList.some((i) => i === 2);
              break;
            case 90: //今日特价
              noActive = couponItem.NoActiveList.some((i) => i === 3);
              break;
            case 121: //新人特价
              noActive = couponItem.NoActiveList.some((i) => i === 4);
              break;
            case 122: //优惠方案
              noActive = couponItem.NoActiveList.some((i) => i === 5);
              break;
            case 80: //近效期
              noActive = couponItem.NoActiveList.some((i) => i === 6);
              break;
            default:
              break;
          }
          if (noActive) {
            var moneys = car.BuyCount * car.BuyPrice - car.DiscountAmount;
            totalUnCoupidMoney = totalUnCoupidMoney - moneys;
            if (totalUnCoupidMoney <= 0) {
              totalUnCoupidMoney = 0;
            } else if (couponItem.MixOrderAmount && couponItem.MixOrderAmount > 0) {
              if (totalUnCoupidMoney >= couponItem.MixOrderAmount) {
                totalUnCoupidMoney = couponItem.MixOrderAmount - 0.1;
              }
            }
          }
        }
        //后台设置了商品不能使用该券，减去对应的商品金额
        let _extentSome = extend.some((n) => {
          if (couponItem.CouponId === n.CouponId && n.Spid === car.Spid) {
            var moneys = car.BuyCount * car.BuyPrice - car.DiscountAmount;
            totalUnCoupidMoney = totalUnCoupidMoney - moneys;
            if (totalUnCoupidMoney <= 0) {
              totalUnCoupidMoney = 0;
            }
            return true;
          }
        });

        return _extentSome;
      });

      let isUseCoupon = false;
      that.couponList.forEach((use) => {
        if (use.CouponId == couponItem.CouponId) {
          isUseCoupon = true;
        }
      });
      if (!isUseCoupon) {
        return 0;
      }
      return totalUnCoupidMoney;
    },
    // 获取当前红包的有效金额
    getCurRedPackMoney(totalMoney, extend, couponItem) {
      var that = this;
      var totalUnCoupidMoney = totalMoney;
      var goodList = that.goodDetail.ListGoods;
      var goodSpid = [];
      for (var m in extend) {
        if (extend[m].PacketDetailId == couponItem.Id) {
          goodSpid = extend[m].Goods;
        }
      }
      goodList.filter((car) => {
        return extend.some((n) => {
          return goodSpid.some((j) => {
            if (couponItem.Id == n.PacketDetailId && j == car.Spid) {
              var moneys = car.BuyCount * car.BuyPrice - car.DiscountAmount;
              totalUnCoupidMoney = totalUnCoupidMoney - moneys;
              if (totalUnCoupidMoney <= 0) {
                totalUnCoupidMoney = 0;
              }
              return true;
            }
          });
        });
      });

      return totalUnCoupidMoney;
    },
    // 比较优惠券和红包最大的金额，使用优惠金额大的
    maxCoupon() {
      var that = this;
      var couponList = that.$store.state.couponList;
      var redPackList = that.redPackList;
      // 优惠券和红包都有数据
      if (couponList.length > 0 || redPackList.length > 0) {
        // 最大优惠券
        var Amount = Math.max.apply(
          Math,
          couponList.map((item) => {
            return item.Amount;
          })
        );
        var arr = [];
        couponList.forEach((item, index) => {
          if (item.Amount == Amount) {
            arr.push(item);
          }
        });

        var maxCouponList = arr;
        // 最大红包
        var Moneys = Math.max.apply(
          Math,
          redPackList.map((item) => {
            return item.Moneys;
          })
        );
        var arr1 = [];
        redPackList.forEach((item, index) => {
          if (item.Moneys == Moneys) {
            arr1.push(item);
          }
        });
        var maxRedPackList = arr1;
        if (couponList.length > 0 && redPackList.length > 0) {
          // 优惠券比红包的优惠金额大，则用优惠券，否则用红包
          if (maxCouponList[0].Amount > maxRedPackList[0].Moneys) {
            that.newCoupon(maxCouponList);
          } else if (maxCouponList[0].Amount < maxRedPackList[0].Moneys) {
            that.newRedPack(maxRedPackList);
          }
        }

        // 如果只有优惠券，则用优惠券，否则用红包
        if (couponList.length > 0 && redPackList.length <= 0) {
          that.newCoupon(maxCouponList);
        } else if (couponList.length <= 0 && redPackList.length > 0) {
          that.newRedPack(maxRedPackList);
        }
      }
    },

    // 组成新的最大优惠券
    newCoupon(maxCouponList) {
      var that = this;
      that.coupon = maxCouponList[0];
      var coupon = maxCouponList[0];
      // console.log(coupon,"优惠券");
      coupon.couponName = "优惠券";
      that.couponTit = "优惠券";
      var couponName = "满" + coupon.MixOrderAmount + "减" + coupon.Amount;
      that.couponName = couponName;
      that.extend = that.couponExtend;
    },
    // 组成新的最大红包
    newRedPack(maxRedPackList) {
      var that = this;
      that.coupon = maxRedPackList[0];
      var redPack = maxRedPackList[0];
      redPack.couponName = "红包";
      that.couponTit = "红包";
      var couponName = "满" + redPack.MinMoneys + "减" + redPack.Moneys;
      that.couponName = couponName;
      that.extend = that.redPackExtend;
    },
  },
  computed: {
    // 商品优惠金额(合计)
    totalPirce: function () {
      var that = this;
      if (that.goodDetail.length == 0) return;
      var rows = that.goodDetail.ListGoods;
      if (!rows) return;

      // 比较优惠券和红包最大的金额，使用优惠金额大的
      var couponIt = that.$store.state.couponItem;
      // 没有去选择优惠券，则执行
      if (Object.keys(couponIt).length == 0) {
        that.maxCoupon();
      }

      var coupon = that.coupon;
      var extend = that.extend;
      var totalMoney = that.totalMoney;
      // console.log("coupon",coupon);
      // 处理优惠券红包
      if (coupon.couponName == "优惠券") {
        // 减去禁止使用的优惠券的商品价格
        var money = that.getCurCoupMoney(totalMoney, extend, coupon);
        that.couponMoney.money = 0;
        if (Number(money) >= Number(coupon.MixOrderAmount)) {
          that.couponMoney.money = coupon.Amount;
          totalMoney = parseFloat(totalMoney - coupon.Amount).toFixed(that.pointNum);
        } else {
          that.coupon = {};
          that.couponName = "请选择";
        }
      } else if (coupon.couponName == "红包") {
        var money = that.getCurRedPackMoney(totalMoney, extend, coupon);
        // 红包可以使用
        that.couponMoney.money = 0;
        if (Number(money) >= Number(coupon.MinMoneys)) {
          that.couponMoney.money = coupon.Moneys;
          totalMoney = parseFloat(totalMoney - coupon.Moneys).toFixed(that.pointNum);
        } else {
          that.coupon = {};
          that.couponName = "请选择";
        }
      }

      totalMoney = that.formatMoney(totalMoney);
      // 减去满赠优惠
      totalMoney -= that.discount;
      // console.log(that.freight);
      // 加上运费
      totalMoney = Number(totalMoney) + Number(that.freight);

      totalMoney = Number(totalMoney).toFixed(that.pointNum);
      return totalMoney;
    },
    // 商品总金额
    goodTotalPrice: function () {
      var that = this;
      if (that.goodDetail.length == 0) return;
      var rows = that.goodDetail.ListGoods;
      if (!rows) return;
      var price = 0;
      rows.forEach((item, i) => {
        if (item.CartSource > 0) {
          price += item.BuyPrice * item.BuyCount;
        } else {
          price += item.BuyPrice * item.BuyCount;
        }
      });
      //抹零优惠处理
      var tempNum = Number(price).toFixed(that.pointNum);
      if (that.isDecimalDiscount) {
        tempNum = that.formatMoney(price);
      }
      return tempNum;
    },
  },
};
</script>

<style scoped>
/* 收货地址 */
.address-box {
  width: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
}

.address-box .not-address .img-box img {
  width: 0.6rem;
  margin-right: 0.2rem;
}

.address-box .not-address .img-box span {
  font-size: 0.3rem;
}

.address-box .not-address .img-r {
  width: 0.16rem;
  margin-left: 0.2rem;
}

.address-box .address-info {
  align-items: flex-start;
}

.address-box .address-info .img-add {
  width: 0.36rem;
  margin-right: 0.2rem;
}

.address-box .address-info .address-right {
  flex: 1;
}

.address-box .address-info .address-right .use-info {
  flex: 1;
}

.address-box .address-info .address-right .use-info .right-mobile span {
  font-size: 0.3rem;
}

.address-box .address-info .address-right .add-cont {
  font-size: 0.28rem;
  color: #999;
}

.address-box .address-info .address-right .img-r {
  width: 0.16rem;
  margin-left: 0.28rem;
}

/* 商品详情列表 */
.good-list {
  width: 100%;
  padding: 0.2rem 0;
}

.good-list .good-item {
  width: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
  border-bottom: 0.2rem solid #fff;
  background: #f7f7f7;
}

.good-list .good-item .gift-box {
  font-size: 0.26rem;
  color: #f32222;
  padding-left: 0.2rem;
  box-sizing: border-box;
}

.good-list .good-item:last-child {
  border-bottom: none;
}

.good-item .item-left {
  width: 2.3rem;
  margin-right: 0.12rem;
}

.good-item .item-left .left-img {
  width: 2rem;
  max-height: 2rem;
  margin: 0 auto;
  border-radius: 0.12rem;
  object-fit: contain;
}

.good-item .item-left .item-setime {
  width: 80%;
  margin: 0.12rem auto 0;
  padding: 0.02rem 0.16rem;
  background: #199ed8;
  border-radius: 0.28rem;
}

.good-item .item-left .item-setime img {
  width: 0.22rem;
  height: 0.22rem;
  margin-right: 0.12rem;
}

.good-item .item-left .item-setime .settime-tit {
  color: #fff;
  font-size: 0.22rem;
}

.good-item .item-info {
  flex: 1;
  box-sizing: border-box;
  justify-content: space-between;
}

.good-item .item-info .info-name {
  width: 4rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 0.3rem;
}

.good-item .item-info .info-stock .stock-tit {
  font-size: 0.24rem;
}

.good-item .item-info .info-stock .stock-quota {
  font-size: 0.24rem;
}

.good-item .item-info .info-specifi {
  font-size: 0.24rem;
}

.good-item .item-info .info-company {
  font-size: 0.28rem;
  width: 4rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.good-item .item-info .info-seck .info-price .info-tit {
  font-size: 0.36rem;
}

.good-item .item-info .info-seck .info-price .info-old-price {
  font-size: 0.24rem;
  margin-left: 0.12rem;
  text-decoration: line-through;
}

.good-item .item-info .info-seck .seck-btn {
  display: inline-block;
  padding: 0.02rem 0.2rem;
  font-size: 0.28rem;
}

/* 优惠券、发票 */
.invoce-box {
  width: 100%;
  padding: 0 0.2rem;
  box-sizing: border-box;
}

.invoce-box .invoce-item {
  width: 100%;
  height: 0.8rem;
  border-bottom: 0.02rem solid #eee;
}

.invoce-box .invoce-item:last-child {
  border-bottom: none;
}

.invoce-box .invoce-item .invoce-tit {
  font-size: 0.3rem;
  width: 1.6rem;
}

.invoce-box .invoce-item .picker-box {
  flex: 1;
}

.invoce-box .invoce-item .picker-box .picker-tit {
  font-size: 0.28rem;
  flex: 1;
  text-align: right;
}

.invoce-box .invoce-item .picker-box img {
  width: 0.15rem;
  margin-left: 0.18rem;
}

/* 价格详情 */
.price-list {
  width: 100%;
  padding: 0.1rem 0.2rem;
  box-sizing: border-box;
}

.price-list .price-item {
  width: 100%;
  height: 0.6rem;
}

.price-list .price-item:first-child {
  border-bottom: 0.02rem solid #eee;
}

.price-list .price-item span {
  font-size: 0.3rem;
}

.price-list .price-item span.item-tit {
  font-size: 0.32rem;
}

/* 备注 */
.remarks {
  width: 100%;
  height: 1.2rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
}

.remarks span {
  font-size: 0.32rem;
  width: 1.2rem;
}

.remarks input {
  flex: 1;
  font-size: 0.3rem;
}

/* 底部  总价 */
.bot-fixed {
  width: 100%;
  height: 1rem;
}

.bot-total {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
  z-index: 2;
  background: #fff;
  border-top: 0.02rem solid #eee;
}

.bot-total .total-left {
  flex: 1;
}

.bot-total .total-left .left-tit {
  font-size: 0.3rem;
}

.bot-total .total-left .total-price span:first-child {
  font-size: 0.3rem;
}

.bot-total .total-left .total-price span:last-child {
  font-size: 0.36rem;
}

.bot-total .but-submit {
  font-size: 0.3rem;
  /* background: #f32222; */
  background: var(--theme-color);
  padding: 0.08rem 0.2rem;
  border-radius: 0.2rem;
  color: #fff;
  margin-left: 0.16rem;
}

/* 套餐包 */
.good-list .good-item .pack-item {
  margin-bottom: 0.2rem;
}

.good-list .good-item .pack-seck {
  display: flex;
  justify-content: flex-end;
}

.good-list .good-item .pack-seck .info-price .info-tit {
  font-size: 0.32rem;
}

.good-list .good-item .pack-seck .seck-btn {
  font-size: 0.3rem;
  margin-left: 0.6rem;
}

.surplusMoney {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* 新版 */
.submit-box {
  position: relative;
  width: 100;
}

.submit-box .red-box {
  width: 100%;
  height: 2rem;
  background: var(--theme-color);
}

.submit-box .order-info {
  position: absolute;
  width: 92%;
  top: 1rem;
  left: 4%;
}

.submit-box .order-info .order-line {
  width: 100%;
  height: 0.32rem;
  background: rgb(99 95 95 / 45%);
  border-radius: 0.3rem;
}

.submit-box .order-info .info-shop {
  width: 96%;
  position: absolute;
  top: 0.15rem;
  left: 2%;
  background: #fff;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  padding: 0.2rem 0.3rem;
  box-sizing: border-box;
  box-shadow: 0 0 0.3rem 0.06rem #ede3e3;
  margin-bottom: 1.6rem;
}

/* 地址 */
.submit-box .order-info .info-shop .address-info {
  width: 100%;
  padding-bottom: 0.2rem;
  border-bottom: 0.03rem dashed #999;
}

.submit-box .order-info .info-shop .address-info .address-label span:first-child {
  font-size: 0.26rem;
  padding: 0.08rem 0.2rem;
  color: #fff;
  background: var(--theme-color);
  margin-right: 0.2rem;
  border-radius: 0.12rem;
}

.submit-box .order-info .info-shop .address-info .address-label span:last-child {
  font-size: 0.26rem;
  color: #999;
}

.submit-box .order-info .info-shop .address-info .address-txt {
  margin-top: 0.1rem;
}

.submit-box .order-info .info-shop .address-info .address-txt span {
  font-size: 0.3rem;
  font-weight: 700;
}

.submit-box .order-info .info-shop .address-info .address-txt img {
  width: 0.16rem;
}

.submit-box .order-info .info-shop .address-info .address-name span {
  font-size: 0.3rem;
  font-weight: 700;
}

.submit-box .order-info .info-shop .address-info .address-name span:first-child {
  margin-right: 0.3rem;
}

.submit-box .order-info .info-shop .address-no .address-img {
  flex: 1;
}

.submit-box .order-info .info-shop .address-no .address-img img {
  width: 0.36rem;
}

.submit-box .order-info .info-shop .address-no .address-img span {
  font-size: 0.32rem;
}

.submit-box .order-info .info-shop .address-no .img-right {
  width: 0.16rem;
}

/* 商品详情 */
.submit-box .order-info .info-shop .shop-detail {
  padding: 0.2rem 0;
  box-sizing: border-box;
  border-bottom: 0.03rem dashed #999;
}

.submit-box .order-info .info-shop .shop-detail .shop-n {
  font-size: 0.32rem;
  font-weight: 700;
}

.submit-box .order-info .info-shop .shop-detail .shop-list .shop-item {
  margin-top: 0.2rem;
}

.submit-box .order-info .info-shop .shop-detail .shop-list .shop-item .shop-item-img {
  width: 1.6rem;
  object-fit: contain;
  margin-right: 0.2rem;
}

.submit-box .order-info .info-shop .shop-detail .shop-list .shop-item .item-det {
  flex: 1;
}

.submit-box .order-info .info-shop .shop-detail .shop-list .shop-item .item-det .item-name span {
  font-size: 0.3rem;
  font-weight: 700;
}

.submit-box .order-info .info-shop .shop-detail .shop-list .shop-item .item-det .item-name span:first-child {
  width: 3.2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.submit-box .order-info .info-shop .shop-detail .shop-list .shop-item .item-det .item-specs {
  margin-top: 0.08rem;
}

.submit-box .order-info .info-shop .shop-detail .shop-list .shop-item .item-det .item-specs span {
  font-size: 0.26rem;
  color: #999;
}

.submit-box .order-info .info-shop .shop-detail .shop-list .shop-item .item-det .item-specs span:first-child {
  display: inline-block;
  width: 3.4rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.submit-box .order-info .info-shop .shop-detail .shop-list .shop-item .item-det .item-factory {
  padding: 0 0.12rem;
  box-sizing: border-box;
  border-radius: 0.3rem;
  background: #d4ecf0;
  font-size: 0.24rem;
  margin-top: 0.2rem;
  width: 4rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.info-shop .shop-detail .shop-list .list-more-txt {
  justify-content: center;
  margin-top: 0.2rem;
}

.info-shop .shop-detail .shop-list .list-more-txt img {
  width: 0.3rem;
  margin-right: 0.2rem;
}

.info-shop .shop-detail .shop-list .list-more-txt span {
  font-size: 0.24rem;
  color: var(--theme-color);
}

/* 支付方式、发票类型 */
.info-shop .select-box .select-row {
  margin-top: 0.2rem;
}

.info-shop .select-box .select-row .sel-left {
  font-size: 0.3rem;
  width: 1.8rem;
}

.info-shop .select-box .select-row .sel-rig {
  flex: 1;
  justify-content: flex-end;
}

.info-shop .select-box .select-row .sel-rig .sel-tit {
  font-size: 0.3rem;
}

.info-shop .select-box .select-row .sel-rig .sel-tit.sel-active {
  color: #999;
}

.info-shop .select-box .select-row .sel-rig img {
  width: 0.16rem;
  margin-left: 0.2rem;
}

.info-shop .select-box .select-row .sel-remark {
  width: 1.2rem;
  font-size: 0.3rem;
}

.info-shop .select-box .select-row .sel-rig {
  font-size: 0.3rem;
}

.invoce-item {
  margin-top: 0.2rem;
}

.invoce-item .item-left .invoce-tit {
  font-size: 0.3rem;
}

.input-sy {
  margin-right: 0.6rem;
  width: 0.4rem;
  height: 0.4rem;
  overflow: hidden;
}
</style>
<style>
/* 弹窗背景色 */
.van-overlay {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
</style>
