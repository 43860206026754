import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Login from '@/views/login/login.vue'
import forgetPass from '@/views/login/forgetPass.vue'
import selectCompany from '@/views/login/selectCompany.vue'
import index from '@/views/index/index.vue'
import coupon from '@/views/index/coupon.vue'
import seckillList from '@/views/index/seckillList.vue'
import goodsDetail from '@/views/index/goodsDetail.vue'
import search from '@/views/search/searchPage.vue'
import allProducts from '@/views/search/allProducts.vue'
import cart from '@/views/cart/cartPage.vue'
import mine from '@/views/mine/mine.vue'
import bargainPrice from '@/views/index/bargainPrice.vue'
import Package from '@/views/index/package.vue'
import termValidity from '@/views/index/termValidity.vue'
import brandZone from '@/views/index/brandZone.vue'
import brandList from '@/views/index/brandList.vue'
import searchHistory from '@/views/search/searchHistory.vue'
import waitBuyPay from '@/views/index/waitBuyPay.vue'
import myCoupon from '@/views/mine/myCoupon.vue'
import purchaseOrder from '@/views/mine/purchaseOrder.vue'
import evaluate from '@/views/mine/evaluate.vue'
import orderDetail from '@/views/mine/orderDetail.vue'
import myBrowse from '@/views/mine/myBrowse.vue'
import myCollect from '@/views/mine/myCollect.vue'
import wantBook from '@/views/mine/wantBook.vue'
import paperCapitalPlan from '@/views/mine/paperCapitalPlan.vue'
import mySecure from '@/views/mine/mySecure.vue'
import seePlanDetail from '@/views/mine/seePlanDetail.vue'
import receiveAddress from '@/views/mine/receiveAddress.vue'
import editAddress from '@/views/mine/editAddress.vue'
import aboutUs from '@/views/mine/about.vue'
import serveAgreement from '@/views/mine/serveAgreement.vue'
import privacyPolicy from "@/views/pages/policyAgreement.vue"
import companyInfo from '@/views/mine/companyInfo.vue'
import oftenBreed from '@/views/mine/oftenBreed.vue'
import scan from '@/views/index/scan.vue'
import floorList from '@/views/index/floorList.vue'
import activeDetail from '@/views/index/activeDetail.vue'
import favourableActive from '@/views/index/favourableActive.vue'
import favourList from '@/views/index/favourList.vue'
import orderSuccess from '@/views/index/orderSuccess.vue'
import redPackRain from '@/views/index/redPackRain.vue'
import turntable from '@/views/index/turntable.vue'
import orderPay from '@/views/index/orderPay.vue'
import saleBindOpenId from '@/views/userbind/saleBindOpenId.vue'
import employeeIndex from '@/views/userbind/employeeIndex.vue'
import logreg01 from '@/views/reg/setup01.vue'
import logreg02 from '@/views/reg/setup02.vue'
import updateBasicInfo from '@/views/reg/updateBasicInfo.vue'
import InvoiceOrder from '@/views/mine/InvoiceOrder.vue'
import InvoiceDetails from '@/views/mine/InvoiceDetails.vue'
import writeInvoice from '@/views/mine/writeInvoice.vue'
import billManage from '@/views/mine/billManage.vue'
import qualification from '@/views/mine/qualification.vue'
import companyZiZhi from '@/views/mine/companyZiZhi.vue'
import pcOrderPay from '@/views/pcOrderPay/pcOrderPay.vue'
import mobileAliPay from '@/views/mobileAliPay/mobileAliPay.vue'
import pointsDetails from '@/views/mine/points/pointsDetails.vue'
import pointsMall from '@/views/mine/points/pointsMall.vue'
import exchangeRecord from '@/views/mine/points/exchangeRecord.vue'
import pointOrder from '@/views/mine/points/pointOrder.vue'
import details from '@/views/mine/points/details.vue'
import shopOrder from '@/views/mine/points/shopOrder.vue'
import noPermission from '@/views/mine/noPermission.vue'
import pointsOrderDetails from '@/views/mine/points/pointsOrderDetails.vue'
import roundTable from '@/views/index/roundTable.vue'
import text from '@/views/mine/text.vue'
import shopAllProducts from '@/views/index/shopAllProducts.vue'
import Bind from '@/views/index/Bind.vue'
import logisticsInfo from '@/views/mine/logisticsInfo.vue'
import SingleProduct from '@/views/index/SingleProduct.vue'
import classifcation from '@/views/classifcation/classifcation.vue'
import employeeBind from '@/views/userbind/employeeBind.vue'
import ArticleDetail from '@/views/index/ArticleDetail.vue'
import productRecommend from '@/views/classifcation/newProducts.vue'
import reportForm from '@/views/pages/reportForm.vue'
import expertVideos from '@/views/pages/expertVideos.vue'
import expertDetails from '@/views/pages/expertDetails.vue'
import setting from '@/views/pages/setting.vue'
import signInDetails from '@/views/pages/signInDetails.vue'
import signInGift from '@/views/pages/signInGift.vue'
import followOfficial from '@/views/pages/followOfficial.vue'
import lotteryDraw from '@/views/pages/lotteryDraw.vue'
import CMworld from '@/views/pages/CMworld.vue'
import dailySpecial from '@/views/pages/dailySpecial.vue'
import buyBook from '@/views/pages/buyBook.vue'
import editBuyBook from '@/views/pages/editBuyBook.vue'
import posterPage from '@/views/pages/posterPage.vue'
import bankCard from '@/views/pages/bankCard.vue'
import bankCardBind from '@/views/pages/bankCardBind.vue'

Vue.use(VueRouter)
const routes = [{
		// 首页
		path: '/',
		name: '首页',
		component: index,
		meta: {
			isTabBar: true, //true底部导航显示
			keepAlive: true, // 页面不刷新
			isTop: true, // true顶部导航栏不显示
		}
	},
	{
		// 搜索页面
		path: '/search',
		name: '搜索页',
		component: search,
		meta: {
			keepAlive: true, // 页面不刷新
			isRefresh: false, // 页面是否刷新 false刷新  true不刷新
			isTop: true, // 顶部导航不显示
		}
	},
	{
		// 购物车页面
		path: '/cart',
		name: '购物车',
		component: cart,
		meta: {
			isTabBar: true, //true底部导航显示
			isTop: true, // true顶部导航栏不显示
		}
	},
	{
		// 我的页面
		path: '/mine',
		name: '我的',
		component: mine,
		meta: {
			isTabBar: true, //true底部导航显示
			isTop: true, // true顶部导航栏不显示
		}
	},
	{
		// 分类页面
		path: '/classifcation',
		name: '分类',
		component: classifcation,
		meta: {
			isTabBar: true, //true底部导航显示
			isTop: true, // true顶部导航栏不显示
			isRefresh: false, // 页面是否刷新 false刷新  true不刷新
			keepAlive: true,
		}
	},
	{
		path: '/Home',
		name: 'Home',
		component: Home
	},
	// {
	//   path: '/about',
	//   name: 'About',
	//   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	// },
	{
		// 登录页面
		path: '/login',
		name: '登录',
		component: Login,
		meta: {
			isTop: true, // true顶部导航栏不显示
		}
	},

	{
		// 修改密码页面
		path: '/forgetPass',
		name: '修改密码',
		component: forgetPass,
		meta: {
			isTop: true, // true顶部导航栏不显示
		}
	},
	{
		// 选择机构页面
		path: '/selectCompany',
		name: '选择机构',
		component: selectCompany,
		meta: {
			// isTop: true,   // true顶部导航栏不显示
		}
	},
	{
		// 购物券页面
		path: '/coupon',
		name: '购物券',
		component: coupon,
	},
	{
		// 秒杀列表页面
		path: '/seckillList',
		name: '秒杀列表',
		component: seckillList,
	},
	{
		// 商品详情页面
		path: '/goodsDetail',
		name: '商品详情',
		component: goodsDetail,
		meta: {
			isTop: true, // true顶部导航栏不显示
			isBackPage: true, // true 返回按钮显示
			// keepAlive: true,
		}
	},
	{
		// 今日特价页面
		path: '/bargainPrice',
		name: '今日特价',
		component: bargainPrice,
	},
	{
		// 套餐包页面
		path: '/Package',
		name: '套餐包',
		component: Package,
	},
	{
		// 近效期页面
		path: '/termValidity',
		name: '近效期专区',
		component: termValidity,
	},
	{
		// 品牌专区页面
		path: '/brandZone',
		name: '品牌专区',
		component: brandZone,
	},
	{
		// 品牌列表页面
		path: '/brandList',
		name: '品牌列表',
		component: brandList,
	},
	{
		// 厂家专区页面
		path: '/millZone',
		name: '厂家专区',
		component: brandZone,
	},
	{
		// 厂家列表列表页面
		path: '/millList',
		name: '厂家列表',
		component: brandList,
	},
	{
		// 搜索页面
		path: '/searchHistory',
		name: '搜索',
		component: searchHistory,
		meta: {
			isTop: true, // true顶部导航栏不显示
			isTabBar: true, //true底部导航显示
		}
	},
	{
		// 全部商品页面
		path: '/allProducts',
		name: '商品列表',
		component: allProducts,
		meta: {
			isBack: true, //  可以返回上一页
			keepAlive: true, // 页面不刷新
			isRefresh: false,
			isTop: true, // true顶部导航栏不显示
		}
	},
	{
		// 提交订单页面
		path: '/waitBuyPay',
		name: '提交订单',
		component: waitBuyPay,
		meta: {
			keepAlive: true, // 页面不刷新
		}
	},
	{
		// 我的卡券
		path: '/myCoupon',
		name: '我的卡券',
		component: myCoupon,
	},
	{
		// 我的采购订单
		path: '/purchaseOrder',
		name: '采购订单',
		component: purchaseOrder,
	},
	{
		// 评价页面
		path: '/evaluate',
		name: '评价',
		component: evaluate,
	},
	{
		// 订单详情
		path: '/orderDetail',
		name: '订单详情',
		component: orderDetail,
	},
	{
		// 最近浏览
		path: '/myBrowse',
		name: '最近浏览',
		component: myBrowse,
	},
	{
		// 我的收藏
		path: '/myCollect',
		name: '我的收藏',
		component: myCollect,
	},
	{
		// 缺货登记
		path: '/wantBook',
		name: '缺货登记',
		component: wantBook,
	},
	{
		// 求购登记
		path: '/buyBook',
		name: '求购登记',
		component: buyBook,
	},
	{
		// 求购登记
		path: '/editBuyBook',
		name: '新增求购商品',
		component: editBuyBook,
	},
	{
		// 海报宣传
		path: '/posterPage',
		name: '宣传文案',
		component: posterPage,
	},
	{
		// 银行卡
		path: '/bankCard',
		name: '我的银行卡',
		component: bankCard,
	},
	{
		// 银行卡绑定
		path: '/bankCardBind',
		name: '添加银行卡',
		component: bankCardBind,
	},
	{
		// 纸资计划
		path: '/paperCapitalPlan',
		name: '纸资计划',
		component: paperCapitalPlan,
	},
	{
		// 安全中心
		path: '/mySecure',
		name: '安全中心',
		component: mySecure,
	},
	{
		// 查看计划
		path: '/seePlanDetail',
		name: '查看计划',
		component: seePlanDetail,
	},
	{
		// 收货地址
		path: '/receiveAddress',
		name: '收货地址',
		component: receiveAddress,
	},
	{
		// 编辑收货地址
		path: '/editAddress',
		name: '编辑收货地址',
		component: editAddress,
	},
	{
		// 关于我们
		path: '/aboutUs',
		name: '关于我们',
		component: aboutUs,
	},
	{
		// 服务协议
		path: '/serveAgreement',
		name: '服务协议',
		component: serveAgreement,
		meta: {
			isTop: false, // true顶部导航栏不显示
		}
	},
	{
		// 隐私政策
		path: '/privacyPolicy',
		name: '隐私政策',
		component: privacyPolicy,
		meta: {
			isTop: false, // true顶部导航栏不显示
		}
	},
	{
		// 企业信息
		path: '/companyInfo',
		name: '企业信息',
		component: companyInfo,
	},
	{
		// 常购品种
		path: '/oftenBreed',
		name: '常购品种',
		component: oftenBreed,
	},
	{
		// 扫码
		path: '/scan',
		name: '扫码',
		component: scan,
		meta: {
			isTop: true, // true顶部导航栏不显示
		}
	},
	{
		// 楼层列表
		path: '/floorList',
		name: '楼层列表',
		component: floorList,
		meta: {
			keepAlive: true, // 页面不刷新
			isRefresh: true,
			isTabBar: false, //true底部导航显示
		}
	},
	{
		// 楼层列表
		path: '/dailySpecial',
		name: '楼层列表',
		component: dailySpecial,
		meta: {
			keepAlive: true, // 页面不刷新
			isRefresh: true,
			isTabBar: false, //true底部导航显示
		}
	},
	{
		// 活动页面详情
		path: '/activeDetail',
		name: '详情',
		component: activeDetail,
		meta: {
			isTop: true, // true顶部导航栏不显示
			isBackPage: true, // true 返回按钮显示
		}
	},
	{
		// 优惠活动页面
		path: '/favourableActive',
		name: '优惠活动',
		component: favourableActive,
		meta: {
			// keepAlive: true, // 页面不刷新
		}
	},
	{
		// 优惠活动列表页面
		path: '/favourList',
		name: '优惠活动',
		component: favourList,

	},
	{
		// 支付成功页面
		path: '/orderSuccess',
		name: '下单成功',
		component: orderSuccess,
		meta: {
			isTop: true, // true顶部导航栏不显示
		}

	},
	{
		// 红包雨页面
		path: '/redPackRain',
		name: '红包雨',
		component: redPackRain,
		meta: {
			isTop: true, // true顶部导航栏不显示
		}
	},
	{
		//大转盘页面
		path: '/turntable',
		name: '大转盘',
		component: turntable,

	},
	{
		//订单支付页面
		path: '/orderPay',
		name: '支付',
		component: orderPay,
		meta: {
			isTop: true, // true顶部导航栏不显示
		}

	},
	{
		//业务员绑定
		path: '/saleBindOpenId',
		name: '业务员绑定',
		component: saleBindOpenId,
	},
	{
		//业务员绑定成功
		path: '/employeeIndex',
		name: '绑定成功',
		component: employeeIndex,
	},
	{
		//注册也没    注册1
		path: '/reg',
		name: '用户注册',
		component: logreg01,
		meta: {
			isTop: false,
			register: true, // true 返回按钮显示
		}
	},
	{
		//注册2
		path: '/regStep2',
		name: '新用户注册',
		component: logreg02,
	},
	{
		//完善基本信息
		path: '/updateBasicInfo',
		name: '完善基本信息',
		component: updateBasicInfo,
	},
	{
		//选择订单
		path: '/invoiceOrder',
		name: '选择订单',
		component: InvoiceOrder,
	},
	{
		//发票管理
		path: '/billManage',
		name: '发票管理',
		component: billManage,
	},

	{
		//发票详情
		path: '/InvoiceDetails',
		name: '发票详情',
		component: InvoiceDetails,
	},
	{
		//填写发票
		path: '/writeInvoice',
		name: '填写发票',
		component: writeInvoice,
	},
	{
		//公司资质
		path: '/companyZiZhi',
		name: '公司资质',
		component: companyZiZhi,
	},
	{
		//我的资质
		path: '/qualification',
		name: '我的资质',
		component: qualification,
	},
	{
		//pc支付订单
		path: '/pcOrderPay',
		name: '支付',
		component: pcOrderPay,
	},
	{
		//支付宝支付订单
		path: '/mobileAliPay',
		name: '支付宝支付',
		component: mobileAliPay,
		meta: {
			isTop: true, // true顶部导航栏不显示
		},
	},
	{
		//积分商城
		path: '/pointsMall',
		name: '积分商城',
		component: pointsMall,
	},
	{
		//积分商城
		path: '/pointsDetails',
		name: '积分明细',
		component: pointsDetails,
	},
	{
		//积分商城
		path: '/exchangeRecord',
		name: '积分兑换记录',
		component: exchangeRecord,
	},
	{
		// 积分订单
		path: '/pointOrder',
		name: '积分订单',
		component: pointOrder,
	},
	{
		// 积分兑换商品详情
		path: '/details',
		name: '商品详情',
		component: details,
	},
	{
		// 积分兑换商品提交订单
		path: '/shopOrder',
		name: '提交订单',
		component: shopOrder,
	},

	{
		path: '/noPermission',
		name: '',
		component: noPermission,
		meta: {
			isTop: true, // true顶部导航栏不显示
		},
	},

	{
		// 积分兑换商品提交订单
		path: '/pointsOrderDetails',
		name: '订单详情',
		component: pointsOrderDetails,
	},
	{
		// 单个店铺商品
		path: '/shopAllProducts',
		name: '全部商品',
		component: shopAllProducts,
		meta: {
			isTop: true, // true顶部导航栏不显示
		}
	},
	{
		//绑定
		path: '/Bind',
		name: '绑定',
		component: Bind,
		meta: {
			isTop: true, // true顶部导航栏不显示

		}
	},

	{
		// 圆桌会议
		path: '/roundTable',
		name: '',
		component: roundTable,
		meta: {
			isTop: true, // true顶部导航栏不显示
			keepAlive: true // 需要被缓存
		},

	},
	{
		// 测试
		path: '/text',
		name: '测试',
		component: text,
		meta: {
			isTabBar: true, //true底部导航显示
			isTop: true, // true顶部导航栏不显示

		}
	},
	{
		// 物流信息
		path: '/logisticsInfo',
		name: '物流信息',
		component: logisticsInfo,
	},
	{
		// 公告文章详情
		path: '/ArticleDetail',
		name: '文章详情',
		component: ArticleDetail,
	},

	{
		// 物流信息
		path: '/SingleProduct',
		name: '单品包邮',
		component: SingleProduct,

	},
	{
		// pc支付页面
		path: '/defray',
		name: '支付中',
		component: () => import("@/views/defray/defray"),
		meta: {
			isTop: true, // true顶部导航栏不显示
		}
	},
	{
		// pc支付成功
		path: '/defrayResult',
		name: '支付成功',
		component: () => import("@/views/defray/defrayResult"),
		meta: {
			isTop: true, // true顶部导航栏不显示
		}
	},
	{
		// pdf预览
		path: '/pdf',
		name: 'pdf预览',
		component: () => import("@/views/mine/pdf"),
		meta: {
			isTop: false, // true顶部导航栏不显示
		}
	},
	{
		//业务员绑定openid
		path: '/employeeBind',
		name: '绑定成功',
		component: employeeBind,
	},
	{
		path: '/reportForm',
		name: '报表',
		component: reportForm,
		meta: {
			isTop: true, // true顶部导航栏不显示
		},
	},
	{
		path: '/followOfficial',
		name: '关注公众号',
		component: followOfficial,
		meta: {
			isTop: true, // true顶部导航栏不显示
		},
	},
	{
		path: '/productRecommend',
		name: '新品推荐',
		component: productRecommend,
		meta: {
			keepAlive: true,
			isRefresh: false, //是否刷新
		}
	},
	{
		path: '/expertVideos',
		name: '公开讲堂',
		component: expertVideos,
		meta: {
			keepAlive: true,
			// isRefresh: false, //是否刷新
		}
	},
	{
		path: '/expertDetails',
		name: '公开讲堂',
		component: expertDetails,
		meta: {
			// keepAlive: true,
		}
	},
	{
		path: '/setting',
		name: '设置',
		component: setting,
		meta: {
			// keepAlive: true,
		}
	},
	{
		path: '/signInDetails',
		name: '签到统计',
		component: signInDetails,
		meta: {
			isTop: true, // true顶部导航栏不显示
		}
	},
	{
		path: '/signInGift',
		name: '签到礼品',
		component: signInGift,
		meta: {
			// isTop: true, // true顶部导航栏不显示
		}
		
	},
	{
		path: '/lotteryDraw',
		name: '抽奖活动',
		component: lotteryDraw,
		meta: {
			isTop: true, // true顶部导航栏不显示
		}
	},
	{
		path: '/CMworld',
		name: '中药天地',
		component: CMworld,
		meta: {
			// isTop: true, // true顶部导航栏不显示
		}
	},

]

const router = new VueRouter({
	mode: 'history',
	path: '/',
	routes
})

export default router
