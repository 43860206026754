import { render, staticRenderFns } from "./allProducts.vue?vue&type=template&id=d8e9b792&scoped=true"
import script from "./allProducts.vue?vue&type=script&lang=js"
export * from "./allProducts.vue?vue&type=script&lang=js"
import style0 from "./allProducts.vue?vue&type=style&index=0&id=d8e9b792&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8e9b792",
  null
  
)

export default component.exports