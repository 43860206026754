<template>
  <div class="invoiceOrder">
    <!-- 发票订单 -->
    <div class="filter">
      <img src="@/assets/images/icon_loudou.png" alt="" />
      <span @click="isFilter">订单筛选</span>
    </div>
    <!-- 商品列表 -->

    <div class="floor-box" v-if="billOrderList.length">
      <div class="floor-list" v-for="(item, ind) in billOrderList" :key="ind">
        <div class="floor-shop">
          <div class="icon_radio">
            <img
              src="@/assets/images/icon_radio.png"
              alt=""
              v-if="!item.select"
              @click.stop="selectCur(ind, item)"
            />
            <img
              src="@/assets/images/icon_Selected.png"
              alt=""
              v-else
              @click.stop="selectCur(ind, item)"
              style="z-index:9999;"
            />
          </div>
          <div class="floor-item">
            <div class="drugTop">
              <div>
                <span class="drug-title">
                  {{ item.orderInfo.ReceiveOrgName }}</span
                >
                <span class="drug-number">{{ item.orderInfo.OrderNo }}</span>
              </div>
              <span class="cancel">{{
                item.orderInfo.Status == 99 ? "已取消" : ""
              }}</span>
            </div>
            <div class="drugMiddle">
              <span class="drug-img">
                <div class="itemimg">
                  <div
                    class="imgs"
                    v-for="(item2, ind2) in item.orderDetails"
                    :key="ind2"
                  >
                    <img :src="item2.SpPic" alt="" />
                  </div>
                </div>
                <img
                  class="iconImg"
                  src="@/assets/images/icon_omit.png"
                  alt=""
                />
              </span>
              <span
                >共{{ item.orderDetails.length }}个品种{{ item.totals }}件
              </span>
            </div>
            <div class="drugBottom">
              <span>{{ item.orderInfo.CreateDate }}</span>
              <span class="font"
                >优惠：<i> {{ item.orderInfo.CouponAmount }}元</i> 付款金额：<i
                  >{{ item.orderInfo.OrderCost }}元</i
                ></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="noData" v-else>
      暂无数据~~
    </div>

    <!-- 底部订单 -->
    <div class="invoiceOrder-bottom">
      <span class="selectAll">
        <div class="icon_radio">
          <img
            src="@/assets/images/icon_allradio.png"
            alt=""
            v-if="!allselect"
            @click.stop="allselectCur()"
          />
          <img
            src="@/assets/images/icon_radio2.png"
            alt=""
            v-else
            @click.stop="allselectCur()"
            style="z-index:9999;"
          />
        </div>
        <em>全选</em>
      </span>
      <div class="dingdan">
        <p>
          共勾选<span>{{ selectlist.length }}</span
          >个订单，共计<span>{{ sumNum.toFixed(pointNum) }}元</span>
        </p>
        <button @click="nextbtn">下一步</button>
      </div>
    </div>

    <!-- 订单弹层 -->
    <van-popup
      v-model="isshowFilter"
      position="right"
      :style="{ height: '100%', width: '80%' }"
      @close="cancelbtn"
    >
      <div class="order">
        <div>
          <p>订单搜索</p>
          <div class="choose">
            <input
              type="text"
              value=""
              style="width:5.5rem"
              placeholder="请输入订单编号"
              v-model="filterList.OrderNo"
            />
          </div>
        </div>

        <div class="date">
          <p>订单日期</p>
          <div class="choose">
            <input
              type="text"
              placeholder="起始时间"
              @click="starTimebtn"
              :value="dateBegin"
              readonly
            />

            <i class="line"></i>
            <input
              type="text"
              placeholder="终止时间"
              @click="endTimebtn"
              :value="dateEnd"
              readonly
            />
          </div>
        </div>

        <div>
          <p>订单金额范围</p>
          <div class="choose">
            <input
              type="number"
              name=""
              id=""
              placeholder="最低价"
              v-model="filterList.CostBegin"
            />
            <i class="line"></i>
            <input
              type="number"
              placeholder="最高价"
              v-model="filterList.CostEnd"
            />
          </div>
        </div>

        <div>
          <p>订单类型</p>
          <div class="choose ">
            <span
              class="auditBtn"
              :class="{ checked: filterList.TypeLx == 1 }"
              @click="clockBtns(1)"
              >线上订单</span
            >
            <span
              class="auditBtn"
              :class="{ checked: filterList.TypeLx == 2 }"
              @click="clockBtns(2)"
              >线下订单</span
            >
          </div>
        </div>

        <div class="choose  processBtn">
          <span class="cancel" @click="cancelbtn">取消</span>
          <span @click="sureBtn()">确定</span>
        </div>
      </div>

      <!-- 时间选择 -->
      <div class="van-overlay" v-if="filterList.isStarTime">
        <div class="datetime">
          <van-datetime-picker
            v-if="filterList.isStarTime"
            v-model="filterList.SubmitDateB"
            type="datetime"
            title="选择完整时间"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="cancelDate1"
            @confirm="confirmDate1"
          />
        </div>
      </div>

      <div class="van-overlay" v-if="filterList.isEndTime">
        <div class="datetime">
          <van-datetime-picker
            v-if="filterList.isEndTime"
            v-model="filterList.SubmitDateE"
            type="datetime"
            title="选择完整时间"
            :min-date="minDate2"
            :max-date="maxDate2"
            @cancel="cancelDate2"
            @confirm="confirmDate2"
          />
        </div>
      </div>
    </van-popup>
    <!-- 时间选择 -->
    <div class="van-overlay" v-if="filterList.isStarTime">
      <div class="datetime">
        <van-datetime-picker
          v-if="filterList.isStarTime"
          v-model="filterList.SubmitDateB"
          type="date"
          title="选择时间"
          @cancel="cancelDate1"
          @confirm="confirmDate1"
          :min-date="minDate"
          :max-date="maxDate"
          :columns-order="['year', 'month', 'day']"
          :formatter="formatter1"
        />
      </div>
    </div>

    <div class="van-overlay" v-if="filterList.isEndTime">
      <div class="datetime">
        <van-datetime-picker
          v-if="filterList.isEndTime"
          v-model="filterList.SubmitDateE"
          type="date"
          title="选择时间"
          :min-date="minDate2"
          :max-date="maxDate2"
          @cancel="cancelDate2"
          @confirm="confirmDate2"
          :columns-order="['year', 'month', 'day']"
          :formatter="formatter1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Popup } from "vant";
import { Calendar } from "vant";
import { List } from "vant";

Vue.use(List);
Vue.use(Calendar);
Vue.use(Popup);
export default {
  data() {
    return {
      shopList: [],
      noMore: false,
      checkedAll: false,
      isshowFilter: false,
      billOrderList: [],
      allselect: false,
      selectlist: [],
      loading: false,
      finished: false,
      minDate: new Date(2000, 0, 1),
      minDate2: new Date(2000, 0, 1),
      maxDate: new Date(),
      maxDate2: new Date(),
      dateBegin: "",
      dateEnd: "",
 token : sessionStorage.getItem('member_token'),
      ids: [], // 选中ID的集合
      filterList: {
        isStarTime: false, //起始时间
        isEndTime: false,
        SubmitDateB: "",
        SubmitDateE: "",
        CostBegin: "",
        CostEnd: "",
        TypeLx: "", //发票类型
        page: 1,
        rows: 10,
        OrderNo: "", //订单编号
        pointNum:2,//小数点
      },
    };
  },
  beforeCreate() {
    // document.querySelector("body").setAttribute("style", "background:#f2f2f2");
  },
  created() {
    this.GetBillListOrder();
    var that=this;
    var shuju=JSON.parse(localStorage.getItem('webconfig'))
             if(shuju&&shuju.GoodPriceFixed){
               that.pointNum=shuju.GoodPriceFixed;
               }


    // this.onLoad();
  },
  mounted() {
    window.addEventListener("scroll", this.onReachBottom);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onReachBottom);
  },

  methods: {
    
    // 获取商品列表
    GetBillListOrder() {
      this.$toast.loading({
        message: "加载中...",
        duration: 0,
      });
      if(!this.token){
         this.$toast.clear();
         return false
      }

      this.noMore = true;
      this.public.request(
        "post",
        "/api/Bill/GetBillListOrderPage",

        this.filterList,
        (result) => {
          var that = this;
          that.$toast.clear();
          // console.log("数据", result);

          if (result.data.errorCode == "00") {
            var allArr = [];
            var initArr = that.billOrderList;
            var curArr = result.data.rows ? result.data.rows : [];
            var lastPageLength = curArr.length;
            if (that.filterList.page > 1) {
              allArr = initArr.concat(curArr);
            } else {
              allArr = result.data.rows;
            }

            // 自定义数据
            allArr.forEach((item) => {
              item.select = false;
              item.totals = 0;
            });

            allArr.forEach((item2) => {
              item2.orderDetails.forEach((item3) => {
                item2.totals += item3.Total;
              });
            });

            that.billOrderList = allArr;
            that.noMore = false;
            // that.billOrderList = allArr;
            if (lastPageLength < 10) {
              that.noMore = true;
              // that.showLoading = false;
            }
          } else {
            if (that.filterList.page <= 1) {
              that.billOrderList = [];
              that.noMore = false;
            } else {
              that.noMore = true;
            }
          }
        }
      );
    },

    // 选中单选
    selectCur(index, item) {
      // console.log("单选", item);
      var that = this;
      // 还未选中
      if (!that.billOrderList[index].select) {
        that.billOrderList[index].select = true;
        // console.log(that.billOrderList[index]);
        that.ids.push(that.billOrderList[index].orderInfo.OrderNo);
        that.selectlist.push(item.orderInfo.OrderCost);
        // console.log("selectlist", that.selectlist);
      } else {
        // 已经选中
        that.billOrderList[index].select = false;
        for (var i = 0; i < that.ids.length; i++) {
          if (that.ids[i] == that.billOrderList[index].orderInfo.OrderNo) {
            that.ids.splice(i, 1);
          }
        }
        for (var i = 0; i < that.selectlist.length; i++) {
          if (
            that.selectlist[i] == that.billOrderList[index].orderInfo.OrderCost
          ) {
            that.selectlist.splice(i, 1);
          }
        }
      }
      that.allselect = true;
      that.billOrderList.forEach((item, i) => {
        if (!item.select) {
          that.allselect = false;
        }
      });
    },
    // 全选
    allselectCur() {
      this.allselect = !this.allselect;
      var that = this;
      //全部不选中
      if (!that.allselect) {
        that.ids = [];
        that.selectlist = [];
        that.billOrderList.forEach((item, index) => {
          item.select = false;
        });
        return;
      }
      //全部选中
      var cartIds = [];
      var cartSelectlist = [];
      for (var i = 0; i < that.billOrderList.length; i++) {
        that.billOrderList[i].select = true;
        var id = that.billOrderList[i].orderInfo.OrderNo;
        var num = that.billOrderList[i].orderInfo.OrderCost;
        cartIds.concat(cartIds.push(id));
        cartSelectlist.concat(cartSelectlist.push(num));
      }
      that.ids = [];
      that.selectlist = [];
      that.ids = cartIds;
      that.selectlist = cartSelectlist;

      // console.log(that.ids);
    },

    // 分页
    //到达底部
    onReachBottom() {
      var that = this;
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      //       console.log('距顶部 =='+scrollTop);
      // console.log('可视区高度 =='+windowHeight);
      // console.log('滚动条总高度 =='+scrollHeight);
      if (scrollTop > 0) {
        if (scrollTop + windowHeight + 17 >= scrollHeight) {
          //写后台加载数据的函数
          // console.log("----------------");
          // console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
          // console.log(that.noMore);
          var page = that.filterList.page;
          var noMore = that.noMore;
          page++;
          that.filterList.page = page;
          if (noMore) {
            // console.log("已经到底了");
          } else {
            // console.log("加载更多");
            that.GetBillListOrder();
          }
        }
      }
    },
    // 筛选弹层
    isFilter() {
      this.isshowFilter = true;
    },
    // 下一步
    nextbtn() {
      if (this.selectlist.length == 0) {
        this.$toast({
          message: "请先选择订单",
        });
      } else {
        sessionStorage.setItem("OrderNo", JSON.stringify(this.ids));
        // localStorage.setItem('billId',JSON.stringify(this.ids))
        const OrderNum = this.selectlist.length;
        const OrderCost = this.sumNum.toFixed(this.pointNum);
        // console.log('价格',OrderNum, OrderCost);
        this.$router.push({
          path: "/writeInvoice",
          query: { OrderNum, OrderCost },
        });
      }
      // console.log(this.sumNum);
      // console.log(this.ids);
    },

    // 弹层
    // 审核状态
    clockBtns(num) {
      this.filterList.TypeLx = num;
    },
    // 发票类型
    activeStyleBtn(num) {
      this.filterList.BillType = num;
    },

    formatter1(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      return val;
    },
    // 起始时间
    starTimebtn() {
      // var subDate = new Date();
      // this.filterList.SubmitDateB = new Date(subDate.getFullYear(), 0, 1);
      this.filterList.SubmitDateB = new Date();
      this.filterList.isStarTime = true;
    },
    cancelDate1() {
      this.filterList.isStarTime = false;
      this.filterList.SubmitDateB = "";
      this.dateBegin = "";
    },
    confirmDate1(value) {
      let yy = new Date(value).getFullYear();
      let mm = new Date(value).getMonth() + 1;
      let dd = new Date(value).getDate();
      let hh = new Date(value).getHours();
      let mf = new Date(value).getMinutes();
      let ss = new Date(value).getSeconds();
      mm = mm >= 10 ? mm : "0" + mm;
      dd = dd >= 10 ? dd : "0" + dd;
      hh = hh >= 10 ? hh : "0" + hh;
      mf = mf >= 10 ? mf : "0" + mf;
      ss = ss >= 10 ? ss : "0" + ss;

      this.filterList.SubmitDateB =
        yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      this.dateBegin = yy + "年" + mm + "月" + dd + "日";
      this.filterList.isStarTime = false;
       mm=mm-1
      this.minDate2 = new Date(yy, mm, dd);
    },

    // 终止时间
    endTimebtn() {
      // var subDate = new Date();
      // this.filterList.SubmitDateE = new Date(subDate.getFullYear(), 0, 1);
      this.filterList.SubmitDateE = new Date();
      this.filterList.isEndTime = true;
    },
    cancelDate2() {
      this.filterList.isEndTime = false;
      this.filterList.SubmitDateE = "";
      this.dateEnd = "";
    },
    confirmDate2(value) {
      let yy = new Date(value).getFullYear();
      let mm = new Date(value).getMonth() + 1;
      let dd = new Date(value).getDate();
      let hh = new Date(value).getHours();
      let mf = new Date(value).getMinutes();
      let ss = new Date(value).getSeconds();
      mm = mm >= 10 ? mm : "0" + mm;
      dd = dd >= 10 ? dd : "0" + dd;
      hh = hh >= 10 ? hh : "0" + hh;
      mf = mf >= 10 ? mf : "0" + mf;
      ss = ss >= 10 ? ss : "0" + ss;

      this.filterList.SubmitDateE =
        yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      this.dateEnd = yy + "年" + mm + "月" + dd + "日";
      this.filterList.isEndTime = false;
      mm=mm-1
      this.maxDate = new Date(yy, mm, dd);
    },

    // starTimebtn() {
    //   this.filterList.isStarTime = true;
    // },
    // formatDate(date) {
    //   return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    // },
    // onStarTime(date) {
    //   this.filterList.isStarTime = false;
    //   var myDate = new Date();

    //   var h = myDate.getHours();
    //   var f = myDate.getMinutes();
    //   var s = myDate.getSeconds();
    //   var time = h + ":" + f + ":" + s;
    //   this.minDate2 = new Date(this.formatDate(date));
    //   this.filterList.SubmitDateB = this.formatDate(date) + " " + time;
    //   // this.filterList.SubmitDateB=JSON.stringify((this.filterList.SubmitDateB))
    // },
    // // 终止时间
    // endTimebtn() {
    //   this.filterList.isEndTime = true;
    // },
    // onEndTime(date) {
    //   this.filterList.isEndTime = false;
    //   var myDate = new Date();

    //   var h = myDate.getHours();
    //   var f = myDate.getMinutes();
    //   var s = myDate.getSeconds();
    //   var time = h + ":" + f + ":" + s;

    //   this.filterList.SubmitDateE = this.formatDate(date) + " " + time;
    // },
    // 取消
    cancelbtn() {
      this.isshowFilter = false;
      this.filterList.TypeLx = "";
      this.filterList.SubmitDateB = "";
      this.filterList.SubmitDateE = "";
      this.filterList.CostBegin = "";
      this.filterList.CostEnd = "";
      this.filterList.OrderNo = "";
      this.filterList.page = 1;
      this.dateBegin = "";
      this.dateEnd = "";
      this.minDate = new Date(2000, 0, 1);
      this.minDate2 = new Date(2000, 0, 1);
      this.maxDate = new Date();
      this.maxDate2 = new Date();
    },
    //  确定
    sureBtn() {
      if (this.filterList.CostBegin > this.filterList.CostEnd) {
        var cost = this.filterList.CostBegin;
        this.filterList.CostBegin = this.filterList.CostEnd;
        this.filterList.CostEnd = cost;
      }
      this.filterList.page = 1;
      this.GetBillListOrder();
      this.cancelbtn();
      document.documentElement.scrollTop = 0;
    },
  },
  watch: {},
  computed: {
    number() {
      this.billOrderList.forEach((item) => {
        var totals = 0;
        item.orderDetails.forEach((item2) => {
          totals += item2.Total;
        });
        return totals;
      });
    },
    sumNum() {
      var allsum = 0;
      this.selectlist.forEach((item) => {
        allsum += item;
      });
      return allsum;
    },
  },
};
</script>

<style scoped>
html {
  background-color: #f2f2f2 !important;
}
.invoiceOrder {
  font-size: 0.26rem;
  background: #f2f2f2;
}
.filter {
  height: 0.59rem;
  line-height: 0.59rem;
  overflow: hidden;
  background-color: white;
  position: fixed;
  top: 0.9rem;
  width: 100%;
}
.filter img {
  width: 0.3rem;
  height: 0.3rem;
  position: absolute;
  right: 1.3rem;
  top: 0.14rem;
}

.filter span {
  float: right;
  margin-right: 0.2rem;
}

/* 商品列表 */
.floor-box {
  margin-bottom: 1.3rem;
  margin-top: 0.6rem;
}
.noData {
  margin: 2rem 3rem;
}
.floor-shop {
  display: flex;
  align-items: center;
  margin-bottom: 0.1rem;
}
.floor-item {
  flex: 24;
  width: 100%;
  margin: 0.12rem;
  padding: 0.12rem 0.32rem;
  background-color: rgb(255, 254, 254);
  border-radius: 0.24rem;
}
.selectAll {
  display: flex;
}
.icon_radio {
  width: 0.4rem;
  margin-left: 0.2rem;
}
.selectAll em {
  font-style: normal !important;
}
.drug-img {
  display: flex;
}
.itemimg {
  display: flex;
  width: 1.8rem;
  overflow: hidden;
}
.drug-img .imgs img {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.1rem;
}
.drug-img .iconImg {
  margin-top: 0.5rem;
}
.drugTop,
.drugMiddle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.drugMiddle span img {
  display: inline;
  width: 0.3rem;
  height: 0.3rem;
  vertical-align: middle;
}

.drug-title,
.drug-number,
.cancel,
.font {
  font-weight: 700;
  font-size: 0.25rem;
}
.drug-title {
  margin-right: 0.14rem;

  color: #f3040a;
}
.drugMiddle {
  margin: 0.28rem 0;
}

.drugBottom {
  display: flex;
  justify-content: space-between;
}

i {
  color: #c84c4c;
  font-style: normal;
}

/* 订单底部 */
.invoiceOrder-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0.12rem 0.5rem;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  z-index: 22;
}
p {
  margin: 0;
}
button {
  width: 2rem;
  height: 0.62rem;
  background-color: var(--theme-color);
  color: white;
  border-radius: 0.15rem;
  right: 0.3rem;
}
.dingdan {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.filter {
  background: white;
}

/* 弹层 */

.order {
  margin: 0.6rem 0.5rem 0 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.choose {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0.2rem 0 0.6rem 0;
}
.choose span {
  width: 2.6rem;
  line-height: 0.7rem;
  text-align: center;
  margin: 0 0.2rem 0.2rem 0;
  color: white;
  background-color: #c9c9c9;
}
.order input {
  width: 2.5rem;
  height: 0.5rem;
  border: 0.01rem solid black;
}
.line {
  display: inline-block;
  width: 0.3rem;
  height: 0.01rem;
  background-color: black;
  margin: 0.1rem;
}
.style {
  justify-content: flex-start !important;
  flex-wrap: wrap;
}
.checked {
  background-color: #169bd5 !important;
}
.processBtn {
  position: absolute;
  bottom: 0.2rem;
}
.processBtn span {
  line-height: 0.7rem;
  margin-top: 1rem;
  border-radius: 0.1rem;
  background-color: #169bd5 !important;
}
.van-overlay {
  z-index: 9999;
}
.van-picker {
  top: 8rem;
}
</style>
