<template>
  <div class="container">
    <!-- 商品列表页面 :style="{marginTop:marTop+'rem'}" -->
    <div class="goods-cont" v-if="!historyShow">
      <div class="fixed-top" :style="{top:topHeight+'rem'}">
        <!-- 顶部标题 -->
        <!-- <div class="tab-top flex-wrap" >
            <img  class="tab-img" src="@/assets/images/tab/icon_left.png" alt="">
            <div class="tab-tit">搜索</div>
        </div> -->
        <!-- 搜索框 -->
        <div class="input-box flex-wrap">
            <!-- <img class="scan-code" @click="toScanPage" src="@/assets/images/index/RichScan.png" alt="图片"> -->
            <!-- <a v-if="scanUrl" :href="scanUrl" target="_blank">
                <img class="scan-code" src="@/assets/images/index/RichScan.png" alt="图片">
            </a> -->
            <img class="back-img" src="@/assets/images/tab/icon_left.png" alt="" @click="backPage">
            <div class="search-input flex-wrap">
                <input type="text" placeholder="请输入商品" v-model="keyword" @click="onHistory()">
                <img @click="onSearch" src="@/assets/images/index/main-sousuo.png" alt="搜索">
            </div>
        </div>
        <!-- 筛选导航 -->
        <div class="screen-nav flex-sp">
            <!-- <span class="active" @click="onNavShow">{{navName}}</span> -->
            <div class="sort flex-wrap" @click="onNavShow">
                <span class="active">{{navName}}</span>
                <img v-if="!navShow" src="@/assets/images/search/icon_down.png" alt="">
                <img v-if="navShow" src="@/assets/images/search/icon_up.png" alt="">
            </div>
            <div class="nav-type" v-if="navShow">
                <span @click="changeNav(-1)">默认</span>
                <!-- <span @click="changeNav(2)">现货</span>
                <span @click="changeNav(-2)">期货</span> -->
                <span @click="changeNav(0)">零货</span>
                <span @click="changeNav(1)">整件</span>
            </div>

			<div class="sort flex-wrap" @click="handleSort('Price')">
				<span>售价</span>
				<img
				  class="price-img"
				  v-if="sortField=='sorttotal'||sortType==''"
				  src="@/assets/images/icon_sort.png"
				  alt=""
				/>
				<img
				  class="price-img"
				  v-if="sortField=='Price'&&sortType=='asc'"
				  src="@/assets/images/icon_downTrigonum.png"
				  alt=""
				/>
				<img
				  class="price-img"
				  v-if="sortField=='Price'&&sortType=='desc'"
				  src="@/assets/images/icon_upTrigonum.png"
				  alt=""
				/>
			</div>
		  
			<!-- <div class="sort flex-wrap" @click="changeClass">
				<span>分类</span>
				<img
				  v-if="!sortShow"
				  src="@/assets/images/index/icon_down.png"
				  alt=""
				/>
				<img
				  v-if="sortShow"
				  src="@/assets/images/index/icon_up.png"
				  alt=""
				/>
			</div> -->
			
			<!-- <div></div> -->
			<div class="sort flex-wrap" @click="handleSort('sorttotal')">
				<span>销量</span>
				<img
				  class="price-img"
				  v-if="sortField=='Price'||sortType==''"
				  src="@/assets/images/icon_sort.png"
				/>
				<img
				  class="price-img"
				  v-if="sortField=='sorttotal'&&sortType=='asc'"
				  src="@/assets/images/icon_downTrigonum.png"
				/>
				<img
				  class="price-img"
				  v-if="sortField=='sorttotal'&&sortType=='desc'"
				  src="@/assets/images/icon_upTrigonum.png"
				/>
			</div>
        </div>
      </div>

      <!-- 商品列表 -->
      <shopList class="shopLists" :list="goodList" @toDetail='toGoodsDetail' @cartShow='onCartShow' @onCartShow='onCartShow1' @showSupplyDialog='showSupplyDialog'></shopList>
      

      <div class="loading-box flex-center" v-if="noMore && goodList.length > 5">
        <span class="load-txt">已经到底了！</span>
      </div>
      <!-- 加载样式 -->
      <div
        class="loading-box flex-center"
        v-if="showLoading && goodList.length > 0"
      >
        <img class="load-img" src="@/assets/images/index/loading.gif" alt="" />
        <span class="load-txt">正在加载</span>
      </div>
      <!-- 暂无数据 -->
      <div class="empty-box" v-if="goodList.length == 0">
        <div class="no-data">
          <img src="@/assets/images/cart/noActiveDrug.png" alt="" />
          <span>暂无数据</span>
        </div>
      </div>
    </div>

    <!-- 分类弹窗 -->
    <div class="sort-pop" v-if="sortShow" @click="closeClass()"></div>
    <div class="sort-cont flex-wrap1" v-if="sortShow">
      <div class="sort-left">
        <div
          class="left-item"
          v-for="(item, index) in typeList"
          :key="index"
          :class="typeIndex == index ? 'active' : ''"
          @click="changtype(index)"
        >
          {{ item.text }}
        </div>
      </div>
      <div class="sort-right">
        <div class="sort-top">
          <div class="sort-type flex-wrap">
            <div
              class="right-item"
              v-for="(item1, i) in typeList[typeIndex].children"
              :key="i"
              @click="changeTab(item1.code)"
              :class="TypeId == item1.code ? 'active' : ''"
            >
              {{ item1.text }}
            </div>
          </div>
        </div>

        <div class="cancel" @click="cancelType()">取消</div>
      </div>
    </div>

    <!-- 筛选弹窗 -->
    <div class="screen-popup" v-if="screenShow"></div>
    <div class="screen-cont" v-if="screenShow">
      <div class="screen-top">筛选</div>
      <div class="screen-box">
        <div class="screen-item">
          <div class="item-tit flex-sp">
            <span>品牌</span>
            <img src="@/assets/images/index/icon_down.png" alt="更多图标" />
          </div>
          <div class="label-list flex-wrap1">
            <div class="label-item">华润三九</div>
            <div class="label-item">扬子江</div>
            <div class="label-item active">华润三九</div>
            <div class="label-item">扬子江</div>
          </div>
        </div>
        <div class="screen-item">
          <div class="item-tit flex-sp">
            <span>标签</span>
            <img src="@/assets/images/index/icon_down.png" alt="更多图标" />
          </div>
          <div class="label-list flex-wrap1">
            <div class="label-item">促销</div>
          </div>
        </div>
        <div class="screen-item">
          <div class="item-tit flex-sp">
            <span>库存</span>
          </div>
          <div class="label-list flex-wrap1">
            <div class="label-item">只看有货</div>
          </div>
        </div>
        <div class="screen-item">
          <div class="item-tit flex-sp">
            <span>中包装拆销</span>
          </div>
          <div class="label-list flex-wrap1">
            <div class="label-item">整件</div>
            <div class="label-item">拆零</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 商品购物车弹窗 -->
    <cart-pop :cartShowPop="cartShowPop" :goodInfo="goodInfo" @diffCartShow="closeCart" ></cart-pop>
    <!-- 活动商品购物车弹窗 -->
    <active-cart
      :cartShowPop="activeCartShowPop" :goodSpid="goodSpid" :goodDetail="goodDetail" :typePage="typePage" @diffCartShow="closeCart1"></active-cart>
    <search-history :historyShow="historyShow" :backShow="backShow" @onHistoryShow="onHistoryShow"></search-history>
    <!-- 多规格弹窗 -->
    <specification  :isSpecification='isSpecification'  @isGuiGeShow="closeGuiGe" :guiGeDetail="guiGeDetail" :guiGeDes='guiGeDes' :bottomHight='bottomHight'/>
		
		
	<!-- 缺货登记弹窗 -->
	<div class="register-popup" v-if="showSupplyStock"></div>
	<div class="register-cont" v-if="showSupplyStock">
	  <div class="regis-tit">缺货登记</div>
	  <div class="regis-item flex-wrap">
	    <span class="item-tit">商品名称：</span>
	    <div class="input-box"><input type="text" disabled v-model="supplyInfo.spmch" /></div>
	  </div>
	  <div class="regis-item flex-wrap">
	    <span class="item-tit">商品规格：</span>
	    <div class="input-box"><input type="text" disabled v-model="supplyInfo.shpgg" /></div>
	  </div>
	  <div class="regis-item flex-wrap">
	    <span class="item-tit">生产厂商：</span>
	    <div class="input-box">
	      <input type="text" disabled v-model="supplyInfo.shengccj" />
	    </div>
	  </div>
	  <div class="regis-item flex-wrap">
	    <span class="item-tit">需求数量：</span>
	    <div class="input-box">
	      <input type="number" v-model="supplyNum" placeholder="请输入需求数量"/>
	    </div>
	  </div>
	  <div class="regis-item flex-wrap">
	    <span class="item-tit">最长等待时间：</span>
	    <div class="input-box flex-wrap">
	      <input type="number" v-model="waitDay" placeholder="请输入等待天数" />
	      <span class="input-tit">天</span>
	    </div>
	  </div>
	  <div class="regis-but flex-wrap1">
	    <div class="but-around grey" @click="showSupplyStock=false">取消</div>
	    <div class="but-around blue" @click="onSupplyStock">确定</div>
	  </div>
	</div>
		
</div>

</template>

<script>
import shopList from "../../components/shopList.vue"
import cartPop from "../../components/cartPop.vue";
import searchHistory from "../../components/searchHistory.vue";
// 引入活动弹窗组件
import activeCart from "../../components/activeCartPop.vue";
// 引入跳转活动详情的js
import jumpActivity from "../../assets/js/toActivityPage";
import specification from '../../components/specification.vue';
import registerRequest from "@/assets/js/api/register.js";
export default {
  components: {
    cartPop,
    searchHistory,
    activeCart,specification,shopList
  },
  data() {
    return {
      type: "", // 接收不同页面传过来的类型
      page: 1,
      showLoading: true, // 加载样式
      noMore: false, // 暂无数据，已经到达底部
      sortShow: false, // 分类弹窗
      screenShow: false, // 筛选弹窗
      navShow: false, // 默认、零货、整件隐藏
      navName: "默认", // 默认、零货、整件
      goodList: [], // 全部商品列表
      sortField: "", // 排序字段: Price售价, sorttotal销量
      sortType: "", // 售价排序方式 asc价格从低到高，desc价格从高到低
      keyword: "", // 搜索内容
      TypeId: "", // 选择分类的id
      OnlyPackageSold: "", // 默认为空，0零货， 1整件
      tag: "", // 从首页进入的type为10的类型，传递的tag
      typeList: [], // 分类数据
      typeIndex: "0",

      cartShowPop: false, // 组件显示隐藏
      goodInfo: {}, // 向组件传递商品详情

      historyShow: false, // 历史搜索
      isBack: true,

      scanUrl: "", // 微信扫码路径

      executeOne: true, // 进入页面只执行一次

      activeCartShowPop: false, // 组件显示隐藏
      goodSpid: "", // 向组件传递的spid
      goodDetail: {}, // 向组件传递商品详情
      typePage: "", // 向组件传递页面类型
      token: sessionStorage.getItem('member_token'),

      backShow: true,       // 历史组件
      GoodsSource:'',//期货现货判断
      isGoodsSource:false,
      errorImg:require('@/assets/images/loseImg.png'),
      isSpecification:false,//规格
      guiGeDetail:{},//规格详情
      guiGeDes:{},//规格
      bottomHight:true,
      allgoodList:[],
      topHeight: 0,   // 顶部距离
      marTop: 1.9,  // 外边距
	  
	  keyId: "" ,//埋点 主键
	  apiResult: null,
	  
	  showSupplyStock: false, //缺货登记弹窗  false隐藏  true显示
	  supplyInfo: null,
	  supplyNum: "", // 缺货登记需求数量
	  waitDay: "", // 缺货登记等待天数
    };
  },
  created() {
    var that = this;
    var headTit = that.$route.query.headTit;
    that.$store.commit("setHeadTitle", headTit);
    that.tag = that.$route.query.tag;

    that.keyword = that.$route.query.keyword;
    that.getAllGoods(); // 列表
    that.getType(); // 分类

  },
  beforeRouteEnter(to, from, next) {  
    if (from.path == "/goodsDetail" || from.path == "/activeDetail") {
      // 这个name是下一级页面的路由path

      to.meta.isRefresh = true; // 设置为true说明是返回到这个页面，而不是通过跳转从其他页面进入到这个页面
    } else if (from.path != "/goodsDetail") {
      to.meta.isRefresh = false;
    }
    next();
  },
  mounted() {
    var that = this;
    // that.isBack = that.$route.meta.isBack;
    // // console.log(that.isBack);
    // if (window.history && window.history.pushState && !that.isBack) {
    //   history.pushState(null, null, document.URL);
    //   window.addEventListener("popstate", that.popstateBack, true);
    // }

    window.addEventListener("scroll", that.onReachBottom);
  },
  destroyed() {
    this.$store.commit("setHeadTitle", "");
    window.removeEventListener("scroll", this.onReachBottom);
    // window.removeEventListener("popstate", this.popstateBack, true);
  },

  activated() {
    var that = this;
	//统计分析 埋点进来
	this.keyId = ""; //每次进入置空
	this.public.setBuryPoint(3,1,this.keyId).then(res=>{
		if(res.errorCode=="00"){
			that.keyId = res.rows;
		}
	});
    switch (localStorage.clientType) {
      case "app":
        that.topHeight = 0.8;
        that.marTop = 3;
        break;
		case "ios":
		  that.topHeight = 0.8;
		  that.marTop = 3;
		  break;
      case "applets":
        that.topHeight = 0;
        that.marTop = 1.9;
        break;
      case "weixin":
        that.topHeight = 0;
        that.marTop = 1.9;
        break;
      default:
        that.topHeight = 0;
        that.marTop = 1.9;
		break;
    }

    that.token=sessionStorage.getItem('member_token');
    that.keyword = that.$route.query.keyword;
    if (!that.$route.meta.isRefresh) {
      that.page = 1;
      that.noMore = false;
      that.getAllGoods(); // 列表
      that.getType(); // 分类
    }

    that.historyShow = false;

    //微信扫码
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      var url = location.href;
      var ishttps = "https:" == document.location.protocol ? true : false;
      if (ishttps) {
        // console.log('https');
        that.scanUrl = "http://sao315.com/w/api/saoyisao/?redirect_uri=" + url;
      } else {
        // console.log('http');
        that.scanUrl = "http://sao315.com/w/api/saoyisao?redirect_url=" + url;
      }

      var code = that.$route.query.qrresult;
      if (code) {
        that.onWxScan();
      }
    }

    // 获取扫码结果
    var code = that.$route.query.code;
    if (code) {
      var index = code.lastIndexOf("\,");
      code = code.substring(index + 1, code.length);
      that.page = 1;
      that.noMore = false;
      that.keyword = code;
      that.getAllGoods(); // 列表
      that.getType(); // 分类
    }

    // that.isBack = that.$route.meta.isBack;
    // // console.log(that.isBack);
    // if (window.history && window.history.pushState && !that.isBack) {
    //   history.pushState(null, null, document.URL);
    //   window.addEventListener("popstate", that.popstateBack, true);
    // }
    
    window.addEventListener("scroll", that.onReachBottom);
  },
  deactivated() {
	  //埋点离开
	  this.public.setBuryPoint(3,2,this.keyId,this.apiResult).then(res=>{
		if(res.errorCode=="00"){}
	  });
	  
    this.$store.commit("setHeadTitle", "");
    this.$store.commit("sethistoryShow", false);

    window.removeEventListener("scroll", this.onReachBottom);
    // window.removeEventListener("popstate", this.popstateBack, true);
  },

  methods: {
    backPage(){
        this.$router.replace({
          path: '/searchHistory'
        });
    },
	//缺货登记补充弹窗
	showSupplyDialog(goodInfo){
		this.supplyInfo = goodInfo;
		this.showSupplyStock = true;
	},
	// 缺货登记弹窗确定
	onSupplyStock() {
	  var that = this;
	  if (!that.supplyNum) {
	    that.$toast("请输入需求数量");
	    return;
	  }
	  if (!that.waitDay) {
	    that.$toast("请输入等待天数");
	    return;
	  }
	  var reg = /(^[1-9]\d*$)/;
	  if (!reg.test(that.supplyNum)) {
	    that.$toast("请输入大于0的整数");
	    return;
	  }
	  if (!reg.test(that.waitDay)) {
	    that.$toast("请输入大于0的整数");
	    return;
	  }
	
	  registerRequest.saveGoodsStockOutByDay({
	    spid: that.supplyInfo.spid,
	    goodsCount: that.supplyNum,
	    waitDays: that.waitDay,
		ReceiveId: localStorage.getItem('orgId')
	  }, (res) => {
	    if (res.data.errorCode == "00") {
	      that.$toast("登记成功");
	      that.showSupplyStock = false;
	    } else {
	      that.$toast(res.data.errorMsg);
	    }
	  });
	},
    // 扫码获得的参数
    onWxScan() {
      var that = this;
      var code = this.$route.query.qrresult;
      var index = code.indexOf(",");

      if (index < 0) {
        that.$toast("请描扫条形码");
        return false;
      }

      window.history.pushState(null, null, document.URL);
      that.$router.replace({
        path: "/allProducts",
        query: {
          headTit: "全部商品",
          code: code,
        },
      });
    },

    // 活动商品弹窗显示
    onCartShow1(id,url,item) {
      //  console.log('item',item);
      var that = this; // 查看是否登录

      if (that.token == null) {
        var orgId = localStorage.getItem("orgId");
        that.$dialog
          .confirm({
            message: "你还未登录，是否登录!",
          })
          .then(() => {
            that.$router.push({
              path: "/login",
              query: {
                orgId: orgId,
                auto: 0,
              },
            });
          })
          .catch(() => {
            // console.log('取消');
          });
        return false;
      }

      that.activeCartShowPop = true;
      if(item.CurActivityData.ActivityType == 0){ // 团购
        that.typePage = "group";
      }else if(item.CurActivityData.ActivityType == 1){ // 秒杀
        that.typePage = "seckill";
      }else if(item.CurActivityData.ActivityType == 2){  // 今日特价
        that.typePage = "bargain";
      }else if(item.CurActivityData.ActivityType == 3){  // 集采
        that.typePage = "purch";
      }
      that.goodDetail = item;
      that.goodSpid = item.spid;
      

    },
    // 商品弹窗隐藏
    closeCart1() {
      var that = this;
      that.activeCartShowPop = false;
    },

    // 历史搜索记录组件
    onHistoryShow(keyword) {
      var that = this;
      that.keyword = keyword;
      that.historyShow = false;
      that.$store.commit("sethistoryShow", false);
      that.page = 1;
      that.getAllGoods(); // 列表
      that.getType(); // 分类
    },
     //  关闭规格弹窗
        closeGuiGe() {
        var that = this;
        that.isSpecification = false;
        },

    // 购物车弹窗组件
    onCartShow(spid) {
      var that = this; // 查看是否登录

      if (that.token == null) {
        var orgId = localStorage.getItem("orgId");
        that.$dialog
          .confirm({
            message: "你还未登录，是否登录!",
          })
          .then(() => {
            that.$router.push({
              path: "/login",
              query: {
                orgId: orgId,
                auto: 0,
              },
            });
          })
          .catch(() => {
            // console.log('取消');
          });
        return false;
      }

    
      var goodList = that.goodList;
      goodList.forEach((item, i) => {
        if (spid == item.spid) {
          that.goodInfo = item;
        }
      });
         if((JSON.stringify(that.goodInfo.StyleGroup)!='{}'&&that.goodInfo.StyleGroup!=null)){
                 that.isSpecification=true;
             var guiges=that.goodInfo.StyleGroup;
             Object.keys(guiges).forEach(key=>{
                if( guiges[key].ActivityCacheDataList.length>0){
                    let item1='ActivityId';
                    let value1=guiges[key].ActivityCacheDataList[0].ActivityId;
                    guiges[key][item1]=value1;
                    let item2='Description';
                    let value2=guiges[key].ActivityCacheDataList[0].Description;
                    guiges[key][item2]=value2;
                    let item3='ActivityType';
                    let value3=guiges[key].ActivityCacheDataList[0].ActivityType;
                    guiges[key][item3]=value3;
                }
                let item='num';
                let value=0;
            guiges[key][item]=value;
            });
            that.guiGeDes=guiges;
            console.log(that.guiGeDes);
            that.guiGeDetail = that.goodInfo;
           }else{
               console.log(22);
               that.cartShowPop = true;
            that.goodInfo = that.goodInfo;
           }



    },
    // 关闭组件弹窗
    closeCart() {
      var that = this;
      that.cartShowPop = false;
    },

    // 显示历史搜索页面
    onHistory() {
      var that = this;
      // that.historyShow = true;
      
      this.noMore= false;
      that.$router.replace({
        path: '/searchHistory',
      })
    },

    // 扫码页面
    toScanPage() {
      this.$router.replace({
        path: "/scan",
      });
    },
    // 默认、零货、整件显示
    onNavShow() {
      var that = this;
      that.navShow = !that.navShow;
      that.sortShow = false; // 分类隐藏
    },
    // 选择默认、零货、整件
    changeNav(num) {
      var that = this;
      // console.log(num);
       that.isGoodsSource=false;
      if (num == -1) {
        // 默认
        that.OnlyPackageSold = "";
        that.navName = "默认";
      } else if (num == 0) {
        that.OnlyPackageSold = 0;
        that.navName = "零货";
        // console.log(that.navName);
      } else if (num == 1) {
        that.OnlyPackageSold = 1;
        that.navName = "整件";
      }else if (num == 2) {
        that.GoodsSource = '';
        that.isGoodsSource=true;
        that.navName = "现货";
      }else if (num == -2) {
        that.GoodsSource =20;
        that.isGoodsSource=true;
        that.navName = "期货";
      }
      that.page = 1;

      that.navShow = false;
      that.getAllGoods();
    },
    // 价格、销量,升降序
    handleSort(fieldType) {
      var that = this;
      if (that.sortType == "asc") {
        // 如果价格从低到高，则降序,否则升序
        that.sortType = "desc";
      } else {
        that.sortType = "asc";
      }
      that.navShow = false; // 默认隐藏
      that.sortShow = false; // 分类隐藏
	  that.sortField = fieldType;
      that.page = 1;
      that.getAllGoods();
    },
	
    // 获取搜索内容
    onBoard() {
      var that = this;
      that.page = 1;
      that.getAllGoods();
    },
    onSearch() {
      var that = this;
      this.noMore= false;
      that.page = 1;
      that.getAllGoods();
    },
    // 顶部分类显示隐藏
    changeClass() {
      var that = this;
      that.sortShow = !that.sortShow;
      that.navShow = false; // 默认隐藏
    },
    // 取消选中的分类，搜索全部
    cancelType() {
      var that = this;
      that.sortShow = false; // 分类隐藏
      that.navShow = false; // 默认隐藏
      that.TypeId = "";
      that.getAllGoods();
    },

    //  跳转详情
    toGoodsDetail(item) {
      var that = this;
      if(item.CurActivityData == null){ // 普通商品
        that.$router.push({
          path: "/goodsDetail",
          query: {
            spid: item.spid,
          },
        });
      }else if(item.CurActivityData != null){  // 活动商品
        jumpActivity.jumpPage(item);
      }

    },

    // 关闭分类弹窗
    closeClass() {
      this.sortShow = false;
    },

    // 选中分类标签
    changeTab(code) {
      var that = this;
      that.TypeId = code;
      that.page=1

      that.sortShow = false;
      that.getAllGoods();
    },

    // 切换药品分类
    changtype(index) {
      var that = this;
      that.typeIndex = index;
    },

    // 分类
    getType() {
		return;
      var that = this;
      var orgId = localStorage.getItem("orgId");
      // console.log(orgId);
      var data = {
        OrgId: orgId,
      };
      that.public.request(
        "post",
        "/api/OrgGoodsType/QueryOrgGoodsTypeAll",
        data,
        (res) => {
          // console.log(res);
          if (res.data.errorCode == "00") {
            that.typeList = res.data.rows;
          } else if (res.data.errorCode == "99") {
            that.$toast(res.data.errorMsg);
          }
        }
      );
    },

   

    // 全部商品
    getAllGoods() {
      var that = this;
      var page = that.page;
      var sortField = that.sortField;
      var sortType = that.sortType;
      var keyword = that.keyword;
      var TypeId = that.TypeId;
      var OnlyPackageSold = that.OnlyPackageSold;
      var tag = that.tag;
       that.allgoodList=[];
      var GoodsSource=that.GoodsSource;
       var data={};
      if(!that.isGoodsSource){
          data = {
          page: page,
          rows: 10,
          SortField: sortField,
          SortType: sortType,
          keyword: keyword,
          TypeId: TypeId,
          OnlyPackageSold: OnlyPackageSold,
          tag: tag,
        };
      }else{
          data = {
          page: page,
          rows: 10,
          SortField: sortField,
          SortType: sortType,
          keyword: keyword,
          TypeId: TypeId,
          GoodsSource: GoodsSource,
          tag: tag,
        };

      }
      // console.log(data);
      that.$toast.loading({
        message: "加载中",
        duration: 0,
      });

      var IsSpecial = sessionStorage.getItem("IsSpecial")
      if(IsSpecial){
        var url = '/api/SCustomerGoods/QueryPage';
      }else{
        var url = '/api/Store/QueryStoreGoods';
      }

      that.public.request("post", url, data, (res) => {
        // console.log(res);
        if (res.data.errorCode == "00") {
          that.$toast.clear();
          var allArr = [];

          var initArr = that.goodList;
          
          var curArr = res.data.rows ? res.data.rows : [];
          var lastPageLength = curArr.length;
          if (page > 1) {
            allArr = initArr.concat(curArr);
          } else {
            allArr = res.data.rows;
          }
          // console.log("---------initArr",allArr);
          allArr.forEach((item, index) => {
            // item.activityPrice = null;
            if(item.ActivityCacheDataList != null){
              item.ActivityCacheDataList.forEach((item1, i) => {
                if (item1.ActivityType === 1) {   // 秒杀
                  // 天时分秒  倒计时
                  that.public.countdown(item1.EndTime, (res) => {
                    item1.countTime = res;
                  });
                }
                // console.log(item1.ActivityType);
                // 如果有活动商品，则按活动价格显示
                // 0团购 1秒杀 2今日特价 3集采
                // if (
                //   item1.ActivityType == 0 ||
                //   item1.ActivityType == 1 ||
                //   item1.ActivityType == 2 ||
                //   item1.ActivityType == 3
                // ) {
                //   item.activityType = item.ActivityCacheDataList[0].ActivityType;
                //   item.activityPrice = item.ActivityCacheDataList[0].MinAmount;
                //   item.activityId = item.ActivityCacheDataList[0].ActivityId;
                // } else {
                //   item.activityId = item.ActivityCacheDataList[0].ActivityId;
                // }
              });
            }
            if(item.ActivityCacheDataList == null){
              item.ActivityCacheDataList = [];
            }
            
          });

          // console.log(allArr);
          // 如果搜索内容有数据，则把搜索内容存放到store里面
          if (allArr.length > 0 && keyword) {
            var arr = that.$store.state.searchHistory;
            arr.unshift(keyword);
            arr = Array.from(new Set(arr));
            that.$store.commit("setHistory", arr);
          }

          that.goodList = allArr;
          if (lastPageLength < 10) {
            that.noMore = true;
            that.showLoading = false;
          }
        } else if (res.data.errorCode == "99") {
          that.$toast.clear();
          if (page <= 1) {
            that.goodList = [];
            that.noMore = false;
            that.showLoading = false;
          } else {
            that.noMore = true;
            that.showLoading = false;
          }
        }
        // console.log('goodList',that.goodList);  
         that.goodList.forEach(item=>{
                var list=[];
                item.ActivityCacheDataList.forEach(item1=>{
                    list.push(item1.ActivityTypeName);
                    
                });
                // console.log('修改',item);
                const set = new Set(list);
                item.activeList=set;
            });
            //  console.log('最终',that.goodList);  
          that.allgoodList=that.goodList;  
		   
		   that.apiResult = res.data;
		   that.apiResult.rows = null;
      });
       
    },


    //到达底部
    onReachBottom() {
      var that = this;
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      // console.log(scrollTop);
      if (scrollTop > 0) {
        if (scrollTop + windowHeight + 5 >= scrollHeight) {
          //写后台加载数据的函数
          // console.log("----------------");
          // console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
          if (that.goodList.length > 0) {
            var page = that.page;
            var noMore = that.noMore;
            page++;
            that.page = page;
            if (noMore) {
              // console.log('已经到底了');
            } else {
              // console.log("加载");
              that.getAllGoods();
            }
          }
        }
      }
    },
    // 监听返回
    // popstateBack() {
    //   var that = this;
    //   var isBack = that.isBack;
    //   // console.log("-------1.0",isBack);
    //   if (!isBack) {
    //     var userAgent = navigator.userAgent;
    //     var isAndroid =
    //       userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1; //android终端
    //     var isIos =
    //       (userAgent.indexOf("iphone") > -1 ||
    //         userAgent.indexOf("ipad") > -1 ||
    //         userAgent.indexOf("ipod") > -1) &&
    //       (platform.indexOf("iphone") > -1 ||
    //         platform.indexOf("ipad") > -1 ||
    //         platform.indexOf("ipod") > -1);

    //     if (window.WeixinJSBridge) {
    //       window.WeixinJSBridge.call("closeWindow"); // 微信
    //     } else if (window.AlipayJSBridge) {
    //       window.AlipayJSBridge.call("closeWebview"); // 支付宝
    //     } else if (isAndroid) {
    //       window.obj.closePageLppzRequest(""); // 安卓app
    //     } else if (isIos) {
    //       window.webkit.messageHandlers.closePageLppzRequest.postMessage(""); //ios app
    //     } else {
    //       location.replace("about:blank", "_top");
    //     }
    //   }
    // },
  },
};
</script>

<style scoped>
/* 顶部导航标题 */
.tab-top {
  width: 100%;
  height: 0.9rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
  background: #fff;
}

.tab-top .tab-img {
  width: 0.4rem;
  height: 0.4rem;
}

.tab-top .tab-tit {
  flex: 1;
  text-align: center;
  font-size: 0.32rem;
  /* padding-right:0.5rem; */
}
/* 顶部固定 */
.fixed-top {
  position: fixed;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 10;
}

/* 搜索框 */
.input-box {
  width: 100%;
  padding: 0.16rem 0.3rem;
  box-sizing: border-box;
  border-bottom: 0.02rem solid #eee;
  /* margin-top:0.9rem; */
}
.input-box .back-img{
    width:0.42rem;
    margin-right:0.2rem;
}
.input-box .scan-code {
  width: 0.46rem;
  height: 0.46rem;
  margin-right: 0.2rem;
}
.input-box .search-input {
  flex: 1;
  border: 0.02rem solid #eee;
  border-radius: 0.2rem;
  padding: 0.12rem 0.2rem;
  box-sizing: border-box;
}
.input-box .search-input input {
  flex: 1;
  font-size: 0.28rem;
}
.input-box .search-input img {
  width: 0.36rem;
  height: 0.36rem;
  margin-left: 0.2rem;
}

.font-blue {
  color: #007aff;
}

/* 商品列表页面 */
.goods-cont {
  width: 100%;
  margin-top: 1.9rem;
}
.goods-cont .screen-nav {
  width: 100%;
  height: 0.9rem;
  padding: 0 0.3rem;
  box-sizing: border-box;
  border-bottom: 0.02rem solid #eee;
  position: relative;
}
.goods-cont .screen-nav span {
  font-size: 0.32rem;
  color: #333;
}
.goods-cont .screen-nav span.active {
  color: #2f2fff;
}
.goods-cont .screen-nav .sort img.price-img {
  width: 0.38rem;
  margin-left: 0;
}
.goods-cont .screen-nav .sort img {
  width: 0.28rem;
  height: 0.28rem;
  margin-left: 0.1rem;
}
.goods-cont .screen-nav .nav-type {
  position: absolute;
  left: 0.2rem;
  top: 0.7rem;
  border: 0.02rem solid #eee;
  padding: 0.1rem 0.2rem;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 0 0.2rem 0.03rem #eee;
  border-radius: 0.2rem;
  z-index: 9;
}
.goods-cont .screen-nav .nav-type span {
  display: block;
  font-size: 0.3rem;
  color: #666;
  margin-bottom: 0.1rem;
}

.goods-cont .floor-list {
  width: 100%;
}
.floor-list .floor-item {
  width: 100%;
  padding: 0.2rem 0.3rem;
  box-sizing: border-box;
  border-bottom: 0.02rem solid #eee;
}
.floor-list .floor-item .floor-good {
  width: 2.2rem;
  max-height: 2.5rem;
  margin-right: 0.2rem;
}
.floor-list .floor-item .floor-good img {
  width: 100%;
  height: 100%;
  max-height: 2rem;
  object-fit: contain;
}
.floor-list .floor-item .floor-specifi {
  position: relative;
  width: 100%;
}
.floor-list .floor-item .floor-specifi span {
  display: block;
}
.floor-list .floor-item .floor-specifi span.specifi-name {
  font-size: 0.3rem;
  width: 4rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
}
.floor-list .floor-item .floor-specifi span.specifi-num {
  font-size: 0.24rem;
}
.floor-list .floor-item .floor-specifi span.specifi-company {
  font-size: 0.28rem;
  width: 4.3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.floor-list .floor-item .floor-specifi .specifi-price-box span {
  display: inline-block;
}
.floor-list .floor-item .floor-specifi span.specifi-price {
  font-size: 0.32rem;
  margin-right: 0.18rem;
}
.floor-list .floor-item .floor-specifi span.specifi-old-price {
  font-size: 0.22rem;
  text-decoration: line-through;
}
.floor-list .floor-item .floor-specifi .shop-cart {
  position: absolute;
  right: 0rem;
  bottom: 0.2rem;
  width: 0.36rem;
}
/* 库存、满赠、远近效期 */
.floor-list .floor-item .floor-specifi .specifi-stock span {
  font-size: 0.24rem;
}
.floor-list .floor-item .floor-specifi .specifi-time {
  font-size: 0.24rem;
}
/* 活动 */
.floor-list .floor-item .active-box {
  width: 100%;
}
.floor-list .floor-item .active-box .active-tit {
  font-size: 0.24rem;
}

/* 分类弹窗 */
.sort-pop {
  position: fixed;
  top: 1.9rem;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
}
.sort-cont {
  position: fixed;
  top: 1.9rem;
  left: 0;
  width: 100%;
  height: 5rem;
  background: #fff;
  z-index: 3;
  border-radius: 0 0 0.2rem 0.2rem;
}
.sort-cont .sort-left {
  width: 22%;
  background: #f7f7f7;
  height: 100%;
  overflow: hidden;
  overflow: auto;
  border-radius: 0 0 0 0.2rem;
}
.sort-cont .sort-left .left-item {
  width: 100%;
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  font-size: 0.32rem;
  white-space: nowrap;
  padding: 0 0.12rem;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sort-cont .sort-left .left-item.active {
  background: #fff;
  color: #2fbdff;
}
.sort-cont .sort-right {
  width: 78%;
  border-radius: 0 0 0 0.2rem;
  padding: 0.2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.sort-cont .sort-right .sort-top {
  width: 100%;
  height: 80%;
  overflow-y: auto;
}
.sort-cont .sort-right .sort-type {
  flex-wrap: wrap;
}
.sort-cont .sort-right .cancel {
  width: 100%;
  height: 16%;
  text-align: center;
  line-height: 0.8rem;
  font-size: 0.3rem;
  color: #fff;
  background: #2fbdff;
  border-radius: 0.12rem;
  margin: 0 auto;
}
.sort-cont .sort-right .right-item {
  padding: 0.12rem 0rem;
  font-size: 0.28rem;
  border-radius: 0.1rem;
  margin: 0 0.18rem 0.2rem 0;
  background: #b1afaf;
  color: #fff;
  width: 31%;
  text-align: center;
  white-space: nowrap;
}
.sort-cont .sort-right .right-item.active {
  color: #2fbdff;
  border: 0.02rem solid #2fbdff;
  background: #fff;
  box-sizing: border-box;
}
.sort-cont .sort-right .right-item:nth-child(3n) {
  margin-right: 0;
}

/* 筛选弹窗 */
.screen-popup {
  position: fixed;
  top: 0.9rem;
  left: 0;
  right: 0;
  bottom: 1.1rem;
  background: rgba(0, 0, 0, 0.4);
  z-index: 4;
}
.screen-cont {
  position: fixed;
  top: 0.9rem;
  left: 20%;
  bottom: 1.1rem;
  width: 80%;
  background: #fff;
  z-index: 5;
}
.screen-cont .screen-top {
  width: 100%;
  height: 0.8rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
  font-size: 0.36rem;
  color: #666;
  background: #f7f7f7;
  line-height: 0.8rem;
}

.screen-cont .screen-box {
  width: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
}
.screen-cont .screen-box .screen-item .item-tit {
  width: 100%;
  height: 0.5rem;
}
.screen-box .screen-item .item-tit span {
  font-size: 0.3rem;
}
.screen-box .screen-item .item-tit img {
  width: 0.28rem;
  height: 0.28rem;
}

.screen-box .screen-item .label-list {
  width: 100%;
  flex-wrap: wrap;
  margin-top: 0.16rem;
  border-bottom: 0.02rem solid #eee;
  min-height: 1.2rem;
}
.screen-box .screen-item:last-child .label-list {
  border-bottom: 0;
}
.screen-box .screen-item .label-list .label-item {
  padding: 0.03rem 0.2rem;
  font-size: 0.28rem;
  background: #f7f7f7;
  border-radius: 0.28rem;
  margin: 0 0.4rem 0.2rem 0;
}
.screen-box .screen-item .label-list .label-item.active {
  background: #2fbdff;
  color: #fff;
}
.shopLists{
  margin-top: 2.1rem;
}

/* 缺货登记弹窗 */
	.register-popup {
	  position: fixed;
	  width: 100%;
	  height: 100%;
	  top: 0;
	  left: 0;
	  background: rgba(0, 0, 0, 0.6);
	  z-index: 99;
	}
	.register-cont {
	  position: fixed;
	  width: 86%;
	  top: 4.5rem;
	  left: 7%;
	  background: #fff;
	  z-index: 7;
	  padding: 0.2rem;
	  box-sizing: border-box;
	  border-radius: 0.2rem;
	  z-index: 999;
	}
	.register-cont .regis-tit {
	  width: 100%;
	  text-align: center;
	  font-size: 0.39rem;
	}
	.register-cont .regis-item {
	  margin-bottom: 0.2rem;
	  border-bottom: 0.02rem solid #eee;
	  height: 0.8rem;
	}
	.register-cont .regis-item .item-tit {
	  width: 2.3rem;
	  font-size: 0.3rem;
	}
	.register-cont .regis-item .input-box {
	  flex: 1;
	}
	.register-cont .regis-item .input-box input {
	  font-size: 0.3rem;
	  flex: 1;
	}
	.register-cont .regis-item .input-box img {
	  width: 0.16rem;
	  margin-left: 0.2rem;
	}
	.register-cont .regis-item .input-box .input-tit {
	  font-size: 0.32rem;
	}
	
	.register-cont .regis-but {
	  width: 100%;
	  justify-content: space-around;
	}
	.register-cont .regis-but .but-around {
	  width: 1.8rem;
	  height: 0.6rem;
	  font-size: 0.3rem;
	  text-align: center;
	  line-height: 0.6rem;
	  border-radius: 0.2rem;
	  color: #fff;
	}
	.register-cont .regis-but .but-around.grey {
	  background: #c0bfbf;
	}
	.register-cont .regis-but .but-around.blue {
	  background: #0cb4e7;
	}
</style>
