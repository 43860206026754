<template>
  <div class="pointsDetails">
    <div class="pointsDetailsHead">
      <div class="details">
        <div>可用积分</div>
        <div style="font-size: 0.4rem;">{{points.ValidPoints}}</div>
       <div>今日积分:{{todayPoint}}</div>
      <div>
         <div class="exchangeBtn" @click="exchangeBtn">兑换积分</div>
      </div>
      </div>
    </div>

    <van-tabs  class="tabs"  @click="onClick" color='#1ABC9C' title-active-color='#1ABC9C' line-width='1.8rem' :sticky=true offset-top='3.9rem'>
     <van-tab title="全部">
      <van-list
        v-model="loading" :finished="finished"  finished-text="没有更多了"  @load="onLoad" :offset="10" :immediate-check='false' >
        <van-cell v-for="(item,id) in allList" :key="id">
          <div class="flex-sb">
            <div>
              <div>{{item.PointsTypeName}}</div>
              <div>{{item.PointsDate}}</div>
            </div>
            <div class="green" style="padding-right: 0.5rem;">
              {{item.Points}}
            </div>
          </div>
        </van-cell>
      </van-list>   
    </van-tab>

    
    <van-tab title="收入">
        <van-list   v-model="loading"  :finished="finished"   finished-text="没有更多了"  @load="onLoad('10')" :offset="10" :immediate-check='false' >
        <van-cell v-for="(item,id) in incomeList" :key="id">
          <div class="flex-sb">
            <div>
              <div>{{item.PointsTypeName}}</div>
              <div>{{item.PointsDate}}</div>
            </div>
            <div class="green" style="padding-right: 0.5rem;">
              {{item.Points}}
            </div>
          </div>
        </van-cell>
      </van-list>
    </van-tab>

    <van-tab title="支出">
      <van-list  v-model="loading"    :finished="finished"    finished-text="没有更多了"   @load="onLoad('20')" :offset="10"  :immediate-check='false'>
        <van-cell v-for="(item,id) in outlayList" :key="id">
          <div class="flex-sb">
            <div>
              <div>{{item.PointsTypeName}}</div>
              <div>{{item.PointsDate}}</div>
            </div>
            <div class="green" style="padding-right: 0.5rem;">
              {{item.Points}}
            </div>
          </div>
        </van-cell>
      </van-list>
    </van-tab>
  </van-tabs>
  </div>
</template>

<script>
import Vue from "vue";
import { Tab, Tabs, List} from "vant";
import { Cell } from 'vant';

Vue.use(Cell);

Vue.use(List, Tab, Tabs);
export default {
  data() {
    return {
      active: 0,
      allList: [], //全部商品
      points:[],
      incomeList: [], //收入商品
      outlayList: [], //支出商品
      loading: false,
      finished: false,
      page: 1,
      rows:10,
      total: 1,
      InOutType:'',
      isLondShow:false,
      todayPoint:'',
    };
  },
  created () {
    this.getPointsDetails(); 
    this.onLoad()
  },
  methods: {
  getPointsDetails(){
      var that=this
      that.public.request(
        'post',
        'api/PointsMain/QueryItem?isShowTodayScore=true',
       '',
        (res)=>{
          that.todayPoint=res.data.extend;
          that.points=res.data.rows;
        }
      )
    },
   
    //tab切换
    onClick(name, title){
      var that=this
      that.loading= true,
      that.finished=false,
      that.page= 1,
      that.rows=10,
      that.total= 1,
      that.allList=[],
      that.incomeList=[],
      that.outlayList=[]
      if(title=='收入'){
          that.onLoad(10)
      }else if(title=='支出'){        
          that.onLoad(20)       
      }else if(title=='全部'){      
         that.onLoad()
      }
      
    },
    //加载数据
    onLoad(type) { 
    var that=this
     var data={
        page:that.page,
        rows:that.rows,
        total:that.total,
        InOutType: type,
        
      }
      if(that.page==1){
          that.$toast.loading({
          message:'加载中'
        });
      }
    setTimeout(()=>{
        that.public.request(
        'post',
        'api/PointsDetail/QueryPage',
        data,
        (res)=>{
          that.$toast.clear();
          if(res.data.errorCode=='00'){
          if(type=='10'){ 
            that.incomeList.push(...res.data.rows);
          }else if(type=='20'){
              that.outlayList.push(...res.data.rows);
          }else{
             that.allList.push(...res.data.rows);     
          }    
          that.loading = false;
          if(that.page*that.rows>res.data.records){
             this.finished = true;	// 结束加载状态     
          }else{
            that.page++;
          }
         }else{
           that.$toast(res.data.errorCode);
         }
        });
    },500);
   
    },
//兑换积分
  exchangeBtn(){
      var that=this
        that.$router.push({
           path: "/pointsMall",
      
        });
    }
  }
};
</script>

<style scoped>
.pointsDetailsHead{
  background-color: #1ABC9C;
 margin: 0 0.2rem;
 height: 3rem;
 color: white;
 position: fixed;
   top: .90rem ;
    width: 95%;
    overflow: hidden;
    z-index: 99;
}
.details{
  line-height: 0.48rem;
  text-align: center;
  padding-top: 0.5rem;
  font-size: 0.26rem;
}
.flex-sb{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.green{
  color: #1ABC9C;
}
.exchangeBtn{
  padding: 0.06rem;
  background-color: white;
    color: #1ABC9C;
    width: 3.9rem;
    margin: 0 22%;
    border-radius: 0.2rem;
}
.tabs{
margin-top: 3rem;

}
.van-tabs__wrap{
  position: fixed;
    top: 216px;
    width: 98%;
    z-index: 3;
}


</style>
