<template>
  <div class="container"> <!-- :style="{top:topHeight+'rem'}" -->
    <!-- 个人信息 -->
    <div class="info-box" :style="{backgroundImage:themeImgObj.mineTopBg ? `url(${themeImgObj.mineTopBg})` : 'auto'}">
      <div class="info-b">
        <div class="info-img">
            <img v-if="userInfo&&userInfo.HeadImgUrl" :src="userInfo.HeadImgUrl" alt="" />
            <img v-else src="@/assets/images/mine/head.png" />  
        </div>  
        <div class="userInfo">
          <div class="flex-ac">
              <div v-if="userInfo&&userInfo.Name" class="userName" >{{userInfo.Name}}</div>
			  <!-- <div class="userName" v-else-if="JSON.stringify(wxUserInfo)!='{}'">{{wxUserInfo.Name}}</div> -->
			  <!-- <div v-else class="userName">游客</div> -->
              <div>
                <div v-if="pointsSetting.IsSignInEnable &&token" class="integral-item flex-wrap" @click="onSignShow">
                    <img src="@/assets/images/mine/icon-qiandao.png" alt="">
                    <span class="integral-txt">每日签到</span>
                </div>
              </div>
          </div>
          
         <div class="orgNames" v-if="token&&userInfo.OrgName">
            <div style="display:flex">
				<span class="orgName" >{{userInfo.OrgName}}</span>
				<div class="qiehuan" >
					<img  @click="toSelectCompany" src="@/assets/images/mine/qiehuan.png" alt="">
				</div>
			 </div>
          </div>
		  <div class="orgNames" v-if="token">
			<img class="diZhiImg" src="../../assets/images/mine/dizhi.png" alt="">
			<span class="name-mobile">{{userInfo&&userInfo.Address?userInfo.Address:"" }}</span>
		  </div>
		  <!-- <div class="points" @click="toPointsBtn">
		      <div>可用积分:0</div>
		      <div>在途积分:0</div>
		      <div>已兑积分:0</div>
		  </div> -->
          <div class="points" v-if="token&&pointsSetting.Enable" @click="toPointsBtn">
              <div>可用积分:{{pointExtend.ValidPoints}}</div>
              <div>在途积分:{{pointExtend.OnTheRoadPoints}}</div>
              <div>已兑积分:{{pointExtend.ExchangePoints}}</div>
          </div>
        </div>     
      </div>
    </div>
    <div class="order-box">
      <div class="orderDetail">
         <div class="order-list  border-b flex-sp" @click="toPurchase(0)">
          <div class="order-l"> 
            我的订单
          </div>
          <div class="order-b flex-wrap">
            <span>全部订单</span>
            <img src="@/assets/images/mine/icon_right.png" alt="" />
          </div>
        </div>
         <div class="order-status flex-sp">
          <div class="order-item" @click="toPurchase(100)">
            <!-- <span class="order-num">2</span> -->
            <img :src="mineDfk" alt="" />
            <span>待付款</span>
          </div>
          <div class="order-item" @click="toPurchase(15)">
            <!-- <span class="order-num">2</span> -->
            <img :src="mineYyd" alt="" />
            <span>已预订</span>
          </div>
          <div class="order-item" @click="toPurchase(20)">
            <!-- <span class="order-num">2</span> -->
            <img :src="mineYfh" alt="" />
            <span>已发货</span>
          </div>
          <div class="order-item" @click="toPurchase(30)">
            <!-- <span class="order-num">2</span> -->
            <img :src="mineYsh" alt="" />
            <span>已收货</span>
          </div>
          <div class="order-item" @click="toPurchase(99)">
            <!-- <span class="order-num">2</span> -->
            <img :src="mineYqx" alt="" />
            <span>已取消</span>
          </div>
        </div>
      </div>

      <div class="serve-box">
        <div class="tool order-l border-b">常用工具</div>
        <div class="serve-list">
          <div class="serve-item " @click="toMyBrowse">
            <img src="@/assets/images/mine/icon_zjll.png" alt="" />
            <span>最近浏览</span>
          </div>
          <div class="serve-item " @click="toMyCollect">
            <img src="@/assets/images/mine/icon_mySc.png" alt="" />
            <span>我的收藏</span>
          </div>
          <div class="serve-item " @click="toMyCoupon">
            <img src="@/assets/images/mine/icon_myKq.png" alt="" />
            <span>我的卡券</span>
          </div>
          <div class="serve-item " @click="toWantBook">
            <img src="@/assets/images/mine/icon_qhlb.png" alt="" />
            <span>缺货列表</span>
          </div>
          <div class="serve-item " @click="toOftenBreed">
            <img src="@/assets/images/mine/icon_cgwz.png" alt="" />
            <span>常购物种</span>
          </div>
		  <div class="serve-item " @click="toBuyBook">
		    <img src="@/assets/images/mine/icon_qgdj.png" alt="" />
		    <span>求购登记</span>
		  </div>
          <div class="serve-item " @click="toCompanyInfo">
            <img src="@/assets/images/mine/icon_qyxx.png" alt="" />
            <span>企业信息</span>
          </div>
          <div class="serve-item " @click="toPaperPlan">
            <img src="@/assets/images/mine/icon_zzjh.png" alt="" />
            <span>纸质计划</span>
          </div>
          <div class="serve-item " @click="toReceiveAdd">
            <img src="@/assets/images/mine/icon_address.png" alt="" />
            <span>收货地址</span>
          </div>
          <!-- <div class="serve-item " @click="toAbout">
            <img src="@/assets/images/mine/icon_about.png" alt="" />
            <span>关于我们</span>
          </div> -->
          <!-- <div class="serve-item " @click="toBill" v-if="orgId!='2679591e77624a6489912571f449832c'">
            <img src="@/assets/images/mine/icon_text.png" alt="" />
            <span>发票管理</span>
          </div> -->
		  <div class="serve-item " @click="toMySecure">
		    <!-- <img src="@/assets/images/mine/icon_aqzx.png" alt="" /> -->
		  			<img src="@/assets/images/index/supper.png" alt="" />
		    <span>签到礼品</span>
		  </div>
		  <div class="serve-item " @click="toMyQualification">
		    <img src="@/assets/images/mine/icon_myzizhi.png" alt="" />
		    <span>我的资质</span>
		  </div>	
          <div class="serve-item " @click="toQualification">
            <img src="@/assets/images/mine/icon_zizhi.png" alt="" />
            <span>公司资质</span>
          </div>
		  <div class="serve-item " @click="toBankCard">
            <img src="@/assets/images/mine/icon_logOut.png" alt="" />
            <span>银行卡</span>
          </div>
		  <div class="serve-item " @click="toSetting">
		    <img src="@/assets/images/mine/icon_set.png" alt="" />
		    <span>设置</span>
		  </div>
        </div>
        <!-- <div class="out-btn" @click="logOut">退出登录</div> -->
      </div>
    </div>
	
	<div class="adviser" v-if="adviserUser">
		<p style="color: #666;">专属业务顾问：</p>
		<div class="user">
			<!-- <img src="@/assets/images/concat.png" alt=""> -->
			<span>{{adviserUser.EmployeeName}}  {{adviserUser.EmployeeMobile}}</span> 
			<img src="@/assets/images/icon_mobile.png"  @click="callPhone(adviserUser.EmployeeMobile)">
		</div>
	</div>

    <!-- 签到弹窗 -->
    <div class="sign-pop-box" v-if="signShow"></div>
    <div class="sign-pop" v-if="signShow">
      <div class="sign-top flex-wrap">
        <span class="top-tit">每日签到</span>
        <span class="top-close" @click="closeSign">x</span>
      </div>
      <div class="sign-box">
        <div class="sign-tit">每日签到领积分，积分可兑换卡券或好物</div>
        <div class="sign-day flex-sp">
          <div class="day-item" v-for="(item,index) in signData.SignList" :key="index">
            <div class="item-box flex-column" :class="item.IsToday?'active':''"  >
              <span class="item-num">{{item.WeekDay}}</span>
              <img class="item-img" v-if="item.IsSign" src="@/assets/images/mine/signin_do.png" alt="">
              <img class="item-img" v-else src="@/assets/images/mine/signin_did.png" alt="">
            </div>
            <span class="item-d">{{item.IsToday?"今天":item.WeekDate}}</span>
          </div>
        </div>
        <div style="display: flex; justify-content: center; padding: 0 20px;">
        	<div style="flex: 1;">
        		<div class="sign-t">已连续签到<b class="sign-blue" >{{signData.ContinueDay}}</b>天</div>
        		<div class="sign-point flex-center">
        		  <img class="pImg" src="@/assets/images/mine/singin_points.png" alt=""> 
        		  <span class="sign-blue" style="padding-left: 0.1rem;"> +{{signData.TodaySignInPoints}}</span>
        		</div>
        	</div>
        	<div style="flex: 1;">
        		<div class="sign-t">已获取礼品 <b class="sign-blue" >{{signData.giftCount}}</b></div>
        		<div class="sign-point flex-center">
        		  <img class="gImg" :src="signData.GiftPic" alt=""> 
        		  <span class="sign-blue">{{signData.GiftName}} +1</span>
        		</div>
        	</div>
        </div>
        <div class="sign-btn sign-blue" v-if="!signData.IsSignIn || signData.IsSignInMore" @click="onSignTap">签到</div>
        <div class="sign-btn sign-grey" v-if="signData.IsSignIn && !signData.IsSignInMore" >已签到</div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  data() {
    return {
      mobile: "",
      userInfo: {},
      publicPath: process.env.BASE_URL,
      codeUrl: "",
      appType: "", //当前app类型
      salemanName: "", //业务员名称
      salemanMobile: "", //手机号

      isBack: true, // 为true可以返回上一页

      pointsSetting: {},    // 积分数据
      pointExtend: {},    // 积分extend
      signData:{},  // 签到数据
      signShow: false,    // 是否显示签到弹窗 false隐藏  true显示
      token:sessionStorage.getItem('member_token'),
      wxUserInfo: {},   // 微信授权登录的用户信息
      topHeight: 0,   // 顶部距离

      orgId: localStorage.getItem("orgId"),
	  androidFlag: false,//是否Android 浏览器
	  adviserUser: null, //专属顾问
    };
  },
  beforeCreate(){
    document.querySelector('body').setAttribute('style', '');
    document.querySelector('body').setAttribute('style', "font-size:12px;");
  },
  created() {
    var that = this;
	// that.androidFlag = /(Android)/i.test(navigator.userAgent.toLowerCase());
	let clientType = localStorage.getItem("clientType");
	if(clientType == "app"){
		that.androidFlag = true;
	}else if(clientType == "ios"){
		//ios todo
	}
	
    // 主题图片
    that.themeImgObj = that.themeColorImg.imgObj;
    // console.log(that.themeImgObj);
    // console.log(that.themeImgObj.mineDfk);
    that.mineDfk = that.themeImgObj.mineDfk;
    that.mineYyd = that.themeImgObj.mineYyd;
    that.mineYfh = that.themeImgObj.mineYfh;
    that.mineYsh = that.themeImgObj.mineYsh;
    that.mineYqx = that.themeImgObj.mineYqx;


    // 获取微信授权登录的用户信息
    var wxUserInfo = sessionStorage.getItem("wxUserInfo");
    if(wxUserInfo){
      that.wxUserInfo = wxUserInfo;
    }
    
    sessionStorage.setItem("junp", true);
    // 查看用户是否登录
    // 查看是否登录
    var token = sessionStorage.getItem('member_token');
    if (token == null) {
		var orgId = localStorage.getItem("orgId");
		that.$dialog.confirm({
			message: "你还未登录，是否登录!",
		}).then(() => {
			if(that.isMini()){
				that.wx.miniProgram.reLaunch({
				    url: window.baseUrl.xcxLogin?'/pages/login/index?logout=true':'/pages/login/oldLogin/index?logout=true',
				})
				return;
			}
			
		  that.$router.push({
			path: "/login",
			query: {
			  orgId: orgId,
			  auto: 0,
			},
		  });
		}).catch(() => {
		  // console.log('取消');
		});
    }

	if(token){
		// 请求购物车数量
		setTimeout(function() {
		  // console.log(9999);
		  that.public.getCartNum();
		},500);

	}
	//查询企业信息
	that.getUserInfo();
	//获取专属顾问
	that.getAdviserInfo();
    // 获取机构配置信息 logo 客服电话
    // that.getCompany();
    // 获取积分
    that.getPoint();

    switch (localStorage.clientType) {
      case "app":
        that.appType = "APP";
        that.topHeight = 0.8;
		case "ios":
		  that.appType = "APP";
		  // that.appType = "IOS";
		  that.topHeight = 0.8;
        break;
      case "applets":
        that.appType = "AppLet";
        that.topHeight = 0;
        break;
      case "weixin":
        that.appType = "WeChat";
        that.topHeight = 0;
        break;
      default:
        that.appType = "";
        that.topHeight = 0;
    }
  },
  mounted() {
    var that = this;
    that.isBack = that.$route.meta.isBack;
    // console.log(that.isBack);
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", that.popstateBack, true);
    }
  },
  
  activated(){
  	if(this.androidFlag && top.android){
  		top.android.onGoBack(true); //调用app方法
  	}
  },
  deactivated(){
  	if(this.androidFlag && top.android){
  		top.android.onGoBack(false); //调用app方法
  	}
  },
  destroyed() {
    window.removeEventListener("popstate", this.popstateBack, true);
  },

  methods: {
    // 签到弹窗显示
    onSignShow(){
      var that = this;
      var data = {}
      that.public.request("post",'/api/SignIn/QuerySignInInfo',data, res => {
        // console.log(res);
        if(res.data.errorCode == '00'){
          that.signData = res.data.rows;
          that.signShow = true;
        }else if(res.data.errorCode == '99'){
          that.$toast(res.data.errorMsg);
        }
      })
    },
    // 去签到
    onSignTap(){
      var that = this;
      var data = {};
      if(!that.signData.isSignIn){
        that.public.request('post','/api/SignIn/SignIn',data, res => {
          if(res.data.errorCode == '00'){
           that.onSignShow();
           that.getPoint();
          }else if(res.data.errorCode == '99'){
            that.$toast(res.data.errorMsg);
          }
        })
      }
    },
    // 关闭弹窗
    closeSign(){
      var that = this;
      that.signShow = false; 
      
    },
    // 获取积分
    getPoint(){
      var that = this;
      var data = {}
      
      that.public.request('post','/api/PointsMain/PersonInfoPoints',data, res => {
        // console.log(res);
        if(res.data.errorCode != '00'){
          return false;
        }
        that.pointsSetting = res.data.rows;
        that.pointExtend = res.data.extend;
      })
    },
  
    //获取当前用户名称，企业名称
    getUserInfo() {
      var that = this;
      that.public.request("post","/api/sysUserInfo/queryAppUserInfoExtend",
        {},(res) => {
			if (res.data.errorCode == "00") {
				that.userInfo = res.data.rows;
				if (res.data.extend) {
				  that.salemanName = res.data.extend.EmployeeName?res.data.extend.EmployeeName:"";
				  that.salemanMobile = res.data.extend.EmployeeMobile?res.data.extend.EmployeeMobile:"";
				}
			}else{
			   that.$toast(data.errorMsg);
			}
        }
      );
    },
	
	//获取专属顾问
	getAdviserInfo() {
	  var that = this;
	  that.public.request("post","/api/Qualification/GetCustomerQualification",
	    {},(res) => {
			if (res.data.errorCode == "00") {
				that.adviserUser = res.data.rows.salesman;
			}else{
			   that.$toast(data.errorMsg);
			}
	    }
	  );
	},

    // 获取机构配置信息 logo 客服电话
    getCompany() {
      var that = this;
      var orgId = localStorage.getItem("orgId");
      var data = {
        orgId: orgId,
      };
      that.public.request("get","/api/SysOrgInfo/QueryOrgWebConfig",
        data,(res) => {
          // console.log(res);
        }
      );
    },
    toPointsBtn(){
        var that=this;
        if (that.token == null) {
         var orgId = localStorage.getItem("orgId");
         that.$dialog
            .confirm({
             message: "你还未登录，是否登录!",
          })
         .then(() => {
			 if(that.isMini()){
			 	that.wx.miniProgram.reLaunch({
			 	    url: window.baseUrl.xcxLogin?'/pages/login/index?logout=true':'/pages/login/oldLogin/index?logout=true',
			 	})
			 	return;
			 }
			 
           that.$router.push({
             path: "/login",
              query: {
                orgId: orgId,
                auto: 0,
             },
           });
          })
         .catch(() => {
          // console.log('取消');
          });
        }else{
           this.$router.push({
          path: "/pointsMall",    
         });
        }
      
    },


    // 采购订单
    toPurchase(status) {
      this.$router.push({
        path: "/purchaseOrder",
        query: {
          status: status,
        },
      });
    },
    // 我的卡券
    toMyCoupon() {
      this.$router.push({
        path: "/myCoupon",
      });
    },
    // 最近浏览
    toMyBrowse() {
      this.$router.push({
        path: "/myBrowse",
      });
    },
    // 我的收藏
    toMyCollect() {
      this.$router.push({
        path: "/myCollect",
      });
    },
    // 缺货列表
    toWantBook() {
      this.$router.push({
        path: "/wantBook",
      });
    },
	//求购登记
	toBuyBook(){
		this.$router.push({
		  path: "/buyBook",
		});
	},
    // 签到礼品
    toMySecure() {
      this.$router.push({
        path: "/signInGift",
      });
    },
    // 纸资计划
    toPaperPlan() {
      this.$router.push({
        path: "/paperCapitalPlan",
      });
    },
    // 收货地址
    toReceiveAdd() {
      this.$router.push({
        path: "/receiveAddress",
      });
    },
    // 关于我们
    toAbout() {
      this.$router.push({
          path: '/aboutUs'
      })
      // location.href = "./about/AboutUs.html";
    },

    //发票管理
    toBill() {
       
     this.$router.push({
        path: "/billManage",
        
      });
    },
    //公司资质
    toQualification() {      
     this.$router.push({
        path: "/companyZiZhi",
        
      });
    },
    //我的资质
    toMyQualification(){
		if(this.isMini()){
			this.wx.miniProgram.navigateTo({
			    url: '/pages/login/updateBasicInfo/index',
			})
			return;
		}
		
		this.$router.push({
			path: "/qualification",
		});
    },
	
	//判断是否小程序浏览器加载
	isMini: function(){
		let mini = false;
		if (/MicroMessenger/i.test(navigator.userAgent)) {
			//ios的ua中无miniProgram，很坑爹,但都有MicroMessenger（表示是微信浏览器）
			this.wx.miniProgram.getEnv((res) => {
				if (res.miniprogram) {
					mini = true;
				}
			});
		}
		return mini;
	},

    // 切换企业
    toSelectCompany() {
      this.$router.push({
        path: "/selectCompany",
        query: {
          // type: "switchMine",
          // from:'mine'
        },
      });
    },
    // 常购品种
    toOftenBreed() {
      this.$router.push({
        path: "/oftenBreed",
      });
    },
    // 企业信息
    toCompanyInfo() {
      this.$router.push({
        path: "/companyInfo",
      });
    },
	
	// 银行卡
	toBankCard(){
		this.$router.push({
		  path: "/bankCard",
		});
	},
	//设置
	toSetting(){
		this.$router.push({
		  path: "/setting",
		});
	},
	
    //退出登录
    logOut() {
      var that = this;
      that.public.request("post", "/api/Online/Logout",{
		  LoginType: that.appType ,
		},(res) => {
          //localStorage.setItem("CloseIndexPopupLayer", false);
		  localStorage.user = "";
		  let token = sessionStorage.getItem("member_token");
		  let queryObj = {
            orgId: localStorage.orgId,
            openid: localStorage.openid,
            clientType: localStorage.clientType,
            auto: (token?1:0),
            _: new Date().valueOf(),
          };
		  if (localStorage.appv) {
		    queryObj.appv = localStorage.appv;
		  }
		  
          var cipherText = localStorage.cipherText;
          var cipherPsw = localStorage.cipherPsw;
		   // 清除localStorage所有缓存
          localStorage.clear();
          sessionStorage.clear();
		  
		  localStorage.setItem("appFirst",false); //设置非第一次启动
		  sessionStorage.setItem("junp", true);
		  localStorage.setItem("agreement", true);
		  if (cipherText) { //设置记住账号密码
		    localStorage.setItem("cipherText", cipherText);
		    localStorage.setItem("cipherPsw", cipherPsw);
		  }
		  // sessionStorage.setItem("junp", true);
		  if(that.isMini()){
		  	that.wx.miniProgram.reLaunch({
		  	    url: window.baseUrl.xcxLogin?'/pages/login/index?logout=true':'/pages/login/oldLogin/index?logout=true',
		  	})
		  	return;
		  }
          that.$router.push({
            path: "/login",
			query: queryObj,
          });
        }
      );
    },
	
	//判断是否小程序浏览器加载
	isMini: function(){
		let mini = false;
		if (/MicroMessenger/i.test(navigator.userAgent)) {
			//ios的ua中无miniProgram，很坑爹,但都有MicroMessenger（表示是微信浏览器）
			this.wx.miniProgram.getEnv((res) => {
				if (res.miniprogram) {
					mini = true;
				}
			});
		}
		return mini;
	},
	
	callPhone (phoneNumber) {
	   window.location.href = 'tel:' + phoneNumber
	},
	
    // 监听返回
    popstateBack() {
      var that = this;
      var isBack = that.isBack;
      if (!isBack) {
        var userAgent = navigator.userAgent;
        var isAndroid =
          userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1; //android终端
        var isIos =
          (userAgent.indexOf("iphone") > -1 ||
            userAgent.indexOf("ipad") > -1 ||
            userAgent.indexOf("ipod") > -1) &&
          (platform.indexOf("iphone") > -1 ||
            platform.indexOf("ipad") > -1 ||
            platform.indexOf("ipod") > -1);

        if (window.WeixinJSBridge) {
          window.WeixinJSBridge.call("closeWindow"); // 微信
        } else if (window.AlipayJSBridge) {
          window.AlipayJSBridge.call("closeWebview"); // 支付宝
        } else if (isAndroid) {
          window.obj.closePageLppzRequest(""); // 安卓app
        } else if (isIos) {
          window.webkit.messageHandlers.closePageLppzRequest.postMessage(""); //ios app
        } else {
          location.replace("about:blank", "_top");
        }
      }
    },
  },
};
</script>

<style scoped>
.container {
	position: relative;
	padding-bottom: 30px;
	background: #f6f6f6;
}
/* 用户头像框 */
.info-box {
  width: 100%;
  /* height: 150px; */
  padding-top: 0.5rem;
  /* background: #027ada; */
  /* background:  url("../../assets/images/mine/bg.png") no-repeat; */
  background-size: cover;
  box-sizing: border-box;
  padding-bottom: 0.1rem;
  color: white;
  font-size: 0.25rem;
}
.userInfo{
  margin-left: 1.9rem;
  padding-top: 0.2rem;
  min-height: 1.8rem;
}
 .info-box .info-b{position: relative;}
.info-box .info-b .info-img {
   position: absolute;
    width: 1.35rem;
    border-radius: 50%;
    overflow: hidden;
    background: #fff;
    left: 0.3rem;
    top: 0.3rem;
}
/* .info-box .info-b .info-img img{
  width: 100%;
} */
.info-box .info-b .info-pharmacy {
  flex: 1;
  margin-left: 2.3rem;
  box-sizing: border-box;
}
 /* .pharmacy-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 2.2rem ;
  margin-top: 0.5rem;
} */
.flex-ac{display: flex;align-content: center;}
.pharmacy-names{
    display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 2.2rem ;
    margin-top: 0.2rem;
}
.name-phar {
  margin-left: 2.3rem ;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

}
.userName{ font-size: 0.35rem;width: 3.75rem;    white-space: nowrap;    white-space: nowrap;text-overflow: ellipsis;}
.qiehuan{width:.4rem ;height: .24rem ; margin:0.04rem 0 0 0.05rem;    vertical-align: middle; }
.qiehuan img{  margin-top: 0.03rem; border-radius: 0.2rem;}
.orgName{ font-weight: 400;max-width: 5rem ;display: inline-block; white-space: nowrap;overflow: hidden; text-overflow: ellipsis;}
.points{display: flex; justify-content: space-between;  padding-bottom: 0.2rem;padding-right: 0.25rem;}
.info-box .info-b .info-pharmacy .pharmacy-change {
  width: 1.46rem;
  height: 0.4rem;
  line-height: 0.4rem;
  text-align: center;
  background: #027ada;
  border-radius: 0.17rem;
  color: #fff;
  font-size: 0.23rem;
}
.info-box .info-b .integral-box {
  width: 100%;
  height: 0.86rem;
  box-sizing: border-box;
}
 .integral-item {
    font-size: .28rem;
    align-items: center;
    background-color: white;
    color: var(--theme-color);
    padding: 0 0.1rem;
    margin: 0.08rem 0 0 0.2rem;
    font-size: 0.12rem;
    border-radius: 0.1rem;
}

 .integral-item img {
  width: 0.3rem;
  padding-right: 0.1rem;
}

.order-box {
  width: 100%;
  padding: 0.3rem 0.2rem;
  box-sizing: border-box;
}

/* 订单 */
.border-b { border-bottom: 0.02rem solid #f3f3f3;}
.order-box .order-list .order-b img {width: 0.36rem;}
.order-box .order-list .order-b span { font-size: 0.29rem; color: #656565;}
.order-box .order-status { padding: 0.2rem 0.3rem 0 0.3rem;}
.order-box .order-status .order-item { display: flex; flex-direction: column; align-items: center;position:relative;}
.order-box .order-status .order-item .order-num{ position:absolute;top:-0.04rem;right:0;padding:0 0.08rem;box-sizing:border-box;border-radius:50%;font-size:0.2rem;background:blue;color:#fff; }
.order-box .order-status .order-item img { width: 0.6rem;  height: 0.53rem;}
.order-box .order-status .order-item span { font-size: 0.24rem; color: #444444;}
.orderDetail{background-color: white; padding: 0.2rem 0;border-radius: 0.14rem;}
.order-l{padding-left: 0.2rem;   font-size: 0.29rem;color: #656565;;}

/* 工具列表 */
.tool{
padding: 0.1rem 0.2rem;
}
.order-box .serve-box {
  width: 100%;
  background: #fff;
  border-radius: 0.14rem;
 
  box-sizing: border-box;
  margin-top: 0.2rem;
}
.order-box .serve-box .serve-list {
	padding: 0.2rem; 
	display: flex;
	flex-wrap: wrap;
}
.order-box .serve-box .serve-item {
	width: 25%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 0.3rem;
}
.order-box .serve-box .serve-item:nth-child(4n) {
  margin-right: 0;
}
.order-box .serve-box .serve-item img {
  width: 0.7rem;
  height: 0.7rem;
  margin-bottom: 0.12rem;
}
.order-box .serve-box .serve-item span {
  font-size: 0.25rem;
  color: #262626;
}

.order-box .serve-box .out-btn {
  width: 80%;
  height: 0.63rem;
  line-height: 0.63rem;
  text-align: center;
  background: #0592fa;
  color: #fff;
  font-size: 0.42rem;
  border-radius: 0.21rem;
  margin: 0 auto 0.3rem;
}


/* 签到 */
.sign-pop-box{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background:rgba(0,0,0,0.5);
  z-index:10;
}
.sign-pop{
  position:fixed;
  width:90%;
  left:5%;
  top:20%;
  z-index:11;
}
.sign-pop .sign-top{
  width:100%;
  height:0.8rem;
  background:var(--theme-color);
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  padding:0 0.3rem;
  box-sizing:border-box;
}
.sign-pop .sign-top .top-tit{
  flex:1;
  height:100%;
  line-height:0.8rem;
  color:#fff;
  text-align:center;
  font-size:0.36rem;
}
.sign-pop .sign-top .top-close{
  height:100%;
  font-size:0.5rem;
  color:#fff;
  
}
.sign-pop .sign-box{
  width:100%;
  background:#fff;
  padding:0.3rem 0 0.2rem;
  box-sizing:border-box;
  border-radius:0 0 0.2rem 0.2rem;
}
.sign-pop .sign-box .sign-tit{
  width:100%;
  font-size:0.26rem;
  text-align:center;
  margin-bottom:0.3rem;
}
.sign-pop .sign-box .sign-day{
  width:100%;
  padding:0 0.1rem;
  box-sizing:border-box;
  margin-bottom:0.3rem;
}
.sign-pop .sign-box .sign-day .day-item{ width:0.85rem;}
.sign-pop .sign-box .sign-day .day-item .item-box{ align-items:center; background:#fafafa;  border-radius:0.12rem;}
.sign-pop .sign-box .sign-day .day-item .item-box.active{ background:var(--theme-color)}
.sign-pop .sign-box .sign-day .day-item .item-box.active .item-num{ color:#fff;}
.sign-pop .sign-box .sign-day .day-item .item-box .item-num{ font-size:0.27rem;}
.sign-pop .sign-box .sign-day .day-item .item-box .item-img{ width:0.68rem; margin-bottom:0.2rem;}
.sign-pop .sign-box .sign-day .day-item .item-d{ display:block; text-align:center;  font-size:0.24rem;}
.sign-pop .sign-box .sign-t{ font-size:0.32rem;  text-align:center;}
.sign-pop .sign-box .sign-point{  margin:0.2rem 0;}
.sign-pop .sign-box .sign-point .pImg{ width:0.63rem;}
.sign-pop .sign-box .sign-point .gImg{ width: 1rem; max-height: 40px;}
.sign-pop .sign-box .sign-point span{font-size:0.3rem;}
.sign-pop .sign-box .sign-btn{ width:70%; height:0.6rem; line-height:0.6rem; text-align:center;font-size:0.3rem; color:#fff; border-radius:0.2rem; margin:0.3rem auto;}
.sign-pop .sign-box .sign-btn.sign-blue{  background:var(--theme-color)}
.sign-pop .sign-box .sign-btn.sign-grey{ background:#C3C4C4;}
.sign-blue{ color:var(--theme-color);}
.change{background-color: var(--theme-color); color: white; padding:0.05rem 0.2rem; border-radius: 0.2rem; font-size: 14px; margin-top: 0.2rem;}
.color-blue{ color: var(--theme-color); font-size: .32rem;}
.change2{ margin-right: .4rem;}
.notoken{height: 0.7rem;}
.orgNames{background: rgb(255,255,255,0.2);padding: 0 0.1rem; display: inline-block; border-radius: 0.15rem;margin: 2px 5px 2px 0;}
.diZhiImg{width: 0.3rem;margin-right: 0.1rem;    display: inline-block;vertical-align: middle;}

.adviser{
	position: relative;
	margin: 0 10px;
	background: #fff;
	padding: 0 15px;
	font-size: 14px;
	border-radius: 5px;
	
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.adviser .user{
	color: #333;
	display: flex;
	align-items: center;
}
.adviser .user img{
	width: 28px;
	height: 28px;
	margin-left: 10px;
}
</style>
