<template>
  <div>
      <div class=" state p-60">
          <div>订单状态: {{ detailsList.StatusName}} </div>
          <div>订单编号: {{ detailsList.OrderNo}}</div>
          <!-- <div>支付方式: {{detailsList.StatusName}}</div> -->
          <div>消耗积分: {{ detailsList.Points}}</div>
          <div>下单时间: {{ detailsList.SubmitDate}}</div>
      </div>
      <div class=" shopList state">
          <div class="adressImg"><img src="@/assets/images/icon-adress.png" alt=""></div>
          <div class="p-left">
              <div>收货人: {{detailsList.CustomerName}}     <span class="p-left"> {{detailsList.ContactMobile}}</span></div>
              <div>收货地址:{{detailsList.Address}}{{detailsList.XqAddress}} </div>
          </div>
      </div>
       <div class="p-20 shopList">
          <div class="shopImg">
              <img :src=detailsList.Pic alt="">
            </div>
          <div class="shopItem">       
              <div>{{detailsList.Name}} <span class="p-left">x{{detailsList.Num}}</span></div>
              <div>{{detailsList.GoodsStyle}}/{{detailsList.GoodsUnit}}</div>
              <div>{{detailsList.GoodsFactory}}</div>
              <div>所需积分{{detailsList.Points}}</div>          
          </div>       
      </div>
  </div>
</template>

<script>
export default {
    data () {
        return {
            No:this.$route.query.no,
            detailsList:{},
        }
    },
    created () {
      this.getDetails()  
    },
    methods: {
        getDetails(){
            var that=this;
            that.$toast.loading({
                message:'加载中'
            });
          
            that.public.request(
                'post',
                'api/PointsOrders/QueryOrderInfoById',
                {OrderNo:that.No},
                (res)=>{
                    that.$toast.clear();
                    if(res.data.errorCode==='00'){
                        that.detailsList=res.data.rows[0];
                    }else{
                        that.$toast(res.data.errorMsg);
                    }
                } 
            );
        }
    }

}
</script>

<style scoped>

.flex-sp{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.state{
    font-size: .28rem;
    padding: .2rem 0.7rem;
    border-bottom: .02rem  solid #e7e7e7; 
}
.shopList{
    display: flex;
    align-items: center;
    padding: .4rem ;
}
.shopImg{
    width: 1.7rem ;
    height: 1.7rem;
    border-radius: 0.2rem;
    margin: 0 .4rem 0 0 ;
    overflow: hidden;
    border: .02rem  solid rgb(196, 195, 195);
    box-shadow: 0rem 0rem .2rem rgb(196, 195, 195);    
}
.shopImg img{
    width: 100%;
    height: 100%;
   object-fit: fill;
}
.shopItem{
    flex: 1 ;
    font-size: .28rem;
    padding-right: .2rem ;

}
.adressImg{
    width: 0.55rem;
}
.p-60{
    padding-left: 1.1rem ;
}
.p-left{
    padding-left: .3rem ;
}


</style>