import plJs from '../../../../public/public.js'
import router from '@/router'
var wechatHelp = {
    getCode() {
        var that = this;
        var redirect = encodeURIComponent(that.funcUrlDel("code"));
        var url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd1b24cad4f8a76c2&redirect_uri=" + redirect + "&response_type=code&scope=snsapi_userinfo&state=state&connect_redirect=1#wechat_redirect";
        location.href = url;
    },
    funcUrlDel(name) {
        var loca = window.location;
        var baseUrl = loca.origin + loca.pathname + "?";
        var query = loca.search.substr(1);
        if (query.indexOf(name) > -1) {
            var obj = {}
            var arr = query.split("&");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].split("=");
                obj[arr[i][0]] = arr[i][1];
            };
            delete obj[name];
            var url = baseUrl + JSON.stringify(obj).replace(/[\"\{\}]/g, "").replace(/\:/g, "=").replace(/\,/g, "&");
            return url
        } else {
            return window.location.href;
        };
    },
    getURLParameters() {
        var url = location.href;
        return (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
            (a, v) => ((a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)), a),
            {}
        );
    },
    getUserInfo(code) {
        var that = this;
        localStorage.setItem("wxCode", code);
        return new Promise((resolve, reject) => {
            var url = "/api/OAuth2/GetWeChatInfo";
            var data = {
                state: plJs.getConfig.defaultOrgId,
                code: code//微信code
            };
            plJs.request('post', '/api/OAuth2/GetWeChatInfo', data, res => {
                if (res.data.errorCode == '00') {
                    resolve(res);
                } else {
                    reject(res);
                }
            })
        });
    },
    bindWxOpenid(openId,mobile){
        var that = this;
        return new Promise((resolve,reject) => {
            var data = {
                OpenId: openId,
                Mobile: mobile,
            }
            plJs.request('post','/api/AppLogin/MobileBindOpenId',data,res => {
                if(res.data.errorCode == '00'){
                    router.push({
                        path: '/employeeIndex',
                        // query: {
                        //     OrgId: plJs.getConfig.defaultOrgId,
                        //     openId: openId,
                        // }
                    })
                }else{
                    alert("请联系管理员")
                }
            })
        })
    },
    run() {
        var that = this;
        var urlArgs = that.getURLParameters();
        if (urlArgs.code) {
            var url = window.location.pathname;
            window.history.pushState({},0,url);
            that.getUserInfo(urlArgs.code).then(res => {
                if (res.data.errorCode == '00') {
                    var userInfo = JSON.parse(res.data.rows);
                    var openId = userInfo.openid;
                    var mobile = localStorage.getItem("Mobile_bind");
                    that.bindWxOpenid(openId,mobile);
                }

            }).catch(res => {
                debugger;
            });
        }
        else{
            this.getCode();
        }
    }
};

export default wechatHelp;


